import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";

import Grid from "@material-ui/core/Grid";
// import { Link } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Card } from "@material-ui/core";
import { useStyles } from "./styles/MyOrganizationInformationStyles";
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import chaincodeService from '../../services/ChaincodeService'
import UserManagementService from '../../services/UserManagementService';
import notify from "../../utils/notifier";
import organizationService from "../../services/OrganizationService";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NetworkService from '../../services/NetworkService';
import SearchOrgPeers from "../organizations/SearchOrgPeers";
import SearchNetworks from "../organizations/SearchNetworks";
import OrganizationUsers from "../usermanagement/OrganizationUsers"
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from "@material-ui/icons/Close";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { VALID_USER_INPUT_REGEX } from "../../utils/constants";
import MySettings from "./MySettings";
import DialogTitle from '@material-ui/core/DialogTitle';
//TODO: this columns to be fetched form api once api supports the same...
export const _columns = [
  { id: "peerName", label: "Peer Name", align: "left", minWidth: 170, sortable: true },
  { id: "peerDomainName", label: "Peer Domain", align: "left", minWidth: 170, sortable: true },
  { id: "peerPort", label: "Peer Port", align: "left", minWidth: 170, sortable: true },
];

//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};

export default function OrganizationInformation() {

  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [searchKey, setSearchKey] = useState({ value: "" });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("peerName");
  const [columns, setColumns] = React.useState(_columns);
  const [page, setPage] = React.useState(0);
  const [invalidUserInput, setInvalidUserInput] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [backdrop, setBackdrop] = React.useState(false);
  const [chaincodes, setChaincodes] = React.useState(null);
  const [queryCount, setQueryCount] = React.useState(0);
  const [originalqueryCount, setOriginalQueryCount] = React.useState(0);
  const [tab, setTab] = React.useState(0);
  const [networkData, setNetworkData] = React.useState([]);
  const [networkOriginalData, setNetworkOriginalData] = React.useState([]);
  const [networkSearchKey, setNetworkSearchKey] = useState({ value: "" });
  const [organizationName, setOrganizationName] = React.useState(localStorage.getItem("_orgName"));
  const [organizationDomainName, setOrganizationDomainName] = React.useState(localStorage.getItem("_orgDomain"));
  const [organizationDomainNameCopy, setOrganizationDomainNameCopy] = React.useState(localStorage.getItem("_orgDomain"));
  const [organizationDescription, setOrganizationDescription] = React.useState("");
  const [isAdminEnrolled, setIsAdminEnrolled] = React.useState(
    localStorage.getItem('_orgRole') == "SUPERADMIN" ? true : localStorage.getItem("_adminEnrolled")
  );
  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [organizationNameCopy, setOrganizationNameCopy] = React.useState(localStorage.getItem("_orgName"));
  const [organizationDescriptionCopy, setOrganizationDescriptionCopy] = React.useState("");
  const [textBreadCrumbsOrganizationName, setTextBreadCrumbsOrganizationName] = React.useState(classes.textBreadCrumbsOrganizationName);
  const [textBreadCrumbsOrganizationDescription, setTextBreadCrumbsOrganizationDescription] = React.useState(classes.textBreadCrumbsDesc);
  const [textBreadCrumbsOrganizationDomainName, setTextBreadCrumbsOrganizationDomainName] = React.useState(classes.textBreadCrumbsDesc);
  const [breadCrumbOrganizationNameFocus, setBreadCrumbOrganizationNameFocus] = React.useState(false);
  const [breadCrumbOrganizationDescriptionFocus, setBreadCrumbOrganizationDescriptionFocus] = React.useState(false);
  const [breadCrumbOrganizationDomainNameFocus, setBreadCrumbOrganizationDomainNameFocus] = React.useState(false);
  const [onClickUsersTab, setOnClickUsersTab] = React.useState(false)
  const [textBreadCrumbsPeerName, setTextBreadCrumbPeerName] = React.useState(classes.textBreadCrumbPeerName);
  const [textBreadCrumbsPeerDomainName, setTextBreadCrumbPeerDomainName] = React.useState(classes.textBreadCrumbPeerDomainName);
  const [editPeerRow, setEditPeerRow] = React.useState({});
  const [editPeerRowId, setEditPeerRowId] = React.useState(null);
  const [editPeerName, setEditPeerName] = React.useState("");
  const [editPeerNameCopy, setEditPeerNameCopy] = React.useState("");
  const [editPeerDomainName, setEditPeerDomainName] = React.useState("");
  const [editPeerDomainNameCopy, setEditPeerDomainNameCopy] = React.useState("");
  const [focusedPeerColumn, setFocusedPeerColumn] = React.useState("")
  const [newEditPeerRow, setNewEditPeerRow] = React.useState("")
  const [newProp, setNewProp] = React.useState("")
  const [newEventTargetValue, setNewEventTargetValue] = React.useState("");
  const [emailSetting, setEmailSetting] = React.useState('');


  const handleMouseEnterOrganizationName = () => {
    setTextBreadCrumbsOrganizationName(classes.textBreadCrumbsOnHoverOrganizationName);
  }

  const handleMouseEnterOrganizationDescription = () => {
    setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsOnHoverDesc);
  }

  const handleMouseEnterOrganizationDomainName = () => {
    setTextBreadCrumbsOrganizationDomainName(classes.textBreadCrumbsOnHoverDesc);
  }

  const handleMouseEnterPeerRow = () => {
    setTextBreadCrumbPeerName(classes.textBreadCrumbsOnHoverPeerName);
    setTextBreadCrumbPeerDomainName(classes.textBreadCrumbsOnHoverPeerDomainName);
  }

  const handleMouseLeavePeerRow = () => {
    if (editPeerName === editPeerNameCopy) {
      setTextBreadCrumbPeerName(classes.textBreadCrumbs);
    }
  }

  const handleMouseLeaveOrganizationName = () => {
    if (organizationName === organizationNameCopy) {
      setTextBreadCrumbsOrganizationName(classes.textBreadCrumbsOrganizationName);
    }
  }

  const handleMouseLeaveOrganizationDescription = () => {
    if (organizationDescription === organizationDescriptionCopy) {
      setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsDesc);
    }
  }

  const handleMouseLeaveOrganizationDomainName = () => {
    if (organizationDomainName === organizationDomainNameCopy) {
      setTextBreadCrumbsOrganizationDomainName(classes.textBreadCrumbsDesc);
    }
  }

  const handleChangeOrganizationName = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      setOrganizationName(event.target.value);
    }
    setBreadCrumbOrganizationNameFocus(true);
  }

  const handleChangePeerRow = (row, prop) => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      if (editPeerRowId !== null && editPeerRowId !== row["id"]) {
        setNewEditPeerRow(row);
        setNewProp(prop);
        setNewEventTargetValue(event.target.value);
        setPopupOpen(true);
      } else {
        setEditPeerRow(row)
        setEditPeerRowId(row["id"])
        setFocusedPeerColumn(prop)
        if (editPeerRowId === null || editPeerRowId !== row["id"]) {
          setEditPeerName(row["peerName"])
          setEditPeerNameCopy(row["peerName"])
          setEditPeerDomainName(row["peerDomainName"])
          setEditPeerDomainNameCopy(row["peerDomainName"])
        }
        if (prop === "peerName") {
          setEditPeerName(event.target.value)
        } else if (prop === "peerDomainName") {
          setEditPeerDomainName(event.target.value)
        }
        setBreadCrumbOrganizationDescriptionFocus(true);
        setTextBreadCrumbPeerName(classes.textBreadCrumbsOnHoverPeerName);
        setTextBreadCrumbPeerDomainName(classes.textBreadCrumbsOnHoverPeerDomainName);
      }
    }
  }

  const buildRequestObjEditPeer = (_peerName, _peerDomainName) => {
    var formData = {};
    formData["peerName"] = _peerName;
    formData["peerDomainName"] = _peerDomainName;
    return formData;
  };

  const handleSubmitPeerDetails = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setBackdrop(true);
    let requestObj = buildRequestObjEditPeer(editPeerName, editPeerDomainName);
    if (!requestObj) return;
    organizationService.updatePeer(localStorage.getItem("_orgID"), editPeerRowId, requestObj).then(
      response => {
        notify({
          type: "success",
          message: "Successfully updated Peer"
        });
        setEditPeerRowId(null)
        setBackdrop(false);
        fetchOrganizationData();
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while Updating Peer"
        });
      }
    );
  }

  const handleClearPeerDetails = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setEditPeerRowId(null)
    setEditPeerName(editPeerNameCopy)
    setEditPeerDomainName(editPeerDomainNameCopy)
  }

  const handleMouseEnterOrganizationRowOnClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const handleClosePopupDiscard = () => {
    setPopupOpen(false);
    let row = newEditPeerRow;
    let prop = newProp;
    let eventTargetValue = newEventTargetValue
    setEditPeerRow(row)
    setEditPeerRowId(row["id"])
    setFocusedPeerColumn(prop)

    setEditPeerName(row["peerName"])
    setEditPeerNameCopy(row["peerName"])
    setEditPeerDomainName(row["peerDomainName"])
    setEditPeerDomainNameCopy(row["peerDomainName"])
    if (prop === "peerName") {
      setEditPeerName(eventTargetValue)
    } else if (prop === "peerDomainName") {
      setEditPeerDomainName(eventTargetValue)
    }
    // setBreadCrumbOrganizationDescriptionFocus(true);
    setTextBreadCrumbPeerName(classes.textBreadCrumbsOnHoverPeerName);
    setTextBreadCrumbPeerDomainName(classes.textBreadCrumbsOnHoverPeerDomainName);
  }

  const handleClosePopupDoNotDiscard = () => {
    setPopupOpen(false);
  }


  const handleChangeOrganizationDescription = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      setOrganizationDescription(event.target.value);
    }

    setBreadCrumbOrganizationDescriptionFocus(true);
  }

  const handleChangeOrganizationDomainName = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      setOrganizationDomainName(event.target.value);
    }
    setBreadCrumbOrganizationDomainNameFocus(true);
  }

  const handleOrganizationNameSave = (event) => {
    if (!invalidUserInput.includes("organizationName")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditOrganization(organizationName, organizationDescriptionCopy, organizationDomainNameCopy);
    }
  }

  const handleOrganizationDescriptionSave = (event) => {
    if (!invalidUserInput.includes("organizationDescription")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditOrganization(organizationNameCopy, organizationDescription, organizationDomainNameCopy);
    }
  }

  const handleOrganizationDomainNameSave = (event) => {
    if (!invalidUserInput.includes("organizationDomainName")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditOrganization(organizationNameCopy, organizationDescriptionCopy, organizationDomainName);
    }
  }

  const handleBreadCrumbUndoOrganizationName = () => {
    setOrganizationName(organizationNameCopy);
    setBreadCrumbOrganizationNameFocus(false);
    setTextBreadCrumbsOrganizationName(classes.textBreadCrumbsOrganizationName);
  }

  const handleBreadCrumbUndoOrganizationDescription = () => {
    setOrganizationDescription(organizationDescriptionCopy);
    setBreadCrumbOrganizationDescriptionFocus(false);
    setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsDesc);
  }

  const handleBreadCrumbUndoOrganizationDomainName = () => {
    setOrganizationDomainName(organizationDomainNameCopy);
    setBreadCrumbOrganizationDomainNameFocus(false);
    setTextBreadCrumbsOrganizationDomainName(classes.textBreadCrumbsDesc);
  }

  const validateFields = (...fields) => {
    let errorFields = [];
    fields.forEach(field => {
      if (!field) {
        errorFields.push(field);
      }
    });
    return errorFields;
  };

  const buildRequestObjEditOrganization = (_organizationName, _organizationDescription, _organizationDomainName) => {
    let invalidFields = [];
    invalidFields = validateFields(_organizationName);

    if (invalidFields.length) {
      setBackdrop(false);
      notify({
        type: "error",
        message:
          "Invalid Fields(" +
          invalidFields.length +
          "): Please provide valid input."
      });
      return;
    }
    var formData = {};
    formData["organizationName"] = _organizationName;
    formData["organizationDescription"] = _organizationDescription;
    formData["organizationDomainName"] = _organizationDomainName;
    // formData["organizationId"] = editOrganizationValues.organizationId;
    formData["orgRole"] = localStorage.getItem("_orgRole")
    formData["userRole"] = localStorage.getItem("_userRole")
    return formData;
  };

  function handleSubmitEditOrganization(_organizationName, _organizationDescription, _organizationDomainName) {
    setBackdrop(true);
    let requestObj = buildRequestObjEditOrganization(_organizationName, _organizationDescription, _organizationDomainName);
    if (!requestObj) return;
    organizationService.updateOrganization(localStorage.getItem("_orgID"), requestObj).then(
      response => {
        if (_organizationName !== organizationNameCopy) {
          setTextBreadCrumbsOrganizationName(classes.textBreadCrumbsOrganizationName);
          localStorage.setItem("_orgName", _organizationName);
          setOrganizationName(_organizationName);
          setOrganizationNameCopy(_organizationName);
        } else if (_organizationDescription !== organizationDescriptionCopy) {
          setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsDesc);
          setOrganizationDescription(_organizationDescription);
          setOrganizationDescriptionCopy(_organizationDescription);
        } else if (_organizationDomainName !== organizationDomainNameCopy) {
          setTextBreadCrumbsOrganizationDomainName(classes.textBreadCrumbsDesc);
          localStorage.setItem("_orgDomain", _organizationDomainName);
          setOrganizationDomainName(_organizationDomainName);
          setOrganizationDomainNameCopy(_organizationDomainName);
        }
        notify({
          type: "success",
          message: "Successfully updated organization"
        });
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while Updating organization"
        });
      }
    );
  };

  const openDrawer = (side, open, peer) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setBackdrop(true);

    chaincodeService.getInstalledChaincodesByPeerId(peer).then(
      response => {
        let _data = response.data;
        setBackdrop(false);
        setChaincodes(_data);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching Policy"
        });
      }
    );

    setDrawerState({ ...drawerState, [side]: open });
  };

  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
  };

  const handleSetInitialRow = () => {
    setRows(originalRows);
    setQueryCount(originalqueryCount);
  }

  const handleSetInitialNetworkRow = () => {
    setNetworkData(networkOriginalData);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(+event.target.value, 0);
  };

  const handleRequestSort = property => event => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    fetchData(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value);
  };

  /* TODO Change the view from JSON to table */
  const sideList = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Installed Chaincodes"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {chaincodes != null && Object.keys(chaincodes).length !== 0 && typeof chaincodes === 'object' ? (
            chaincodes.map((chaincode) => {
              return (
                <TableRow style={{ height: "60px" }} key="id">
                  <TableCell component="th" scope="row">
                    <b>{chaincode.name}</b>
                  </TableCell>
                  <TableCell align="left">Version : {chaincode.version} </TableCell>
                </TableRow>
              );
            })
          ) : (
              ""
            )}
        </TableBody>
      </Table>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>

  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    if (newValue === 0 && !rows.length) {
      fetchData();
    }
    if (newValue === 1 && !networkData.length) {
      fetchNetworkData();
    }
    if (newValue === 2) {
      setOnClickUsersTab(true)
    }
    if (newValue === 3) {

    }
    setTab(newValue);
  };

  const fetchOrganizationData = () => {

    setBackdrop(true);
    let orgId = localStorage.getItem("_orgID");
    organizationService.getOrgsByOrgId(orgId).then(
      response => {
        const _data = response.data;
        setRows(_data.peers)
        setOriginalRows(_data.peers)
        setQueryCount(_data.peers.length)
        setOrganizationName(_data.organizationName)
        setOrganizationDomainName(_data.organizationDomainName)
        setOrganizationDomainNameCopy(_data.organizationDomainName)
        setOrganizationDescription(_data.organizationDescription)
        setOrganizationDescriptionCopy(_data.organizationDescription)
        setOriginalQueryCount(_data.peers.length)
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching organization data"
        });
      }
    );

  };

  const fetchData = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = "") => {

    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder
    }
    if (searchText) {
      query['q'] = searchText;
    }
    setSearchKey({ value: searchText });

    setBackdrop(true);

    let orgId = localStorage.getItem("_orgID")
    organizationService.getPeersByOrgId(orgId, query).then(
      response => {
        let _data = response.data;

        setRows(_data);
        if (response.headers.hasOwnProperty('x-total-count')) {
          setQueryCount(parseInt(response.headers['x-total-count']));
        }

        if (!searchText) {
          setOriginalRows(_data);
          setOriginalQueryCount(parseInt(response.headers['x-total-count']));
        }

        setBackdrop(false);
      },
      error => {

        setBackdrop(false);

        if (searchText) {
          setRows([]);
        }
        else {
          notify({
            type: "error",
            message: "Error: " + error.response.data.errorCode
          });
        }
      }
    );
  };

  const fetchNetworkData = (searchText = "") => {

    let query = {};
    if (searchText) {
      query['q'] = searchText;
    }
    setNetworkSearchKey({ value: searchText });

    setBackdrop(true);
    NetworkService.getNetworks(query).then(response => {

      let _data = response.data;
      setNetworkData(_data);

      if (!searchText) {
        setNetworkOriginalData(_data);
      }
      setBackdrop(false);

    },
      error => {

        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching Networks"
        });

      });
  };

  React.useEffect(() => {
    if (tab === 0) {
      fetchOrganizationData();
    }
    if (tab === 1) {
      fetchNetworkData();
    }
  }, []);

  const updateAdminEnrolled = () => {
    setIsAdminEnrolled(true);
  };

  return (
    <div className={classes.borderMain}>
      <Paper style={{ minHeight: "88vh" }}>
        {
          !(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN") ?
            <div>
              <div className={classes.navigation}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.orgInfo}>
                  <Typography style={{ marginLeft: "10px", fontWeight: "700" }}>
                    {organizationName}
                  </Typography>
                </Breadcrumbs>

              </div>

              <div className={classes.orgDescriptionDiv}>
                <div className={classes.orgDescription}>{organizationDescription}</div>
                <div>{organizationDomainName}</div>
              </div>
            </div>
            :
            <div>
              <div className={classes.navigation}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.organizationBreadCrumb}>
                  <div className={classes.textBreadCrumbDiv}>
                    <input
                      id="standard-basic"
                      className={textBreadCrumbsOrganizationName}
                      error={invalidUserInput.includes("organizationName")}
                      margin="normal"
                      rows="2"
                      disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                      autoComplete='off'
                      value={organizationName}
                      onMouseEnter={handleMouseEnterOrganizationName}
                      onMouseLeave={handleMouseLeaveOrganizationName}
                      onChange={handleChangeOrganizationName("organizationName")} />
                  </div>
                </Breadcrumbs>
                <div className={(breadCrumbOrganizationNameFocus && (organizationName != organizationNameCopy)) ? classes.textBreadCrumbButtonsDiv : classes.textBreadCrumbButtonsDivNone}>
                  <IconButton className={classes.breadCrumbButton} onClick={handleOrganizationNameSave}>
                    <DoneIcon fontSize="small" />
                  </IconButton>
                  <IconButton className={classes.breadCrumbButton} onClick={handleBreadCrumbUndoOrganizationName}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </div>

              <div className={classes.orgDescriptionDiv}>
                <div className={classes.navigation}>
                  <Breadcrumbs aria-label="breadcrumb" className={classes.organizationBreadCrumbDesc}>
                    <div className={classes.textBreadCrumbDiv}>
                      <input
                        id="standard-basic"
                        className={textBreadCrumbsOrganizationDescription}
                        error={invalidUserInput.includes("organizationDescription")}
                        margin="normal"
                        rows="2"
                        disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                        autoComplete='off'
                        value={organizationDescription}
                        onMouseEnter={handleMouseEnterOrganizationDescription}
                        onMouseLeave={handleMouseLeaveOrganizationDescription}
                        onChange={handleChangeOrganizationDescription("organizationDescription")} />
                    </div>
                  </Breadcrumbs>
                  <div className={(breadCrumbOrganizationDescriptionFocus && (organizationDescription !== organizationDescriptionCopy)) ? classes.textBreadCrumbButtonsDivDesc : classes.textBreadCrumbButtonsDivNone}>
                    <IconButton className={classes.breadCrumbButton} onClick={handleOrganizationDescriptionSave}>
                      <DoneIcon fontSize="small" />
                    </IconButton>
                    <IconButton className={classes.breadCrumbButton} onClick={handleBreadCrumbUndoOrganizationDescription}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
                <div className={classes.navigation}>
                  <Breadcrumbs aria-label="breadcrumb" className={classes.organizationBreadCrumbDesc}>
                    <div className={classes.textBreadCrumbDiv}>
                      <input
                        id="standard-basic"
                        className={textBreadCrumbsOrganizationDomainName}
                        error={invalidUserInput.includes("organizationDomainName")}
                        margin="normal"
                        rows="2"
                        disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                        autoComplete='off'
                        value={organizationDomainName}
                        onMouseEnter={handleMouseEnterOrganizationDomainName}
                        onMouseLeave={handleMouseLeaveOrganizationDomainName}
                        onChange={handleChangeOrganizationDomainName("organizationDomainName")} />
                    </div>
                  </Breadcrumbs>
                  <div className={(breadCrumbOrganizationDomainNameFocus && (organizationDomainName !== organizationDomainNameCopy)) ? classes.textBreadCrumbButtonsDivDesc : classes.textBreadCrumbButtonsDivNone}>
                    <IconButton className={classes.breadCrumbButton} onClick={handleOrganizationDomainNameSave}>
                      <DoneIcon fontSize="small" />
                    </IconButton>
                    <IconButton className={classes.breadCrumbButton} onClick={handleBreadCrumbUndoOrganizationDomainName}>
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
        }
        <div className={classes.contentDiv}>
          {
            tab === 0 ?
              <div className={classes.tableHeadRight}>
                <SearchOrgPeers searchKey={searchKey} fetchData={fetchData} rowsPerPage={rowsPerPage} page={page} orderBy={orderBy} order={order} handleSetInitialRow={handleSetInitialRow} />
              </div>
              :
              tab === 1 ?
                <div className={classes.tableHeadRight}>
                  <SearchNetworks searchKey={networkSearchKey} fetchData={fetchNetworkData} handleSetInitialRow={handleSetInitialNetworkRow} />
                </div>
                :
                ""
          }
          <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" className={classes.tabsStyling}>
            <Tab label="Peers" {...a11yProps(0)} className={classes.tabStyling} />
            <Tab label="Networks" {...a11yProps(1)} className={classes.tabStyling} />
            <Tab label="Users" {...a11yProps(2)} className={classes.tabStyling} />
            <Tab label="My Settings" {...a11yProps(3)} className={classes.tabStyling} />
          </Tabs>

          <TabPanel value={tab} index={0}>
            <Grid item xs={12} >
              <div className={classes.tableWrapper}>
                <Paper className={classes.tableContainer}>
                  <Table stickyHeader aria-label="sticky table" className={classes.tableBorder} >

                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={classes.tableHeadRow}
                            sortDirection={orderBy === column.id ? order : false}
                          >
                            <div className={classes.tableHeadAlign}>
                              {column.sortable ? (
                                <TableSortLabel
                                  active={orderBy === column.id}
                                  direction={order}
                                  onClick={handleRequestSort(column.id)}
                                >
                                  {column.label}
                                  {orderBy === column.id ? (
                                    <span className={classes.visuallyHidden}>
                                      {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                    </span>
                                  ) : null}
                                </TableSortLabel>
                              ) : (
                                  column.label
                                )}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {rows.length === 0 ?
                      <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                        {backdrop ? " Fetching the peers, please wait" : "No Peers found"}
                      </TableCell>
                      :
                      !(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN") ?
                        <TableBody>
                          {rows.length === 0 ?
                            "" :
                            rows.map((row, index) => {
                              return (
                                <TableRow style={{ cursor: "pointer", height: "60px" }} hover role="data-row" tabIndex={-1} key={index} onClick={openDrawer("right", true, row.id)} >
                                  {columns.map(column => {
                                    const value = row[column.id];
                                    return (
                                      <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                        :
                        <TableBody>
                          {rows.length === 0 ?
                            "" :
                            rows.map((row, index) => {
                              return (
                                <TableRow style={{ cursor: "pointer", height: "60px" }} hover role="data-row" tabIndex={-1} key={index} onClick={openDrawer("right", true, row.id)}>
                                  {columns.map(column => {
                                    const value = row[column.id];
                                    if (column.id === "peerPort") {
                                      return (
                                        <TableCell key={column.id} align={column.align}>
                                          {
                                            editPeerRowId === row["id"] ?
                                              <div className={classes.editRow}>
                                                <div style={{ paddingTop: '5px' }}>{column.format && typeof value === "number" ? (column.format(value)) : (value)}</div>
                                                <Button onClick={handleSubmitPeerDetails} style={{ marginLeft: '55px' }}>
                                                  <DoneIcon />
                                                </Button>
                                                <Button onClick={handleClearPeerDetails}>
                                                  <CloseIcon />
                                                </Button>
                                              </div>
                                              :
                                              <div className={classes.editRow}>
                                                <div style={{ paddingTop: '5px' }}>{column.format && typeof value === "number" ? (column.format(value)) : (value)}</div>
                                                <Button style={{ opacity: 0, marginLeft: '55px' }}>
                                                  <DoneIcon />
                                                </Button>
                                                <Button style={{ opacity: 0 }}>
                                                  <CloseIcon />
                                                </Button>
                                              </div>
                                          }
                                        </TableCell>
                                      );
                                    } else {
                                      return (
                                        <TableCell key={column.id} align={column.align}>
                                          <Breadcrumbs aria-label="breadcrumb">
                                            <div>
                                              <input
                                                id="standard-basic"
                                                className={editPeerRowId === row["id"] ? column.id === "peerName" ? textBreadCrumbsPeerName : column.id === "peerDomainName" ? textBreadCrumbsPeerDomainName : "" : column.id === "peerName" ? classes.textBreadCrumbsPeerNameDefault : column.id === "peerDomainName" ? classes.textBreadCrumbsPeerDomainNameDefault : ""}
                                                margin="normal"
                                                rows="2"
                                                autoFocus={editPeerRowId === row["id"] && focusedPeerColumn === column.id}
                                                disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                autoComplete='off'
                                                value={editPeerRowId === row["id"] ? column.id === "peerName" ? editPeerName : column.id === "peerDomainName" ? editPeerDomainName : "" : column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                                onMouseEnter={handleMouseEnterPeerRow}
                                                onMouseLeave={handleMouseLeavePeerRow}
                                                onChange={handleChangePeerRow(row, column.id)}
                                                onClick={handleMouseEnterOrganizationRowOnClick} />
                                            </div>
                                          </Breadcrumbs>
                                        </TableCell>
                                      );
                                    }
                                  })}
                                </TableRow>
                              );
                            })
                          }
                        </TableBody>
                    }

                  </Table>

                </Paper>
              </div>
              {rows.length === 0 ?
                ""
                :
                <TablePagination
                  rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                  component="div"
                  count={queryCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "previous page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page"
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={"Rows per page"}
                />
              }
            </Grid>
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <Grid item xs={12} >
              <div className={classes.paperCard}>

                {networkData != null && Object.keys(networkData).length ? (
                  networkData.map((network) => {
                    return (
                      <Card key={network.networkName} className={classes.card}>
                        <CardContent>
                          <CardHeader
                            title={network.networkName}
                          />
                          <Divider />
                          <CardContent>
                            <Typography variant="body1">
                              Network Info : {network.networkDescription}
                            </Typography>
                          </CardContent>
                        </CardContent>
                      </Card>
                    );
                  })
                ) : (
                    <div style={{ margin: "15px" }}>{backdrop ? "Fetching networks" : "No Networks"}</div>
                  )}

                <Drawer
                  anchor="right"
                  open={drawerState.right}
                  onOpen={closeDrawer('right', true)}
                  onClose={closeDrawer('right', true)}
                >
                  {sideList('right')}
                </Drawer>
              </div>
            </Grid>
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <Grid item xs={12} >
              {/* <OrganizationUsers updateAdminEnrolled={updateAdminEnrolled} cameFrom="myOrganization" /> */}
            </Grid>
          </TabPanel>
          {
            tab === 2 ?
              <OrganizationUsers updateAdminEnrolled={updateAdminEnrolled} cameFrom="myOrganization" />
              :
              ""
          }

        <TabPanel value={tab} index={3}>
            {/* <MySettings/> */}
          </TabPanel>
          {
            tab === 3 ?
            <MySettings />
              :
              ""
          }

        </div>
        <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Drawer
          anchor="right"
          open={drawerState.right}
          onOpen={closeDrawer('right', true)}
          onClose={closeDrawer('right', true)}
        >
          {sideList('right')}
        </Drawer>

      </Paper>
      <Dialog
        open={popupOpen}
        onClose={handleClosePopupDoNotDiscard}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle} gutterBottom>
            {`Discard details`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
            You are about to discard other modified Peer details
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopupDiscard} className={classes.dialogButton} color="primary">
            PROCEED
          </Button>
          <Button onClick={handleClosePopupDoNotDiscard} className={classes.dialogButton} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
