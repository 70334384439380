import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

const useStyles = makeStyles({
    list: {
        width: "60vw",
        display: "block"
    },
    eventSideList: {
        width: "30vw",
    },
    root: {
        width: "100%",
        background: "white",
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier"
    },
    cardHeaderBlock: {
        padding: "5px",
        border: "1px dotted #173a64",
        background: "#f8f8f8"
    },
    titleBlock: {
        fontSize: "15px",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    filtersDivRight: {
        float: "right",
        paddingRight: "0.75rem"
    },
    filterTextField: {
        width: "20vw",
        paddingRight: theme.spacing(2),
        marginTop: "-1vw",
    },
    filterTextFieldIsRequiredDoc: {
        width: "20vw",
        marginTop: "-1.5em",
    },
    clearFilterButton: {
        marginTop: "-0.4vw",
        marginLeft: "0.5vw"
    },
    clearFilterButtonIsRequiredDoc: {
        marginTop: "-0.5em",
        marginLeft: "0.5vw"
    },
    tableHeadRightFilter: {
        float: "right"
    },
    searchBox: {
        borderBottom: "0.8px solid #00000021",
        height: theme.spacing(5.5),

    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        marginRight: theme.spacing(2),
        marginTop: "15px",
        // marginBottom:theme.spacing(5),
        // marginTop:theme.spacing(2)
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        //margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    button: {
        margin: theme.spacing(1),
    },
    buttonMap: {
        float: "right"
    },
    a: {
        margin: theme.spacing(1),
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: '#E5E7E9'
        }
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",
    },
    disableHoverIcon: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }
    },
    docFormDiv: {
        display: "block",
        overflow: "auto",
        maxHeight: "85vh"
    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    sidedrawerFooterJSON: {
        width: "100%",
        backgroundColor: "#ffffff",
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    channelInfoPara: {
        padding: "15px",
    },
    shipmentDataPara: {
        padding: "0px 10px 0px"
    },

    tableBorder: {
        border: "0.8px solid #00000021"
    },
    tableBorderProducts: {
        border: "1.5px solid #00000021"
    },
    iconAlign: {
        verticalAlign: "middle"

    },
    dividerStyling: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    tabStyling: {
        paddingLeft: theme.spacing(4),

    },
    tableContainer: {
        padding: "20px"
    },
    tableColumnLabel: {
        marginLeft: theme.spacing(3),
    },
    shipmentInfo: {
        padding: "10px",
        paddingLeft: "25px",
        paddingRight: "20px"
    },
    shipmentRefNumLabel: {
        background: "#f8f8f8",
        padding: "8px 30px 8px",
        margin: "8px 0px 24px"
    },
    shipmentInfopara: {
        background: "#f8f8f8",
        padding: "40px 20px 40px 20px"
    },

    paper_root: {
        alignItems: 'center',
        display: 'flex',
        '& > *': {
            marginLeft: "1%",
            marginRight: "2%",
            width: theme.spacing(20),
            height: theme.spacing(12),
        },
    },

    head_three: {
        alignContent: 'center'
    },
    noRecordsRow: {
        background: "#ffffff"
    },
    tableHeadRow: {
        fontWeight: "700"
    },
    buttonDownload: {
        background: "none",
        padding: 0,
        fontSize: "68.75%",
        fontWeight: "bold"
    },
    hsixstyle: {
        display: "initial",
        fontSize: "0.9em",
        marginBlockStart: "0",
        marginBlockEnd: "0"
    },
    card: {
        cursor: "pointer"
    },
    row_pointer: {
        cursor: "pointer"
    },
    hfivestyle: {
        fontSize: "0.8em",
        marginBlockStart: "0",
        marginBlockEnd: "0",
        fontWeight: "200"
    },
    noMargin: {
        margin: '0px'
    },
    smallText: {
        fontSize: 'small',
        color: 'gray'
    },
    smallTextIcon: {
        fontSize: 'small',
        color: 'gray',
        marginBottom: "-2px"
    },
    boldText: {
        fontWeight: 'bold',
        color: 'black'
    },
    cardContent: {
        marginLeft: "- 13px",
        marginTop: "-13px",
        cursor: 'pointer',
    },
    anchorLink: {
        color: '#173a64',
        cursor: 'pointer'
    },
    printIcon: {
        verticalAlign: 'bottom'
    },
    typography: {
        padding: theme.spacing(2),
    },
    filtersDiv: {
        width: "100%",
        display: "inline-block",
        paddingLeft: theme.spacing(2)
    },
    reqDocumentsDialog: {
        '& .MuiDialog-paperWidthSm': {
            width: theme.spacing(45)
        }
    },
    reqDocumentsButton: {
        float: "left"
    },
    treeViewDiv: {
        paddingBottom: theme.spacing(1.5)
    },
    treeViewCollapsableTabs: {
        background: "#ffffff",
        border: "0.5px solid #00000021",
        fontWeight: "bold",
        borderRadius: "2px",
        boxShadow: "2px 2px 10px -5px",
        height: theme.spacing(7.5)
    },
    treeViewListItemText: {
        marginLeft: "-55px",
        fontWeight: "700",
    },
    tableFooterDiv: {
        display: "flex"
    },
    tableFooterNoteDiv: {
        width: "100%",
        paddingLeft: "1.5rem",
        marginTop: "0.25rem"
    },
    tablePaginationDiv: {
        marginTop: "-0.75rem",
        width: "100%",
        justifyContent: "flex-end"
    },
    missingDocTypeDiv: {
        display: "flex",
        justifyContent: "center"
    },
    documentNameDiv: {
        display: "flex",
        justifyContent: "center"
    },
    privateDocTooltipDiv: {
        paddingTop: theme.spacing(0.25),
        paddingLeft: theme.spacing(0.5)
    },
    docRevisionRow: {
        display: "flex",
        justifyContent: "space-around"
    },
    privateMemberList: {
        paddingTop: theme.spacing(1)
    },
    memberListTitle: {
        fontSize: "1rem",
        fontWeight: "700"
    },
    memberListItem: {

    },
    memberListItemText: {

    },
    orgMemberDocPageDiv: {
        width: "100%",
        marginTop: theme.spacing(2)
    },
    orgMemberFormControl: {
        width: "100%",
    },
    orgMembersSelect: {

    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    addMemberButtonDiv: {
        justifyContent: "flex-end",
        paddingTop: theme.spacing(1)
    },
    addDocButtonTooltip: {
        float: "right"
    },
    addButton: {
        fontFamily: "timesnewroman",
        textAlign: "center",
        display: "flex",
    },
    previewButton: {
        display: 'flex',
        marginLeft: 'auto'
    },
    buttonPreview: {
        background: "#D3D3D3",
        padding: 0,
        fontSize: "11px",
        float: "right",
        marginLeft: "10px",
        marginTop: "0.75rem"
    },
    actionDiv: {
        display: "flex",
        justifyContent: "center"
    },
    memberListTitle: {
        fontSize: "0.875rem",
        fontWeight: "700"
    },
    memberListItem: {

    },
    memberListItemIcon: {
        color: "#000000",
        display: 'inline-flex',
        minWidth: theme.spacing(4)
    },
    memberListItemText: {
        fontSize: "0.875rem"
    },
    memberListItemTextAttachedContainers: {
        fontSize: "0.875rem",
        textAlign: "center"
    },
    attachedContainers: {
        padding: theme.spacing(1)
    }
});

export default useStyles;