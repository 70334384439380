import axios from "axios";
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

axios.defaults.withCredentials = true;
function uploadChaincode(requestObj) {
    return axios.post(BASE_API_URL + `/admin/chaincodes`, requestObj, {
        headers: {
            'Content-type': 'multipart/form-data'
        }
    });
}


// Add token in all requests
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + authUtil.getToken();

//TODO: update once api is ready, add user context
function getAllChaincodes() {
    return axios.get(BASE_API_URL + `/admin/chaincodes`, {
    });
}

//TODO: update once api is ready, add user context
function getUnInstalledPeers(orgId) {

    // TODO :remove below lines
    const response = {
        data: [
            { 'peerName': 'peer4', 'id': 'peer4' },
            { 'peerName': 'peer5', 'id': 'peer5' },
        ]
    };
    const error = { response: { data: { errorCode: 500 } }, message: 'Internal server error' };

    //TODO: remove below promise once the backend api is ready
    return new Promise((resolve, reject) => {
        resolve(response);
    });
}

//TODO: update once api is ready, add user context
function getUnInstChannels(peer) {

    const reqObj = { peer: peer };
    // TODO :remove below lines
    const response = {
        data: ['channel4', 'channel5', 'channel6']
    };
    const error = { response: { data: { errorCode: 500 } }, message: 'Internal server error' };
    //TODO: remove below promise once the backend api is ready
    return new Promise((resolve, reject) => {
        resolve(response);
    });
}

//TODO: update once api is ready, add user context
function getPeersByChannel(orgId, channelName) {
    let peers = JSON.parse(localStorage.getItem('_peers'));
    let data = [];
    for (let i = 0; i < peers.length; i++) {
        data.push(peers[i]["peerName"]);
    }
    // TODO :remove below lines
    const response = {
        data: peers
    };
    const error = { response: { data: { errorCode: 500 } }, message: 'Internal server error' };
    //TODO: remove below promise once the backend api is ready
    return new Promise((resolve, reject) => {
        resolve(response);
    });
}

function getEndorsementPolicies(noOfMembers) {

    const _data = []
    for (var i = 1; i <= noOfMembers; i++) {
        _data.push({
            name: 'Policy-' + i + '-Of',
            nOf: i
        })
    }

    // TODO: this has to be made dynamic once we got the working solution for flexible endorsement policies
    const response = {
        data: _data
    };

    //TODO: remove below promise once the backend api is ready
    return new Promise((resolve, reject) => {
        resolve(response);
    });
}

function installChainCode(data) {
    let peerArray = [];

    if (data.peers) {
        for (let i = 0; i < data.peers.length; i++) {
            peerArray.push(data.peers[i].id);
        }
    }

    const requestObj = {
        peers: peerArray,
        chaincodeVersion: data.version
    };
    let ccId = data.id;
    return axios.post(BASE_API_URL + `/admin/chaincodes/` + ccId + `/install`, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });

}

function instantiateChainCode(data, orgDomain) {

    let peerArray = [];
    for (var i = 0; i < data.peers.length; i++) {
        peerArray.push(data.peers[i].id);
    }
    const requestObj = {
        endorsementPolicy: data.policy,
        peers: peerArray,
        chaincodeVersion: data.version,
        channelId: data.channelId,
        args: []
    };
    let ccId = data.id;
    return axios.post(BASE_API_URL + `/admin/chaincodes/` + ccId + `/instantiate`, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });

}


function getInstalledChaincodesByPeerId(peer) {
    return axios.get(BASE_API_URL + `/admin/chaincodes/peer/` + peer + `/installedChaincodes`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getInstantiatedChainCodes(peer, channelId) {
    return axios.get(BASE_API_URL + `/channels/` + channelId + `/instantiatedChaincodes`, {
        params: {
            peer: peer
        },
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getCommittedChaincodesByChannelId(channelId) {
    return axios.get(BASE_API_URL + `/admin/chaincodes/channel/` + channelId + `/committedChaincodes`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getTransactionById(channelId, txId) {
    let query = {};
    query['channelId'] = channelId;
    query['trxnId'] = txId;
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/chaincodes/transactionById` + queryParams, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getEventById(shipmentId, eventId) {
    return axios.get(BASE_API_URL + `/shipments/` + shipmentId + `/events/` + eventId, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getEventByIdV2(trackingShipmentId, eventId) {
    return axios.get(BASE_API_URL + `/bookings/trackingShipments/` + trackingShipmentId + `/events/` + eventId, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getInstalledOnPeers(chaincodeId, version) {
    return axios.get(BASE_API_URL + `/admin/chaincodes/` + chaincodeId + `/version/` + version + `/peers`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getInstantiatedOnChannels(chaincodeId, version) {
    return axios.get(BASE_API_URL + `/chaincodes/` + chaincodeId + `/version/` + version + `/channels`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getChannelsByCommittedChaincodeId(chaincodeVersionId) {
    return axios.get(BASE_API_URL + `/chaincodes/chaincodeversion/${chaincodeVersionId}` + `/committed/channels`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getAvailableSystemChaincodes() {
    return axios.get(BASE_API_URL + `/admin/chaincodes/systemchaincodes`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getEndorsementPolicyByChaincodeVersionId(chaincodeVersionId, channelId, lastCommited = false, sequence = null) {
    let query = {};
    query['lastCommited'] = lastCommited;
    if (sequence != null) {
        query['sequence'] = sequence;
    }
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/chaincodes/${chaincodeVersionId}/endorsementPolicy/${channelId}` + queryParams, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getEndorsementPolicyByChaincodeName(chaincodeName, channelId, lastCommited = false, sequence = null) {
    let query = {};
    query['lastCommited'] = lastCommited;
    if (sequence != null) {
        query['sequence'] = sequence;
    }
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/chaincodes/name/${chaincodeName}/endorsementPolicy/${channelId}` + queryParams, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getCurrentChaincodeSequence(chaincodeId, channelId) {
    return axios.get(BASE_API_URL + `/admin/chaincodes/${chaincodeId}/channel/${channelId}/sequence`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function approveChaincode(chaincodeId, requestObj) {
    return axios.post(BASE_API_URL + `/admin/chaincodes/` + chaincodeId + `/approve`, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function commitChaincode(chaincodeVersionId, requestObj) {
    return axios.post(BASE_API_URL + `/admin/chaincodes/` + chaincodeVersionId + `/commit`, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function getApprovedOrganizationsByChaincodeVersionId(chaincodeVersionId, channelId, sequence = null) {
    let query = {};
    if (sequence != null) {
        query['sequence'] = sequence;
    }
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/chaincodes/${chaincodeVersionId}/approvedOrganization/${channelId}` + queryParams, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getDistinctChaincodes() {
    return axios.get(BASE_API_URL + `/admin/chaincodes/distinct`, {
    });
}

function getBlockByTxId(channelId, txId) {
    let query = {};
    query['channelId'] = channelId;
    query['trxnId'] = txId;
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/chaincodes/block/transactionById` + queryParams, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getChaincodeVersionsById(chaincodeId) {
    return axios.get(BASE_API_URL + `/admin/chaincodes/` + chaincodeId + `/versions`, {
    });
}

function getCommittedEndorsementPoliciesByChaincodeVersionId(chaincodeVersionId, channelId) {

    return axios.get(BASE_API_URL + `/admin/chaincodes/${chaincodeVersionId}/committedPolicy/${channelId}`, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

export default {
    getAllChaincodes,
    getUnInstalledPeers,
    getUnInstChannels,
    getPeersByChannel,
    installChainCode,
    instantiateChainCode,
    getEndorsementPolicies,
    getInstalledChaincodesByPeerId,
    getInstantiatedChainCodes,
    getCommittedChaincodesByChannelId,
    getTransactionById,
    uploadChaincode,
    getInstalledOnPeers,
    getInstantiatedOnChannels,
    getEventById,
    getEventByIdV2,
    getAvailableSystemChaincodes,
    approveChaincode,
    getEndorsementPolicyByChaincodeVersionId,
    getCurrentChaincodeSequence,
    commitChaincode,
    getApprovedOrganizationsByChaincodeVersionId,
    getChannelsByCommittedChaincodeId,
    getEndorsementPolicyByChaincodeName,
    getDistinctChaincodes,
    getBlockByTxId,
    getChaincodeVersionsById,
    getCommittedEndorsementPoliciesByChaincodeVersionId
};