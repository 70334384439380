"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoaderStyles = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    .container {\n        align-items: center;\n        display: flex;\n        flex-direction: row;\n        height: 100%;\n        justify-content: center;\n        width: 100%;\n    }\n\n    .loader {\n        background-image: url('../assets/spinner-primary-large.png');\n        background-repeat: no-repeat;\n        display: inline-block;\n        height: 96px;\n        width: 96px;\n        animation: rotating 2s linear infinite;\n    }\n\n    @keyframes rotating {\n        from {\n            transform: rotate(0deg);\n        }\n\n        to {\n            transform: rotate(360deg);\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var LoaderStyles = _styledComponents.default.span(_templateObject());

exports.LoaderStyles = LoaderStyles;