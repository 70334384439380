import React, { useState } from "react";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from "@material-ui/core/Grid";
import '../../css/style.css';
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Drawer from '@material-ui/core/Drawer';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import organizationService from "../../services/OrganizationService";
import notify from '../../utils/notifier';
import { MenuItem } from "@material-ui/core";
import UserManagementService from "../../services/UserManagementService"
import { useStyles } from "./styles/UserManagementStyles";
import { EMAIL_REGEX, VALID_USER_INPUT_REGEX } from "../../utils/constants";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DoneIcon from '@material-ui/icons/Done';

//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};

export const _userColumns = [
    { id: "firstName", label: "First Name", align: 'left', minWidth: 100, sortable: true },
    { id: "lastName", label: "Last Name", align: 'left', minWidth: 100, sortable: true },
    { id: "userEmail", label: "Email", align: 'center', minWidth: 170, sortable: true },
    { id: "organizationName", label: "Organization", align: 'center', minWidth: 170, sortable: true },
    { id: "role", label: "Role Type", align: 'center', minWidth: 100, sortable: true },
    { id: "isActive", label: "Active", align: 'center', minWidth: 100, sortable: true },
    { id: "editUser", label: "Reset Password", align: 'left', minWidth: 100, sortable: false }
];

export default function OrganizationUsers(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("firstName");
    const [rows, setRows] = React.useState([]);
    const [originalRows, setOriginalRows] = React.useState([]);
    const [searchKey, setSearchKey] = useState({ value: "" });
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [queryCount, setQueryCount] = React.useState(0);
    const [originalqueryCount, setOriginalQueryCount] = React.useState(0);

    const [popupOpenPassword, setPopupOpenPassword] = React.useState(false);
    const [updatedPassword, setUpdatedPassword] = React.useState("");

    const [checkChangePassword, setCheckChangePassword] = React.useState(false);
    const [userRoleChanged, setUserRoleChanged] = React.useState(true);
    const [firstLastNameChanged, setFirstLastNameChanged] = React.useState(false);
    const [orgData, setOrgData] = React.useState([]);
    const [formType, setFormType] = React.useState("add");
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);

    const [editUser, setEditUser] = React.useState({});

    const [popupOpen, setPopupOpen] = React.useState(false);
    const [userColumns, setUserColumns] = React.useState(_userColumns);
    const [textBreadCrumbsFirstName, setTextBreadCrumbFirstName] = React.useState(classes.textBreadCrumbFirstName);
    const [textBreadCrumbsLastName, setTextBreadCrumbLastName] = React.useState(classes.textBreadCrumbLastName);
    const [editUserRow, setEditUserRow] = React.useState({});
    const [editUserRowId, setEditUserRowId] = React.useState(null);
    const [editFirstName, setEditFirstName] = React.useState("");
    const [editFirstNameCopy, setEditFirstNameCopy] = React.useState("");
    const [editUserRole, setEditUserRole] = React.useState("");
    const [editLastName, setEditLastName] = React.useState("");
    const [editLastNameCopy, setEditLastNameCopy] = React.useState("");
    const [focusedUserColumn, setFocusedUserColumn] = React.useState("")
    const [newEditUserRow, setNewEditUserRow] = React.useState("")
    const [newProp, setNewProp] = React.useState("")
    const [newEventTargetValue, setNewEventTargetValue] = React.useState("")
    const [isActive, setIsActive] = React.useState(false);
    const [isActiveChanged, setIsActiveChanged] = React.useState(false);
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [backdrop, setBackdrop] = React.useState(false);


    const [values, setValues] = React.useState({
        userName: "",
        email: "",
        password: "",
        userOrganizationId: "",
        firstName: "",
        lastName: "",
        userRole: "",
    });

    const [editUserValues, setEditUserValues] = React.useState({
        userEmail: "",
        userRole: "",
        userId: "",
        intialUserRole: "",
        userOrgId: "",
        firstName: "",
        lastName: ""
    });

    const handleClosePopupPassword = () => {
        setPopupOpenPassword(false);
    }

    const handleMouseEnterUserRow = () => {
        setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
        setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);
    }

    const handleMouseLeaveUserRow = () => {
        if (editFirstName === editFirstNameCopy) {
            setTextBreadCrumbFirstName(classes.textBreadCrumbs);
        }
    }

    const handleChangeUserRow = (row, prop, value = false) => (event) => {
        if (editUserRowId !== null && editUserRowId !== row["id"]) {
            setNewEditUserRow(row);
            setNewProp(prop);
            setNewEventTargetValue(event.target.value);
            setPopupOpen(true);
        } else {
            setEditUserRow(row)
            setFocusedUserColumn(prop)
            if (editUserRowId === null || editUserRowId !== row["id"]) {
                setEditUserRowId(row["id"])
                setEditFirstName(row["firstName"])
                setEditFirstNameCopy(row["firstName"])
                setEditLastName(row["lastName"])
                setEditLastNameCopy(row["lastName"])
                setEditUser(row);
                setEditUserRole(row["role"])
                setFirstLastNameChanged(true);
                setCheckChangePassword(false);
                setIsActive(row["isActive"]);
                setIsActiveChanged(true);
                setEditUserValues({
                    "userEmail": row["userEmail"],
                    "firstName": row["firstName"],
                    "lastName": row["lastName"],
                    "userRole": row["role"],
                    "userId": row["id"],
                    "intialUserRole": row["role"],
                    "userOrgId": row["organizationId"],
                    "isActive": row["isActive"]
                });
            }
            if (prop === "firstName") {
                setEditFirstName(event.target.value)
            } else if (prop === "lastName") {
                setEditLastName(event.target.value)
            } else if (prop === "isActive") {
                setIsActive(value)
            }
            setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
            setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);
        }
    }

    const handleSubmitUserDetails = () => {
        setBackdrop(true);
        let userRoleChangedValue = (editUserRole === "SUPERADMIN" && editUserValues.intialUserRole === "SUPERADMIN") ? false : userRoleChanged
        UserManagementService.editUser(editUserValues.userEmail, editUserValues.userId, editUserRole, editFirstName, editLastName, firstLastNameChanged, checkChangePassword, userRoleChangedValue, editUserValues.userOrgId, localStorage.getItem("_username"), localStorage.getItem("_orgID"), localStorage.getItem("_userRole"), editUserValues.intialUserRole, isActive, isActiveChanged).then(
            response => {
                if (response && response.data) {
                    setUpdatedPassword(response.data)
                    if (checkChangePassword) {
                        setPopupOpenPassword(true);
                    }

                    if (editUserValues.userEmail == localStorage.getItem("_emailId")) {
                        localStorage.setItem('_firstName', editFirstName);
                        localStorage.setItem('_lastName', editLastName ? editLastName === "null" ? "" : editLastName : "");
                        window.location.reload();
                    }
                    setBackdrop(false);
                    let tempRows = rows
                    for (let row of tempRows) {
                        if (row["id"] === editUserValues.userId) {
                            row["firstName"] = editFirstName
                            row["lastName"] = editLastName
                            row["role"] = editUserRole
                            row["isActive"] = isActive
                        }
                    }
                    setRows(tempRows)
                    setOriginalRows(tempRows)
                    setInitialState();
                    notify({
                        type: "success",
                        message: "Successfully edited the user details"
                    });
                } else {
                    setBackdrop(false);
                    notify({
                        type: "error",
                        message: "Error while editing USER "
                    });
                }
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while editing USER "
                });
            }
        );
    }

    const handleClearUserDetails = () => {
        setEditUserRowId(null)
        setEditUserRow({})
        setEditFirstName(editFirstNameCopy)
        setEditLastName(editLastNameCopy)
    }

    const handleClosePopupDiscard = () => {
        setPopupOpen(false);
        let row = newEditUserRow;
        let prop = newProp;
        let eventTargetValue = newEventTargetValue
        if (prop === "generateNewPassword") {
            setCheckChangePassword(true);
            setEditUserRow(row)
            // if ((editFirstName.length === 0 && editLastName.length === 0)) {
            if (editUserRowId === null || editUserRowId !== row["id"]) {
                // setInitialState();
                setEditUserRowId(row["id"])
                setEditFirstName(row["firstName"])
                setEditFirstNameCopy(row["firstName"])
                setEditLastName(row["lastName"])
                setEditLastNameCopy(row["lastName"])
                setEditUser(row);
                setFirstLastNameChanged(true);
                setEditUserRole(row["role"]);
                setIsActive(row["isActive"]);
                setEditUserValues({
                    "userEmail": row["userEmail"],
                    "firstName": row["firstName"],
                    "lastName": row["lastName"],
                    "userRole": row["role"],
                    "userId": row["id"],
                    "intialUserRole": row["role"],
                    "userOrgId": row["organizationId"],
                    "isActive": row["isActive"]
                });
            }
            setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
            setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);

        } else if (prop === "changeUserRole") {
            setCheckChangePassword(false)
            setEditUserRow(row)
            if (editUserRowId === null || editUserRowId !== row["id"]) {
                setEditUserRowId(row["id"])
                setEditFirstName(row["firstName"])
                setEditFirstNameCopy(row["firstName"])
                setEditLastName(row["lastName"])
                setEditLastNameCopy(row["lastName"])
                setEditUser(row);
                setFirstLastNameChanged(true);
                setIsActive(row["isActive"]);
                setEditUserValues({
                    "userEmail": row["userEmail"],
                    "firstName": row["firstName"],
                    "lastName": row["lastName"],
                    "userRole": row["role"],
                    "userId": row["id"],
                    "intialUserRole": row["role"],
                    "userOrgId": row["organizationId"],
                    "isActive": row["isActive"]
                });
            }
            if (eventTargetValue === "USER" && editUserValues.intialUserRole === eventTargetValue) {
                setUserRoleChanged(false)
            }
            setEditUserRole(eventTargetValue)
            setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
            setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);
        } else {
            setCheckChangePassword(false);
            setEditUserRow(row)
            setFocusedUserColumn(prop)
            // if ((editFirstName.length === 0 && editLastName.length === 0)) {
            if (editUserRowId === null || editUserRowId !== row["id"]) {
                // setInitialState();
                setEditUserRowId(row["id"])
                setEditFirstName(row["firstName"])
                setEditFirstNameCopy(row["firstName"])
                setEditLastName(row["lastName"])
                setEditLastNameCopy(row["lastName"])
                setEditUser(row);
                setFirstLastNameChanged(true);
                setEditUserRole(row["role"]);
                setIsActive(row["isActive"]);
                setEditUserValues({
                    "userEmail": row["userEmail"],
                    "firstName": row["firstName"],
                    "lastName": row["lastName"],
                    "userRole": row["role"],
                    "userId": row["id"],
                    "intialUserRole": row["role"],
                    "userOrgId": row["organizationId"],
                    "isActive": row["isActive"]
                });
            }
            if (prop === "firstName") {
                setEditFirstName(eventTargetValue)
            } else if (prop === "lastName") {
                setEditLastName(eventTargetValue)
            }
            setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
            setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);
        }
    }

    const handleClosePopupDoNotDiscard = () => {
        setPopupOpen(false);
    }

    const handleEditUserRole = (row, prop) => (event) => {
        if (editUserRowId !== null && editUserRowId !== row["id"]) {
            setNewEditUserRow(row);
            setNewProp("changeUserRole");
            setNewEventTargetValue(event.target.value);
            setPopupOpen(true);
        } else {
            if (event.target.value === "USER" && editUserValues.intialUserRole === event.target.value) {
                setUserRoleChanged(false)
            }
            setEditUserRow(row)
            if (editUserRowId === null || editUserRowId !== row["id"]) {
                setEditUserRowId(row["id"])
                setEditFirstName(row["firstName"])
                setEditFirstNameCopy(row["firstName"])
                setEditLastName(row["lastName"])
                setEditLastNameCopy(row["lastName"])
                setEditUser(row);
                setFirstLastNameChanged(true);
                setCheckChangePassword(false);
                setIsActive(row["isActive"]);
                setEditUserValues({
                    "userEmail": row["userEmail"],
                    "firstName": row["firstName"],
                    "lastName": row["lastName"],
                    "userRole": row["role"],
                    "userId": row["id"],
                    "intialUserRole": row["role"],
                    "userOrgId": row["organizationId"],
                    "isActive": row["isActive"]
                });
            }
            setEditUserRole(event.target.value)
            setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
            setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);
        }
    };

    const handleChangeCheckPassword = (row) => (event) => {
        if (editUserRowId !== null && editUserRowId !== row["id"]) {
            setNewEditUserRow(row);
            setNewProp("generateNewPassword");
            setNewEventTargetValue(event.target.value);
            setPopupOpen(true);
        } else {
            setCheckChangePassword(event.target.checked);
            if (event.target.checked) {
                setEditUserRow(row)
                // if ((editFirstName.length === 0 && editLastName.length === 0)) {
                if (editUserRowId === null || editUserRowId !== row["id"]) {
                    // setInitialState();
                    setEditUserRowId(row["id"])
                    setEditFirstName(row["firstName"])
                    setEditFirstNameCopy(row["firstName"])
                    setEditLastName(row["lastName"])
                    setEditLastNameCopy(row["lastName"])
                    setEditUser(row);
                    setFirstLastNameChanged(true);
                    setEditUserRole(row["role"]);
                    setIsActive(row["isActive"]);
                    setEditUserValues({
                        "userEmail": row["userEmail"],
                        "firstName": row["firstName"],
                        "lastName": row["lastName"],
                        "userRole": row["role"],
                        "userId": row["id"],
                        "intialUserRole": row["role"],
                        "userOrgId": row["organizationId"],
                        "isActive": row["isActive"]
                    });
                }
                setTextBreadCrumbFirstName(classes.textBreadCrumbsOnHoverFirstName);
                setTextBreadCrumbLastName(classes.textBreadCrumbsOnHoverLastName);
            } else {
                if ((editFirstName === editFirstNameCopy) && (editLastName === editLastNameCopy)) {
                    setEditUserRowId(null)
                    setEditUserRow({})
                    setEditFirstName(editFirstNameCopy)
                    setEditLastName(editLastNameCopy)
                }
            }
        }
    };

    const tableHeadWidthUsers = (100 / userColumns.length) + "%";

    const handleChange = prop => (event) => {
        let ValidRegEx = (prop === 'email') ? EMAIL_REGEX : VALID_USER_INPUT_REGEX;
        if (!ValidRegEx.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                if (prop === 'userName' && values.email === event.target.value) {
                    setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
                } else {
                    setInvalidUserInput([...invalidUserInput, prop]);
                }
            } else if (prop === 'userName' && values.email === event.target.value) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        } else {
            if (invalidUserInput.includes(prop) || (prop === 'userName' && values.email === event.target.value)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangePassword = prop => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleChangeEditUserRole = prop => (event) => {
        if (event.target.value === "USER" && editUserValues.intialUserRole === event.target.value) {
            setUserRoleChanged(false)
        }
        setEditUserValues({ ...editUserValues, [prop]: event.target.value });
    };

    const handleChangeEditUser = prop => (event) => {
        let ValidRegEx = (prop === 'email') ? EMAIL_REGEX : VALID_USER_INPUT_REGEX;
        if (!ValidRegEx.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setFirstLastNameChanged(true);
        setEditUserValues({ ...editUserValues, [prop]: event.target.value });
    };

    let timeout = null;

    const disableSubmitButtonAddUser = () => {
        if (values.userName && values.email && values.userOrganizationId && values.firstName && values.userRole) {
            return false;
        }
        return true;
    };


    const disableSubmitButtonEnroll = () => {
        if (values.userName && values.password) {
            return false;
        }
        return true;
    };

    const setInitialState = () => {
        values.userName = ""
        values.password = ""
        values.userOrganizationId = ""
        values.email = ""
        values.firstName = ""
        values.userRole = ""
        values.lastName = ""
        editUserValues.userRole = ""
        editUserValues.userEmail = ""
        editUserValues.userId = ""
        editUserValues.intialUserRole = ""
        editUserValues.userOrgId = ""
        editUserValues.firstName = ""
        editUserValues.lastName = ""
        setFirstLastNameChanged(false)
        setInvalidUserInput([])
        setEditUserRowId(null)
        setEditUserRow({})
        setEditFirstName("")
        setEditLastName("")
        setEditFirstNameCopy("")
        setEditLastNameCopy("")
    }

    const openEnrollAdminDrawer = (side, open) => event => {
        setFormType("enrollAdmin");
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        //setNetwork(currentNetwork[0]);
        setDrawerState({ ...drawerState, [side]: open });
    };

    const openDrawerEditUser = (side, open, row) => event => {
        setFormType("editUser");
        setEditUser(row);
        setEditUserValues({
            "userEmail": row["userEmail"],
            "firstName": row["firstName"],
            "lastName": row["lastName"],
            "userRole": row["role"],
            "userId": row["id"],
            "intialUserRole": row["role"],
            "userOrgId": row["organizationId"]
        });
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    const openDrawerAddUser = (side, open) => event => {
        if (localStorage.getItem("_userRole") === "SUPERADMIN") {
            fetchOrganizationData()
        } else if (localStorage.getItem("_userRole") === "ADMIN") {
            setValues({ ...values, ["userOrganizationId"]: localStorage.getItem("_orgID") })
        }
        setFormType("addUser");
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });

    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setInitialState();
    };

    const clearSearchText = () => {
        setSearchKey({ value: "" });
        fetchData();
    };

    const handleSearch = event => {
        const _val = event.target.value;
        setSearchKey(prevState => {
            return { ...prevState, value: _val };
        });

        if (_val.length <= 2) {
            setRows(originalRows);
            setQueryCount(originalqueryCount);
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchData(rowsPerPage, 0, orderBy, order, _val);
        }, 200);
    };

    const handleSubmitEditUser = () => {
        setBackdrop(true);
        UserManagementService.editUser(editUserValues.userEmail, editUserValues.userId, editUserValues.userRole, editUserValues.firstName, editUserValues.lastName, firstLastNameChanged, checkChangePassword, userRoleChanged, editUserValues.userOrgId, localStorage.getItem("_username"), localStorage.getItem("_orgID"), localStorage.getItem("_userRole"), editUserValues.intialUserRole).then(
            response => {
                setBackdrop(false);
                if (response && response.data) {
                    setUpdatedPassword(response.data)
                    if (checkChangePassword) {
                        setPopupOpenPassword(true);
                    }
                    notify({
                        type: "success",
                        message: "Successfully edited the user details"
                    });
                    setDrawerState({ ...drawerState, ["right"]: false });
                    if (editUserValues.userEmail == localStorage.getItem("_emailId")) {
                        localStorage.setItem('_firstName', editUserValues.firstName);
                        localStorage.setItem('_lastName', editUserValues.lastName ? editUserValues.lastName === "null" ? "" : editUserValues.lastName : "");
                        window.location.reload();
                    }
                    fetchData();
                    setBackdrop(false);
                    setInitialState();
                } else {
                    notify({
                        type: "error",
                        message: "Error while editing USER "
                    });
                }
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while editing USER "
                });
            }
        );
    };

    const handleSubmitAddUser = () => {
        setBackdrop(true);

        UserManagementService.addUser(values.userName, values.email, values.userOrganizationId, values.firstName, values.lastName, values.userRole, localStorage.getItem("_username"), localStorage.getItem("_orgID"), localStorage.getItem("_userRole"), "USER").then(
            response => {
                setBackdrop(false);
                notify({
                    type: "success",
                    message: "Successfully added user"
                });
                fetchData();
                setBackdrop(false);
                setDrawerState({ ...drawerState, ["right"]: false });
                setInitialState();

            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while adding user"
                });
            }
        );
    };

    const handleEnrollSubmit = () => {
        setBackdrop(true);
        let organizationId = localStorage.getItem("_orgID");
        UserManagementService.enrollUser(values.userName, values.password, organizationId).then(
            response => {
                setBackdrop(false);
                notify({
                    type: "success",
                    message: "Successfully enrolled admin"
                });
                fetchData();
                setBackdrop(false);
                setDrawerState({ ...drawerState, ["right"]: false });
                setInitialState();
                localStorage.setItem("_adminEnrolled", true);
                props.updateAdminEnrolled();
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error enrolling admin"
                });
            }
        );

    };


    //let currentTheme = localStorage.getItem("currentTheme") === "darkTheme" ? darkTheme : lightTheme;
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
        fetchData(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value);
    };

    function validateEmail(elementValue) {
        var emailPattern = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchData(rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        fetchData(+event.target.value, 0);
    };

    const fetchOrganizationData = () => {
        organizationService.getOrgsWithoutPeers().then(
            response => {
                setBackdrop(false)
                let _data = response.data;
                setOrgData(_data);
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching organizations without peers data"
                });
            }
        );
    }

    const fetchData = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = "") => {

        let query = {
            '_page': currentPage + 1,
            '_limit': pageSize,
            '_sort': sortBy,
            '_order': sortOrder
        }
        if (searchText) {
            query['q'] = searchText;
        }

        setBackdrop(true);
        let organizationId = parseInt(localStorage.getItem('_orgID'));



        UserManagementService.getUsersByOrgId(organizationId, query).then(
            response => {
                let _data = response.data;
                _data = _data.filter(user => user.organizationId === organizationId);
                setRows(_data);
                if (response.headers.hasOwnProperty('x-total-count')) {
                    setQueryCount(parseInt(response.headers['x-total-count']));
                }
                if (!searchText) {
                    setOriginalRows(_data);
                    setOriginalQueryCount(parseInt(response.headers['x-total-count']));
                }
                setBackdrop(false)
            },
            error => {
                setBackdrop(false);
                if (searchText) {
                    setRows([]);
                }
                else {
                    notify({
                        type: "error",
                        message: "Error while fetching Users data"
                    });
                }
            }
        );

    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const sideListAddUser = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Add a new user"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div className={classes.flexItem}>
                <TextField
                    id="standard-basic"
                    select
                    required
                    className={classes.textField}
                    label={"User Type"}
                    margin="normal"
                    rows="2"
                    fullWidth
                    value={values.userRole}
                    onChange={handleChange("userRole")}
                >
                    {[
                        <MenuItem key={"ADMIN"} value={"ADMIN"}>
                            {"ADMIN"}
                        </MenuItem>,
                        <MenuItem key={"USER"} value={"USER"}>
                            {"USER"}
                        </MenuItem>
                    ]}
                </TextField>
            </div>

            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        error={invalidUserInput.includes("email")}
                        label={"Email"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={values.email}
                        helperText={invalidUserInput.includes("email") ? "Enter valid email Id" : ""}
                        onChange={handleChange("email")}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        error={invalidUserInput.includes("firstName")}
                        label={"First Name"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={values.firstName}
                        onChange={handleChange("firstName")}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        error={invalidUserInput.includes("lastName")}
                        label={"Last Name"}
                        margin="normal"
                        rows="2"
                        fullWidth
                        value={values.lastName}
                        onChange={handleChange("lastName")}
                    />
                </div>
                <div className={classes.flexItem}>
                    {
                        localStorage.getItem("_userRole") === 'ADMIN' ?
                            <TextField
                                id="standard-basic"
                                select
                                required
                                className={classes.textField}
                                label={"Organization"}
                                margin="normal"
                                rows="2"
                                fullWidth
                                disabled
                                value={localStorage.getItem("_orgName")}
                            >
                                <MenuItem key={localStorage.getItem("_orgName")} value={localStorage.getItem("_orgName")}>
                                    {localStorage.getItem("_orgName")}
                                </MenuItem>
                            </TextField>
                            :
                            <TextField
                                id="standard-basic"
                                select
                                required
                                className={classes.textField}
                                label={"Organization"}
                                margin="normal"
                                rows="2"
                                fullWidth
                                value={values.userOrganizationId}
                                onChange={handleChange("userOrganizationId")}
                            >
                                {orgData.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.organizationName}
                                    </MenuItem>
                                ))}
                            </TextField>
                    }
                </div>

                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        error={invalidUserInput.includes("userName")}
                        label={"User Name"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        disabled={values.email ? invalidUserInput.includes("email") : true}
                        helperText={values.email ? invalidUserInput.includes("userName") ? "User name must be alpha-numberic or same as email Id" : "" : "Enter valid email Id first"}
                        value={values.userName}
                        onChange={handleChange("userName")}
                    />
                </div>


            </div>


            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmitAddUser} variant="contained" color="primary" disabled={backdrop || disableSubmitButtonAddUser() || invalidUserInput.length}>
                    {"SUBMIT"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>

            </div>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >
    );

    const sideListEditUser = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Edit User"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>


            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        label={"User Name"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        disabled
                        value={editUser["userName"]}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        disabled
                        id="standard-basic"
                        className={classes.textField}
                        label={"Email"}
                        margin="normal"
                        error={!validateEmail(editUser["userEmail"])}
                        rows="2"
                        required
                        fullWidth
                        value={editUser["userEmail"]}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        disabled
                        id="standard-basic"
                        className={classes.textField}
                        label={"Organization"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={editUser["organizationCode"]}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        label={"First Name"}
                        margin="normal"
                        error={invalidUserInput.includes("firstName")}
                        rows="2"
                        required
                        fullWidth
                        value={editUserValues["firstName"]}
                        onChange={handleChangeEditUser("firstName")}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        label={"Last Name"}
                        margin="normal"
                        error={editUserValues["lastName"] ? invalidUserInput.includes("lastName") : false}
                        rows="2"
                        required
                        fullWidth
                        value={editUserValues["lastName"]}
                        onChange={handleChangeEditUser("lastName")}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        select
                        required
                        className={classes.textField}
                        label={"User Type"}
                        margin="normal"
                        rows="2"
                        fullWidth
                        defaultValue={editUser["role"]}
                        onChange={handleChangeEditUserRole("userRole")}
                    >
                        {[
                            <MenuItem key={"ADMIN"} value={"ADMIN"}>
                                {"ADMIN"}
                            </MenuItem>,
                            <MenuItem key={"USER"} value={"USER"}>
                                {"USER"}
                            </MenuItem>
                        ]}
                    </TextField>
                </div>
                <div className={classes.flexItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkChangePassword}
                                onChange={handleChangeCheckPassword}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label="Auto-generate a new password"
                    />
                </div>

            </div>


            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmitEditUser} variant="contained" color="primary" disabled={backdrop}>
                    {"SUBMIT"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>

            </div>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );

    const sideListEnrollUser = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Enroll a new admin"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                    <div className={classes.flexItem}>
                        <TextField
                            id="standard-basic"
                            className={classes.textField}
                            error={invalidUserInput.includes("userName")}
                            label={"Username"}
                            margin="normal"
                            rows="2"
                            required
                            fullWidth
                            value={values.userName}
                            onChange={handleChange("userName")}
                        />
                    </div>
                    <div className={classes.flexItem}>
                        <TextField
                            id="standard-basic"
                            required
                            type="password"
                            className={classes.textField}
                            label={"Password"}
                            margin="normal"
                            rows="2"
                            fullWidth
                            value={values.password}
                            onChange={handleChangePassword("password")}
                        />
                    </div>

                </div>
            </div>

            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleEnrollSubmit} variant="contained" color="primary" disabled={backdrop || disableSubmitButtonEnroll() || invalidUserInput.length}>
                    {"SUBMIT"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>

            </div>
            <Backdrop open={backdrop} color="primary">
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
    );

    return (
        <div>
            <Grid item xs={12}>
                {
                    props.cameFrom === "UserManagement" ?
                        <div className={classes.tableTitle}>
                            <h3>{"Users"} ({localStorage.getItem('_orgName')})</h3>
                        </div>
                        :
                        ""
                }
                <div className={props.cameFrom === "UserManagement" ? classes.tableHeadRightUserManagement : classes.tableHeadRightMyOrganization}>
                    <div className={classes.searchBox}>
                        <InputBase
                            onChange={handleSearch}
                            value={searchKey.value}
                            className={classes.input}
                            placeholder={"Search Users"}
                        />
                        {searchKey.value.length ? (
                            <IconButton
                                size="medium"
                                aria-label="search"
                                onClick={clearSearchText}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : (
                                ""
                            )}
                        <IconButton
                            size="medium"
                            aria-label="search"
                            disabled
                        >
                            <SearchIcon />
                        </IconButton>
                    </div>

                    <Button
                        color="primary"
                        className={classes.button}
                        onClick={openEnrollAdminDrawer("right", true)}
                        startIcon={<AddCircleIcon />}
                        disabled={(localStorage.getItem("_adminEnrolled") === 'true' || localStorage.getItem("_orgRole") === 'SUPERADMIN') || (localStorage.getItem('_userRole') === "USER")}
                    >
                        {"Enroll Admin"}
                    </Button>

                    <Button
                        color="primary"
                        disabled={localStorage.getItem('_userRole') === "USER"}
                        className={classes.button}
                        onClick={openDrawerAddUser("right", true)}
                        startIcon={<AddCircleIcon />}
                    >
                        {"Add User"}
                    </Button>
                </div>
                <div className={classes.tableWrapper}>
                    <Paper className={classes.tableContainerOrganiztionUsers}>
                        <Table stickyHeader aria-label="sticky table" className={classes.tableBorder} >

                            <TableHead>
                                <TableRow>
                                    {userColumns.map(column => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className={classes.tableHeadRow}
                                            sortDirection={orderBy === column.id ? order : false}
                                            width={tableHeadWidthUsers}
                                        >
                                            {column.sortable ? (
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={order}
                                                    onClick={handleRequestSort(column.id)}
                                                    className={column.id === "lastName" ? classes.tableColumnLabelLastName : classes.tableColumnLabelOrganizationUsers}
                                                    >
                                                    {column.label}
                                                    {orderBy === column.id ? (
                                                        <span className={classes.visuallyHidden}>
                                                            {order === "desc"
                                                                ? "sorted descending"
                                                                : "sorted ascending"}
                                                        </span>
                                                    ) : null}
                                                </TableSortLabel>
                                            ) : (
                                                    column.label
                                                )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {rows.length === 0 ?
                                <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>

                                    {backdrop ? " Fetching the Users, please wait" : "No Users are found "}
                                </TableCell>
                                :
                                localStorage.getItem("_userRole") === "USER" ?
                                    <TableBody>
                                        {rows.length === 0 ?
                                            "" :
                                            rows.map((row, index) => {
                                                return (
                                                    <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index} >
                                                        {userColumns.map(column => {
                                                            if (column.id === "editUser") {
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        <Checkbox
                                                                            checked={editUserRowId === row["id"] ? checkChangePassword : false}
                                                                            onChange={editUserRowId === row["id"] ? handleChangeCheckPassword(row) : editUserRowId === null ? handleChangeCheckPassword(row) : ""}
                                                                            name="checkedB"
                                                                            color="primary"
                                                                            disabled
                                                                            className={classes.buttonDownload}
                                                                        />
                                                                    </TableCell>
                                                                );
                                                            } else if (column.id === "isActive") {
                                                                let value = row["isActive"];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        <Checkbox
                                                                            checked={value}
                                                                            name="checkedB"
                                                                            color="primary"
                                                                        />
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                let value = row[column.id];
                                                                if (!value) {
                                                                    value = "";
                                                                }
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {rows.length === 0 ?
                                            "" :
                                            rows.map((row, index) => {
                                                return (
                                                    <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index} >
                                                        {userColumns.map(column => {
                                                            if (column.id === "editUser") {
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {
                                                                            editUserRowId === row["id"] ?
                                                                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
                                                                                    <Checkbox
                                                                                        checked={checkChangePassword}
                                                                                        onChange={handleChangeCheckPassword(row)}
                                                                                        name="checkedB"
                                                                                        color="primary"
                                                                                        disabled={row["role"] === "SUPERADMIN" || localStorage.getItem("_userRole") === "USER"}
                                                                                        className={classes.editCheckbox}
                                                                                    />
                                                                                    <Button onClick={handleSubmitUserDetails}>
                                                                                        <DoneIcon />
                                                                                    </Button>
                                                                                    <Button onClick={handleClearUserDetails}>
                                                                                        <CloseIcon />
                                                                                    </Button>
                                                                                </div>
                                                                                :
                                                                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
                                                                                    <Checkbox
                                                                                        checked={false}
                                                                                        onChange={handleChangeCheckPassword(row)}
                                                                                        name="checkedB"
                                                                                        color="primary"
                                                                                        disabled={row["role"] === "SUPERADMIN" || localStorage.getItem("_userRole") === "USER"}
                                                                                        className={classes.editCheckbox}
                                                                                    />
                                                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                        <Button style={{ opacity: 0 }}>
                                                                                            <DoneIcon />
                                                                                        </Button>
                                                                                        <Button style={{ opacity: 0 }}>
                                                                                            <CloseIcon />
                                                                                        </Button>
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            } else if (["firstName", "lastName"].includes(column.id)) {
                                                                let value = row[column.id];
                                                                if (!value) {
                                                                    value = "";
                                                                }
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        <Breadcrumbs aria-label="breadcrumb">
                                                                            <div>
                                                                                <input
                                                                                    id="standard-basic"
                                                                                    className={editUserRowId === row["id"] ? column.id === "firstName" ? textBreadCrumbsFirstName : column.id === "lastName" ? textBreadCrumbsLastName : "" : column.id === "firstName" ? classes.textBreadCrumbsFirstNameDefault : column.id === "lastName" ? classes.textBreadCrumbsLastNameDefault : ""}
                                                                                    margin="normal"
                                                                                    rows="2"
                                                                                    autoFocus={editUserRowId === row["id"] && focusedUserColumn === column.id}
                                                                                    disabled={localStorage.getItem("_userRole") === "USER"}
                                                                                    autoComplete='off'
                                                                                    value={editUserRowId === row["id"] ? column.id === "firstName" ? editFirstName : column.id === "lastName" ? editLastName : "" : column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                                                                    onMouseEnter={handleMouseEnterUserRow}
                                                                                    onMouseLeave={handleMouseLeaveUserRow}
                                                                                    onChange={handleChangeUserRow(row, column.id)} />
                                                                            </div>
                                                                        </Breadcrumbs>
                                                                    </TableCell>
                                                                );
                                                            } else if (column.id === "role") {
                                                                let value = row[column.id];
                                                                if (!value) {
                                                                    value = "";
                                                                }
                                                                if (row['role'] === "SUPERADMIN") {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            <TextField
                                                                                id="standard-basic"
                                                                                select
                                                                                required
                                                                                className={classes.textFieldTable}
                                                                                margin="normal"
                                                                                rows="2"
                                                                                fullWidth
                                                                                value={"SUPERADMIN"}
                                                                                disabled
                                                                            >
                                                                                {[
                                                                                    <MenuItem key={"SUPERADMIN"} value={"SUPERADMIN"}>
                                                                                        {"SUPERADMIN"}
                                                                                    </MenuItem>
                                                                                ]}
                                                                            </TextField>
                                                                        </TableCell>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align}>
                                                                            <TextField
                                                                                id="standard-basic"
                                                                                select
                                                                                required
                                                                                className={classes.textFieldTable}
                                                                                margin="normal"
                                                                                rows="2"
                                                                                fullWidth
                                                                                value={editUserRowId === row["id"] ? editUserRole : row[column.id]}
                                                                                // value={editUserRole}
                                                                                onChange={handleEditUserRole(row, "userRole")}
                                                                            >
                                                                                {[
                                                                                    <MenuItem key={"ADMIN"} value={"ADMIN"}>
                                                                                        {"ADMIN"}
                                                                                    </MenuItem>,
                                                                                    <MenuItem key={"USER"} value={"USER"}>
                                                                                        {"USER"}
                                                                                    </MenuItem>
                                                                                ]}
                                                                            </TextField>
                                                                        </TableCell>
                                                                    );
                                                                }
                                                            } else if (column.id === "isActive") {
                                                                let value = row["isActive"];
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {editUserRowId === row["id"] ?
                                                                            <Checkbox
                                                                                checked={isActive}
                                                                                onChange={handleChangeUserRow(row, column.id, !isActive)}
                                                                                name="checkedB"
                                                                                color="primary"
                                                                                disabled={row["role"] === "SUPERADMIN" || localStorage.getItem("_userRole") === "USER"}
                                                                            />
                                                                            : <Checkbox
                                                                                checked={value}
                                                                                onChange={handleChangeUserRow(row, column.id, !value)}
                                                                                name="checkedB"
                                                                                color="primary"
                                                                                disabled={row["role"] === "SUPERADMIN" || localStorage.getItem("_userRole") === "USER"}
                                                                            />
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                let value = row[column.id];
                                                                if (!value) {
                                                                    value = "";
                                                                }
                                                                return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                                                    </TableCell>
                                                                );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                            }

                        </Table>
                        <Drawer
                            anchor="right"
                            open={drawerState.right}
                            onOpen={closeDrawer('right', true)}
                            onClose={closeDrawer('right', true)}
                        >
                            {formType === 'addUser' ? sideListAddUser('right') : formType === 'editUser' ? sideListEditUser('right') : formType === 'enrollAdmin' ? sideListEnrollUser('right') : ""}

                        </Drawer>
                    </Paper>
                </div>
                {rows.length === 0 ?
                    ""
                    :
                    <TablePagination
                        rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                        component="div"
                        count={queryCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "previous page"
                        }}
                        nextIconButtonProps={{
                            "aria-label": "next page"
                        }}
                        className={classes.pagination}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        labelRowsPerPage={"Rows per page"}
                    />
                }
            </Grid>
            <Dialog
                open={popupOpenPassword}
                onClose={handleClosePopupPassword}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle} gutterBottom>
                        {`New updated password of the user`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogContentText}>
                        {updatedPassword}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus className={classes.dialogButton} color="primary" onClick={handleClosePopupPassword} >
                        {"OK"}
                    </Button>
                </DialogActions>
                </Dialog>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={popupOpen}
                onClose={handleClosePopupDoNotDiscard}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle} gutterBottom>
                        {`Discard details`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
                        You are about to discard other modified peer details
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePopupDiscard} className={classes.dialogButton} color="primary">
                        PROCEED
                    </Button>
                    <Button onClick={handleClosePopupDoNotDiscard} className={classes.dialogButton} color="primary">
                        CANCEL
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
