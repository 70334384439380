import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

const useStyles = makeStyles({
    list: {
        width: "90vw",
    },
    shipmentRevisionGrid: {
        width: "45%"
    },
    buttonDownload: {
        background: "none",
        padding: 0,
        fontSize: "11px",
        float: "right",
        marginLeft: "10px",
        marginTop: "0.75rem"
    },
    buttonPreview: {
        background: "#D3D3D3",
        padding: 0,
        fontSize: "11px",
        float: "right",
        marginLeft: "10px",
        marginTop: "0.75rem"
    },
    hfivestyle: {
        fontSize: "0.8em",
        marginBlockStart: "0",
        marginBlockEnd: "0",
        fontWeight: "200"
    },
    hsixstyle: {
        display: "initial",
        fontSize: "0.9em",
        marginBlockStart: "0",
        marginBlockEnd: "0"
    },
    sideBar: {
        width: "50vw",
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    cardContent: {
        marginLeft: "- 13px",
        marginTop: "-13px",
        cursor: 'pointer',
        display: "flex"
    },
    iconAlign: {
        verticalAlign: "middle"

    },
    root: {
        width: "100%",
        background: "white"
    },
    cardWith: {
        //width: "60vw",
    },
    card: {
        cursor: "pointer"
    },
    typography: {
        padding: theme.spacing(2),
    },
    evenHeader: {
        background: "#F8F8F8"
    },
    h3padding: {
        paddingLeft: theme.spacing(2),
        marginBottom: "5px",
        marginTop: "5px",
        minHeight: "64px"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerIcon: {
        paddingLeft: "4%",
        "&:hover": {
            background: 'none'
        }

    },
    noMargin: {

    },
    versionInfoDiv: {
    },
    boldText: {
        fontWeight: 'bold',
        color: 'black'
    },
    smallTextIcon: {
        fontSize: 'small',
        color: 'gray',
        marginBottom: "-2px",
        marginTop: "2px"
    },
    smallText: {
        display: "flex",
        fontSize: 'small',
        color: 'gray',
        float: "left"
    },
    smallTextTransactionId: {
        display: "flex",
        fontSize: 'small',
        color: 'gray',
        float: "left",
        overflowWrap: "anywhere"
    },
    printIcon:{
        verticalAlign:'bottom'
    },
    flexItem: {
        flex: 1,
        paddingLeft: theme.spacing(3)
    },
    cardContentInfoDiv: {
        width: "85%",
        display: "inline-grid",
    },
    cardContentActionDiv: {
        width: "15%"
    },
    verticalTimeLine: {
        // width: "60%"
    },
    docVersionInfoDiv: {
        fontSize: 'small',
    },
    docTransactionIdDiv: {
        fontSize: 'small',
        color: 'gray',
        float: "left",
        display: "flex"
    },
    previewButton: {
        display: 'flex',
        marginLeft: 'auto'
    },
    previewBackground:{
        backgroundColor: 'transparent'
    },
});

export default useStyles;