export const _routes = [
  { title: "Home", route: "/", icon: "stars", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  { title: "Organizations", route: "/organizations", icon: "person", admin_view: false, super_admin_view: true, user_view: false, operator_view: true },
  { title: "Networks", route: "/networks", icon: "home", admin_view: true, super_admin_view: true, user_view: false, operator_view: true },
  { title: "Chaincode", route: "/chaincodes", icon: "person", admin_view: true, super_admin_view: true, user_view: false, operator_view: true },
  //{ title: "Shipments", route: "/shipments", icon: "home", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  { title: "Bookings", route: "/bookings", icon: "home", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  //{ title: "Documents", route: "/documents", icon: "home", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  { title: "Documents", route: "/documentsV2", icon: "home", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  { title: "Assets", route: "/assets", icon: "person", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  { title: "Users", route: "/userManagement", icon: "person", admin_view: false, super_admin_view: true, user_view: false, operator_view: true },
  { title: "My Organization", route: "/myOrganization", icon: "person", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
];

export const _monitoring = [
  { title: "Transactions", route: "/transaction", icon: "person", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
  { title: "Blocks", route: "/blocks", icon: "person", admin_view: true, super_admin_view: true, user_view: true, operator_view: true },
]