import L from 'leaflet'

export const originIcon = new L.Icon({
  iconUrl: require('./assets/origin.svg'),
  iconSize: [20, 20],
})

export const portOfDepartureIcon = new L.Icon({
  iconUrl: require('./assets/portofDeparture.svg'),
  iconSize: [20, 20],
})

export const portOfArrivalIcon = new L.Icon({
  iconUrl: require('./assets/portofArrival.svg'),
  iconSize: [20, 20],
})

export const destinationIcon = new L.Icon({
  iconUrl: require('./assets/destination.svg'),
  iconSize: [20, 20],
})

export const shipmentRouteNotPresentIcon = new L.Icon({
  iconUrl: require('./assets/shipmentRouteNotPresent.svg'),
  iconSize: [100, 100],
})