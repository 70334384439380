import decode from 'jwt-decode';

// Authentication utility function
const authUtil = {

    //TODO: update based on actual response
    //set the token in local storage
    setSession: (response) => {
        const data = response.data;
        const token = data.token;
        const isAdminEnrolled = data.adminEnrolled;
        const peers = data.peers;

        // Saves user token to localStorage
        localStorage.setItem('_token', token);

        // TODO: DO NOT Save user context in localStorage, Update this storage to REDUX STORE
        // let userRole = (data.role === "ADMIN" || data.role === "SUPERADMIN") ? 'ADMIN' : 'USER';
        localStorage.setItem('_userRole', data.role)
        localStorage.setItem('_orgCode', data.organizationCode);
        localStorage.setItem('_username', data.userName);
        localStorage.setItem('_emailId', data.userEmail);
        localStorage.setItem('_firstName', data.firstName);
        localStorage.setItem('_lastName', data.lastName ? data.lastName === "null" ? "" : data.lastName : "");
        localStorage.setItem('_orgName', data.organizationName);
        localStorage.setItem('_orgDomain', data.organizationDomainCode);
        localStorage.setItem('_orgID', data.organizationId);
        localStorage.setItem('_orgRole', data.organizationRole);
        localStorage.setItem('_adminEnrolled', isAdminEnrolled);
        localStorage.setItem('_peers', JSON.stringify(peers));
        localStorage.setItem('_userId', data.id);
        //set expiry time 
        const decoded = decode(token);
        localStorage.setItem('_expiry', decoded.exp);
    },

    updateToken: (newToken) => {
        if (authUtil.isAuthenticated()) {
            const decoded = decode(newToken);
            localStorage.setItem('_token', newToken);
            localStorage.setItem('_expiry', decoded.exp);
        }
    },

    //get token utlity
    getToken: () => {
        if (authUtil.isAuthenticated()) {
            return localStorage.getItem('_token');
        } else {
            return '';
        }
    },

    //check if token is expired or not
    isTokenExpired: () => {
        try {
            const expiry = localStorage.getItem('_expiry');
            if (expiry < Date.now() / 1000) { // Checking if token is expired.
                return true;
            }
            else {
                return false;
            }
        }
        catch (err) {
            return false;
        }
    },

    // checks if the user is authenticated
    isAuthenticated: () => {
        const token = localStorage.getItem('_token');
        if (token === null || token === undefined) {
            return false;
        } else {
            return !authUtil.isTokenExpired();
        }
    },

    //checks if the logged in user has ADMIN role
    isAdminOrSuperAdmin: () => {
        let user = localStorage.getItem('_userRole');

        if (user && (user === 'ADMIN' || user === "SUPERADMIN")) {
            return true;
        } else {
            return false;
        }

    },

    isAdmin: () => {
        let user = localStorage.getItem('_userRole');

        if (user && (user === 'ADMIN')) {
            return true;
        } else {
            return false;
        }

    },

    isSuperAdmin: () => {
        let user = localStorage.getItem('_userRole');

        if (user && (user === "SUPERADMIN")) {
            return true;
        } else {
            return false;
        }

    },

    getUsertype: () => {
        let user = localStorage.getItem('_userRole');
        let orgRole = localStorage.getItem('_orgRole');

        if (user && (user === "SUPERADMIN")) {
            return "SUPERADMIN";
        } else if (user && (user === "ADMIN" && orgRole === "operator")) {
            return "OPERATOR_ADMIN";
        } else if (user && (user === "ADMIN" && orgRole !== "operator")) {
            return "ADMIN";
        } else if (user && (user === "USER")) {
            return "USER";
        }
    },

    //clear tokens and localstorage
    clearSession: () => {
        localStorage.clear();
    }

};
export default authUtil;
