import React, { useState, useRef } from "react";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';

import ReactToPrint from 'react-to-print';
import './styles/style.css';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import theme from "../../themes/lightTheme";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import '../../css/style.css';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import Util from "../../utils/Util";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DescriptionIcon from '@material-ui/icons/Description';
import DialogContentText from '@material-ui/core/DialogContentText';
import Toolbar from '@material-ui/core/Toolbar';
import RelativeTime from 'react-relative-time'
import ReactJson from 'react-json-view';
import { Divider } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import documentService from "../../services/DocumentService";
import notify from "../../utils/notifier";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AdjustIcon from '@material-ui/icons/Adjust';
import chaincodeService from "../../services/ChaincodeService"
import useStyles from "./styles/TransactionsShipmentRevisonStyles"
import FormRenderer from 'form-render-module'
import { APPLICATION_ID } from "../../utils/constants";
import PrintIcon from '@material-ui/icons/Print';
import Skeleton from 'react-loading-skeleton';
import JSONTree from 'react-json-tree';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import FilePreview from './filePreview'
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';


//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};
const writeTheme = {
    base00: '#ffffff',
    base01: '#2e2f30',
    base02: '#515253',
    base03: '#737475',
    base04: '#959697',
    base05: '#b7b8b9',
    base06: '#dadbdc',
    base07: '#fcfdfe',
    base08: '#e31a1c',
    base09: '#e6550d',
    base0A: '#dca060',
    base0B: '#31a354',
    base0C: '#80b1d3',
    base0D: '#3182bd',
    base0E: '#756bb1',
    base0F: '#b15928'
};

export default function Transactions(props) {

    const classes = useStyles();
    const [transDetails, setTransDetails] = React.useState(null);
    const [backdrop, setBackdrop] = React.useState(false);
    const [showTransactionsInfo, setShowTransactionsInfo] = useState(false);
    const [transInfo, setTransInfo] = useState([]);
    const [lastUpdatedBy, setLastUpdatedBy] = React.useState("");
    const [documentHash, setDocumentHash] = React.useState("");
    const [timestamp, setTimestamp] = React.useState("");
    const [version, setVersion] = React.useState(1);
    const [popupOpen, setpopupOpen] = React.useState(false);
    const [showDocumentJSON, setShowDocumentJSON] = React.useState({});
    const [tampered, setTampered] = React.useState(null);
    const [uploadValues, setValues] = React.useState({
        DocumentID: "",
        DocumentType: "",
        DocumentCode: "",
        DocumentName: "",
        DocumentDescription: "",
        DocumentJSON: "",
        eventType: "",
    });
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [showComponent, setShowComponent] = React.useState(false);

    const setInitialState = () => {
        setShowDocumentJSON({})
        setVersion(1)
        setpopupOpen(false);
        setTimestamp("")
        setLastUpdatedBy("")
        setDocumentHash("")
    }
    const openDrawer = (side, open, txId) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        let details = {}
        setBackdrop(true)
        chaincodeService.getTransactionById(props.channel, txId).then(
            response => {
                const _data = response.data;
                setTransDetails(_data);

                setDrawerState({ ...drawerState, [side]: open });
                setBackdrop(false)
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching transaction"
                });
            }
        );
    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    function fetchDocumentRevisionsbyDocumentID(documentID) {

        documentService.getDocumentRevisions(documentID).then(
            response => {
                const _data = response.data;
                let showTransactionsInfoArr = []
                let trans = []
                for (let i = 0; i < _data.length; i++) {
                    let obj = _data[i]
                    showTransactionsInfoArr.push(obj)
                    let objo = {};
                    let clientName = obj['client'];
                    objo["transactionId"] = obj["txId"]
                    objo["transactionTimestamp"] = obj["createdAt"]
                    objo["lastUpdatedBy"] = clientName.includes("Datachain UI Portal") ? `${obj["firstName"]}${obj["lastName"] && obj["lastName"] !== "null" ? " " + obj["lastName"] : ""}, ${obj["organizationName"]}` : clientName;
                    objo["version"] = obj["version"]
                    objo["jsonFile"] = obj
                    trans.push(objo)
                }
                setShowTransactionsInfo(showTransactionsInfoArr)
                setTransInfo(trans);
                setShowComponent(true);
                if (props.handleTransactionComponentLoaded) {
                    props.handleTransactionComponentLoaded(true);
                }

            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching Revisions"
                });
            }
        );
    }


    const fetchData = () => {
        if (props.documentID === 0) {

        } else {
            fetchDocumentRevisionsbyDocumentID(props.documentID);
        }
    };

    const handlePopupClose = () => {
        setInitialState();
    };


    const sideList = side => (
        <div className={classes.sideBar}>
            <div style={{ overflow: "auto" }}>
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                            className={classes.drawerIcon}

                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>
                        Transactions Details
                    </Typography>



                </Toolbar>
                <Divider></Divider>

            </div>

            <div style={{ background: "#f8f8f8", height: "140%", overflow: "auto" }}>
                {
                    transDetails != null ? (
                        <div>
                            {
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <TableRow style={{ height: "60px" }} key="id" >
                                            <TableCell component="th" scope="row">
                                                <b>Transaction ID</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.transactionId} </TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="creator">
                                            <TableCell component="th" scope="row">
                                                <b>Creator Organization</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.creator ? transDetails.creator.toUpperCase().slice(0, transDetails.creator.length - 3) : ""}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="endorser">
                                            <TableCell component="th" scope="row">
                                                <b>Endorsers</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {transDetails != null && transDetails.endorsers.length > 0 ? (
                                                    transDetails.endorsers.map((member, index) => {
                                                        return (
                                                            <div>{(index + 1) + ")"} {member.toUpperCase().slice(0, member.length - 3)}</div>
                                                        );
                                                    })
                                                ) : (
                                                        ""
                                                    )}
                                                {/* <JSONTree
                                                data={transDetails.endorsers.length > 0 ? transDetails.endorsers.map(i => i.toUpperCase().slice(0, i.length - 3)) : ""}
                                                theme={writeTheme}
                                                invertTheme={false}
                                            /> */}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="channel">
                                            <TableCell component="th" scope="row">
                                                <b>Channel</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.channelId}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="trxntype">
                                            <TableCell component="th" scope="row">
                                                <b>Transaction Type</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.transactionType}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="timestamp">
                                            <TableCell component="th" scope="row">
                                                <b>Timestamp</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.timestamp}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="chaincodeName">
                                            <TableCell component="th" scope="row">
                                                <b>Chaincode Name</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.chaincode.name}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="chaincodeVersion">
                                            <TableCell component="th" scope="row">
                                                <b>Chaincode Version</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.chaincode.version}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            }
                        </div>
                    ) : (
                            <Grid item xs={12}>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                            </Grid>
                        )
                }
            </div >

        </div >
    );

    const obj = (arg) => {
        var json = Object.assign({}, arg)
        if (Object.keys(json).length === 0) {

        } else {
            setValues({ ...uploadValues, ["DocumentJSON"]: json });
        }
    }

    const handleClickPopupOpen = (event, row) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true)
        uploadValues.DocumentID = row.documentId
        uploadValues.DocumentType = props.documentTypeFortransaction
        uploadValues.DocumentCode = props.documentCodeFortransaction
        uploadValues.DocumentName = props.documentRow["name"]
        uploadValues.DocumentDescription = props.documentRow["description"]


        documentService.downloadDocumentMetadata(row.documentId, row.version).then(
            response => {
                const _data = response.data;
                let jsonData = typeof (_data.data.metadata) === "string" ? JSON.parse(_data.data.metadata) : _data.data.metadata;
                setVersion(_data["version"])
                uploadValues.DocumentJSON = jsonData;
                setShowDocumentJSON(jsonData);
                setTimestamp(_data["created_at"])
                setLastUpdatedBy(`${_data["first_name"]}${_data["last_name"] !== "null" && _data["last_name"] ? " " + _data["last_name"] : ""}, ${_data["organization_name"]}`)
                setDocumentHash(_data["documentHash"])
                setTampered(_data["tampered"])
                setpopupOpen(true)
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching Document"
                });
            }
        );


    };

    const handleClickDownloadDocument = (event, versionToDownload) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true);

        documentService.downloadDocument(props.documentID, versionToDownload).then(
            response => {
                setBackdrop(false)
                Util.downloadTemplate(response);
            },
            error => {
                setBackdrop(false)
                if (error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }


            }
        );
    };

    const handleClickPreviewDocument = (event, versionToDownload) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true);

        documentService.downloadDocument(props.documentID, versionToDownload).then(
            response => {
                setBackdrop(false)

                var type = response.headers["content-type"];
                if (type === 'application/octet-stream') {
                    type = 'application/pdf';
                }
                
                const blob = new Blob([response.data], {
                    type: type,
                    encoding: "UTF-8"
                });

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                window.open(link.href)
            },
            error => {
                setBackdrop(false)
                if (error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }


            }
        );
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    const [print, setprint] = useState(0);
    const componentRef = useRef();

    function checkFileExtentionValidForPreview() {
        let fileExtensionPreviewAllowed = ['pdf', 'png', 'jpg', 'html', 'jpeg'];
        let row = props.documentRow;
        let originalFileName = row['originalFileName'];
        let fileExt = originalFileName ? originalFileName.split('.').slice(-1).pop() : "";
        return fileExtensionPreviewAllowed.includes(fileExt);
    }

    return (
        showComponent ? 
        
        <Grid className={classes.shipmentRevisionGrid}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <VerticalTimeline className={classes.verticalTimeLine} layout="1-column">
                {transInfo != null ? (
                    transInfo.map((member) => {
                        return (
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: '#fff', color: '#fff', boxShadow: 'none', cursor: 'pointer', marginLeft: '40px' }}
                                iconStyle={{ background: '#173a64', color: '#fff', width: '30px', height: '30px', left: '5px' }}
                                icon={<AdjustIcon />}
                            >
                                <div className={classes.cardContent} onClick={openDrawer("right", true, member.transactionId)}>
                                    <div className={classes.cardContentInfoDiv}>
                                        <div className={classes.noMargin}>
                                            <span className={classes.smallText}>
                                                Updated by &nbsp;
                                                <span className={classes.boldText}>
                                                    {member.lastUpdatedBy}
                                                </span> &nbsp;&nbsp; (<RelativeTime value={member.transactionTimestamp} titleformat="iso8601" />)
                                            </span>
                                        </div>
                                        <div className={classes.versionInfoDiv}>
                                            <span className={classes.smallText}>
                                                Version: {member.version}
                                                <span>
                                                    &nbsp; &nbsp; <QueryBuilderIcon className={classes.smallTextIcon} /> {member.transactionTimestamp ? member.transactionTimestamp : 'Not Available'}
                                                </span>
                                            </span>
                                        </div>
                                        <div className={classes.docTransactionIdDiv}>
                                            <VerifiedUserIcon className={classes.smallTextIcon} style={{ "color": "green" }} />
                                            <span className={classes.smallTextTransactionId}>
                                               {member.transactionId}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={classes.cardContentActionDiv}>
                                        {
                                            props.fileType === "JSON" ?
                                                <Tooltip title="View">
                                                    <Button className={classes.buttonDownload} onClick={event => handleClickPopupOpen(event, member.jsonFile)}>
                                                        <DescriptionIcon />
                                                    </Button>
                                                </Tooltip>
                                                : props.fileType === "File" ?
                                                    <div>
                                                        {
                                                            checkFileExtentionValidForPreview() ? 
                                                            <Tooltip title="Preview">
                                                                <Button className={classes.buttonDownload} onClick={event => handleClickPreviewDocument(event, member.version)}>
                                                                    <DescriptionIcon />
                                                                </Button>
                                                            </Tooltip> : ""
                                                        }
                                                        <Tooltip title="Download">
                                                            <Button className={classes.buttonDownload} onClick={event => handleClickDownloadDocument(event, member.version)}>
                                                                <CloudDownloadIcon />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    : ""
                                        }

                                        &nbsp; &nbsp;
                                    </div>
                                </div>
                            </VerticalTimelineElement>
                        );
                    })
                ) : (
                        ""
                    )}

            </VerticalTimeline>
            <Dialog
                open={popupOpen}
                onClose={handlePopupClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle id="alert-dialog-title">
                    <h5 className={classes.hfivestyle}>
                        Name        :
                        <h6 className={classes.hsixstyle}>
                            &nbsp;{uploadValues.DocumentName}
                        </h6>
                    </h5>
                    <h5 className={classes.hfivestyle}>
                        Description        :
                        <h6 className={classes.hsixstyle}>
                            &nbsp;{uploadValues.DocumentDescription}
                        </h6>
                    </h5>
                    <h5 className={classes.hfivestyle}>
                        Version        :
                        <h6 className={classes.hsixstyle}>
                            &nbsp;{version}
                        </h6>
                    </h5>
                    <h5 className={classes.hfivestyle}>
                        Timestamp        :
                        <h6 className={classes.hsixstyle}>
                            &nbsp;{timestamp}
                        </h6>
                    </h5>
                    <h5 className={classes.hfivestyle}>
                        Last updated by        :
                        <h6 className={classes.hsixstyle}>
                            &nbsp;{lastUpdatedBy}
                        </h6>
                    </h5>
                    <h5 className={classes.hfivestyle}>
                        Document Hash        :
                        <h6 className={classes.hsixstyle}>
                            &nbsp;{documentHash}
                        </h6>
                    </h5>
                    <h5 className={classes.hfivestyle}>
                        Print       :
                        <ReactToPrint
                            trigger={() => <PrintIcon className={classes.printIcon}>Print this out!</PrintIcon>}
                            content={() => componentRef.current}
                        />
                        <div ref={componentRef} className="print-source">
                            <FormRenderer docName={uploadValues.DocumentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={showDocumentJSON} disable={true} />
                        </div>
                    </h5>
                    {tampered != null ? (!tampered ? (
                        <span className={classes.smallTextIcon}>
                            <VerifiedUserIcon style={{ "color": "green" }} className={classes.smallTextIcon} />Data’s authenticity is verified against blockchain ledger
                        </span>) : (<span className={classes.smallTextIcon}>
                            <CancelIcon style={{ "color": "red" }} className={classes.smallTextIcon} />Data looks like tampered. Hashes don't match from blockchain
                        </span>))
                        : ("")}


                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div style={{
                            background: "#f8f8f8", height: "140%", overflow: "auto"
                        }}>
                            {/*< ReactJson displayDataTypes={false} displayObjectSize={false} src={showDocumentJSON} style={{ padding: theme.spacing(2) }
                            } ></ReactJson>*/}
                            <div className={classes.flexItem}>
                                <p>Document</p>
                                <div style={{ maxWidth: "1400px", maxHeight: "100%", border: "1px solid", borderColor: "#D4D4D4", marginRight: " 30px", zoom: '1.1', MozTransform: 'scale(1,1.1)', MozTransformOrigin: 'top' }}>
                                    <FormRenderer docName={uploadValues.DocumentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={showDocumentJSON} disable={true} />
                                </div>

                            </div>

                        </div >
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handlePopupClose} color="primary" autoFocus>
                        CLOSE
                    </Button>
                </DialogActions>
            </Dialog>
            <Drawer
                anchor="right"
                open={drawerState.right}
                onClose={closeDrawer('right', false)}
            >
                {sideList('right')}
            </Drawer>
        </Grid >
        : <div></div>
    );
}


