import React, { useState, useRef } from "react";
import useStyles from './styles/AssetStyles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import assetService from '../../services/AssetService';
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import CloseIcon from "@material-ui/icons/Close";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Input, FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import "../../css/style.css";
import Util from "../../utils/Util";
import notify from "../../utils/notifier";
import Tooltip from '@material-ui/core/Tooltip';
import Highlight from 'react-highlighter';
import UploadAssets from '../UploadAssets';
import Drawer from '@material-ui/core/Drawer';
import TablePagination from "@material-ui/core/TablePagination";
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import {
    _columns,
    userPrefs,
    MenuProps
} from './AssetUtils'

const highlightableColumns = ["originalAssetName", "assetReference", "currentOwnerOrganizationName", "currentStateDescription"];

export default function Assets(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [columns, setColumns] = React.useState(_columns);
    const [rows, setRows] = React.useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [orderBy, setOrderBy] = React.useState("updatedAt");
    const [order, setOrder] = React.useState("desc");
    const [queryCount, setQueryCount] = useState(0);
    const [originalQueryCount, setOriginalQueryCount] = useState(0);
    const [searchKey, setSearchKey] = useState({ value: "" });
    const [newAsset, setNewAsset] = React.useState(0);
    const [drawerState, setDrawerState] = React.useState({
        right: false,
    });

    const [selectedArrays, setSelectedArrays] = React.useState({
        channelName: [],
        currentOwnerOrganizationName: [],
        subAssetTypeName: [],
    });
    const [inputArrays, setInputArrays] = React.useState({
        channelName: [],
        currentOwnerOrganizationName: [],
        subAssetTypeName: [],
    });

    const setInitialState = () => {

    }

    const fetchAssets = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = null, prop = null, filterArray = null) => {
        let organizationId = localStorage.getItem('_orgID');
        setBackdrop(true);
        let query = {
            '_page': currentPage + 1,
            '_limit': pageSize,
            '_sort': sortBy,
            '_order': sortOrder
        }

        if (searchText) {
            query['q'] = searchText;
        }

        if (prop == "channelName") {
            if (filterArray.length > 0) {
                query["_channelName"] = filterArray;
            }
        } else {
            if (selectedArrays["channelName"].length > 0) {
                query["_channelName"] = selectedArrays["channelName"];
            }
        }

        if (prop == "currentOwnerOrganizationName") {
            if (filterArray.length > 0) {
                query["_currentOwnerOrganizationName"] = filterArray;
            }
        } else {
            if (selectedArrays["currentOwnerOrganizationName"].length > 0) {
                query["_currentOwnerOrganizationName"] = selectedArrays["currentOwnerOrganizationName"];
            }
        }
        if (prop == "subAssetTypeName") {
            if (filterArray.length > 0) {
                query["_subAssetType"] = filterArray;
            }
        } else {
            if (selectedArrays["subAssetTypeName"].length > 0) {
                query["_subAssetType"] = selectedArrays["subAssetTypeName"];
            }
        }
        fetchData(organizationId, query, searchText);

    }

    const fetchData = (organizationId, query, searchText = null) => {
        assetService.getAssetsByOrgId(organizationId, query).then(
            response => {
                let data = response.data;
                setRows(data);
                if (response.headers.hasOwnProperty('x-total-count')) {
                    setQueryCount(parseInt(response.headers['x-total-count']));
                }

                if (!searchText) {
                    setOriginalRows(data);
                    setOriginalQueryCount(parseInt(response.headers['x-total-count']));
                }

                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching assets"
                });
            }
        );

        assetService.getUniqueColumnValuesAssetsSharedWithOrgId(organizationId).then(
            response => {
                let _assetData = response.data;
                let channelArray = []
                for (let j = 0; j < _assetData[0].length; j++) {
                    if (_assetData[0][j]["channel_name"] && _assetData[0][j]["channel_name"].length) {
                        channelArray.push(_assetData[0][j]["channel_name"])
                    }
                }

                let currentOwnerArray = []
                for (let j = 0; j < _assetData[1].length; j++) {
                    if (_assetData[1][j]["currentOwnerOrganizationName"] && _assetData[1][j]["currentOwnerOrganizationName"].length) {
                        currentOwnerArray.push(_assetData[1][j]["currentOwnerOrganizationName"])
                    }
                }


                let subAssetArray = []
                for (let j = 0; j < _assetData[2].length; j++) {
                    if (_assetData[2][j]["subAssetTypeName"] && _assetData[2][j]["subAssetTypeName"].length) {
                        subAssetArray.push(_assetData[2][j]["subAssetTypeName"])
                    }
                }
                // setDictChannels(tempDict);
                setInputArrays({
                    "channelName": channelArray,
                    "currentOwnerOrganizationName": currentOwnerArray,
                    "subAssetTypeName": subAssetArray,
                });
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching filter values"
                });
            }
        );

    }


    const handleChangeMulitpleSelect = prop => (event) => {
        let newArray = Array.from(event.target.value);
        selectedArrays[prop] = newArray;
        setSelectedArrays((prevState) => {
            return { ...prevState, [prop]: newArray };
        });

        fetchAssets(rowsPerPage, 0, orderBy, order, "", null, prop, event.target.value);
    };

    const placeHolders = {
        channelName: "Channel",
        currentOwnerOrganizationName: "Current Owner",
        subAssetTypeName: "Asset Type"
    }

    function refreshTable() {
        setDrawerState({ ...drawerState, ["right"]: false });
        fetchAssets();
        setInitialState();
    }

    const buttonClickClearAllFilters = () => {
        let doRefresh = false
        if (selectedArrays.channelName.length > 0 || selectedArrays.currentOwnerOrganizationName.length > 0 || selectedArrays.subAssetTypeName.length > 0) {
            doRefresh = true
        }
        selectedArrays.channelName = []
        selectedArrays.currentOwnerOrganizationName = []
        selectedArrays.subAssetTypeName = []
        if (doRefresh) {
            refreshTable();
        }
    }

    React.useEffect(() => {
        fetchAssets();
    }, []);

    const sideDrawerMap = {
        "RowClickDocument": 0,
        "UploadAsset": 1
    }

    const tableHeadWidth = (100 / _columns.length) + "%";

    let timeout = null;
    const handleSearch = event => {
        const _val = event.target.value;
        setSearchKey(prevState => {
            return { ...prevState, value: _val };
        });
        if (_val.length <= 2) {
            setRows(originalRows);
            setQueryCount(originalQueryCount);
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchAssets(rowsPerPage, 0, orderBy, order, _val);
        }, 200);
    };

    const clearSearchText = () => {
        setSearchKey({ value: "" });
        fetchAssets(rowsPerPage, 0, orderBy, order, "");
    };

    const buttonClickUploadNewAsset = (side, open, row) => event => {
        setNewAsset(sideDrawerMap["UploadAsset"]);
        setDrawerState({ ...drawerState, ["right"]: true });
    }

    const handleRequestSortTable = property => event => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
        fetchAssets(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value);
    };

    const createNoAssetTable = () => {
        let arr = []
        arr.push(
            <TableRow style={{ height: "60px" }}>
                <TableCell colSpan={columns.length} align="center" width={tableHeadWidth} className={classes.noRecordsRow}>
                    {backdrop ? " Fetching the assets of your organization, please wait" : "No assets are found."}
                </TableCell>
            </TableRow>
        )
        return arr;
    }

    const handleClickonRow = (event, row) => {
        props.history.push({ pathname: "/assets/" + row.id, state: row });
    };

    const createAssetTableInner = (row, assetNo) => {
        let table = [];
        let tableColumns = columns;

        let fileExtensionPreviewAllowed = ['pdf', 'png', 'jpg', 'html', 'jpeg'];

        for (let i = 0; i < tableColumns.length; i++) {
            var column = tableColumns[i]
            const value = row[column.id];
            let originalAssetName = row['originalAssetName'];
            let fileExt = originalAssetName ? originalAssetName.split('.').slice(-1).pop() : "";

            if (column.id === "version") {
                table.push(
                    <TableCell key={column.id} align={column.align} width={column.width} onClick={event => handleClickonRow(event, row)}>
                        v{column.format && typeof value === "number" ? (column.format(value)) : (value)}
                    </TableCell>
                )
            } else if (column.id === "Link") {
                table.push(
                    <TableCell key={column.id} width={column.width} align={column.align}>
                        <div className={classes.actionDiv}>
                            <Tooltip title={fileExtensionPreviewAllowed.includes(fileExt) ? "Preview" : "Preview is not supported for this file type"}>
                                <span>
                                    <Button className={classes.buttonDownload} disabled={(fileExtensionPreviewAllowed.includes(fileExt) ? false : true)} onClick={event => handleClickPreviewDocument(event, row)}>
                                        <DescriptionIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={"Download"}>
                                <span>
                                    <Button className={classes.buttonDownload} onClick={event => handleClickDownloadDocument(event, row)}>
                                        <CloudDownloadIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            {/* <Tooltip title="Update">
                                <Button className={classes.buttonDownload} onClick={event => handleClickRowDocument(event, row, true)}>
                                    <EditIcon />
                                </Button>
                            </Tooltip> */}
                        </div>
                    </TableCell>
                )
            }
            else if (column.id === "assetNo") {
                table.push(
                    <TableCell key={column.id} width={column.width} align={column.align} className={classes.row_pointer} onClick={event => handleClickonRow(event, row)}>
                        {assetNo}
                    </TableCell>
                )
            }
            else if (column.id === "updatedAt") {
                table.push(
                    <TableCell key={column.id} width={column.width} align={column.align} className={classes.row_pointer} onClick={event => handleClickonRow(event, row)}>
                        {`${value.split('T')[0]} ${value.split('T')[1].split('.')[0]}`}
                    </TableCell>
                )
            }
            else {
                if (highlightableColumns.includes(column.id)) {
                    table.push(
                        <TableCell key={column.id} width={column.width} align={column.align} className={classes.row_pointer} onClick={event => handleClickonRow(event, row)}>
                            <Highlight search={searchKey.value}>
                                {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                            </Highlight>
                        </TableCell>
                    )
                } else {
                    table.push(
                        <TableCell key={column.id} width={column.width} align={column.align} className={classes.row_pointer} onClick={event => handleClickonRow(event, row)}>
                            {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                        </TableCell>
                    )
                }
            }
        }
        return table
    }

    const createAssetTable = (rows) => {
        let table = [];
        let assetNo = 1 + page * rowsPerPage;

        rows.map((row, index) => {
            table.push(
                <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index}  >
                    {createAssetTableInner(row, assetNo)}
                </TableRow>
            )
            assetNo += 1;
        });

        return table;
    }

    const handleClickPreviewDocument = (event, row) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true);

        assetService.downloadAsset(row.id, row.version).then(
            response => {
                setBackdrop(false);

                var type = response.headers["content-type"];
                if (type === 'application/octet-stream') {
                    type = 'application/pdf';
                }

                const blob = new Blob([response.data], {
                    type: type,
                    encoding: "UTF-8"
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                window.open(link.href)
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading asset."
                    });
                }


            }
        );
    };

    const handleClickDownloadDocument = (event, row) => {
        setBackdrop(true);
        assetService.downloadAsset(row.id, row.version).then(
            response => {
                setBackdrop(false)
                Util.downloadTemplate(response);
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading asset."
                    });
                }
            }
        );
    };

    function refreshAssetTable() {
        setInitialState();
        setDrawerState({ ...drawerState, ["right"]: false });
        fetchAssets();
    }

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setInitialState();
    };

    const newDocumentSideList = side => (
        <UploadAssets refreshAssetTable={refreshAssetTable} fromPage={"assets"} closeDrawer={closeDrawer}
        />
    );


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchAssets(rowsPerPage, newPage, orderBy, order, searchKey.value);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        fetchAssets(+event.target.value, 0, orderBy, order, searchKey.value);
    };

    return (
        <div className={classes.borderMain}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className={classes.root}>
                <Grid item xs={12} style={{ minHeight: "87vh" }}>
                    <div className={classes.tableTitleDiv} >
                        <div className={classes.tableTitle}>
                            <h2>{"Assets"}</h2>
                        </div>
                        <div className={classes.tableHeadRight}>
                            <div className={classes.searchBox}>
                                <InputBase
                                    onChange={handleSearch}
                                    value={searchKey.value}
                                    className={classes.input}
                                    placeholder={"Search Assets"}
                                />
                                {
                                    searchKey.value.length ? (
                                        <IconButton
                                            size="medium"
                                            aria-label="search"
                                            onClick={clearSearchText}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                            ""
                                        )
                                }
                                <IconButton
                                    size="medium"
                                    aria-label="search"
                                    disabled
                                    className={classes.searchIcon}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                            <div className={classes.UploadButtonDiv}>
                                <Button
                                    color="primary"
                                    className={classes.UploadButton}
                                    onClick={buttonClickUploadNewAsset("right", true)}
                                    startIcon={<AddCircleIcon />}
                                >
                                    {"Upload Assets"}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.tableHeadRightFilters}>
                        {
                            <div className={classes.filterDiv}>
                                <div className={classes.filterTextFieldDiv}>
                                    <Grid item xs={12} style={{ minHeight: "7vh" }}>
                                        {
                                            (columns.length ? columns.map(column => (
                                                column.isFilterable ?
                                                    <FormControl variant="outlined" className={classes.filterTextField}>
                                                        <InputLabel id="demo-mutiple-checkbox-label">{placeHolders[column.id]}</InputLabel>
                                                        <Select
                                                            labelId="demo-mutiple-checkbox-label"
                                                            id="demo-mutiple-checkbox"
                                                            multiple
                                                            value={selectedArrays[column.id]}
                                                            onChange={handleChangeMulitpleSelect(column.id)}
                                                            input={<Input />}
                                                            renderValue={(selected) => selected.join(', ')}
                                                            MenuProps={MenuProps}
                                                            variant="outlined"
                                                        >
                                                            {inputArrays[column.id].map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    <Checkbox checked={selectedArrays[column.id].indexOf(name) > -1} />
                                                                    <ListItemText primary={name} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    :
                                                    ""

                                            )) : "")
                                        }
                                    </Grid>
                                </div>
                                <div className={classes.clearFilterButton}>
                                    <Button
                                        color="primary"
                                        onClick={buttonClickClearAllFilters}
                                    >
                                        {"Clear All"}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={classes.tableWrapper}>
                        <Paper className={classes.tableContainer}>
                            <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                <TableHead>
                                    <TableRow>
                                        {columns.map(column => (
                                            <TableCell key={column.id} className={classes.tableHeadRow} width={column.width} align={"center"} sortDirection={orderBy === column.id ? order : false} >
                                                {column.sortable ? (
                                                    <TableSortLabel className={classes.tableColumnLabel} active={orderBy === column.id} direction={orderBy === column.id ? order : 'asc'} onClick={handleRequestSortTable(column.id)}>
                                                        {column.label}
                                                        {orderBy === column.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {order === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                ) : (column.label)}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length === 0 ? createNoAssetTable() : createAssetTable(rows)}
                                </TableBody>
                            </Table>
                        </Paper>
                    </div>

                    <Drawer
                        anchor="right"
                        open={drawerState.right}
                    >
                        {newAsset === 1 ? newDocumentSideList('right') : ""}
                    </Drawer>
                    <div className={classes.tableFooterDiv}>
                        <div className={classes.tablePaginationDiv}>
                            <TablePagination
                                rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                                component="div"
                                count={queryCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                backIconButtonProps={{
                                    "aria-label": "previous page"
                                }}
                                nextIconButtonProps={{
                                    "aria-label": "next page"
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                labelRowsPerPage={"Rows per page"}
                            />
                        </div>
                    </div>
                </Grid>
            </Paper>
        </div>
    )
}