import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

const useStyles = makeStyles({

    root: {
        width: "100%",
        background: "white"
    },
    tableTitleDiv: {
        display: "flex",
        paddingLeft: "20px",
        flexDirection: 'row',
    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    },
    shipmentInfopara: {
        background: "#f8f8f8",
        // padding: "10px 40px 80px 0px",
        padding: "20px 20px 60px 10px",
        // height: '40%',
        marginLeft: '15px',
        marginRight: '15px',
    },
    inputBox: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        width: theme.spacing(25),
        marginRight: theme.spacing(3),
        height: theme.spacing(4.5),
        borderColor: 'black',
        // border: "0.8px solid #757575",


    },
    submitFilterButton: {
        marginRight: "1rem",
        float: "right",
    },
    formControl: {
        paddingRight: "0.5rem",
        minWidth: 180,
        maxWidth: 180,
        marginTop: theme.spacing(3),
        paddingBottom: '0px',
        marginLeft: theme.spacing(3),
    },
    paper_root: {
        // alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '5px',

    },
    detailsDiv1: {
        minWidth: '40vw',
        maxWidth: '40vw',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
    },
    detailsDiv: {
        minWidth: '50vw',
        maxWidth: '50vw',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(1),
    },
    smallTextIcon: {

    },
    detailsDivSpan: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    detailsDivSpan1: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    detailsLabel: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
        fontSize: '0.95rem',
    },
    detailsValue: {
        paddingTop: theme.spacing(1),
        fontSize: '0.95rem',
    },
    detailsNum: {
        paddingTop: theme.spacing(1),
        // marginLeft: theme.spacing(185),
        marginRight: theme.spacing(45),
        fontSize: '0.95rem',
    },
    detailsLogs: {
        fontWeight: 'bold',
        padding: theme.spacing(3),
    },
    blockNumber: {
        paddingTop: '3px',
        // fontSize: '0.95rem',
        fontSize: 16,
        paddingLeft: '25px'
    },
    tableBorder: {
        border: "0.8px solid #00000021"
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier",
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingTop: "0px",
        paddingRight: theme.spacing(2),
    },
    tableContainer: {
        // margin: theme.spacing(3),
        padding: "20px",
        paddingTop: "0px"
    },
    tableColumn: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '0.95rem',
    },
    tableValueLatestTransaction: {
        textAlign: 'center',
        fontSize: '0.85rem',
    },
    latestTransactionHeading: {
        paddingLeft: theme.spacing(2),
        paddingTop: "0px",
        paddingRight: theme.spacing(2),
    },
    tableValue: {
        textAlign: 'center',
        fontSize: '0.95rem',
    },
    errorText: {
        padding: theme.spacing(2),
        color: 'red',
        paddingLeft: theme.spacing(4),
        fontWeight: 'bold',
        paddingBottom: '2px',
    },
    labelRoot: {
        fontSize: 16,
        fontWeight: "bold",
        color: "black",
        "&$labelFocused": {
            color: "black",
            fontWeight: "bold",
            fontSize: 16,
        }
    },
    labelFocused: {}
});
export default useStyles;