import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({

    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: "none"
    },
    floatLeft: {
        float: "left"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(3)
    },
    flexItem: {
        flex: 1
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    hiddenField: {
        visibility: "hidden",
        padding: "20px"
    },
    fileUploadWrapper: {
        background: "#f8f8f8",
        minHeight: "75px",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        padding: "20px"
    },
    dialogPaper: {
        minWidth: "50vw"
    },
    successColour: {
        color: "green",
        verticalAlign: "bottom"
    },
    failureColour: {
        color: "red",
        verticalAlign: "bottom"
    }
}));

// Inspired by the Facebook spinners.
const useStylesFacebook = makeStyles({
    root: {
        position: 'relative',
        verticalAlign: "middle"
    },
    top: {
        color: '#eef3fd',
    },
    bottom: {
        color: '#6798e5',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
});


function Progress(props) {
    const classes = useStylesFacebook();

    return (
        <span className={classes.root}>
            <CircularProgress
                variant="determinate"
                value={100}
                className={classes.top}
                size={24}
                thickness={4}
                {...props}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                className={classes.bottom}
                size={24}
                thickness={4}
                {...props}
            />
        </span>
    );
}


function PaperComponent(props) {
    return (
        <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function EventsMessagesModal(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.handleCloseModal();
    };

    return (
        <Dialog classes={{ paper: classes.dialogPaper }} open={props.open} PaperComponent={PaperComponent}>
            <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                We are processing your requests.
                </DialogTitle>
            <DialogContent>

                <Typography>
                    {props.addOrgState == null
                        ?
                        <div><Progress /> <span>Creating the requested organization</span></div>
                        :
                        props.addOrgState
                            ?
                            <div><CheckCircleIcon className={classes.successColour} /> Successfully created the organization</div>
                            :
                            <div><CancelIcon className={classes.failureColour} /> Failed to create organization</div>}
                </Typography>
                <br></br>
                <Typography>
                    {props.enrollAdminState == null
                        ?
                        <div><Progress /> Enrolling the admin</div>
                        :
                        props.enrollAdminState
                            ?
                            <div><CheckCircleIcon className={classes.successColour} /> Enroll Admin Successful</div>
                            :
                            <div><CancelIcon className={classes.failureColour} /> Failed to enroll admin</div>}
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="secondary">
                    {"CLOSE"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
