import { LENGTH_BREADTH_HEIGHT, ONLY_NUMBERS_REGEX } from "../../utils/constants";
import React from "react";
import MenuItem from '@material-ui/core/MenuItem';

const origins = [
  'Tokyo',
  'London',
  'Chennai',
  'Shanghai',
  'Dubai',
  'Sharjah',
  'Hyderabad'
]

const destinations = [
  'Tokyo',
  'London',
  'Chennai',
  'Shanghai',
  'Dubai',
  'Hyderabad',
  'Sharjah'
]

export const _columns = [
  { id: "BookingNo", label: "ID", minWidth: 10, width: '5%', isFilterable: false, fitlerTypeSearch: false },
  { id: "isDocumentUploaded", label: "Info", minWidth: 170, width: '5%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  //{ id: "importerName", label: "Importer", minWidth: 170 },
  //{ id: "exporterName", label: "Exporter", minWidth: 170 },
  { id: "podName", label: "Origin Port", minWidth: 170, width: '10%', sortable: true, isFilterable: true, fitlerTypeSearch: true },
  { id: "poaName", label: "Destination Port", minWidth: 170, width: '10%', sortable: true, isFilterable: true, fitlerTypeSearch: true },
  // { id: "origin", label: "Origin", minWidth: 170, sortable: true },
  // { id: "destination", label: "Destination", minWidth: 170, sortable: true },
  { id: "channelName", label: "Associated Channel", minWidth: 170, width: '10%', isFilterable: true, fitlerTypeSearch: true },
  { id: "carrier", label: "Carrier", minWidth: 170, width: '10%', sortable: true, isFilterable: true, fitlerTypeSearch: true },
  { id: "vessel", label: "Vessel Voyage", minWidth: 170, width: '10%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  //{ id: "voyage", label: "Voyage", minWidth: 170, sortable: true },
  { id: "referenceNumber", label: "Reference Number", minWidth: 170, width: '13%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: "bookingNumber", label: "External Booking No", minWidth: 170, width: '11%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: "eta", label: "ETA", minWidth: 170, width: '8%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: "ata", label: "ATA", minWidth: 170, width: '8%', sortable: true, isFilterable: false, fitlerTypeSearch: false },


  // { id: "containerNumber", label: "Container Number", minWidth: 170, sortable: true },
  // { id: "edit", label: "Action", minWidth: 170 },
];

export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const disableSubmitButtonEditShipment = (VesselVoyage, ContainerNumber) => {
  if (VesselVoyage && ContainerNumber) {
    return false;
  }
  return true;
};


export const disableSubmitButtonBookingRefNumber = (channel, bookingNumber, referenceNumber) => {
  if (channel) {
    if (bookingNumber || referenceNumber) {
      return false;
    }
  }
  return true;
};

export const createMovementType = (movementType) => {
  let table = []
  for (let i = 0; i < movementType.length; i++) {
    table.push(
      <MenuItem key={movementType[i]} value={movementType[i]}>
        {movementType[i]}
      </MenuItem>
    )
  }
  return table
}

export const createPorts = (portsMap, excludeValue) => {
  let table = []
  for (let i in portsMap) {
    if (i != excludeValue) {
      table.push(
        <MenuItem key={portsMap[i]} value={i}>
          {portsMap[i]}
        </MenuItem>
      )
    }
  }
  return table
}

export const createPortOfArrivals = (portOfArrivals, valuesPortOfDeparture) => {
  let table = []
  for (let i = 0; i < portOfArrivals.length; i++) {
    var portOfArrivalVal = portOfArrivals[i]
    if (portOfArrivalVal === valuesPortOfDeparture) {

    } else {
      table.push(
        <MenuItem key={portOfArrivalVal} value={portOfArrivalVal}>
          {portOfArrivalVal}
        </MenuItem>
      )
    }
  }
  return table
}

export const createImporters = (importers, valuesExporter) => {
  let table = []
  for (let i = 0; i < importers.length; i++) {
    var importer = importers[i];
    if (importer === valuesExporter) {

    } else {
      table.push(
        <MenuItem key={importer} value={importer}>
          {importer}
        </MenuItem>
      )
    }
  }
  return table
}


export const createExporters = (exporters, valuesImporter) => {
  let table = []
  for (let i = 0; i < exporters.length; i++) {
    var exporter = exporters[i]
    if (exporter === valuesImporter) {

    } else {
      table.push(
        <MenuItem key={exporter} value={exporter}>
          {exporter}
        </MenuItem>
      )
    }
  }
  return table
}

export const createPortOfDepartures = (portOfDepartures, valuesPortOfArrival) => {
  let table = []
  for (let i = 0; i < portOfDepartures.length; i++) {
    var portOfDepartureVal = portOfDepartures[i]
    if (portOfDepartureVal === valuesPortOfArrival) {

    } else {
      table.push(
        <MenuItem key={portOfDepartureVal} value={portOfDepartureVal}>
          {portOfDepartureVal}
        </MenuItem>
      )
    }
  }
  return table
}

export const createOrigins = (valuesDestination) => {
  let table = []
  for (let i = 0; i < origins.length; i++) {
    var origin = origins[i]
    if (origin === valuesDestination) {
      ////// console.log('----------*******-- ', values.Destination)
    } else {
      table.push(
        <MenuItem key={origin} value={origin}>
          {origin}
        </MenuItem>
      )
    }
  }
  return table
}

export const createDestinations = (valuesOrigin) => {
  let table = []
  for (let i = 0; i < destinations.length; i++) {
    var destination = destinations[i]
    if (destination === valuesOrigin) {

    } else {
      table.push(
        <MenuItem key={destination} value={destination}>
          {destination}
        </MenuItem>
      )
    }
  }
  return table
}

export const createChannels = (channels) => {
  let table = []
  for (let i = 0; i < channels.length; i++) {
    var channel = channels[i]
    table.push(
      <MenuItem key={channel} value={channel}>
        {channel}
      </MenuItem>
    )
  }
  return table
}

export const createOrgDropdown = (orgMembers) => {
  let table = []
  for (let i = 0; i < orgMembers.length; i++) {
    let org = orgMembers[i]
    table.push(
      <MenuItem key={org.orgId} value={org.orgId}>
        {org.organizationName}
      </MenuItem>
    )
  }
  return table;
}

export function checkIfNotNumber(val) {
  if (val == "" || val == null || ONLY_NUMBERS_REGEX.test(val)) {
    return false
  }
  return true
}

export function checkIfLBH(val) {
  if (val == "" || val == null || LENGTH_BREADTH_HEIGHT.test(val)) {
    return false
  }
  return true
}

export const disableAddProductButton = () => {
  return false;
};

export const disableAddContainerButton = () => {
  return false;
};


function finalContainersArray(containerData) {
  let tempFinalProductArray = [];
  for (let j in containerData) {
    let productArray = [];
    for (let i = 0; i < containerData[j]["products"].length; i++) {
      delete containerData[j]["products"][i]["validationCheckId"];
      productArray.push({
        "productCode": containerData[j]["products"][i]["productNumber"],
        "description": containerData[j]["products"][i]["productDescription"],
        "hsCode": containerData[j]["products"][i]["productHsCode"],
        "poNumber": containerData[j]["products"][i]["purchaseOrderNumber"],
        "unitPrice": containerData[j]["products"][i]["unitPrice"],
        "quantity": containerData[j]["products"][i]["quantity"]
      })
    }
    delete containerData[j]["products"];
    delete containerData[j]["validationCheckId"];
    containerData[j]["trackingState"] = "ACTIVE";
    containerData[j]["products"] = productArray;
    tempFinalProductArray.push(containerData[j]);
  }
  return tempFinalProductArray;
}

const movementTypeIDMap = {
  "CY-CY": 301,
  "CY - Door": 302,
  "Door - CY": 303,
  "Door - Door": 304
}

export const buildRequestObj = (portsMap, values, channelIDMap, containerData) => {
  var formData = {};
  formData["importer"] = values.Importer;
  formData["exporter"] = values.Exporter;
  formData["origin"] = values.Origin;
  formData["destination"] = values.Destination;
  formData["channelId"] = channelIDMap[values.Channel];
  formData["poaCode"] = values.poaCode;
  formData["podCode"] = values.podCode;
  formData["vessel"] = values.Vessel;
  formData["voyage"] = values.Voyage;
  formData["portOfArrival"] = values.PortOfArrival//portsMap[values.poaCode];
  formData["portOfDeparture"] = values.PortOfDeparture//portsMap[values.podCode];
  formData["containers"] = finalContainersArray(containerData)
  formData["movementType"] = movementTypeIDMap[values.movementType]
  formData["carrier"] = values.Carrier
  formData["shipmentReference"] = values.shipmentReference
  return formData;
};

export const buildRequestObjEditShipment = (organizationID, editRow, values) => {
  var formData = {};
  formData["ownerId"] = organizationID
  formData["importerId"] = editRow["importerId"];
  formData["exporterId"] = editRow["exporterId"];
  formData["origin"] = editRow["origin"];
  formData["destination"] = editRow["destination"];
  formData["channelId"] = editRow["channelId"];
  formData["poaId"] = editRow["poaId"];
  formData["podId"] = editRow["podId"];
  formData["vesselVoyage"] = values.VesselVoyage
  formData["containerNumber"] = values.ContainerNumber
  return formData;
};
