import React, { useState } from "react";

import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';

import { FormControl } from '@material-ui/core';
import useStyles from './styles/TransactionSearchStyles';


import MenuItem from '@material-ui/core/MenuItem';
import channelService from "../../services/ChannelService";
import chaincodeService from "../../services/ChaincodeService";
import notify from '../../utils/notifier';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            paddingBottom: 0,
        },
    },
    classes: {
        list: {
            paddingTop: 0,
            paddingBottom: 0,
        },

    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
        paddingBottom: 0,
    },
    getContentAnchorEl: null

};

export default function TransactionSearch(props) {

    const classes = useStyles();
    const [channels, setChannels] = React.useState([]);
    const [selectedChannel, setSelectedChannel] = React.useState('');
    const [trxId, setTrxId] = useState({ value: "" });
    const [transactionDetails, setTransactionDetails] = React.useState('');
    const [blockNumber, setBlockNumber] = React.useState('');
    const [backdrop, setBackdrop] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [disabledBackButton, setDisabledBackButton] = React.useState(true);
    const fetchData = () => {
        setBackdrop(true);
        channelService.getChannelsByOrgId(parseInt(localStorage.getItem('_orgID'))).then(
            response => {
                setBackdrop(false);
                const _channelData = response.data;

                setChannels(response.data);
                if (props.location.state) {
                    let ch = _channelData.filter(x => x.id === props.location.state.channel.id);
                    setSelectedChannel(ch[0]);
                    handleSearchTransaction(props.location.state.transactionId, props.location.state.channel);
                }
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching channels data by org ID"
                });
            }
        );

    };
    const handleChannel = (event) => {
        setError(false);
        setSelectedChannel(event.target.value);
    };

    const handleTrxId = event => {
        const _val = event.target.value;
        setError(false);
        setTrxId(prevState => {
            return { ...prevState, value: _val };
        });
    }

    const clearFields = () => {
        setTrxId({ value: "" });
        setSelectedChannel('');
        setError(false);
    };

    const handleSearchTransaction = (trxId, selectedChannel) => {
        setBackdrop(true);
        chaincodeService.getTransactionById(selectedChannel.id, trxId).then(
            response => {
                let transactionData = response.data;
                chaincodeService.getBlockByTxId(selectedChannel.id, trxId).then(
                    response => {
                        if (response.data.height) {
                            setBlockNumber(response.data.height);
                            setTransactionDetails(transactionData);
                            setBackdrop(false);
                        }
                        else {
                            setTransactionDetails('');
                            setBackdrop(false);
                            setError(true);
                            notify({
                                type: "error",
                                message: "No transaction details found"
                            });
                        }
                    },
                    error => {
                        setBackdrop(false);
                        notify({
                            type: "error",
                            message: "Error while fetching block  details"
                        });
                    }
                );
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching transaction  details"
                });
            }
        );
    };
    const handleBlock = () => {
        var newState = { "blockNumber": props.location.state.blockNum, "selectedChannel": props.location.state.channel, "totalBlock": props.location.state.totalBlocks }
        props.history.push({ pathname: '/blocks/', state: newState });
    }

    React.useEffect(() => {

        if (props.location.state) {
            setTrxId(prevState => {
                return { ...prevState, value: props.location.state.transactionId };
            });
            fetchData();
            setDisabledBackButton(false);
        }
        else {
            fetchData();
        }

    }, [props.location.state]);

    return (
        <div className={classes.borderMain}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className={classes.root}>
                <Grid item xs={12} style={{ minHeight: "87vh" }}>
                    <div className={classes.tableTitleDiv} >
                        <div className={classes.tableTitle}>
                            {disabledBackButton ? '' :
                                <ArrowBackIcon size="medium"
                                    className={classes.backIcon}
                                    onClick={handleBlock}
                                />
                            }
                            <h2>{"Transaction Search"}</h2>
                        </div>
                    </div>
                    <div className={classes.box}>
                        <div className={classes.paper_root}>
                            <div>
                                <FormControl>
                                <TextField
                                    variant="outlined"
                                    className={classes.inputBox}
                                    margin="normal"
                                    rows="2"
                                    autoComplete='off'
                                    onChange={handleTrxId}
                                    value={trxId.value}
                                    label="Enter Transaction Id"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                        }
                                    }}
                                />
                            </FormControl>
                                {error ?
                                    <div className={classes.errorText} >Error while fetching transaction details, this transaction Id does not exist</div> : ""}
                            </div>
                            <FormControl className={classes.formControl} >
                                <TextField
                                    select
                                    value={selectedChannel}
                                    label={"Select Channel"}
                                    onChange={handleChannel}
                                    fullWidth
                                    variant="outlined"
                                    SelectProps={MenuProps}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                        }
                                    }}
                                >
                                    {channels.map((member) => (<MenuItem key={member.channelName} value={member}>{member.channelName}</MenuItem>))} </TextField>
                            </FormControl>

                        </div>
                        <div
                            style={{ display: 'flex', float: "right", marginRight: '20px', overflow: 'auto' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.submitFilterButton}
                                onClick={() => handleSearchTransaction(trxId.value, selectedChannel)}
                                disabled={trxId.value === '' || selectedChannel === ''}
                            >
                                {"Search"}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.submitFilterButton}
                                onClick={clearFields}
                                disabled={trxId.value === '' && selectedChannel === ''}
                            >
                                {"Clear"}
                            </Button>

                        </div>

                    </div>
                    {transactionDetails === '' ? " " :

                        <div className={classes.tableBorder}>
                            <h3 style={{ paddingLeft: '12px' }}>Transaction Details</h3>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.detailsDiv}>

                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Transaction Id"}</label>
                                        <span className={classes.detailsValue} >{transactionDetails.transactionId}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Nonce"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.nonce}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Chaincode Name"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.chaincode.name}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Chaincode Version"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.chaincode.version}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Endorsers"}</label>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {transactionDetails.endorsers.map((member, i) => {
                                                return (
                                                    <span>{(i + 1) + ")"} {member.toUpperCase().slice(0, member.length - 3)}</span>
                                                )
                                            })}
                                        </div>
                                    </span>
                                </div>
                                <div className={classes.detailsDiv1}>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Block Number"}</label>
                                        <span className={classes.detailsValue}>{blockNumber}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Transaction Type"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.channelInfo.transactionType}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Channel"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.channelInfo.channelId}</span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Creator"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.creator.toUpperCase().slice(0, transactionDetails.creator.length - 3)} </span>
                                    </span>
                                    <span className={classes.detailsDivSpan} >
                                        <label className={classes.detailsLabel}>{"Timestamp"}</label>
                                        <span className={classes.detailsValue}>{transactionDetails.channelInfo.timestamp}</span>
                                    </span>

                                </div>

                            </div>

                            <label className={classes.detailsLogs}>{"Proposal Arguments"}</label>
                            <div className={classes.box} style={{ paddingBottom: '5px', marginBottom: '5px', paddingTop: '5px' }}>
                                {transactionDetails.propArgs.map(i => {
                                    return (<p>{i}</p>
                                    )
                                })}
                            </div>
                            {(transactionDetails.actions[0].rwset.writes[0] && transactionDetails.actions[0].rwset.writes[0].value) ?
                                <>
                                    <label className={classes.detailsLogs} style={{ paddingBottom: '2px' }}>{"Payload"}</label>
                                    <div className={classes.box} style={{ paddingBottom: '5px', marginBottom: '20px', paddingTop: '5px' }}>
                                        <span className={classes.detailsDivSpan} >
                                            <label className={classes.detailsLabel}>{"Key"}</label>
                                            <span className={classes.detailsValue}>{transactionDetails.actions[0].rwset.writes[0].key}</span>
                                        </span>
                                        {transactionDetails.actions[0].rwset.writes[0].value.documentHash ?
                                            <span className={classes.detailsDivSpan} >
                                                <label className={classes.detailsLabel}>{"Value"}</label>
                                                <span className={classes.detailsValue}>{transactionDetails.actions[0].rwset.writes[0].value.documentHash}</span>
                                            </span> :
                                            <span className={classes.detailsDivSpan} >
                                                <label className={classes.detailsLabel}>{"Value"}</label>
                                                <span className={classes.detailsValue}>{transactionDetails.actions[0].rwset.writes[0].value}</span>
                                            </span> 
                                        }
                                    </div></> : ""}
                        </div>

                    }
                </Grid>
            </Paper>
        </div >
    )
}