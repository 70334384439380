import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const userRole = localStorage.getItem('_userRole');

const theme = createMuiTheme({
  primaryColor: "#fff",
  secondaryColor: "#0000",
  primaryBordercolor: "#00000024",
  typography: {
    useNextVariants: true,
    fontFamily: "LatoLight",
    color: "#006db3"
  },
  palette: {
    primary: {
      light: "#f50057",
      main: userRole === 'ADMIN' ? '#173a64' : '#173a64',
      navigation: userRole === 'ADMIN' ? '#298bc1' : '#298bc1' //f50057
    },

    menuhover: {
      main: "#173a64"
    }
  }
});
let lightTheme = responsiveFontSizes(theme);
export default lightTheme;
