import { useEffect, useRef } from 'react'
import { parseHtml } from './ParseHtml'

export const useHtmlData = (path, fileType) => {
    const container = useRef(null)

    useEffect(() => {
        if (fileType === ".html") {
            const createDocx = async () => {
                const html = await parseHtml(path)
                container.current && (container.current.innerHTML = html)
            }
            createDocx()
        }else if(fileType === ".doc"){
            const createDocx = async () => {
                const html = await parseHtml(path)
                container.current && (container.current.innerHTML = html)
            }
            createDocx()
        }
    }, [path, container])

    return container
}