import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import theme from "../../themes/lightTheme";
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from "@material-ui/core/Grid";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import '../../css/style.css';
import HistoryIcon from '@material-ui/icons/History';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import ReactJson from 'react-json-view';
import { Divider } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import BlockService from "../../services/BlockService"
import notify from "../../utils/notifier";
import { useStyles } from "./styles/TransactionsStyles";
import Skeleton from 'react-loading-skeleton';
import JSONTree from 'react-json-tree';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import chaincodeService from "../../services/ChaincodeService"
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AccessTimeIcon from '@material-ui/icons/AccessTime';



//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};

const writeTheme = {
    base00: '#ffffff',
    base01: '#2e2f30',
    base02: '#515253',
    base03: '#737475',
    base04: '#959697',
    base05: '#b7b8b9',
    base06: '#dadbdc',
    base07: '#fcfdfe',
    base08: '#e31a1c',
    base09: '#e6550d',
    base0A: '#dca060',
    base0B: '#31a354',
    base0C: '#80b1d3',
    base0D: '#3182bd',
    base0E: '#756bb1',
    base0F: '#b15928'
};




export default function Transactions(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [transDetails, setTransDetails] = React.useState(null);
    const [xsValue, setXs] = useState(12);
    const [showTransactionsInfo, setShowTransactionsInfo] = useState(false);
    const [backdrop, setBackdrop] = React.useState(false);

    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const openDrawer = (side, open, transactionId) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (transactionId) {

            setBackdrop(true)
            chaincodeService.getTransactionById(props.channelId, transactionId).then(
                response => {
                    const _data = response.data;
                    setTransDetails(_data);

                    setDrawerState({ ...drawerState, [side]: open });
                    setBackdrop(false);
                },
                error => {
                    setBackdrop(false);
                    notify({
                        type: "error",
                        message: "Error while fetching transaction"
                    });
                }
            );

        }

    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    const handleClick = (transactionId) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        let details = "Transferred 100 from A to B";
        setShowTransactionsInfo(true)
        setTransDetails(details);
        setXs(6);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [transInfo, setTransInfo] = useState([]);
    const fetchData = () => {

        let trans2 = [{
            "payload": {
                "header": {
                    "channel_header": {
                        "type": 1,
                        "version": 0,
                        "timestamp": "2020-01-22T13:03:42.000Z",
                        "channel_id": "dtlp-common-channel",
                        "tx_id": "83492",
                        "epoch": "0",
                        "extension": {
                            "type": "Buffer",
                            "data": []
                        },
                        "typeString": "CONFIG"
                    }
                }
            }
        },
        {
            "payload": {
                "header": {
                    "channel_header": {
                        "type": 1,
                        "version": 0,
                        "timestamp": "2020-01-22T13:03:42.000Z",
                        "channel_id": "dtlp-common-channel",
                        "tx_id": "09989",
                        "epoch": "0",
                        "extension": {
                            "type": "Buffer",
                            "data": []
                        },
                        "typeString": "CONFIG"
                    }
                }
            }
        },
        {
            "payload": {
                "header": {
                    "channel_header": {
                        "type": 1,
                        "version": 0,
                        "timestamp": "2020-01-22T13:03:42.000Z",
                        "channel_id": "dtlp-common-channel",
                        "tx_id": "809342",
                        "epoch": "0",
                        "extension": {
                            "type": "Buffer",
                            "data": []
                        },
                        "typeString": "CONFIG"
                    }
                }
            }
        },
        ];
        let blockNum = props.blockNo;
        let channelName = props.channelName;
        let channelId = props.channelId;
        if (blockNum != "") {

            BlockService.getBlockTransactions(channelId, blockNum).then(
                response => {
                    const _data = response.data;
                    setTransInfo(_data.transactions);

                },
                error => {
                    notify({
                        type: "error",
                        message: "Error while fetching block transactions"
                    });
                }
            );

        } else {
            setTransInfo(trans2);
        }

        //setTransInfo(trans2);


    };


    const sideList = side => (
        <div className={classes.sideBar}>
            <div style={{ overflow: "auto" }}>
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                            className={classes.drawerIcon}

                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>
                        Transaction Details
                    </Typography>



                </Toolbar>
                <Divider></Divider>

            </div>

            <div style={{ height: "140%", overflow: "auto" }}>
                {
                    transDetails != null ? (
                        <div>
                            {
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <TableRow style={{height: "60px"}} key="id" >
                                            <TableCell component="th" scope="row">
                                                <b>Transaction ID</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.transactionId} </TableCell>
                                        </TableRow>
                                        <TableRow style={{height: "60px"}} key="creator">
                                            <TableCell component="th" scope="row">
                                                <b>Creator Organization</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.creator ? transDetails.creator.toUpperCase().slice(0, transDetails.creator.length - 3) : ""}</TableCell>
                                        </TableRow>
                                        <TableRow  style={{height: "60px"}} key="endorser">
                                            <TableCell component="th" scope="row">
                                                <b>Endorsers</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {transDetails != null && transDetails.endorsers.length > 0 ? (
                                                    transDetails.endorsers.map((member, index) => {
                                                        return (
                                                            <div>{(index + 1) + ")"} {member.toUpperCase().slice(0, member.length - 3)}</div>
                                                        );
                                                    })
                                                ) : (
                                                        ""
                                                    )}
                                                {/* <JSONTree
                                                    data={transDetails.endorsers.length > 0 ? transDetails.endorsers.map(i => i.toUpperCase().slice(0, i.length - 3)) : ""}
                                                    theme={writeTheme}
                                                    invertTheme={false}
                                                /> */}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{height: "60px"}} key="channel">
                                            <TableCell component="th" scope="row">
                                                <b>Channel</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.channelId}</TableCell>
                                        </TableRow>
                                        <TableRow style={{height: "60px"}} key="trxntype">
                                            <TableCell component="th" scope="row">
                                                <b>Transaction Type</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.transactionType}</TableCell>
                                        </TableRow>
                                        <TableRow style={{height: "60px"}} key="timestamp">
                                            <TableCell component="th" scope="row">
                                                <b>Timestamp</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.timestamp}</TableCell>
                                        </TableRow>
                                        <TableRow style={{height: "60px"}} key="chaincodeName">
                                            <TableCell component="th" scope="row">
                                                <b>Chaincode Name</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.chaincode.name}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="chaincodeVersion">
                                            <TableCell component="th" scope="row">
                                                <b>Chaincode Version</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.chaincode.version}</TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>
                            }
                        </div>
                    ) : (
                            <Grid item xs={12}>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                            </Grid>
                        )
                }
            </div >

        </div >
    );


    React.useEffect(() => {
        fetchData();
    }, [props.blockNo]);


    return (
        <Grid container item xs={props.xsValue} spacing={3}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Paper style={{ maxHeight: '56vh', overflow: 'auto', width: "100%", marginLeft: "12px" }} elevation={2}>
                <Toolbar style={{ background: "#f8f8f8" }}>
                    <Typography variant="h9" >
                        {/* {props.blockNo != "" ? (<ChevronRightIcon onClick={props.closeTransactionsPane()} style={{ verticalAlign: "middle", cursor: "pointer" }} />) : ("")} */}
                        Transactions  {props.blockNo != "" ? (<span> for Block {props.blockNo}</span>) : ("")}
                    </Typography>
                </Toolbar>
                <Divider></Divider>
                <br></br>
                {transInfo.length != 0 ? (
                    <VerticalTimeline layout="1-column">
                        {transInfo.map((member) => {
                            return (
                                <VerticalTimelineElement
                                    contentStyle={{ background: '#fff', color: '#fff', boxShadow: 'none' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<HistoryIcon />}
                                >
                                    {/* <section className={classes.cardContent} onClick={openDrawer("right", true, member.payload.header.channel_header.tx_id)} >
                                        <div className={classes.smallText}>
                                            <span>
                                                <VerifiedUserIcon className={classes.smallTextIcon} style={{ "color": "green" }} />{member.payload.header.channel_header.tx_id ? <div className={classes.smallText}>{member.payload.header.channel_header.tx_id}</div> : "Not available"}
                                            </span>
                                            <span>
                                                <AccessTimeIcon className={classes.smallTextIcon} />{member.payload.header.channel_header.timestamp}
                                            </span>

                                        </div>
                                    </section> */}

                                    <Card variant="outlined" className={classes.card} onClick={openDrawer("right", true, member.payload.header.channel_header.tx_id)} >
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            <span><b>Transaction Id :</b> &nbsp;{member.payload.header.channel_header.tx_id ? member.payload.header.channel_header.tx_id : "Not available"}</span>
                                        </Typography>
                                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                                            <b>Timestamp:</b> &nbsp;{member.payload.header.channel_header.timestamp}
                                        </Typography>
                                    </Card>
                                </VerticalTimelineElement>
                            );
                        })}
                    </VerticalTimeline>)
                    : (
                        ""
                    )}
            </Paper>

            <Drawer
                anchor="right"
                open={drawerState.right}
                onClose={closeDrawer('right', false)}
            >
                {sideList('right')}
            </Drawer>
        </Grid >

    );
}


