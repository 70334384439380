import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles(theme => ({
    list: {
        width: "30vw",
    },
    root: {
        //width: "100%",
        background: "white",
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier"
    },
    buttonDownload: {
        background: "none",
        padding: 0,
        fontSize: "68.75%",
        fontWeight: "bold"
    },
    buttonUserType: {
        background: "none",
        padding: 0,
        fontSize: "100%",
        // fontWeight: "bold"
    },
    buttonUserTypeDefault: {
        background: "none",
        padding: 0,
        fontSize: "100%",
        // fontWeight: "bold"
    },
    editCheckbox: {
        fontSize: "68.75%",
        fontWeight: "bold",
        // marginLeft: theme.spacing(1),
        // ['@media only screen and (max-width:1300px)']: {
        //     marginLeft: theme.spacing(2),
        // }
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    tableColumnLabel: {
        marginLeft: theme.spacing(3),
    },
    tableColumnLabelOrganizationUsers: {
        marginLeft: theme.spacing(1.2),
    },
    tableColumnLabelLastName: {
        marginLeft: theme.spacing(-0.2),
    },
    tableHeadRightUserManagement: {
        float: "right",
        display: "flex",
        marginRight: theme.spacing(1.5),
    },
    tableHeadRight: {
        float: "right",
        display: "flex"
    },
    tableHeadRightMyOrganization: {
        float: "right",
        display: "flex",
        marginTop: "-3.7vw"
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    tableHeadRow: {
        fontWeight: "700"
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    searchBox: {
        // borderBottom: "0.8px solid #00000021",
        // marginRight: theme.spacing(3),
        borderBottom: "0.8px solid #00000021",
        display: 'flex',
        height: theme.spacing(5),
        // marginTop: theme.spacing(1),
        marginRight: theme.spacing(3)
    },

    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    closeButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        display: "flex",
    },
    addButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(3),
        textAlign: "center",
        display: "flex"
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4),
        margin: theme.spacing(-1)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    textFieldTable: {
        paddingRight: theme.spacing(4),
        margin: theme.spacing(-1)
    },
    button: {
        margin: theme.spacing(1)
    },
    drawerButton: {
        margin: theme.spacing(1),
        float: "right",

    },

    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    networkInfo: {
        padding: "10px",
        paddingLeft: "25px"
    },
    networkInfoPara: {
        background: "#f8f8f8",
        padding: "28px"
    },
    tableBorder: {
        border: "0.8px solid #00000021"
    },
    tableContainer: {
        padding: "20px"
    },
    tableContainerOrganiztionUsers: {
        padding: "20px",
        marginTop: "-10px"
    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        marginLeft: "-10px",
    },
    textBreadCrumbFirstName: {
        border: "none",
        width: theme.spacing(20),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbsFirstNameDefault: {
        border: "none",
        width: theme.spacing(20),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    tableHeadAlign: {
        marginLeft: theme.spacing(7)
    },
    textBreadCrumbsLastNameDefault: {
        border: "none",
        width: theme.spacing(20),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
    },
    textBreadCrumbLastName: {
        border: "none",
        width: theme.spacing(20),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    textBreadCrumbsOnHoverFirstName: {
        border: "0.8px solid #00000021",
        width: theme.spacing(20),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbsOnHoverLastName: {
        border: "0.8px solid #00000021",
        width: theme.spacing(20),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
    },
    pagination: {
        marginRight: theme.spacing(2),
    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem"
    },
    dialogContentText: {
        fontWeight: "700"
    },
    dialogButton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    },

}));