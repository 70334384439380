import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles({
    root: {
        background: "white",
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier"
    },
    searchBox: {
        borderBottom: "0.8px solid #00000021",
        display: 'flex',
        height: theme.spacing(5),
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        marginRight: theme.spacing(3)
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    tableHeadRow: {
        fontWeight: "700"
    },
    tableColumnLabel: {
        marginLeft: theme.spacing(3),
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    card: {
        height: "100%",
        display: "flex",
        margin: theme.spacing(2),
        boxShadow: "2px 2px 10px -5px",
    },
    a: {
        fontWeight: "bold",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: '#E5E7E9'
        }
    },
    list: {
        width: "30vw",
    },
    tabsStyling: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    tabStyling: {
        "&:hover": {
            background: '#E5E7E9'
        }
    },
    contentDiv: {
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginLeft: "24px",
        marginRight: "24px",
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    navigation: {
        display: "flex"
    },
    orgInfo: {
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    tableContainer: {
        padding: "20px"
    },
    tableBorder: {
        border: "0.8px solid #00000021"
    },
    paperCard: {
        width: "100%",
        fontFamily: "courier",
        top: 56,
        right: -24,
        maxHeight: 400,
        padding: theme.spacing(0.4),
        backgroundColor: theme.secondaryColor,
        overflow: "scroll",
        borderTop: "1px solid white",
        display: "flex"

    },
    closeButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    textBreadCrumbDiv: {
        display: "flex",
        // marginTop: "4px"
    },
    textBreadCrumbEditSaveButtonDiv: {
        display: "flex",
        marginTop: theme.spacing(1),
    },
    textBreadCrumbEditSaveButtonDivNone: {
        display: "flex",
        marginTop: theme.spacing(1),
        display: "none"
    },
    textFieldBreadCrumbsNonEdit: {
        display: "inline-block",
        paddingBottom: theme.spacing(1),
    },
    organizationDescriptionInput: {
        width: theme.spacing(30),
        // marginTop: theme.spacing(1.25),
        height: theme.spacing(4.5),
        border: "none"
    },
    textFieldBreadCrumbsEdit: {
        width: theme.spacing(30),
        // marginTop: theme.spacing(1.25),
        border: "none",
        height: theme.spacing(4.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    textFieldBreadCrumbsEditOnHover: {
        width: theme.spacing(30),
        // marginTop: theme.spacing(1.25),
        border: "0.8px solid #00000021",
        height: theme.spacing(4.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    breadCrumbButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    orgDescriptionDiv: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(4),
        height: theme.spacing(4.5),
        display: "block"
    },
    orgDescription: {
        marginBottom: theme.spacing(0.5),
    },
    organizationDescriptionInput: {
        width: theme.spacing(30),
        height: theme.spacing(4.5),
        border: "none"
    },
    organizationDescriptionInputOnHover: {
        width: theme.spacing(30),
        border: "0.8px solid #00000021",
        height: theme.spacing(4.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    descriptionButtonsDiv: {
        display: "flex",
        marginTop: -theme.spacing(0.5)
    },
    descriptionButtonsDivNone: {
        display: "none",
        marginTop: -theme.spacing(0.5)
    },
    textBreadCrumbsDesc: {
        border: "none",
        width: theme.spacing(40),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        marginLeft: theme.spacing(-4.7)
    },
    organizationBreadCrumbDesc: {
        padding: "10px",
        paddingLeft: "36px",
        paddingRight: "20px",
        marginTop: "-1.5%"
    },
    textBreadCrumbDiv: {
        display: "flex",
        // marginTop: "4px"
    },
    textBreadCrumbButtonsDivDesc: {
        marginTop: "-0.9%",
        marginLeft: "-1.5%",
        display: "flex",
        paddingBottom: theme.spacing(0.8),
        height: theme.spacing(4.2)
    },
    textBreadCrumbButtonsDivNone: {
        marginTop: theme.spacing(1),
        display: "none",
        paddingBottom: theme.spacing(0.8),
        height: theme.spacing(4.2)
    },
    breadCrumbButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    textBreadCrumbsOnHoverDesc: {
        width: theme.spacing(40),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
        paddingRight: theme.spacing(2),
        marginLeft: theme.spacing(-4),
    },
});
