import React from "react";
import networkService from "../../services/NetworkService"
import { useStyles } from "./styles/OrganizationStyles";
import { VALID_USER_INPUT_REGEX } from "../../utils/constants";
import organizationService from "../../services/OrganizationService";
import Typography from '@material-ui/core/Typography';
import notify from '../../utils/notifier';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from "@material-ui/core/IconButton";
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import { Input, FormControl, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
    _columns,
    MenuProps,
    disableSubmitButton,
    buildRequestObjForAddingOrgWithNetwork,
    getExistingOrganizations,
} from './OrganizationsUtils'

export default function InviteOrgsToNetworkSideDrawer(props) {

    const classes = useStyles();
    const [values, setValues] = React.useState({
        orgName: "",
        hostCaPort: "",
        orgCaPort: "",
        orgDomainName: "",
        organizationDescription: "",
        peers: [],
        peerNames: [null],
        peerPorts: [null],
        users: null,
        couchDBUsername: "",
        couchDBPassword: "",
        orgCaUsername: "",
        orgCaPassword: "",
        members: 0,
        inviteOrgs: [],
        roles: []
    });

    const [render, setRender] = React.useState(true)
    const [noOfMembersOrg, setNoOfMembersOrg] = React.useState([]);
    const [backdrop, setBackdrop] = React.useState(false);
    const [dropdownOrganizations, setDropdownOrganizations] = React.useState([]);
    const [selectedOrgs, setSelectedOrgs] = React.useState([]);
    const [outsidePeerNames, setOutsidePeerNames] = React.useState([null]);
    const [mapValueExistingOrganization, setMapValueExistingOrganization] = React.useState({});
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);

    const roles = [
        {
            "roleType": "operator",
            "roleName": "Operator"
        },
        {
            "roleType": "member",
            "roleName": "Member"
        }
    ];


    const setInitialState = () => {
        values.organizationDescription = "";
        values.orgName = ""
        values.orgDomainName = ""
        values.peers = []
        values.peerNames = [null]
        values.users = null;

        values.couchDBUsername = "";
        values.couchDBPassword = "";
        values.orgCaUsername = "";
        values.orgCaPassword = "";
        values.members = 0;
        values.inviteOrgs = [];
        values.roles = [];
        setSelectedOrgs([]);
        setNoOfMembersOrg([]);
        setOutsidePeerNames([null])
    }

    const handleChange = prop => (event) => {

        // reset selection when changing number of members
        if (prop === "members") {
            values.inviteOrgs = [];
            values.roles = [];
            if (render) {
                setRender(false)
            } else {
                setRender(true)
            }
        }

        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchData = () => {
        setBackdrop(true);
        organizationService.getOrgs().then(
            response => {
                let _data = response.data;
                {
                    _data.map(org => {
                        org["peerCount"] = org["peers"].length;
                    })
                }
                let allOrgs = _data;
                props.handleSetDrawerState("right", true);
                let invitableOrgs = [];
                if (props.orgData)
                    invitableOrgs = allOrgs.filter(org1 => !props.orgData.some(org2 => org2.id === org1.id));
                let noOfMembersArr = [...Array(invitableOrgs.length + 1).keys()].slice(1);
                setNoOfMembersOrg(noOfMembersArr);
                setDropdownOrganizations(invitableOrgs);
                let tempMap = {}
                for (let i = 0; i < allOrgs.length; i++) {
                    let tempOrg = allOrgs[i];
                    tempMap[tempOrg["id"]] = tempOrg["organizationName"]
                }
                setMapValueExistingOrganization(tempMap);
                if (noOfMembersArr.length > 0) {
                    setValues({ ...values, ["members"]: 1 });
                }
                setBackdrop(false);
                

            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching organizations data"
                });
            }
        );
    }

    const handleChangeInviteOrgs = i => (event) => {
        if (values.inviteOrgs[i]) {
            values.inviteOrgs[i] = event.target.value;
            selectedOrgs[i] = event.target.value;
        }
        else {
            let tempInvitedOrgs = Array.from(values.inviteOrgs)
            tempInvitedOrgs.push(event.target.value)
            setValues({ ...values, ["inviteOrgs"]: tempInvitedOrgs });
            setSelectedOrgs(tempInvitedOrgs);
        }

        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }

    };

    const handleDeleteInvitedOrg = i => (event) => {
        let modifiedSelectedOrgs = Array.from(values.inviteOrgs)
        let modifiedRoles = Array.from(values.roles)
        modifiedSelectedOrgs.splice(i, 1)
        modifiedRoles.splice(i, 1)
        setValues({
            ...values,
            ["inviteOrgs"]: modifiedSelectedOrgs,
            ["members"]: values.members - 1,
            ["roles"]: modifiedRoles
        });
        setSelectedOrgs(modifiedSelectedOrgs)
    }

    function deleteInviteNewOrg(i) {
        return (
            <div>
                <Button
                    color="primary"
                    className={classes.deleteButton}
                    onClick={handleDeleteInvitedOrg(i)}
                    startIcon={<RemoveCircleIcon />}
                    disabled={false}
                >
                </Button>
            </div>
        )
    }

    function inviteNewOrg(isDisabled) {
        return (
            <div>
                <Button
                    color="primary"
                    className={classes.inviteNewOrgButton}
                    onClick={() => { setValues({ ...values, ["members"]: values.members + 1 }); }}
                    startIcon={<AddCircleIcon />}
                    disabled={isDisabled}
                >
                    {"Add an org"}
                </Button>
            </div>
        )
    }

    const handleChangeRoles = i => (event) => {
        if (values.roles[i]) {
            values.roles[i] = event.target.value;
        }
        else {
            let tempRoles = Array.from(values.roles)
            tempRoles.push(event.target.value)
            setValues({ ...values, ["roles"]: tempRoles });
        }

        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }

    };

    function createInviteOrgs() {
        let arr = [];
        if (values.members > 0) {
            for (let i = 0; i < values.members; i++) {
                arr.push(
                    <div className={classes.formContainer}>
                        <div className={classes.flexItem}>
                            <FormControl style={{ minWidth: '100%' }}>
                                <InputLabel id="demo-mutiple-name-label">Organization *</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    fullWidth
                                    renderValue={() => { return <div>{mapValueExistingOrganization[values.inviteOrgs[i]]}</div>; }}
                                    value={values.inviteOrgs[i]}
                                    onChange={handleChangeInviteOrgs(i)}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {getExistingOrganizations(dropdownOrganizations, selectedOrgs, i)}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.flexItem}>
                            <FormControl style={{ minWidth: '100%' }}>
                                <InputLabel id="demo-mutiple-name-label">Role Type *</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    fullWidth
                                    value={values.roles[i]}
                                    onChange={handleChangeRoles(i)}
                                    renderValue={() => { return <div>{values.roles[i]}</div>; }}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {roles.map(role => (
                                        <MenuItem key={role.roleType} value={role.roleType}>
                                            {role.roleName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        {i == 0 ? "" : deleteInviteNewOrg(i)}
                    </div>
                )
            }
        }
        return arr
    }

    const handleSubmit = () => {
        values.peerNames = [...outsidePeerNames];
        props.onSubmitfunction(true);
        let requestObjWithNetwork = buildRequestObjForAddingOrgWithNetwork(values);
        // setBackdrop(true);

        networkService.inviteOrgsToNetwork(requestObjWithNetwork, props.networkId).then(
            response => {
                // setBackdrop(false);
                props.onSubmitfunction(false);
                let message = "";
                let _data = response.data;
                let partialFailure = _data.some(i => !i.success)

                if (partialFailure) {
                    let failedOrgs = _data.filter(i => !i.success).map(i => i.data.orgName);
                    message = "Successfully invited some of the orgs to the network. Failed to invite the following orgs : " + failedOrgs.toString();
                } else {
                    message = "Successfully invited all orgs to network";
                }

                notify({
                    type: "success",
                    message: message
                });
                let reload = true;
                props.fetchOrganizationData(reload, props.orgRowsPerPage, props.orgPage, props.orgOrderBy, props.orgOrder);
                setInitialState();
            },
            error => {
                // setBackdrop(false);
                props.onSubmitfunction(false);
                notify({
                    type: "error",
                    message: "Failed to invite the orgs to the network"
                });
            }
        );
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.handleSetDrawerState("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Invite organizations to network"}</Typography>
                </Toolbar>
                <Divider></Divider>

            </div>
            <div>
                <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                    {/* <div className={classes.flexItem}>
                        <TextField
                            id="standard-multiline-static"
                            key="noOfMembers"
                            select
                            required
                            label="Select no of members"
                            className={classes.textField}
                            margin="normal"
                            fullWidth
                            value={values.members}
                            onChange={handleChange("members")}
                            disabled={noOfMembersOrg.length ? false : true}
                            helperText={noOfMembersOrg.length ? false : `All organizations are already a member of ${props.networkName}`}
                        >
                            {noOfMembersOrg.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div> */}

                    {createInviteOrgs()}
                    {values.members >= noOfMembersOrg.length ? backdrop ? "" : inviteNewOrg(true) : backdrop ? "" : inviteNewOrg(false)}
                    {backdrop ?
                        ""
                        :
                        noOfMembersOrg.length ?
                            "" :
                            <div className={classes.warningStyle}>
                                <h3>{`All organizations are already members of this network`}</h3>
                            </div>
                    }

                </div>
                <div class={classes.sidedrawerFooter}>
                    <Divider />
                    <Button className={classes.drawerButton} onClick={handleSubmit} variant="contained" color="primary" disabled={backdrop || disableSubmitButton(values)}>
                        {"SUBMIT"}
                    </Button>
                    <Button autoFocus className={classes.drawerButton} onClick={props.handleSetDrawerState("right", false)} variant="contained" disabled={backdrop}>
                        {"CANCEL"}
                    </Button>
                </div>
            </div>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
