import React from "react";
import { Switch, BrowserRouter as Router, Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from '@material-ui/core/Backdrop';
import MenuIcon from "@material-ui/icons/Menu";
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import { FormControl, Input } from '@material-ui/core';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@material-ui/icons/Home";
import notify from "./utils/notifier";
import GroupIcon from '@material-ui/icons/Group';
import BlockIcon from "@material-ui/icons/BorderAll";
import ChannelIcon from "@material-ui/icons/Cast";
import NetworkIcon from '@material-ui/icons/WifiTethering';
import InfoIcon from '@material-ui/icons/Info';
import DescriptionIcon from '@material-ui/icons/Description';
import CCIcon from '@material-ui/icons/ClosedCaption';
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
// TODO: get the user icon from DB or generic
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import InputLabel from "@material-ui/core/InputLabel";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { useTranslation } from "react-i18next";
// import SaveAltIcon from '@material-ui/icons/SaveAlt';
// import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import LanguageIcon from '@material-ui/icons/Language';

import CategoryIcon from '@material-ui/icons/Category';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { _routes, _monitoring } from "./utils/Routes";
import HomePage from "./components/homePage";
import Networks from "./components/networks/Networks";
import Organizations from "./components/organizations/Organizations";
import myOrganizationInformation from "./components/myOrganization/MyOrganizationInformation";
import Assets from "./components/assets/Assets";
import AssetInfo from './components/assets/AssetInfo';
import organizationInformation from "./components/organizations/OrganizationInformation";
import ContactUsForm from './pages/ContactUsForm';
// import Profile from "./components/Profile";

import lightTheme from "./themes/lightTheme";
import darkTheme from "./themes/default-theme";
import ChannelInformation from "./components/channel/ChannelInformation";
import ShipmentInformation from "./components/shipments/ShipmentInformation";
import BookingInformation from "./components/bookings/BookingInformation";
import authUtil from './utils/authUtil';
import Role from './utils/roles';
import { ProtectedRoute } from './utils/ProtectedRoute';
import Chaincode from './pages/Chaincode';
import UserManagement from "./components/usermanagement/Usermanagement";
import Shipments from './components/shipments/Shipments'
import Bookings from './components/bookings/Bookings'
import Documents from './components/documents/Documents'
import DocumentsV2 from './components/documents/DocumentsV2'
import dpWorldLogo from './images/Cargochain.png';
import cargoesLogo from './images/Cargoes.png';
import userManagementService from './services/UserManagementService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Fab from '@material-ui/core/Fab';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import NetworkDetails from "./components/networks/NetworkDetails";

import DialogContentText from '@material-ui/core/DialogContentText';
import TransactionSearch from "./components/monitoring/TransactionSearch";
import Blocks from "./components/monitoring/BlockSearch";
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { PREVIOUS_PASSWORDS_TO_CHECK_NUM } from '../src/utils/constants';
// TODO: read from session context and update currentTheme.
// const currentTheme = darkTheme;
const DEFAULT_LANG = "EN";

const USER_VIEW_MAPPING = {
  "SUPERADMIN": "super_admin_view",
  "OPERATOR_ADMIN": "operator_view",
  "ADMIN": "admin_view",
  "USER": "user_view"
}

const drawerWidth = 240;
export default function AdminApp(props) {
  let currentTheme =
    localStorage.getItem("currentTheme") === "darkTheme" ? darkTheme : lightTheme;
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    },

    leftMenuColor: {
      // background: theme.palette.primary.main
      background: '#231F1F'

    },

    iconColor: {
      color: "white"
    },

    logo: {
      width: '75%',
      height: '80%',
      verticalAlign: "middle"
    },

    login_name: {
      marginRight: theme.spacing(1.4),
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
      fontWeight: "700"
    },
    change_password_align: {
      textAlign: "right"
    },
    logout_align: {
      textAlign: "right"
    },
    appBar: {
      height: "64px",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: "none"
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      position: "fixed",
      zIndex: "9990",
      width: drawerWidth + 1,
      background: currentTheme.palette.primary.navigation,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    changePasswordSideList: {
      width: "30vw",
    },
    drawerClose: {
      position: "relative",
      // transition: theme.transitions.create("width", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen
      // }),
      background: currentTheme.palette.primary.navigation,
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1
      }
    },
    sidedrawerFooter: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      backgroundColor: "#ffffff",
    },
    toolbar: {
      display: "flex",
      background: currentTheme.palette.primary.main,
      alignItems: "center",
      justifyContent: "flex-start",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    homePage: {
      flexGrow: 1,
      marginTop: 55,
      background: "white",
      minHeight: "100vh"
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: 55,
      background: "#80808033",
      minHeight: "100vh"
    },
    displayContents: {
      display: "contents",
      color: "white",
      textDecoration: "none"

    },
    MuiBackdrop: {
      backgroundColor: "#fafafa"
    },
    flexGrow0: {
      marginRight: "-10px",
      flexGrow: 0,
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        background: 'none'
      }
    },
    iconHover: {
      paddingLeft: "1.5em",
      "&:hover": {
        //you want this to be the same as the backgroundColor above
        background: currentTheme.palette.menuhover.main,
        borderLeft: "5px solid white"
      }
    },
    iconHover1: {
      paddingLeft: "3.5em",
      position: "fixed",
      bottom: "0px",
    },
    fontVersion: {
      fontSize: '0.9rem'
    },
    selectedLeftMenu: {
      background: currentTheme.palette.menuhover.main,
      borderLeft: "5px solid white"

    },
    flexGrow1: {
      flexGrow: 1,
      fontWeight: "1000"
    },
    wrapper: {
      position: "relative"
    },
    flexItem: {
      flex: 1,
      margin: theme.spacing(1),
      paddingLeft: theme.spacing(3)
    },
    warningStyle: {
      //width: "100%",
      background: "#F5C6CB",
      color: "#721C24",
      textAlign: "center",
      padding: '1px 3px 4px',
      marginLeft: "-10px",
    },
    warningStyleNoBg: {
      // color: "#721C24",
      textAlign: "center",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(2),
      fontSize: "10px",
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(2),
    },
    warningStyleHidden: {
      display: "none"
      //color: "#721C24",
    },
    drawerButton: {
      margin: theme.spacing(2),
      float: "right",
    },
    //paper: {
    //position: "absolute",
    //top: 56,
    //right: -24,
    //maxHeight: 400,
    //padding: theme.spacing(1),
    //backgroundColor: currentTheme.palette.primary.main,
    //padding: 2,
    //overflow: "scroll",
    //borderTop: "1px solid white"
    //},
    //card: {
    //minWidth: 300,
    //height: "100%"
    //},
    paper: {
      position: "absolute",
      top: 56,
      right: -2, //24
      maxHeight: 400,
      padding: theme.spacing(1),
      //backgroundColor: currentTheme.palette.primary.main,
      padding: 2,
      overflow: "scroll",
      //borderTop: "1px solid white"
    },
    card: {
      //maxWidth: 800,
      marginRight: "10%",
      minWidth: 300,
      height: "90%"
    },
    textField: {
      paddingRight: theme.spacing(4)
    },
    setting: {
      padding: "20px 0px"
    },
    customSelect: {
      fontSize: "x-large",
      width: "100%",
      border: "none",
      margin: "5px"
    },
    logoutBtn: {
      float: "right",
      backgroundColor: '#006db3',
      color: '#ffffff'
    },
    dialogTitle: {
      fontWeight: "700",
      fontSize: "1.25rem"
    },
    dialogContentText: {
      fontWeight: "700"
    },
    dialogButton: {
      fontWeight: "700",
      fontFamily: "sans-serif"
    },
  }));

  const modalStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const [t, i18n] = useTranslation();
  const classes = useStyles();
  const routes = _routes;
  const monitoringRoutes = _monitoring;
  const icons = {
    "Home": <HomeIcon />,
    "Organizations": <GroupWorkIcon />,
    "Networks": <LanguageIcon />,
    "Chaincode": <CCIcon />,
    "Shipments": <LocalShippingIcon />,
    "Documents": <DescriptionIcon />,
    "Documents-Bookings": <DescriptionIcon />,
    "Users": <GroupIcon />,
    "My Organization": <AccountCircleIcon />,
    "Bookings": <LocalShippingIcon />,
    "Assets": <WebAssetIcon />,
    "Monitoring": <InfoIcon />
  }
  const [backdrop, setBackdrop] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const username = localStorage.getItem('_username');
  const emailId = localStorage.getItem('_emailId');
  const firstName = localStorage.getItem('_firstName');
  const lastName = localStorage.getItem('_lastName');
  const orgName = localStorage.getItem('_orgName');
  const [selectedLeftMenu, setSelectedLeftMenu] = React.useState("");
  const [userType, setUsertype] = React.useState(authUtil.getUsertype());
  const [samePasswordError, setSamePasswordError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openuser, setOpenuser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contactUs, setContactUs] = React.useState(false);
  const [maxChangedPassword, setMaxChangedPassword] = React.useState(false);
  const [values, setValues] = React.useState({
    oldPassword: "",
    newPassword: "",
    newPassword: "",
    newPasswordCheck: "",
  });
  const [openList, setOpenList] = React.useState(false);

  const handleMonitoringList = () => {
    setOpenList(!openList);
  };

  const setInitialState = () => {
    values.oldPassword = ""
    values.newPassword = ""
    values.newPasswordCheck = ""
  }

  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const DialogTitle = withStyles(modalStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  const [lang, setLang] = React.useState(
    localStorage.getItem("currentLanguage") || DEFAULT_LANG
  );
  const [curTheme, setCurTheme] = React.useState(
    localStorage.getItem("currentTheme") || "lightTheme"
  );

  const [isAdminEnrolled, setIsAdminEnrolled] = React.useState(
    localStorage.getItem('_orgRole') == "SUPERADMIN" ? true : localStorage.getItem("_adminEnrolled")
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const setSelectedMenu = (menu) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setSelectedLeftMenu(menu);
    setOpenList(false);
  };

  const handleChangeLang = event => {
    // console.log("language changed" + event.target.value);
    setLang(event.target.value);
    let lng = event.target.value;
    localStorage.setItem("currentLanguage", lng);
    i18n.changeLanguage(lng);
  };
  const handleChangeTheme = event => {
    // console.log("theme changed" + event.target.value);
    let _theme = event.target.value;
    localStorage.setItem("currentTheme", _theme);
    currentTheme =
      localStorage.getItem("currentTheme") === "darkTheme"
        ? darkTheme
        : lightTheme;
    setCurTheme(event.target.value);
  };

  const handleClick = (event) => {
    setOpenuser(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenuser(null);
    setAnchorEl(null);
  };

  const openHelp = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openVideo = (event) => {
    setModal(true);

  };

  const handleClose = () => {
    setModal(false);
    setContactUs(false);
  }

  const handleClickAway = () => {
    setOpenuser(false);
    setAnchorEl(false);
  };

  React.useEffect(() => {
    setIsAdminEnrolled(localStorage.getItem('_orgRole') == "SUPERADMIN" ? true : isAdminEnrolled);
  }, [isAdminEnrolled]);

  function validatePasswords() {
    var passwordPattern;
    if (localStorage.getItem("_userRole") === 'SUPERADMIN' || localStorage.getItem("_userRole") === 'ADMIN') {
      passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#$&%@!ˆ*._-]).{14,}$/;
    } else {
      passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#$&%@!ˆ*._-]).{8,}$/;
    }
    return passwordPattern.test(values.newPassword) && passwordPattern.test(values.newPasswordCheck);
  }

  const disableSubmitButtonPasswords = (oldPassword, newPassword, newPasswordCheck) => {
    if (oldPassword && newPassword && newPasswordCheck) {
      if (oldPassword !== newPassword && (newPassword === newPasswordCheck) && validatePasswords()) {
        return false
      }
    }
    return true;
  }

  const handleOpenDrawerUpdatePassword = (side, open, row) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
  };

  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
    setInitialState();
  };

  const handleChangePasswords = prop => (event) => {
    setSamePasswordError(false);
    setMaxChangedPassword(false);
    setPasswordError(false);
    if (prop === 'oldPassword' && event.target.value === values.newPassword)
      setPasswordError(true);
    else if (prop === 'newPassword' && event.target.value === values.oldPassword)
      setPasswordError(true);
    else if (prop === 'newPassword' || prop === 'oldPassword')
      setPasswordError(false);
    else if (passwordError === true)
      setPasswordError(true); 
    setValues({ ...values, [prop]: event.target.value });
  };

  const ChangePasswordSideList = side => (
    <div className={classes.changePasswordSideList}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Change Your Password"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <br></br>

      <div style={{ overflow: "auto", maxHeight: "85vh" }}>
        <div className={classes.warningStyle} style={{
          display: samePasswordError ? 'block' : 'none', fontSize: '0.85rem'
        }}>
          < h3 > New password should not match previous {PREVIOUS_PASSWORDS_TO_CHECK_NUM} passwords</h3>
        </div>
        <div className={classes.warningStyle} style={{
          display: maxChangedPassword ? 'block' : 'none', fontSize: '0.85rem'
        }}>
          < h3 > Password is already changed for maximum number of times in a day.Please try again later</h3>
        </div>
        <div className={classes.warningStyle} style={{
          display: passwordError ? 'block' : 'none', fontSize: '0.85rem'
        }}>
          < h3 > New password should not match current password</h3>
        </div>
        <div className={classes.flexItem}>
          <FormControl style={{ minWidth: '94%' }}>
            <TextField
              id="standard-basic"
              type="password"
              className={classes.textField}
              label={"Enter current password"}
              margin="normal"
              rows="2"
              required
              fullWidth
              autoFocus
              value={values.oldPassword}
              onChange={handleChangePasswords("oldPassword")}
            />
          </FormControl>
        </div>
        <div className={classes.flexItem}>
          <FormControl style={{ minWidth: '94%' }}>
            <TextField
              id="standard-basic"
              type="password"
              className={classes.textField}
              label={"Enter new password"}
              margin="normal"
              rows="2"
              required
              disabled={values.oldPassword.length === 0}
              fullWidth
              value={values.newPassword}
              onChange={handleChangePasswords("newPassword")}
            />
          </FormControl>
        </div>
        <div className={classes.flexItem}>
          <FormControl style={{ minWidth: '94%' }}>
            <TextField
              id="standard-basic"
              type="password"
              className={classes.textField}
              label={values.newPasswordCheck.length > 0 ? values.newPassword === values.newPasswordCheck ? "Enter new password again" : "Enter new password again(passwords do not match)" : "Enter new password again"}
              margin="normal"
              rows="2"
              disabled={values.oldPassword.length === 0}
              error={values.newPasswordCheck.length > 0 && values.newPassword !== values.newPasswordCheck}
              required
              fullWidth
              value={values.newPasswordCheck}
              onChange={handleChangePasswords("newPasswordCheck")}
            />
          </FormControl>
        </div>
      </div>

      {
        validatePasswords() ?
          ""
          : (localStorage.getItem("_userRole") === 'SUPERADMIN' || localStorage.getItem("_userRole") === 'ADMIN') ? <div className={classes.warningStyleNoBg}>
            <h3>* Password should be at least 14 characters with a combination of numbers, lower case, upper case letters and special characters (# $ & % @ ! ˆ * . _ - _ )</h3>
          </div> :
            <div className={classes.warningStyleNoBg}>
            <h3>* Password should be at least 8 characters with a combination of numbers, lower case, upper case letters and special characters (# $ & % @ ! ˆ * . _ - _ )</h3>
          </div>
      }

      <div class={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmitUpdatePassword} color="primary" variant="contained" disabled={disableSubmitButtonPasswords(values.oldPassword, values.newPassword, values.newPasswordCheck)}>
          {"CHANGE PASSWORD"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>

      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );

  const handleSubmitUpdatePassword = () => {
    setSamePasswordError(false);
    setMaxChangedPassword(false);
    setBackdrop(true);
    userManagementService.updateUserPassword(values.oldPassword, values.newPassword, emailId).then(
      response => {
        setBackdrop(false);
        if (response.data.errorCode === 406) {
          setSamePasswordError(true);
        } else if (response.data.errorCode === 40602) {
          setMaxChangedPassword(true);
        }
        else {
        if (response.data) {
          setDrawerState({ ...drawerState, ["right"]: false });
          // ask the user to login with the new password
          authUtil.clearSession();
          props.history.push('/');
        } else {
          notify({
            type: "error",
            message: "Internal Server Error"
          });
        }
          setSamePasswordError(false);
          setMaxChangedPassword(false);
        }
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Incorrect current password"
        });
      }
    );
  };

  const handleLogout = () => {

    userManagementService.logout().then(
      response => {
        authUtil.clearSession();
      },
      error => {
        notify({
          type: "error",
          message: "Error while logging out"
        });
      }
    );

    props.history.push('/login');
  };

  const updateAdminEnrolled = () => {
    setIsAdminEnrolled(true);
  };

  const handleContactUs = () => {
    setContactUs(!contactUs);
  }
  return (
    <MuiThemeProvider theme={currentTheme}>
      <Router>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open
                })}
              >
                <img src={cargoesLogo} alt='DPW' style={{ height: "1em" }} />
              </IconButton>
              <Typography variant="h6" noWrap className={classes.flexGrow1}>
                {"DP World's Blockchain Platform"}
              </Typography>
              <div className={classes.flexGrow0}>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classes.wrapper}>

                    <IconButton
                      className={classes.flexGrow0}
                      aria-label="user-settings"
                      color="inherit"
                      size="medium"
                      onClick={openHelp}
                    >

                      <Fab size="medium" aria-label="add" style={{ fontSize: '15px', color: "#173a64", background: "white", fontWeight: "500", height: "40px", width: "40px" }}>
                        <h3 style={{ fontWeight: "bold" }}>?</h3>
                      </Fab>

                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                      transformOrigin={{ vertical: "top", horizontal: "center" }}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={() => handleContactUs()}><b>Contact Us</b></MenuItem> 
                      <MenuItem onClick={openVideo}><b>Tutorial Video</b></MenuItem>
                    </Menu> 
                    <IconButton
                      className={classes.flexGrow0}
                      aria-label="user-settings"
                      color="inherit"
                      size="medium"
                      onClick={handleClick}
                    >


                      <Fab size="medium" aria-label="add" style={{ fontSize: '15px', color: "#173a64", background: "white", fontWeight: "500", height: "45px", width: "45px" }}>
                        <h3 >{`${firstName[0]} ${lastName ? lastName[0] : ""}`} </h3>
                      </Fab>
                    </IconButton>
                    <Menu
                      id="simple-menu"
                      anchorEl={openuser}
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                      transformOrigin={{ vertical: "top", horizontal: "center" }}
                      keepMounted
                      open={Boolean(openuser)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem ><b>{`${firstName} ${lastName}`}</b></MenuItem>
                      <MenuItem ><b>{`${orgName}`}</b></MenuItem>
                      <MenuItem ><b>{`${emailId}`}</b></MenuItem>
                      <hr></hr>
                      <MenuItem onClick={handleOpenDrawerUpdatePassword("right", true)}><b>Change Password</b></MenuItem>
                      <MenuItem onClick={handleLogout}><b>Logout</b></MenuItem>
                    </Menu> 

                  </div>
                </ClickAwayListener>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer anchor="right" open={drawerState.right} >
            {ChangePasswordSideList('right')}
          </Drawer>
          <Drawer
            onMouseEnter={handleDrawerOpen}
            onMouseLeave={handleDrawerClose}
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            open={open}
          >
            <div className={classes.toolbar} >
              {currentTheme.direction === "rtl" ? (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronRightIcon className={classes.iconColor} />
                </IconButton>) : (
                  <div style={{ color: "white" }}>
                    <img src={cargoesLogo} alt='DPW' style={{ height: "1.2em", verticalAlign: "sub" }} /> &nbsp;<b>CARGOES DATACHAIN </b>
                    {/* <img src={dpWorldLogo} alt='DPW' className={classes.logo} /> */}
                    {/* <IconButton onClick={handleDrawerClose}>
                      <ChevronLeftIcon className={classes.iconColor} />
                    </IconButton> */}
                  </div>
                )}

              {/* <IconButton onClick={handleDrawerClose}>
                {currentTheme.direction === "rtl" ? (
                  <ChevronRightIcon className={classes.iconColor} />
                ) : (
                    <div><img src={dpWorldLogo} alt='DPW' className={classes.logo} /> <ChevronLeftIcon className={classes.iconColor} /></div>
                  )}
              </IconButton> */}
            </div>
            <Divider />
            <List >
              {routes.map((route, index) => {
                if (route[USER_VIEW_MAPPING[userType]]) {
                  return (
                    <Link to={route.route} key={route.title} className={classes.displayContents}>
                      <ListItem button onClick={setSelectedMenu(index)} className={selectedLeftMenu === index ? classes.selectedLeftMenu : classes.iconHover}>
                        <ListItemIcon className={classes.iconColor}>
                          {icons[route.title]}
                        </ListItemIcon>
                        {open ? <ListItemText primary={route.title} /> : ""}
                      </ListItem>
                    </Link>
                  );
                }
              })}
              <ListItem button onClick={handleMonitoringList} className={selectedLeftMenu === "Monitoring" ? classes.selectedLeftMenu : classes.iconHover}>
                <ListItemIcon className={classes.iconColor}>
                  {icons['Monitoring']}
                </ListItemIcon>
                <ListItemText style={{ color: "white", textDecoration: "none" }} primary="Monitoring" />
                {openList ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding style={{ paddingLeft: "1.5em" }}>
                  {monitoringRoutes.map((route, index) => {
                    return (
                      <Link to={route.route} key={route.title} className={classes.displayContents}>
                        <ListItem button onClick={setSelectedMenu("Monitoring")} className={classes.nested}>
                          <ListItemIcon className={classes.iconColor}>
                            {icons['Monitoring']}
                          </ListItemIcon>
                          <ListItemText style={{ color: "white", textDecoration: "none" }} primary={route.title} />
                        </ListItem>
                      </Link>
                    )
                  })}
                </List>
              </Collapse>
              <ListItem
                className={classes.iconHover1}
              >
                <ListItemIcon
                >
                </ListItemIcon>
                {open === true ? <ListItemText style={{ color: "white", textDecoration: "none" }} classes={{ primary: classes.fontVersion }} primary="built on fabric v2.3.1" /> : ''}
              </ListItem>
            </List>
          </Drawer>
          <main className={window.location.pathname === "/" ? classes.homePage : classes.content}>
            <ToastContainer autoClose={2000} />
            {<div className={(isAdminEnrolled === 'false' && localStorage.getItem("_orgRole") !== 'SUPERADMIN') ? classes.warningStyle : classes.warningStyleHidden}>
              <h3>{"Please enroll admin of the organization."}</h3>
            </div>}
            <Switch>
              <ProtectedRoute exact path="/" component={HomePage} />
              <ProtectedRoute exact path="/networks" Roles={[Role.ADMIN]} component={Networks} />
              <ProtectedRoute exact path="/organizations" Roles={[Role.ADMIN]} component={Organizations} />
              <ProtectedRoute exact path={`/organizations/:organizationName`} component={organizationInformation} />
              <ProtectedRoute exact path={`/networks/:networkName`} Roles={[Role.ADMIN]} component={NetworkDetails} />
              <ProtectedRoute exact path={`/channel/:channelId`} component={ChannelInformation} />
              <ProtectedRoute exact path={`/shipment/:shipmentID`} component={ShipmentInformation} />
              <ProtectedRoute exact path={`/booking/:bookingNumber`} component={BookingInformation} />
              <ProtectedRoute exact path="/chaincodes" Roles={[Role.ADMIN]} component={Chaincode} />
              <ProtectedRoute exact path="/userManagement" Roles={[Role.ADMIN]} updateAdminEnrolled={updateAdminEnrolled} component={UserManagement} />
              <ProtectedRoute exact path="/shipments" component={Shipments} />
              <ProtectedRoute exact path="/bookings" component={Bookings} />
              <ProtectedRoute exact path="/documents" component={Documents} />
              <ProtectedRoute exact path="/documentsV2" component={DocumentsV2} />
              <ProtectedRoute exact path="/myOrganization" component={myOrganizationInformation} />
              <ProtectedRoute exact path="/assets" component={Assets} />
              <ProtectedRoute exact path={`/assets/:assetId`} component={AssetInfo} />
              <ProtectedRoute exact path={`/transaction`} component={TransactionSearch} />
              <ProtectedRoute exact path={`/blocks`} component={Blocks} />
            </Switch>
          </main>
          </div>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={modal} fullWidth={true} maxWidth={"md"}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          {/* <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <div style={{ color: "#173a64" }}>Datachain Tutorial Video</div>
          </DialogTitle> */}
          <DialogContent>
            <iframe src="https://player.vimeo.com/video/433225919?autoplay=true" width="940" height="500" frameborder="0" allow="autoplay; fullscreen" allowfullscreen ></iframe>
            {/* <iframe width="966" height="543" src="https://www.youtube.com/embed/yRr0_gJ-3mI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}


            {/* <span style={{ color: "#173a64" }}>Basic walkthrough on how to use Datachain</span> */}
          </DialogContent>


        </Dialog>
        {contactUs ? <ContactUsForm handleClose={handleContactUs} /> : ""}
      </Router>
    </MuiThemeProvider >
  );
}