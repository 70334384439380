import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography, FormControl, Button, Select, MenuItem, Divider, Input, TextField,
    Checkbox, IconButton, Backdrop, CircularProgress, InputLabel
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChaincodeService from '../../services/ChaincodeService';
import notify from '../../utils/notifier';
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import UploadFile from '../../components/UploadFile';
import { ALPHA_NUM_SMALLCAP_NO_SPACE, VALID_USER_INPUT_REGEX } from "../../utils/constants";

const drawerWidth = "30vw";
const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1,
        transition: lightTheme.transitions.create("margin", {
            easing: lightTheme.transitions.easing.sharp,
            duration: lightTheme.transitions.duration.leavingScreen
        }),
        marginRight: 0
    },
    rootShift: {
        transition: lightTheme.transitions.create("margin", {
            easing: lightTheme.transitions.easing.easeOut,
            duration: lightTheme.transitions.duration.enteringScreen
        }),
        marginRight: 0
    },
    paper: {
        height: '100%',
    },
    list: {
        width: "30vw",
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px",
        margin: "5px 0px"
    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        marginRight: "5px"
    },
    titleRow: {
        paddingTop: "5px",

    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    heading: {
        fontSize: lightTheme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: lightTheme.typography.pxToRem(15),
        color: lightTheme.palette.text.secondary,
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        //margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: lightTheme.spacing(1),
        paddingLeft: lightTheme.spacing(3)
    },
    textField: {
        paddingRight: lightTheme.spacing(4)
    },
    chaincodeDropdown: {
        paddingTop: lightTheme.spacing(4),
        paddingBottom: lightTheme.spacing(4),
        paddingRight: lightTheme.spacing(4)
    },
    chaincodeDropdownFormControl: {
        width: "100%"
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    pageTitle: {
        paddingBottom: '1.3%'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        // top: '64px'
    },
    forms: {
        //padding: '5%',
        boxShadow: 'none'
    },
    drawerButton: {
        margin: lightTheme.spacing(2),
        float: "right",
    },
    disableHoverIcon: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }
    },
    errorRow: {
        margin: "80px 10px",
        padding: "15px",
        textAlign: "center",
        background: "#f8f8f8",
        color: "orange",
        marginRight: lightTheme.spacing(2)
    },
    nested: {
        paddingLeft: lightTheme.spacing(4),
    },
    button: {
        height: "2rem",
        // paddingTop: "1rem",
        padding: "1rem",
        margin: '1rem',
    },
    formBody: {
        marginTop: '8%',
        overflow: 'auto',
        maxHeight: '74vh'
    },
    fields: {
        paddingBottom: '5%',
        paddingLeft: lightTheme.spacing(2)
    },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function UploadNewVersionForm(props) {

    const classes = useStyles();

    const [newChannels, setNewChannels] = React.useState('');
    const [selectedPeers, setSelectedPeers] = React.useState([]);
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [selectedMembers, setselectedMembers] = React.useState([]);
    const [endorsementPolicy, setEndorsementPolicy] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selectedChaincode, setSelectedChaincode] = React.useState("");
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [systemChaincodes, setSystemChaincodes] = React.useState([]);
    const [uploadNewOption, setUploadNewOption] = React.useState(false);

    const [backdrop, setBackdrop] = React.useState(false);
    const [file, setFile] = useState("");
    const [uploadValues, setValues] = React.useState({
        ccName: "",
        ccVersion: "",
        ccDesc: "",
        ccVerisonId: ""
    });
    const fileUploadCriteria = {
        validFileTypes: [
            'application/zip'
        ],
        maxFileSizeLimit: 10000000 // 10MB
    }
    //Fetch chaincode information
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        //Fetch channels
        // TODO: once backed is working, DONOT construct peer
        setValues({
            ccName: props.name,
            ccVersion: parseInt(props.version) + 1,
            ccDesc: "",
            ccVerisonId: props.id
        });
        ChaincodeService.getAvailableSystemChaincodes().then(response => {
            let result = response.data;
            result = result.map(cc => {
                let temp = cc.split('.');
                return temp.join('.');
            })
            setSystemChaincodes(result);
        }, err => {
            notify({
                type: "error",
                message: "Error occured while getting system chaincodes"
            });
        });
    };



    const handleChangeChaincodeUploadOption = (event) => {
        setUploadNewOption(event.target.checked);
        setSelectedChaincode("");
    }
    const handleChangeChaincodeSelect = (event) => {
        setSelectedChaincode(event.target.value);
    };
    const handleFileUpload = (file) => {
        if (file) {
            if (fileUploadCriteria.validFileTypes.includes(file.type) && file.size < fileUploadCriteria.maxFileSizeLimit) {
                setFile(file);
            } else {
                setFile("");
                let errMsg = !fileUploadCriteria.validFileTypes.includes(file.type)
                    ? "Invalid file type , please upload a .zip file."
                    : "File size should be less than 1 MB"
                notify({
                    type: "error",
                    message: errMsg
                });
            }
        } else {
            setFile("")
        }
    }

    const handleChange = prop => (event) => {
        let regexMap = {
            'ccName': ALPHA_NUM_SMALLCAP_NO_SPACE
        }
        let regex = regexMap[prop] ? regexMap[prop] : VALID_USER_INPUT_REGEX;

        if (!regex.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setValues({ ...uploadValues, [prop]: event.target.value });
    };

    const handleUploadChaincode = (evt) => {

        if (!uploadValues.ccName || uploadValues.ccName == "") {
            notify({
                type: "error",
                message: "Chaincode name is required"
            });
            return;
        }
        if (!uploadValues.ccDesc || uploadValues.ccDesc == "") {
            notify({
                type: "error",
                message: "Description is required"
            });
            return;
        }
        if (uploadNewOption && !file) {
            notify({
                type: "error",
                message: "Please select a file to upload"
            });
            return;
        }
        if (!uploadNewOption && (selectedChaincode.length === 0)) {
            notify({
                type: "error",
                message: "Please select any chaincode from dropdown to upload"
            });
            return;
        }

        var formData = new FormData();
        formData.append("name", uploadValues.ccName);
        formData.append("description", uploadValues.ccDesc);

        formData.append("version", uploadValues.ccVersion);

        if (uploadNewOption) {
            formData.append("file", file);
        } else {
            formData.append("chaincodeName", selectedChaincode);
        }

        setBackdrop(true);
        ChaincodeService.uploadChaincode(formData).then(response => {
            setBackdrop(false);
            notify({
                type: "success",
                message: "Successfully uploaded chaincode"
            });
            response = response.data;
            // setChains([]); // Temporary fix for duplicate chaincodes view after upload. Ideally we shouldn't clear it, need to come back to it later
            // setUploadDrawer(false);
            props.closeNewVersionDrawer();

            props.fetchData();
        }, errr => {
            setBackdrop(false);
            if (errr.response.status === 409) {
                notify({
                    type: "error",
                    message: "A chaincode with this name already exists. Please provide a different name"
                });
            } else {
                notify({
                    type: "error",
                    message: "Error occured while uploading chaincode"
                });
            }

        });
    };

    return (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.closeNewVersionDrawer}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Upload new version"}</Typography>
                </Toolbar>
                <Divider />
            </div>
            <br></br>
            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                <div className={classes.flexItem}>
                    <TextField
                        id="name"
                        required
                        disabled={true}
                        className={classes.textField}
                        label={"Chaincode Name"}
                        margin="normal"
                        required
                        fullWidth
                        value={uploadValues.ccName}
                    />
                    <TextField
                        id="version"
                        required
                        className={classes.textField}
                        label={"Chaincode Verison"}
                        margin="normal"
                        required
                        fullWidth
                        value={uploadValues.ccVersion}
                    />

                    <TextField
                        id="description"
                        required
                        className={classes.textField}
                        error={invalidUserInput.includes("ccDesc")}
                        label={"Description"}
                        margin="normal"
                        required
                        rows="2"
                        fullWidth
                        value={uploadValues.ccDesc}
                        onChange={handleChange("ccDesc")}
                    />

                    <div className={classes.chaincodeDropdown}>
                        <FormControl className={classes.chaincodeDropdownFormControl}>
                            <InputLabel id="channel-checkbox-label">Select Chaincode</InputLabel>
                            <Select
                                value={selectedChaincode}
                                onChange={handleChangeChaincodeSelect}
                                input={<Input />}
                                MenuProps={MenuProps}
                                disabled={uploadNewOption}
                            >
                                {
                                    systemChaincodes.map((cc) => (
                                        <MenuItem
                                            key={cc}
                                            value={cc}
                                        >
                                            {cc}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={uploadNewOption}
                                    onChange={handleChangeChaincodeUploadOption}
                                    name="uploadNewCC"
                                    color="primary"
                                />
                            }
                            label="Upload new chaincode file"
                        />
                    </div>


                    {
                        (uploadNewOption) ?
                            <div style={{ paddingRight: "32px" }}>
                                <UploadFile onFileUpload={handleFileUpload} fileUploadCriteria={fileUploadCriteria}></UploadFile>
                            </div>
                            : ""
                    }
                </div>
                {/* <br></br> */}
            </div>
            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleUploadChaincode} color="primary" variant="contained" disabled={backdrop || !uploadValues.ccName || !uploadValues.ccDesc || (uploadNewOption ? !file : selectedChaincode.length === 0) || invalidUserInput.length}>
                    {"UPLOAD"}
                </Button>
                <Button className={classes.drawerButton} onClick={props.closeNewVersionDrawer} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >

    );
}