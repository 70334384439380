import React from "react";
import { useStyles } from "./styles/OrganizationInformationStyles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

export default function SearchNetworks(props) {

    const classes = useStyles();
    const [searchKey, setSearchKey] = React.useState(props.searchKey.value === null ? {value : ""} : props.searchKey);


    const clearSearchText = () => {
        setSearchKey({ value: "" });
        props.fetchData();
    };

    let timeout = null;
    const handleSearch = event => {
        const _val = event.target.value;
        setSearchKey({ value: _val });

        if (_val.length <= 2) {
            props.handleSetInitialRow();
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            props.fetchData(_val);
        }, 200);
    };

    return (
        <div className={classes.searchBox}>
            <InputBase
                onChange={handleSearch}
                value={searchKey.value}
                className={classes.input}
                placeholder={"Search Networks"}
            />
            {
                searchKey.value.length ? (
                    <IconButton
                        size="medium"
                        aria-label="search"
                        onClick={clearSearchText}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : (
                        ""
                    )
            }
            <IconButton
                size="medium"
                aria-label="search"
                disabled
            >
                <SearchIcon />
            </IconButton>
        </div>
    )
}