import axios from 'axios'
import authUtil from '../utils/authUtil'
import Util from '../utils/Util'

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`

axios.defaults.withCredentials = true
// function getOrganizations() {
//   return axios.get(BASE_API_URL + `/admin/organizations?peers=false`, {
//     headers: {
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

// function getShipmentsCreatedByOrgID(organizationID) {
//   return axios.get(
//     BASE_API_URL +
//     `/shipments/organizations/` +
//     organizationID +
//     `?type=created`,
//     {
//       headers: {
//         Authorization: 'Bearer ' + authUtil.getToken()
//       }
//     }
//   )
// }



// function getEventsByShipmentID(shipmentID, query = {}) {
//   let queryParams = Util.buildQueryParams(query)
//   return axios.get(
//     BASE_API_URL + `/shipments/` + shipmentID + `/events/` + queryParams,
//     {
//       headers: {
//         Authorization: 'Bearer ' + authUtil.getToken()
//       }
//     }
//   )
// }

// function getProductsByShipmentID(shipmentID, query) {
//   let queryParams = Util.buildQueryParams(query)
//   return axios.get(
//     BASE_API_URL + `/shipments/` + shipmentID + `/products/` + queryParams,
//     {
//       headers: {
//         Authorization: 'Bearer ' + authUtil.getToken()
//       }
//     }
//   )
// }

function getContainersByBookingNumber(bookingNumber, query) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/bookings/` + bookingNumber + `/containers/` + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getBookingsSharedByOrgID(organizationID, query = {}) {
  query['type'] = 'shared'
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/bookings/organizations/` + organizationID + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

// function getShipmentsByShipmentID(shipmentID) {
//   return axios.get(BASE_API_URL + `/shipments/` + shipmentID, {
//     headers: {
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

function getBookingsByDocumentId(documentId) {
  return axios.get(BASE_API_URL + `/bookings/documents/` + documentId, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

// function createShipment(orgainzationID, requestObj) {
//   return axios.post(
//     BASE_API_URL + `/shipments/organizations/` + orgainzationID,
//     requestObj,
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + authUtil.getToken()
//       }
//     }
//   )
// }

function createBookingByBookingReference(orgainzationID, requestObj) {
  return axios.post(BASE_API_URL + `/bookings/organizations/external/` + orgainzationID, requestObj, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

// function generateEvent(shipmentID, requestObj) {
//   return axios.post(
//     BASE_API_URL + `/shipments/` + shipmentID + `/events/`,
//     requestObj,
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: 'Bearer ' + authUtil.getToken()
//       }
//     }
//   )
// }

// function createNewEventType(requestObj) {
//   return axios.post(BASE_API_URL + `/events`, requestObj, {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

function getEvents() {
  return axios.get(BASE_API_URL + `/assets/types?assetTypeName=Events`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

// function getShipmentPorts(searchString) {
//   return axios.get(BASE_API_URL + `/staticdata/shipmentPorts?searchString=` + searchString, {
//     headers: {
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

function getEventsByContainerId(containerId, query = {}) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL +
    `/bookings/trackingShipments/`+
    containerId +
    `/events` + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

// function updateShipment(shipmentID, requestObj) {
//   return axios.post(BASE_API_URL + `/shipments/` + shipmentID, requestObj, {
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

// function getShipmentsChannelId(channelId) {
//   return axios.get(BASE_API_URL + `/shipments/channels/` + channelId, {
//     headers: {
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

function getDocumentTypes() {
  return axios.get(BASE_API_URL + `/assets/types?assetTypeName=Documents`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getDocumentTypesByDestination(referenceNumber = null) {
  return axios.get(BASE_API_URL + `/staticdata/bookings/documentTypes/destination?referenceNumber=${referenceNumber}`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getDocumentTypesByDestinationForBookings(bookingNumberList) {
  return axios.get(BASE_API_URL + `/staticdata/bookings/documentTypes/destination?bookingNumberList=${bookingNumberList}`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

// function getShipmentRevisionsByShipmentId(shipmentID) {
//   return axios.get(BASE_API_URL + `/shipments/` + shipmentID + `/revisions`, {
//     headers: {
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

// function getShipmentsByShipmentReferenceNumber (requestObj) {
//   let queryParams = Util.buildQueryParams(requestObj);
//   return axios.get(BASE_API_URL + `/shipments/shipmentReferenceNumber` + queryParams, {
//     headers: {
//       Authorization: 'Bearer ' + authUtil.getToken()
//     }
//   })
// }

function getLiveBookingRouteDataCargoFlow(cargoFlowShipmentId) {
  return axios.get(BASE_API_URL + `/bookings/trackingShipments/` + cargoFlowShipmentId + `/map`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getUniqueColumnValuesBookingsSharedWithOrgId(orgainzationId) {
  return axios.get(BASE_API_URL + `/bookings/UniqueColumnValues/` + orgainzationId, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getContainersAttachedToDocumentByDocumentId(bookingNumber, documentId) {
  return axios.get(BASE_API_URL + `/bookings/${bookingNumber}/document/${documentId}/containers`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

export default {
//   getOrganizations,
//   getShipmentsCreatedByOrgID,
  getBookingsSharedByOrgID,
//   getShipmentsByShipmentID,
//   getProductsByShipmentID,
//   createShipment,
  createBookingByBookingReference,
//   generateEvent,
//   createNewEventType,
//   getShipmentsChannelId,
  getEventsByContainerId,
  getDocumentTypes,
//   updateShipment,
  getEvents,
//   getEventsByShipmentID,
//   getShipmentRevisionsByShipmentId,
  getContainersByBookingNumber,
//   getShipmentPorts,
//   getShipmentsByShipmentReferenceNumber,
  getLiveBookingRouteDataCargoFlow,
  getDocumentTypesByDestination,
  getDocumentTypesByDestinationForBookings,
  getBookingsByDocumentId,
  getUniqueColumnValuesBookingsSharedWithOrgId,
  getContainersAttachedToDocumentByDocumentId
}
