import React, { useState, useRef } from "react";
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Grid from "@material-ui/core/Grid";
import Chip from '@material-ui/core/Chip';
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import RoomIcon from '@material-ui/icons/Room';
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from '@material-ui/icons/Error';
import Backdrop from '@material-ui/core/Backdrop';
import TableRow from "@material-ui/core/TableRow";
import AdjustIcon from '@material-ui/icons/Adjust';
import SearchIcon from "@material-ui/icons/Search";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import InputBase from "@material-ui/core/InputBase";
import StorageIcon from '@material-ui/icons/Storage';
import ExploreIcon from '@material-ui/icons/Explore';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DescriptionIcon from '@material-ui/icons/Description';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import TablePagination from "@material-ui/core/TablePagination";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NotesIcon from '@material-ui/icons/Notes';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import RelativeTime from 'react-relative-time';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import "../../css/style.css";
import Util from "../../utils/Util";
import { APPLICATION_ID } from "../../utils/constants";
import notify from "../../utils/notifier";
import MapComponent from './shipmentMaps/mapComponent';
import EventTransactionInfo from "./EventTransactionInfo";
import Transactions from "./TransactionsShipmentRevision";
import FormRenderer from 'form-render-module'
import useStyles from './styles/ShipmentInformationStyles';
import documentService from "../../services/DocumentService";
import shipmentService from "../../services/ShipmentService";
import PrintIcon from '@material-ui/icons/Print';
import ShipmentContainers from "./ShipmentContainers";
import ReactToPrint from 'react-to-print';
import MenuItem from '@material-ui/core/MenuItem';
import SubjectIcon from '@material-ui/icons/Subject';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GenerateEvent from './GenerateEvent';
import CreateEvent from './CreateEvent';
import UploadDocuments from '../UploadDocuments';
import UpdateDocument from '../UpdateDocument';
import SharePrivateDocument from '../SharePrivateDocument';
import AttachDocumentToShipments from '../AttachDocumentToShipments';
import Highlight from 'react-highlighter';
import LockIcon from '@material-ui/icons/Lock';
import channelService from "../../services/ChannelService";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import organizationService from "../../services/OrganizationService";
import GetAppIcon from '@material-ui/icons/GetApp';
import FilePreview from './filePreview'


import {
    buildRequestObjCreateEventType,
    buildRequestObjGenerateEvent,
    disableSubmitButtonGenerateAnEvent,
    createJSON,
    MenuProps,
    userPrefs,
    _documentColumns,
    fileUploadCriteria
} from './ShipmentInformationUtils';

const highlightableColumns = ["name"]

export default function ShipmentInformation(props) {

    const [ShipmentID, setShipmentID] = React.useState(props.match.params.shipmentID);
    const [DocumentID, setDocumentID] = React.useState(0);
    const classes = useStyles();
    const [documentRows, setDocumentRows] = React.useState([]);
    const [containers, setContainers] = React.useState([]);
    const [originalDocumentRows, setOriginalDocumentRows] = React.useState([]);
    const [documentColumns, setDocumentColumns] = React.useState(_documentColumns);
    const [pageDocument, setPageDocument] = React.useState(0);
    const [rowsPerPageDocument, setRowsPerPageDocument] = React.useState(25);
    const [order, setOrder] = React.useState("asc");
    const [documentTableOrderBy, setDocumentTableOrderBy] = React.useState("id");
    const [searchKeyDocument, setSearchKeyDocument] = useState({ value: "" });
    const [documentQueryCount, setDocumentQueryCount] = useState(0);
    const [originalDocumentQueryCount, setOriginalDocumentQueryCount] = useState(0);
    const [newDocument, setNewDocument] = React.useState(0);
    const [update, setUpdate] = React.useState(false)
    const [tempDocumentJSON, setTempDocumentJSON] = React.useState({})
    const [documentTypes, setDocumentTypes] = React.useState([]);
    const [isRequiredDoc, setIsRequiredDoc] = React.useState(false);
    const [fileName, setFileName] = React.useState("");
    const [documentTypeIDMap, setDocumentTypeIDMap] = React.useState({})
    const [documentTypeIDMapRev, setdocumentTypeIDMapRev] = React.useState({})
    const [documentCodeIDMapRev, setdocumentCodeIDMapRev] = React.useState({})
    const [documentTypesInfo, setDocumentTypesInfo] = React.useState([])
    const [popupOpen, setpopupOpen] = React.useState(false);
    const [organizationID, setOrganisationID] = React.useState(localStorage.getItem('_orgID'));
    const [showDocumentJSON, setShowDocumentJSON] = React.useState({});
    const [version, setVersion] = React.useState(1);
    const [timestamp, setTimestamp] = React.useState("");
    const [lastUpdatedBy, setLastUpdatedBy] = React.useState("");
    const [documentHash, setDocumentHash] = React.useState("");
    const [tabValue, setTabValue] = React.useState(0);
    const [file, setFile] = useState("");
    const [transInfo, setTransInfo] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState("");
    const [events, setEvents] = React.useState([])
    const [eventMap, setEventMap] = React.useState({})
    const [rowFileType, setRowFileType] = React.useState('');
    const [newEventType, setNewEventType] = React.useState('');
    const [blocksList, setBlocksList] = useState([]);
    const [showTransactionsInfoBlock, setShowTransactionsInfoBlock] = useState(false);
    const [xsValueBlock, setXsBlock] = useState(12);
    const [backdrop, setBackdrop] = React.useState(false);
    const [documentRow, setDocumentRow] = React.useState(null)
    const [documentTypeFortransaction, setDocumentTypeFortransaction] = React.useState("")
    const [documentCodeFortransaction, setDocumentCodeFortransaction] = React.useState("")
    const [showMap, setShowMap] = React.useState(false)
    const [tampered, setTampered] = React.useState(null);
    const [eventId, setEventId] = React.useState(null);
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [openPanels, setOpenPanels] = React.useState({});
    const [shipmentCurrentRoute, setShipmentCurrentRoute] = React.useState([]);
    const [shipmentFutureRoute, setShipmentFutureRoute] = React.useState([]);
    const [ports, setPorts] = React.useState([]);
    const [shipmentLocation, setShipmentLocation] = React.useState({});
    const [selectedContainer, setSelectedContainer] = React.useState("");
    const [eta, setEta] = React.useState("");
    const [atd, setAtd] = React.useState("");
    const [routeDataFetched, setRouteDataFetched] = React.useState(false);
    const [fetchingRouteData, setFetchingRouteData] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [subAssetTypeId, setSubAssetTypeId] = React.useState("");
    const [filter, setFilter] = React.useState(true);
    const [selectedDocumentTypes, setSelectedDocumentTypes] = React.useState({ value: [] });
    const [documentTypesArray, setDocumentTypesArray] = React.useState([]);
    const [uploadMissingDocType, setUploadMissingDocType] = React.useState(false);
    const [footerStyle, setFooterStyle] = React.useState(false);
    const [documentVersion, setDocumentVersion] = React.useState("");
    const [channelMembers, setChannelMembers] = React.useState([]);
    const [privateMemberList, setPrivateMemberList] = React.useState([]);
    const [transactionComponentLoaded, setTransactionComponentLoaded] = React.useState(false);

    const sideDrawerMap = {
        "RowClickDocument": 0,
        "UploadDocument": 1,
        "GenerateEvent": 2,
        "CreateEventType": 3
    }

    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const [uploadValues, setValues] = React.useState({
        DocumentID: "",
        DocumentType: "",
        DocumentCode: "",
        DocumentName: "",
        DocumentDescription: "",
        DocumentJSON: "",
        eventType: "",
        location: "",
        eventJSON: {},
        containerId: null,
        containerNumber: "",
        descriptionFromSource: "",
        source: "",
    });

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    React.useEffect(() => {
        fetchDocumentTypes();
        fetchData();
        fetchRouteData();
    }, []);

    const setInitialState = () => {
        uploadValues.DocumentID = ""
        uploadValues.DocumentType = ""
        uploadValues.DocumentCode = ""
        uploadValues.DocumentName = ""
        uploadValues.DocumentDescription = ""
        uploadValues.DocumentJSON = ""
        setFileName("");
        setTempDocumentJSON({})
        setDocumentID(0)
        setRowFileType("")
        setUpdate(false)
        setFile("")
        setNewEventType("")
        setDocumentRow(null)
        setInvalidUserInput([]);
        uploadValues.eventType = "";
        uploadValues.location = "";
        uploadValues.eventJSON = {};
        uploadValues.containerId = null;
        uploadValues.containerNumber = "";
        uploadValues.descriptionFromSource = "";
        uploadValues.source = "";
        setSubAssetTypeId("");
        setFooterStyle(false);
        setDocumentVersion("");
        setChannelMembers([]);
        setPrivateMemberList([]);
        setSelectedDocumentTypes({ value: [] });
        setSearchKeyDocument({ value: "" });
        setTransactionComponentLoaded(false);
    }

    const fetchData = () => {
        setEta(props.location.state.shipmentValue[0].eta ? new Date(props.location.state.shipmentValue[0].eta).toLocaleDateString() : "-");
        setAtd(props.location.state.shipmentValue[0].atd ? new Date(props.location.state.shipmentValue[0].atd).toLocaleDateString() : "-");
        fetchContainersbyShipmentID();
    };

    function buildShipmentRouteDataObj() {
        var formData = {};
        formData["startPort"] = props.location.state.shipmentValue[0].podCode;
        formData["endPort"] = props.location.state.shipmentValue[0].poaCode;
        return formData
    }

    function fetchRouteData() {
        let shipmentRouteDataObj = buildShipmentRouteDataObj();
        setBackdrop(true);
        shipmentService.getLiveShipmentRouteDataCargoFlow(props.location.state.shipmentValue[0].cargoFlowShipmentId).then(
            response => {
                if (response.data["pastRoute"].length) {
                    setShipmentCurrentRoute(response.data["pastRoute"]);
                    setRouteDataFetched(true);
                }
                if (response.data["futureRoute"].length) {
                    setShipmentFutureRoute(response.data["futureRoute"]);
                    setRouteDataFetched(true);
                }
                if (response.data["ports"].length) {
                    setPorts(response.data["ports"]);
                }
                if (response.data["shipmentLocation"]) {
                    setShipmentLocation(response.data["shipmentLocation"])
                }
                setBackdrop(false);
                setFetchingRouteData(false);
            },
            error => {
                setBackdrop(false);
                setFetchingRouteData(false);
                // notify({
                //     type: "error",
                //     message: "Error while fetching shipment maps"
                // });
            }
        );
    }

    const handleClick = container => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPanels({ ...openPanels, [container]: !openPanels[container] });
    };

    const showTransactionsBlock = (txId, data) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setXsBlock(6);
        setSelectedEvent(txId);
        setEventId(data)
        setShowTransactionsInfoBlock(true);
    };

    const closeTransactionsPaneBlock = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setXsBlock(12);
        setSelectedEvent(null);
        setShowTransactionsInfoBlock(false);
    };

    const handleEventTypeChange = prop => (event) => {
        setNewEventType(event.target.value)
    };


    const handleChangeTabs = (event, newValue) => {
        if (newValue === 3) {
            fetchDocumentsbyShipmentID()
        } else if (newValue === 2) {
            if (drawerState.right) {
            } else {
                fetchEventsbyContainerId(selectedContainer);
            }
        }
        setTabValue(newValue);
    };

    const handleChangeContainer = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSelectedContainer(event.target.value);
        fetchEventsbyContainerId(event.target.value);

    };

    const handleClickPopupOpen = (event, row) => {
        setBackdrop(true)
        uploadValues.DocumentID = row.id
        uploadValues.DocumentType = documentTypeIDMapRev[row.subAssetTypeId]
        uploadValues.DocumentCode = documentCodeIDMapRev[row.subAssetTypeId]
        uploadValues.DocumentName = row.name
        uploadValues.DocumentDescription = row.description

        documentService.downloadDocumentMetadata(row.id, row.version).then(
            response => {
                setBackdrop(false)
                const _data = response.data;
                let jsonData = typeof (_data.data.metadata) === "string" ? JSON.parse(_data.data.metadata) : _data.data.metadata;
                setVersion(_data["version"])
                uploadValues.DocumentJSON = jsonData;
                setShowDocumentJSON(jsonData);
                setTampered(_data["tampered"])
                setTimestamp(_data["created_at"])
                setLastUpdatedBy(`${_data["first_name"]}${_data["last_name"] !== "null" && _data["last_name"] ? " " + _data["last_name"] : ""}, ${_data["organization_name"]}`)
                setDocumentHash(_data["documentHash"])
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching  Document"
                });
            }
        );
        setpopupOpen(true);
    };

    const handleClickDownloadDocument = (event, row) => {
        setBackdrop(true);
        documentService.downloadDocument(row.id, row.version).then(
            response => {
                setBackdrop(false)
                Util.downloadTemplate(response);
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }
            }
        );
    };

    const handlePopupClose = () => {
        setVersion(0)
        setTimestamp("")
        setBackdrop(false)
        setShowDocumentJSON({})
        setpopupOpen(false);
        setInitialState();
    };

    const handleClickPreviewDocument = (event, row) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true)

        documentService.downloadDocument(row.id, row.version).then(
            response => {
                setBackdrop(false)

                var type = response.headers["content-type"];
                if (type === 'application/octet-stream') {
                    type = 'application/pdf';
                }

                const blob = new Blob([response.data], {
                    type: type,
                    encoding: "UTF-8"
                });

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                window.open(link.href)
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }


            }
        );
    };

    const handleSubmitGenerateEvent = () => {
        let formDataGenerateEvent = buildRequestObjGenerateEvent(organizationID, eventMap, uploadValues);
        if (!formDataGenerateEvent) {
            return;
        }
        setBackdrop(true);
        shipmentService.generateEvent(props.match.params.shipmentID, formDataGenerateEvent).then(
            response => {
                refreshEvents();
                setInitialState();
                setDrawerState({ ...drawerState, ["right"]: false });
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while creating an event"
                });
            }
        );
    }

    const handleSubmitCreateEventType = () => {

        let formDataCreateEventType = buildRequestObjCreateEventType(organizationID, newEventType);
        if (!formDataCreateEventType) {
            return;
        }
        setBackdrop(true);
        shipmentService.createNewEventType(formDataCreateEventType).then(
            response => {
                refreshEvents();
                setInitialState();
                setDrawerState({ ...drawerState, ["right"]: false });
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while creating an event"
                });
            }
        );
    }

    const handleOpenDrawerGenerateEvent = (side, open, row) => event => {
        setNewDocument(sideDrawerMap["GenerateEvent"])
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setBackdrop(true)
        shipmentService.getEvents().then(
            response => {
                const _data = response.data;
                let arr = []
                let dic = {}
                for (let i = 0; i < _data.length; i++) {
                    arr.push(_data[i]["name"])
                    dic[_data[i]["name"]] = [_data[i]["id"], _data[i]["code"]];
                }
                setEventMap(dic)
                setEvents(arr)
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching events"
                });
            }
        );
    };

    const handleOpenDrawerCreateEventType = (side, open, row) => event => {
        setNewDocument(sideDrawerMap["CreateEventType"])
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
    };

    const handleOpenUploadDocument = (side, open, row) => event => {
        setUploadMissingDocType(false);
        setInitialState();
        setNewDocument(sideDrawerMap["UploadDocument"])
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setTempDocumentJSON(createJSON(props))
    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setInitialState();
    };

    const handleRequestSortDocumentTable = property => event => {
        const isDesc = documentTableOrderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setDocumentTableOrderBy(property);
        fetchDocumentsbyShipmentID(rowsPerPageDocument, 0, property, isDesc ? "asc" : "desc", searchKeyDocument.value, selectedDocumentTypes);
    };

    const obj = (arg) => {
        if (!footerStyle) {
            setFooterStyle(true);
        }
        var json = Object.assign({}, arg)
        var temp = Object.assign(tempDocumentJSON, json)
        setTempDocumentJSON(temp)
        if (Object.keys(json).length === 0) {

        } else {
            setValues({ ...uploadValues, ["DocumentJSON"]: temp });
        }
    }

    const handleChangePageDocument = (event, newPage) => {
        setPageDocument(newPage);
        fetchDocumentsbyShipmentID(rowsPerPageDocument, newPage, documentTableOrderBy, order, searchKeyDocument.value, selectedDocumentTypes);
    };

    const handleChangeRowsPerPageDocument = event => {
        setRowsPerPageDocument(+event.target.value);
        setPageDocument(0);
        fetchDocumentsbyShipmentID(+event.target.value, 0, documentTableOrderBy, order, searchKeyDocument.value, selectedDocumentTypes);
    };

    const clearSearchTextDocument = () => {
        setSearchKeyDocument({ value: "" });
        fetchDocumentsbyShipmentID(rowsPerPageDocument, pageDocument, documentTableOrderBy, order, "", selectedDocumentTypes);
    };

    const showRouteMap = () => {
        if (showMap) {
            setShowMap(false);
        } else {
            setShowMap(true);
        }
    };

    let timeout = null;
    const handleDocumentSearch = event => {
        const _val = event.target.value;
        setSearchKeyDocument(prevState => {
            return { ...prevState, value: _val };
        });

        if (_val.length <= 2) {
            setDocumentRows(originalDocumentRows);
            setDocumentQueryCount(originalDocumentQueryCount);
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchDocumentsbyShipmentID(rowsPerPageDocument, pageDocument, documentTableOrderBy, order, _val, selectedDocumentTypes);
        }, 200);
    };

    const handleClickRowDocument = (event, row, _update = false) => {
        setDocumentRow(row);
        setDocumentTypeFortransaction(documentTypeIDMapRev[row.subAssetTypeId])
        setDocumentCodeFortransaction(documentCodeIDMapRev[row.subAssetTypeId])
        setNewDocument(sideDrawerMap["RowClickDocument"])
        setDocumentID(row.id)
        setShipmentID(props.match.params.shipmentID);
        setSubAssetTypeId(row.subAssetTypeId);
        setDocumentVersion(row.version);
        uploadValues.DocumentID = row.id
        uploadValues.DocumentType = documentTypeIDMapRev[row.subAssetTypeId]
        uploadValues.DocumentCode = documentCodeIDMapRev[row.subAssetTypeId]
        uploadValues.DocumentName = row.name
        uploadValues.DocumentDescription = row.description
        setFileName(row.originalFileName);
        setTransactionComponentLoaded(false);

        if (row.fileType === "JSON") {
            setRowFileType("JSON")
        } else if (row.fileType === "File") {
            setRowFileType("File")
        }
        if (_update) {
            setDrawerState({ ...drawerState, ["right"]: true });
            setUpdate(true);
        }

        if (row.isPrivate == true) {

            let userOrgId = parseInt(localStorage.getItem('_orgID'));

            channelService.getOrgNameMspIdByChannelId(props.location.state.shipmentValue[0].channelId).then(
                response => {
                    const _data = response.data;
                    setChannelMembers(_data);
                },
                error => {
                    notify({
                        type: "error",
                        message: "Error while fetching channel members list"
                    });
                }
            );

            documentService.getOrganizationsListForPrivateDoc(row.id).then(
                response => {
                    let data = response.data;
                    data = data.map(member => {
                        member['id'] = member['organizationId'];
                        return member;
                    })
                    setPrivateMemberList(data);
                },
                error => {
                    notify({
                        type: "error",
                        message: `Error while getting private member list for document : ${row.name}`
                    });
                }
            );
        }
    };

    const buttonClickClearAllFilters = () => {
        let doRefresh = false
        if (selectedDocumentTypes.value.length > 0) {
            doRefresh = true
        }
        selectedDocumentTypes.value = []
        if (doRefresh) {
            fetchDocumentsbyShipmentID();
        }
    }

    const handleClickUploadMissingDocument = (_docType) => event => {
        setUploadMissingDocType(true);
        uploadValues.DocumentType = _docType;
        uploadValues.DocumentCode = documentCodeIDMapRev[documentTypeIDMap[_docType]];
        setNewDocument(sideDrawerMap["UploadDocument"]);
        setDrawerState({ ...drawerState, ["right"]: true });
    }

    const tableHeadWidth = (100 / documentColumns.length) + "%";

    const createDocumentTableInner = (row) => {
        let table = [];
        let fileExtensionPreviewAllowed = ['pdf', 'png', 'jpg', 'html' , 'jpeg'];
        for (let i = 0; i < documentColumns.length; i++) {
            var column = documentColumns[i]
            const value = row[column.id];
            let originalFileName = row['originalFileName'];
            const fileExt = originalFileName ? originalFileName.split('.').slice(-1).pop() : "";

            if (column.id === "version") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        v{column.format && typeof value === "number" ? (column.format(value)) : (value)}
                    </TableCell>
                )
            } else if (column.id === "Link") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align}>
                        <div className={classes.actionDiv}>
                            <Tooltip title={row.fileType === "JSON" ? "Preview" : fileExtensionPreviewAllowed.includes(fileExt) ? "Preview" : "Preview is not supported for this file type" }>
                                <span>
                                    <Button className={classes.buttonDownload} disabled={row.fileType === "JSON" ? false : (fileExtensionPreviewAllowed.includes(fileExt) ? false : true)} onClick={event => row.fileType === "JSON" ? handleClickPopupOpen(event, row): handleClickPreviewDocument(event, row)}>
                                        <DescriptionIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={row.fileType === "File" ? "Download" : "Download is not supported"}>
                                <span>
                                    <Button className={classes.buttonDownload} disabled={row.fileType === "JSON"} onClick={event => handleClickDownloadDocument(event, row)}>
                                        <CloudDownloadIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title="Update">
                                <Button className={classes.buttonDownload} onClick={event => handleClickRowDocument(event, row, true)}>
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    </TableCell>
                )
            } else if (column.id == "subAssetTypeName") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        <Highlight search={searchKeyDocument.value}>
                            {
                                documentTypes.includes(value) ? value + String.fromCharCode(42) : (value && value !== 'null') ? value : '-'
                            }
                        </Highlight>
                    </TableCell>
                )
            } else if (column.id == "name") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        <div className={classes.documentNameDiv}>
                            <Highlight search={searchKeyDocument.value}>
                            {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                            </Highlight>
                        </div>
                    </TableCell>
                )
            } else if (column.id === "originalFileName") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        <div className={classes.documentNameDiv}>
                            <Highlight search={searchKeyDocument.value}>
                                {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                            </Highlight>
                            {
                                row.isPrivate === true ?
                                    <div className={classes.privateDocTooltipDiv}>
                                        <Tooltip title={"Private document"}>
                                            <LockIcon style={{ fontSize: "0.775rem" }} color="primary" />
                                        </Tooltip>
                                    </div>
                                    : ""
                            }
                        </div>
                    </TableCell>
                )
            }
            else {
                if (highlightableColumns.includes(column.id)) {
                    table.push(
                        <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                            <Highlight search={searchKeyDocument.value}>
                            {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                            </Highlight>
                        </TableCell>
                    )
                } else {
                    table.push(
                        <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                            {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                        </TableCell>
                    )
                }
            }
        }
        return table
    }

    function handleSharePrivateDocumentSuccessfully() {
        setChannelMembers([]);
        setPrivateMemberList([]);
    }

    function handleTransactionComponentLoaded(open) {
        setTransactionComponentLoaded(open);
    }

    const createDocumentTable = () => {
        let allUploadedDocTypes = [];
        for (let i of documentRows) {
            if (!allUploadedDocTypes.includes(i.subAssetTypeName)) {
                allUploadedDocTypes.push(i.subAssetTypeName);
            }
        }
        let remainingDocToUpload = documentTypes.filter(i => !allUploadedDocTypes.includes(i));
        let table = [];
        documentRows.map((row, index) => {
            if (row.id == DocumentID && !update) {
                table.push(
                    <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index}  >
                        {createDocumentTableInner(row)}
                    </TableRow>
                )
                {
                    if (!backdrop) {
                        table.push(
                            <TableRow>
                                <TableCell colSpan="8" align="center">
                                    <div className={classes.docRevisionRow}>
                                        <AttachDocumentToShipments documentId={row.id} channelId={row.channelId}/>
                                        {
                                            row.isPrivate === true && privateMemberList.length && channelMembers.length ?
                                                <SharePrivateDocument privateMemberList={privateMemberList} channelMembers={channelMembers}
                                                    documentId={row.id} handleSharePrivateDocumentSuccessfully={handleSharePrivateDocumentSuccessfully}
                                                />
                                                : ""
                                        }
                                        {
                                            transactionComponentLoaded ? "" : <CircularProgress color="inherit" />
                                        }
                                        <Transactions xsValue={12} shipmentID={ShipmentID} documentID={DocumentID} documentRow={documentRow} documentCodeFortransaction={documentCodeFortransaction} 
                                            documentTypeFortransaction={documentTypeFortransaction} subAssetTypeId={subAssetTypeId} channel={props.location.state.shipmentValue[0].channelId} 
                                            fileType={rowFileType} handleTransactionComponentLoaded={handleTransactionComponentLoaded}/>
                                    </div>
                                </TableCell>
                            </TableRow>
                        )
                    }
                }

            } else {
                table.push(
                    <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index}  >
                        {createDocumentTableInner(row)}
                    </TableRow>
                )
            }
        });

        if (remainingDocToUpload.length) {
            for (let docType of remainingDocToUpload) {
                table.push(
                    <TableRow style={{ height: "60px" }}>
                        <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                            <div className={classes.missingDocTypeDiv}>
                                {"Missing required document: " + docType}
                                <Tooltip title="Upload">
                                    <Button className={classes.buttonDownload} onClick={handleClickUploadMissingDocument(docType)}>
                                        <CloudUploadIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </TableCell>
                    </TableRow>
                )
            }
        }
        return table;
    }

    const createNoDocumentTable = () => {
        let arr = [];

        if (documentTypes.length) {
            if (backdrop) {
                arr.push(
                    <TableRow style={{ height: "60px" }}>
                        <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                            {"Fetching the Documents, please wait"}
                        </TableCell>
                    </TableRow>
                )
            } else {
                for (let docType of documentTypes) {
                    arr.push(
                        <TableRow style={{ height: "60px" }}>
                            <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                                <div className={classes.missingDocTypeDiv}>
                                    {"Missing required document: " + docType}
                                    <Tooltip title="Upload">
                                        <Button className={classes.buttonDownload} onClick={handleClickUploadMissingDocument(docType)}>
                                            <CloudUploadIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </TableCell>
                        </TableRow>
                    )
                }
            }
        } else {
            arr.push(
                <TableRow style={{ height: "60px" }}>
                    <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                        {backdrop ? " Fetching the documents, please wait" : "No documents are found."}
                    </TableCell>
                </TableRow>
            )
        }

        return arr;
    }

    function fetchEventsbyContainerId(containerId) {
        //setBackdrop(true)
        const query = {
            '_sort': 'createdAt',
            '_order': 'desc'
        }
        setBackdrop(true);

        shipmentService.getEventsByContainerId(props.match.params.shipmentID, containerId).then(
            response => {
                const _data = response.data;
                setTransInfo(_data);
                setBlocksList(_data);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching events"
                });
            }
        );
    }

    function TabPanel(props) {

        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
    }

    function a11yPropsMain(index) {

        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }

    function refreshDocumentTable() {
        setInitialState();
        setDrawerState({ ...drawerState, ["right"]: false });
        setUploadMissingDocType(false);
        fetchDocumentsbyShipmentID();
    }

    function refreshEvents() {
        setDrawerState({ ...drawerState, ['right']: false });
        fetchEventsbyContainerId(selectedContainer);
    }

    const buttonClickFilter = () => event => {
        if (filter) {
            setFilter(false)
            setSelectedDocumentTypes({ value: [] });
        } else {
            setFilter(true)
        }
    }

    const handleChangeMulitpleSelect = (event) => {
        setSelectedDocumentTypes({ value: event.target.value });
        fetchDocumentsbyShipmentID(rowsPerPageDocument, 0, documentTableOrderBy, "asc", null, event.target.value);
    };

    function fetchDocumentsbyShipmentID(pageSize = rowsPerPageDocument, currentPage = 0, sortBy = documentTableOrderBy, sortOrder = 'asc', searchText = null, filterArray = null) {
        let query = {
            '_page': currentPage + 1,
            '_limit': pageSize,
            '_sort': sortBy,
            '_order': sortOrder
        }
        if (searchText) {
            query['q'] = searchText;
        }

        if (filterArray && filterArray.length > 0) {
            // documentTypeIDMap
            let newArray = []
            for (const documentType of filterArray) {
                newArray.push(documentTypeIDMap[documentType])
            }
            query["_subAssetTypeId"] = newArray
        }

        setBackdrop(true)
        documentService.getDocumentsByShipmentID(props.match.params.shipmentID, query).then(
            response => {
                let _data = response.data;
                for (let i = 0; i < _data.length; i++) {
                    _data[i]['documentNo'] = i + 1
                    _data[i]["Link"] = "Download Document"
                }
                setDocumentRows(_data);
                if (response.headers.hasOwnProperty('x-total-count')) {
                    setDocumentQueryCount(parseInt(response.headers['x-total-count']));
                }

                if (!searchText) {
                    setOriginalDocumentRows(_data)
                    setOriginalDocumentQueryCount(parseInt(response.headers['x-total-count']));
                }

                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                if (searchText) {
                    setDocumentRows([]);
                }
                else {
                    notify({
                        type: "error",
                        message: "Error while fetching Documents"
                    });
                }
            }
        );
    }

    function fetchDocumentTypes() {
        let shipmentReference = props.location.state.shipmentValue[0].shipmentReference;
        setBackdrop(true);
        shipmentService.getDocumentTypesByDestination(shipmentReference).then(
            response => {
                const _data = response.data;
                let _isReq = false;
                let arr = []
                let dict = {}
                let dictRev = {}
                let dictMap = {}
                let _tempArr = [];
                for (let i = 0; i < _data.length; i++) {
                    var singleData = _data[i]
                    if (singleData["isRequiredDoc"]) {
                        _isReq = true;
                        arr.push(singleData["name"]);
                    }
                    if (singleData["name"] && singleData["name"].length) {
                        _tempArr.push(singleData["name"]);
                    }
                    dict[singleData["name"]] = singleData["id"]
                    dictRev[singleData["id"]] = singleData["name"]
                    dictMap[singleData["id"]] = singleData["code"]
                }
                setDocumentTypes(arr);
                setIsRequiredDoc(_isReq);
                setDocumentTypeIDMap(dict);
                setdocumentTypeIDMapRev(dictRev);
                setDocumentTypesArray(_tempArr);
                setdocumentCodeIDMapRev(dictMap);
                setDocumentTypesInfo(_data);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching Document types"
                });
            }
        );
    }

    function fetchContainersbyShipmentID(searchText = "") {
        setBackdrop(true);
        let query = {};
        if (searchText) {
            query['q'] = searchText;
        }

        shipmentService.getContainersByShipmentID(props.match.params.shipmentID, query).then(
            response => {
                const _data = response.data;
                setContainers(_data);
                if (_data.length) {
                    setSelectedContainer(_data[0].containerId)
                }
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching Containers"
                });
            }
        );
    }

    const componentRef = useRef();

    const newDocumentSideList = side => (
        <UploadDocuments shipmentId={ShipmentID} shipmentNumber={props.location.state.shipmentValue[0].shipmentNumber}
            shipmentReference={props.location.state.shipmentValue[0].shipmentReference} uploadMissingDocType={uploadMissingDocType} uploadMissingDocTypeName={uploadValues.DocumentType}
            documentList={documentRows} refreshDocumentTable={refreshDocumentTable} fromPage={"shipmentInformation"} channelId={props.location.state.shipmentValue[0].channelId}
            closeDrawer={closeDrawer} documentTypesInfo={documentTypesInfo} events={events} documentCodeIDMapRev={documentCodeIDMapRev} documentTypeIDMap={documentTypeIDMap}
        />
    );

    const GenerateEventSideList = side => (
        <GenerateEvent shipmentID={props.match.params.shipmentID} refreshEvents={refreshEvents} closeDrawer={closeDrawer} containers={containers} />
    );

    const CreateEventTypeSideList = side => (
        <CreateEvent closeDrawer={closeDrawer} refreshEvents={refreshEvents} />
    );

    const modifyDocumentSideList = side => (
        <UpdateDocument subAssetTypeId={subAssetTypeId} rowFileType={rowFileType} shipmentID={ShipmentID} documentID={DocumentID} documentType={uploadValues.DocumentType}
            documentCode={uploadValues.DocumentCode} documentName={uploadValues.DocumentName} documentDescription={uploadValues.DocumentDescription} fileName={fileName} refreshDocumentTable={refreshDocumentTable}
            shipmentNumber={props.location.state.shipmentValue[0].shipmentNumber} shipmentReference={props.location.state.shipmentValue[0].shipmentReference}
            version={documentVersion} closeDrawer={closeDrawer} isPrivate={documentRow ? documentRow.isPrivate : false} channelId={props.location.state.shipmentValue[0].channelId}
        />
    );

    const handleClickReqDocOpen = () => {
        setOpen(true);
    };


    const handleClickReqDocClose = () => {
        setOpen(false);
    };

    function SimpleDialog(props) {
        const { onClose, open, documentList } = props;

        const handleClose = () => {
            onClose();
        };

        return (
            <Dialog className={classes.reqDocumentsDialog} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title" align="center">Required Documents</DialogTitle>
                <Divider />
                <List>
                    {documentList.map((document) => (
                        <ListItem key={document}>
                            <ListItemAvatar>
                                <DescriptionIcon />
                            </ListItemAvatar>
                            <ListItemText primary={document} />
                        </ListItem>
                    ))}
                </List>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        selectedValue: PropTypes.string.isRequired,
    };

    return (
        <div className={classes.borderMain}>
            <Paper className={classes.root}>
                <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} style={{ minHeight: "87vh" }}>
                    <section className={classes.shipmentInfo}>
                        <h2>
                            {<Link style={{ textDecoration: 'none' }} to={{ pathname: "/shipments" }}>
                                <a color="primary" className={classes.a}>
                                    Shipments
                                </a>
                            </Link>}
                            / Shipment Details
                            <Chip label={"Shipment Number : " + (props.location.state.shipmentValue[0].shipmentNumber ? props.location.state.shipmentValue[0].shipmentNumber : "-")} style={{ float: "right",marginLeft:'20px' }} />
                            <Chip label={"Channel : " + props.location.state.shipmentValue[0].channelName} style={{ float: "right" ,marginLeft:'20px' }} />

                        </h2>
                        <div className={classes.shipmentInfopara}>
                            <div className={classes.paper_root}>
                                <Paper variant="outlined" square>
                                    <p align="center" className={classes.smallText}>
                                        Shipment Reference
                                    </p>
                                    <h3 align="center" >
                                        {props.location.state.shipmentValue[0].shipmentReference ? props.location.state.shipmentValue[0].shipmentReference : "-"}
                                    </h3>
                                </Paper>
                                <Paper variant="outlined" square>
                                    <p align="center" className={classes.smallText}>
                                        Carrier
                                    </p>
                                    <h3 align="center" >
                                        {props.location.state.shipmentValue[0].carrier ? props.location.state.shipmentValue[0].carrier : "-"}
                                    </h3>
                                </Paper>
                                <Paper variant="outlined" square >
                                    <p align="center" className={classes.smallText}>
                                        Port of Origin
                                    </p>
                                    <h3 align="center" >
                                        {props.location.state.shipmentValue[0].podName ? props.location.state.shipmentValue[0].podName : "-"}
                                    </h3>
                                </Paper>
                                <Paper variant="outlined" square >
                                    <p align="center" className={classes.smallText}>
                                        Port of Destination
                                    </p>
                                    <h3 align="center" >
                                        {props.location.state.shipmentValue[0].poaName ? props.location.state.shipmentValue[0].poaName : "-"}
                                    </h3>
                                </Paper>

                                <Paper variant="outlined" square>
                                    <p align="center" className={classes.smallText}>
                                        Vessel
                                    </p>
                                    <h3 align="center" >
                                        {props.location.state.shipmentValue[0].vessel ? props.location.state.shipmentValue[0].vessel : "-"}
                                    </h3>
                                </Paper>

                                <Paper variant="outlined" square>
                                    <p align="center" className={classes.smallText}>
                                        Voyage
                                    </p>
                                    <h3 align="center" >
                                        {props.location.state.shipmentValue[0].voyage ? props.location.state.shipmentValue[0].voyage : "-"}
                                    </h3>
                                </Paper>

                                <Paper variant="outlined" square>
                                    <p align="center" className={classes.smallText}>
                                        Vessel ATD
                                    </p>
                                    <h3 align="center" >
                                        {atd}
                                    </h3>
                                </Paper>
                                <Paper variant="outlined" square>
                                    <p align="center" className={classes.smallText}>
                                        Vessel ETA
                                    </p>
                                    <h3 align="center" >
                                        {eta}
                                    </h3>
                                </Paper>


                            </div>
                        </div>
                    </section>
                    <div className={classes.tableHeadRight}>
                        {
                            tabValue === 0 ?
                                ""
                                :
                                tabValue === 1 ?
                                    ""
                                    : tabValue === 2 ?
                                        ""
                                        :
                                        tabValue === 3 ?
                                            <div className={classes.searchBox}>
                                                <InputBase
                                                    onChange={handleDocumentSearch}
                                                    value={searchKeyDocument.value}
                                                    className={classes.input}
                                                    placeholder={"Search Documents"}
                                                />
                                                {searchKeyDocument.value.length ? (
                                                    <IconButton
                                                        size="medium"
                                                        aria-label="search"
                                                        onClick={clearSearchTextDocument}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                ) : (
                                                        ""
                                                    )}
                                                <IconButton
                                                    size="medium"
                                                    aria-label="search"
                                                    disabled
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </div>
                                            :
                                            ""
                        }
                        {tabValue === 0 ?
                            ""
                            : tabValue === 1 ?
                                ""
                                : tabValue === 2 ?
                                    <div>
                                        <Button color="primary" className={classes.button} onClick={handleOpenDrawerGenerateEvent("right", true)} startIcon={<AddCircleIcon />} disabled={localStorage.getItem("_orgCode") !== "dpw-cargoes"}>
                                            {"Generate an Event"}
                                        </Button>
                                        <Button color="primary" className={classes.button} onClick={handleOpenDrawerCreateEventType("right", true)} startIcon={<AddCircleIcon />} disabled={localStorage.getItem("_orgCode") !== "dpw-cargoes"}>
                                            {"Create an Event Type"}
                                        </Button>
                                    </div>
                                    : tabValue === 3 ?
                                        <Button color="primary" className={classes.button} onClick={handleOpenUploadDocument("right", true)} startIcon={<AddCircleIcon />} >
                                            {"Upload documents"}
                                        </Button>
                                        : ""
                        }
                    </div>
                    <SwipeableDrawer anchor="right" open={drawerState.right} >
                        {newDocument === 0 ? modifyDocumentSideList('right') : newDocument === 1 ? newDocumentSideList('right') : newDocument === 2 ? GenerateEventSideList('right') : newDocument === 3 ? CreateEventTypeSideList('right') : ""}
                    </SwipeableDrawer>
                    <br></br>
                    <Tabs value={tabValue} indicatorColor="primary" className={classes.tabStyling}
                        textColor="primary" onChange={handleChangeTabs} aria-label="simple tabs example">
                        <Tab icon={<div> <ExploreIcon className={classes.iconAlign} /> &nbsp;Shipment Route</div>} {...a11yPropsMain(0)} />
                        <Tab icon={<div> <StorageIcon className={classes.iconAlign} /> &nbsp;Containers {containers != null && containers.length > 0 ? <span> ( {containers.length} )</span> : ""}</div>} {...a11yPropsMain(1)} />
                        <Tab label={<div><EventAvailableIcon className={classes.iconAlign} /> &nbsp; Events {blocksList != null && blocksList.length > 0 ? <span> ( {blocksList.length} )</span> : ""} </div>} {...a11yPropsMain(2)} />
                        <Tab label={<div> <InsertDriveFileIcon className={classes.iconAlign} /> &nbsp; Documents {documentRows != null && documentRows.length > 0 ? <span> ( {documentRows.length} )</span> : ""}  </div>} {...a11yPropsMain(3)} />
                    </Tabs>
                    <Divider className={classes.dividerStyling}></Divider>
                    <TabPanel value={tabValue} index={0}>
                        <Grid item xs={12}>
                            <MapComponent shipmentCurrentRoute={shipmentCurrentRoute} shipmentFutureRoute={shipmentFutureRoute} routeDataFetched={routeDataFetched} ports={ports} shipmentLocation={shipmentLocation} fetchingRouteData={fetchingRouteData} />
                        </Grid>
                    </TabPanel>
                    <SimpleDialog open={open} documentList={documentTypes} onClose={handleClickReqDocClose} />
                    <TabPanel value={tabValue} index={3}>
                        <Grid item xs={12}>
                            <div className={classes.filtersDiv}>
                                {
                                    filter ?
                                        <div className={classes.filtersDivRight}>
                                            <FormControl className={isRequiredDoc ? classes.filterTextFieldIsRequiredDoc : classes.filterTextField}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Document Type</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={selectedDocumentTypes.value}
                                                    onChange={handleChangeMulitpleSelect}
                                                    input={<Input />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                >
                                                    {documentTypesArray.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                            <Checkbox checked={selectedDocumentTypes.value.indexOf(name) > -1} />
                                                            <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                color="primary"
                                                className={isRequiredDoc ? classes.clearFilterButtonIsRequiredDoc : classes.clearFilterButton}
                                                onClick={buttonClickClearAllFilters}
                                            >
                                                {"Clear All"}
                                            </Button>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            {/* <Button
                                    color="primary"
                                    className={classes.filterButton}
                                    onClick={buttonClickFilter()}
                                    startIcon={<SearchIcon />}
                                >
                                    {"Filter"}
                                </Button> */}
                            <div className={classes.tableWrapper}>
                                <Paper className={classes.tableContainer}>
                                    <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                        <TableHead>
                                            <TableRow>
                                                {documentColumns.map(column => (
                                                    <TableCell key={column.id} className={classes.tableHeadRow} width={column.minWidth} align={column.align} sortDirection={documentTableOrderBy === column.id ? order : false} >
                                                        {column.sortable ? (
                                                            <TableSortLabel className={classes.tableColumnLabel} active={documentTableOrderBy === column.id} direction={documentTableOrderBy === column.id ? order : 'asc'} onClick={handleRequestSortDocumentTable(column.id)}>
                                                                {column.label}
                                                                {documentTableOrderBy === column.id ? (
                                                                    <span className={classes.visuallyHidden}>
                                                                        {order === "desc"
                                                                            ? "sorted descending"
                                                                            : "sorted ascending"}
                                                                    </span>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        ) : (column.label)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {documentRows.length === 0 ? createNoDocumentTable() : createDocumentTable(documentRows)}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </div>
                            {
                                documentRows.length === 0 ? "" :
                                    <div className={classes.tableFooterDiv}>
                                        <div className={classes.tableFooterNoteDiv}>
                                            <Typography style={{ fontSize: "0.85rem" }}>
                                                {"Note: * indicates required Document Type"}
                                            </Typography>
                                        </div>
                                        <div className={classes.tablePaginationDiv}>
                                            <TablePagination
                                                rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                                                component="div"
                                                count={documentQueryCount}
                                                rowsPerPage={rowsPerPageDocument}
                                                page={pageDocument}
                                                backIconButtonProps={{
                                                    "aria-label": "previous page"
                                                }}
                                                nextIconButtonProps={{
                                                    "aria-label": "next page"
                                                }}
                                                onChangePage={handleChangePageDocument}
                                                onChangeRowsPerPage={handleChangeRowsPerPageDocument}
                                                labelRowsPerPage={"Rows per page"}
                                            />
                                        </div>
                                    </div>
                            }
                        </Grid>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <div style={{ marginLeft: "12px" }}>
                            <h4 style={{ minWidth: '10%', float: "left" }}>
                                Select a Container &nbsp;  &nbsp;  &nbsp;
                            </h4>
                            <FormControl style={{ width: '15%', float: "left" }}>
                                <InputLabel id="demo-mutiple-name-label">Conatiner Number</InputLabel>
                                <Select
                                    labelId="demo-mutiple-name-label"
                                    id="demo-mutiple-name"
                                    fullWidth
                                    value={selectedContainer}
                                    onChange={handleChangeContainer}
                                    input={<Input />}
                                    MenuProps={MenuProps}
                                >
                                    {containers != null && containers.length > 0 ? containers.map(container => (
                                        <MenuItem value={container.containerId}>{container.containerNumber}</MenuItem>

                                    )) : ""}
                                </Select>
                            </FormControl>
                            <h4 className={classes.smallText} style={{ minWidth: '10%', float: "right", background: "#f8f8f8", borderRadius: "1" }}>
                                <SubjectIcon style={{ verticalAlign: "middle" }} /> Events are currently being tracked for 12 different carriers.
                            </h4>
                        </div>

                        {selectedContainer === "" ?
                            "" :
                            <Grid container spacing={5}>
                                <Grid container item xs={xsValueBlock} spacing={3}>
                                    <Paper style={{ maxHeight: '56vh', overflow: 'auto', width: "100%", marginLeft: "12px", boxShadow: "none" }} elevation={2}>
                                        <h4 style={{ marginLeft: '20px', color: '#173a64', fontWeight: 'bold' }}>Events</h4>
                                        <VerticalTimeline layout="1-column">
                                            {blocksList != null && typeof blocksList != "string" && Object.keys(blocksList).length !== 0 ? (
                                                blocksList.map((member) => {
                                                    return (
                                                        <VerticalTimelineElement
                                                            className="vertical-timeline-element--work"
                                                            contentStyle={{ background: '#fff', color: 'black', boxShadow: 'none', cursor: 'pointer' }}
                                                            iconStyle={{ background: '#173a64', color: '#fff', width: '30px', height: '30px', left: '5px' }}
                                                            icon={<AdjustIcon />}
                                                        >
                                                            <section className={classes.cardContent} className={selectedEvent == member.txId ? (classes.cardHeaderBlock) : ("")} onClick={showTransactionsBlock(member.txId, member.id)}>
                                                                <h4 className={classes.noMargin}>{member.description} <span className={classes.smallText}> &nbsp; by <span className={classes.boldText}>{member.organizationName}</span> &nbsp;&nbsp; (<RelativeTime value={member.actualEventTime} titleformat="iso8601" />)</span></h4>
                                                                <div className={classes.smallText}>
                                                                    <span>
                                                                        <AccessTimeIcon className={classes.smallTextIcon} /> {member.actualEventTime}
                                                                    </span>
                                                                    <span>
                                                                        &nbsp; &nbsp; <RoomIcon className={classes.smallTextIcon} /> {member.location ? member.location : 'Not Available'}
                                                                    </span>
                                                                    <span>
                                                                        &nbsp; &nbsp; <NotesIcon className={classes.smallTextIcon} /> {member.source ? member.source : 'N/A'}
                                                                    </span>
                                                                    <span>
                                                                        &nbsp; &nbsp; <LocalShippingIcon className={classes.smallTextIcon} /> {member.details.transportType == 'vessel' ? member.details.transportName + " " + member.details.transportVoyage : 'Truck'}
                                                                    </span>
                                                                </div>
                                                                <div className={classes.smallText}>
                                                                    <span>
                                                                        <VerifiedUserIcon className={classes.smallTextIcon} style={{ "color": "green" }} />{member.txId}
                                                                    </span>
                                                                </div>
                                                            </section>
                                                        </VerticalTimelineElement>
                                                    );
                                                })
                                            ) : (events.length == 0 && backdrop ?
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{ background: '#fff', color: 'black', boxShadow: 'none', cursor: 'pointer' }}
                                                    iconStyle={{ background: '#173a64', color: '#fff', width: '30px', height: '30px', left: '5px' }}
                                                    icon={<AdjustIcon />}
                                                >

                                                    <section className={classes.cardContent}>
                                                        <Skeleton height={10} />
                                                        <Skeleton height={50} />
                                                    </section>
                                                </VerticalTimelineElement>
                                                :
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{ background: '#fff', color: 'black', boxShadow: 'none', cursor: 'pointer' }}
                                                    iconStyle={{ background: '#173a64', color: '#fff', width: '30px', height: '30px', left: '5px' }}
                                                    icon={<ErrorIcon />}
                                                >
                                                    <section className={classes.cardContent}>
                                                        <h4 className={classes.noMargin}> No Events Available</h4>
                                                    </section>
                                                </VerticalTimelineElement>

                                                )}
                                        </VerticalTimeline>
                                    </Paper>
                                </Grid>
                                {showTransactionsInfoBlock === true ? (
                                    <EventTransactionInfo
                                        closeTransactionsPane={closeTransactionsPaneBlock}
                                        txId={selectedEvent}
                                        xsValue={xsValueBlock}
                                        channel={props.location.state.shipmentValue[0].channelId}
                                        eventId={eventId}
                                        shipmentId={props.match.params.shipmentID}
                                    />
                                ) : ("")}
                            </Grid>
                        }
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <Grid item xs={12}>
                            <ShipmentContainers containers={containers} ></ShipmentContainers>
                        </Grid>
                    </TabPanel>
                    <Dialog
                        open={popupOpen}
                        onClose={handlePopupClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                        maxWidth={'md'}
                    >
                        <DialogTitle id="alert-dialog-title">
                            <h5 className={classes.hfivestyle}>
                                Name        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{uploadValues.DocumentName}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Description        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{uploadValues.DocumentDescription}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Version        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{version}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                &nbsp;Timestamp        :
                                <h6 className={classes.hsixstyle}>
                                    {timestamp}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Last updated by        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{lastUpdatedBy}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Document Hash        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{documentHash}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Print       :
                                    <ReactToPrint
                                    trigger={() => <PrintIcon className={classes.printIcon}>Print this out!</PrintIcon>}
                                    content={() => componentRef.current}
                                />
                                <div ref={componentRef} className="print-source">
                                    <p>
                                        <FormRenderer docName={uploadValues.DocumentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={showDocumentJSON} disable={true} />
                                    </p>
                                </div>
                            </h5>
                            {tampered != null ? (!tampered ? (
                                <span className={classes.smallTextIcon}>
                                    <VerifiedUserIcon style={{ "color": "green" }} className={classes.smallTextIcon} />Data’s authenticity is verified against blockchain ledger
                                </span>) : (<span className={classes.smallTextIcon}>
                                    <CancelIcon style={{ "color": "red" }} className={classes.smallTextIcon} />Data looks like tampered. Hashes don't match from blockchain
                                </span>))
                                : ("")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div style={{
                                    background: "#f8f8f8", height: "140%", overflow: "auto"
                                }}>
                                    <div className={classes.flexItem}>
                                        <p>Document</p>
                                        <div style={{ maxWidth: "1400px", maxHeight: "100%", border: "1px solid", borderColor: "#D4D4D4", marginRight: " 30px" }}>
                                            <FormRenderer docName={uploadValues.DocumentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={showDocumentJSON} disable={true} />

                                        </div>
                                    </div>
                                </div >
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handlePopupClose}
                                color="primary"
                                autoFocus
                            >
                                CLOSE
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Paper>
        </div>
    );
}
