import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const theme = createMuiTheme({
  primaryColor: "#c8c8d8",
  secondaryColor: "#0000",
  primaryBordercolor: "#00000024",
  typography: {
    useNextVariants: true,
    fontFamily: "auto",
    color: "#006db3"
  },
  palette: {
    primary: {
      light: "#f50057",
      main: "#f50057"
    },
    menuhover: {
      main: "#e872a5"
    }
  }
});
let defaultTheme = responsiveFontSizes(theme);
export default defaultTheme;
