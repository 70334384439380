import React, { useState } from "react";

import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { useStyles } from "./styles/MyOrganizationInformationStyles";
import UserManagementService from '../../services/UserManagementService';
import notify from "../../utils/notifier";

import Switch from '@material-ui/core/Switch';


export default function MySettings() {
    const classes = useStyles();
    const [emailDocUpload, setEmailDocUpload] = React.useState('');
    const [emailOrgJoinsChannel, setEmailOrgJoinsChannel] = React.useState('');
    const [emailOtherOrgJoinsChannel, setEmailOtherOrgJoinsChannel] = React.useState('');
    const [emailOrgJoinsNetwork, setEmailOrgJoinsNetwork] = React.useState('');
    const [emailPrivateDocumentShared, setEmailPrivateDocumentShared] = React.useState('');
    const [emailOtherOrgJoinsNetwork, setEmailOtherOrgJoinsNetwork] = React.useState('');
    const [datafetched, setDataFetched] = React.useState('');
    const [backdrop, setBackdrop] = React.useState(false);
    const handleChangeEmailSetting = (value, flag) => (event) => {
        // setEmailSetting(flag);
        // setBackdrop(true);
        if (value === 'EMAIL_OTHER_ORG_JOINS_NETWORK')
            setEmailOtherOrgJoinsNetwork(flag);
        else if (value === 'EMAIL_ORG_JOINS_NETWORK')
            setEmailOrgJoinsNetwork(flag);
        else if (value === 'EMAIL_ORG_JOINS_CHANNEL')
            setEmailOrgJoinsChannel(flag);
        else if (value === 'EMAIL_OTHER_ORG_JOINS_CHANNEL')
            setEmailOtherOrgJoinsChannel(flag);
        else if (value === 'EMAIL_DOCUMENT_UPLOAD')
            setEmailDocUpload(flag);
        else if (value === 'EMAIL_PRIVATE_DOCUMENT_SHARED')
            setEmailPrivateDocumentShared(flag);
        UserManagementService.editEmailFlag(value, flag).then(
            response => {

            },
            error => {
                fetchData();
                notify({
                    type: "error",
                    message: "Error while updating email setting"
                });
            }
        );
    };

    const fetchData = () => {
        setBackdrop(true);
        UserManagementService.getUserDetailsByUserId(localStorage.getItem("_userId")).then(
            response => {
                setBackdrop(false);
                response.data.map(x => {
                    if (x.setting_code === 'EMAIL_ORG_JOINS_NETWORK')
                        setEmailOrgJoinsNetwork(x.setting_value);
                    else if (x.setting_code === 'EMAIL_OTHER_ORG_JOINS_NETWORK')
                        setEmailOtherOrgJoinsNetwork(x.setting_value);
                    else if (x.setting_code === 'EMAIL_ORG_JOINS_CHANNEL')
                        setEmailOrgJoinsChannel(x.setting_value);
                    else if (x.setting_code === 'EMAIL_OTHER_ORG_JOINS_CHANNEL')
                        setEmailOtherOrgJoinsChannel(x.setting_value);
                    else if (x.setting_code === 'EMAIL_DOCUMENT_UPLOAD')
                        setEmailDocUpload(x.setting_value);
                    else if (x.setting_code === 'EMAIL_PRIVATE_DOCUMENT_SHARED')
                        setEmailPrivateDocumentShared(x.setting_value);
                })

                setDataFetched(true);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching organization data"
                });
            }
        );
    }

    React.useEffect(() => {
        fetchData();
    }, []);
    return (
        backdrop ? <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }} >
            <CircularProgress color="inherit" />
        </Backdrop> :
            <Grid item xs={12} >
                {datafetched !== '' ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold', width: '45vw', marginTop: '2px' }}>Enable email notifications when your organization join a network</div>
                            <Switch
                                checked={emailOrgJoinsNetwork}
                                onChange={handleChangeEmailSetting('EMAIL_ORG_JOINS_NETWORK', !emailOrgJoinsNetwork)}
                                color="primary"
                                name="checkedB"
                            />
                            {emailOrgJoinsNetwork ? <div style={{ marginTop: '2px', paddingTop: '2px' }}>on</div> : <div style={{ marginTop: '3px', paddingTop: '2px' }}>off</div>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold', width: '45vw', marginTop: '2px' }}> Enable email notifications when other organizations join your networks</div>
                            <Switch
                                checked={emailOtherOrgJoinsNetwork}
                                onChange={handleChangeEmailSetting('EMAIL_OTHER_ORG_JOINS_NETWORK', !emailOtherOrgJoinsNetwork)}
                                color="primary"
                                name="checkedB"
                            />
                            {emailOtherOrgJoinsNetwork ? <div style={{ marginTop: '2px', paddingTop: '2px' }}>on</div> : <div style={{ marginTop: '3px', paddingTop: '2px' }}>off</div>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold', width: '45vw', marginTop: '2px' }}> Enable email notifications when your organization join a channel</div>
                            <Switch
                                checked={emailOrgJoinsChannel}
                                onChange={handleChangeEmailSetting('EMAIL_ORG_JOINS_CHANNEL', !emailOrgJoinsChannel)}
                                color="primary"
                                name="checkedB"
                            />
                            {emailOrgJoinsChannel ? <div style={{ marginTop: '2px', paddingTop: '2px' }}>on</div> : <div style={{ marginTop: '3px', paddingTop: '2px' }}>off</div>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold', width: '45vw', marginTop: '2px' }}> Enable email notifications when other organizations join your channels</div>
                            <Switch
                                checked={emailOtherOrgJoinsChannel}
                                onChange={handleChangeEmailSetting('EMAIL_OTHER_ORG_JOINS_CHANNEL', !emailOtherOrgJoinsChannel)}
                                color="primary"
                                name="checkedB"
                            />
                            {emailOtherOrgJoinsChannel ? <div style={{ marginTop: '2px', paddingTop: '2px' }}>on</div> : <div style={{ marginTop: '3px', paddingTop: '2px' }}>off</div>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold', width: '45vw', marginTop: '2px' }}>Enable email notifications when a document is uploaded into your organization channel</div>
                            <Switch
                                checked={emailDocUpload}
                                onChange={handleChangeEmailSetting('EMAIL_DOCUMENT_UPLOAD', !emailDocUpload)}
                                color="primary"
                                name="checkedB"
                            />
                            {emailDocUpload ? <div style={{ marginTop: '2px', paddingTop: '2px' }}>on</div> : <div style={{ marginTop: '3px', paddingTop: '2px' }}>off</div>}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ fontWeight: 'bold', width: '45vw', marginTop: '2px' }}>Enable email notifications when other organizations share private documents with your organizations</div>
                            <Switch
                                checked={emailPrivateDocumentShared}
                                onChange={handleChangeEmailSetting('EMAIL_PRIVATE_DOCUMENT_SHARED', !emailPrivateDocumentShared)}
                                color="primary"
                                name="checkedB"
                            />
                            {emailPrivateDocumentShared ? <div style={{ marginTop: '2px', paddingTop: '2px' }}>on</div> : <div style={{ marginTop: '3px', paddingTop: '2px' }}>off</div>}
                        </div>
                    </div> : ""
                }
            </Grid >
    );
}