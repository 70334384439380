import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

const useStyles = makeStyles({
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    input: {
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        flex: 1
    },

    textField: {
        // paddingRight: theme.spacing(4)
    },

    shipmentShareInfo: {
        paddingBottom: theme.spacing(2)
    },
    sharingOptionForm: {
        width: "100%",
        marginBottom: theme.spacing(1)
    },


    tableBorder: {
        marginTop: theme.spacing(10),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        border: "0.8px solid #00000021",
        marginBottom: theme.spacing(15),

    },


    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        paddingRight: theme.spacing(4)
    },

    hiddenField: {
        visibility: "hidden",
        padding: "20px",
        maxHeight: theme.spacing(3)
    },
    fileUploadWrapper: {
        background: "#f8f8f8",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
    },
    fileCriteriaLabel: {
        display: "flex",
        justifyContent: "space-between"
    },
    tableTitleDiv: {
        display: "flex",
        paddingLeft: "15px",
        flexDirection: 'row',
    },
    tableTitle: {
        display: "flex",
    },
    backIcon: {
        marginTop: "20px",
        marginRight: theme.spacing(1),
    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        justifyContent: "flex-end",
        width: "100%"
    },
    root: {
        width: "100%",
        background: "white"
    },

    paper_root: {
        // alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '5px',

    },
    box: {
        background: "#f8f8f8",
        padding: "20px 20px 60px 10px",
        marginLeft: '15px',
        marginRight: '15px',
        wordBreak: "break-all",
    },
    submitButtonDiv: {
        minHeight: "5vh",
        paddingLeft: "1rem",
        minWidth: '7vw'
    },
    submitFilterButton: {
        // paddingRight: theme.spacing(2.5),
        marginRight: "1rem",
        // marginTop: theme.spacing(4),
        // marginTop: "1rem",
        float: "right",
        // marginLeft: '20px'
    },
    formControl: {
        paddingRight: "0.5rem",
        minWidth: 180,
        maxWidth: 180,
        // paddingTop: theme.spacing(1),
        marginTop: theme.spacing(3),
        paddingBottom: '0px',
    },
    detailsDiv: {
        minWidth: '55vw',
        maxWidth: '55vw',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1),
    },
    detailsDiv1: {
        minWidth: '35vw',
        maxWidth: '35vw',
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1),
    },
    smallTextIcon: {

    },
    detailsDivSpan: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    detailsLabel: {
        fontWeight: 'bold',
        paddingTop: theme.spacing(1),
        paddingRight: '0.5px',
        fontSize: '0.95rem',
    },
    detailsValue: {
        paddingTop: theme.spacing(1),
        fontSize: '0.95rem',
    },
    detailsLogs: {
        fontWeight: 'bold',
        padding: theme.spacing(3),
        paddingTop: '0px'
    },
    inputBox: {


        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        width: theme.spacing(80),
        marginRight: theme.spacing(3),
        height: theme.spacing(4.5),
        borderColor: 'black',
    },
    errorText: {
        padding: theme.spacing(3),
        color: 'red',
        fontWeight: 'bold',
        paddingBottom: '2px',
    },
    labelRoot: {
        fontSize: 15,
        fontWeight: "bold",
        color: "black",
        "&$labelFocused": {
            color: "black",
            fontWeight: "bold",
            fontSize: 16,
        }
    },
    labelFocused: {}
});
export default useStyles;