import React, { useState, useRef } from "react";
import documentService from "../../services/DocumentService";
import Backdrop from '@material-ui/core/Backdrop';
import useStyles from './styles/DocumentStyles';
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Select from '@material-ui/core/Select';
import CloseIcon from "@material-ui/icons/Close";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import Drawer from '@material-ui/core/Drawer';
import GetAppIcon from '@material-ui/icons/GetApp';

import TablePagination from "@material-ui/core/TablePagination";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import "../../css/style.css";
import MenuItem from '@material-ui/core/MenuItem';
import Util from "../../utils/Util";
import { APPLICATION_ID } from "../../utils/constants";
import notify from "../../utils/notifier";
import Transactions from "../shipments/TransactionsShipmentRevision";
import FormRenderer from 'form-render-module'
import shipmentService from "../../services/ShipmentService";
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import { green, orange, red } from '@material-ui/core/colors';
import FlagIcon from '@material-ui/icons/Flag';
import Checkbox from '@material-ui/core/Checkbox';
import EditIcon from '@material-ui/icons/Edit';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import UploadDocuments from '../UploadDocumentsV2';
import UpdateDocument from '../UpdateDocumentV2';
import SharePrivateDocument from '../SharePrivateDocument';
import AttachDocumentToBookings from '../AttachDocumentToBookings';
import Highlight from 'react-highlighter';
import LockIcon from '@material-ui/icons/Lock';
import channelService from "../../services/ChannelService";

import organizationService from "../../services/OrganizationService";
import FilePreview from '../shipments/filePreview'

import {
    _columns,
    _columnsTreeView,
    MenuProps,
    userPrefs,
} from './DocumentsUtils'
import bookingService from "../../services/BookingService";

const highlightableColumns = ["name", "channelName", "shipmentNumber", "shipmentReference"]

export default function DocumentV2(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [transactionComponentLoaded, setTransactionComponentLoaded] = React.useState(false);
    const [searchKey, setSearchKey] = useState({ value: "" });
    const [columns, setColumns] = React.useState(_columns);
    const [columnsTreeView, setColumnsTreeView] = React.useState(_columnsTreeView);
    const [organizationID, setOrganisationID] = React.useState(localStorage.getItem('_orgID'));

    const [rows, setRows] = React.useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [orderBy, setOrderBy] = React.useState("updatedAt");
    const [order, setOrder] = React.useState("desc");
    const [queryCount, setQueryCount] = useState(0);
    const [originalQueryCount, setOriginalQueryCount] = useState(0);

    const [popupOpen, setpopupOpen] = React.useState(false);
    const [version, setVersion] = React.useState(1);
    const [timestamp, setTimestamp] = React.useState("");
    const [lastUpdatedBy, setLastUpdatedBy] = React.useState("");
    const [documentHash, setDocumentHash] = React.useState("");
    const [showDocumentJSON, setShowDocumentJSON] = React.useState({});
    const [tampered, setTampered] = React.useState(null);
    const [newDocument, setNewDocument] = React.useState(0);
    const [update, setUpdate] = React.useState(false);
    const [tempDocumentJSON, setTempDocumentJSON] = React.useState({});
    const [ShipmentID, setShipmentID] = React.useState();
    const [DocumentID, setDocumentID] = React.useState();
    const [channelId, setChannelId] = React.useState();
    const [channelName, setChannelName] = React.useState();
    const [rowFileType, setRowFileType] = React.useState('');
    const [documentTypeFortransaction, setDocumentTypeFortransaction] = React.useState("");
    const [documentCodeFortransaction, setDocumentCodeFortransaction] = React.useState("");
    const [documentRow, setDocumentRow] = React.useState(null);
    const [radioButtonView, setRadioButtonView] = React.useState('ListView');
    const [documentName, setDocumentName] = React.useState("");
    const [documentDescription, setDocumentDescription] = React.useState("");
    const [fileName, setFileName] = React.useState("");
    const [documentType, setDocumentType] = React.useState("");
    const [documentVersion, setDocumentVersion] = React.useState("");
    const [treeViewData, setTreeViewData] = React.useState({});
    const [openPanels, setOpenPanels] = React.useState({});
    const [subAssetTypeId, setSubAssetTypeId] = React.useState("");
    const [uploadedDocTypeByBooking, setUploadedDocTypeByBooking] = React.useState({});
    const [bookingReqDocumentType, setBookingReqDocumentType] = React.useState({});
    const [filter, setFilter] = React.useState(true);
    const [selectedChannels, setSelectedChannels] = React.useState([]);
    const [channelsArray, setChannelsArray] = React.useState([]);
    const [uploadMissingDocType, setUploadMissingDocType] = React.useState(false);
    const [channelMembers, setChannelMembers] = React.useState([]);
    const [privateMemberList, setPrivateMemberList] = React.useState([]);
    const [bookingViewMetadata, setBookingViewMetadata] = React.useState({});
    const [documentTypesInfo, setDocumentTypesInfo] = React.useState([]);
    const [documentTypesIdToName, setDocumentTypesIdToName] = React.useState({});

    //ALL: show all docs, true: show only private doc, false: show only public docs
    const [selectedDocumentPrivacyValue, setSelectedDocumentPrivacyValue] = React.useState("ALL");

    const [documentTypeFilterValues, setDocumentTypeFilterValues] = React.useState([]);

    const [values, setValues] = React.useState({
        DocumentID: "",
        DocumentType: "",
        DocumentCode: "",
        DocumentName: "",
        DocumentDescription: "",
        DocumentJSON: "",
    });

    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const setInitialState = () => {
        values.DocumentID = ""
        values.DocumentType = ""
        values.DocumentCode = ""
        values.DocumentName = ""
        values.DocumentDescription = ""
        values.DocumentJSON = ""
        setTempDocumentJSON({})
        setDocumentID();
        setShipmentID();
        setRowFileType("")
        setUpdate(false)
        setDocumentDescription("")
        setDocumentName("");
        setFileName("");
        setDocumentType("");
        setChannelId();
        setChannelName();
        values.eventType = ""
        values.eventType = "";
        values.location = "";
        values.eventJSON = {};
        values.containerId = null;
        values.containerNumber = "";
        values.descriptionFromSource = "";
        values.source = "";
        setDocumentVersion("");
        setSubAssetTypeId("");
        setChannelMembers([]);
        setPrivateMemberList([]);
        setSearchKey({ value: "" });
        setSelectedDocumentPrivacyValue("ALL");
        setSelectedChannels([]);
        setTransactionComponentLoaded(false);
    }

    const sideDrawerMap = {
        "RowClickDocument": 0,
        "UploadDocument": 1
    }


    const tableHeadWidth = (100 / _columns.length) + "%";


    const fetchData = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = null, filterArray = null, isPrivate = null, docTypeFilter = null) => {

        let query = {
            '_page': currentPage + 1,
            '_limit': pageSize,
            '_sort': sortBy,
            '_order': sortOrder
        }

        if (searchText) {
            query['q'] = searchText;
        }

        if (isPrivate != null && isPrivate != 'ALL') {
            query["_isPrivate"] = isPrivate;
        }

        if (filterArray && filterArray.length > 0) {
            query["_channelName"] = filterArray
        }

        if (docTypeFilter && docTypeFilter.length > 0) {
            query["_subAssetTypeId"] = docTypeFilter
        }

        setBackdrop(true);
        documentService.getDocumentsByOrgId(organizationID, query).then(
            response => {
                let _data = response.data;
                setRows(_data);
                if (response.headers.hasOwnProperty('x-total-count')) {
                    setQueryCount(parseInt(response.headers['x-total-count']));
                }

                if (!searchText) {
                    setOriginalRows(_data);
                    setOriginalQueryCount(parseInt(response.headers['x-total-count']));
                }

                setBackdrop(false);

            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching documents"
                });
            }
        )

        documentService.getChannelFilterValuesByOrgId(organizationID).then(
            response => {
                let _channelData = response.data;
                let channelsArray = []
                for (let j = 0; j < _channelData.length; j++) {
                    if (_channelData[j]["channel_name"] && _channelData[j]["channel_name"].length) {
                        channelsArray.push(_channelData[j]["channel_name"])
                    }
                }
                setChannelsArray(channelsArray);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching Channel filter values"
                });
            }
        )
    }


    const fetchDataBookingView = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = 'asc', searchText = null, filterArray = null, isPrivate = null) => {

        let query = {
            '_page': currentPage + 1,
            '_limit': pageSize,
            '_sort': sortBy,
            '_order': sortOrder
        }
        if (searchText) {
            query['q'] = searchText;
        }
        if (isPrivate != null && isPrivate != 'ALL') {
            query["_isPrivate"] = isPrivate;
        }
        if (filterArray && filterArray.length > 0) {
            query["_channelName"] = filterArray
        }

        setBackdrop(true);
        documentService.getBookingViewDocumentInfo(organizationID, query).then(
            response => {
                let _data = response.data[0];

                let _bookingViewMetadata = {};
                for (let sinfo in _data) {
                    if (_data[sinfo]['uploadedDocumentsCount'] && parseInt(_data[sinfo]['uploadedDocumentsCount']) > 0) {
                        _bookingViewMetadata[sinfo] = _data[sinfo];
                    }
                }

                setBookingViewMetadata(_bookingViewMetadata);

                if (response.headers.hasOwnProperty('x-total-count')) {
                    setQueryCount(parseInt(response.headers['x-total-count']));
                }

                if (!searchText) {
                    setOriginalRows(_bookingViewMetadata);
                    setOriginalQueryCount(parseInt(response.headers['x-total-count']));
                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching BookingView Documents"
                });
            }
        );

        documentService.getChannelFilterValuesByOrgId(organizationID).then(
            response => {
                let _channelData = response.data;
                let channelsArray = []
                for (let j = 0; j < _channelData.length; j++) {
                    if (_channelData[j]["channel_name"] && _channelData[j]["channel_name"].length) {
                        channelsArray.push(_channelData[j]["channel_name"])
                    }
                }
                setChannelsArray(channelsArray);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching Channel filter values"
                });
            }
        )

    }

    React.useEffect(() => {
        if (radioButtonView === 'TreeView') {
            fetchDataBookingView();
        } else {
            fetchData();
        }
        //For documentType filter
        bookingService.getDocumentTypesByDestination().then(
            response => {
                const _data = response.data;
                let idToNameMapping = {};
                for (let docType of _data) {
                    idToNameMapping[docType.id] = docType.name
                }
                setDocumentTypesIdToName(idToNameMapping);
                setDocumentTypesInfo(_data);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching Document types"
                });
            }
        );

    }, []);


    let timeout = null;
    const handleSearch = event => {
        const _val = event.target.value;
        setSearchKey(prevState => {
            return { ...prevState, value: _val };
        });
        if (_val.length <= 2) {
            setRows(originalRows);
            setQueryCount(originalQueryCount);
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (radioButtonView === "TreeView") {
                fetchDataBookingView(rowsPerPage, 0, orderBy, order, _val, selectedChannels, selectedDocumentPrivacyValue);
            } else {
                fetchData(rowsPerPage, 0, orderBy, order, _val, selectedChannels, selectedDocumentPrivacyValue);
            }
            
        }, 200);
    };

    const clearSearchText = () => {
        setSearchKey({ value: "" });
        if (radioButtonView === "TreeView") {
            fetchDataBookingView(rowsPerPage, 0, orderBy, order, "", selectedChannels, selectedDocumentPrivacyValue);
        } else {
            fetchData(rowsPerPage, 0, orderBy, order, "", selectedChannels, selectedDocumentPrivacyValue);
        }
    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setInitialState();
    };

    const handleRequestSortTable = property => event => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
        if (radioButtonView === "TreeView") {
            fetchDataBookingView(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value, selectedChannels, selectedDocumentPrivacyValue);
        } else {
            fetchData(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value, selectedChannels, selectedDocumentPrivacyValue);
        }
    };

    const buttonClickClearAllFilters = () => {
        let doRefresh = false
        if (selectedChannels.length > 0 || selectedDocumentPrivacyValue != "ALL" || documentTypeFilterValues.length > 0) {
            doRefresh = true
        }
        setSelectedChannels([]);
        setSelectedDocumentPrivacyValue("ALL");
        setDocumentTypeFilterValues([]);

        if (doRefresh) {
            if (radioButtonView === "TreeView") {
                fetchDataBookingView(rowsPerPage, 0, orderBy, order, searchKey.value);
            } else {
                fetchData(rowsPerPage, 0, orderBy, order, searchKey.value);
            }
            
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (radioButtonView === "TreeView") {
            fetchDataBookingView(rowsPerPage, newPage, orderBy, order, searchKey.value, selectedChannels, selectedDocumentPrivacyValue);
        } else {
            fetchData(rowsPerPage, newPage, orderBy, order, searchKey.value, selectedChannels, selectedDocumentPrivacyValue);
        }
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        if (radioButtonView === "TreeView") {
            fetchDataBookingView(+event.target.value, 0, orderBy, order, searchKey.value, selectedChannels, selectedDocumentPrivacyValue);
        } else {
            fetchData(+event.target.value, 0, orderBy, order, searchKey.value, selectedChannels, selectedDocumentPrivacyValue);
        }
    };

    const handleClickPreviewDocument = (event, row) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true)

        documentService.downloadDocument(row.id, row.version).then(
            response => {
                setBackdrop(false)

                var type = response.headers["content-type"];
                if (type === 'application/octet-stream') {
                    type = 'application/pdf';
                }
                
                const blob = new Blob([response.data], {
                    type: type,
                    encoding: "UTF-8"
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                window.open(link.href)
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }


            }
        );
    };

    const handleClickDownloadDocument = (event, row) => {
        setBackdrop(true);
        documentService.downloadDocument(row.id, row.version).then(
            response => {
                setBackdrop(false)
                Util.downloadTemplate(response);
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }
            }
        );
    };

    const handlePopupClose = () => {
        setVersion(0)
        setTimestamp("")
        setBackdrop(false)
        setShowDocumentJSON({})
        setpopupOpen(false);
        setInitialState();
    };

    const obj = (arg) => {
        var json = Object.assign({}, arg)
        var temp = Object.assign(tempDocumentJSON, json)
        setTempDocumentJSON(temp);
        if (Object.keys(json).length === 0) {

        } else {
            setValues({ ...values, ["DocumentJSON"]: temp });
        }
    }

    const buttonClickUploadNewDocument = (side, open, row) => event => {
        setUploadMissingDocType(false);
        setNewDocument(sideDrawerMap["UploadDocument"]);
        setDrawerState({ ...drawerState, ["right"]: true });
    }

    const handleClickRowDocument = (event, row, _update = false) => {
        values.DocumentID = row.id
        values.DocumentType = row.subAssetTypeName;
        values.DocumentCode = row.code;
        values.DocumentName = row.name
        values.DocumentDescription = row.description;
        setDocumentType(row.subAssetTypeName);
        setDocumentName(row.name);
        setFileName(row.originalFileName);
        setDocumentDescription(row.description);
        setDocumentRow(row);
        setDocumentTypeFortransaction(row.subAssetTypeName);
        setDocumentCodeFortransaction(row.code);
        setSubAssetTypeId(row.subAssetTypeId);
        setNewDocument(sideDrawerMap["RowClickDocument"]);
        setDocumentID(row.id);
        setChannelId(row.channelId);
        setDocumentVersion(row.version);
        setChannelMembers([]);
        setPrivateMemberList([]);
        setTransactionComponentLoaded(false);

        if (row.fileType === "JSON") {
            setRowFileType("JSON")
        } else if (row.fileType === "File") {
            setRowFileType("File");
        }

        if (_update) {
            setDrawerState({ ...drawerState, ["right"]: true });
            setUpdate(true);
        }

        if (row.isPrivate == true) {

            channelService.getOrgNameMspIdByChannelId(row.channelId).then(
                response => {
                    const _data = response.data;
                    setChannelMembers(_data);
                },
                error => {
                    notify({
                        type: "error",
                        message: "Error while fetching channel members list"
                    });
                }
            );

            documentService.getOrganizationsListForPrivateDoc(row.id).then(
                response => {
                    let data = response.data;
                    data = data.map(member => {
                        member['id'] = member['organizationId'];
                        return member;
                    })
                    setPrivateMemberList(data);
                },
                error => {
                    notify({
                        type: "error",
                        message: `Error while getting private member list for document : ${row.name}`
                    });
                }
            );
        }
    };

    const handleClickPopupOpen = (event, row) => {
        setBackdrop(true)
        values.DocumentID = row.id
        values.DocumentType = row.subAssetTypeName;
        values.DocumentCode = row.code;
        values.DocumentName = row.name
        values.DocumentDescription = row.description

        documentService.downloadDocumentMetadata(row.id, row.version).then(
            response => {
                setBackdrop(false)
                const _data = response.data;
                let jsonData = typeof (_data.data.metadata) === "string" ? JSON.parse(_data.data.metadata) : _data.data.metadata;
                setVersion(_data["version"])
                values.DocumentJSON = jsonData;
                setShowDocumentJSON(jsonData);
                setTampered(_data["tampered"])
                setTimestamp(_data["created_at"])
                setLastUpdatedBy(`${_data["first_name"]}${_data["last_name"] !== "null" && _data["last_name"] ? " " + _data["last_name"] : ""}, ${_data["organization_name"]}`)
                setDocumentHash(_data["documentHash"])
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching  Document"
                });
            }
        );
        setpopupOpen(true);
    };

    const componentRef = useRef();

    function refreshDocumentTable() {
        setInitialState();
        setDrawerState({ ...drawerState, ["right"]: false });
        if (radioButtonView === "TreeView") {
            fetchDataBookingView();
        } else {
            fetchData();
        }
    }

    const handleChangeRadioButtonView = event => {
        setRadioButtonView(event.target.value);

        setSearchKey({ value: "" });
        setPage(0);
        setOrder("asc");

        if (event.target.value === "TreeView") {
            setOrderBy('id');
            fetchDataBookingView(rowsPerPage, 0, 'id', "asc", "", selectedChannels, selectedDocumentPrivacyValue);
        } else {
            setOrderBy('updatedAt');
            fetchData(rowsPerPage, 0, 'updatedAt', "asc", "", selectedChannels, selectedDocumentPrivacyValue);
        }
        // setInitialState();
    }


    const handleClick = _bookingNumber => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        let query = {};

        if (selectedDocumentPrivacyValue != 'ALL') {
            query["_isPrivate"] = selectedDocumentPrivacyValue;
        }

        if (!openPanels[_bookingNumber]) {
            setBackdrop(true);

            documentService.getDocumentsByBookingNumber(_bookingNumber, query).then(
                response => {
                    let _data = response.data;
                    let _treeViewData = treeViewData;
                    _treeViewData[_bookingNumber] = [];
                    let _uploadedDocTypeByBooking = uploadedDocTypeByBooking;
                    _uploadedDocTypeByBooking[_bookingNumber] = [];

                    for (let i = 0; i < _data.length; i++) {
                        _data[i]['documentNo'] = i + 1
                        _data[i]["Link"] = "Download Document";
                        _treeViewData[_bookingNumber].push(_data[i]);
                        if (!_uploadedDocTypeByBooking[_bookingNumber].includes(_data[i]['subAssetTypeName'])) {
                            _uploadedDocTypeByBooking[_bookingNumber].push(_data[i]['subAssetTypeName']);
                        }
                    }

                    setTreeViewData(_treeViewData);
                    setUploadedDocTypeByBooking(_uploadedDocTypeByBooking);
                    setOpenPanels({ ...openPanels, [_bookingNumber]: !openPanels[_bookingNumber] });
                    setBackdrop(false);
                },
                error => {
                    setBackdrop(false);
                    notify({
                        type: "error",
                        message: "Error while fetching documents"
                    });
                }
            )

            if (!bookingReqDocumentType.hasOwnProperty(_bookingNumber)) {
                bookingService.getDocumentTypesByDestinationForBookings([_bookingNumber]).then(
                    response => {
                        const res = response.data;
                        let _bookingReqDocumentType = bookingReqDocumentType;
                        _bookingReqDocumentType[_bookingNumber] = res[_bookingNumber];
                        setBookingReqDocumentType(_bookingReqDocumentType);
                    },
                    error => {
                        notify({
                            type: "error",
                            message: "Error while fetching required document types"
                        });
                    }
                );
            }

        } else {
            setOpenPanels({ ...openPanels, [_bookingNumber]: !openPanels[_bookingNumber] });
        }
        
    };

    const handleClickUploadMissingDocument = (_shipmentNumber, _channelId, _channelName, _docType) => event => {
        setChannelId(_channelId);
        setChannelName(_channelName);
        setUploadMissingDocType(true);
        setShipmentID(bookingViewMetadata[_shipmentNumber]['shipmentId']);

        values.DocumentType = _docType;

        setNewDocument(sideDrawerMap["UploadDocument"]);
        setDrawerState({ ...drawerState, ["right"]: true });
    }

    const newDocumentSideList = side => (
        // <UploadDocuments shipmentId={ShipmentID} uploadMissingDocType={uploadMissingDocType}
        //     uploadMissingDocTypeName={uploadMissingDocType ? values.DocumentType : null} refreshDocumentTable={refreshDocumentTable} fromPage={"documents"} closeDrawer={closeDrawer}
        //     channelId={channelId} channelName={channelName}
        // />
        <UploadDocuments refreshDocumentTable={refreshDocumentTable} fromPage={"documents"} closeDrawer={closeDrawer}
            channelId={channelId} channelName={channelName}
        />
    );

    const modifyDocumentSideList = side => (
        <UpdateDocument subAssetTypeId={subAssetTypeId} rowFileType={rowFileType} documentID={DocumentID} documentType={documentType} fileName={fileName}
            documentCode={values.DocumentCode} documentName={documentName} documentDescription={documentDescription} refreshDocumentTable={refreshDocumentTable}
            channelId={channelId} version={documentVersion} closeDrawer={closeDrawer} isPrivate={documentRow ? documentRow.isPrivate : false} fromPage={"documents"}
        />
    );

    const createNoDocumentTable = () => {
        let arr = []
        arr.push(
            <TableRow style={{ height: "60px" }}>
                <TableCell colSpan="8" align="center" width={tableHeadWidth} className={classes.noRecordsRow}>
                    {backdrop ? " Fetching the documents, please wait" : "No documents are found."}
                </TableCell>
            </TableRow>
        )
        return arr;
    }

    const createDocumentTableInner = (row, documentNo, treeView = false, _bookingNumber) => {
        let table = [];
        let tableColumns = columns;

        let fileExtensionPreviewAllowed = ['pdf', 'png', 'jpg', 'html' , 'jpeg'];

        if (treeView) {
            tableColumns = columnsTreeView;
        }

        for (let i = 0; i < tableColumns.length; i++) {
            var column = tableColumns[i]
            const value = row[column.id];
            let originalFileName = row['originalFileName'];
            const fileExt = originalFileName ? originalFileName.split('.').slice(-1).pop() : "";

            if (column.id === "version") {
                table.push(
                    <TableCell key={column.id} align={column.align} width={tableHeadWidth} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        v{column.format && typeof value === "number" ? (column.format(value)) : (value)}
                    </TableCell>
                )
            } else if (column.id === "Link") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align}>
                        <div className={classes.actionDiv}>
                            <Tooltip title={row.fileType === "JSON" ? "Preview" : fileExtensionPreviewAllowed.includes(fileExt) ? "Preview" : "Preview is not supported for this file type" }>
                                <span>
                                    <Button className={classes.buttonDownload} disabled={row.fileType === "JSON" ? false : (fileExtensionPreviewAllowed.includes(fileExt) ? false : true)} onClick={event => row.fileType === "JSON" ? handleClickPopupOpen(event, row): handleClickPreviewDocument(event, row)}>
                                        <DescriptionIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={row.fileType === "File" ? "Download" : "Download is not supported"}>
                                <span>
                                    <Button className={classes.buttonDownload} disabled={row.fileType === "JSON"} onClick={event => handleClickDownloadDocument(event, row)}>
                                        <CloudDownloadIcon />
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title="Update">
                                <Button className={classes.buttonDownload} onClick={event => handleClickRowDocument(event, row, true)}>
                                    <EditIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    </TableCell>
                )
            }
            else if (column.id === "documentNo") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        {documentNo}
                    </TableCell>
                )
            }
            else if (column.id === "updatedAt") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        {`${value.split('T')[0]} ${value.split('T')[1].split('.')[0]}`}
                    </TableCell>
                )
            }
            else if (column.id === "subAssetTypeName") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        <Highlight search={searchKey.value}>
                            {
                                treeView && bookingReqDocumentType[_bookingNumber] ? (bookingReqDocumentType[_bookingNumber].includes(value) ? value + String.fromCharCode(42) : ((value && value !== 'null') ? value : '-')) : (value && value !== 'null') ? value : '-'
                            }
                        </Highlight>
                    </TableCell>
                )
            }
            else if (column.id === "name") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        <div className={classes.documentNameDiv}>
                            <Highlight search={searchKey.value}>
                                {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                            </Highlight>
                        </div>
                    </TableCell>
                )
            }
            else if (column.id === "originalFileName") {
                table.push(
                    <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                        <div className={classes.documentNameDiv}>
                            <Highlight search={searchKey.value}>
                                {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                            </Highlight>
                            {
                                row.isPrivate === true ?
                                    <div className={classes.privateDocTooltipDiv}>
                                        <Tooltip title={"Private document"}>
                                            <LockIcon style={{ fontSize: "0.775rem" }} color="primary" />
                                        </Tooltip>
                                    </div>
                                    : ""
                            }
                        </div>
                    </TableCell>
                )
            }
            else {
                if (highlightableColumns.includes(column.id)) {
                    table.push(
                        <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                            <Highlight search={searchKey.value}>
                                {column.format && typeof value === "number" ? (column.format(value)) : ((value && value!== 'null') ? value : "-")}
                            </Highlight>
                        </TableCell>
                    )
                } else {
                    table.push(
                        <TableCell key={column.id} width={tableHeadWidth} align={column.align} className={classes.row_pointer} onClick={event => row.id == DocumentID ? setInitialState() : handleClickRowDocument(event, row)}>
                            {column.format && typeof value === "number" ? (column.format(value)) : ((value && value !== 'null') ? value : "-")}
                        </TableCell>
                    )
                }
            }
        }
        return table
    }

    function handleSharePrivateDocumentSuccessfully() {
        setChannelMembers([]);
        setPrivateMemberList([]);
    }

    function handleTransactionComponentLoaded(open) {
        setTransactionComponentLoaded(open);
    }

    const createDocumentTable = (rows, treeView = false, _bookingNumber) => {
        let table = [];
        let documentNo = treeView ? 1 : (1 + page * rowsPerPage);

        rows.map((row, index) => {
            if (row.id == DocumentID && !update) {
                table.push(
                    <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index}  >
                        {createDocumentTableInner(row, documentNo, treeView, _bookingNumber)}
                    </TableRow>
                )
                table.push(
                    <TableRow style={{ height: "60px" }}>
                        <TableCell colSpan="8" align="center" width={tableHeadWidth}>
                            <div className={classes.docRevisionRow}>
                                <AttachDocumentToBookings documentId={row.id} channelId={row.channelId} />
                                {
                                    row.isPrivate === true && privateMemberList.length && channelMembers.length ?
                                        <SharePrivateDocument privateMemberList={privateMemberList} channelMembers={channelMembers}
                                            documentId={row.id} handleSharePrivateDocumentSuccessfully={handleSharePrivateDocumentSuccessfully}
                                        />
                                        : ""
                                }
                                {
                                    transactionComponentLoaded ? "" : <CircularProgress color="inherit" />
                                }
                                <Transactions xsValue={12} shipmentID={ShipmentID} documentID={DocumentID} documentRow={documentRow} 
                                    documentCodeFortransaction={documentCodeFortransaction} documentTypeFortransaction={documentTypeFortransaction} 
                                    subAssetTypeId={subAssetTypeId} channel={channelId} fileType={rowFileType} handleTransactionComponentLoaded={handleTransactionComponentLoaded}/>
                            </div>
                                
                        </TableCell>
                    </TableRow>
                )
            } else {
                table.push(
                    <TableRow style={{ height: "60px" }} hover role="data-row" tabIndex={-1} key={index}  >
                        {createDocumentTableInner(row, documentNo, treeView, _bookingNumber)}
                    </TableRow>
                )
            }
            documentNo += 1;
        });

        if (rows.length && treeView) {
            let requiredDoctype = bookingReqDocumentType[_bookingNumber] ? bookingReqDocumentType[_bookingNumber] : [];
            let uploadedDocType = uploadedDocTypeByBooking[_bookingNumber] ? uploadedDocTypeByBooking[_bookingNumber] : [];
            let remainingDocToUpload = requiredDoctype.filter(i => !uploadedDocType.includes(i));
            for (let docType of remainingDocToUpload) {
                table.push(
                    <TableRow style={{ height: "60px" }}>
                        <TableCell colSpan="8" align="center" width={tableHeadWidth} className={classes.noRecordsRow}>
                            <div className={classes.missingDocTypeDiv}>
                                {"Missing required document: " + docType}
                                <Tooltip title="Upload">
                                    <Button className={classes.buttonDownload} onClick={handleClickUploadMissingDocument(_bookingNumber, rows[0].channelId, rows[0].channelName, docType)}>
                                        <CloudUploadIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                        </TableCell>
                    </TableRow>
                )
            }
        }

        return table;
    }


    function getDocumentUploadedStatusTitle(shipmentNum) {

        let requiredDocumentTypeCount = bookingViewMetadata[shipmentNum]['requiredDocumentTypeCount'];
        let uploadedReqDocumentTypeCount = bookingViewMetadata[shipmentNum]['uploadedReqDocumentTypeCount'];

        let statusColor;
        let statusInString;

        if (requiredDocumentTypeCount) {

            statusColor = red[500];
            let statusInNum = parseInt((uploadedReqDocumentTypeCount / requiredDocumentTypeCount) * 100);
            if (statusInNum >= 50) {
                statusColor = orange[500];
            }
            let missingCount = requiredDocumentTypeCount - uploadedReqDocumentTypeCount;
            statusInString = `${missingCount} required ${missingCount > 1 ? 'document types are' : 'document type is'} missing`;
            if (statusInNum == 100) {
                statusColor = green[500];
                statusInString = `All required documents are uploaded`;
            }

        } else {
            statusInString = "";
            statusColor = "#ffffff";
        }

        return {
            statusInString: statusInString,
            statusColor: statusColor
        }
    }

    const buttonClickFilter = () => event => {
        if (filter) {
            setFilter(false)
            setSelectedChannels([]);
        } else {
            setFilter(true)
        }
    }


    const handleChangeMulitpleSelect = (event) => {
        setSelectedChannels(event.target.value);
        if (radioButtonView === "TreeView") {
            fetchDataBookingView(rowsPerPage, 0, orderBy, "asc", null, event.target.value, selectedDocumentPrivacyValue);
        } else {
            fetchData(rowsPerPage, 0, orderBy, "asc", null, event.target.value, selectedDocumentPrivacyValue);
        }
        
    };

    const handleChangeDocumentPrivacyValue = (event) => {
        setSelectedDocumentPrivacyValue(event.target.value);
        if (radioButtonView === "TreeView") {
            fetchDataBookingView(rowsPerPage, 0, orderBy, "asc", null, selectedChannels, event.target.value);
        } else {
            fetchData(rowsPerPage, 0, orderBy, "asc", null, selectedChannels, event.target.value);
        }
    };

    const handleChangeDocumentTypeFilter = (event) => {
        setDocumentTypeFilterValues(event.target.value);
        fetchData(rowsPerPage, 0, orderBy, "asc", null, null, null, event.target.value);
    };

    return (
        <div className={classes.borderMain}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className={classes.root}>
                <Grid item xs={12} style={{ minHeight: "87vh" }}>
                    <div className={classes.tableTitleDiv} >
                        <div className={classes.tableTitle}>
                            <h2>{"Documents"}</h2>
                        </div>
                        <div className={classes.tableHeadRight}>
                            <div className={classes.searchBox}>
                                <InputBase
                                    onChange={handleSearch}
                                    value={searchKey.value}
                                    className={classes.input}
                                    placeholder={ radioButtonView === "ListView" ? "Search Documents" : "Search Bookings"}
                                />
                                {
                                    searchKey.value.length ? (
                                        <IconButton
                                            size="medium"
                                            aria-label="search"
                                            onClick={clearSearchText}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    ) : (
                                            ""
                                        )
                                }
                                <IconButton
                                    size="medium"
                                    aria-label="search"
                                    disabled
                                    className={classes.searchIcon}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                            <div className={classes.UploadButtonDiv}>
                                <Button
                                    color="primary"
                                    className={classes.UploadButton}
                                    onClick={buttonClickUploadNewDocument("right", true)}
                                    startIcon={<AddCircleIcon />}
                                >
                                    {"Upload documents"}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={classes.viewOptionFilterDiv}>
                        <div className={classes.viewOption}>
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="position" name="position" value={radioButtonView} onChange={handleChangeRadioButtonView} row>
                                    <FormControlLabel
                                        className={classes.viewText}
                                        value="ListView"
                                        control={<Radio color="primary" />}
                                        label="List View"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value="TreeView"
                                        control={<Radio color="primary" />}
                                        label="Booking View"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        <div className={classes.viewOptionFilterRightDiv}>
                            
                            {
                                filter ?
                                    <div className={classes.filterDiv}>
                                        {
                                            radioButtonView === "ListView" ? 
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Document type</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    multiple
                                                    value={documentTypeFilterValues}
                                                    onChange={handleChangeDocumentTypeFilter}
                                                    input={<Input />}
                                                    renderValue={(selected) => {
                                                        let selectedDocTypeNames = [];
                                                        for (let id of selected) {
                                                            selectedDocTypeNames.push(documentTypesIdToName[id]);
                                                        }

                                                        return selectedDocTypeNames.join(', ')
                                                    }}
                                                    MenuProps={MenuProps}
                                                >
                                                    {documentTypesInfo.map((docType) => (
                                                        <MenuItem key={docType.id} value={docType.id}>
                                                            <Checkbox checked={documentTypeFilterValues.indexOf(docType.id) > -1} />
                                                            <ListItemText primary={docType.name} />
                                                        </MenuItem>
                                                    ))}
                                                    
                                                </Select> 
                                            </FormControl> : ""
                                        }
                                        {
                                            radioButtonView === "ListView" ? 
                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-mutiple-checkbox-label">Show private documents</InputLabel>
                                                <Select
                                                    labelId="demo-mutiple-checkbox-label"
                                                    id="demo-mutiple-checkbox"
                                                    value={selectedDocumentPrivacyValue}
                                                    onChange={handleChangeDocumentPrivacyValue}
                                                    input={<Input />}
                                                    MenuProps={MenuProps}
                                                >
                                                    {[
                                                        <MenuItem key={"ALL"} value={"ALL"}>
                                                            {"N/A"}
                                                        </MenuItem>,
                                                        <MenuItem key={"Public"} value={false}>
                                                            {"No"}
                                                        </MenuItem>,
                                                        <MenuItem key={"private"} value={true}>
                                                            {"Yes"}
                                                        </MenuItem>
                                                    ]}
                                                </Select> 
                                            </FormControl>: ""
                                        }
                                        
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-mutiple-checkbox-label">Channels</InputLabel>
                                            <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={selectedChannels}
                                                onChange={handleChangeMulitpleSelect}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {channelsArray.map((name) => (
                                                    <MenuItem key={name} value={name}>
                                                        <Checkbox checked={selectedChannels.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <div className={classes.clearFilterButtonDiv}>
                                            <Button
                                                color="primary"
                                                className={classes.clearFilterButton}
                                                onClick={buttonClickClearAllFilters}
                                            >
                                                {"Clear All"}
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    {
                        radioButtonView === "ListView" ? (
                            <div className={classes.tableWrapper}>
                                <Paper className={classes.tableContainer}>
                                    <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                        <TableHead>
                                            <TableRow>
                                                {columns.map(column => (
                                                    <TableCell key={column.id} className={classes.tableHeadRow} width={tableHeadWidth} align={"center"} sortDirection={orderBy === column.id ? order : false} >
                                                        {column.sortable ? (
                                                            <TableSortLabel className={classes.tableColumnLabel} active={orderBy === column.id} direction={orderBy === column.id ? order : 'asc'} onClick={handleRequestSortTable(column.id)}>
                                                                {column.label}
                                                                {orderBy === column.id ? (
                                                                    <span className={classes.visuallyHidden}>
                                                                        {order === "desc"
                                                                            ? "sorted descending"
                                                                            : "sorted ascending"}
                                                                    </span>
                                                                ) : null}
                                                            </TableSortLabel>
                                                        ) : (column.label)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.length === 0 ? createNoDocumentTable() : createDocumentTable(rows)}
                                        </TableBody>
                                    </Table>
                                </Paper>
                            </div>
                        ) : (
                                <div className={classes.tableWrapper}>
                                    <Paper className={classes.tableContainer}>
                                        <List
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                            className={classes.root}
                                        >
                                            {bookingViewMetadata != null && Object.keys(bookingViewMetadata).length !== 0 ? (
                                                Object.keys(bookingViewMetadata).map((_bookingNumKey) => {
                                                    return (
                                                        <div className={classes.treeViewDiv}>
                                                            <ListItem className={classes.treeViewCollapsableTabs} button onClick={handleClick(_bookingNumKey)}>
                                                                <ListItemIcon>
                                                                    <AttachFileIcon style={{ color: "#000000" }} />
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    className={classes.listItemText}
                                                                    primary={
                                                                        <div className={classes.listItemTextPrimaryDiv}>
                                                                            <div className={classes.listItemTextPrimaryDivInfoIconDiv}>
                                                                                <Tooltip title={getDocumentUploadedStatusTitle(_bookingNumKey)['statusInString']} className={classes.listItemTextPrimaryDivInfoIcon}>
                                                                                    <FlagIcon style={{ color: getDocumentUploadedStatusTitle(_bookingNumKey)['statusColor'] }} />
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div className={classes.listItemTextPrimaryDivTypo}>
                                                                                <TableCell align="center" className={classes.treeViewTableCell}>
                                                                                    <div className={classes.treeViewTableCellInfoDiv}>
                                                                                        <Typography className={classes.treeViewTableCellInfoLabel}>
                                                                                            {`Booking Number`}
                                                                                        </Typography>
                                                                                        <Typography className={classes.treeViewTableCellInfoValue}>
                                                                                            {_bookingNumKey}
                                                                                        </Typography>
                                                                                    </div>

                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.treeViewTableCell}>
                                                                                    <div className={classes.treeViewTableCellInfoDiv}>
                                                                                        <Typography className={classes.treeViewTableCellInfoLabel}>
                                                                                            {`Reference Number`}
                                                                                        </Typography>
                                                                                        <Typography className={classes.treeViewTableCellInfoValue}>
                                                                                            {bookingViewMetadata[_bookingNumKey]['referenceNumber'] ? bookingViewMetadata[_bookingNumKey]['referenceNumber'] : "-"}
                                                                                        </Typography>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.treeViewTableCell}>
                                                                                    <div className={classes.treeViewTableCellInfoDiv}>
                                                                                        <Typography className={classes.treeViewTableCellInfoLabel}>
                                                                                            {`# Required Doc Types`}
                                                                                        </Typography>
                                                                                        <Typography className={classes.treeViewTableCellInfoValue}>
                                                                                            {bookingViewMetadata[_bookingNumKey]['requiredDocumentTypeCount'] ? bookingViewMetadata[_bookingNumKey]['requiredDocumentTypeCount'] : "NA"}
                                                                                        </Typography>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.treeViewTableCell}>
                                                                                    <div className={classes.treeViewTableCellInfoDiv}>
                                                                                        <Typography className={classes.treeViewTableCellInfoLabel}>
                                                                                            {`# Uploaded Req'd Doc Types`}
                                                                                        </Typography>
                                                                                        <Typography className={classes.treeViewTableCellInfoValue}>
                                                                                            {bookingViewMetadata[_bookingNumKey]['uploadedReqDocumentTypeCount'] ? bookingViewMetadata[_bookingNumKey]['uploadedReqDocumentTypeCount'] : "NA"}
                                                                                        </Typography>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell align="center" className={classes.treeViewTableCell}>
                                                                                    <div className={classes.treeViewTableCellInfoDiv}>
                                                                                        <Typography className={classes.treeViewTableCellInfoLabel}>
                                                                                            {`# Uploaded Documents`}
                                                                                        </Typography>
                                                                                        <Typography className={classes.treeViewTableCellInfoValue}>
                                                                                            {bookingViewMetadata[_bookingNumKey]['uploadedDocumentsCount']}
                                                                                        </Typography>
                                                                                    </div>
                                                                                </TableCell>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                />
                                                                {openPanels[_bookingNumKey] ? <ExpandLess /> : <ExpandMore />}
                                                            </ListItem>
                                                            <Collapse in={openPanels[_bookingNumKey]} timeout="auto" unmountOnExit>
                                                                <Paper className={classes.tableContainer}>
                                                                    <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                {columnsTreeView.map(column => (
                                                                                    <TableCell key={column.id} width={tableHeadWidth} align={"center"} className={classes.tableHeadRow} >
                                                                                        {column.label}
                                                                                    </TableCell>
                                                                                ))}
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {createDocumentTable(treeViewData.hasOwnProperty(_bookingNumKey) ? treeViewData[_bookingNumKey] : [], true, _bookingNumKey)}
                                                                        </TableBody>
                                                                    </Table>
                                                                </Paper>
                                                            </Collapse>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                    <Typography style={{ textAlign: "center" }}>
                                                        {
                                                            backdrop ? `Fetching ${radioButtonView === "ListView" ? "documents" : "shipments"}, please wait` 
                                                            : `No ${radioButtonView === "ListView" ? "documents" : "shipments"} are found`
                                                        }
                                                    </Typography>
                                                )}

                                        </List>
                                    </Paper>
                                </div>
                            )
                    }

                    <Drawer
                        anchor="right"
                        open={drawerState.right}
                    >
                        {newDocument === 0 ? modifyDocumentSideList('right') : newDocument === 1 ? newDocumentSideList('right') : ""}
                    </Drawer>

                    {
                        ((radioButtonView === 'TreeView' && Object.keys(bookingViewMetadata).length === 0)) ? "" :
                            <div className={classes.tableFooterDiv}>
                                {
                                    radioButtonView === 'TreeView' ? 
                                    <div className={classes.tableFooterNoteDiv}>
                                        <Typography style={{ fontSize: "0.85rem" }}>
                                            {"Note: * indicates required Document Type"}
                                        </Typography>
                                    </div> : ""
                                }
                                <div className={classes.tablePaginationDiv}>
                                    <TablePagination
                                        rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                                        component="div"
                                        count={queryCount}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        backIconButtonProps={{
                                            "aria-label": "previous page"
                                        }}
                                        nextIconButtonProps={{
                                            "aria-label": "next page"
                                        }}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        labelRowsPerPage={"Rows per page"}
                                    />
                                </div>
                            </div>
                    }
                    <Dialog
                        open={popupOpen}
                        onClose={handlePopupClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        fullWidth={true}
                        maxWidth={'md'}
                    >
                        <DialogTitle id="alert-dialog-title">
                            <h5 className={classes.hfivestyle}>
                                Name        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{values.DocumentName}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Description        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{values.DocumentDescription}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Version        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{version}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                &nbsp;Timestamp        :
                                <h6 className={classes.hsixstyle}>
                                    {timestamp}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Last updated by        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{lastUpdatedBy}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Document Hash        :
                                <h6 className={classes.hsixstyle}>
                                    &nbsp;{documentHash}
                                </h6>
                            </h5>
                            <h5 className={classes.hfivestyle}>
                                Print       :
                                    <ReactToPrint
                                    trigger={() => <PrintIcon className={classes.printIcon}>Print this out!</PrintIcon>}
                                    content={() => componentRef.current}
                                />
                                <div ref={componentRef} className="print-source">
                                    <p>
                                        <FormRenderer docName={values.DocumentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={showDocumentJSON} disable={true} />
                                    </p>
                                </div>
                            </h5>
                            {tampered != null ? (!tampered ? (
                                <span className={classes.smallTextIcon}>
                                    <VerifiedUserIcon style={{ "color": "green" }} className={classes.smallTextIcon} />Data’s authenticity is verified against blockchain ledger
                                </span>) : (<span className={classes.smallTextIcon}>
                                    <CancelIcon style={{ "color": "red" }} className={classes.smallTextIcon} />Data looks like tampered. Hashes don't match from blockchain
                                </span>))
                                : ("")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div style={{
                                    background: "#f8f8f8", height: "140%", overflow: "auto"
                                }}>
                                    <div className={classes.flexItem}>
                                        <p>Document</p>
                                        <div style={{ maxWidth: "1400px", maxHeight: "100%", border: "1px solid", borderColor: "#D4D4D4", marginRight: " 30px" }}>
                                            <FormRenderer docName={values.DocumentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={showDocumentJSON} disable={true} />
                                        </div>
                                    </div>
                                </div >
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handlePopupClose}
                                color="primary"
                                autoFocus
                            >
                                CLOSE
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Paper>
        </div>
    )
}
