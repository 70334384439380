import React, { useState } from "react";
import { useStyles } from "./styles/ChannelInformationStyles";
import theme from "../../themes/lightTheme";
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from "@material-ui/core/IconButton";
import { MenuItem, Input, Checkbox, ListItemText, InputLabel, Select, FormControl } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';

import Tab from '@material-ui/core/Tab';
import organizationService from '../../services/OrganizationService'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import channelService from "../../services/ChannelService";
import Paper from '@material-ui/core/Paper';
import BlockIcon from "@material-ui/icons/BorderAll";
import notify from "../../utils/notifier";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Toolbar from '@material-ui/core/Toolbar';
import StorageIcon from '@material-ui/icons/Storage';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from "@material-ui/core/Divider";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import GroupIcon from '@material-ui/icons/Group';
import Grid from "@material-ui/core/Grid";
import BlockInfo from "./BlockInfo";
import { Link } from "react-router-dom";
import Transactions from "./Transactions"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Skeleton from 'react-loading-skeleton';
import chaincodeService from '../../services/ChaincodeService'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import ChannelInformationNavigation from './ChannelInformationNavigation';
import {
    getExistingOrganizations,
    MenuProps,
} from '../organizations/OrganizationsUtils'

//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};


export default function ChannelInformation(props) {

    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const [channelInfoData, setChannelInfoData] = useState({});
    const [open, setOpen] = React.useState(true);
    const [numBlocks, setNumBlocks] = React.useState(0);
    const [backdrop, setBackdrop] = React.useState(false);
    const [noOfMembers, setNoOfMembers] = React.useState([]);
    const [orgData, setOrgData] = React.useState([]);
    const [chaincodeData, setChaincodeData] = React.useState([]);
    const [endorsers, setEndorsers] = React.useState(null);
    const [drawer, setDrawer] = React.useState(null);
    const [nOf, setNOf] = React.useState("");
    const [selectedMembers, setSelectedMembers] = React.useState([]);
    const [fetchingChaincodeInstantiated, setFetchingChaincodeInstantiated] = React.useState(true);
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [mapValueExistingOrganization, setMapValueExistingOrganization] = React.useState({})
    const [dataFetched, setDataFetced] = React.useState(false);

    const [sideDrawerOpen, setSideDrawerOpen] = React.useState(false);



    const [members, setMembers] = React.useState("");
    const [memberNames, setMemberNames] = React.useState([]);

    const [selectedOrgToRemove, setSelectedOrgToRemove] = React.useState({
        id : null,
        organizationName: null
    });

    const [openDialog, setOpenDialog] = React.useState(false);

    const [dialogTextContent, setDialogTextContent] = React.useState("");

    const handleClickOpenDialog = (orgObj) => event => {
        setBackdrop(true);
        channelService.getEndorsementPoliciesOfOrgByChannelId(props.location.state.channelId, orgObj.id).then(
            response => {
                let data = response.data;
                setSelectedOrgToRemove(orgObj);
                if (data.length) {
                    setDialogTextContent(`Cannot remove ${orgObj.organizationName} from ${props.location.state.channelName}, ${orgObj.organizationName} is a member of following endorsement policies of chaincodes: ${data}`);
                } else {
                    setDialogTextContent(`Do you really want to remove ${orgObj.organizationName} from ${props.location.state.channelName}?`);
                }
                setOpenDialog(true);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Failed to invite the orgs to the channel"
                });
            }
        );
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setBackdrop(false);
    };

    const disableSubmitButton = () => {
        if (!members) {
            return true;
        }
        return memberNames.length !== members;
    };

    const setInitialState = () => {
        setMemberNames([]);
        setMembers("");
        setSelectedMembers([])
        setNoOfMembers([])
        setSelectedOrgToRemove({
            id : null,
            organizationName: null
        })
    }
    const handleSubmit = () => {
        closeDrawer('right', false);
        let userOrgId = localStorage.getItem('_orgID');
        setBackdrop(true);

        //Create channel call
        var apiReqObj = {};
        let channelId = props.location.state.channelId;
        apiReqObj["organizationId"] = userOrgId;
        apiReqObj["invitedOrgs"] = [];
        for (let i = 0; i < memberNames.length; i++) {
            apiReqObj["invitedOrgs"].push(memberNames[i]);
        }

        //Invite orgs call
        channelService.inviteOrgsToChannel(apiReqObj, channelId).then(
            response => {
                setBackdrop(false);
                let message = "";
                let _data = response.data;
                let partialFailure = _data.some(i => !i.success)

                if (partialFailure) {
                    let failedOrgs = _data.filter(i => !i.success).map(i => i.data.orgName);
                    message = "Successfully invited some of the orgs to the channel. Failed to invite the following orgs : " + failedOrgs.toString();
                } else {
                    message = "Successfully invited all orgs to channel";
                }

                notify({
                    type: "success",
                    message: message
                });
                setDrawerState({ ...drawerState, ["right"]: false });
                setInitialState();
                fetchData();
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Failed to invite the orgs to the channel"
                });
            }
        );
    };

    const [render, setRender] = React.useState(false)

    const updateOrganizationList = (members) => {
        let newOrgsList = orgData.filter(org => !members.includes(org["id"]));
        setOrgData(newOrgsList);
    }

    const handleChangeMemberNames = (event) => {
        setMemberNames(event.target.value.map(member => member.id));
        setSelectedMembers(event.target.value);
        if (render) {
            setRender(false);
        } else {
            setRender(true);
        }
    };

    const openDrawer = (side, open, row) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            setSideDrawerOpen(true)
        } else {
            setSideDrawerOpen(false)
        }

        setDrawer("invite");
        let peersFlag = false;
        let includeOrderer = false;
        let query = {
            'peers': peersFlag,
            'orderer': includeOrderer
        }
        let networkId = props.location.state.networkId;
        setBackdrop(true)
        organizationService.getOrgsByNetwork(networkId, query).then(
            response => {
                let _data = response.data;
                if (channelInfoData["organizations"]) {
                    let channelOrgs = channelInfoData["organizations"].map(org => org["id"]);
                    _data = _data.filter(org => !channelOrgs.includes(org["id"]));
                    setOrgData(_data);
                    let noOfMembersArr = [...Array(_data.length + 1).keys()].slice(1);
                    setNoOfMembers(noOfMembersArr);
                    if (noOfMembersArr.length > 0) {
                        setMembers(1);
                    }
                    let tempMap = {}
                    let allOrgs = _data
                    for (let i = 0; i < allOrgs.length; i++) {
                        let tempOrg = allOrgs[i];
                        tempMap[tempOrg["id"]] = tempOrg["organizationName"]
                    }
                    setMapValueExistingOrganization(tempMap);
                }
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching organizations data"
                });
            }
        );

        setDrawerState({ ...drawerState, [side]: open });
    };


    const openDrawerEndorsementPolicy = (side, open, chaincodeId, version) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        // version is in v1 format, so we need to slice off the first letter;
        version = version.slice(1);
        setDrawer("policy");
        setDrawerState({ ...drawerState, [side]: open });
        setBackdrop(true);
        channelService.getEndorsementPolicy(props.location.state.channelId, chaincodeId, version).then(
            response => {
                setBackdrop(false);
                let _data = response.data;
                let n_Of = "";
                let endorsers = [];
                if (_data["policy"]) {
                    n_Of = Object.keys(_data["policy"])[0];
                    for (let i = 0; i < _data["policy"][n_Of].length; i++) {
                        let identityIndex = _data["policy"][n_Of][i]["signed-by"];
                        endorsers.push(_data["identities"][identityIndex]["role"]["mspId"])
                    }
                    if (endorsers.length > 0) {
                        endorsers = endorsers.map(i => i.toUpperCase().slice(0, i.length - 3));
                    }
                }
                setNOf(n_Of);
                setEndorsers(endorsers);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching Policy"
                });
            }
        );
    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            setSideDrawerOpen(true)
        } else {
            setSideDrawerOpen(false)
        }
        setDrawerState({ ...drawerState, [side]: open });
        setInitialState();
    };

    const handleClick = () => {
        setOpen(!open);
    };

    /* TODO Change the view from JSON to a more suitable one */

    const policySideList = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Endorsement Policy : "} <b>{nOf}</b></Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <div style={{
                background: "#f8f8f8", overflow: "auto"
            }}>
                <Table className={classes.table} aria-label="customized table">
                    <TableBody>
                        {endorsers != null && Object.keys(endorsers).length !== 0 && typeof endorsers === 'object' ? (
                            endorsers.map((endorser) => {
                                return (
                                    <TableRow style={{height: "60px"}} key="id">
                                        <TableCell align="center"><b>{endorser}</b> </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                                ""
                            )}

                    </TableBody>
                </Table>
            </div >

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>

    );

    const handleDeleteInvitedOrg = i => (event) => {
        let modifiedSelectedOrgs = Array.from(memberNames)
        modifiedSelectedOrgs.splice(i, 1);
        setMemberNames(modifiedSelectedOrgs);
        setMembers(members - 1);
        setSelectedMembers(modifiedSelectedOrgs)
    }

    function inviteNewOrg(isDisabled) {
        return (
            <div>
                {noOfMembers.length ?
                    <Button
                        color="primary"
                        className={classes.inviteNewOrgButton}
                        onClick={() => { setMembers(members + 1) }}
                        startIcon={<AddCircleIcon />}
                        disabled={isDisabled}
                    >
                        {"Add an org"}
                    </Button>
                    :
                    ""
                }
            </div>
        )
    }

    function deleteInviteNewOrg(i) {
        return (
            <Button
                color="primary"
                className={classes.removeButton}
                onClick={handleDeleteInvitedOrg(i)}
                startIcon={<RemoveCircleIcon />}
                disabled={false}
            >
            </Button>
        )
    }

    const handleSetMemberNames = (_value) => {
        setMemberNames(_value);
    }

    const handleSetSelectedMembers = (_value) => {
        setSelectedMembers(_value);
    }

    const handleSetMembers = (_value) => {
        setMembers(_value);
    }



    const handleChangeInviteOrgs = i => (event) => {
        if (memberNames[i]) {
            memberNames[i] = event.target.value;
            selectedMembers[i] = event.target.value;
        } else {
            let tempInvitedOrgs = Array.from(memberNames)
            tempInvitedOrgs.push(event.target.value)
            setMemberNames(tempInvitedOrgs);
            setSelectedMembers(tempInvitedOrgs);
        }

        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }

    };

    function createInviteOrgs() {
        let arr = [];
        if (members > 0) {
            for (let i = 0; i < members; i++) {
                arr.push(
                    <div>
                        <div className={classes.formContainer}>
                            <div className={classes.flexItem}>
                                <FormControl style={{ minWidth: '80%', fontStyle: "inherit" }}>
                                    <InputLabel id="demo-mutiple-name-label">Organization *</InputLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        fullWidth
                                        renderValue={() => { return <div>{mapValueExistingOrganization[memberNames[i]]}</div>; }}
                                        value={memberNames[i]}
                                        onChange={handleChangeInviteOrgs(i)}
                                        input={<Input />}
                                        MenuProps={MenuProps}
                                    >
                                        {getExistingOrganizations(orgData, selectedMembers, i)}
                                    </Select>
                                </FormControl>
                                <div style={{ display: 'inline', overflow: "auto", maxHeight: "74vh", float: "right" }}>
                                    {i == 0 ? "" : deleteInviteNewOrg(i)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        return arr
    }

    const handleRemoveOrgFromChannel = () => {
        setBackdrop(true);
        setOpenDialog(false);
        channelService.removeOrgFromChannel(props.location.state.channelId, selectedOrgToRemove.id).then(
            response => {
                setBackdrop(false);
                let message = response.message;
                notify({
                    type: "success",
                    message: message
                });
                fetchData();
            },
            error => {
                let errMsg = error.response.data.message;
                let temp = errMsg.split(" ");
                temp = temp.slice(1);
                errMsg = temp.join(" ");
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error: " + errMsg,
                    autoClose: false
                });
            }
        );
    };


    const sideList = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Invite organizations to channel"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>
            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                {createInviteOrgs()}
            </div>
            {backdrop ?
                ""
                :
                noOfMembers.length ?
                    "" :
                    sideDrawerOpen ?
                        <div className={classes.warningStyle}>
                            <h3>{`All organizations are already members of this channel`}</h3>
                        </div>
                        :
                        ""
            }
            <div style={{ overflow: "auto", maxHeight: "74vh", float: "right" }}>
                {members >= noOfMembers.length ? inviteNewOrg(true) : inviteNewOrg(false)}
                <br></br>
            </div>
            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmit} color="primary" variant="contained" disabled={backdrop || disableSubmitButton()}>
                    {"SUBMIT"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>


            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>

    );

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={3}>{children}</Box>}
            </Typography>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const handleChangeTabs = (event, newValue) => {
        setTabValue(newValue);
    };

    const fetchData = () => {
        let peer = JSON.parse(localStorage.getItem('_peers'))[0]["peerDomainCode"];

        setBackdrop(true);
        channelService.getChannelByChannelId(props.location.state.channelId).then(
            response => {
                const _data = response.data;
                setChannelInfoData(_data);
                setDataFetced(true);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                setDataFetced(true);
                notify({
                    type: "error",
                    message: "Error while fetching channel's info"
                });
            }
        );

        chaincodeService.getInstantiatedChainCodes(peer, props.location.state.channelId).then(
            response => {
                const _data = response.data;
                setChaincodeData(_data);
                setFetchingChaincodeInstantiated(false);

            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching instantiated chaincode"
                });
                setFetchingChaincodeInstantiated(false);
            }

        );


        channelService.getChannelInfo(props.location.state.channelId, peer).then(
            response => {
                const _data = response.data;
                if (_data.height) {
                    setNumBlocks(_data.height);
                }
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching channel's info"
                });
            }
        );

    };

    React.useEffect(() => {
        fetchData();
    }, []);


    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.borderMain}>
            <Paper style={{ minHeight: "85vh" }}>
                <ChannelInformationNavigation networkName={props.location.state.networkName} networkId={props.location.state.networkId} networkDescription={props.location.state.networkDescription}
                    channelName={props.location.state.channelName} channelDescription={props.location.state.channelDescription} channelId={props.location.state.channelId} channelValue={props.location.state.channelValue}
                    channelCode={props.location.state.channelCode} fetchData={fetchData} numBlocks={numBlocks} history={props.history}
                />
                <br></br><br></br>
                <Button
                    color="primary"
                    className={classes.button}
                    onClick={openDrawer("right", true)}
                    startIcon={<AddCircleIcon />}
                    disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN") || !dataFetched}
                    style={{ float: "right", marginRight: "25px", marginTop: "10px" }}
                >
                    {"Invite Organizations"}
                </Button>
                <Tabs value={tabValue} indicatorColor="primary" className={classes.tabStyling}
                    textColor="primary" onChange={handleChangeTabs} aria-label="simple tabs example">
                    <Tab icon={<div> <ConfirmationNumberIcon className={classes.iconAlign} /> &nbsp;Info</div>} {...a11yProps(0)} />
                    <Tab label={<div> <BlockIcon className={classes.iconAlign} /> &nbsp; Blocks </div>} {...a11yProps(1)} />
                    {/* <Tab icon={<div> <AccountBalanceWalletIcon className={classes.iconAlign} />&nbsp;Transactions</div>} {...a11yProps(2)} /> */}
                    {/* <Tab label={<div> <StorageIcon className={classes.iconAlign} /> &nbsp; Assets </div>} {...a11yProps(2)} /> */}
                </Tabs>
                <Divider className={classes.dividerStyling}></Divider>

                <TabPanel value={tabValue} index={0}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                <h2>Members</h2>
                            </Typography>

                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3} style={{ textAlign: "left" }}>

                                    {channelInfoData != null && Object.keys(channelInfoData).length !== 0 ? (
                                        channelInfoData.organizations.map((member) => {
                                            return (
                                                <Grid item xs={6}>
                                                    <Card className={classes.card}>
                                                        <CardContent>
                                                            <div className={classes.orgNameAndRemoveDiv}>
                                                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                                    <GroupIcon style={{ verticalAlign: 'middle' }} />&nbsp;{member.organizationName}
                                                                </Typography>
                                                                <div className={classes.removeOrgDiv}>
                                                                    <Tooltip title={`Remove ${member.organizationName} from channel`} className={classes.listItemTextPrimaryDivInfoIcon}>
                                                                        <Button
                                                                            color="primary"
                                                                            className={classes.button}
                                                                            onClick={handleClickOpenDialog(member)}
                                                                            startIcon={<RemoveCircleIcon />}
                                                                            disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                                            style={{float: "right"}}
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                            <Grid container spacing={1}>
                                                                <Grid container item xs={12} spacing={3}>
                                                                    {member.peers.map(peer => {
                                                                        return (
                                                                            <Grid item xs={6}>
                                                                                <Paper className={classes.paperText}>
                                                                                    <PermIdentityIcon style={{ verticalAlign: 'middle' }} />&nbsp;{peer.peerName}
                                                                                </Paper>
                                                                            </Grid>
                                                                        );
                                                                    })}

                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Card>
                                                </Grid>
                                            );
                                        })
                                    ) : (
                                            <Grid item xs={6}>
                                                <CardContent>
                                                    <Skeleton height={10} />
                                                    <Skeleton height={50} />
                                                </CardContent>

                                            </Grid>
                                        )}

                                </Grid>
                            </Grid>

                        </CardContent>

                    </Card>
                    <br></br>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                <h2>Instantiated Chaincodes</h2>
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid container item xs={12} spacing={3}>
                                    {chaincodeData != null && Object.keys(chaincodeData).length !== 0 && typeof chaincodeData === 'object' ? (
                                        chaincodeData.map((member) => {
                                            return (
                                                <Grid item xs={4}>
                                                    <Paper style={{ cursor: "pointer" }} className={classes.paperText} onClick={openDrawerEndorsementPolicy("right", true, member.id, member.version)}>{member.name} : {member.version}</Paper>
                                                </Grid>
                                            );
                                        })
                                    ) : (
                                            fetchingChaincodeInstantiated ? "" : <div style={{ paddingLeft: "0.8em", paddingTop: "1em" }}> No chaincodes instantiated</div>
                                        )}

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </TabPanel>

                <TabPanel value={tabValue} index={1}>
                    {/* <BlockInfo channelName={props.location.state.channelName} channelCode={props.location.state.channelCode} channelId={props.location.state.channelId} blockHeight={numBlocks}></BlockInfo> */}
                </TabPanel>

                {
                    tabValue === 1
                        ? <BlockInfo channelName={props.location.state.channelName} channelCode={props.location.state.channelCode} channelId={props.location.state.channelId} blockHeight={numBlocks}></BlockInfo>
                        : ""
                }

                {/* <TabPanel value={tabValue} index={3}>
                    <Grid container spacing={1}>
                        <Grid container item xs={12} spacing={3}>
                            <Transactions xsValue={12} blockNo={""} blockPage={false}></Transactions>
                        </Grid>
                    </Grid>
                </TabPanel> */}

                {/* <TabPanel value={value} index={2}>
                    <ChannelAsset channelName={props.location.state ? (props.location.state.channelName) : ""} channelId={props.location.state ? (props.location.state.channelId) : ""}></ChannelAsset>
                </TabPanel> */}

                <Drawer
                    anchor="right"
                    open={drawerState.right}
                    onOpen={closeDrawer('right', true)}
                    onClose={closeDrawer('right', false)}
                >
                    {drawer === "policy" ? policySideList('right') : sideList('right')}
                </Drawer>
                <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Paper>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle} gutterBottom>
                        {`Removing ${selectedOrgToRemove.organizationName} from channel`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogContentText}>
                        {`${dialogTextContent}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        dialogTextContent.includes('Cannot remove') ? "" : 
                        <Button autoFocus className={classes.dialogBotton} color="primary" onClick={handleRemoveOrgFromChannel}>
                            CONFIRM
                        </Button>
                    }
                    <Button autoFocus className={classes.dialogBotton} color="primary" onClick={handleCloseDialog} >
                        {dialogTextContent.includes('Cannot remove') ? "CLOSE" : "CANCEL"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div >


    );
}