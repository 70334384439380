import React, { useState } from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import lightTheme from '../themes/lightTheme';
import { Typography, Paper, Drawer } from '@material-ui/core';
import notify from '../utils/notifier';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { ALPHA_NUM_SMALLCAP_NO_SPACE, VALID_USER_INPUT_REGEX } from "../utils/constants";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import GroupIcon from '@material-ui/icons/Group';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import Toolbar from '@material-ui/core/Toolbar';
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import theme from "../themes/lightTheme";
import Moment from 'react-moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormControl, Input, InputLabel, Checkbox } from '@material-ui/core';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SubjectIcon from '@material-ui/icons/Subject';
//Import child components
import ChainCodeCard from '../components/chaincode/ChainCodeCard';
import InstallForm from '../components/chaincode/InstallForm';
import InstanstiateForm from '../components/chaincode/InstanstiateForm';
import ApproveChaincodeForm from '../components/chaincode/ApproveChaincodeForm';
import CommitChaincodeForm from '../components/chaincode/CommitChaincodeForm';
import ChaincodeService from '../services/ChaincodeService';
import OrganizationService from '../services/OrganizationService';
import UploadFile from '../components/UploadFile';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChannelIcon from "@material-ui/icons/Cast";
import LanguageIcon from '@material-ui/icons/Language';

const drawerWidth = "30vw";
const fileUploadCriteria = {
    validFileTypes: [
        'application/zip'
    ],
    maxFileSizeLimit: 10000000 // 10MB
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1,
        transition: lightTheme.transitions.create("margin", {
            easing: lightTheme.transitions.easing.sharp,
            duration: lightTheme.transitions.duration.leavingScreen
        }),
        marginRight: 0
    },
    rootShift: {
        transition: lightTheme.transitions.create("margin", {
            easing: lightTheme.transitions.easing.easeOut,
            duration: lightTheme.transitions.duration.enteringScreen
        }),
        marginRight: 0
    },
    paper: {
        height: '100%',
    },
    list: {
        width: "30vw",
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px",
        margin: "5px 0px"
    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        marginRight: "5px"
    },
    titleRow: {
        paddingTop: "5px",

    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        //margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    chaincodeDropdown: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        paddingRight: theme.spacing(4)
    },
    chaincodeDropdownFormControl: {
        width: "100%"
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    pageTitle: {
        paddingBottom: '1.3%'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        // top: '64px'
    },
    forms: {
        //padding: '5%',
        boxShadow: 'none'
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",
    },
    disableHoverIcon: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }
    },
    errorRow: {
        margin: "80px 10px",
        padding: "15px",
        textAlign: "center",
        background: "#f8f8f8",
        color: "orange",
        marginRight: theme.spacing(2)
    },
    nested1: {
        paddingLeft: theme.spacing(4),
    },
    nested2: {
        paddingLeft: theme.spacing(14),
    },
    nested3: {
        paddingLeft: theme.spacing(18),
        textTransform: 'capitalize'
    },
    nested4: {
        paddingLeft: theme.spacing(17),
    },
    nested5: {
        paddingLeft: theme.spacing(21),
        textTransform: 'capitalize'
    },
    button: {
        height: "2rem",
        padding: "1rem",
        margin: '1rem',
    },
    formBody: {
        marginTop: '8%',
        overflow: 'auto',
        maxHeight: '74vh'
    },
    fields: {
        paddingBottom: '5%',
        paddingLeft: lightTheme.spacing(2)
    },

}));

export default function Chaincode(props) {

    const classes = useStyles();

    //Drawer open state
    const [open, setOpen] = React.useState(false);
    const [approveDrawer, setApproveDrawer] = React.useState(false);
    const [commitDrawer, setCommitDrawer] = React.useState(false);
    const [formType, setFormType] = React.useState('');
    const [chains, setChains] = React.useState([]);
    const [chaincodeVersions, setChaincodeVersions] = React.useState([]);
    const [formData, setFormData] = React.useState({});
    const [backdrop, setBackdrop] = React.useState(false);
    const [newVersionDrawer, setNewVersionDrawer] = React.useState(false);
    const [peerDrawer, setPeerDrawer] = React.useState(false);
    const [peers, setPeers] = React.useState(null);
    const [orgs, setOrgs] = React.useState([]);

    const [networks, setNetworks] = React.useState([]);
    const [channels, setChannels] = React.useState([]);
    const [uploadDrawer, setUploadDrawer] = React.useState(false);

    const [uploadValues, setValues] = React.useState({
        ccName: "",
        ccVersion: "",
        ccDesc: "",
        ccVerisonId: ""
    });

    const [uploadNewOption, setUploadNewOption] = React.useState(false);
    const [systemChaincodes, setSystemChaincodes] = React.useState([]);
    const [selectedChaincode, setSelectedChaincode] = React.useState("");

    const [file, setFile] = useState("");

    const [openPanels, setOpenPanels] = React.useState({});
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);

    const [installedChaincodeList, setInstalledChaincodeList] = React.useState([]);
    const [committedChaincodeEndorser, setCommittedChaincodeEndorser] = React.useState({});
    const [previousCommittedEndorsers, setPreviousCommittedEndorsers] = React.useState({});
    const [openCommittedChaincodes, setOpenCommittedChaincodes] = React.useState({});
    const [prevPolicy, setPrevPolicy] = React.useState({});
    const handleClick = org => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPanels({ ...openPanels, [org]: !openPanels[org] });
    };

    //Fetch chaincode information
    React.useEffect(() => {
        fetchData();

    }, []);

    const fetchData = () => {
        setBackdrop(true);

        ChaincodeService.getDistinctChaincodes().then(
            response => {
                let _data = response.data;
                ChaincodeService.getAllChaincodes().then(
                    response => {
                        let data = response.data;
                        setChaincodeVersions(data);
                        setBackdrop(false);
                    },
                    error => {
                        setBackdrop(false);
                        notify({
                            type: "error",
                            message: "Error occured while fetcing the data"
                        });
                    }

                );
                setChains(_data);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error occured while fetcing the data"
                });
            }
        );

    };

    // const handleDrawerOpen = (formCard) => {
    //     setFormType(formCard.mode);
    //     setFormData(formCard.chainInfo);
    //     setOpen(true);
    // };

    // const handleApproveChaincode = (formCard) => {
    //     setFormData(formCard.chainInfo);
    //     setApproveDrawer(true);
    // };

    // const handleCommitChaincode = (formCard) => {
    //     // setBackdrop(true);
    //     setFormData(formCard.chainInfo);
    //     setCommitDrawer(true);
    // };

    const handleInstall = (formCard) => {
        setFormType(formCard.mode);
        setFormData(formCard.chainInfo);
        setBackdrop(true);
        const orgName = localStorage.getItem('_orgName');
        const reqObj = { id: formCard.chainInfo.id, version: formCard.chainInfo.version };
        ChaincodeService.installChainCode(reqObj).then(response => {
            OrganizationService.getPeersByOrgId(parseInt(localStorage.getItem('_orgID'))).then(
                response => {
                    let data = response.data;
                    if (data.length) {
                        let peerId = data[0].id;
                        ChaincodeService.getInstalledChaincodesByPeerId(peerId).then(
                            response => {
                                let _installedChaincodeList = response.data;
                                setInstalledChaincodeList(_installedChaincodeList);
                            },
                            error => {
                                notify({
                                    type: "error",
                                    message: `Failed to get installed chaincodes on peer of ${orgName}`
                                });
                            }
                        )
                    }
                },
                error => {
                    notify({
                        type: "error",
                        message: `Failed to get peers info for the ${orgName}`
                    });
                }
            )

            notify({
                type: 'success',
                message: 'Chaincode installed successfully!'
            });
            setBackdrop(false);
        }, error => {
            if (error.response && error.response.status == 409) {
                notify({
                    type: "error",
                    message: "This chaincode is already installed"
                });
            } else {
                notify({
                    type: "error",
                    message: "Error occured while installing chiancode"
                });
            }
            setBackdrop(false);
        });
    };

    // const handleNewVersion = (formCard) => {
    //     uploadValues.ccName = formCard.chainInfo.name;
    //     uploadValues.ccVersion = formCard.chainInfo.version + 1;
    //     setNewVersionDrawer(formCard.showVersionDrawer);

    //     ChaincodeService.getAvailableSystemChaincodes().then(response => {
    //         let result = response.data;
    //         result = result.map(cc => {
    //             let temp = cc.split('.');
    //             let extention = "." + temp.splice(-1);
    //             return temp.join('.');
    //         })
    //         setSystemChaincodes(result);
    //     }, err => {
    //         notify({
    //             type: "error",
    //             message: "Error occured while getting system chaincodes"
    //         });
    //     });

    // };

    const handlePeerInstall = (newData) => {
        const newPeers = newData.installPeers;
        const ccname = newData.data.name;
        const newCard = newData.data;
        const isCCname = (chain) => {
            return chain.ccname === ccname;
        };
        handleDrawerClose();
        // TODO: Don't remove the commented code. Chaincode screen has to be re-designed to accommodate all the requirements, that point of time, this should be dealt differently.
        // const chainIndex = chains.findIndex(isCCname);
        // const tempChains = chains;
        // tempChains[chainIndex] = newCard
        // setChains(tempChains);
    };

    const handleChannelInstanstiate = (newData) => {
        const newChannels = newData.newChannels;
        const ccname = newData.data.ccname;
        const newCard = newData.data;
        // TODO: Don't remove the commented code. Chaincode screen has to be re-designed to accommodate all the requirements, that point of time, this should be dealt differently.
        // newCard.channels.push(newChannels);
        // const isCCname = (chain) => {
        //     return chain.ccname === ccname;
        // };
        // const chainIndex = chains.findIndex(isCCname);
        // const tempChains = chains;
        // tempChains[chainIndex] = newCard;
        // setChains(tempChains);
        handleDrawerClose();

    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleApprovalDrawerClose = () => {
        setApproveDrawer(false);
    };

    const handleCommitDrawerClose = () => {
        setCommitDrawer(false);
    };


    const handleFileUpload = (file) => {
        if (file) {
            if (fileUploadCriteria.validFileTypes.includes(file.type) && file.size < fileUploadCriteria.maxFileSizeLimit) {
                setFile(file);
            } else {
                setFile("");
                let errMsg = !fileUploadCriteria.validFileTypes.includes(file.type)
                    ? "Invalid file type , please upload a .zip file."
                    : "File size should be less than 1 MB"
                notify({
                    type: "error",
                    message: errMsg
                });
            }
        } else {
            setFile("")
        }
    }
    const setInitialState = () => {
        uploadValues.ccName = "";
        uploadValues.ccDesc = "";
        uploadValues.ccVersion = "";
        setFile("");
        setUploadNewOption(false);
        setSelectedChaincode("");
    }
    const handleChange = prop => (event) => {
        let regexMap = {
            'ccName': ALPHA_NUM_SMALLCAP_NO_SPACE
        }
        let regex = regexMap[prop] ? regexMap[prop] : VALID_USER_INPUT_REGEX;

        if (!regex.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setValues({ ...uploadValues, [prop]: event.target.value });
    };

    const handleChangeChaincodeUploadOption = (event) => {
        setUploadNewOption(event.target.checked);
        setSelectedChaincode("");
    }

    const closeNewVersionDrawer = () => {
        setNewVersionDrawer(false);
        setInitialState();
    };

    const openUploadDrawer = () => {
        setInitialState();
        setUploadDrawer(true);

        ChaincodeService.getAvailableSystemChaincodes().then(response => {
            let result = response.data;
            result = result.map(cc => {
                let temp = cc.split('.');
                let extention = "." + temp.splice(-1);
                return temp.join('.');
            })
            setSystemChaincodes(result);
        }, err => {
            notify({
                type: "error",
                message: "Error occured while getting system chaincodes"
            });
        });

    };
    const closeUploadDrawer = () => {
        setUploadDrawer(false);
    };

    const openPeerDrawer = (chaincodeId, chaincodeName, version, chaincodeVersionId, description) => {

        uploadValues.ccVersion = version;
        uploadValues.ccName = chaincodeName;
        uploadValues.ccVerisonId = chaincodeVersionId;
        uploadValues.ccDesc = description;
        setBackdrop(true);

        ChaincodeService.getInstalledOnPeers(chaincodeId, version).then(response => {
            let _data = response.data;
            setPeerDrawer(true);
            if (typeof (_data) === 'object') {
                let orgs = Object.keys(_data)
                setOrgs(orgs);
                for (let i = 0; i < orgs.length; i++) {
                    openPanels[orgs[i]] = false;
                }
            }
            setPeers(_data);
            setBackdrop(false);
        }, errr => {
            setBackdrop(false);
            notify({
                type: "error",
                message: "Error occured while getting peers"
            });
        });

        ChaincodeService.getChannelsByCommittedChaincodeId(chaincodeVersionId).then(response => {
            let _data = response.data;
            setPeerDrawer(true);
            if (typeof (_data) === 'object') {
                let networks = Object.keys(_data)
                setNetworks(networks);
                for (let i = 0; i < networks.length; i++) {
                    openPanels[networks[i]] = false;
                }
            }
            setChannels(_data);
        }, errr => {
            notify({
                type: "error",
                message: "Error occured while getting instantiated chaincodes on channels"
            });
        });
        
    };
    const closePeerDrawer = () => {
        setPeerDrawer(false);
        setPeers([]);
        for (let key in openPanels) {
            openPanels[key] = false;
        }
        setOpenPanels(openPanels);
    };

    const handleClickGetEndorser = (channel, network) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        
        let key = network + "_" + channel.channelName;

        if (!Object.keys(openPanels).includes(key)) {
            openPanels[key] = false;
        }
        if (openPanels[key] === false) {
            ChaincodeService.getEndorsementPolicyByChaincodeVersionId(uploadValues.ccVerisonId, channel.channelId, true).then(response => {
                let _data = response.data;
                if (!Object.keys(committedChaincodeEndorser).includes(`${network}_${channel.channelName}`)) {
                    committedChaincodeEndorser[`${network}_${channel.channelName}`] = [];
                }
                if (_data.length) {
                    let _endorsers = _data[0].endorsementPolicy
                    _endorsers = _endorsers.split('(')[1];
                    _endorsers = _endorsers.split(')')[0];
                    _endorsers = _endorsers.split(',');
                    _endorsers = _endorsers.map(i => i.slice(1, i.length - 1));
                    _endorsers = _endorsers.map(i => i.split('.')[0].split('-').join(' '));
                    _endorsers = _endorsers.map(i => i.slice(0, i.length - 3));
                    committedChaincodeEndorser[`${network}_${channel.channelName}`] = _endorsers;
                }
                setOpenPanels({ ...openPanels, [key]: !openPanels[key] });
                setCommittedChaincodeEndorser(committedChaincodeEndorser);

            }, errr => {
                notify({
                    type: "error",
                    message: "Error occured while getting instantiated chaincodes on channels"
                });
            });
        } else {
            setOpenPanels({ ...openPanels, [key]: !openPanels[key] });
        }


    };

    const handleClickGetPreviousEndorser = (channel, network) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        let key = "Previous_" + network + "_" + channel.channelName;

        if (!Object.keys(openPanels).includes(key)) {
            openPanels[key] = false;
        }
        if (openPanels[key] === false) {
            ChaincodeService.getCommittedEndorsementPoliciesByChaincodeVersionId(uploadValues.ccVerisonId, channel.channelId).then(response => {
                let _data = response.data;
                if (!Object.keys(previousCommittedEndorsers).includes(`${network}_${channel.channelName}`)) {
                    previousCommittedEndorsers[`${network}_${channel.channelName}`] = [];
                }
                let endorserPolicy = [];
                if (_data.length > 1) {
                    for (let i = 1; i < _data.length; i++) {
                        let _endorsers = _data[i].endorsementPolicy
                        _endorsers = _endorsers.split('(')[1];
                        _endorsers = _endorsers.split(')')[0];
                        _endorsers = _endorsers.split(',');
                        _endorsers = _endorsers.map(i => i.slice(1, i.length - 1));
                        _endorsers = _endorsers.map(i => i.split('.')[0].split('-').join(' '));
                        _endorsers = _endorsers.map(i => i.slice(0, i.length - 3));
                        let obj = {
                            createdAt: _data[i].createdAt,
                            policy: _endorsers
                        };
                        endorserPolicy.push(obj);
                    }
                    previousCommittedEndorsers[`Previous_${network}_${channel.channelName}`] = endorserPolicy;
                }
                setOpenPanels({ ...openPanels, [key]: !openPanels[key] });
                setPreviousCommittedEndorsers(previousCommittedEndorsers);

            }, errr => {
                notify({
                    type: "error",
                    message: "Error occured while getting instantiated chaincodes on channels"
                });
            });
        } else {
            setOpenPanels({ ...openPanels, [key]: !openPanels[key] });
        }


    };

    const handleClickOpenChannel = (key) => {
        if (!Object.keys(openCommittedChaincodes).includes(key)) {
            openCommittedChaincodes[key] = false;
        }
        setOpenCommittedChaincodes({ ...openCommittedChaincodes, [key]: !openCommittedChaincodes[key] });
    }
    const handlePrevPolicy = (key) => {
        if (!Object.keys(prevPolicy).includes(key)) {
            prevPolicy[key] = false;
        }
        setPrevPolicy({ ...prevPolicy, [key]: !prevPolicy[key] });

    }
    const handleUploadChaincode = (evt) => {

        if (!uploadValues.ccName || uploadValues.ccName == "") {
            notify({
                type: "error",
                message: "Chaincode name is required"
            });
            return;
        }
        if (!uploadValues.ccDesc || uploadValues.ccDesc == "") {
            notify({
                type: "error",
                message: "Description is required"
            });
            return;
        }
        if (uploadNewOption && !file) {
            notify({
                type: "error",
                message: "Please select a file to upload"
            });
            return;
        }
        if (!uploadNewOption && (selectedChaincode.length === 0)) {
            notify({
                type: "error",
                message: "Please select any chaincode from dropdown to upload"
            });
            return;
        }

        var formData = new FormData();
        formData.append("name", uploadValues.ccName);
        formData.append("description", uploadValues.ccDesc);
        if (newVersionDrawer) {
            formData.append("version", uploadValues.ccVersion);
        }

        if (uploadNewOption) {
            formData.append("file", file);
        } else {
            formData.append("chaincodeName", selectedChaincode);
        }

        setBackdrop(true);
        ChaincodeService.uploadChaincode(formData).then(response => {
            setBackdrop(false);
            notify({
                type: "success",
                message: "Successfully uploaded chaincode"
            });
            response = response.data;
            setChains([]); // Temporary fix for duplicate chaincodes view after upload. Ideally we shouldn't clear it, need to come back to it later
            setUploadDrawer(false);
            setNewVersionDrawer(false);
            setInitialState();
            fetchData();
        }, errr => {
            setBackdrop(false);
            if (errr.response.status === 409) {
                notify({
                    type: "error",
                    message: "A chaincode with this name already exists. Please provide a different name"
                });
            } else {
                notify({
                    type: "error",
                    message: "Error occured while uploading chaincode"
                });
            }

        });
    };

    const handleChangeChaincodeSelect = (event) => {
        setSelectedChaincode(event.target.value);
    };

    const sideList = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={newVersionDrawer ? closeNewVersionDrawer : closeUploadDrawer}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{newVersionDrawer ? "Upload new version" : "Upload a Chaincode"}</Typography>
                </Toolbar>
                <Divider />
            </div>
            <br></br>
            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                <div className={classes.flexItem}>
                    <TextField
                        id="name"
                        required
                        disabled={newVersionDrawer}
                        className={classes.textField}
                        error={invalidUserInput.includes("ccName")}
                        label={"Chaincode Name"}
                        margin="normal"
                        required
                        fullWidth
                        value={uploadValues.ccName}
                        onChange={handleChange("ccName")}
                    />
                    {newVersionDrawer ? <TextField
                        id="version"
                        required
                        disabled={newVersionDrawer}
                        className={classes.textField}
                        error={invalidUserInput.includes("ccVersion")}
                        label={"Chaincode Verison"}
                        margin="normal"
                        required
                        fullWidth
                        value={uploadValues.ccVersion}
                        onChange={handleChange("ccVersion")}
                    /> : ""}

                    <TextField
                        id="description"
                        required
                        className={classes.textField}
                        error={invalidUserInput.includes("ccDesc")}
                        label={"Description"}
                        margin="normal"
                        required
                        rows="2"
                        fullWidth
                        value={uploadValues.ccDesc}
                        onChange={handleChange("ccDesc")}
                    />

                    <div className={classes.chaincodeDropdown}>
                        <FormControl className={classes.chaincodeDropdownFormControl}>
                            <InputLabel id="channel-checkbox-label">Select Chaincode</InputLabel>
                            <Select
                                value={selectedChaincode}
                                onChange={handleChangeChaincodeSelect}
                                input={<Input />}
                                MenuProps={MenuProps}
                                disabled={uploadNewOption}
                            >
                                {
                                    systemChaincodes.map((cc) => (
                                        <MenuItem
                                            key={cc}
                                            value={cc}
                                        >
                                            {cc}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={uploadNewOption}
                                    onChange={handleChangeChaincodeUploadOption}
                                    name="uploadNewCC"
                                    color="primary"
                                />
                            }
                            label="Upload new chaincode file"
                        />
                    </div>


                    {
                        (uploadNewOption) ?
                            <div style={{ paddingRight: "32px" }}>
                                <UploadFile onFileUpload={handleFileUpload} fileUploadCriteria={fileUploadCriteria}></UploadFile>
                            </div>
                            : ""
                    }
                </div>
                <br></br>
            </div>
            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleUploadChaincode} color="primary" variant="contained" disabled={backdrop || !uploadValues.ccName || !uploadValues.ccDesc || (uploadNewOption ? !file : selectedChaincode.length === 0) || invalidUserInput.length}>
                    {"UPLOAD"}
                </Button>
                <Button className={classes.drawerButton} onClick={newVersionDrawer ? closeNewVersionDrawer : closeUploadDrawer} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >

    );

    const peerSideList = side => (
        <div className={classes.list}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closePeerDrawer}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{uploadValues.ccName} {": version "} v{uploadValues.ccVersion} </Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <div style={{
                background: "#f8f8f8", overflow: "auto"
            }}>
                <div className={classes.formBody}>
                    <div className={classes.fields} style={{ display: 'block' }}>
                        <Typography><b>Chaincode Name : </b>{uploadValues.ccName}</Typography>
                    </div>
                    <div className={classes.fields} style={{ display: 'block' }}>
                        <Typography><b>Version : </b>{uploadValues.ccVersion}</Typography>
                    </div>
                    <div className={classes.fields} style={{ display: 'block' }}>
                        <Typography><b>Description : </b>{uploadValues.ccDesc}</Typography>
                    </div>
                </div>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Installed on
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {orgs != null && orgs.length !== 0 ? (
                        orgs.map((org) => {
                            return (
                                <div>
                                    <ListItem button onClick={handleClick(org)}>
                                        <ListItemIcon>
                                            <GroupIcon />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontWeight: "bold" }} primary={org} />
                                        {openPanels[org] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openPanels[org]} timeout="auto" unmountOnExit>

                                        {peers != null && peers[org] != null && peers[org].length !== 0 ? (
                                            peers[org].map((peer) => {

                                                return (
                                                    <List component="div" disablePadding>
                                                        <ListItem button className={classes.nested1}>
                                                            <ListItemIcon>
                                                                <PermIdentityIcon />

                                                            </ListItemIcon>
                                                            <ListItemText primary={peer.peerName} />
                                                            {peer.installedAt ? <ListItemText style={{
                                                                textAlign: "right"
                                                            }} primary={<Moment style={{ fontSize: "small" }} format="D MMM, YYYY hh:mm A">{peer.installedAt}</Moment>} /> : ""}

                                                        </ListItem>
                                                    </List>
                                                );
                                            })
                                        ) : (
                                                ""
                                            )}

                                    </Collapse>
                                </div>
                            );
                        })
                    ) : (
                            <Typography style={{ textAlign: "center" }}>Not installed anywhere</Typography>
                        )}

                </List>
            </div >

            <br></br>

            <div style={{
                background: "#f8f8f8", overflow: "auto"
            }}>

                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Committed on
                        </ListSubheader>
                    }
                    className={classes.root}
                >
                    {networks != null && networks.length !== 0 ? (
                        networks.map((network) => {
                            return (
                                <div>
                                    <ListItem button onClick={handleClick(network)}>
                                        <ListItemIcon>
                                            <LanguageIcon />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontWeight: "bold" }} primary={network} />
                                        {openPanels[network] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={openPanels[network]} timeout="auto" unmountOnExit>

                                        {channels != null && channels[network] != null && channels[network].length !== 0 ? (
                                            channels[network].map((channel) => {
                                                return (
                                                    <List component="div" disablePadding>
                                                        <ListItem button className={classes.nested1} onClick={() => handleClickOpenChannel(`${network}_${channel.channelName}`)}>
                                                            <ListItemIcon>
                                                                <ChannelIcon />
                                                            </ListItemIcon>
                                                            <ListItemText primary={channel.channelName} />
                                                            {openCommittedChaincodes[network + '_' + channel.channelName] ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItem>
                                                        <Collapse in={openCommittedChaincodes[network + '_' + channel.channelName]} timeout="auto" unmountOnExit>
                                                            <List component="div" disablePadding style={{ paddingLeft: "1.5em" }}>
                                                                <ListItem button className={classes.nested1} onClick={handleClickGetEndorser(channel, network)}>
                                                                    <ListItemIcon className={classes.iconColor}>
                                                                        <SubjectIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText style={{ textDecoration: "none" }} primary={"Endorsement Policy (Current)"} />
                                                                    {openPanels[network + '_' + channel.channelName] ? <ExpandLess /> : <ExpandMore />}
                                                                </ListItem>
                                                                <Collapse in={openPanels[network + '_' + channel.channelName]} timeout="auto" unmountOnExit>
                                                                    <ListItem className={classes.nested2}>
                                                                        <ListItemText primary={"Committed on"} />
                                                                        {channel.committedAt ? <ListItemText
                                                                            primary={< Moment format="D MMM, YYYY hh:mm A" style={{ fontSize: "small" }}>{channel.committedAt}</Moment>}
                                                                        /> : ""}
                                                                        <ExpandLess />
                                                                    </ListItem>

                                                                { 
                                                                    committedChaincodeEndorser != null && committedChaincodeEndorser[network + '_' + channel.channelName] != null && committedChaincodeEndorser[network + '_' + channel.channelName].length !== 0 ? (
                                                                        committedChaincodeEndorser[network + '_' + channel.channelName].map((endorserOrg) => {
                                                                            return (
                                                                                <List component="div" disablePadding>
                                                                                    <ListItem button className={classes.nested3}>
                                                                                        <ListItemText primary={endorserOrg} />
                                                                                    </ListItem>
                                                                                </List>
                                                                            )
                                                                        })
                                                                        ) : (
                                                                                ""
                                                                            )
                                                                    }
                                                                </Collapse>
                                                                <ListItem button className={classes.nested1} onClick={handleClickGetPreviousEndorser(channel, network)}>
                                                                    <ListItemIcon className={classes.iconColor}>
                                                                        <SubjectIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText style={{ textDecoration: "none" }} primary={"Previous Endorsement Policy(s)"} />
                                                                    <ExpandMore />
                                                                </ListItem>

                                                            </List>
                                                        </Collapse>
                                                        <Collapse in={openPanels["Previous_" + network + '_' + channel.channelName] && openCommittedChaincodes[network + '_' + channel.channelName]} timeout="auto" unmountOnExit>
                                                            {
                                                                previousCommittedEndorsers != null && previousCommittedEndorsers["Previous_" + network + '_' + channel.channelName] != null && previousCommittedEndorsers["Previous_" + network + '_' + channel.channelName].length !== 0 ? (
                                                                    previousCommittedEndorsers["Previous_" + network + '_' + channel.channelName].map((endorserOrg, index) => {
                                                                        return (
                                                                            <List>
                                                                                <ListItem button className={classes.nested4} onClick={() => handlePrevPolicy(`${channel.channelName}_${index}`)}>
                                                                                    <ListItemText primary={"Committed on"} />
                                                                                    {<ListItemText
                                                                                        primary={<Moment format="D MMM, YYYY hh:mm A" style={{ fontSize: "small" }}>{endorserOrg.createdAt}</Moment>} />}
                                                                                    {prevPolicy === index ? <ExpandLess /> : <ExpandMore />}
                                                                                </ListItem>
                                                                                <Collapse in={prevPolicy[channel.channelName + '_' + index]} timeout="auto" unmountOnExit>
                                                                                    {
                                                                                        endorserOrg.policy.map((org) => {
                                                                                            return (
                                                                                                <List component="div" disablePadding>
                                                                                                    <ListItem button className={classes.nested5}>
                                                                                                        <ListItemText primary={org} />
                                                                                                    </ListItem>
                                                                                                </List>
                                                                                            )
                                                                                        })

                                                                                    }
                                                                                </Collapse>
                                                                            </List>
                                                                        )
                                                                    }
                                                                    )
                                                                ) : (
                                                                        ""
                                                                    )
                                                            }
                                                        </Collapse>
                                                    </List>
                                                );
                                            })
                                        ) : (
                                                ""
                                            )}

                                    </Collapse>
                                </div>
                            );
                        })
                    ) : (
                            <Typography style={{ textAlign: "center" }}>Not committed anywhere</Typography>
                        )}

                </List>
            </div >

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>

    );

    return (
        <MuiThemeProvider theme={lightTheme}>
            <div className={classes.borderMain}>
                <Paper style={{ minHeight: "87vh", overflowY: "scroll" }}
                    className={open ? classes.root : classes.rootShift}>

                    <div className={classes.titleRow}>
                        <h2 className={classes.tableTitle}>{"Chaincode Repository"}</h2>
                        <div className={classes.tableHeadRight}>
                            <Button color="primary" className={classes.button}
                                disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                onClick={openUploadDrawer}
                                startIcon={<AddCircleIcon />} >
                                {"Upload Chaincode"}
                            </Button>
                        </div>
                    </div>

                    {backdrop ?
                        ""
                        :
                        !chains.length ?
                            (<div className={classes.errorRow}>
                                <h3>No Chaincodes available. Please click on 'Upload Chaincode' for uploading a new chaincode to the repository.</h3>
                            </div>)
                            :
                            ""
                    }

                    <Grid
                        container
                        spacing={4}
                        wrap={'wrap'}
                    >
                        {chains.map(chain => {
                            // let disableUploadNewVersion = chains.some(i => (i.name === chain.name && i.version > chain.version))
                            // let ccVersion = chaincodeVersions.filter(x => x.name === chain.name)
                            return (
                                <Grid item key={chain.name} xs={4}
                                    style={{ minWidth: open ? '460px' : '440px', maxWidth: '465px', marginLeft: '25px' }}
                                >
                                    <ChainCodeCard
                                        handlePeerDrawer={openPeerDrawer}
                                        name={chain.name}
                                        id={chain.id}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>

                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <Paper className={classes.forms}>
                            {formType === 'install' ? <InstallForm data={formData} handleClose={handleDrawerClose} handleInstall={handlePeerInstall} /> : <InstanstiateForm data={formData} handleClose={handleDrawerClose} handleInstanstiate={handleChannelInstanstiate} />}
                        </Paper>
                    </Drawer>
                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={approveDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <Paper className={classes.forms}>
                            <ApproveChaincodeForm data={formData} handleClose={handleApprovalDrawerClose}/>
                        </Paper>
                    </Drawer>
                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={commitDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <Paper className={classes.forms}>
                            <CommitChaincodeForm data={formData} handleClose={handleCommitDrawerClose}/>
                        </Paper>
                    </Drawer>
                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={uploadDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <Paper className={classes.forms}>
                            {sideList('right')}
                        </Paper>
                    </Drawer>

                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={newVersionDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <Paper className={classes.forms}>
                            {sideList('right')}
                        </Paper>
                    </Drawer>

                    <Drawer
                        className={classes.drawer}
                        anchor="right"
                        open={peerDrawer}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <Paper className={classes.forms}>
                            {peerSideList('right')}
                        </Paper>
                    </Drawer>
                    <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                </Paper>
                <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </MuiThemeProvider>
    );
}