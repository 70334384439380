import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Util from "../../utils/Util";
import { NO_SPACE_REGEX, VALID_USER_INPUT_REGEX } from "../../utils/constants";
import { Typography, Toolbar } from "@material-ui/core";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom";
import NetworkService from '../../services/NetworkService';
import notify from '../../utils/notifier';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExploreIcon from '@material-ui/icons/Explore';
import { useStyles } from "./styles/NetworkStyles";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

//TODO: this columns to be fetched form api once api supports the same...
export const _columns = [
  { id: "organizationName", label: "Organization", minWidth: 100, sortable: true },
  { id: "hostName", label: "Host", minWidth: 170, sortable: true },
  { id: "peers", label: "Peers", minWidth: 170, sortable: true },
  { id: "Domain", label: "Domain", minWidth: 170, sortable: true }
];

//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};

export default function Networks() {
  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [newNetwork, setNewNetwork] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [networkData, setNetworkData] = React.useState(null);
  const [searchKey, setSearchKey] = useState({ value: "" });
  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [backdrop, setBackdrop] = React.useState(false);
  const [invalidUserInput, setInvalidUserInput] = React.useState([]);

  const [values, setValues] = React.useState({
    networkName: "",
    networkDescription: "",
    ordererName: "",
    ordererDomain: "",
    ordererPeer0Name: "",
    ordererPeer0Domain: "",
    ordererPeer1Name: "",
    ordererPeer1Domain: "",
    ordererPeer2Name: "",
    ordererPeer2Domain: "",
    ordererPeer3Name: "",
    ordererPeer3Domain: "",
    ordererPeer4Name: "",
    ordererPeer4Domain: "",
  });

  const setInitialState = () => {
    values.networkName = ""
    values.networkDescription = ""
    values.ordererName = ""
    values.ordererDomain = ""
    values.ordererPeer0Name = ""
    values.ordererPeer0Domain = ""
    values.ordererPeer1Name = ""
    values.ordererPeer1Domain = ""
    values.ordererPeer2Name = ""
    values.ordererPeer2Domain = ""
    values.ordererPeer3Name = ""
    values.ordererPeer3Domain = ""
    values.ordererPeer4Name = ""
    values.ordererPeer4Domain = ""
  };

  const handleChange = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeDomainsName = prop => (event) => {
    if (event.target.value === '' || NO_SPACE_REGEX.test(event.target.value) || VALID_USER_INPUT_REGEX.test(event.target.value)) {
      setValues({ ...values, [prop]: event.target.value });
    }
  };

  const handleChangeName = prop => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleChangeNetworkName = prop => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const openDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
    setValue(0);
  };

  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
    setInitialState();
  };

  const disableSubmitButton = () => {
    if (values.networkName && values.ordererName
      && values.ordererDomain && values.ordererPeer0Name && values.ordererPeer1Name && values.ordererPeer2Name && values.ordererPeer3Name && values.ordererPeer4Name) {
      return false;
    }
    return true;
  };

  const createOrdererPeersObj = () => {
    let peerObjArray = [];
    let peerObj = {};
    peerObj["peerName"] = values.ordererPeer0Name;
    peerObj["peerDomainName"] = values.ordererPeer0Name + '.' + values.ordererDomain;
    peerObjArray.push(peerObj);
    peerObj = {};
    peerObj["peerName"] = values.ordererPeer1Name;
    peerObj["peerDomainName"] = values.ordererPeer1Name + '.' + values.ordererDomain;
    peerObjArray.push(peerObj);
    peerObj = {};
    peerObj["peerName"] = values.ordererPeer2Name;
    peerObj["peerDomainName"] = values.ordererPeer2Name + '.' + values.ordererDomain;
    peerObjArray.push(peerObj);
    peerObj = {};
    peerObj["peerName"] = values.ordererPeer3Name;
    peerObj["peerDomainName"] = values.ordererPeer3Name + '.' + values.ordererDomain;
    peerObjArray.push(peerObj);
    peerObj = {};
    peerObj["peerName"] = values.ordererPeer4Name;
    peerObj["peerDomainName"] = values.ordererPeer4Name + '.' + values.ordererDomain;
    peerObjArray.push(peerObj);
    return peerObjArray;
  };


  const buildRequestObj = () => {
    let invalidFields = [];
    invalidFields = validateFields(values.networkName,
      values.ordererName,
      values.ordererDomain,
      values.ordererPeer0Name,
      values.ordererPeer1Name,
      values.ordererPeer2Name,
      values.ordererPeer3Name,
      values.ordererPeer4Name);

    if (invalidFields.length) {
      setBackdrop(false);
      notify({
        type: "error",
        message:
          "Invalid Fields(" +
          invalidFields.length +
          "): Please provide valid input."
      });
      return;
    }
    var formData = {};
    formData["networkName"] = values.networkName;
    formData["networkDescription"] = values.networkDescription;
    formData["ordererName"] = values.ordererName;
    formData["ordererDomain"] = values.ordererDomain;
    formData["ordererPeers"] = createOrdererPeersObj();
    return formData;
  };

  const handleSubmit = () => {
    setBackdrop(true);
    closeDrawer('right', false);
    let requestObj = buildRequestObj();
    let statusCode = null;
    if (!requestObj) return;
    NetworkService.createNetwork(requestObj).then(
      response => {
        setBackdrop(false);
        statusCode = response.status;
        notify({
          type: "success",
          message: "Successfully created network"
        });
        handleUploadedData({ data: requestObj, status: statusCode });
        setDrawerState({ ...drawerState, ["right"]: false });
        setInitialState();
        fetchData();
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while creating network"
        });
      }
    );
  };

  const validateFields = (...fields) => {
    let errorFields = [];
    fields.forEach(field => {
      if (!field) {
        errorFields.push(field);
      }
    });
    return errorFields;
  };

  const fetchData = () => {
    setBackdrop(true)
    NetworkService.getNetworks().then(response => {
      let _data = response.data;
      setRows(_data);
      setOriginalRows(_data);
      setNetworkData(_data);
      setBackdrop(false)
    },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching Networks"
        });
      });
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const clearSearchText = () => {
    setSearchKey({ value: "" });
    fetchData();
  };

  let timeout = null;
  const handleSearch = event => {
    const _val = event.target.value;
    setSearchKey(prevState => {
      return { ...prevState, value: _val };
    });

    if (_val.length <= 2) {
      setRows(originalRows);
      return;
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      let key = _val;
      let newRows = Util.searchByValue(rows, key);
      setRows(newRows);
    }, 200);
  };

  const handleUploadedData = result => {
    if (result.status === 201) {
      setNewNetwork(true);
    }
  };

  const sideList = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Create a new Network"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>

      <div style={{ overflow: "auto", maxHeight: "74vh" }}>
        <div className={classes.flexItem}>
          <TextField
            id="standard-basic"
            className={classes.textField}
            error={invalidUserInput.includes("networkName")}
            label={"Network Name"}
            margin="normal"
            rows="2"
            required
            fullWidth
            value={values.networkName}
            autoComplete='off'
            onChange={handleChange("networkName")}
          />
        </div>
        <div className={classes.flexItem}>
          <TextField
            id="standard-basic"
            className={classes.textField}
            error={invalidUserInput.includes("networkDescription")}
            label={"Network Description"}
            margin="normal"
            rows="2"
            required
            fullWidth
            value={values.networkDescription}
            autoComplete='off'
            onChange={handleChange("networkDescription")}
          />
        </div>

        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("ordererName")}
              label={"Orderer Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererName}
              onChange={handleChange("ordererName")}
            />
          </div>

          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              label={"Orderer Domain"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererDomain}
              onChange={handleChangeDomainsName("ordererDomain")}
            />
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("ordererPeer0Name")}
              label={"Orderer Peer 1 Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererPeer0Name}
              onChange={handleChange("ordererPeer0Name")}
            />
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("ordererPeer1Name")}
              label={"Orderer Peer 2 Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererPeer1Name}
              onChange={handleChange("ordererPeer1Name")}
            />
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("ordererPeer2Name")}
              label={"Orderer Peer 3 Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererPeer2Name}
              onChange={handleChange("ordererPeer2Name")}
            />
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("ordererPeer3Name")}
              label={"Orderer Peer 4 Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererPeer3Name}
              onChange={handleChange("ordererPeer3Name")}
            />
          </div>
        </div>

        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("ordererPeer4Name")}
              label={"Orderer Peer 5 Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.ordererPeer4Name}
              onChange={handleChange("ordererPeer4Name")}
            />
          </div>
        </div>
      </div>

      <div className={classes.sidedrawerFooter}>
        {/* {
          invalidUserInput.length ? (
              <div className={classes.warningStyle}>
                  <p>Warning : Invalid Input values </p>
                  <p>valid input : alphanumeric, - _ . "space"</p>
              </div>    
          ) : (
              ""
          )
        } */}
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmit} color="primary" variant="contained" disabled={backdrop || disableSubmitButton() || invalidUserInput.length}>
          {"SUBMIT"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  return (
    <div className={classes.root}>
      <Grid item xs={12} style={{ minHeight: "87vh" }}>
        <div className={classes.tableTitle}>
          <h2>{"Networks"}</h2>
        </div>
        <div className={classes.tableHeadRight}>
          <div className={classes.searchBox}>
            <InputBase
              onChange={handleSearch}
              value={searchKey.value}
              className={classes.input}
              placeholder={"Search Networks"}
            />
            {searchKey.value.length ? (
              <IconButton
                size="medium"
                aria-label="search"
                onClick={clearSearchText}
              >
                <CloseIcon />
              </IconButton>
            ) : (
                ""
              )}
            <IconButton
              size="medium"
              aria-label="search"
              disabled
            >
              <SearchIcon />
            </IconButton>
          </div>
          <Button
            color="primary"
            className={classes.button}
            onClick={openDrawer("right", true)}
            startIcon={<AddCircleIcon />}
            disabled={(localStorage.getItem('_userRole') !== "SUPERADMIN")}
          >
            {"Create network"}
          </Button>
        </div>
        <div className={classes.paperCard}>

          {networkData != null && Object.keys(networkData).length ? (
            networkData.map((network) => {
              return (
                <Card key={network.networkName} className={classes.card}>
                  <CardContent>
                    <CardHeader
                      title={network.networkName}
                    />
                    <Divider />
                    <CardContent>
                      <Typography variant="body1">
                        Description: {network.networkDescription}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <Link style={{ textDecoration: 'none' }} to={{
                      pathname: "/networks/" + network.id,
                      state: { networkId: network.id, networkName: network.networkName, networkDescription: network.networkDescription, fromNetwork: true }
                    }
                    }>
                      <Button
                        color="primary"
                        className={classes.button}
                        style={{ float: "right" }}
                        disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                        startIcon={<ExploreIcon />}>
                        Explore
                      </Button>
                    </Link>
                  </CardContent>
                </Card>
              );
            })
          ) : (
              <div style={{ margin: "10px" }}>{backdrop ? "Fetching networks" : "No Networks"}</div>
            )}

          <Drawer
            anchor="right"
            open={drawerState.right}
            onOpen={closeDrawer('right', true)}
            onClose={closeDrawer('right', true)}
          >
            {sideList('right')}
          </Drawer>
        </div>
      </Grid>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
