import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';

export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const _columns = [
  { id: 'assetNo', label: 'ID', align: 'center', width: '5%', isFilterable: false, fitlerTypeSearch: false },
  { id: 'originalAssetName', label: 'Asset Name', align: 'center', width: '11%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'assetReference', label: 'Asset Reference', align: 'center', width: '11%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'subAssetTypeName', label: 'Asset Type', align: 'center', width: '11%', sortable: true, isFilterable: true, fitlerTypeSearch: false },
  { id: 'version', label: 'Version', align: 'center', width: '5%', isFilterable: false, fitlerTypeSearch: false },
  { id: 'channelName', label: 'Channel', align: 'center', width: '11%', isFilterable: true, fitlerTypeSearch: true },
  { id: 'currentOwnerOrganizationName', label: 'Current Owner', align: 'center', width: '11%', isFilterable: true, fitlerTypeSearch: true },
  { id: 'currentStateDescription', label: 'Current State', align: 'center', width: '13%', isFilterable: false, fitlerTypeSearch: true },
  { id: 'updatedAt', label: 'Last updated at', align: 'center', width: '11%', sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'Link', label: 'Action', align: 'center', width: '11%', isFilterable: false, fitlerTypeSearch: false }
];

export const assetType = [
  'Bill Of Lading'
]

export const ebillOfLadingChaincodeName = "ebillofladingcc";

export const disableSubmitButtonMultiUpload = (documentsData, selectedChannelId, selectedOrgCommercialRole) => {

  if (documentsData.length === 0 || selectedOrgCommercialRole == "" || selectedChannelId.length == 0) {
    return true;
  }


  for (let doc of documentsData) {
    if (doc.file && doc.documentName.length != 0) {
    } else {
      return true;
    }
  }

  return false;

}

export const buildRequestObjUploadMultiDocument = (documentsData, issuerOrgCode, assetTypeCodeMap, issuerAssetOrgRole, channelId) => {

  let docData = [];
  var formData = new FormData();
  for(let doc in documentsData) {
    let docDataObj = {
      'issuerOrganizationCode': issuerOrgCode,
      'issuerOrganizationRole':issuerAssetOrgRole,
      'uniqueId': documentsData[doc]['documentName'] ? documentsData[doc]['documentName'] + '-' + documentsData[doc]['validationCheckId'] :  documentsData[doc]['validationCheckId'],
      'assetReference' : documentsData[doc]['documentName'],
      'assetTypeCode': assetTypeCodeMap[documentsData[doc]['documentType']],
      'assetSubTypeCode': 'MASTER',
      'channelId': channelId,
      'isDraft': documentsData[doc]['isDraft']
    };

    docData.push(docDataObj)
    formData.append('file', documentsData[doc]['file']);
  }

  formData.append('assetsData', JSON.stringify(docData));

  return formData;
}

export const fileUploadCriteria = {
  validFileTypes: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/zip',
    'image/jpeg',
    'image/png'
    //'text/html'
  ],
  maxFileSizeLimit: 10000000 // 10 MB
}

export const buildRequestObjUpdateAsset = (
  file,
  currentOwnerOrgCode,
  currentOwnerOrgRole,
  assetId
) => {
  var formData = new FormData()
  formData.append('file', file)

  formData.append('currentOwnerOrgCode', currentOwnerOrgCode)
  formData.append('currentOwnerOrgRole', currentOwnerOrgRole)
  formData.append('assetId', assetId)

  return formData
}

