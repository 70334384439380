import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  fileUploadCriteria,
} from './documents/DocumentsUtils';

const useStyles = makeStyles(theme => ({
  uploadFileDiv: {
  },
  hiddenField: {
    fontSize: "80%"
  },
  fileUploadWrapper: {
    background: "#f8f8f8",
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    minHeight: theme.spacing(4),
    width: "100%"
  },
  fileCriteriaLabel: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between"
  },
  fileUploadToolbar: {
    paddingLeft: theme.spacing(1),
    display: "flex"
  },
  fileUploadInfo: {

  },
  fileUploadInfoFileName: {
    fontSize: theme.spacing(2)
  },
  fileUploadInfoInput: {

  }
}));

const fileTypeMap = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
  'application/msword': '.doc',
  'application/pdf': '.pdf',
  'image/jpeg': '.jpeg',
  'image/png': '.png',
  //'text/html': '.html',
  'application/zip': '.zip'
}

export default function UploadModal(props) {

  const classes = useStyles();
  const [render, setRender] = React.useState(false);
  const [file, setFile] = React.useState( props.documentsDetails ? props.documentsDetails[props.documentIndex]['file'] : "" );

  function renderAgain() {
    if (render) {
      setRender(false)
    } else {
      setRender(true)
    }
  }

  const validateOnFileChange = _file => {
    props.onFileUpload(_file, props.documentIndex);
    setFile(_file);
    renderAgain();
    if (!_file) return;
  };

  const handleClickDeleteFile = (docIndex) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (props.handleClickDeleteFile) {
      props.handleClickDeleteFile(docIndex);
    }
    setFile("");
    renderAgain();
  };

  let validFileTypesMsg = "Supported file types: ";
  let maxFileSizeLimitMsg = `Max file size: `;
  let _fileUploadCriteria = props.fileUploadCriteria ? props.fileUploadCriteria : fileUploadCriteria;
  if (_fileUploadCriteria) {
    for (let key in _fileUploadCriteria.validFileTypes) {
      validFileTypesMsg += fileTypeMap[_fileUploadCriteria.validFileTypes[key]] + ' '
    }
    let maxFileSizeLimitInMB = _fileUploadCriteria.maxFileSizeLimit / Math.pow(10, 6);
    maxFileSizeLimitMsg += `${maxFileSizeLimitInMB} MB`;
  }

  return (
    <div className={classes.uploadFileDiv}>
      <label id="uploadLabel" className={classes.fileUploadWrapper}>
        <div className={classes.fileUploadToolbar}>
          <div className={classes.fileUploadInfo}>
            {
              file ?
                <div className={classes.fileUploadInfoFileName}>
                  {file['name']}
                </div>
                 :
                 <div className={classes.fileUploadInfoInput}>
                   <input
                    id="file"
                    name="file  "
                    className={classes.hiddenField}
                    type="file"
                    required
                    onChange={e => {
                      const val = e.target.files[0];
                      validateOnFileChange(val);
                    }}
                  />
                 </div>
                
            }
          </div>
          {
            file ? (
              <IconButton
                aria-label="delete"
                color="secondary"
                size="small"
                onClick={handleClickDeleteFile(props.documentIndex)}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            ) : (
                ""
              )
          }
        </div>
      </label>
      <label className={classes.fileCriteriaLabel}>
        <div>
          {validFileTypesMsg}
        </div>
        <div>
          {maxFileSizeLimitMsg}
        </div>
      </label>
    </div>
  );
}

