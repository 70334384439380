import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles({
    list: {
        width: "30vw",
    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        width: "100%",
        marginRight: "80px",
        marginTop: "15px"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    filterDiv: {
        display: "flex"
    },
    formControl: {
        paddingRight: "0.5rem",
        minWidth: 180,
        maxWidth: 180,
    },
    clearFilterButtonDiv: {
        minHeight: "5vh",
        paddingTop: "1rem"
    },
    tableHeadRight: {
        float: "right",
        marginRight: theme.spacing(3),
        display: "flex",
    },
    tableHeadRow: {
        fontWeight: "700"
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    tableHeadAlignLeft: {
        marginLeft: theme.spacing(3.7)
    },
    tableColumnLabel: {
        marginLeft: theme.spacing(3),
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    searchBox: {
        borderBottom: "0.8px solid #00000021"
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    closeButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    addButton: {
        fontFamily: "timesnewroman",
        margin: "0px 24px",
        textAlign: "center",
        display: "flex"
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    a: {
        fontWeight: "bold",
        "&:hover": {
            background: '#E5E7E9'
        }
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    button: {
        //margin: theme.spacing(2)
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",

    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    networkBreadCrumb: {
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    // tableHead: {
    //     border: "0.8px solid #00000021"
    // },
    tableBorder: {
        border: "0.8px solid #00000021",
        overflowX: "scroll"
    },
    tableContainer: {
        padding: "20px",

    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier",

    },
    tableHeader: {
        fontWeight: "bold",
        textAlign: 'center'
    },
    tableHeaderChannelName: {
        fontWeight: "bold",
        paddingLeft: theme.spacing(5),
        width: theme.spacing(15),
    },
    tableOrgRole: {
        fontSize: '14px',
        // color:'red'
    },
    channelButtons: {
        // backgroundColor:"#183A64",
        color: 'white',
        display: 'flex',
        marginRight: '2px'
    },
    // tableContainerOrg: {
    //     padding: "20px",
    //     paddingTop: "20px"
    // },
    // tabsStyling: {
    //     paddingLeft: theme.spacing(4)
    // },
    paperChannelBlockInfo: {
        paddingBottom: theme.spacing(1),
        // padding: theme.spacing(3),
        // textAlign: 'right',
        justifyContent: 'right',
        marginRight: theme.spacing(1),
        // float: 'right',
    },
    iconStyle: {
        color: "green"
    },
    navigation: {
        display: "flex"
    },
    networkInfo: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        height: theme.spacing(4.5),
        display: "flex"
    },
    textNetworkDescription: {
        border: "none",
        width: theme.spacing(50),
        height: theme.spacing(4.5),
    },
    textNetworkDescriptionHover: {
        height: theme.spacing(4.5),
        width: theme.spacing(50),
        paddingLeft: theme.spacing(1),
        border: "0.8px solid #00000021",
    },
    textBreadCrumbDiv: {
        display: "flex",
        marginTop: "4px"
    },
    textBreadCrumbs: {
        border: "none",
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3)
    },
    textBreadCrumbsChannel: {
        border: "none",
        width: theme.spacing(25),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3)
    },
    textBreadCrumbsInputChannelOnHover: {
        width: theme.spacing(25),
        marginRight: '0px',
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
    },
    textBreadCrumbsInputOnHover: {
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
    },
    textBreadCrumbsOnHover: {
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    textBreadCrumbButtonsDiv: {
        marginTop: theme.spacing(2.5),
        display: "flex",
        paddingBottom: theme.spacing(0.8),
        height: theme.spacing(4.2)
    },
    textBreadCrumbButtonsDivNone: {
        marginTop: theme.spacing(2.5),
        display: "none",
        paddingBottom: theme.spacing(0.8),
        height: theme.spacing(4.2)
    },
    descriptionButtonsDiv: {
        display: "flex",
        marginTop: -theme.spacing(0.5)
    },
    descriptionButtonsDivNone: {
        display: "none",
        marginTop: -theme.spacing(0.5)
    },
    breadCrumbButton: {
        // fontFamily: "timesnewroman",
        // margin: theme.spacing(1),
        // textAlign: "center",
        // marginTop: "auto",
        // display: "flex",
    },
    descriptionButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    textBreadCrumbOrganizationName: {
        border: "none",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem"
    },
    dialogContentText: {
        fontWeight: "700"
    },
    dialogButton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        // color: theme.palette.text.secondary,
        overflowWrap: "break-word",
        marginBottom: theme.spacing(2)
    },
    list: {
        width: "50%",
        fontFamily: "sans-serif"
    },
    textBreadCrumbOrganizationDescription: {
        border: "none",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbOrganizationDomainName: {
        border: "none",
        width: theme.spacing(30),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    textBreadCrumbPeerName: {
        border: "none",
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(2),
    },
    textBreadCrumbPeerDomainName: {
        border: "none",
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(1)
    },
    uploadDiv: {
        // border: "1px solid #00000021",
        fontWeight: '600',
        fontSize: '0.9rem',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(10),
        alignItems: "center",
        float: "left",
    },

});
