import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import AdminApp from './AdminApp';
import Onboard from './pages/Onboard';
import { ProtectedRoute } from './utils/ProtectedRoute';

export default function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/login" component={Onboard} />
                <ProtectedRoute exact path="/*" component={AdminApp} />
            </Switch>
        </Router>
    );
}
