"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorStyles = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    .container {\n        align-items: center;\n        display: flex;\n        flex-direction: row;\n        height: 100%;\n        justify-content: center;\n        width: 100%;\n    }\n\n    .alert {\n        color: #ed1818;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ErrorStyles = _styledComponents.default.span(_templateObject());

exports.ErrorStyles = ErrorStyles;