import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid";
import CardHeader from '@material-ui/core/CardHeader';
import Transactions from './Transactions.js';
import StopIcon from '@material-ui/icons/Stop';
import ErrorIcon from '@material-ui/icons/Error';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import '../../css/style.css';
import Button from "@material-ui/core/Button";
import blockService from "../../services/BlockService"
import RefreshIcon from '@material-ui/icons/Refresh';
import Chip from '@material-ui/core/Chip';
import lightTheme from '../../themes/lightTheme';
import darkTheme from "../../themes/default-theme";
import notify from "../../utils/notifier";
import organizationService from '../../services/OrganizationService'
import Skeleton from 'react-loading-skeleton';
import { useStyles } from "./styles/BlockInfoStyles";

//import DateTime from 'luxon';



let currentTheme =
    localStorage.getItem("currentTheme") === "darkTheme" ? darkTheme : lightTheme;


//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};



export default function BlockInfo(props) {

    const classes = useStyles();
    let orgId = localStorage.getItem("_orgID");
    let peer = JSON.parse(localStorage.getItem('_peers'))[0]["peerDomainCode"];

    //let currentTheme = localStorage.getItem("currentTheme") === "darkTheme" ? darkTheme : lightTheme;
    const [blocksList, setBlocksList] = useState([]);

    const [showTransactionsInfo, setShowTransactionsInfo] = useState(false);
    const [xsValue, setXs] = useState(12);
    const [selectedBlock, setSelectedBlock] = useState("");
    const fetchData = () => {
        //setBlocksList(blockInfo);
        let blockHeight = props.blockHeight - 1;
        if (blockHeight < 0) {

        } else {
            blockService.getBlocksInChannel(props.channelId, blockHeight).then(
                response => {
                    const _data = response.data;
                    setBlocksList(_data);
                    if (typeof _data === "object" && _data.length > 0) {
                        setSelectedBlock(_data[0].header.number);
                        setShowTransactionsInfo(true)
                        setXs(6);
                    }
                },
                error => {
                    notify({
                        type: "error",
                        message: "Error: " + error.response.data.errorCode
                    });
                }
            );

        }


    };

    const loadMoreBlocks = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        let peer = JSON.parse(localStorage.getItem('_peers'))[0]["peerDomainCode"];
        let blockHeight = blocksList[blocksList.length - 1].header.number - 1;
        let newBlocks = blocksList;
        if (blockHeight < 0) {
            setBlocksList(newBlocks);

        } else {
            blockService.getBlocksInChannel(props.channelId, blockHeight).then(
                response => {
                    const _data = response.data;
                    if (typeof _data != "string") {
                        newBlocks = newBlocks.concat(_data);
                    }

                    setBlocksList(newBlocks);

                },
                error => {
                    notify({
                        type: "error",
                        message: "Error: " + error.response.data.errorCode
                    });
                }
            );

        }

    };

    const showTransactions = (blockNo) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setXs(6);
        setSelectedBlock(blockNo);
        setShowTransactionsInfo(true);
    };

    const closeTransactionsPane = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setXs(12);
        setSelectedBlock("");
        setShowTransactionsInfo(false);
    };


    React.useEffect(() => {
        fetchData();
    }, []);


    return (
        <div>
            <Grid container spacing={5}>
                <Grid container item xs={xsValue} spacing={3}>
                    <Paper style={{ maxHeight: '56vh', overflow: 'auto', width: "100%", marginLeft: "12px" }} elevation={2}>
                        <Toolbar style={{ background: "#f8f8f8" }}>
                            <Typography variant="h9" >
                                Blocks Info
                            </Typography>


                        </Toolbar>
                        <br></br>
                        <VerticalTimeline layout="1-column">
                            {blocksList != null && typeof blocksList != "string" && Object.keys(blocksList).length !== 0 ? (

                                blocksList.map((member) => {
                                    return (
                                        <VerticalTimelineElement
                                            className="vertical-timeline-element--work"
                                            contentStyle={{ background: '#fff', color: '#fff', boxShadow: 'none' }}
                                            contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                            icon={<StopIcon />}
                                        >

                                            <Card variant="outlined" className={selectedBlock == member.header.number ? (classes.card) : ("")} >
                                                <CardHeader className={classes.cardHeader}
                                                    title={<div> <StopIcon onClick={showTransactions(member.header.number)} style={{ verticalAlign: "middle", float: "right", cursor: "pointer" }} /> Block {member.header.number}</div>}
                                                    titleTypographyProps={{ variant: 'h6' }}
                                                />
                                                <CardContent>
                                                    <Typography className={classes.title} color="textSecondary" >
                                                        <b>Channel Name :</b> &nbsp;{member.channelInfo.channel_id}
                                                        <Chip
                                                            //label={DateTime.fromISO("2017-05-15T09:10:23-09:00").toRelative()}
                                                            label={member.channelInfo.timestamp}
                                                            style={{ float: "right" }}
                                                        />
                                                    </Typography>
                                                    <Typography noWrap={false} className={classes.title} color="textSecondary" >
                                                        <b>Data Hash : </b> &nbsp;{member.header.data_hash}
                                                    </Typography>
                                                    <Typography className={classes.title} color="textSecondary" >
                                                        <b>Number of Tx : </b>&nbsp;{member.numTransactions}
                                                    </Typography>

                                                </CardContent>
                                            </Card>
                                        </VerticalTimelineElement>
                                    );
                                })
                            ) : (props.blockHeight == 0 ?
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#fff', color: '#fff', boxShadow: 'none' }}
                                    contentArrowStyle={{ borderRight: '7px solid  #ff3333' }}
                                    iconStyle={{ background: "#ff3333", color: '#fff' }}
                                    icon={<ErrorIcon />}
                                >
                                    <Card variant="outlined">
                                        <CardHeader style={{ background: "#f8f8f8" }}
                                            title={<div> No Blocks available</div>}
                                            titleTypographyProps={{ variant: 'h6' }}
                                        />
                                    </Card>
                                </VerticalTimelineElement>

                                :

                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: '#fff', color: '#fff', boxShadow: 'none' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<StopIcon />}
                                >
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Skeleton height={10} />
                                            <Skeleton height={50} />
                                        </CardContent>
                                    </Card>
                                </VerticalTimelineElement>

                                )}

                        </VerticalTimeline>
                        <br></br>
                        {
                            blocksList != null && typeof blocksList != "string" && blocksList.length == props.blockHeight ?
                                ("") :
                                (
                                    <Button style={{ width: "100%" }} onClick={loadMoreBlocks()} color="primary">
                                        <RefreshIcon />{"Load more Blocks"}
                                    </Button>
                                )
                        }

                    </Paper>

                    {/*
                            <Timeline lineColor={'#ddd'}>
                            {blockInfo != null ? (
                                blockInfo.map((member) => {
                                    return (
                                        <TimelineItem
                                            dateComponent={""}
                                            //dateStyle={{ width: "0px" }}
                                            style={{ color: "#006db3" }}
                                        >
                                            <Card variant="outlined" className={classes.card} onClick={showTransactions(member.blockNo)}>
                                                <CardHeader style={{ background: "#006db3", color: "white", height: "5vh" }}
                                                    title={<div> <StopIcon style={{ verticalAlign: "middle" }} /> Block {member.blockNo}</div>}
                                                    titleTypographyProps={{ variant: 'h6' }}
                                                />
                                                <CardContent>
                                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                                        &nbsp;{member.blockTimestamp}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </TimelineItem>
                                    );
                                })
                            ) : (
                                    ""
                                )}
                            </Timeline> */}

                </Grid>
                {showTransactionsInfo === true ? (
                    <Transactions closeTransactionsPane={closeTransactionsPane} channelName={props.channelName} channelId={props.channelId} xsValue={xsValue} blockNo={selectedBlock} blockPage={true}
                    />
                ) : (
                        ""
                    )}
            </Grid>

        </div>
    );
}

