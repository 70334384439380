import React, { useState } from "react";

import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';
import useStyles from './styles/BlockSearchStyles';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ButtonGroup from '@material-ui/core/ButtonGroup';

import MenuItem from '@material-ui/core/MenuItem';
import channelService from "../../services/ChannelService";
import notify from '../../utils/notifier';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            paddingBottom: 0,
        },
    },
    classes: {
        list: {
            paddingTop: 0,
            paddingBottom: 0,
        },

    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
        paddingBottom: 0,
    },
    getContentAnchorEl: null

};



export default function BlockSearch(props) {

    const classes = useStyles();
    const [channels, setChannels] = React.useState([]);
    const [selectedChannel, setSelectedChannel] = React.useState('');
    const [blockNumber, setBlockNumber] = useState({ value: "" });
    const [blockDetails, setBlockDetails] = React.useState('');
    const [backdrop, setBackdrop] = React.useState(false);
    const [totalBlocks, setTotalblocks] = React.useState('');
    const [error, setError] = React.useState(false);
    const [latestTransactions, setLatestTransactions] = React.useState('');
    const fetchData = () => {
        setBackdrop(true);
        channelService.getChannelsByOrgId(parseInt(localStorage.getItem('_orgID'))).then(
            response => {
                setBackdrop(false);
                const _channelData = response.data;

                setChannels(_channelData);
                if (props.location.state) {
                    let ch = _channelData.findIndex(x => x.id === props.location.state.selectedChannel.id);
                    setSelectedChannel(_channelData[ch]);
                    fetchLatestTransactions(_channelData[ch].id, props.location.state.totalBlock);
                } else if (_channelData.length) {
                    let event = { target: { value: _channelData[0] } }
                    handleChannel(event);
                }
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching channels data by org ID"
                });
            }
        );

    };
    const handleChannel = (event) => {
        setSelectedChannel(event.target.value);
        setBackdrop(true);
        setError(false);
        let peer = JSON.parse(localStorage.getItem('_peers'))[0]["peerDomainCode"];
        channelService.getChannelInfo(event.target.value.id, peer).then(
            response => {
                const _data = response.data;
                if (_data.height) {
                    // setNumBlocks(_data.height);
                    setBlockNumber(prevState => {
                        return { ...prevState, value: _data.height - 1 };
                    });
                    setTotalblocks(_data.height - 1);
                    handleSearchBlockDetails(event.target.value.id, _data.height - 1);
                    fetchLatestTransactions(event.target.value.id, _data.height - 1);

                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching channel's info"
                });

            }
        );
    };
    const fetchLatestTransactions = (channelId, blockNumber) => {
        channelService.getBlocks(channelId, blockNumber, 10).then(
            response => {
                const _data = response.data;
                if (_data.length) {
                    _data.map(x => { x.channelInfo.timestamp = new Date(x.channelInfo.timestamp).toString(); });
                    setLatestTransactions(_data);
                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching latest blocks"
                });

            }
        );

    };
    const handleBlockNum = event => {
        setError(false);
        const _val = event.target.value;
        setBlockNumber(prevState => {
            return { ...prevState, value: _val };
        });
    }

    const clearFields = () => {
        setBlockNumber({ value: "" });
        setSelectedChannel('');
        setError(false);
    };

    const handleSearchBlockDetails = (channelId, blockNum) => {
        setBackdrop(true);
        channelService.getBlockByNum(channelId, blockNum, true).then(
            response => {
                if (response.data.header) {
                    setBlockDetails(response.data);

                }
                else {
                    setError(true);
                    setBlockDetails('');
                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching block details"
                });
            }
        );
    };
    const handleClickonRow = (event, row) => {
        var newState = { "channel": selectedChannel, "transactionId": row.trx_id, "blockNum": blockNumber, "totalBlocks": totalBlocks }
        props.history.push({ pathname: '/transaction/', state: newState });
    };

    const decrementBlockNumber = () => {
        let val = parseInt(blockNumber.value) - 1;
        setBlockNumber(prevState => {
            return { ...prevState, value: val };
        });
        handleSearchBlockDetails(selectedChannel.id, val);
    }

    const incrementBlockNumber = () => {
        let val = parseInt(blockNumber.value) + 1;
        setBlockNumber(prevState => {
            return { ...prevState, value: val };
        });
        handleSearchBlockDetails(selectedChannel.id, val);
    }

    const handleSetLatestBlockNumber = () => {
        setBlockNumber(prevState => {
            return { ...prevState, value: totalBlocks };
        });
        handleSearchBlockDetails(selectedChannel.id, totalBlocks);
    }
    const handleBlockLink = (blockNum) => {
        setBlockNumber(prevState => {
            return { ...prevState, value: blockNum };
        });
        handleSearchBlockDetails(selectedChannel.id, blockNum);
    }

    React.useEffect(() => {
        if (props.location.state) {
            fetchData();
            setTotalblocks(parseInt(props.location.state.totalBlock));
            setBlockNumber(prevState => {
                return { ...prevState, value: parseInt(props.location.state.blockNumber.value) };
            });
            handleSearchBlockDetails(props.location.state.selectedChannel.id, props.location.state.blockNumber.value);
        } else {
            fetchData();

        }
    }, []);

    return (
        <div className={classes.borderMain}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className={classes.root}>
                <Grid item xs={12} style={{ minHeight: "87vh" }}>
                    <div className={classes.tableTitleDiv} >
                        <div className={classes.tableTitle}>
                            <h2>{"Blocks"}</h2>
                        </div>
                    </div>
                    <div className={classes.shipmentInfopara}>
                        <div className={classes.paper_root}>
                            <div>
                            <FormControl className={classes.formControl} >
                                <TextField
                                    select
                                    value={selectedChannel}
                                    label={"Select Channel"}
                                    onChange={handleChannel}
                                    fullWidth
                                    variant="outlined"
                                    SelectProps={MenuProps}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                        }
                                    }}
                                >
                                    {channels.map((member) => (<MenuItem key={member.channelName} value={member}>{member.channelName}</MenuItem>))} </TextField>
                            </FormControl>
                            <FormControl>
                                <TextField
                                    variant="outlined"
                                    className={classes.inputBox}
                                    margin="normal"
                                    rows="2"
                                    autoComplete='off'
                                    onChange={handleBlockNum}
                                    value={blockNumber.value}
                                    label="Enter Block Number"
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelRoot,
                                            focused: classes.labelFocused
                                        }
                                    }}
                                />
                            </FormControl>
                            {error ?
                                <div className={classes.errorText} >Error while fetching block details, this block does not exist</div> : ""}
                            </div>
                        </div>
                        <div
                            style={{ display: 'flex', float: "right", marginRight: '20px' }}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.submitFilterButton}
                                onClick={() => handleSearchBlockDetails(selectedChannel.id, blockNumber.value)}
                                disabled={blockNumber.value === '' || selectedChannel === ''}
                            >
                                {"Search"}
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.submitFilterButton}
                                onClick={clearFields}
                                disabled={blockNumber.value === '' && selectedChannel === ''}
                            >
                                {"Clear"}
                            </Button>

                        </div>

                    </div>
                    {blockDetails == '' ? "" :
                        <div>
                            <div className={classes.shipmentInfopara} style={{ marginTop: '25px', paddingBottom: '20px' }}>
                                <h3 style={{ paddingLeft: '12px', margin: '2px' }}>Block Details</h3>

                                <div style={{ display: 'flex' }}>
                                    <div className={classes.detailsDiv1}>
                                        <span className={classes.detailsDivSpan}>
                                            <label className={classes.detailsLabel}>{"Block Number"}</label>

                                                <ButtonGroup size="small" color="primary" aria-label="small primary button group"  >
                                                <span className={classes.blockNumber}>
                                                    {blockNumber.value}
                                                </span>
                                                { 
                                                    <Button
                                                    disabled={(blockNumber.value <= 0) || totalBlocks < blockNumber.value}
                                                    onClick={() => {
                                                        decrementBlockNumber()
                                                    }}
                                                        style={{ border: 'none' }}
                                                    ><ChevronLeftIcon style={{ fontSize: '1.40rem' }} /></Button>}
                                                <Button
                                                    disabled={totalBlocks <= blockNumber.value}
                                                    onClick={() => { incrementBlockNumber() }}
                                                    style={{ border: 'none', float: 'right' }}
                                                ><ChevronRightIcon style={{ fontSize: '1.40rem', alignContent: 'right' }} /></Button>
                                                <Button
                                                    disabled={totalBlocks <= blockNumber.value}
                                                    onClick={() => { handleSetLatestBlockNumber() }}
                                                    style={{ border: 'none', float: 'right' }}
                                                >
                                                    <DoubleArrowIcon style={{ fontSize: '1.30rem', alignContent: 'right' }} />
                                                </Button>
                                            </ButtonGroup>
                                        </span>
                                        <span className={classes.detailsDivSpan} >
                                            <label className={classes.detailsLabel}>{"Channel"}</label>
                                            <span className={classes.detailsValue} >{selectedChannel.channelName}</span>
                                        </span>
                                    </div>
                                    <div className={classes.detailsDiv}>
                                        <span className={classes.detailsDivSpan} >
                                            <label className={classes.detailsLabel}>{"Block Hash"}</label>
                                            <span className={classes.detailsValue} >{blockDetails.header.data_hash}</span>
                                        </span>
                                        <span className={classes.detailsDivSpan} >
                                            <label className={classes.detailsLabel}>{"Block Size"}</label>
                                            <span className={classes.detailsNum} >{blockDetails.numTransactions}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.tableWrapper}>
                                <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                    <TableHead>
                                        <TableRow >
                                        <TableCell className={classes.tableColumn} >
                                                {"Id"}
                                            </TableCell>
                                            <TableCell className={classes.tableColumn} >
                                                {"Transaction Id"}
                                            </TableCell>
                                            <TableCell className={classes.tableColumn} >
                                                {"Nonce"}
                                            </TableCell>
                                            <TableCell className={classes.tableColumn} >
                                                {"Action"}
                                            </TableCell>
                                        </TableRow >
                                    </TableHead  >
                                    <TableBody>
                                        {blockDetails.transactions.map((x, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell className={classes.tableValue} >
                                                        {parseInt(index) + 1}
                                                    </TableCell>
                                                    <TableCell className={classes.tableValue}>
                                                        {x.trx_id ? x.trx_id : 'NA'}
                                                    </TableCell>
                                                    <TableCell className={classes.tableValue}>
                                                        {x.nonce}
                                                    </TableCell>
                                                    <TableCell className={classes.tableValue}>
                                                        <Button
                                                            onClick={event => handleClickonRow(event, x)}
                                                            disabled={x.trx_id ? false : true}>
                                                            {"view"}
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                        }
                                    </TableBody>
                                </Table>
                            </div>
                            <br></br>
                            {latestTransactions.length ?
                                <>
                                    <h3 className={classes.latestTransactionHeading}>Latest Blocks</h3>
                                    <div className={classes.tableWrapper}>

                                        <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell className={classes.tableColumn} >
                                                        {"Block Number"}
                                                    </TableCell>
                                                    <TableCell className={classes.tableColumn} >
                                                        {"Block Hash"}
                                                    </TableCell>
                                                    <TableCell className={classes.tableColumn} >
                                                        {"Block Timestamp"}
                                                    </TableCell>
                                                    <TableCell className={classes.tableColumn} >
                                                        {"Block Transaction"}
                                                    </TableCell>
                                                </TableRow >
                                            </TableHead  >
                                            <TableBody>
                                                {latestTransactions.map((x, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.tableValueLatestTransaction} >
                                                                <Button
                                                                    variant="text"
                                                                    color="primary"
                                                                    onClick={event => handleBlockLink(x.header.number)}>
                                                                    {x.header.number}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell className={classes.tableValueLatestTransaction}>
                                                                {x.header.data_hash ? x.header.data_hash : 'NA'}
                                                            </TableCell>
                                                            <TableCell className={classes.tableValueLatestTransaction}>
                                                                {`${x.channelInfo.timestamp.split('G')[0]}`}
                                                            </TableCell>
                                                            <TableCell className={classes.tableValueLatestTransaction}>

                                                                {x.numTransactions ? x.numTransactions : 'NA'}

                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })
                                                }
                                            </TableBody>
                                        </Table>
                                        <br></br>
                                    </div>
                                </> : ""}
                            <div>

                            </div>
                        </div>
                    }
                </Grid>
            </div>
        </div >
    )
}