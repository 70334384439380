export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const ONLY_NUMBERS_REGEX = /^[0-9\b]+$/;
export const NO_SPACE_REGEX = /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^\s]\1*$/;
export const NO_SPACE_AND_CAPS_REGEX = /^([a-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^A-Z\s]\1*$/;
export const NO_SPACE_AND_HYPHEN_REGEX = /^([A-z0-9!@#$%^&*().,<>{}[\]<>?=+\|;:\'\"\/])*[^-_\s]\1*$/;
export const LENGTH_BREADTH_HEIGHT = /^(\d+)\*(\d+)\*(\d+)$/;
export const APPLICATION_ID = 'blockchain';
export const VALID_USER_INPUT_REGEX = /^[ a-zA-Z0-9_,.-]*$/;
export const ALPHA_NUM_SMALLCAP_NO_SPACE = /^[a-z0-9-]*$/;
export const SHIPMENT_NUMBER_REGEX = /^SA-[a-zA-Z0-9*]{6}$/;
export const SHIPMENT_REF_NUM_VALID_USER_INPUT_REGEX = /^[ a-zA-Z0-9_,-\/]*$/;
export const PREVIOUS_PASSWORDS_TO_CHECK_NUM = 12
export const PASSWORD_VALIDITY_DAYS = 90
