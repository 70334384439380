
import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { useStyles } from "./styles/NetworkDetailsStyle";
import chaincodeService from '../../services/ChaincodeService';


import { VALID_USER_INPUT_REGEX } from "../../utils/constants";
import NetworkService from "../../services/NetworkService";
import organizationService from "../../services/OrganizationService";
import Drawer from '@material-ui/core/Drawer';

import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import notify from '../../utils/notifier';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { channelUseStyles } from "../channel/styles/ChannelStyles";
import networkService from "../../services/NetworkService";
import channelService from "../../services/ChannelService";
import CreateChannelSideDrawer from "../organizations/CreateChannelSideDrawer";
import InviteOrgsToNetworkSideDrawer from "../organizations/InviteOrgsToNetworkSideDrawer";
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockInfo from '../channel/BlockInfo';
import Skeleton from '@material-ui/lab/Skeleton';
import ChaincodeService from "../../services/ChaincodeService";

export const _channelColumns = [
  { id: "channelName", label: "Name", align: "center", minWidth: 170, sortable: true },
  { id: "channelDescription", label: "Description", align: "center", minWidth: 170, sortable: true },
  { id: "memberCount", label: "No. of Members", align: "center", minWidth: 170 },
];

export default function NetworkDetails(props) {


  const classes = useStyles();
  const [orgRows, setOrgRows] = React.useState([]);
  const [orgRowsPerPage, setOrgRowsPerPage] = React.useState(10);
  const [orgData, setOrgData] = React.useState();
  const [outsidePeerNames, setOutsidePeerNames] = React.useState([null])
  const [orgPage, setOrgPage] = React.useState(0);
  const [orgOrder, setOrgOrder] = React.useState("asc");
  const [orgOrderBy, setOrgOrderBy] = React.useState("organizationName");
  const [networkName, setNetworkName] = React.useState("");
  const [networkNameCopy, setNetworkNameCopy] = React.useState("");
  const [networkDescription, setNetworkDescription] = React.useState("");
  const [networkDescriptionCopy, setNetworkDescriptionCopy] = React.useState("");
  const [breadCrumbNetworkNameFocus, setBreadCrumbNetworkNameFocus] = React.useState(false);
  const [networkDescriptionFocus, setNetworkDescriptionFocus] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);


  const [networksArray, setNetworksArray] = React.useState([]);
  const channelClasses = channelUseStyles();
  const [channelRows, setChannelRows] = React.useState([]);
  const [channelOrgDetails, setChannelOrgDetails] = React.useState([]);


  const [popupOpen, setpopupOpen] = React.useState(false);
  const [chaincodePopup, setChaincodePopup] = React.useState(false);
  const [channelSearchKey, setChannelSearchKey] = useState({ value: "" });
  const [channelDrawerState, setChannelDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  }); //drawer


  const [invalidUserInput, setInvalidUserInput] = React.useState([]);
  const [successChangeEditNetworkValues, setSuccessChangeEditNetworkValues] = React.useState(false);

  const [editNetworkValues, setEditNetworkValues] = React.useState({
    networkName: "",
    networkDescription: "",
    networkId: ""
  });

  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStateInviteOrg, setDrawerStateInviteOrg] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const [values, setValues] = React.useState({
    orgName: "",
    hostCaPort: "",
    orgCaPort: "",
    orgDomainName: "",
    organizationDescription: "",
    peers: [],
    peerNames: [null],
    peerPorts: [null],
    users: null,
    couchDBUsername: "",
    couchDBPassword: "",
    orgCaUsername: "",
    orgCaPassword: "",
    members: 0,
    inviteOrgs: [],
    roles: []
  });

  const [channelValues, setChannelValues] = React.useState({
    channelName: "",
    channelCode: "",
    channelId: "",
    blockHeight: "",
  });

  const [showRemoveIcon, setShowRemoveIcon] = React.useState(false);
  const [fetchingChaincodeInstantiated, setFetchingChaincodeInstantiated] = React.useState(true);
  const [numBlocks, setNumBlocks] = React.useState(0);
  const [chaincodeData, setChaincodeData] = React.useState([]);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openOrgNetworkDialog, setOpenOrgNetworkDialog] = React.useState(false);
  const [openDialogforOrg, setOpenDialogforOrg] = React.useState(false);
  const [showCircleProgress, setShowCircleProgress] = React.useState('');
  const [dialogTextContent, setDialogTextContent] = React.useState("");
  const [selectedOrgToRemove, setSelectedOrgToRemove] = React.useState({
    id: null,
    organizationName: null
  });
  const [orgToAdd, setOrgToAdd] = React.useState({
    id: null,
    organizationName: null
  });
  const [selectedOrgToRemoveFromNetwork, setSelectedOrgToRemoveFromNetwork] = React.useState({
    id: null,
    organizationName: null
  });
  const [channelId, setChannelId] = React.useState('');
  const [isOrgLoading, setIsOrgLoading] = React.useState(false);
  const [chaincodeChannelName, setChaincodeChannelName] = React.useState('');

  const [channelName, setChannelName] = React.useState('');
  const [channelNameCopy, setChannelNameCopy] = React.useState('');
  const [channelNameFocus, setChannelNameFocus] = React.useState(false);

  const [editChannelIndex, setEditChannelIndex] = React.useState(null);
  const [updatingChannelName, setUpdatingChannelName] = React.useState(false);
  const [invitingOrg, setInvitingOrg] = React.useState(false);
  const [addingChannel, setAddingChannel] = React.useState(false);
  const [channelAdded, setChannelAdded] = React.useState(false);
  const [invitingMember, setInvitingMember] = React.useState(false);
  const [addingChannelName, setAddingChannelName] = React.useState('');
  const [endorsers, setEndorsers] = React.useState(null);
  const [chaincodeDetails, setChaincodeDetails] = React.useState({
    chaincodeName: null,
    version: null
  });
  const [highlightChaincode, setHighlightChaincode] = React.useState('');
  const [removingOrg, setRemovingOrg] = React.useState('');
  const setInitialState = () => {
    values.organizationDescription = "";
    values.orgName = ""
    values.orgDomainName = ""
    values.peers = []
    values.peerNames = [null]
    values.users = null;

    values.couchDBUsername = "";
    values.couchDBPassword = "";
    values.orgCaUsername = "";
    values.orgCaPassword = "";
    values.members = 0;
    values.inviteOrgs = [];
    values.roles = [];
    setOutsidePeerNames([null])
    editNetworkValues.networkName = ""
    editNetworkValues.networkDescription = ""
    editNetworkValues.networkId = ""
    setSelectedOrgToRemove({
      id: null,
      organizationName: null
    });
    setSelectedOrgToRemoveFromNetwork({
      id: null,
      organizationName: null
    });
    setShowCircleProgress('');
    setShowRemoveIcon('');
    setNumBlocks(0);
    setChaincodeData([]);
    setChaincodeChannelName('');
    setChannelValues({
      channelName: "",
      channelCode: "",
      channelId: "",
      blockHeight: "",
    });
    setChannelId('');
    setEndorsers(null);
    setChaincodeDetails({
      chaincodeName: null,
      version: null
    });
    setHighlightChaincode('');
    setRemovingOrg('');
  }

  const fetchNetworkData = () => {
    let query = {
      'peers': false,
      'organizations': false
    }
    networkService.getNetworkById(props.location.state.networkId, query).then(
      response => {
        let _data = response.data;
        setNetworkName(_data.networkName);
        setNetworkNameCopy(_data.networkName);
        setNetworkDescription(_data.networkDescription);
        setNetworkDescriptionCopy(_data.networkDescription);

      },
      error => {

        notify({
          type: "error",
          message: "Error while fetching network data"
        });
      }
    );
  }

  React.useEffect(() => {
    fetchOrganizationData();


    fetchChannelsData();
    if (props.location.state) {
      setNetworkName((successChangeEditNetworkValues ? editNetworkValues.networkName : props.location.state.networkName));
      setNetworkNameCopy((successChangeEditNetworkValues ? editNetworkValues.networkName : props.location.state.networkName));
      setNetworkDescription((successChangeEditNetworkValues ? editNetworkValues.networkDescription : props.location.state.networkDescription));
      setNetworkDescriptionCopy((successChangeEditNetworkValues ? editNetworkValues.networkDescription : props.location.state.networkDescription));
      fetchNetworkData();
    }
    return function cleanup() {
      setOrgData([]);
      setOrgRows([]);
    };
  }, [props.location.state]);


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };


  const handleBlocks = (row) => {
    let peer = JSON.parse(localStorage.getItem('_peers'))[0]["peerDomainCode"];
    setpopupOpen(true);
    channelService.getChannelInfo(row.id, peer).then(
      response => {
        const _data = response.data;
        if (_data.height) {
          setNumBlocks(_data.height);
          setChannelValues({
            channelName: row.channelName,
            channelCode: row.channelCode,
            channelId: row.id,
            blockHeight: _data.height,
          });
        }

      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching channel's info"
        });
      }
    );
  }
  const handlePopupClose = () => {
    setpopupOpen(false);
    setInitialState();
    setChaincodePopup(false);
  };
  const handleChainCodes = (row) => {
    setChaincodeChannelName(row.channelName)
    chaincodeService.getCommittedChaincodesByChannelId(row.id).then(
      response => {
        const _data = response.data;
        setChaincodeData(_data);
        setChannelId(row.id);
        setFetchingChaincodeInstantiated(false);
        if (_data.length)
          handleShowChaincodeDetails(row.id, _data[0].name);
      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching instantiated chaincode"
        });
        setFetchingChaincodeInstantiated(false);
      }

    );

    setChaincodePopup(true);
  }
  const handleShowChaincodeDetails = (channelId, chaincodeName) => {
    if (highlightChaincode != chaincodeName) {
      setHighlightChaincode(chaincodeName);
      setEndorsers(null);
      ChaincodeService.getEndorsementPolicyByChaincodeName(chaincodeName, channelId, true).then(
        response => {
          let _data = response.data;
          if (_data.length) {
            let _endorsers = _data[0].endorsementPolicy
            _endorsers = _endorsers.split('(')[1];
            _endorsers = _endorsers.split(')')[0];
            _endorsers = _endorsers.split(',');
            _endorsers = _endorsers.map(i => i.toUpperCase().slice(1, i.length - 1));
            _endorsers = _endorsers.map(i => i.split('.')[0].split('-').join(' '));
            _endorsers = _endorsers.map(i => i.slice(0, i.length - 3));
            setEndorsers(_endorsers);
            let version = _data[0].version;
            setChaincodeDetails({
              chaincodeName: chaincodeName,
              version: version,
            })
          } 
        },
        error => {
          notify({
            type: "error",
            message: "Error while fetching Policy"
          });
        }
      );
    }
  };
  const handleClickOpenDialog = (orgObj, channel) => {
    channelService.getEndorsementPoliciesOfOrgByChannelId(channel.id, orgObj.id).then(
      response => {
        let data = response.data;
        setSelectedOrgToRemove(orgObj);
        setChannelId(channel.id);
        if (data.length) {
          setDialogTextContent(`Cannot remove ${orgObj.organizationName} from ${channel.channelName}, ${orgObj.organizationName} is a member of following endorsement policies of chaincodes: ${data}`);
        } else {
          setDialogTextContent(`Do you really want to remove ${orgObj.organizationName} from ${channel.channelName}?`);
        }
        setOpenDialog(true);
      },
      error => {
        notify({
          type: "error",
          message: "Failed to invite the orgs to the channel"
        });
      }
    );
  };

  const handleAddOrgDialog = (orgObj, channel) => {
    setShowRemoveIcon('');
    setChannelId(channel.id);
    setOrgToAdd(orgObj);
    setDialogTextContent(`Do you really want to add ${orgObj.organizationName} to ${channel.channelName}?`);
    setOpenDialogforOrg(true);
  }
  const handleRemoveOrgFromChannel = () => {
    setOpenDialog(false);
    setShowCircleProgress(`${channelId}${selectedOrgToRemove.organizationName}`);
    channelService.removeOrgFromChannel(channelId, selectedOrgToRemove.id).then(
      response => {
        let message = response.data.message;
        fetchChannelsData();
        notify({
          type: "success",
          message: message
        });

      },
      error => {
        let errMsg = error.response.data.message;
        let temp = errMsg.split(" ");
        temp = temp.slice(1);
        errMsg = temp.join(" ");
        notify({
          type: "error",
          message: "Error: " + errMsg,
          autoClose: false
        });
        setShowCircleProgress('');
      }
    );
  }
  const handleAddOrgToChannel = () => {
    setOpenDialogforOrg(false);
    setShowCircleProgress(`${channelId}${orgToAdd.organizationName}`);
    let userOrgId = localStorage.getItem('_orgID');
    //Create channel call
    var apiReqObj = {};
    apiReqObj["organizationId"] = userOrgId;
    apiReqObj["invitedOrgs"] = [orgToAdd.id];
    channelService.inviteOrgsToChannel(apiReqObj, channelId).then(
      response => {
        fetchChannelsData();
        let message = "";
        message = "Successfully invited org to channel";
        notify({
          type: "success",
          message: message
        });
      },
      error => {
        notify({
          type: "error",
          message: "Failed to invite the orgs to the channel"
        });
        setShowCircleProgress('');
      }
    );
  }

  const handleOpenRemoveOrgDialog = (orgInfo) => {
    channelService.getChannelsInNetworkByOrganizationId(props.location.state.networkId, orgInfo.id).then(
      response => {
        let _data = response.data;
        let _dialogMsg = '';
        setSelectedOrgToRemoveFromNetwork(orgInfo);
        if (_data.length) {
          let _channelNames = _data.map(ch => ch.channelName);
          _dialogMsg = `Cannot remove ${orgInfo.organizationName} from network, ${orgInfo.organizationName} is a member of ${_channelNames}`
        } else {
          _dialogMsg = `Do you really want to remove ${orgInfo.organizationName} from network?`;
        }
        setDialogTextContent(_dialogMsg);

        setOpenOrgNetworkDialog(true);
      },
      error => {

        notify({
          type: "error",
          message: `Error while fetching channel list for ${orgInfo.organizationName}`
        });
      }
    );


  };

  const handleRemoveOrgFromNetwork = () => {
    setRemovingOrg(selectedOrgToRemoveFromNetwork.organizationName);
    handleCloseDialog();
    NetworkService.removeOrgFromNetwork(props.location.state.networkId, selectedOrgToRemoveFromNetwork.id).then(
      response => {

        let message = response.data.message;
        notify({
          type: "success",
          message: message
        });
        setInitialState();
        fetchOrganizationData();
      },
      error => {

        setInitialState();
        notify({
          type: "error",
          message: `Failed to remove ${selectedOrgToRemoveFromNetwork.organizationName} from network`,
          autoClose: false
        });
      }
    );
  };

  
  const handleSetDrawerState = (side, open, drawer) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateInviteOrg({ ...drawerStateInviteOrg, [side]: open });
    if (drawer === "close") {
      setInitialState();
    }
  }
  function handledrawerState(value) {
    // setDrawerStateInviteOrg({ ...drawerStateInviteOrg, right: false });
    setInitialState();
    setInvitingOrg(value);
  }

  const openInviteOrgDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateInviteOrg({ ...drawerStateInviteOrg, [side]: open });
  };


  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
    setInitialState();
  };

  const closeDrawerInviteOrg = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateInviteOrg({ ...drawerStateInviteOrg, [side]: open });
    setInitialState();
  };

  const closeChannelDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setChannelDrawerState({ ...channelDrawerState, [side]: open });
  };

  function handleChannelDrawer(value, channel, org) {
    setChannelDrawerState({ ...channelDrawerState, right: value });
    if (channel === 1)
      setAddingChannel(true);
    if (channel === 2) {
      setAddingChannel(false);
      setChannelAdded(true);
    }
    if (channel === 0) {
      setAddingChannel(false);
      setChannelAdded(false);
      setInvitingMember(false);
      setAddingChannelName('');
    }
    setInvitingMember(org);

  }
  function handleChannelName(value) {
    setAddingChannelName(value);
  }



  const fetchOrganizationData = (reload = true, pageSize = orgRowsPerPage, currentPage = 0, sortBy = "organizationName", sortOrder = orgOrder, searchText = "", filterArray = null) => {

    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder
    }

    if (searchText) {
      query['q'] = searchText;
    }

    if (drawerStateInviteOrg['right'] === true) {
      setDrawerStateInviteOrg({ ...drawerStateInviteOrg, ['right']: false });
    }



    if (props.location.state) {
      setIsOrgLoading(true);
      organizationService.getOrgsByNetwork(props.location.state.networkId, query).then(
        response => {
          let _data = response.data;
          _data.map(org => {
            org["peerCount"] = org["peers"].length;
            org["open"] = false
          })
          _data = _data.filter(x => x.organizationRole !== 'orderer');
          setOrgRows(_data);
          if (!searchText) {
            setOrgData(_data);
            }
          setIsOrgLoading(false);
          closeDrawer("right", false);
        },
        error => {
          setIsOrgLoading(false);
          if (searchText) {
            setOrgRows([]);
          }
          else {
            notify({
              type: "error",
              message: "Error while fetching organizations data by network"
            });
          }
        }
      );
    } else {
      if (filterArray && filterArray.length > 0) {
        query["_networkName"] = filterArray
      }
      organizationService.getOrgs(query).then(
        response => {
          let _data = response.data;
          let networksArrayTemp = []
          {
            _data.map(org => {
              org["peerCount"] = org["peers"].length;
              org["networks"].map(networkTemp => {
                if (!networksArrayTemp.includes(networkTemp["networkName"])) {
                  networksArrayTemp.push(networkTemp["networkName"])
                }
              })
              org["open"] = false
            })
          }
          if (networksArray.length === 0) {
            setNetworksArray(networksArrayTemp);
          }

          setOrgRows(_data);
          if (response.headers.hasOwnProperty('x-total-count')) {
            }
          if (!searchText) {
            setOrgData(_data);
           }


        },
        error => {
          notify({
            type: "error",
            message: "Error while fetching organizations data"
          });
        }
      );
    }

  };


  const buildRequestObjEditNetwork = (_networkName, _networkDescription) => {
    var formData = {};
    formData["networkName"] = _networkName;
    formData["networkDescription"] = _networkDescription;
    formData["userOrganizationId"] = localStorage.getItem("_orgID")
    formData["orgRole"] = localStorage.getItem("_orgRole")
    formData["userRole"] = localStorage.getItem("_userRole")
    return formData;
  };

  function handleSubmitEditNetwork(_networkName, _networkDescription) {
    closeDrawer('right', false);
    let requestObj = buildRequestObjEditNetwork(_networkName, _networkDescription);
    let statusCode = null;
    if (!requestObj) return;
    NetworkService.updateNetwork(props.location.state.networkId, requestObj).then(
      response => {
        statusCode = response.status;
        notify({
          type: "success",
          message: "Successfully updated network"
        });
        setSuccessChangeEditNetworkValues(true)
        setDrawerState({ ...drawerState, ["right"]: false });
        setInitialState();
        setNetworkName(_networkName);
        setNetworkNameCopy(_networkName);
        props.location.state.networkName = _networkName;
        setNetworkDescription(_networkDescription);
        setNetworkDescriptionCopy(_networkDescription);
        props.location.state.networkDescription = _networkDescription;
        setBreadCrumbNetworkNameFocus(false);
        setNetworkDescriptionFocus(false);
      },
      error => {
        notify({
          type: "error",
          message: "Error while updating the network"
        });
      }
    );
  };



  const fetchChannelsData = (pageSize = 10, currentPage = 0, sortBy = "channelName", sortOrder = 'asc', searchText = "") => {

    let userOrgId = localStorage.getItem('_orgID');
    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder
    }
    if (searchText) {
      query['q'] = searchText;
    }

    setChannelSearchKey({ value: searchText });

    setChannelDrawerState({ ...channelDrawerState, 'right': false });
    channelService.getChannelsByNetworkId(props.location.state.networkId, userOrgId, query).then(
      response => {
        const _data = response.data;
        setChannelRows(_data);
      },
      error => {

        if (searchText) {
          setChannelRows([]);
        }
        else {
          notify({
            type: "error",
            message: "Error while fetching channels data"
          });
        }
      }
    )
    channelService.getOrganizationsInChannelsByNetworkId(props.location.state.networkId).then(
      response => {
        let _data1 = response.data;
        setChannelOrgDetails(_data1);
        setInitialState();
      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching channel's info"
        });
      }
    );

  };


  const openChannelDrawer = (side, open, row) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setChannelDrawerState({ ...channelDrawerState, [side]: open });
  };



  const handleChangeNetworkName = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }

    setNetworkName(event.target.value);
    setBreadCrumbNetworkNameFocus(true);
  }

  const [textBreadCrumbs, setTextBreadCrumbs] = React.useState(classes.textBreadCrumbs);

  const handleNetworkNameSave = (event) => {
    if (!invalidUserInput.includes("networkName")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditNetwork(networkName, networkDescriptionCopy);
    }
  }

  const handleBreadCrumbUndo = () => {
    setNetworkName(networkNameCopy);
    setBreadCrumbNetworkNameFocus(false);
    setTextBreadCrumbs(classes.textBreadCrumbs);
  }

  const handleMouseEnterNetworkName = () => {
    setTextBreadCrumbs(classes.textBreadCrumbsOnHover);
  }

  const handleMouseLeaveNetworkName = () => {
    if (networkName === networkNameCopy) {
      setTextBreadCrumbs(classes.textBreadCrumbs);
    }
  }


  const handleChangeNetworkDescription = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }

    setNetworkDescription(event.target.value);
    setNetworkDescriptionFocus(true);
  }

  const [textNetworkDescription, setTextNetworkDescription] = React.useState(classes.textNetworkDescription);

  const handleNetworkDescriptionSave = (event) => {
    if (!invalidUserInput.includes("networkDescription")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditNetwork(networkNameCopy, networkDescription);
    }
  }

  const handleNetworkDescriptionUndo = () => {
    setNetworkDescription(networkDescriptionCopy);
    setNetworkDescriptionFocus(false);
    setTextNetworkDescription(classes.textNetworkDescription);
  }

  const handleMouseEnterNetworkDescription = () => {
    setTextNetworkDescription(classes.textNetworkDescriptionHover);
  }

  const handleMouseLeaveNetworkDescription = () => {
    if (networkDescription === networkDescriptionCopy) {
      setTextNetworkDescription(classes.textNetworkDescription);
    }
  }

  const validateFields = (...fields) => {
    let errorFields = [];
    fields.forEach(field => {
      if (!field) {
        errorFields.push(field);
      }
    });
    return errorFields;
  };


  const buildRequestObjEditChannel = (_channelName, _channelDescription) => {
    let invalidFields = [];
    invalidFields = validateFields(_channelName);

    if (invalidFields.length) {

      notify({
        type: "error",
        message:
          "Invalid Fields(" +
          invalidFields.length +
          "): Please provide valid input."
      });
      return;
    }
    var formData = {};
    formData["channelName"] = _channelName;
    formData["channelDescription"] = _channelDescription;
    formData["organizationId"] = localStorage.getItem("_orgID")
    formData["orgRole"] = localStorage.getItem("_orgRole")
    formData["userRole"] = localStorage.getItem("_userRole")
    return formData;
  };

  const handleSubmitEditChannel = (_channelName, row) => {
    setUpdatingChannelName(true);
    let requestObj = buildRequestObjEditChannel(_channelName, row.channelDescription);
    let statusCode = null;
    if (!requestObj) return;
    channelService.updateChannel(row.id, requestObj).then(
      response => {
        statusCode = response.status;
        notify({
          type: "success",
          message: "Successfully updated channel"
        });
        setInitialState();
        setEditChannelIndex(null);
        setChannelName(_channelName);
        setChannelNameCopy(_channelName);
        fetchChannelsData();
        setUpdatingChannelName(false);
      },
      error => {
        setUpdatingChannelName(false);
        setEditChannelIndex(null);
        notify({
          type: "error",
          message: "Error while updating channel information"
        });
      }
    );
  };


  const handleChangeChannelName = (index, prop) => (event) => {
    setEditChannelIndex(index);
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }
    setChannelName(event.target.value);
    setChannelNameFocus(true);
  }

  const [textBreadCrumbsInput, setTextBreadCrumbsInput] = React.useState(classes.textBreadCrumbsInput);

  const handleChannelNameSave = (row) => {
    if (!invalidUserInput.includes("channelName")) {
      handleSubmitEditChannel(channelName, row);
    }
    setChannelNameFocus(false);
  }

  const handleChannelNameUndo = () => {
    setEditChannelIndex(null);
    setChannelName(channelNameCopy);
    setChannelNameFocus(false);
    setTextBreadCrumbsInput(classes.textBreadCrumbsInput);
  }

  const handleMouseEnterChannelName = () => {
    setTextBreadCrumbsInput(classes.textBreadCrumbsInputChannelOnHover);
  }

  const handleMouseLeaveChannelName = () => {
    if (channelName === channelNameCopy) {
      setTextBreadCrumbsInput(classes.textBreadCrumbsInput);
    }
  }


  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenOrgNetworkDialog(false);
    setShowRemoveIcon('');
    setOpenDialogforOrg(false);
    // setDialogTextContent("");
  };


  return (
    <div className={classes.borderMain}>
      {
        props.location.state ? (
          <Paper style={{ minHeight: "87vh" }}>
            <div className={classes.navigation}>
              <Breadcrumbs aria-label="breadcrumb" className={classes.networkBreadCrumb}>
                <Link style={{ textDecoration: 'none' }} to={{ pathname: "/networks" }}>
                  <a color="primary" className={classes.a}>
                    Networks
                      </a>
                </Link>
                <div className={classes.textBreadCrumbDiv}>
                  {
                    ((localStorage.getItem('_userRole') == "SUPERADMIN") || (localStorage.getItem('_userRole') === "ADMIN" && localStorage.getItem('_orgRole') === "operator")) ?
                      <input
                        id="standard-basic"
                        className={textBreadCrumbs}
                        error={invalidUserInput.includes("networkName")}
                        margin="normal"
                        rows="2"
                        autoComplete='off'
                        value={networkName}
                        onMouseEnter={handleMouseEnterNetworkName}
                        onMouseLeave={handleMouseLeaveNetworkName}
                        onChange={handleChangeNetworkName("networkName")} />
                      :
                      <div>{networkName}</div>
                  }
                </div>
              </Breadcrumbs>
              <div className={(breadCrumbNetworkNameFocus && (networkName !== networkNameCopy)) ? classes.textBreadCrumbButtonsDiv : classes.textBreadCrumbButtonsDivNone}>
                <IconButton className={classes.breadCrumbButton} onClick={handleNetworkNameSave} disabled={invalidUserInput.includes("networkName")}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton className={classes.breadCrumbButton} onClick={handleBreadCrumbUndo}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>

            <div className={classes.networkInfo}>
              {
                ((localStorage.getItem('_userRole') === "SUPERADMIN") || (localStorage.getItem('_userRole') === "ADMIN" && localStorage.getItem('_orgRole') === "operator")) ?
                  <input
                    id="standard-basic"
                    className={textNetworkDescription}
                    error={invalidUserInput.includes("networkDescription")}
                    margin="normal"
                    rows="2"
                    autoComplete='off'
                    value={networkDescription}
                    onMouseEnter={handleMouseEnterNetworkDescription}
                    onMouseLeave={handleMouseLeaveNetworkDescription}
                    onChange={handleChangeNetworkDescription("networkDescription")} />
                  :
                  <div>{networkDescription}</div>
              }

              <div className={(networkDescriptionFocus && (networkDescription !== networkDescriptionCopy)) ? classes.descriptionButtonsDiv : classes.descriptionButtonsDivNone}>
                <IconButton className={classes.descriptionButton} onClick={handleNetworkDescriptionSave} disabled={invalidUserInput.includes("networkDescription")}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton className={classes.descriptionButton} onClick={handleNetworkDescriptionUndo}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
            {
              < div style={{ display: 'flex', float: 'right' }} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {invitingOrg ? <div className={classes.uploadDiv} >Inviting organization to {networkName} &nbsp;&nbsp;  <CircularProgress size={18} /></div> : addingChannel
                    ? <div className={classes.uploadDiv} >Adding {addingChannelName} to network &nbsp;&nbsp;  <CircularProgress size={18} /></div> : channelAdded ? <div className={classes.uploadDiv} >Channel added successfully &nbsp;&nbsp;  <CheckCircleIcon size={10} style={{ "color": "green", }} /></div> : " "}
                  {
                    invitingMember ? <div className={classes.uploadDiv} >Inviting members to channel &nbsp;&nbsp;  <CircularProgress size={18} /></div> : ""

                  }</div>
                <div className={classes.tableHeadRight} >
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={openInviteOrgDrawer("right", true)}
                    startIcon={<AddCircleIcon />}
                    disabled={(localStorage.getItem("_orgRole") !== "operator" && localStorage.getItem("_userRole") !== "SUPERADMIN") || (localStorage.getItem('_userRole') === "USER")}
                  >
                    {"Invite organizations to network"}
                  </Button>
                </div>

                <div className={classes.tableHeadRight}>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={openChannelDrawer("right", true)}
                    disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                    startIcon={<AddCircleIcon />}
                  >
                    {"Create a private communication channel"}
                  </Button>
                </div>
              </div>
            }
            <div className={classes.tableWrapper}>
              <Drawer
                anchor="right"
                open={drawerStateInviteOrg.right}
                onOpen={closeDrawerInviteOrg('right', true)}
                onClose={closeDrawerInviteOrg('right', false)}
              >
                <InviteOrgsToNetworkSideDrawer handleSetDrawerState={handleSetDrawerState} networkId={props.location.state.networkId} networkName={props.location.state.networkName}
                  orgData={orgData} fetchOrganizationData={fetchOrganizationData} orgRowsPerPage={orgRowsPerPage} orgPage={orgPage} orgOrderBy={orgOrderBy} orgOrder={orgOrder} onSubmitfunction={handledrawerState} />
              </Drawer>
              <Drawer
                anchor="right"
                open={channelDrawerState.right}
                onOpen={closeChannelDrawer('right', true)}
                onClose={() => { closeChannelDrawer('right', true) }}
              >
                <CreateChannelSideDrawer closeChannelDrawer={closeChannelDrawer} fetchChannelsData={fetchChannelsData} networkName={props.location.state.networkName} networkId={props.location.state.networkId}
                  handleChannelDrawer={handleChannelDrawer} handleChannelName={handleChannelName} />
              </Drawer>

              <Grid item xs={12}>
                <div className={classes.tableWrapper}>
                  <Paper className={classes.tableContainer}>
                    {isOrgLoading ?
                      <div>
                        <Typography >
                          &nbsp;
                          <Skeleton height={70} />
                          <Skeleton height={70} />
                          <Skeleton height={70} />
                        </Typography>
                      </div>
                      : orgRows.length === 0 ? <div align="center">No organizations are present, please add organization</div> :
                      <Table stickyHeader aria-label="sticky table"
                        className={classes.tableBorder}
                      >
                        <TableHead>
                          <TableRow >
                            <TableCell key={Math.random()}
                              className={classes.tableHeaderChannelName}
                            >
                              {"Channels"}&nbsp;&nbsp;&nbsp;
                              {updatingChannelName ? <CircularProgress size={18} /> : ""}
                            </TableCell>
                            <TableCell style={{ width: '50px' }} className={classes.tableHeader}>
                              {"No. of Members"}
                            </TableCell>
                            <TableCell
                              style={{ width: '60px' }}
                              className={classes.tableHeader}
                              key={Math.random()}
                              align={"center"}>
                              {"Channel Details"}
                            </TableCell>

                            {orgRows.map(column => (
                              <TableCell
                                key={column}
                                className={classes.tableHeader}
                              >
                                {column.organizationName}

                                <br />
                                <span className={classes.tableOrgRole}>*{column.organizationRole}</span>
                                <br />
                                {localStorage.getItem('_userRole') === "SUPERADMIN" ?
                                  removingOrg == column.organizationName ? <CircularProgress size={18} /> :
                                    <Tooltip title={<span style={{ fontSize: '12px' }}>Remove Organization from Network</span>} placement="right-end"><RemoveCircleIcon fontSize='small' onClick={() => handleOpenRemoveOrgDialog(column)} /></Tooltip> : ""}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {channelRows.length === 0 ?
                          <TableCell colSpan="10" align="center" className={channelClasses.noRecordsRow}>
                            {backdrop ? " Fetching the channels, please wait" : (!channelSearchKey.value ? "No channels found" : "No channels are present, please create a new channel")}
                          </TableCell>
                          :
                          <TableBody>
                            {channelRows.map((row, index) => {
                              return (
                                <TableRow hover style={{ cursor: "pointer", height: "50px" }} role="data-row" tabIndex={-1} key={index}  >


                                  <TableCell key={Math.random()} align={"center"} >
                                    <Typography>
                                      <div style={{ display: 'flex' }}>
                                        <Tooltip title={<span style={{ fontSize: '12px' }}>{row.channelDescription}</span>} placement="bottom" >
                                          <input
                                            id="standard-basic"
                                            className={editChannelIndex === index ? textBreadCrumbsInput : classes.textBreadCrumbsChannel}
                                            margin="normal"
                                            autoFocus={editChannelIndex === index}
                                            rows="2"
                                            fullWidth
                                            autoComplete='off'
                                            value={editChannelIndex === index ? channelName : row.channelName}
                                            onMouseEnter={handleMouseEnterChannelName}
                                            onMouseLeave={handleMouseLeaveChannelName}
                                            onChange={handleChangeChannelName(index, "channelName")}
                                          />
                                        </Tooltip>
                                        {
                                          (editChannelIndex === index && invalidUserInput.includes("channelName")) ?
                                            <p className={classes.invalidChanneNameValueText}>
                                              invalid character found
                                          </p>
                                            :
                                            editChannelIndex === index ? <div style={{ display: 'flex' }}
                                              className={(channelNameFocus && (channelName !== channelNameCopy)) ? classes.textBreadCrumbEditSaveButtonDiv : classes.textBreadCrumbEditSaveButtonDivNone}
                                            >
                                              <IconButton className={classes.breadCrumbButton} onClick={() => handleChannelNameSave(row)}>
                                                <DoneIcon fontSize="small" />
                                              </IconButton>
                                              <IconButton className={classes.breadCrumbButton} onClick={handleChannelNameUndo}>
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </div> : ""
                                        }</div>
                                    </Typography>
                                  </TableCell>

                                  {row.memberCount ? <TableCell align={"center"} >{row.memberCount}</TableCell> : <TableCell align={"center"} ></TableCell>}
                                  <TableCell align={"center"} >
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                      <Button variant="contained" color="primary"
                                        className={classes.channelButtons} onClick={() => handleBlocks(row)}>
                                        {"Blocks"}
                                      </Button>
                                      <Button variant="contained" color="primary"
                                        className={classes.channelButtons} onClick={() => handleBlocks(row)}
                                        onClick={() => handleChainCodes(row)}>
                                        {"Chaincodes"}
                                      </Button>
                                    </div>
                                  </TableCell>
                                  {channelOrgDetails.length === 0 ? "" : ((localStorage.getItem('_userRole') == "ADMIN" && localStorage.getItem('_orgID') == row.userOrgId) || (localStorage.getItem('_userRole') == "ADMIN" && !channelOrgDetails[row.id].includes(row.userOrgId))) ?
                                    orgRows.map(org => {
                                      return (
                                        <TableCell align={"center"} id={`${row.channelName}${org.id}`}>
                                          {channelOrgDetails[row.id].includes(org.id) ?
                                            showRemoveIcon === `${row.channelName}${org.id}` ?
                                              showCircleProgress === `${row.id}${org.organizationName}` ?
                                                <CircularProgress />
                                                : <Tooltip title="Remove Organization" placement="right">
                                                  <RemoveCircleIcon fontSize="medium" onClick={() => handleClickOpenDialog(org, row)} onMouseLeave={() => setShowRemoveIcon('')} />
                                                </Tooltip>
                                              : showCircleProgress === `${row.id}${org.organizationName}` ?
                                                <CircularProgress />
                                                : <CheckCircleIcon fontSize="medium" className={classes.iconStyle} onMouseEnter={() => setShowRemoveIcon(`${row.channelName}${org.id}`)}
                                                  onClick={() => handleClickOpenDialog(org, row)} onMouseLeave={() => setShowRemoveIcon('')} />
                                            : showCircleProgress === `${row.id}${org.organizationName}` ? <CircularProgress /> : <Tooltip title="Add Organization" placement="right">
                                              <AddIcon fontSize="medium" onClick={() => handleAddOrgDialog(org, row)} />
                                            </Tooltip>}
                                        </TableCell>
                                      );
                                    })
                                    :
                                    orgRows.map(org => {
                                      return (
                                        <TableCell align={"center"} id={`${row.channelName}${org.id}`}>
                                          {channelOrgDetails[row.id].includes(org.id) ? <CheckCircleIcon fontSize="medium" className={classes.iconStyle} /> :
                                            <AddIcon fontSize="medium" />
                                          }
                                        </TableCell>
                                      );
                                    })

                                  }
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        }

                      </Table>
                    }
                  </Paper>
                </div>
              </Grid>
            </div>
            <Dialog
              open={popupOpen}
              onClose={handlePopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'xl'}
            >
              <DialogTitle id="alert-dialog-title" width="50%">
                <div style={{ display: 'flex' }} >
                  <h2 style={{ width: '95%' }}>BLOCKS</h2>
                  {numBlocks ? <div className={classes.paperChannelBlockInfo}><h1 style={{ fontSize: "50px", lineHeight: "10px" }}>{numBlocks} </h1>blocks</div> :
                    " "}
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {channelValues.channelName ? <BlockInfo channelName={channelValues.channelName} channelCode={channelValues.channelCode} channelId={channelValues.channelId} blockHeight={channelValues.blockHeight} /> : ""}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handlePopupClose}
                  color="primary"
                  autoFocus
                  className={classes.dialogButton}
                >
                  CLOSE
      </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={chaincodePopup}
              onClose={handlePopupClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle id="alert-dialog-title" >
                <div style={{ fontWeight: 'bold' }}>Committed Chaincodes</div>
              </DialogTitle>
              <DialogContent style={{ display: 'flex' }}>
                <DialogContentText style={{ width: '50%' }}>
                  {chaincodeData != null && Object.keys(chaincodeData).length !== 0 && typeof chaincodeData === 'object' ? (
                    chaincodeData.map((member) => {
                      return (
                        <Grid item xs={9}>
                          {highlightChaincode === member.name ?
                            <Paper style={{ cursor: "pointer", background: "#f8f8f8" }} className={classes.paperText} variant="elevation"
                              onClick={() => handleShowChaincodeDetails(channelId, member.name)}
                            >{member.name}</Paper> :
                            <Paper style={{ cursor: "pointer" }} className={classes.paperText} variant="outlined"
                              onClick={() => handleShowChaincodeDetails(channelId, member.name)}
                            >{member.name}</Paper>}
                        </Grid>
                      );
                    })


                  ) : (
                      fetchingChaincodeInstantiated ? "" : <div style={{ paddingTop: "1em", width: '100vh' }}> No chaincodes committed on {chaincodeChannelName}</div>
                    )}
                </DialogContentText>
                <DialogContentText style={{ float: 'right' }}>
                  {chaincodeDetails.chaincodeName ?
                    <Card variant="outlined" style={{
                      borderRadius: 6,
                      boxShadow: '0 8px 16px 0 #f8f8f8',
                    }}>
                      <div className={classes.list}>
                        <div style={{
                          height: "60px",
                          width: '300px',
                          padding: '5px',
                          fontSize: '.5rem',
                          background: "#f8f8f8",
                        }} >
                          <Typography variant='subtitle1' noWrap >{chaincodeDetails.chaincodeName} v{chaincodeDetails.version} </Typography>
                          <Typography variant="subtitle1" noWrap>{"Endorsement Policy  "} </Typography>
                          <Divider></Divider>
                        </div>
                        <div
                          style={{
                            width: '300px',
                            // marginTop: '12px',
                            // background: "#f8f8f8", overflow: "auto"
                          }}
                        >
                          <Table className={classes.tablecc} aria-label="customized table">
                            <TableBody>
                              {endorsers != null && Object.keys(endorsers).length !== 0 && typeof endorsers === 'object' ? (
                                endorsers.map((endorser) => {
                                  return (
                                    <TableRow style={{ width: '300px', height: '40px' }} key="id">
                                      <TableCell align="center" ><b>{endorser}</b> </TableCell>
                                    </TableRow>
                                  );
                                })
                              ) : (
                                  ""
                                )}
                            </TableBody>
                          </Table>
                        </div >

                        <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </div> </Card> : ""}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handlePopupClose}
                  color="primary"
                  autoFocus
                  className={classes.dialogButton}
                >
                  CLOSE
      </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
                <Typography className={classes.dialogTitle} gutterBottom>
                  {`Removing ${selectedOrgToRemove.organizationName} from channel`}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogContentText}>
                  {`${dialogTextContent}`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {
                  dialogTextContent.includes('Cannot remove') ? "" :
                    <Button autoFocus className={classes.dialogButton} color="primary" onClick={handleRemoveOrgFromChannel}>
                      CONFIRM
                    </Button>
                }
                <Button autoFocus className={classes.dialogButton} color="primary" onClick={handleCloseDialog} >
                  {dialogTextContent.includes('Cannot remove') ? "CLOSE" : "CANCEL"}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDialogforOrg}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
                <Typography className={classes.dialogTitle} gutterBottom>
                  {`Adding ${orgToAdd.organizationName} to Channel`}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogContentText}>
                  {`${dialogTextContent}`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button className={classes.dialogButton} onClick={handleAddOrgToChannel} color="primary">
                  CONFIRM
            </Button>
                <Button className={classes.dialogButton} onClick={handleCloseDialog} color="primary" autoFocus>
                  CLOSE
          </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openOrgNetworkDialog}
              onClose={handleCloseDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle>
                <Typography className={classes.dialogTitle} gutterBottom>
                  {`Removing ${selectedOrgToRemoveFromNetwork.organizationName} from ${props.location.state.networkName}`}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogContentText}>
                  {`${dialogTextContent}`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {
                  dialogTextContent.includes('Cannot remove') ? "" :
                    <Button className={classes.dialogButton} onClick={handleRemoveOrgFromNetwork} color="primary">
                      CONFIRM
            </Button>
                }
                <Button className={classes.dialogButton} onClick={handleCloseDialog} color="primary" autoFocus>
                  {dialogTextContent.includes('Cannot remove') ? "CLOSE" : "CANCEL"}
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        ) : ""
      }
    </div >
  );
}

