import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles({
    list: {
        width: "30vw",
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
        overflowWrap: "break-word"
    },
    iconAlign: {
        verticalAlign: "middle"

    },
    sideBar: {
        width: "50vw",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    card: {
        minWidth: 275,
        boxShadow: "none"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    root: {
        width: "100%",
        background: "white"
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    tableHeadRight: {
        float: "right",
        display: "flex"
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    channelInfo: {
        padding: "10px",
        paddingLeft: "25px",
        paddingRight: "25px",
    },
    a: {
        fontWeight: "bold",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: '#E5E7E9'
        }
    },
    channelInfoPara: {
        //background: "#f8f8f8",
        padding: "28px",
    },
    paperChannelDescription: {
        padding: theme.spacing(6),
        color: theme.palette.text.secondary,
        textAlign: "left",
        height: "100%",
        fontSize: "16px",
        borderRadius: "0"

    },
    paperChannelBlockInfo: {
        padding: theme.spacing(3),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: "#f8f8f8",
        //overflowWrap: "break-word"
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        fontStyle: "normal"
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",
    },
    disableHoverIcon: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }
    },
    formControl: {
        marginTop: '10px',
        width: '100%',
        paddingRight: theme.spacing(4)
    },

    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#FFFFFF"
    },

    navigation: {
        display: "flex"
    },

    tabStyling: {
        paddingLeft: theme.spacing(4),
    },

    dividerStyling: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },

    multiselect: {

    },
    warningStyle: {
        background: "#FFF",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        width: "100%",
        marginRight: "80px",
    },

    sideDrawerInfoAlign: {
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        marginLeft: "-10px",
    },

    textBreadCrumbDiv: {
        // paddingBottom: theme.spacing(0.5)
    },
    textBreadCrumbEditSaveButtonDiv: {
        display: "flex",
        marginTop: theme.spacing(1),
    },
    textBreadCrumbEditSaveButtonDivNone: {
        display: "flex",
        marginTop: theme.spacing(1),
        display: "none"
    },
    breadCrumbButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    textBreadCrumbsInput: {
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "none"
    },
    textBreadCrumbsInputOnHover: {
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
    },
    channelDescriptionGrid: {
        display: "flex"
    },
    channelDescriptionEditButtonDiv: {
        display: "flex",
        marginBottom: theme.spacing(5),
    },
    channelDescriptionEditButtonDivNone: {
        display: "flex",
        marginBottom: theme.spacing(5),
        display: "none"
    },
    channelDescriptionInput: {
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "none"
    },
    channelDescriptionInputOnHover: {
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
    },
    removeButton: {
        marginTop: "33%"
    },
    invalidChanneNameValueText: {
        paddingTop: theme.spacing(0.5),
        color: "#FF0000",
        fontWeight: "600"
    },
    invalidChanneDescriptionValueText: {
        marginTop: theme.spacing(6.75),
        color: "#FF0000",
        fontWeight: "600"
    },
    orgNameAndRemoveDiv: {
        display: "flex"
    },
    removeOrgDiv: {
        marginLeft: theme.spacing(0.5),
        marginTop: -theme.spacing(0.5)
    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem"
    },
    dialogContentText: {
        fontWeight: "700"
    },
    dialogBotton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    }
});