import axios from "axios";

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

axios.defaults.withCredentials = true;
function login(emailId, password, isFreshLogin, validatePasswordExpiry) {

    const requestObj = JSON.stringify({
        userEmail: emailId,
        password: password,
        isFreshLogin: isFreshLogin,
        validatePasswordExpiry: validatePasswordExpiry
    });
    //TODO: uncomment the below lines once the api is ready
    return axios.post(BASE_API_URL + '/login', requestObj, {
        headers: {
            "Content-Type": "application/json",
            "Authorization":'Basic ' + btoa(emailId + ':' + password),
        }
    });
};


export default {
    login
};