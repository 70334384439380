import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../../themes/lightTheme';
import {
    Typography, FormControl, Button, Select, MenuItem, Divider, Input, TextField,
    Checkbox, ListItemText, IconButton, Backdrop, CircularProgress, InputLabel
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChaincodeService from '../../services/ChaincodeService';
import notify from '../../utils/notifier';
import ChannelService from '../../services/ChannelService';
import organizationService from '../../services/OrganizationService';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1
    },
    drawerHeader: {
        //padding: '5%'
        height: '64px',
        width: '100%',
        display: 'inline-flex'
    },
    title: {
        fontSize: '20px',
        fontFamily: 'Helvetica, sans-serif',
        paddingTop: '16px'
    },
    formBody: {
        marginTop: '8%',
        overflow: 'auto',
        maxHeight: '74vh'
    },
    fields: {
        paddingBottom: '5%',
        paddingLeft: lightTheme.spacing(2)
    },
    formControl: {
        marginTop: '10px',
        width: '100%'
    },
    peers: {
        marginLeft: '5%',
        width: '25%'
    },
    formActions: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        background: 'white'
    },
    cancelBtn: {

        float: "right",
        margin: lightTheme.spacing(2),

    },
    submitBtn: {

        float: "right",
        margin: lightTheme.spacing(2),
    },
    backdrop: {
        zIndex: lightTheme.zIndex.drawer + 1,
    },
    hidden: {
        display: "none",
    },
    flexItem: {
        flex: 1,
        paddingLeft: lightTheme.spacing(2),
        //marginTop: "-30px",

        width: "95%"
    },
    targetPeers: {

    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        marginLeft: "-10px",
    }

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function InstanstiateForm(props) {

    const classes = useStyles();

    const [newChannels, setNewChannels] = React.useState('');
    const [channels, setChannels] = React.useState([]);
    const [targets, setTargets] = React.useState([]);
    const [selectedPeers, setSelectedPeers] = React.useState([]);
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [selectedMembers, setselectedMembers] = React.useState([]);
    const [endorsementPolicy, setEndorsementPolicy] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [policies, setPolicies] = React.useState([]);
    const [selectedVersion, setSelectedVersion] = React.useState("");

    //Fetch chaincode information
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        //Fetch channels
        // TODO: once backed is working, DONOT construct peer
        const orgName = localStorage.getItem('_orgName');
        const orgId = localStorage.getItem('_orgID');
        ChannelService.getChannelsByOrgId(orgId).then(
            response => {
                let _data = response.data;
                //TODO: process columns....
                _data = _data.map((row) => ({ name: `${row.channelName} (${row.networkName})`, ...row }));
                setChannels(_data);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error occured while fetcing the data"
                });
            }
        );
    };

    const handleChange = event => {
        // let orgId = "";
        // let orgName = "";
        // let channelName = newChannels;
        if (newChannels === event.target.value) {
            setNewChannels(event.target.value);
            return;
        }
        // clear the target peers
        setSelectedPeers([]);
        setNewChannels(event.target.value);

        let channelId = event.target.value;

        ChannelService.getOrgNameMspIdByChannelId(channelId).then(
            response => {
                setOrgMembers(response.data);
                ChaincodeService.getEndorsementPolicies(response.data.length).then(
                    response => {
                        const _data = response.data;
                        setPolicies(_data);
                    },
                    error => {
                        notify({
                            type: "error",
                            message: "Error while fetching peers data"
                        });
                    }
                );
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching Org Name and mspId data"
                });
            }
        )

        let orgId = localStorage.getItem("_orgID");
        organizationService.getOrgsByOrgId(orgId).then(
            response => {
                const _data = response.data;
                setTargets(_data.peers);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching peers data"
                });
            }
        );
    };

    const handleTargets = (event) => {
        setSelectedPeers(event.target.value);
    }

    const handleMembers = (event) => {
        setselectedMembers(event.target.value);
    }

    const handlePolicyChange = (event) => {
        setEndorsementPolicy(event.target.value);
    }

    const handleSubmit = () => {

        // TODO: once backed is working, DONT construct peer
        const orgName = localStorage.getItem('_orgName');
        const nOf = endorsementPolicy + '-of';
        const _endorsementPolicy = {
            "identities": orgMembers.map(member => {
                return {
                    "role": { "name": "member", "mspId": member.mspId }
                }
            }),
            "policy": {}
        }
        _endorsementPolicy["policy"][nOf] = selectedMembers.map(member => {
            return { "signed-by": member.index }
        })


        const reqObj = { peers: selectedPeers, id: props.data.id, policy: _endorsementPolicy, version: props.data.version, channelId: newChannels };
        setOpen(true);
        ChaincodeService.instantiateChainCode(reqObj, orgName.toLowerCase()).then(
            response => {
                setOpen(false);
                notify({
                    type: 'success',
                    message: 'Instantiate Chaincode success!'
                });
                props.handleClose();
            },
            error => {
                setOpen(false);
                notify({
                    type: 'error',
                    message: 'Error occured while instantiating chaincode'
                });
            }
        );
    };

    return (
        <MuiThemeProvider theme={lightTheme}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={props.handleClose} style={{ background: 'none' }}>
                    <ChevronRightIcon color="primary" />
                </IconButton>
                <Typography className={classes.title}>Instantiate</Typography>
            </div>
            <Divider />
            <div className={classes.formBody}>
                <div className={classes.fields} style={{ display: 'block' }}>
                    <Typography><b>Chaincode Name : </b>{props.data.name}</Typography>
                </div>
                <div className={classes.fields} style={{ display: 'block' }}>
                    <Typography><b>Version : </b>{props.data.version}</Typography>
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        required
                        select
                        className={classes.textField}
                        label={"Channels"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={newChannels}
                        onChange={handleChange}
                    >
                        {channels.map(channel => (
                            <MenuItem key={channel.channelCode} value={channel.id}>
                                <ListItemText primary={channel.name} />
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {/* <div className={classes.flexItem}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Targets</InputLabel>
                        <Select
                            multiple
                            className={classes.targetPeers}
                            value={selectedPeers}
                            onChange={handleTargets}
                            input={<Input />}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip key={value.id} label={value.peerName} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                            disabled={!newChannels}
                        >
                            {targets.map(target => (
                                <MenuItem key={target.peerName} value={target}>
                                    <Checkbox checked={selectedPeers.some(_peer => _peer['id'] === target.id)} />
                                    <ListItemText primary={target.peerName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div> */}
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        required
                        select
                        className={classes.textField}
                        label={"Endorsement Policy"}
                        margin="normal"
                        required
                        fullWidth
                        value={endorsementPolicy}
                        onChange={handlePolicyChange}
                        disabled={!newChannels}
                    >
                        {policies.map(policy => {
                            return (<MenuItem key={policy.name} value={policy.nOf}>{policy.name}</MenuItem>);
                        })}
                    </TextField>
                </div>

                {
                    ((selectedMembers.length < endorsementPolicy) && selectedMembers.length != 0) ? (
                        <div className={classes.warningStyle}>
                            <h3>Warning : select atleast {endorsementPolicy} members</h3>
                        </div>
                    ) : (
                            ""
                        )
                }
                <div className={classes.flexItem}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Members *</InputLabel>
                        <Select
                            multiple
                            className={classes.targetPeers}
                            value={selectedMembers}
                            onChange={handleMembers}
                            input={<Input />}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip key={value.index} label={value.organizationName} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                            disabled={!newChannels}
                        >
                            {orgMembers.map(member => (
                                <MenuItem key={member.index} value={member}>
                                    <Checkbox checked={selectedMembers.some(_member => _member['organizationName'] === member.organizationName)} />
                                    <ListItemText primary={member.organizationName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className={(newChannels && endorsementPolicy !== null) ? "" : classes.hidden}><p><small>Any {endorsementPolicy} out of the selected members must endorse transactions</small></p></div>
                </div>
            </div>
            <div></div>
            <div className={classes.formActions}>
                <Divider />
                <Button
                    variant="contained"
                    className={classes.submitBtn}
                    color="primary"
                    onClick={handleSubmit}
                    disabled={newChannels && endorsementPolicy !== null && (selectedMembers.length >= endorsementPolicy) ? false : true}
                >
                    Submit
                </Button>
                <Button
                    variant="contained"
                    className={classes.cancelBtn} onClick={props.handleClose}>

                    Cancel
                </Button>

            </div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </MuiThemeProvider>

    );
}