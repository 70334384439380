import React, { useRef, useState } from "react";
import useStyles from '../components/documents/styles/DocumentStyles';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItemText, ListItemIcon, List, ListItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import notify from "../utils/notifier";
import shipmentService from "../services/ShipmentService";
import bookingService from "../services/BookingService";
import documentService from "../services/DocumentService";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Multiselect } from 'multiselect-react-dropdown';

export default function AttachDocumentToBookings(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [sharedBookingList, setSharedBookingList] = React.useState([]);
    const [nonSharedBookings, setNonSharedBookings] = React.useState([]);
    const [selectedBookingNumbers, setSelectedBookingNumbers] = React.useState([]);
    const [render, setRender] = React.useState(false);
    const [showComponent, setShowComponent] = React.useState(false);

    const multiselectRef = useRef();
    function resetValues() {
        multiselectRef.current.resetSelectedValues();
    }
    function renderAgain() {
        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }
    }

    function getBookingDropdownText(bookingInfo) {
        let _text = "";
        if (bookingInfo.bookingNumber) {
            _text += bookingInfo.bookingNumber;
        }
        if (bookingInfo.referenceNumber) {
            _text = _text + " (" + bookingInfo.referenceNumber + ")";
        }

        return _text;
    }
    React.useEffect(() => {
        bookingService.getBookingsByDocumentId(props.documentId).then(
            response => {
                let data = response.data;
                setSharedBookingList(data);
                let _sharedBookingNumbers = data.map(booking => booking.bookingNumber);

                let orgId = parseInt(localStorage.getItem('_orgID'));
                let query = {
                    '_limit': 50
                }

                bookingService.getBookingsSharedByOrgID(orgId, query).then(
                    response => {
                        let data = response.data[0];
                        let _nonSharedBookings = data.filter(booking => !_sharedBookingNumbers.includes(booking.bookingNumber));
                        _nonSharedBookings = _nonSharedBookings.map((row) => ({ name: getBookingDropdownText(row), ...row }));
                        setNonSharedBookings(_nonSharedBookings);
                        setShowComponent(true);
                    },
                    error => {
                        notify({
                            type: "error",
                            message: `Error while getting list of all bookings`
                        });
                    }
                )
            },
            error => {
                notify({
                    type: "error",
                    message: `Error while getting list of bookings where document : ${props.documentName} is uploaded`
                });
            }
        )
    }, []);


    function handleSearch(inputvalue) {

        if (inputvalue.length > 2) {
            bookingService.getBookingsByDocumentId(props.documentId).then(
                response => {
                    let data = response.data;
                    setSharedBookingList(data);
                    let _sharedBookingNumbers = data.map(booking => booking.bookingNumber);

                    let orgId = parseInt(localStorage.getItem('_orgID'));
                    let query = {
                        'q': inputvalue,
                        '_limit': 50
                    }

                    bookingService.getBookingsSharedByOrgID(orgId, query).then(
                        response => {
                            let data = response.data[0];
                            let _nonSharedBookings = data.filter(booking => !_sharedBookingNumbers.includes(booking.bookingNumber));
                            if (_nonSharedBookings.length) {
                                _nonSharedBookings = _nonSharedBookings.map((row) => ({ name: getBookingDropdownText(row), ...row }));
                                setNonSharedBookings(_nonSharedBookings);
                            }
                            setShowComponent(true);
                        },
                        error => {
                            notify({
                                type: "error",
                                message: `Error while getting list of all bookings`
                            });
                        }
                    )
                },
                error => {
                    notify({
                        type: "error",
                        message: `Error while getting list of bookings where document : ${props.documentName} is uploaded`
                    });
                }
            )
        }
    }

    const handleAttachBooking = (documentId) => {
        setBackdrop(true);

        let requestObj = {
            "bookingNumbers": selectedBookingNumbers
        }

        documentService.attachBookingsToDocument(documentId, requestObj).then(
            response => {
                setBackdrop(false);
                let _message = "Document attached successfully with new bookings";
                let _newAttachedBookings = nonSharedBookings.filter(booking => selectedBookingNumbers.includes(booking.bookingNumber));
                let _newNonSharedBookings = nonSharedBookings.filter(booking => !selectedBookingNumbers.includes(booking.bookingNumber));
                setSelectedBookingNumbers([]);
                setSharedBookingList([...sharedBookingList, ..._newAttachedBookings]);
                setNonSharedBookings(_newNonSharedBookings);
                if (_newNonSharedBookings.length > 0)
                    resetValues();
                renderAgain();
                notify({
                    type: "success",
                    message: _message
                });


            },
            error => {
                setBackdrop(false);
                let _message = "Error while attaching the document to newly added bookings";
                notify({
                    type: "error",
                    message: _message
                });
            }
        );

    }


    const handleChangeMultiSelectBookingNumber = prop => (newValue) => {
        let _newSelectedBookingNumbers = newValue.map(option => option.bookingNumber);
        setSelectedBookingNumbers(_newSelectedBookingNumbers);
    }


    return (

        showComponent ?

            <div className={classes.sharedShipments}>
                {
                    sharedBookingList && sharedBookingList.length === 0 ?
                        <Typography variant="h6" className={classes.memberListTitle}>
                            Document is not attached to any booking
                    </Typography>
                        :
                        <div>
                            <Typography variant="h6" className={classes.memberListTitle}>
                                Document is attached to following booking(s):
                    </Typography>
                            <List dense={true}>
                                {sharedBookingList.map((bookingInfo) => (
                                    <ListItem className={classes.memberListItem}>
                                        <ListItemIcon className={classes.memberListItemIcon}>
                                            <LocalShippingIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={getBookingDropdownText(bookingInfo)}
                                            className={classes.memberListItemText}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                }
                {
                    nonSharedBookings.length === 0 ?
                        <span className={classes.smallTextAddShipments}>
                            {
                                backdrop ? "Fetching bookings ..." : "*Document is shared with all the bookings."
                            }
                        </span>
                        :
                        <div className={classes.shipmentRefNumAutoCompleteTextFieldUpdateDoc}>
                            {
                                <Multiselect
                                    options={nonSharedBookings} // Options to display in the dropdown
                                    onSelect={handleChangeMultiSelectBookingNumber()}
                                    onRemove={handleChangeMultiSelectBookingNumber()}
                                    displayValue="name" // Property name to display in the dropdown options
                                    placeholder="Add more bookings"
                                    closeIcon="circle2"
                                    hidePlaceholder="true"
                                    onSearch={(inputvalue) => handleSearch(inputvalue)}
                                    ref={multiselectRef}
                                    style={{ searchBox: { border: "none", "border-bottom": "0.8px solid #00000021", "border-radius": "0px" }, chips: { background: "#e0e0e0", color: "#343a40", fontfamily: 'LatoLight' } }}
                                />
                            }
                            {
                                selectedBookingNumbers.length ?
                                    <div className={classes.addMemberButtonDiv}>
                                        <Tooltip className={classes.addDocButtonTooltip} title="Attach to selected booking(s)">
                                            <Button autoFocus className={classes.shareButton} color="primary"
                                                onClick={() => handleAttachBooking(props.documentId)}>
                                                {"Attach"}
                                            </Button>
                                        </Tooltip>
                                    </div> : ""
                            }
                        </div>
                }
                <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div> : <div></div>
    )
}