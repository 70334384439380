import Notify from "./notifier";
import { EMAIL_REGEX } from './constants';
const hasha = require('hasha');


const DOCUMENT_CONTENT_TYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const DOCUMENT_CONTENT_TYPE_DOC = 'application/msword';
const DOCUMENT_CONTENT_TYPE_PDF = 'application/pdf';
const DOCUMENT_CONTENT_TYPE_ZIP = 'application/zip';
const DOCUMENT_CONTENT_TYPE_IMAGE_JPG = 'image/jpeg';
const DOCUMENT_CONTENT_TYPE_IMAGE_PNG = 'image/png';
const DOCUMENT_CONTENT_TYPE_HTML = 'text/html';
const DOCUMENT_CONTENT_TYPE_HTML_STREAM= 'application/octet-stream';

const Util = {
  toJSONObj: str => {
    try {
      return JSON.parse(str);
    } catch (error) {
      Notify({
        type: "error",
        message: "Unable to convert to JSON Object"
      });
    }
  },
  toString: obj => {
    try {
      return JSON.stringify(obj);
    } catch (error) {
      Notify({
        type: "error",
        message: "Unable to convert to String"
      });
    }
  },
  filterByValue: (array, value) => {
    return array.filter(
      data =>
        JSON.stringify(data)
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
    );
  },
  desc: (_a, _b, orderBy) => {
    //Sort order: Special chars > numbers > case-insensitive alpha,
    const reg = /[0-9]+/g;
    let a = _a[orderBy];
    let b = _b[orderBy];
    a = a.toString().toLowerCase();
    b = b.toString().toLowerCase();
    let v0 = a.replace(reg, v => v.padStart(10, "0"));
    let v1 = b.replace(reg, v => v.padStart(10, "0"));
    return v0.localeCompare(v1);
  },
  stableSort: (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  },
  getSorting: (order, orderBy) => {
    return order === "asc"
      ? (a, b) => Util.desc(a, b, orderBy)
      : (a, b) => -Util.desc(a, b, orderBy);
  },

  objectHasValue: (obj, value) => {
    let _found = false;
    Object.keys(obj).forEach(key => {
      if (!_found) {
        let valStr = obj[key];
        valStr = valStr.toString().toLowerCase();
        _found = valStr.indexOf(value.toLowerCase()) !== -1 ? true : false;
      }
    });
    return _found;
  },
  searchByValue: (array, key) => {
    let result = array.filter(row => {
      return Util.objectHasValue(row, key);
    });
    return result;
  },

  objectColumnHasValue: (obj, columnName, value) => {
    return (value === obj[columnName]);
  },

  searchByColumn: (array, columnName, key) => {
    let result = array.filter(row => {
      return Util.objectColumnHasValue(row, columnName, key);
    });
    return result;
  },

  extractFileNameFromDisposition: disposition => {
    var filename = "";
    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  },
  copyOf: obj => {
    return Object.assign({}, obj);
  },

  validateEmail: email => {
    if (email === undefined || email === null) {
      return false;
    }
    return EMAIL_REGEX.test(email);
  },

  getMd5Hash: (obj) => {
    let calculatedHash = hasha(obj, { algorithm: 'md5' });
    return calculatedHash;
  },

  downloadTemplate: response => {

    let disposition = response.headers["content-disposition"];
    let filename = Util.extractFileNameFromDisposition(disposition);
    let type = response.headers["content-type"];
    if (type === 'application/octet-stream') {
        type = 'application/pdf';
    }
    // let fileExt;
    // if (type == DOCUMENT_CONTENT_TYPE_PDF) {
    //   fileExt = '.pdf';
    // } else if (type == DOCUMENT_CONTENT_TYPE_DOCX) {
    //   fileExt = '.docx';
    // } else if (type == DOCUMENT_CONTENT_TYPE_DOCX) {
    //   fileExt = '.doc';
    // } else if (type == DOCUMENT_CONTENT_TYPE_IMAGE_JPG) {
    //   fileExt = '.jpeg';
    // } else if (type == DOCUMENT_CONTENT_TYPE_IMAGE_PNG) {
    //   fileExt = '.png';
    // } else if (type == DOCUMENT_CONTENT_TYPE_HTML) {
    //   fileExt = '.html';
    // }

    //filename = "document" + fileExt;

    const blob = new Blob([response.data], {
      type: type,
      encoding: "UTF-8"
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  },

  buildQueryParams: (query, options= null) => {
    let queryParams = '';
    for(let key in query){
      queryParams += `&${key}=${query[key]}`;
    }

    for(let key in options){
      queryParams += `&${key}=${options[key]}`;
    }

    if(queryParams){
      queryParams = '?' + queryParams.slice(1);
    }

    return queryParams;
    },

    makeId: (length) => {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
   },

   getFileType: (contentType) => {

    let fileExt;
    if (contentType == DOCUMENT_CONTENT_TYPE_PDF) {
      fileExt = '.pdf';
    } else if (contentType == DOCUMENT_CONTENT_TYPE_DOCX) {
      fileExt = '.docx';
    } else if (contentType == DOCUMENT_CONTENT_TYPE_DOCX) {
      fileExt = '.doc';
    } else if (contentType == DOCUMENT_CONTENT_TYPE_IMAGE_JPG) {
      fileExt = '.jpeg';
    } else if (contentType == DOCUMENT_CONTENT_TYPE_IMAGE_PNG) {
      fileExt = '.png';
    // } else if (contentType == DOCUMENT_CONTENT_TYPE_HTML || contentType == DOCUMENT_CONTENT_TYPE_HTML_STREAM) {
    //   fileExt = '.html';
    // } 
    }else if (contentType == DOCUMENT_CONTENT_TYPE_DOC){
      fileExt = '.doc'
    }  else if (contentType == DOCUMENT_CONTENT_TYPE_ZIP){
      fileExt = '.zip'
    }

    return fileExt

  },
};
export default Util;
