import React from "react";
import dpWorldLogo from '../images/Cargochain.png';

import documentAuthenticity from '../images/documentAuthenticity.svg';
import immutableLogo from '../images/traceability.svg';
import smartContractLogo from '../images/automation.svg';


import ai from '../images/machinelearning.svg';
import iot from '../images/internet_of_things.svg';
import cryptography from '../images/cryptography-lock.svg';


import costEffectiveLogo from '../images/cost.svg';
import cloudAgnostic from '../images/clouds.svg';
import easeOfUse from '../images/ease.svg';
import scalability from '../images/scalable.svg';

import security from '../images/security.svg';
import performance from '../images/performance.svg';


import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";


export default function HomePage(props) {

  const styles = makeStyles(theme => ({

    container: {
      backgroundColor: "#f5f5f5",
      padding: "10px",
      marginTop: "40px"

    },

    containertest: {
      backgroundColor: "#f5f5f5",
      padding: "10px",
      marginTop: "40px"

    },

    containerTechnical: {
      padding: "10px",
      marginTop: "20px",
      backgroundColor: "white",

    },

    mainDiv: {
      textAlign: "center",
      marginTop: "5%",
    },

    mainLogo: {
      height: 100
    },

    infoGrid: {
      margin: "0 auto",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      ['@media only screen and (max-width:600px)']: {
        display: "block"
      }

    },

    infoGridTechnical: {
      margin: "0 auto",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      ['@media only screen and (max-width:600px)']: {
        display: "block"
      }

    },

    gridContents: {
      flex: " 0 0 30%",
      boxSizing: "border-box",
      padding: "10px 10px 0",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      ['@media only screen and (max-width:600px)']: {
        marginBottom: "15px"
      }
    },

    gridContentsTechnical: {
      flex: " 0 0 30%",
      boxSizing: "border-box",
      padding: "10px 10px 0",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      ['@media only screen and (max-width:600px)']: {
        marginBottom: "15px"
      }
    },

    cloudAgnosticDiv: {
      flex: " 0 0 30%",
      marginTop: "20px",
      boxSizing: "border-box",
      padding: "10px 10px 0",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-between",
      ['@media only screen and (max-width:600px)']: {
        marginBottom: "15px"
      }
    },

    gridContentTitle: {
      fontWeight: "500",
      fontSize: "1.6rem",
      margin: "2rem 0",
      lineHeight: "1.5"
    },

    gridContentDescription: {
      color: "#3a3a3a",
      fontSize: "1.2rem",
      lineHeight: "1.2"
    },

    gridContentDescriptionTechnical: {
      color: "#3a3a3a",
      fontSize: "1.2rem",
      lineHeight: "1.2"
    },

    gridLogos: {
      maxHeight: "5rem",
      maxWidth: "5rem",
      verticalAlign: "top",
    }

  }));

  const classes = styles();

  return (
    <div className={classes.mainDiv}>
      <img src={dpWorldLogo} alt='DPW' className={classes.mainLogo} />

      <div className={classes.container}>

        <h1 style={{ fontSize: "3em", color: "#298bc1" }}><b>Solution Focus</b></h1>

        <div className={classes.infoGrid}>

          <div className={classes.gridContents}>
            <img src={documentAuthenticity} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Document Authenticity</b></h2>
            <p className={classes.gridContentDescription}>
              Improving trust via realtime secure document sharing with permissioned access, and ensuring single source of truth globally
          </p>
          </div>

          <div className={classes.gridContents}>
            <img src={immutableLogo} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Improved Traceability</b></h2>
            <p className={classes.gridContentDescription}>
              Ensuring integral product traceability from origin to destination with immutable records on a shared ledger

          </p>
          </div>

          <div className={classes.gridContents}>
            <img src={smartContractLogo} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Operation Automation</b></h2>
            <p className={classes.gridContentDescription}>
              Streamlining and automating business processes via smart contracts resulting in increased efficiency and reduced costs
          </p>
          </div>

        </div>
      </div>


      <div className={classes.containerTechnical}>

        <h1 style={{ fontSize: "3em", color: "#298bc1" }}><b>Technical Focus</b></h1>
        <div className={classes.infoGrid}>

          <div className={classes.gridContents}>
            <img src={ai} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Machine Learning</b></h2>
            <p className={classes.gridContentDescription}>
              Our Blockchain can be used to decentralize federated learning algorithms so that the benefits of these collective machine learning models are shared across the multiple owners of data.
          </p>
          </div>

          <div className={classes.gridContents}>
            <img src={iot} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Internet Of Things</b></h2>
            <p className={classes.gridContentDescription}>
              IoT devices can send data for shared transactions with tamper-evident records. Business partners can access and supply IoT data without the need for central control and management.
          </p>
          </div>

          <div className={classes.gridContents}>
            <img src={cryptography} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Cryptography</b></h2>
            <p className={classes.gridContentDescription}>
              Enhance the speed, efficiency, security, and throughput of the blockchain using next generation multi-party computation (MPC) protocols for random beacons and aggregated signatures.
          </p>
          </div>

        </div>
      </div>

      <div className={classes.containertest}>

        <h1 style={{ fontSize: "3em", color: "#298bc1" }}><b>Our Advantages</b></h1>

        <div className={classes.infoGridTechnical}>
          <div className={classes.cloudAgnosticDiv}>
            <img src={cloudAgnostic} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Cloud Agnostic</b></h2>
            <p className={classes.gridContentDescriptionTechnical}>
              Members can participate from any cloud platform or on-premise infrastructure </p>
          </div>

          <div className={classes.gridContentsTechnical}>
            <img src={easeOfUse} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Ease Of Use </b></h2>
            <p className={classes.gridContentDescriptionTechnical}>
              Simple RESTful APIs as well as intuitive UI interfaces to access data  </p>
          </div>
          <br></br>

          <div className={classes.gridContentsTechnical}>
            <img src={costEffectiveLogo} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Cost Effective </b></h2>
            <p className={classes.gridContentDescriptionTechnical}>
              We use virtualization technologies to reduce the number of servers</p>
          </div>

        </div>

        <div className={classes.infoGridTechnical}>

          <div className={classes.gridContentsTechnical}>
            <img src={security} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Security </b></h2>
            <p className={classes.gridContentDescriptionTechnical}>
            Required controls are in place to secure the data at rest and in transit  </p>
          </div>
          
          <div className={classes.gridContentsTechnical}>
            <img src={scalability} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Highly Scalable</b></h2>
            <p className={classes.gridContentDescriptionTechnical}>
              Can easily scale up to support 10,000 transactions per second          </p>
          </div>

          <div className={classes.gridContentsTechnical}>
            <img src={performance} alt='DPW' className={classes.gridLogos} />
            <h2 className={classes.gridContentTitle} ><b>Performance </b></h2>
            <p className={classes.gridContentDescriptionTechnical}>
            We ensure that the transactions complete with minimum latency  </p>
          </div>
        </div>

      </div>
      {/* <h1 style={{ fontSize: "3em" }}><b>Technical Focus</b></h1> */}


      <br></br>


    </div >
  );

}