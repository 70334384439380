import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";



export const useStyles = makeStyles({
    list: {
        width: "90vw",
    },
    title: {
        paddingLeft: "1em",
    },
    sideBar: {
        width: "45vw",
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    iconAlign: {
        verticalAlign: "middle"

    },
    root: {
        width: "100%",
        background: "white"
    },
    cardWith: {
        //width: "60vw",
    },
    card: {
        cursor: "pointer"
    },
    typography: {
        padding: theme.spacing(2),
    },
    evenHeader: {
        background: "#F8F8F8"
    },
    h3padding: {
        paddingLeft: theme.spacing(2),
        marginBottom: "5px",
        marginTop: "5px",
        minHeight: "64px"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerIcon: {
        paddingLeft: "4%",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }

    },
    smallText: {
        fontSize: 'small',
        color: 'gray'
    },
    smallTextIcon: {
        fontSize: 'small',
        color: 'gray',
        marginBottom: "-2px"
    },
    boldText: {
        fontWeight: 'bold',
        color: 'black'
    },
    cardContent: {
        marginLeft: "- 13px",
        marginTop: "-13px",
        cursor: 'pointer',
    },
    noMargin: {
        margin: '0px'
    },


});