import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const channelUseStyles = makeStyles({
    list: {
        width: "30vw",
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    root: {
        background: "white",
        direction: "row",
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    tableHeadRight: {
        float: "right",
        display: "flex",
        marginRight: theme.spacing(4),
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    tableHeadRow: {
        fontWeight: "700"
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        //margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    button: {
        margin: theme.spacing(1)
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",
    },
    disableHoverIcon: {
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }
    },


    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    tableBorder: {
        border: "0.8px solid #00000021"
    },
    tableContainer: {
        padding: "20px"
    },
    searchBox: {
        borderBottom: "0.8px solid #00000021",
        marginRight: "20px"
    },
    multiselect: {
        
    },
    formControl: {
        marginTop: '10px',
        width: '100%',
        paddingRight: theme.spacing(4)
    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        marginLeft: "-10px",
    },
    networkInfo: {
        display: "block"
    },
    networkLabel: {
        justifyContent: "flex-start",
        fontSize: "0.75rem",
        color: "rgba(0, 0, 0, 0.54)"
    },
    networkText: {
        justifyContent: "flex-start",
        fontSize: "1rem"
    }

});