import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import theme from "../../themes/lightTheme";

import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { Card } from "@material-ui/core";
import Util from "../../utils/Util";
import { useStyles } from "./styles/OrganizationInformationStyles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Drawer from '@material-ui/core/Drawer';

import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import chaincodeService from '../../services/ChaincodeService'
import notify from "../../utils/notifier";
import organizationService from "../../services/OrganizationService";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NetworkService from '../../services/NetworkService';
import SearchOrgPeers from "./SearchOrgPeers";
import SearchNetworks from "./SearchNetworks";
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from "@material-ui/icons/Close";
import { VALID_USER_INPUT_REGEX } from "../../utils/constants";


//TODO: this columns to be fetched form api once api supports the same...
export const _columns = [
  { id: "peerName", label: "Peer Name", align: "center", minWidth: 170, sortable: true },
  { id: "peerPort", label: "Peer Port", align: "center", minWidth: 170, sortable: true },
  { id: "peerDomainName", label: "Peer Domain", align: "center", minWidth: 170, sortable: true },
];

//TODO: userPrefs should be fetched from user-session context
export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};


export default function OrganizationInformation(props) {

  const classes = useStyles();
  const [rows, setRows] = React.useState(props.location.state.row.peers);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [searchKey, setSearchKey] = useState({ value: "" });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("peerName");
  const [columns, setColumns] = React.useState(_columns);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [backdrop, setBackdrop] = React.useState(false);
  const [chaincodes, setChaincodes] = React.useState(null);
  const [queryCount, setQueryCount] = React.useState(props.location.state.row.peers.length);
  const [originalqueryCount, setOriginalQueryCount] = React.useState(props.location.state.row.peers.length);
  const [tab, setTab] = React.useState(0);
  const [networkData, setNetworkData] = React.useState([]);
  const [networkOriginalData, setNetworkOriginalData] = React.useState([]);
  const [networkSearchKey, setNetworkSearchKey] = useState({ value: "" });
  const [organizationDescription, setOrganizationDescription] = React.useState(props.location.state.row.organizationDescription);
  const [organizationDescriptionCopy, setOrganizationDescriptionCopy] = React.useState(props.location.state.row.organizationDescription);
  const [textBreadCrumbsOrganizationDescription, setTextBreadCrumbsOrganizationDescription] = React.useState(classes.textBreadCrumbsDesc);
  const [breadCrumbOrganizationDescriptionFocus, setBreadCrumbOrganizationDescriptionFocus] = React.useState(false);
  const [invalidUserInput, setInvalidUserInput] = React.useState([]);
  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const openDrawer = (side, open, peer) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setBackdrop(true);

    chaincodeService.getInstalledChaincodesByPeerId(peer).then(
      response => {
        let _data = response.data;
        setBackdrop(false);
        setChaincodes(_data);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching Policy"
        });
      }
    );

    setDrawerState({ ...drawerState, [side]: open });
  };

  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
  };


  const handleMouseEnterOrganizationDescription = () => {
    setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsOnHoverDesc);
  }

  const handleMouseLeaveOrganizationDescription = () => {
    if (organizationDescription === organizationDescriptionCopy) {
      setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsDesc);
    }
  }

  const handleChangeOrganizationDescription = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      setOrganizationDescription(event.target.value);
    }

    setBreadCrumbOrganizationDescriptionFocus(true);
  }

  const handleOrganizationDescriptionSave = () => {
    if (!invalidUserInput.includes("organizationDescription")) {
      handleSubmitEditOrganization(props.location.state.row.organizationName, organizationDescription, props.location.state.row.organizationDomainName);
    }
  }

  const handleBreadCrumbUndoOrganizationDescription = () => {
    setOrganizationDescription(organizationDescriptionCopy);
    setBreadCrumbOrganizationDescriptionFocus(false);
    setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsDesc);
  }

  const validateFields = (...fields) => {
    let errorFields = [];
    fields.forEach(field => {
      if (!field) {
        errorFields.push(field);
      }
    });
    return errorFields;
  };

  const buildRequestObjEditOrganization = (_organizationName, _organizationDescription, _organizationDomainName) => {
    let invalidFields = [];
    invalidFields = validateFields(_organizationName);

    if (invalidFields.length) {
      setBackdrop(false);
      notify({
        type: "error",
        message:
          "Invalid Fields(" +
          invalidFields.length +
          "): Please provide valid input."
      });
      return;
    }
    var formData = {};
    formData["organizationName"] = _organizationName;
    formData["organizationDescription"] = _organizationDescription;
    formData["organizationDomainName"] = _organizationDomainName;
    // formData["organizationId"] = editOrganizationValues.organizationId;
    formData["orgRole"] = localStorage.getItem("_orgRole")
    formData["userRole"] = localStorage.getItem("_userRole")
    return formData;
  };

  function handleSubmitEditOrganization(_organizationName, _organizationDescription, _organizationDomainName) {
    setBackdrop(true);
    let requestObj = buildRequestObjEditOrganization(_organizationName, _organizationDescription, _organizationDomainName);
    if (!requestObj) return;
    organizationService.updateOrganization(props.location.state.row.id, requestObj).then(
      response => {
        setTextBreadCrumbsOrganizationDescription(classes.textBreadCrumbsDesc);
        setOrganizationDescription(_organizationDescription);
        setOrganizationDescriptionCopy(_organizationDescription);
        notify({
          type: "success",
          message: "Successfully updated organization"
        });
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while Updating organization"
        });
      }
    );
  };


  let timeout = null;


  const handleSetInitialRow = () => {
    setRows(originalRows);
    setQueryCount(originalqueryCount);
  }

  const handleSetInitialNetworkRow = () => {
    setNetworkData(networkOriginalData);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(+event.target.value, 0);
  };

  const handleRequestSort = property => event => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    fetchData(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value);
  };

  /* TODO Change the view from JSON to table */
  const sideList = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Installed Chaincodes"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {chaincodes != null && Object.keys(chaincodes).length !== 0 && typeof chaincodes === 'object' ? (
            chaincodes.map((chaincode) => {
              return (
                <TableRow style={{ height: "60px" }} key="id">
                  <TableCell component="th" scope="row">
                    <b>{chaincode.name}</b>
                  </TableCell>
                  <TableCell align="left">Version : {chaincode.version} </TableCell>
                </TableRow>
              );
            })
          ) : (
              ""
            )}
        </TableBody>
      </Table>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </div>

  );

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    if (newValue === 0 && !rows.length) {
      fetchData();
    }
    if (newValue === 1 && !networkData.length) {
      fetchNetworkData();
    }
    setTab(newValue);
  };

  const fetchData = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = "") => {

    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder
    }
    if (searchText) {
      query['q'] = searchText;
    }
    setSearchKey({ value: searchText });

    setBackdrop(true);

    organizationService.getPeersByOrgId(props.location.state.row.id, query).then(
      response => {
        let _data = response.data;

        setRows(_data);
        if (response.headers.hasOwnProperty('x-total-count')) {
          setQueryCount(parseInt(response.headers['x-total-count']));
        }

        if (!searchText) {
          setOriginalRows(_data);
          setOriginalQueryCount(parseInt(response.headers['x-total-count']));
        }

        setBackdrop(false);
      },
      error => {

        setBackdrop(false);

        if (searchText) {
          setRows([]);
        }
        else {
          notify({
            type: "error",
            message: "Error: " + error.response.data.errorCode
          });
        }
      }
    );
  };

  const fetchNetworkData = (searchText = "") => {

    let query = {};
    if (searchText) {
      query['q'] = searchText;
    }
    setNetworkSearchKey({ value: searchText });

    setBackdrop(true);
    NetworkService.getNetworksByOrgID(props.location.state.row.id, query).then(response => {

      let _data = response.data;
      setNetworkData(_data);

      if (!searchText) {
        setNetworkOriginalData(_data);
      }
      setBackdrop(false);

    },
      error => {

        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching Networks"
        });

      });
  };

  React.useEffect(() => {
    if (tab == 0) {
      fetchData();
    }
    if (tab == 1) {
      fetchNetworkData();
    }
  }, []);


  return (
    <div className={classes.borderMain}>
      <Paper style={{ minHeight: "88vh" }}>
        <div className={classes.navigation}>
          {props.location.state.cameFrom === "Networks" ?
            <Breadcrumbs className={classes.orgInfo}>
              <Link style={{ textDecoration: 'none' }} to={{ pathname: "/networks" }}>
                <a color="primary" className={classes.a}>
                  Networks
                  </a>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={{ pathname: `/networks/${props.location.state.networkId}`, state: { "networkId": props.location.state.networkId, "networkName": props.location.state.networkName, "networkDescription": props.location.state.networkDescription } }}>
                <a color="primary" className={classes.a}>
                  {props.location.state.networkName}
                </a>
              </Link>
              <Link style={{ textDecoration: 'none' }} to={{ pathname: `/networks/${props.location.state.networkId}`, state: { "networkId": props.location.state.networkId, "networkName": props.location.state.networkName, "networkDescription": props.location.state.networkDescription, "tabValue": 0 } }}>
                <a color="primary" className={classes.a}>
                  Organizations
                  </a>
              </Link>
              <Typography>
                {props.location.state.row.organizationName}
              </Typography>
            </Breadcrumbs>
            :
            <Breadcrumbs aria-label="breadcrumb" className={classes.orgInfo}>
              <Link style={{ textDecoration: 'none' }} to={{ pathname: "/organizations" }}>
                <a color="primary" className={classes.a}>
                  Organizations
                </a>
              </Link>
              <Typography>
                {props.location.state.row.organizationName}
              </Typography>
            </Breadcrumbs>
          }
        </div>

        <div className={classes.orgDescriptionDiv}>
          {/* <div className={classes.orgDescription}>{props.location.state.row.organizationDescription}</div> */}
          <div className={classes.navigation}>
            <Breadcrumbs aria-label="breadcrumb" className={classes.organizationBreadCrumbDesc}>
              <div className={classes.textBreadCrumbDiv}>
                <input
                  id="standard-basic"
                  className={textBreadCrumbsOrganizationDescription}
                  error={invalidUserInput.includes("organizationDescription")}
                  margin="normal"
                  rows="2"
                  disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                  autoComplete='off'
                  value={organizationDescription}
                  onMouseEnter={handleMouseEnterOrganizationDescription}
                  onMouseLeave={handleMouseLeaveOrganizationDescription}
                  onChange={handleChangeOrganizationDescription("organizationDescription")} />
              </div>
            </Breadcrumbs>
            <div className={(breadCrumbOrganizationDescriptionFocus && (organizationDescription != organizationDescriptionCopy)) ? classes.textBreadCrumbButtonsDivDesc : classes.textBreadCrumbButtonsDivNone}>
              <IconButton className={classes.breadCrumbButton} onClick={handleOrganizationDescriptionSave}>
                <DoneIcon fontSize="small" />
              </IconButton>
              <IconButton className={classes.breadCrumbButton} onClick={handleBreadCrumbUndoOrganizationDescription}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <div>{props.location.state.row.organizationDomainName}</div>
        </div>
        <div className={classes.contentDiv}>
          {
            tab === 0 ?
              <div className={classes.tableHeadRight}>
                <SearchOrgPeers searchKey={searchKey} fetchData={fetchData} rowsPerPage={rowsPerPage} page={page} orderBy={orderBy} order={order} handleSetInitialRow={handleSetInitialRow} />
              </div>
              :
              tab === 1 ?
                <div className={classes.tableHeadRight}>
                  <SearchNetworks searchKey={networkSearchKey} fetchData={fetchNetworkData} handleSetInitialRow={handleSetInitialNetworkRow} />
                </div>
                :
                ""
          }
          <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" className={classes.tabsStyling}>
            <Tab label="Peers" {...a11yProps(0)} className={classes.tabStyling} />
            <Tab label="Participated Networks" {...a11yProps(1)} className={classes.tabStyling} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <Grid item xs={12} >
              <div className={classes.tableWrapper}>
                <Paper className={classes.tableContainer}>
                  <Table stickyHeader aria-label="sticky table" className={classes.tableBorder} >

                    <TableHead>
                      <TableRow>
                        {columns.map(column => (
                          <TableCell
                            key={column.id}
                            className={classes.tableHeadRow}
                            align={column.align}
                            sortDirection={orderBy === column.id ? order : false}
                          >
                            {column.sortable ? (
                              <TableSortLabel
                                active={orderBy === column.id}
                                direction={order}
                                className={classes.tableColumnLabel}
                                onClick={handleRequestSort(column.id)}
                              >
                                {column.label}
                                {orderBy === column.id ? (
                                  <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                      ? "sorted descending"
                                      : "sorted ascending"}
                                  </span>
                                ) : null}
                              </TableSortLabel>
                            ) : (
                                column.label
                              )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {rows.length === 0 ?
                      <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                        {backdrop ? " Fetching the peers, please wait" : "No Peers found"}
                      </TableCell>
                      :
                      <TableBody>
                        {rows.length === 0 ?
                          "" :
                          rows.map((row, index) => {
                            return (
                              <TableRow style={{ cursor: "pointer", height: "60px" }} hover role="data-row" tabIndex={-1} key={index} onClick={openDrawer("right", true, row.id)} >
                                {columns.map(column => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })
                        }

                      </TableBody>
                    }

                  </Table>

                </Paper>
              </div>
              {rows.length === 0 ?
                ""
                :
                <TablePagination
                  rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                  component="div"
                  count={queryCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    "aria-label": "previous page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "next page"
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={"Rows per page"}
                />
              }
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Grid item xs={12} >
              <div className={classes.paperCard}>

                {networkData != null && Object.keys(networkData).length ? (
                  networkData.map((network) => {
                    return (
                      <Card key={network.networkName} className={classes.card}>
                        <CardContent>
                          <CardHeader
                            title={network.networkName}
                          />
                          <Divider />
                          <CardContent>
                            <Typography variant="body1">
                              Description: {network.networkDescription}
                            </Typography>
                          </CardContent>
                        </CardContent>
                      </Card>
                    );
                  })
                ) : (
                    <div style={{ margin: "15px" }}>{backdrop ? "Fetching networks" : "No Networks"}</div>
                  )}

                <Drawer
                  anchor="right"
                  open={drawerState.right}
                  onOpen={closeDrawer('right', true)}
                  onClose={closeDrawer('right', true)}
                >
                  {sideList('right')}
                </Drawer>
              </div>
            </Grid>
          </TabPanel>
        </div>
        <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Drawer
          anchor="right"
          open={drawerState.right}
          onOpen={closeDrawer('right', true)}
          onClose={closeDrawer('right', true)}
        >
          {sideList('right')}
        </Drawer>

      </Paper>
    </div>
  );
}
