import React from "react";
import Button from '@material-ui/core/Button';
import notify from "../../utils/notifier";
import Backdrop from '@material-ui/core/Backdrop';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import { FormControl, Input, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import shipmentService from "../../services/ShipmentService";
import { VALID_USER_INPUT_REGEX } from "../../utils/constants";
import {
    disableSubmitButtonGenerateAnEvent,
    generateEvents,
    buildRequestObjGenerateEvent,
    MenuProps,
} from './ShipmentInformationUtils';
import useStyles from './styles/ShipmentInformationStyles';

export default function GenerateEvent(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [events, setEvents] = React.useState([])
    const [eventMap, setEventMap] = React.useState({})
    const [eventType, setEventType] = React.useState("");
    const [location, setLocation] = React.useState("");
    const [containerNumber, setContainerNumber] = React.useState("");
    const [descriptionFromSource, setDescriptionFromSource] = React.useState("");
    const [source, setSource] = React.useState("");
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [organizationID, setOrganisationID] = React.useState(localStorage.getItem('_orgID'));

    const setInitialState = () => {
        setEventType("");
        setLocation("");
        setContainerNumber("");
        setDescriptionFromSource("");
        setSource("");
    }

    const fetchData = () => {

        setBackdrop(true);
        shipmentService.getEvents().then(
            response => {
                const _data = response.data;
                let arr = []
                let dic = {}
                for (let i = 0; i < _data.length; i++) {
                    arr.push(_data[i]["name"])
                    dic[_data[i]["name"]] = [_data[i]["id"], _data[i]["code"]];
                }
                setEventMap(dic)
                setEvents(arr)
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching events"
                });
            }
        );

    }

    React.useEffect(() => {
        fetchData();
    }, []);


    const handleChange = prop => (event) => {

        let _value = event.target.value;

        if (!VALID_USER_INPUT_REGEX.test(_value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        if (prop == "eventType") {
            setEventType(_value);
        } else if (prop == "descriptionFromSource") {
            setDescriptionFromSource(_value);
        } else if (prop == "containerNumber") {
            setContainerNumber(_value);
        } else if (prop == "location") {
            setLocation(_value);
        } else if (prop == "source") {
            setSource(_value);
        }
    };

    const handleSubmitGenerateEvent = () => {
        let uploadValues = {
            "eventType" : eventType,
            "descriptionFromSource": descriptionFromSource,
            "location": location,
            "source": source,
            "containerNumber": containerNumber
        }
        let formDataGenerateEvent = buildRequestObjGenerateEvent(organizationID, eventMap, uploadValues);
        if (!formDataGenerateEvent) {
            return;
        }
        setBackdrop(true);
        shipmentService.generateEvent(props.shipmentID, formDataGenerateEvent).then(
            response => {
                props.refreshEvents();
                setInitialState();
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while creating an event"
                });
            }
        );
    }

    return (
        <div className={classes.eventSideList}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Generate an event"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div style={{ overflow: "auto", maxHeight: "85vh" }}>

                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '91%' }}>
                        <InputLabel id="demo-mutiple-name-label">Select an Event *</InputLabel>
                        <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            fullWidth
                            value={eventType}
                            onChange={handleChange("eventType")}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {generateEvents(events)}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '94%' }}>
                        <TextField
                            id="standard-basic"
                            className={classes.textField}
                            error={invalidUserInput.includes("descriptionFromSource")}
                            label={"Description"}
                            margin="normal"
                            rows="2"
                            fullWidth
                            value={descriptionFromSource}
                            onChange={handleChange("descriptionFromSource")}
                        />
                    </FormControl>
                </div>

                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '91%' }}>
                        <InputLabel id="demo-mutiple-name-label">Container Number *</InputLabel>
                        <Select
                            labelId="demo-mutiple-name-label"
                            id="demo-mutiple-name"
                            fullWidth
                            required
                            value={containerNumber}
                            onChange={handleChange("containerNumber")}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {props.containers != null && props.containers.length > 0 ? props.containers.map(container => (
                                <MenuItem value={container.containerNumber + "-" + container.containerId}>{container.containerNumber}</MenuItem>

                            )) : ""}
                        </Select>
                    </FormControl>
                </div>
                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '94%' }}>
                        <TextField
                            id="standard-basic"
                            className={classes.textField}
                            error={invalidUserInput.includes("location")}
                            label={"Location"}
                            margin="normal"
                            rows="2"
                            fullWidth
                            value={location}
                            onChange={handleChange("location")}
                        />
                    </FormControl>
                </div>
                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '94%' }}>
                        <TextField
                            id="standard-basic"
                            className={classes.textField}
                            error={invalidUserInput.includes("source")}
                            label={"Source"}
                            margin="normal"
                            rows="2"
                            fullWidth
                            value={source}
                            onChange={handleChange("source")}
                        />
                    </FormControl>
                </div>
            </div>

            <div class={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmitGenerateEvent} color="primary" variant="contained" disabled={backdrop || disableSubmitButtonGenerateAnEvent(eventType, containerNumber) || invalidUserInput.length}>
                    {"GENERATE"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={props.closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
}