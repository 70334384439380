import React, { useState } from "react";
import useStyles from '../components/documents/styles/DocumentStyles';
import Backdrop from '@material-ui/core/Backdrop';
import { APPLICATION_ID, VALID_USER_INPUT_REGEX, SHIPMENT_NUMBER_REGEX } from "../utils/constants";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, Input, InputLabel, Paper, Checkbox, ListItemText } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UploadFile from './UploadFile';
import FormRenderer from 'form-render-module'
import notify from "../utils/notifier";
import documentService from "../services/DocumentService";
import shipmentService from "../services/ShipmentService";
import channelService from "../services/ChannelService";
import Util from "../utils/Util";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from '@material-ui/icons/Warning';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import {
    fileUploadCriteria,
    MenuProps,
    createDocumentTypes,
    buildRequestObjUploadMultiDocument,
    disableSubmitButtonMultiUpload,
    availableTemplateTypes,
    documentChaincodeName
} from '../components/documents/DocumentsUtils'

export default function UploadDocuments(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [radioButtonValue, setRadioButtonValue] = React.useState('UploadADocument');
    const [shareingOption, setSharingOption] = React.useState('shareWithAll');
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [documentsByShipment, setDocumentsByShipment] = React.useState(props.fromPage === "shipmentInformation" ? props.documentList : []);

    const [documentTypeIDMap, setDocumentTypeIDMap] = React.useState(props.fromPage === "shipmentInformation" ? props.documentTypeIDMap : {});
    const [documentCodeIDMapRev, setdocumentCodeIDMapRev] = React.useState(props.fromPage === "shipmentInformation" ? props.documentCodeIDMapRev : {});
    const [documentTypesInfo, setDocumentTypesInfo] = React.useState(props.fromPage === "shipmentInformation" ? props.documentTypesInfo : []);
    const [documentArray, setDocumentArray] = React.useState([
        {
            "validationCheckId": Util.makeId(5),
            "documentName": "",
            "documentDescription": "",
            "documentCode": "",
            "documentType": props.uploadMissingDocTypeName ? props.uploadMissingDocTypeName : "",
            "documentJSON": {},
            "file": null,
            "duplicateFile": false
        }
    ]);
    const [render, setRender] = React.useState(false);
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [selectedPrivateMembers, setSelectedPrivateMembers] = React.useState([]);

    const [events, setEvents] = React.useState(props.fromPage === "shipmentInformation" ? props.events : []);

    const [allChannels, setAllChannels] = React.useState([]);
    const [selectedChannelId, setSelectedChannelId] = React.useState((props.uploadMissingDocType || props.fromPage === "shipmentInformation") ? props.channelId : "");

    const [shipmentsData, setShipmentsData] = React.useState([]);
    const [selectedShipmentIds, setSelectedShipmentIds] = React.useState( props.shipmentId ? [parseInt(props.shipmentId)] : []);
    const maxNumberOfDocUploadLimit = 5;

    function renderAgain() {
        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }
    }

    const handleChangeMultiSelectShipmentNumber = prop => (event, newValue) => {
        
        let _newSelectedShipmentIds = newValue.map(option => option.id);
        setSelectedShipmentIds(_newSelectedShipmentIds);
    }

    function checkUserInput(prop, value) {
        if (!VALID_USER_INPUT_REGEX.test(value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
    }

    function removeInvalidUserInputKey(key) {
        let index = invalidUserInput.indexOf(key);
        if (index > -1) {
            invalidUserInput.splice(index, 1)
            setInvalidUserInput(invalidUserInput);
        }
    }

    const handleChangeDocuments = (documentIndex, prop) => event => {

        let documentValidationKey = documentArray[documentIndex]['validationCheckId'] + "_" + prop;
        checkUserInput(documentValidationKey, event.target.value);

        documentArray[documentIndex][prop] = event.target.value;
        renderAgain();
    }


    const handleChangeRadioButton = event => {
        if (event.target.value === "MakeADocument" && props.uploadMissingDocType && !documentArray[0]['documentCode'] && !backdrop) {
            setBackdrop(true);
        }
        setRadioButtonValue(event.target.value);
    };

    const handleChangeSharingOption = event => {
        setSharingOption(event.target.value);

        let userOrgId = parseInt(localStorage.getItem('_orgID'));

        if (event.target.value === 'sharePrivately' && orgMembers.length == 0) {
            setBackdrop(true);
            channelService.getOrgNameMspIdByChannelId(selectedChannelId).then(
                response => {
                    const _data = response.data;
                    setOrgMembers(
                        _data.filter(member => member.orgId !== userOrgId)
                    );
                    setBackdrop(false);
                },
                error => {
                    setBackdrop(false);
                    notify({
                        type: "error",
                        message: "Error while fetching channel members list"
                    });
                }
            );
        }
    };

    function checkDuplicateFileUpload(_tempDocArray) {
        let fileNameSize = [];
        for (let index in _tempDocArray) {
            let doc = _tempDocArray[index];
            if (doc.file) {
                if (fileNameSize.includes(doc.file.name + "_" + String(doc.file.size))) {
                    _tempDocArray[index]['duplicateFile'] = true;
                } else {
                    fileNameSize.push(doc.file.name + "_" + String(doc.file.size));
                    _tempDocArray[index]['duplicateFile'] = false;
                }
            }
        }

        return _tempDocArray;
    }

    const handleFileUpload = (_file, documentIndex) => {
        if (_file) {
            if (fileUploadCriteria.validFileTypes.includes(_file.type) && _file.size < fileUploadCriteria.maxFileSizeLimit) {
                let _tempDocArray = [...documentArray];
                _tempDocArray[documentIndex]['file'] = _file;
                _tempDocArray = checkDuplicateFileUpload(_tempDocArray);

                setDocumentArray(_tempDocArray);
            } else {
                notify({
                    type: "error",
                    message: "File criteria is not matching"
                });
            }
        }
    }

    const handleClickDeleteFile = (documentIndex) => {
    
        let _tempDocArray = documentArray;
        _tempDocArray[documentIndex]['file'] = null;
        _tempDocArray[documentIndex]['duplicateFile'] = false;
        _tempDocArray = checkDuplicateFileUpload(_tempDocArray);

        setDocumentArray(_tempDocArray);
    }

    const handleChangeDocumentType = (documentIndex) => (event) => {
        documentArray[documentIndex]["documentType"] = event.target.value;
        documentArray[documentIndex]["documentCode"] = documentCodeIDMapRev[documentTypeIDMap[event.target.value]];
        renderAgain();
    }

    const handleChangeAttachShipment = event => {

        let orgId = parseInt(localStorage.getItem('_orgID'));

        let query = {};
        query['q'] = event.target.value;

        if (event.target.value.length >= 3) {
            shipmentService.getShipmentsSharedByOrgID(orgId, query).then(
                response => {
                    const _data = response.data;
                    setShipmentsData(_data[0]);
                },
                error => {
                    notify({
                        type: "error",
                        message: `Error while fetching shipment data`
                    });
                }
            )
        }
    }

    const handleSubmitUploadDocument = () => {

        let selectedPrivateMemberIds = [];
        if (shareingOption === "sharePrivately") {
            selectedPrivateMemberIds = selectedPrivateMembers.map(member => {
                return member.orgId;
            })
        } else if (shareingOption === "storePrivately") {
            selectedPrivateMemberIds = [parseInt(localStorage.getItem('_orgID'))];
        }

        let formDataUploadDocumentList = buildRequestObjUploadMultiDocument(documentArray,  selectedShipmentIds, documentTypeIDMap, radioButtonValue === "UploadADocument" ? "File" : "JSON", selectedPrivateMemberIds, selectedChannelId);

        if (!formDataUploadDocumentList) {
            return;
        }
        setBackdrop(true)
        documentService.uploadBulkDocuments(formDataUploadDocumentList).then(
            response => {
                props.refreshDocumentTable();
                let _message;
                let failedToUploadDocName = response.data.documentsFailedToUpload;
                if (failedToUploadDocName.length) {
                    _message = `Failed to upload following documents : ${JSON.stringify(failedToUploadDocName)}`;
                } else {
                    _message = documentArray.length === 1 ? "Document is uploaded successfully" : "Successfully uploaded all documents";
                }

                setBackdrop(false)
                notify({
                    type: failedToUploadDocName.length === 0 ? "success" : "error",
                    message: _message
                });
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while uploading the document"
                });
            }
        );
    };

    const obj = (arg, index) => {

        var json = Object.assign({}, arg)
        var temp = Object.assign(documentArray[index]['documentJSON'], json);
        if (Object.keys(json).length !== 0) {
            documentArray[index]['documentJSON'] = temp;
        }
        renderAgain();
    }

    function fetchDocumentTypes() {

        setBackdrop(true);

        shipmentService.getDocumentTypesByDestination().then(
            response => {
                const _data = response.data;
                let arr = []
                let dict = {}
                let dictRev = {}
                let dictMap = {}
                for (let i = 0; i < _data.length; i++) {
                    var singleData = _data[i];
                    arr.push(singleData["name"])
                    dict[singleData["name"]] = singleData["id"]
                    dictRev[singleData["id"]] = singleData["name"]
                    dictMap[singleData["id"]] = singleData["code"]
                }
                setDocumentTypeIDMap(dict);
                setdocumentCodeIDMapRev(dictMap);
                setDocumentTypesInfo(_data);
                if (props.uploadMissingDocTypeName) {
                    documentArray[0]['documentCode'] = dictMap[dict[props.uploadMissingDocTypeName]];
                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching Document types"
                });
            }
        );
    }

    React.useEffect(() => {

        // This call can be avoided while uploading missing document if documentCode is provide in props.
        fetchDocumentTypes();

        let orgId = parseInt(localStorage.getItem('_orgID'));

        if (props.uploadMissingDocType) {
            setSelectedShipmentIds([parseInt(props.shipmentId)])
        }

        if (props.fromPage === "documents") {
            setBackdrop(true);
            shipmentService.getShipmentsSharedByOrgID(orgId).then(
                response => {
                    const _data = response.data;
                    setShipmentsData(_data[0]);
                    setBackdrop(false);
                },
                error => {
                    setBackdrop(false);
                    notify({
                        type: "error",
                        message: `Error while fetching shipment data`
                    });
                }
            )
            
        }

        setBackdrop(true);
        channelService.getChannelsByInstantiatedChaincodeName(documentChaincodeName).then(
            response => {
                const _data = response.data;
                setAllChannels(_data);
                if (_data.length) {
                    setSelectedChannelId(_data[0]['id']);
                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching channels details"
                });
            }
        );

    }, []);


    const fileTypeMap = {
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/msword': '.doc',
        'application/pdf': '.pdf',
        'image/jpeg': '.jpeg',
        'image/png': '.png',
        //'text/html': '.html',
        'application/zip': '.zip'
    }

    let validFileTypesMsg = "Supported file types: ";
    let maxFileSizeLimitMsg = `Max file size: `;
    if (fileUploadCriteria) {
        for (let key in fileUploadCriteria.validFileTypes) {
            validFileTypesMsg += fileTypeMap[fileUploadCriteria.validFileTypes[key]] + ' '
        }
        let maxFileSizeLimitInMB = fileUploadCriteria.maxFileSizeLimit / Math.pow(10, 6);
        maxFileSizeLimitMsg += `${maxFileSizeLimitInMB} MB`;
    }

    const documentUploadPanel = () => (

        documentArray.length ?

            documentArray.length && documentArray.map((doc, documentIndex) => {
                return (
                    <div className={classes.docInfoDiv}>
                        <Paper className={classes.docInfoPaper}>
                            <div className={classes.docTypeNameDiv}>
                                <div className={classes.docTypeDiv}>
                                    <FormControl style={{ minWidth: '100%', paddingRight: '16px' }}>
                                        <InputLabel id="demo-mutiple-name-label">Document Type</InputLabel>
                                        {
                                            props.uploadMissingDocType
                                                ?
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    fullWidth
                                                    value={props.uploadMissingDocTypeName}
                                                    input={<Input />}
                                                    disabled
                                                    MenuProps={MenuProps}
                                                >
                                                    <MenuItem
                                                        key={props.uploadMissingDocTypeName}
                                                        value={props.uploadMissingDocTypeName}
                                                        disabled
                                                    >
                                                        {props.uploadMissingDocTypeName}
                                                    </MenuItem>
                                                </Select>
                                                :
                                                <Select
                                                    labelId="demo-mutiple-name-label"
                                                    id="demo-mutiple-name"
                                                    fullWidth
                                                    value={doc.documentType}
                                                    onChange={handleChangeDocumentType(documentIndex)}
                                                    input={<Input />}
                                                    MenuProps={MenuProps}
                                                >
                                                    {createDocumentTypes(documentTypesInfo, radioButtonValue, documentsByShipment, events)}
                                                </Select>

                                        }
                                    </FormControl>

                                </div>

                                <div className={classes.docNameDiv}>
                                    <TextField
                                        id="standard-basic"
                                        className={classes.docNameTextField}
                                        error={invalidUserInput.includes(doc.validationCheckId + "_" + "documentName")}
                                        label={"Document Reference"}
                                        margin="normal"
                                        rows="2"
                                        fullWidth
                                        value={doc['documentName']}
                                        onChange={handleChangeDocuments(documentIndex, "documentName")}
                                    />
                                </div>
                            </div>

                            <div className={classes.flexItem}>
                                <TextField
                                    id="standard-basic"
                                    className={classes.documentDescriptionTextField}
                                    error={invalidUserInput.includes(doc.validationCheckId + "_" + "documentDescription")}
                                    label={"Document Description"}
                                    margin="normal"
                                    rows="2"
                                    fullWidth
                                    value={doc['documentDescription']}
                                    onChange={handleChangeDocuments(documentIndex, "documentDescription")}
                                />
                            </div>

                            {
                                radioButtonValue === "UploadADocument" ?
                                    <div className={classes.flexItem}>
                                        <UploadFile key={documentIndex} onFileUpload={handleFileUpload} handleClickDeleteFile={handleClickDeleteFile} documentsDetails={documentArray} documentIndex={documentIndex}></UploadFile>
                                    </div> :
                                    availableTemplateTypes.includes(doc['documentCode']) ?
                                        <div className={classes.flexItem}>
                                            <div style={{ maxWidth: "1400px", maxHeight: "100%", marginRight: " 30px", zoom: '1.1', MozTransform: 'scale(1,1.1)', MozTransformOrigin: 'top' }}>
                                                <FormRenderer docName={doc['documentCode']} appId={APPLICATION_ID} parentCallback={obj} index={documentIndex} metadata={{}} filledData={{}} disable={false} />
                                            </div>
                                        </div>
                                        :
                                        <div className={classes.flexItem}>
                                            {
                                                backdrop ? <h2>Loading template</h2> : <h2>No template is available</h2>
                                            }
                                        </div>
                            }
                            {
                                props.uploadMissingDocType ? ""
                                    :
                                    <div className={classes.removeDocButtonDiv}>
                                        {
                                            doc['duplicateFile'] ? 
                                            <div className={classes.warningDiv}>
                                                <div className={classes.warningIconDiv}>
                                                    <WarningIcon className={classes.warningIcon}/> 
                                                </div>
                                                <span className={classes.warningText}>
                                                    Same file is already selected
                                                </span> 
                                            </div>
                                            : ""
                                        }
                                        <div className={ doc['duplicateFile'] ? classes.removeDocButtonTooltip : classes.buttonTooltip}>
                                            <Tooltip title="Remove document">
                                                <Button startIcon={<DeleteIcon color="secondary" />} className={classes.removeButton}
                                                    onClick={() => handleRemoveDocument(documentIndex)} />
                                            </Tooltip>
                                        </div>
                                    </div>
                            }
                        </Paper>
                        <Divider></Divider>
                    </div>
                )
            })

            :
            ""
    )

    const handleRemoveDocument = (documentIndex) => {
        let tempDocArray = [...documentArray];
        let docCheckId = documentArray[documentIndex]['validationCheckId'];
        tempDocArray.splice(documentIndex, 1);
        removeInvalidUserInputKey(docCheckId + "_" + "documentName");
        removeInvalidUserInputKey(docCheckId + "_" + "documentDescription");
        tempDocArray = checkDuplicateFileUpload(tempDocArray);
        setDocumentArray(tempDocArray);
        renderAgain();
    }


    const handleAddDocument = () => {
        let tempDocArray = [...documentArray];
        let docCheckId = Util.makeId(5);
        tempDocArray.push({
            "validationCheckId": docCheckId,
            "documentName": "",
            "documentDescription": "",
            "documentCode": "",
            "documentType": "",
            "documentJSON": {},
            "file": null
        });
        setDocumentArray(tempDocArray);
        renderAgain();
    }

    const handlePrivateMembers = prop => (event, newValue) => {
        setSelectedPrivateMembers(newValue);
    }

    function checkDisableSubmitButton() {
        return disableSubmitButtonMultiUpload(documentArray, radioButtonValue === "MakeADocument", selectedChannelId) || invalidUserInput.length
            || (shareingOption === "sharePrivately" && selectedPrivateMembers.length === 0) || ( props.fromPage === "documents" && selectedChannelId === "")
    }


    const handleChangeChannelValue = (event) => {
        
        setSelectedChannelId(event.target.value);

    };

    function getSelectedShipmentIndex(shipmentId) {
        for (let index in shipmentsData){
            if (shipmentsData[index]['id'] === shipmentId) {
                return index;
            }
        }
        return -1;
    }

    function getShipmentDropdownText (shipmentInfo) {
        let _text = "";
        if (shipmentInfo.shipmentNumber) {
            _text += shipmentInfo.shipmentNumber;
        }
        if (shipmentInfo.shipmentReference) {
            _text = _text + " (" + shipmentInfo.shipmentReference + ")";
        }

        return _text;
    }


    return (
        <div className={classes.list}>
            <div>
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Upload documents"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div className={classes.docFormDiv}>
                <div className={classes.flexItem}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="position" name="position" value={radioButtonValue} onChange={handleChangeRadioButton} row>
                            <FormControlLabel
                                value="UploadADocument"
                                control={<Radio color="primary" />}
                                label="Upload documents"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="MakeADocument"
                                control={<Radio color="primary" />}
                                label="Create documents from template"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>

                {
                    props.fromPage === "shipmentInformation" ?
                    <div className={classes.shipmentRefNumLabel}>
                        <div className={classes.flexItem}>
                            <span>
                                <b> Shipment Reference &nbsp;:&nbsp;</b> {props.shipmentReference ? props.shipmentReference : "NA"}
                            </span>

                        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                        <span className={classes.flexItem}>
                                <b>Shipment Number  &nbsp;:&nbsp;  </b>{props.shipmentNumber ? props.shipmentNumber : "NA"}
                            </span>
                        </div>
                    </div>
                    :
                    ""
                }

                <div className={classes.shipmentRefAndChannelDiv}>
                    
                    <FormControl className={classes.channelFormControl}>
                        <InputLabel id="channel-checkbox-label">Select channel</InputLabel>
                        <Select
                            labelId="channel-checkbox-label"
                            id="channel-checkbox"
                            value={selectedChannelId}
                            onChange={handleChangeChannelValue}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {
                                allChannels.map((channel) => (
                                    <MenuItem
                                        key={channel.id}
                                        value={channel.id}
                                    >
                                        {channel.channelName}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>Channels with req'd chaincode committed on it</FormHelperText>
                    </FormControl>
                    
                    {
                        props.fromPage === "documents" ? 
                        <div className={classes.shipmentRefNumAutoCompleteTextField}> 
                            <Autocomplete
                                multiple
                                id="tags-standard"
                                options={shipmentsData}
                                size="small"
                                defaultValue={props.shipmentId && shipmentsData.length && getSelectedShipmentIndex(props.shipmentId) !== -1 ? [shipmentsData[getSelectedShipmentIndex(props.shipmentId)]] : [] }
                                getOptionLabel={(option) => getShipmentDropdownText(option)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Attach with shipments"
                                        helperText={"Attach documents to selected shipments"}
                                        onChange={handleChangeAttachShipment}
                                    />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(getShipmentDropdownText(option), inputValue);
                                    const parts = parse(getShipmentDropdownText(option), matches);
                    
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontSize: "0.875rem" }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                onChange={handleChangeMultiSelectShipmentNumber()}
                            />
                        </div> : ""
                    }
                </div>

                <div className={classes.sharingOptionDiv}>
                    <FormControl className={classes.sharingOptionForm} component="fieldset">
                        <RadioGroup aria-label="position" name="position" value={shareingOption} onChange={handleChangeSharingOption} row>
                            <Tooltip className={classes.buttonTooltip} title="Documents will be shared with all current and future channel members.">
                                <FormControlLabel
                                    value="shareWithAll"
                                    control={<Radio color="primary" />}
                                    label="Share with all current & future channel members"
                                    labelPlacement="end"
                                    disabled={selectedChannelId.length === 0}
                                />
                            </Tooltip>
                            <Tooltip className={classes.buttonTooltip} title={`Documents will be stored privately on the ledger and can be accessible to ${localStorage.getItem('_orgName')} only`}>
                                <FormControlLabel
                                    value="storePrivately"
                                    control={<Radio color="primary" />}
                                    label="Store privately"
                                    labelPlacement="end"
                                    disabled={selectedChannelId.length === 0}
                                />
                            </Tooltip>
                            <Tooltip className={classes.buttonTooltip} title="Documents will be shared with seleceted channel members.">
                                <FormControlLabel
                                    value="sharePrivately"
                                    control={<Radio color="primary" />}
                                    label="Share with selected members"
                                    labelPlacement="end"
                                    disabled={selectedChannelId.length === 0}
                                />
                            </Tooltip>
                        </RadioGroup>
                    </FormControl>
                    {
                        shareingOption === 'sharePrivately' ?
                            <div className={classes.orgMemberDiv}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={orgMembers}
                                    size="small"
                                    getOptionLabel={(option) => option.organizationName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Share with"
                                        />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.organizationName, inputValue);
                                        const parts = parse(option.organizationName, matches);
                        
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontSize: "0.875rem" }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    onChange={handlePrivateMembers()}
                                />
                            </div> : ""
                    }
                </div>

                <div className={classes.flexItem}>
                    {documentUploadPanel()}
                    {
                        props.uploadMissingDocType ? "" :
                            documentArray.length >= maxNumberOfDocUploadLimit ? "" :
                                <div className={classes.formContainer}>
                                    <Tooltip className={classes.addDocButtonTooltip} title="Add Document">
                                        <Button autoFocus startIcon={<AddCircleIcon />} className={classes.addButton} color="primary"
                                            onClick={() => handleAddDocument()} />
                                    </Tooltip>
                                </div>
                    }
                </div>

            </div>

            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmitUploadDocument} color="primary" variant="contained" disabled={backdrop || checkDisableSubmitButton()}>
                    {"UPLOAD"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={props.closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )

}