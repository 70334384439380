import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles({
    list: {
        width: "30vw",
    },
    root_tabs: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    buttonDownload: {
        background: "none",
        padding: 0,
        fontSize: "68.75%",
        fontWeight: "bold"
    },
    buttonDownloadSmall: {
        minWidth: theme.spacing(2),
        background: "none",
        padding: theme.spacing(1),
        fontSize: "68.75%",
        fontWeight: "bold"
    },
    buttonText: {
        background: "none",
        padding: 0,
        fontSize: "100%",
        textTransform: 'none'
    },
    root: {
        width: "100%",
        background: "white"
    },
    treeViewDiv: {
        // paddingBottom: theme.spacing(1.5)
    },
    treeViewCollapsableTabs: {
        background: "#ffffff",
        border: "0.5px solid #00000021",
        fontWeight: "bold",
        borderRadius: "2px",
        // boxShadow: "2px 2px 10px -5px",
        height: theme.spacing(8)
    },
    listItemText: {
        fontWeight: "bold"
    },
    listItemTextPrimaryDiv: {
        display: "flex"
    },
    listItemTextPrimaryDivInfoIconDiv: {
        marginRight: "1vw",
        paddingTop: theme.spacing(3.5),
        backgroundColor: "black",
        color: "black",
        background: "black"
    },
    listItemTextPrimaryDivTypo: {
        width: "100%"
    },
    treeViewTableCellName: {
        border: "none",
        width: "15%"
    },
    treeViewTableCellDomain: {
        border: "none",
        width: "26%"
    },
    treeViewTableCellDescription: {
        border: "none",
        width: "26%"
    },
    treeViewTableCellPeerCount: {
        border: "none",
        width: "8%"
    },
    treeViewTableCellUserCount: {
        border: "none",
        width: "8%"
    },
    treeViewTableCellNetwork: {
        border: "none",
        width: "0%"
    },
    treeViewTableCellAction: {
        border: "none",
        width: "8.3%"
    },
    treeViewTableCellInfoDivName: {
        display: "block",
        fontWeight: "700"
    },
    treeViewTableCellInfoDivDomain: {
        display: "block"
    },
    treeViewTableCellInfoDivPeerCount: {
        display: "block"
    },
    treeViewTableCellInfoDivUserCount: {
        display: "block"
    },
    treeViewTableCellInfoDivNetwork: {
        display: "block"
    },
    treeViewTableCellInfoDivAction: {
        display: "block"
    },
    treeViewTableCellInfoDivDescription: {
        display: "block"
    },
    treeViewTableCellInfoLabelName: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabelDescription: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabelDomain: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabelPeerCount: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabelUserCount: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabelNetwork: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabelAction: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    treeViewTableCellInfoLabel: {
        fontSize: "0.8rem"
    },
    treeViewTableCellInfoDiv: {
        display: "block"
    },
    treeViewTableCellInfoValue: {
        fontWeight: "700"
    },
    treeViewTableCell: {
        border: "none",
        width: "8%"
    },
    tableWrapper: {
        width: "100%",
        maxHeight: "70vh",
        overflow: "auto",
        fontFamily: "courier"
    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        width: "100%",
        marginRight: "80px",
        marginTop: theme.spacing(7),
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    filterDiv: {
        display: "flex"
    },
    formControl: {
        paddingRight: "0.5rem",
        minWidth: 180,
        maxWidth: 180,
    },
    clearFilterButtonDiv: {
        minHeight: "5vh",
        paddingTop: "1rem"
    },
    tableHeadRight: {
        float: "right",
        marginRight: theme.spacing(3),
        display: "flex",
        paddingTop: theme.spacing(1),
    },
    tableHeadRow: {
        fontWeight: "700"
    },
    tableTitle: {
        float: "left",
        paddingLeft: "20px"
    },
    tableHeadAlignLeft: {
        marginLeft: theme.spacing(3.7)
    },
    tableColumnLabel: {
        marginLeft: theme.spacing(3),
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    searchBox: {
        // borderBottom: "0.8px solid #00000021",
        borderBottom: "0.8px solid #00000021",
        display: 'flex',
        height: theme.spacing(5),
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    button: {
        margin: theme.spacing(1)
    },
    closeButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    addButton: {
        fontFamily: "timesnewroman",
        margin: "0px 24px",
        textAlign: "center",
        display: "flex"
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    a: {
        fontWeight: "bold",
        "&:hover": {
            background: '#E5E7E9'
        }
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1)
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    button: {
        //margin: theme.spacing(2)
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",

    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    networkBreadCrumb: {
        padding: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    tableHead: {
        border: "0.8px solid #00000021"
    },
    tableBorder: {
        border: "0.8px solid #00000021"
    },
    tableContainer: {
        padding: "20px"
    },
    tableContainerOrg: {
        padding: "20px",
        paddingTop: "20px"
    },
    tabsStyling: {
        paddingLeft: theme.spacing(4)
    },
    tabStyling: {
        "&:hover": {
            background: '#E5E7E9'
        }

    },
    navigation: {
        display: "flex"
    },
    networkInfo: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
        height: theme.spacing(4.5),
        display: "flex"
    },
    textNetworkDescription: {
        // display: "inline-flex",
        // marginBottom: theme.spacing(2),
        border: "none",
        width: theme.spacing(50),
        height: theme.spacing(4.5),
    },
    textNetworkDescriptionHover: {
        // display: "inline-flex",
        // marginBottom: theme.spacing(2),
        height: theme.spacing(4.5),
        width: theme.spacing(50),
        paddingLeft: theme.spacing(1),
        border: "0.8px solid #00000021",
    },
    textBreadCrumbDiv: {
        display: "flex",
        marginTop: "4px"
    },
    textBreadCrumbs: {
        border: "none",
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3)
    },
    textBreadCrumbsOnHover: {
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(4.5),
        border: "0.8px solid #00000021",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(1),
    },
    textBreadCrumbButtonsDiv: {
        marginTop: theme.spacing(1),
        display: "flex",
        paddingBottom: theme.spacing(0.8),
        height: theme.spacing(4.2)
    },
    textBreadCrumbButtonsDivNone: {
        marginTop: theme.spacing(1),
        display: "none",
        paddingBottom: theme.spacing(0.8),
        height: theme.spacing(4.2)
    },
    textFieldBreadCrumbsNonEdit: {
        paddingBottom: theme.spacing(1),
    },
    textFieldBreadCrumbsEdit: {
        // marginTop: "10px",
        borderStyle: "groove",
        position: "relative"
    },
    descriptionButtonsDiv: {
        display: "flex",
        marginTop: -theme.spacing(0.5)
    },
    descriptionButtonsDivNone: {
        display: "none",
        marginTop: -theme.spacing(0.5)
    },
    breadCrumbButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    descriptionButton: {
        fontFamily: "timesnewroman",
        margin: theme.spacing(1),
        textAlign: "center",
        marginTop: "auto",
        display: "flex",
    },
    inviteNewOrgButton: {
        // margin: theme.spacing(1),
        float: "right"
    },
    deleteButton: {
        margin: theme.spacing(1),
        marginTop: "36%"
    },
    tableHeadAlign: {
        marginLeft: theme.spacing(7)
    },
    textBreadCrumbOrganizationName: {
        border: "none",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbsOrganizationNameDefault: {
        border: "none",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbsOnHoverOrganizationName: {
        border: "0.8px solid #00000021",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(2),
    },
    textBreadCrumbOrganizationDescription: {
        border: "none",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbsOrganizationDescriptionDefault: {
        border: "none",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
    },
    textBreadCrumbsOnHoverOrganizationDescription: {
        border: "0.8px solid #00000021",
        width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(2),
    },
    textBreadCrumbOrganizationDomainName: {
        border: "none",
        width: theme.spacing(30),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    textBreadCrumbsOrganizationDomainNameDefault: {
        border: "none",
        width: theme.spacing(30),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
    },
    textBreadCrumbsOnHoverOrganizationDomainName: {
        border: "0.8px solid #00000021",
        width: theme.spacing(30),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
    },
    textBreadCrumbPeerName: {
        border: "none",
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(2),
    },
    textBreadCrumbsPeerNameDefault: {
        border: "none",
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(2),
    },
    textBreadCrumbsPeerDomainNameDefault: {
        border: "none",
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
    },
    textBreadCrumbPeerDomainName: {
        border: "none",
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(1)
    },
    textBreadCrumbsOnHoverPeerName: {
        border: "0.8px solid #00000021",
        width: theme.spacing(30),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        // marginLeft: theme.spacing(2),
    },
    textBreadCrumbsOnHoverPeerDomainName: {
        border: "0.8px solid #00000021",
        width: theme.spacing(50),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
    },
    organizationBreadCrumbDesc: {
        paddingLeft: "36px",
        paddingRight: "20px",
        paddingTop: "20px",
        width: "100%",
        marginTop: "-1.5%"
    },
    textBreadCrumbDivDisplay: {
        display: "flex",
        // marginTop: "4px"
    },
    textBreadCrumbsDesc: {
        border: "none",
        width: theme.spacing(150),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3.5),
        marginLeft: theme.spacing(3.5)
    },
    textBreadCrumbsOnHoverDesc: {
        width: theme.spacing(150),
        marginBottom: theme.spacing(0.5),
        height: theme.spacing(3.5),
        border: "0.8px solid #00000021",
        // paddingRight: theme.spacing(2),
        marginLeft: theme.spacing(3.5),
    },
    treeViewTableCellNetworkValue: {
        border: "none",
        width: "14%"
    },
    treeViewTableCellNameValue: {
        border: "none",
        width: "1%"
    },
    treeViewTableCellDomainValue: {
        border: "none",
        width: "14%"
    },
    treeViewTableCellDescriptionValue: {
        border: "none",
        width: "14%"
    },
    treeViewTableCellPeerCountValue: {
        border: "none",
        width: "7%"
    },
    treeViewTableCellUserCountValue: {
        border: "none",
        width: "6%"
    },
    expandIcon: {
        border: "none",
        // width: theme.spacing(25),
        // marginBottom: theme.spacing(0.5),
        height: theme.spacing(3),
        marginLeft: theme.spacing(2),
        display: "table-cell"
    },
    clientToken: {
        fontSize: "0.9rem",
        fontWeight: "700"
    },
    clientTokenLabel: {

    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem"
    },
    dialogContentText: {
        fontWeight: "700"
    },
    dialogButton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    },
    docFormDiv: {
        display: "block",
        overflow: "auto",
        maxHeight: "80vh"
    },
    shipmentRefAndChannelDiv: {
        justifyContent: "space-between",
        display: "block",
        padding: '0px 30px 8px',
    },
    channelFormControl: {
        width: "100%",
        marginTop: theme.spacing(3)
    },
    shipmentRefNumAutoCompleteTextField: {
        marginTop: theme.spacing(3),
        width: "100%"
    },
    sharedShipmentsUpdateDoc: {

    },
    memberListTitle: {
        fontSize: "0.875rem",
        fontWeight: "700",
        textAlign: 'left'
    },
    memberListItem: {

    },
    memberListItemIcon: {
        color: "#000000",
        display: 'inline-flex',
        minWidth: theme.spacing(4)
    },
    memberListItemText: {
        fontSize: "0.875rem"
    },
    assignedCommercialRoleOfOrg: {
        marginTop: theme.spacing(3)
    },
    uploadDiv: {
        fontWeight: '600',
        fontSize: '0.9rem',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(10),
        alignItems: "center",
        float: "left",
    },
});
