import axios from "axios";
import authUtil from '../utils/authUtil';
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

// Add token in all headers
// axios.defaults.headers.common['X-DPW-User-Token'] = authUtil.getToken();
const TOKEN_HEADER = 'x-dpw-user-token'

axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
    config.headers['x-dpw-apikey'] = `${process.env.REACT_APP_BLOCKCHAIN_API_KEY}`;
    config.headers.common[TOKEN_HEADER] = authUtil.getToken();
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    if (response.headers && response.headers[TOKEN_HEADER]) {
        authUtil.updateToken(response.headers[TOKEN_HEADER]);
    }
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        window.location.href = '/login'
    } else {
        return Promise.reject(error);;
    }
});

function getUsers(query) {
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/users` + queryParams, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function getUsersByOrgId(organizationId, query) {
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/users/organizations/` + organizationId + queryParams, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function logout() {
    return axios.get(BASE_API_URL + `/logout`);
}

function addUser(username, email, orgId, firstName, lastName, userRole, requesterUserName, requesterUserOrgId, requesterUserRole, currentUserRole) {
    var requestObj = {};
    requestObj["username"] = username;
    requestObj["email"] = email;
    requestObj["orgId"] = orgId;
    requestObj["firstName"] = firstName;
    requestObj["lastName"] = lastName;
    requestObj["userRole"] = userRole;
    requestObj["requesterUserName"] = requesterUserName;
    requestObj["requesterUserOrgId"] = requesterUserOrgId;
    requestObj["requesterUserRole"] = requesterUserRole;
    requestObj["currentUserRole"] = currentUserRole;
    return axios.post(BASE_API_URL + `/registerUser`, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function enrollUser(userName, password, organizationId) {
    let requestObj = {};
    requestObj["username"] = userName;
    requestObj["password"] = password;
    requestObj["organizationId"] = parseInt(organizationId);
    //requestObj["orgName"] = orgName;

    return axios.post(BASE_API_URL + `/users/enrollAdmin`, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function updateUserPassword(oldPassword, newPassword, emailId) {
    let requestObj = {};
    requestObj["oldPassword"] = oldPassword;
    requestObj["newPassword"] = newPassword;
    requestObj["emailId"] = emailId;

    return axios.post(BASE_API_URL + `/users/userId/updatePassword`, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function editUser(userEmail, userId, userRole, firstName, lastName, firstLastNameChanged, passwordChanged, userRoleChanged, userOrgId, requesterUserName, requesterUserOrgId, requesterUserRole, currentUserRole, isActive, isActiveChanged) {
    var requestObj = {};
    requestObj["userEmail"] = userEmail;
    requestObj["userRole"] = userRole;
    requestObj["firstName"] = firstName;
    requestObj["lastName"] = lastName;
    requestObj["firstLastNameChanged"] = firstLastNameChanged;
    requestObj["passwordChanged"] = passwordChanged;
    requestObj["userRoleChanged"] = userRoleChanged;
    requestObj["userOrgId"] = userOrgId;
    requestObj["requesterUserName"] = requesterUserName;
    requestObj["requesterUserOrgId"] = requesterUserOrgId;
    requestObj["requesterUserRole"] = requesterUserRole;
    requestObj["currentUserRole"] = currentUserRole === "USER" ? "USER" : "ADMIN";
    requestObj["isActive"] = isActive;
    requestObj["isActiveChanged"] = isActiveChanged;
    return axios.post(BASE_API_URL + `/user/` + userId, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function editEmailFlag(value, flag) {
    let requestObj = {};
    requestObj["flag"] = flag;
    requestObj['value'] = value;
    return axios.post(BASE_API_URL + `/user/emailFlag`, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getUserDetailsByUserId(id) {
    return axios.get(BASE_API_URL + `/user/emailId/` + id, {

    });
}
function addUserContactUsRequest(code, description, email) {
    var requestObj = {};
    requestObj["description"] = description;
    requestObj["code"] = code;
    requestObj["email"] = email;
    return axios.post(BASE_API_URL + `/user/contact-us`, requestObj, {
        headers: {
            "Content-Type": "application/json"
        }
    });
}

function getContactUsTopics() {
    return axios.get(
        BASE_API_URL + `/staticdata?type=user_questions`,
        {
        }
    )
}
export default {
    getUsers,
    addUser,
    enrollUser, 
    editUser,
    logout,
    updateUserPassword,
    getUsersByOrgId,
    editEmailFlag,
    getUserDetailsByUserId,
    addUserContactUsRequest,
    getContactUsTopics
};