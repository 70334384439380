import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import ShipmentService from '../services/ShipmentService'
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

export default function AutoCompleteAsync(props) {

    const [searchString, setSearchString] = React.useState("");

    const getOptionValue = (option) => option.port;

    const getOptionLabel = (option) => option.port;

    const handleChange = (selectedOption) => {
        setSearchString(selectedOption);
        props.handleChange(selectedOption);
    }
    const getOptions = (input) => {
        if (!input) {
            return new Promise(resolve => {
                return [];
            });
        }

        return ShipmentService.getShipmentPorts(input)
            .then(function (response) {
                let data = response.data;
                return data;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div>
            <AsyncSelect
                cacheOptions
                value={searchString}
                getOptionValue={getOptionValue}
                getOptionLabel={getOptionLabel}
                loadOptions={getOptions}
                onChange={handleChange}
                placeholder={props.label}
            />
        </div>
    );
}
