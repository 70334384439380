import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../../themes/lightTheme';
import { Card, Typography, Button, Divider, CardContent } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import { Input, FormControl, MenuItem } from '@material-ui/core';
import notify from '../../utils/notifier';
import ChaincodeService from '../../services/ChaincodeService';
import OrganizationService from '../../services/OrganizationService';
import UploadNewVersionForm from './UploadNewVersionForm';
import ApproveChaincodeForm from '../../components/chaincode/ApproveChaincodeForm';
import CommitChaincodeForm from '../../components/chaincode/CommitChaincodeForm';
import { Paper, Drawer } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
const drawerWidth = "30vw";
const useStyles = makeStyles(lightTheme => ({
    root: {
        // flexGrow: 1,
        height: '100%'
    },
    card: {
        border: '1px solid #00000021'
    },
    cardHeader: {
        padding: '4px',
        paddingLeft: '16px',
        background: "#f8f8f8"
    },
    cardContent: {
        padding: '12px'
    },
    installBtn: {
        textTransform: 'none',
        marginLeft: '56%',
        fontSize: '12px',
        padding: '3px 4px',
        background: 'none',
        lineHeight: '1.5',
        marginBottom: '3px',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }
    },
    domainChips: {
        paddingTop: '2%',
        paddingBottom: '4%'
    },
    chip: {
        marginRight: '2%',
        backgroundColor: '#f2f2f2'
    },
    cardKeys: {
        marginTop: lightTheme.spacing(2),
        paddingTop: lightTheme.spacing(5),
        // marginLeft: lightTheme.spacing(8),
        textAlign: 'center'
        // fontSize: '15px'
    },
    flexItem: {
        display: 'flex',
        flexDirection: 'row',
        paddingBottom: lightTheme.spacing(1)
    },
    description: {
        width: '50%',
        fontSize: '15px',
        padding: '15px',
        paddingLeft: '3px'
    },
    dropdown: {
        minWidth: '120px',
        paddingBottom: lightTheme.spacing(4),
        float: 'right',
        marginLeft: '50px' 
    },
    drawerPaper: {
        width: drawerWidth,
    },
    forms: {
        boxShadow: 'none'
    },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 5,
        },
    },
    classes: {
        list: {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null

};

export default function ChainCodeCard(props) {

    const classes = useStyles();
    const [card, setCard] = React.useState([]);
    const handleInstanstiate = () => {
        props.handleInstantiate({ mode: 'instanstiate', chainInfo: props.data });
    };

    const [installedChaincodeList, setInstalledChaincodeList] = React.useState([]);
    const [installedChaincodeWithVersion, setInstalledChaincodeWithVersion] = React.useState([]);
    const [ccVersion, setCcVersion] = React.useState([]);
    const [latestVersion, setLatestVersion] = React.useState('');
    const [uploadDrawer, setUploadDrawer] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const [approveDrawer, setApproveDrawer] = React.useState(false);
    const [commitDrawer, setCommitDrawer] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState(false);
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setBackdrop(true);
        ChaincodeService.getChaincodeVersionsById(props.id).then(
            response => {
                setCcVersion(response.data);
                setCard(response.data[0]);
                setBackdrop(false);
            }, error => {
                notify({
                    type: "error",
                    message: `Failed to get installed chaincodes on peer of ${orgName}`
                });
                setBackdrop(false);
            },

        );
        let orgId = parseInt(localStorage.getItem('_orgID'));
        const orgName = localStorage.getItem('_orgName');
        OrganizationService.getPeersByOrgId(orgId).then(
            response => {
                let data = response.data;
                if (data.length) {
                    let peerId = data[0].id;
                    ChaincodeService.getInstalledChaincodesByPeerId(peerId).then(
                        response => {
                            let _installedChaincodeList = response.data;
                            setInstalledChaincodeList(_installedChaincodeList);
                            let _installedChaincodeWithVersion = _installedChaincodeList.map(cc => {
                                return cc.name + cc.version
                            })
                            setInstalledChaincodeWithVersion(_installedChaincodeWithVersion);
                        },
                        error => {
                            notify({
                                type: "error",
                                message: `Failed to get installed chaincodes on peer of ${orgName}`
                            });
                    }
                    )
                }
            },
            error => {
                notify({
                    type: "error",
                    message: `Failed to get peers info for the ${orgName}`
                });
            }
        );

    };

    const handleInstall = () => {
        let info = {
            id: props.id,
            version: card.version
        };
        setBackdrop(true);
        const orgName = localStorage.getItem('_orgName');
        const reqObj = { id: info.id, version: info.version };
        ChaincodeService.installChainCode(reqObj).then(response => {
            OrganizationService.getPeersByOrgId(parseInt(localStorage.getItem('_orgID'))).then(
                response => {
                    let data = response.data;
                    if (data.length) {
                        let peerId = data[0].id;
                        ChaincodeService.getInstalledChaincodesByPeerId(peerId).then(
                            response => {
                                let _installedChaincodeList = response.data;
                                setInstalledChaincodeList(_installedChaincodeList);
                                let _installedChaincodeWithVersion = _installedChaincodeList.map(cc => {
                                    return cc.name + cc.version
                                })
                                setInstalledChaincodeWithVersion(_installedChaincodeWithVersion);
                            },
                            error => {
                                notify({
                                    type: "error",
                                    message: `Failed to get installed chaincodes on peer of ${orgName}`
                                });
                            }
                        )
                    }
                },
                error => {
                    notify({
                        type: "error",
                        message: `Failed to get peers info for the ${orgName}`
                    });
                }
            )

            notify({
                type: 'success',
                message: 'Chaincode installed successfully!'
            });
            setBackdrop(false);
        }, error => {
                if (error.response && error.response.status == 409) {
                notify({
                    type: "error",
                    message: "This chaincode is already installed"
                });
            } else {
                notify({
                    type: "error",
                    message: "Error occured while installing chiancode"
                });
            }
            setBackdrop(false);
        });
    };



    const handleNewVersion = () => {
        setLatestVersion(ccVersion[0].version);
        setUploadDrawer(true);

    };
    const closeUploadDrawer = () => {
        setUploadDrawer(false);
    };

    const handleApproveChaincode = () => {
        let info = {
            id: props.id,
            version: card.version,
            name: props.name,
            chaincodeVersionId: card.chaincodeVersionId
        };
        setFormData(info);
        setApproveDrawer(true);
    };
    const handleApprovalDrawerClose = () => {
        setApproveDrawer(false);
    };

    const handleCommitChaincode = () => {
        let info = {
            id: props.id,
            version: card.version,
            name: props.name,
            chaincodeVersionId: card.chaincodeVersionId
        };
        setFormData(info);
        setCommitDrawer(true);
    };
    const handleCommitDrawerClose = () => {
        setCommitDrawer(false);
    };

    const handlePeersDrawer = () => {
        props.handlePeerDrawer(props.id, props.name, card.version, card.chaincodeVersionId, card.description)
    };

    const handleChangeVersion = (event) => {
        setCard(event.target.value);
    }



    return (
        <MuiThemeProvider theme={lightTheme}>
            <div
                className={classes.root}
            >
                {card === '' ? "" :
                <Card
                    variant="outlined"
                    className={classes.card}
                    elevation={3}
                >
                    {/* <CardHeader
                        title={'Chain code ' + props.data}
                    /> */}
                    <div className={classes.cardHeader} style={{ cursor: "pointer" }}>
                        <Typography variant="h6" color="textSecondary" ><b>{props.name}</b>
                            <Button disabled={props.disableUploadNewVersion || !(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                style={{ float: "right", fontSize: '13px' }}
                                startIcon={<CloudUploadIcon />}
                                onClick={handleNewVersion} color="primary">
                                {"New Version"}
                            </Button>
                        </Typography>
                    </div>
                    <Divider />

                    <CardContent className={classes.cardContent}>

                        <Typography style={{ fontSize: '13px' }}>

                            <div className={classes.flexItem}>
                                    <Typography className={classes.description}>
                                        <span className={classes.cardKeys}><b>Description : </b></span>{card.description}
                                    </Typography>
                                    <FormControl className={classes.dropdown}>
                                        <InputLabel id="demo-mutiple-name-label"><b>Select version</b></InputLabel>
                                    <Select
                                        labelId="demo-mutiple-name-label"
                                        id="demo-mutiple-name"
                                        value={card}
                                        input={<Input />}
                                        onChange={handleChangeVersion}
                                        MenuProps={MenuProps}
                                    >
                                            {ccVersion.map(v => (
                                            <MenuItem key={v.chaincodeVersionId} value={v}>
                                                {`Version ${v.version}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    </FormControl>
                            </div>
                        </Typography>
                           

                            <Grid container spacing={0} >

                            <Grid item xs={12} sm={12}>
                                <Button
                                        style={{ float: "right", fontSize: '13px' }}
                                    startIcon={<LocalActivityIcon />}
                                    onClick={handleCommitChaincode}
                                        disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN") || !(installedChaincodeWithVersion.includes(`${props.name}${card.version}`))}
                                    color="primary">
                                    {"Commit"}
                                </Button>
                                <Button
                                    style={{ float: "right", fontSize: '13px' }}
                                    startIcon={<CheckCircleOutlineIcon />}
                                    onClick={handleApproveChaincode}
                                        disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN") || !(installedChaincodeWithVersion.includes(`${props.name}${card.version}`))}
                                    color="primary">
                                    {"Approve"}
                                </Button>
                                <Button
                                    style={{ float: "right", fontSize: '13px' }}
                                    startIcon={<SaveAltIcon />}
                                    onClick={handleInstall}
                                        disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN") || installedChaincodeWithVersion.includes(`${props.name}${card.version}`) || (card === '')}
                                    color="primary">
                                    {"Install"}
                                </Button>
                                {/* <Button
                                    style={{ float: "right", fontSize: '13px' }}
                                    startIcon={<SlowMotionVideoIcon />}
                                    onClick={handleInstanstiate}
                                    disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                    color="primary">
                                    {"Instantiate"}
                                </Button> */}
                                <Button
                                    style={{ float: "right", fontSize: '13px' }}
                                    startIcon={<ExploreIcon />}
                                    onClick={handlePeersDrawer}
                                    disabled={card === ''}
                                    color="primary">
                                    {"Details"}
                                </Button>
                                
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
                }
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={uploadDrawer}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <Paper className={classes.forms}>
                        <UploadNewVersionForm
                            closeNewVersionDrawer={closeUploadDrawer}
                            fetchData={fetchData}
                            name={props.name}
                            id={props.id}
                            version={latestVersion}
                        />
                    </Paper>
                </Drawer>
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={approveDrawer}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <Paper className={classes.forms}>
                        <ApproveChaincodeForm data={formData} handleClose={handleApprovalDrawerClose} />
                    </Paper>
                </Drawer>
                <Drawer
                    className={classes.drawer}
                    anchor="right"
                    open={commitDrawer}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <Paper className={classes.forms}>
                        <CommitChaincodeForm data={formData} handleClose={handleCommitDrawerClose} />
                    </Paper>
                </Drawer>
                <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </MuiThemeProvider>
    );
}