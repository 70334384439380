import React, { useState } from "react";
import useStyles from '../components/assets/styles/AssetStyles';
import Backdrop from '@material-ui/core/Backdrop';
import { VALID_USER_INPUT_REGEX } from "../utils/constants";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, Input, InputLabel, Paper, Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UploadFile from './UploadFile';
import notify from "../utils/notifier";
import assetService from "../services/AssetService";
import organizationService from '../services/OrganizationService';
import channelService from "../services/ChannelService";
import Util from "../utils/Util";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from '@material-ui/icons/Warning';

import {
    fileUploadCriteria,
    MenuProps,
    buildRequestObjUploadMultiDocument,
    disableSubmitButtonMultiUpload,
    ebillOfLadingChaincodeName
} from '../components/assets/AssetUtils'

export default function UploadAssets(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [documentArray, setDocumentArray] = React.useState([
        {
            "validationCheckId": Util.makeId(5),
            "documentName": "",
            "documentDescription": "",
            "documentCode": "",
            "documentType": "",
            "documentJSON": {},
            "file": null,
            "duplicateFile": false,
            "isDraft": true
        }
    ]);
    const [render, setRender] = React.useState(false);

    const [allChannels, setAllChannels] = React.useState([]);
    const [selectedChannelId, setSelectedChannelId] = React.useState("");
    const [orgCommercialRoles, setOrgCommercialRoles] = React.useState([]);
    const [selectedOrgCommercialRole, setSelectedOrgCommercialRole] = React.useState("");

    const maxNumberOfDocUploadLimit = 5;

    const assetType = ['Bill Of Lading'];
    const assetTypeCodeMap = {
        'Bill Of Lading': 'BLLA'
    }

    function renderAgain() {
        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }
    }

    function checkUserInput(prop, value) {
        if (!VALID_USER_INPUT_REGEX.test(value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
    }

    function removeInvalidUserInputKey(key) {
        let index = invalidUserInput.indexOf(key);
        if (index > -1) {
            invalidUserInput.splice(index, 1)
            setInvalidUserInput(invalidUserInput);
        }
    }

    const handleChangeDocuments = (documentIndex, prop) => event => {

        let documentValidationKey = documentArray[documentIndex]['validationCheckId'] + "_" + prop;
        checkUserInput(documentValidationKey, event.target.value);

        documentArray[documentIndex][prop] = event.target.value;
        renderAgain();
    }

    const handleChangeIsDraft = (documentIndex, prop) => event => {
        documentArray[documentIndex][prop] = event.target.checked;
        renderAgain();
    }

    function checkDuplicateFileUpload(_tempDocArray) {
        let fileNameSize = [];
        for (let index in _tempDocArray) {
            let doc = _tempDocArray[index];
            if (doc.file) {
                if (fileNameSize.includes(doc.file.name + "_" + String(doc.file.size))) {
                    _tempDocArray[index]['duplicateFile'] = true;
                } else {
                    fileNameSize.push(doc.file.name + "_" + String(doc.file.size));
                    _tempDocArray[index]['duplicateFile'] = false;
                }
            }
        }

        return _tempDocArray;
    }

    const handleFileUpload = (_file, documentIndex) => {
        if (_file) {
            if (fileUploadCriteria.validFileTypes.includes(_file.type) && _file.size < fileUploadCriteria.maxFileSizeLimit) {
                let _tempDocArray = [...documentArray];
                _tempDocArray[documentIndex]['file'] = _file;
                _tempDocArray = checkDuplicateFileUpload(_tempDocArray);

                setDocumentArray(_tempDocArray);
            } else {
                notify({
                    type: "error",
                    message: "File criteria is not matching"
                });
            }
        }
    }

    const handleClickDeleteFile = (documentIndex) => {
    
        let _tempDocArray = documentArray;
        _tempDocArray[documentIndex]['file'] = null;
        _tempDocArray[documentIndex]['duplicateFile'] = false;
        _tempDocArray = checkDuplicateFileUpload(_tempDocArray);

        setDocumentArray(_tempDocArray);
    }

    const handleSubmitUploadDocument = () => {

        let issuerOrgCode = localStorage.getItem('_orgCode');
        let formDataUploadDocumentList = buildRequestObjUploadMultiDocument(documentArray, issuerOrgCode, assetTypeCodeMap, selectedOrgCommercialRole, selectedChannelId);

        if (!formDataUploadDocumentList) {
            return;
        }
        setBackdrop(true)
        assetService.createBulkAssets(formDataUploadDocumentList).then(
            response => {
                props.refreshAssetTable();
                let _message;
                let failedToUploadDocName = response.data.documentsFailedToUpload;
                if (failedToUploadDocName.length) {
                    _message = `Failed to upload following documents : ${JSON.stringify(failedToUploadDocName)}`;
                } else {
                    _message = documentArray.length === 1 ? "Document is uploaded successfully" : "Successfully uploaded all documents";
                }

                setBackdrop(false)
                notify({
                    type: failedToUploadDocName.length === 0 ? "success" : "error",
                    message: _message
                });
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while uploading the document"
                });
            }
        );
    };

    React.useEffect(() => {

        setBackdrop(true);
        channelService.getChannelsByCommittedChaincodeName(ebillOfLadingChaincodeName).then(
            response => {
                let _data = response.data;
                setAllChannels(_data);
                if (_data.length) {
                    setSelectedChannelId(_data[0]['id']);
                }
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching channels details"
                });
            }
        );

        let currentOrganizationCode = localStorage.getItem('_orgCode');

        organizationService.getOrgCommercialRolesByOrgCode(currentOrganizationCode).then(
            response => {
                let _data = response.data;
                setOrgCommercialRoles(_data);
                if(_data.length) {
                    setSelectedOrgCommercialRole(_data[0]['name']);
                }
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching commerial roles of the organization"
                });
            }
        )

    }, []);


    const fileTypeMap = {
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
        'application/msword': '.doc',
        'application/pdf': '.pdf',
        'image/jpeg': '.jpeg',
        'image/png': '.png',
        //'text/html': '.html',
        'application/zip': '.zip'
    }

    let validFileTypesMsg = "Supported file types: ";
    let maxFileSizeLimitMsg = `Max file size: `;
    if (fileUploadCriteria) {
        for (let key in fileUploadCriteria.validFileTypes) {
            validFileTypesMsg += fileTypeMap[fileUploadCriteria.validFileTypes[key]] + ' '
        }
        let maxFileSizeLimitInMB = fileUploadCriteria.maxFileSizeLimit / Math.pow(10, 6);
        maxFileSizeLimitMsg += `${maxFileSizeLimitInMB} MB`;
    }

    const documentUploadPanel = () => (

        documentArray.length ?

            documentArray.length && documentArray.map((doc, documentIndex) => {
                return (
                    <div className={classes.docInfoDiv}>
                        <Paper className={classes.docInfoPaper}>
                            <div className={classes.docTypeNameDiv}>
                                <div className={classes.docTypeDiv}>
                                    <FormControl style={{ minWidth: '100%', paddingRight: '16px' }}>
                                        <InputLabel id="demo-mutiple-name-label">Asset Type</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-name-label"
                                            id="demo-mutiple-name"
                                            fullWidth
                                            value={assetType[0]}
                                            input={<Input />}
                                            disabled
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem
                                                key={assetType[0]}
                                                value={assetType[0]}
                                                disabled
                                            >
                                                {assetType[0]}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                </div>

                                <div className={classes.docNameDiv}>
                                    <TextField
                                        id="standard-basic"
                                        className={classes.docNameTextField}
                                        error={invalidUserInput.includes(doc.validationCheckId + "_" + "documentName")}
                                        label={"Asset Reference"}
                                        margin="normal"
                                        rows="2"
                                        fullWidth
                                        required
                                        value={doc['documentName']}
                                        onChange={handleChangeDocuments(documentIndex, "documentName")}
                                    />
                                </div>
                            </div>
                            <div className={classes.flexItem}>
                                <UploadFile key={documentIndex} onFileUpload={handleFileUpload} handleClickDeleteFile={handleClickDeleteFile} documentsDetails={documentArray} documentIndex={documentIndex}></UploadFile>
                            </div>
                            {
                                <div className={classes.removeDocButtonDiv}>
                                    <div className={classes.isDraftDiv}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={doc.isDraft}
                                                    color="primary"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    size="small"
                                                    onChange={handleChangeIsDraft(documentIndex, 'isDraft')}
                                                />
                                            }
                                            label="Upload as draft"
                                        />
                                        <div className={ doc['duplicateFile'] ? classes.removeDocButtonTooltip : classes.buttonTooltip}>
                                            <Tooltip title="Remove document">
                                                <Button startIcon={<DeleteIcon color="secondary" />} className={classes.removeButton}
                                                    onClick={() => handleRemoveDocument(documentIndex)} />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    {
                                        doc['duplicateFile'] ? 
                                        <div className={classes.warningDiv}>
                                            <div className={classes.warningIconDiv}>
                                                <WarningIcon className={classes.warningIcon}/> 
                                            </div>
                                            <span className={classes.warningText}>
                                                Same file is already selected
                                            </span> 
                                        </div>
                                        : ""
                                    }
                                </div>
                            }
                        </Paper>
                        <Divider></Divider>
                    </div>
                )
            })

            :
            ""
    )

    const handleRemoveDocument = (documentIndex) => {
        let tempDocArray = [...documentArray];
        let docCheckId = documentArray[documentIndex]['validationCheckId'];
        tempDocArray.splice(documentIndex, 1);
        removeInvalidUserInputKey(docCheckId + "_" + "documentName");
        removeInvalidUserInputKey(docCheckId + "_" + "documentDescription");
        tempDocArray = checkDuplicateFileUpload(tempDocArray);
        setDocumentArray(tempDocArray);
        renderAgain();
    }

    const handleAddDocument = () => {
        let tempDocArray = [...documentArray];
        let docCheckId = Util.makeId(5);
        tempDocArray.push({
            "validationCheckId": docCheckId,
            "documentName": "",
            "documentDescription": "",
            "documentCode": "",
            "documentType": "",
            "documentJSON": {},
            "file": null,
            "isDraft": true
        });
        setDocumentArray(tempDocArray);
        renderAgain();
    }

    function checkDisableSubmitButton() {
        return disableSubmitButtonMultiUpload(documentArray, selectedChannelId,selectedOrgCommercialRole) || invalidUserInput.length
            || ( props.fromPage === "assets" && selectedChannelId === "")
    }


    const handleChangeChannelValue = (event) => {
        setSelectedChannelId(event.target.value);
    };

    const handleChangeCommercialRoleValue = (event) => {
        setSelectedOrgCommercialRole(event.target.value);
    };


    return (
        <div className={classes.list}>
            <div>
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Upload assets"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div className={classes.docFormDiv}>
                <div className={classes.shipmentRefAndChannelDiv}>
                    <FormControl className={classes.channelFormControl}>
                        <InputLabel id="channel-checkbox-label">Select channel</InputLabel>
                        <Select
                            labelId="channel-checkbox-label"
                            id="channel-checkbox"
                            value={selectedChannelId}
                            onChange={handleChangeChannelValue}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {
                                allChannels.map((channel) => (
                                    <MenuItem
                                        key={channel.id}
                                        value={channel.id}
                                    >
                                        {channel.channelName}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        <FormHelperText>Channels with req'd chaincode committed on it</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.channelFormControl}>
                        <InputLabel id="channel-checkbox-label">Select commercial role</InputLabel>
                        <Select
                            labelId="channel-checkbox-label"
                            id="channel-checkbox"
                            value={selectedOrgCommercialRole}
                            onChange={handleChangeCommercialRoleValue}
                            input={<Input />}
                            MenuProps={MenuProps}
                        >
                            {
                                orgCommercialRoles.map((role) => (
                                    <MenuItem
                                        key={role.code}
                                        value={role.code}
                                    >
                                        {role.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </div>

                <div className={classes.flexItem}>
                    {documentUploadPanel()}
                    {
                        props.uploadMissingDocType ? "" :
                            documentArray.length >= maxNumberOfDocUploadLimit ? "" :
                                <div className={classes.formContainer}>
                                    <Tooltip className={classes.addDocButtonTooltip} title="Add Document">
                                        <Button autoFocus startIcon={<AddCircleIcon />} className={classes.addButton} color="primary"
                                            onClick={() => handleAddDocument()} />
                                    </Tooltip>
                                </div>
                    }
                </div>

            </div>

            <div className={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmitUploadDocument} color="primary" variant="contained" disabled={backdrop || checkDisableSubmitButton()}>
                    {"UPLOAD"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={props.closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    )

}