import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../../themes/lightTheme';
import {
    Typography, FormControl, Button, Select, MenuItem, Divider, Input, TextField,
    Checkbox, ListItemText, List, ListItem, IconButton, Backdrop, CircularProgress, InputLabel
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChaincodeService from '../../services/ChaincodeService';
import notify from '../../utils/notifier';
import ChannelService from '../../services/ChannelService';
import Chip from '@material-ui/core/Chip';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1
    },
    drawerHeader: {
        //padding: '5%'
        height: '64px',
        width: '100%',
        display: 'inline-flex'
    },
    title: {
        fontSize: '20px',
        fontFamily: 'Helvetica, sans-serif',
        paddingTop: '16px'
    },
    formBody: {
        marginTop: '8%',
        overflow: 'auto',
        maxHeight: '74vh'
    },
    fields: {
        paddingBottom: '5%',
        paddingLeft: lightTheme.spacing(2)
    },
    formControl: {
        marginTop: '10px',
        width: '100%'
    },
    peers: {
        marginLeft: '5%',
        width: '25%'
    },
    formActions: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        background: 'white'
    },
    cancelBtn: {

        float: "right",
        margin: lightTheme.spacing(2),

    },
    submitBtn: {

        float: "right",
        margin: lightTheme.spacing(2),
    },
    backdrop: {
        zIndex: lightTheme.zIndex.drawer + 1,
    },
    hidden: {
        display: "none",
    },
    flexItem: {
        flex: 1,
        paddingLeft: lightTheme.spacing(2),
        //marginTop: "-30px",

        width: "95%"
    },
    targetPeers: {

    },
    memberListTitle: {
        paddingTop: lightTheme.spacing(1),
        fontSize: "0.875rem",
        fontWeight: "700",
        textAlign: 'left'
    },
    memberListItem: {

    },
    memberListItemIcon: {
        color: "#000000",
        display: 'inline-flex',
        minWidth: lightTheme.spacing(4)
    },
    memberListItemText: {
        fontSize: "0.875rem"
    },
    iconStyle: {
        color: "green"
    },
    warningDiv: {
        display: "flex",
        marginBottom: lightTheme.spacing(1),
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
    },
    warningIconDiv: {
        paddingRight: lightTheme.spacing(0.5),
        marginTop: lightTheme.spacing(1),
        marginLeft: lightTheme.spacing(2),
    },
    warningIcon: {
        fontSize: '0.9rem',
    },
    warningText: {
        fontWeight: '600',
        fontSize: '0.99rem',
        marginTop: lightTheme.spacing(.5)
    },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    classes: {
        list: {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null

};

export default function ApproveChaincodeForm(props) {

    const classes = useStyles();

    const [newChannels, setNewChannels] = React.useState('');
    const [channels, setChannels] = React.useState([]);
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [selectedMembers, setselectedMembers] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [endorser, setEndorser] = React.useState([]);
    const [currentEndorser, setCurrentEndorser] = React.useState([]);
    const [approvedOrgs, setApprovedOrgs] = React.useState([]);
    const [newPolicyMembers, setNewPolicyMembers] = React.useState([]);
    const [previousEndorsementPolicy, setPreviousEndorsementPolicy] = React.useState();
    const [isNewPolicy, setIsNewPolicy] = React.useState();
    //Fetch chaincode information
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        const orgId = localStorage.getItem('_orgID');
        ChannelService.getChannelsByOrgId(orgId).then(
            response => {
                let _data = response.data;
                _data = _data.map((row) => ({ name: `${row.channelName} (${row.networkName})`, ...row }));
                setChannels(_data);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error occured while fetching the data"
                });
            }
        );

    };

    const handleChange = event => {
        if (newChannels === event.target.value) {
            setNewChannels(event.target.value);
            return;
        }
        setNewChannels(event.target.value);
        
        setEndorser([]);
        setCurrentEndorser([]);
        setApprovedOrgs([]);
        
        let channelId = event.target.value;

        ChaincodeService.getCurrentChaincodeSequence(props.data.id, channelId).then(
            response => {

                let lastCommittedSequence = response.data;
                let sequence;
                if (lastCommittedSequence[0].max != null) {
                    lastCommittedSequence = parseInt(lastCommittedSequence[0].max);
                    sequence = lastCommittedSequence + 1;
                } else {
                    sequence = null
                }

                ChaincodeService.getEndorsementPolicyByChaincodeVersionId(props.data.chaincodeVersionId, channelId, false, sequence).then(
                    response => {
                        let _data = response.data;
                        let _endorsers = [];
                        if (_data.length) {
                            setPreviousEndorsementPolicy(response.data[0].endorsementPolicy);
                            _endorsers = _data[0].endorsementPolicy
                            _endorsers = _endorsers.split('(')[1];
                            _endorsers = _endorsers.split(')')[0];
                            _endorsers = _endorsers.split(',');
                            _endorsers = _endorsers.map(i => i.toUpperCase().slice(1, i.length - 1));
                            _endorsers = _endorsers.map(i => i.split('.')[0].split('-').join(' '));
                            _endorsers = _endorsers.map(i => i.slice(0, i.length - 3));
                            setEndorser(_endorsers);
                            setCurrentEndorser(_endorsers);
        
                        } else {
                            setEndorser([]);
                            setCurrentEndorser([]);
                        }

                        if (localStorage.getItem('_orgRole') == "operator") {
                            // setPreviousEndorsementPolicy(_endorsers);
                            ChannelService.getOrganizationsInChannelByInstalledChaincodeId(channelId, props.data.chaincodeVersionId).then(
                                response => {
                                    let orgData = response.data;
                                    let oldMembers = [];
                                    setOrgMembers(orgData);
                                    orgData.map((x) => {
                                        if (_endorsers.includes(x.organizationName.toUpperCase()))
                                            oldMembers.push(x);
    
                                    });
                                    setNewPolicyMembers(oldMembers);
                                },
                                error => {
                                    notify({
                                        type: "error",
                                        message: "Error while fetching Org Name and mspId data"
                                    });
                                }
                            )
                        }

                    },
                    error => {
                        notify({
                            type: "error",
                            message: "Error occured while fetching the endorsement policy"
                        });
                    }
                );

                ChaincodeService.getApprovedOrganizationsByChaincodeVersionId(props.data.chaincodeVersionId, channelId, sequence) .then(
                    response => {
                        let _data = response.data;
                        if (_data.length) {
                            let _orgsCodes = _data.map(org => org.organizationCode.toUpperCase());
                            _orgsCodes = _orgsCodes.map(org => org.split('-').join(' '));
                            setApprovedOrgs(_orgsCodes);
                        } else {
                            setApprovedOrgs([]);
                        }
                    },
                    error => {
                        notify({
                            type: "error",
                            message: "Error occured while fetching organizations that approved the chaincode"
                        });
                    }
                );
                
            },
            error => {
                notify({
                    type: "error",
                    message: "Error occured while fetching the chaincode details"
                });
            }
        )


    };


    const handleMembers = (event) => {
        setselectedMembers(event.target.value);
    }

    const handleNewPolicy = (event) => {
        setNewPolicyMembers(event.target.value);
        let _endorsementPolicy;
        let newEndorserList = event.target.value.map(org => org.organizationName.toUpperCase());
        if (event.target.value.length) {
            _endorsementPolicy = event.target.value.map(org => `${org.mspId}.member`);
            _endorsementPolicy = _endorsementPolicy.sort();
            _endorsementPolicy = _endorsementPolicy.map(x => `'${x}'`);
            _endorsementPolicy = _endorsementPolicy.join(',');
            _endorsementPolicy = `AND(${_endorsementPolicy})`;
            if (previousEndorsementPolicy == _endorsementPolicy) {
                setIsNewPolicy(false)
                setEndorser(currentEndorser);
            }
            else {
                setIsNewPolicy(true);
                setEndorser(newEndorserList);
            }
        }
        else {
            setIsNewPolicy(false);
            setEndorser(currentEndorser);
        }
    }

    const checkIfAllOrgApproved = () => {
        if (localStorage.getItem('_orgRole') == "operator") {
            return false
        }
        for (let i of endorser) {
            if (!approvedOrgs.includes(i)) {
                return false;
            }
        }
        return true;
    }

    const checkDisableSubmitButton = () => {
        return checkIfAllOrgApproved() || (endorser.length || selectedMembers.length) ? false : true
    }



    const handleSubmit = (selectedMembers) => {

        // TODO: once backed is working, DONT construct peer
        const orgName = localStorage.getItem('_orgName');

        const reqObj = { ccVersion: props.data.version, channelId: newChannels };
        let _endorsementPolicy;
        if (selectedMembers.length) {
            _endorsementPolicy = selectedMembers.map(org => `${org.mspId}.member`);
            _endorsementPolicy = _endorsementPolicy.sort();
            reqObj.endorsementPolicy = _endorsementPolicy
        }
        setOpen(true);
        ChaincodeService.approveChaincode(props.data.id, reqObj).then(
            response => {
                setOpen(false);
                notify({
                    type: 'success',
                    message: 'Chaincode definition approved successfully!'
                });
                props.handleClose();
            },
            error => {
                setOpen(false);
                notify({
                    type: 'error',
                    message: 'Error occured while approving chaincode'
                });
            }
        );       
    };

    return (
        <MuiThemeProvider theme={lightTheme}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={props.handleClose} style={{ background: 'none' }}>
                    <ChevronRightIcon color="primary" />
                </IconButton>
                <Typography className={classes.title}>Approve chaincode definition</Typography>
            </div>
            <Divider />
            {/* {isNewPolicy == false ?
                <div className={classes.warningDiv}>
                    <div className={classes.warningIconDiv}>
                        <WarningIcon className={classes.warningIcon} />
                    </div>
                    <span className={classes.warningText}>
                        Please add new members to continue with editing policy
                                                    </span>
                </div> : ""} */}
            <div className={classes.formBody}>
                <div className={classes.fields} style={{ display: 'block' }}>
                    <Typography><b>Chaincode Name : </b>{props.data.name}</Typography>
                </div>
                <div className={classes.fields} style={{ display: 'block' }}>
                    <Typography><b>Version : </b>{props.data.version}</Typography>
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        required
                        select
                        className={classes.textField}
                        label={"Channels"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={newChannels}
                        onChange={handleChange}
                    >
                        {channels.map(channel => (
                            <MenuItem key={channel.channelCode} value={channel.id}>
                                <ListItemText primary={channel.name} />
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                {
                    endorser.length ?
                    <div className={classes.flexItem}>
                            <Typography variant="h6" className={classes.memberListTitle}>
                                Endorsement Policy : Following organization must approve before committing
                        </Typography>
                        <List dense={true}>
                            {endorser.map((member) => (
                                <ListItem className={classes.memberListItem}>
                                    <ListItemText
                                        primary={member}
                                        className={classes.memberListItemText}
                                    />
                                    {
                                        (approvedOrgs.includes(member) && !isNewPolicy) ? <CheckCircleIcon fontSize="medium" className={classes.iconStyle} /> : <Tooltip title={<span style={{ fontSize: '12px' }}>Pending approval</span>} placement="right-end"><MoreHorizIcon fontSize="medium" /></Tooltip>
                                    }
                                </ListItem>
                            ))}
                        </List>
                            {localStorage.getItem('_orgRole') == "operator" ?
                                <FormControl className={classes.formControl}>
                                    <InputLabel>Edit Endorsement Policy</InputLabel>
                                    <Select
                                        multiple
                                        renderValue={selected => (
                                            <div className={classes.chips}>
                                                {selected.map(value => (
                                                    <Chip key={value.index} label={value.organizationName} className={classes.chip} />
                                                ))}
                                            </div>
                                        )}
                                        labelId="inputLabel"
                                        MenuProps={MenuProps}
                                        value={newPolicyMembers}
                                        onChange={handleNewPolicy}
                                    >
                                    {orgMembers.map(member => (
                                        <MenuItem key={member.mspId} value={member}>
                                            <Checkbox checked={newPolicyMembers.some(_member => _member['organizationName'] === member.organizationName)} />
                                            <ListItemText primary={member.organizationName} />
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                : ""
                            }
                    </div> : 
                    localStorage.getItem('_orgRole') == "operator" ? 
                    <div className={classes.flexItem}>
                        <FormControl className={classes.formControl}>
                            <InputLabel>Select members to be a part of endorsement policy*</InputLabel>
                            <Select
                                multiple
                                className={classes.targetPeers}
                                value={selectedMembers}
                                onChange={handleMembers}
                                input={<Input />}
                                renderValue={selected => (
                                    <div className={classes.chips}>
                                        {selected.map(value => (
                                            <Chip key={value.index} label={value.organizationName} className={classes.chip} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={MenuProps}
                                disabled={!newChannels}
                            >
                                {orgMembers.map(member => (
                                    <MenuItem key={member.mspId} value={member}>
                                        <Checkbox checked={selectedMembers.some(_member => _member['organizationName'] === member.organizationName)} />
                                        <ListItemText primary={member.organizationName} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div> 
                    : newChannels != '' ?
                    <div className={classes.flexItem}>
                        <Typography variant="h6" className={classes.memberListTitle}>
                                        Operator did not set the endorsement policy yet.
                                        Operator should set endorsement policy and approve the chaincode first.
                        </Typography>
                                </div>
                                : ""
                    
                } 
            </div>
            <div></div>
            <div className={classes.formActions}>

                <Divider />
                {(localStorage.getItem('_orgRole') == "operator" && endorser.length) ?
                    <Button
                        variant="contained"
                        className={classes.submitBtn}
                        color="primary"
                        onClick={() => handleSubmit(newPolicyMembers)}
                        disabled={isNewPolicy != true}
                    >
                        Submit
                    </Button>
                    :
                    <Button
                        variant="contained"
                        className={classes.submitBtn}
                        color="primary"
                        onClick={() => handleSubmit(selectedMembers)}
                        disabled={newChannels === '' || checkDisableSubmitButton()
                            || approvedOrgs.includes(localStorage.getItem('_orgCode').toUpperCase().split('-').join(' ')) ||
                            (!endorser.includes(localStorage.getItem('_orgName').toUpperCase()) && localStorage.getItem('_orgRole') != "operator")}

                >
                    Submit
                </Button>
                }
                <Button
                    variant="contained"
                    className={classes.cancelBtn} onClick={props.handleClose}>

                    Cancel
                </Button>

            </div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </MuiThemeProvider>

    );
}