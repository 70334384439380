import React from 'react';
import { makeStyles, MuiThemeProvider, useTheme } from '@material-ui/core/styles';
import { Typography, InputLabel } from '@material-ui/core';
import { Input, FormHelperText } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';

import TextField from '@material-ui/core/TextField';
//import Util from '../../utils/Util'
import notify from "../../utils/notifier";
import userManagementService from '../../services/UserManagementService';
import AuthService from '../../services/AuthService';
import authUtil from '../../utils/authUtil';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReCAPTCHA from "react-google-recaptcha";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { InputAdornment } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { PREVIOUS_PASSWORDS_TO_CHECK_NUM } from '../../utils/constants';
//import notify from '../../utils/notifier';

import { PASSWORD_VALIDITY_DAYS } from '../../utils/constants';
const SITE_KEY = `${process.env.REACT_APP_CAPTCHA_SITE_KEY}`;

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: '80px 48px 48px',
        ['@media only screen and (max-width:600px)']: {
            width: "100%",
            minWidth: "100%",
            maxWidth: "100%"
        }
    },
    loginText: {
        fontFamily: 'Helvetica Neue',
        fontSize: '21px',
        lineHeight: '20px',
        color: 'rgba(0,0,0,.87)',
        fontWeight: '500'
    },
    resetText: {
        fontFamily: 'Helvetica Neue',
        fontSize: '21px',
        lineHeight: '20px',
        marginLefft: '5px',
        color: 'rgba(0,0,0,.87)',
        fontWeight: '500'
    },
    form: {
        paddingTop: '32px'
    },
    forgot_password_align: {
        textAlign: "right",
        fontSize: '12px',
        alignItems: "right",
        float: 'right'
    },
    emailId: {
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: 'Helvetica Neue',
    },
    secret: {
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: 'Helvetica Neue',
        marginBottom: '22px'
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",

    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
        margin: theme.spacing(1)
    },
    list: {
        width: "25vw",
    },
    flexItem: {
        flex: 1,
        // margin: theme.spacing(1),
        // paddingLeft: theme.spacing(3)
    },
    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    noSignin: {
        lineHeight: 'normal',
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        margin: '16px auto',
        width: '100%',
        height: '36px',
        textTransform: 'none',
        color: '#a09595',
        backgroundColor: 'lightgray',
    },
    signin: {
        lineHeight: 'normal',
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        margin: '16px auto',
        width: '100%',
        height: '36px',
        textTransform: 'none',
        color: '#ffffff',
        backgroundColor: '#3c4252',
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            backgroundColor: '#3c4252'
        }
    },
    failedMsg: {
        fontFamily: 'Helvetica Neue',
        fontSize: '14px',
        color: 'red'
    },
    warningStyleNoBg: {
        // color: "#721C24",
        textAlign: "center",
        fontSize: '10px',
        // padding: '1px 3px 4px',
        marginBottom: "10px",
        marginLeft: "8px"
    },
    drawerHeader: {
        padding: theme.spacing(2),
    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        padding: '1px 3px 4px',
        marginLeft: "-10px",
    },
    paswordValidity: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        padding: '1px 3px 4px',
        marginLeft: "1px",
        marginTop: "2px"
    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem"
    },
    dialogContentText: {
        fontWeight: "700"
    },
    dialogButton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    },
    input: {
        background: "rgb(232, 241, 250)"
    },
    root1: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function LoginForm(props) {

    const classes = useStyles();
    const theme = useTheme();

    let captcha;
    const [emailId, setemailId] = React.useState('');
    const [organisationName, setOrganisationName] = React.useState([]);
    const [password, setPassword] = React.useState('');
    const [failed, setFailed] = React.useState(false);
    const [loginFailed, setLoginFailed] = React.useState(false);
    const [autoFocus, setAutoFocus] = React.useState(true);
    const [resetPassword, setResetPassword] = React.useState(false);
    const [updatePassword, setUpdatePassword] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState(false);
    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [newPasswordCheck, setNewPasswordCheck] = React.useState("");
    const [passwordsMatch, setPasswordsMatch] = React.useState(true);
    const [popupOpen, setPopupOpen] = React.useState(false);
    const [loginInProgress, setLoginInProgress] = React.useState(false);
    const [captchaValue, setCaptchaValue] = React.useState(null);
    const [userLocked, setUserLocked] = React.useState(false);
    const [attemptsLeft, setAttemptsLeft] = React.useState(5);
    const [samePasswordError, setSamePasswordError] = React.useState(false);
    const [samePassword, setSamePassword] = React.useState(false);
    const [inactiveUser, setInactiveUser] = React.useState(false);
    const [maxChangedPassword, setMaxChangedPassword] = React.useState(false);
    const [passwordCharLimitError, setPasswordCharLimitError] = React.useState(false);
    const [userRole, setUserRole] = React.useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [values, setValues] = React.useState({
        //password: '',
        organizationId: '',
        valid: false,
        disable: true
    });

    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    React.useEffect(() => {
        setAutoFocus(true);
    }, []);

    const setInputFocus = event => {
        if (!autoFocus) {
            setAutoFocus(true);
        }
        return true;
    };

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
    }

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    };

    function validatePasswords() {
        var passwordPattern;
        if (userRole === 'SUPERADMIN' || userRole === 'ADMIN') {
            passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#$&%@!ˆ*._-]).{14,}$/;
        } else {
            passwordPattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#$&%@!ˆ*._-]).{8,}$/;
        }
        return passwordPattern.test(newPassword) && passwordPattern.test(newPasswordCheck);
    }

    const handleOrganisationChange = event => {
        setOrganisationName(event.target.value);
        setValues({ organizationId: event.target.value })
    };

    const handleEmailChange = event => {
        setemailId(event.target.value);
        handleValidationChange(event);
    };

    //TODO: validate password is required
    const handleValidationChange = event => {
        setValues({
            disable: false,
            valid: true
        });
    };

    function onChangeCaptchaValue(value) {
        setCaptchaValue(value);
    }
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleSubmitUpdatePassword = () => {
        setBackdrop(true);
        let validatePasswordExpiry = false;
        setSamePasswordError(false);
        setMaxChangedPassword(false);
        setPasswordCharLimitError(false);
        AuthService.login(emailId, oldPassword, false, validatePasswordExpiry).then(
            (res) => {
                if (res.data.errorCode) {
                    setBackdrop(false);
                    setFailed(true);
                } else {
                    authUtil.setSession(res);
                userManagementService.updateUserPassword(oldPassword, newPassword, emailId).then(
                    response => {
                        if (response.data) {
                            if (response.data.errorCode === 406) {
                                setBackdrop(false);
                                setSamePasswordError(true);
                            } else if (response.data.errorCode === 40602) {
                                setMaxChangedPassword(true);
                                setBackdrop(false);
                            }
                            else if (response.data.errorCode === 40010) {
                                setBackdrop(false);
                                setPasswordCharLimitError(true);
                            }
                            else {
                                if (response.data) {

                                    setDrawerState({ ...drawerState, ["right"]: false });
                                    // ask the user to login with the new password
                                    authUtil.clearSession();
                                    props.history.push('/');
                                } else {
                                    setBackdrop(false);
                                    notify({
                                        type: "error",
                                        message: "Internal Server Error"
                                    });
                                }
                                // ask the user to login with the new password
                                setBackdrop(false);
                                setMaxChangedPassword(false);
                            }
                        }
                    },
                    error => {
                        setBackdrop(false);
                        notify({
                            type: "error",
                            message: "Incorrect current password"
                        });
                    }
                );
                }
            }).catch((error) => {
                //TODO: throw error message in the login form itself, don't show snackbar
                setBackdrop(false);
                if (error && error.response && error.response.status) {
                    setResetPassword(true);
                }
                setFailed(true);
            }
            );
    };

    //TODO: validate password is required
    const handlePasswordChange = event => {
        setPassword(event.target.value);
    };

    function validateEmail(elementValue) {
        var emailPattern = /^[a-zA-Z0-9]+[a-zA-Z0-9._-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(elementValue);
    }

    function flagEmail() {
        var emailPattern = /^[-_ a-zA-Z0-9@.]+$/
        if (emailId.length === 0) {
            return false
        }
        if (password.length === 0) {
            return !emailPattern.test(emailId);
        } else {
            return !validateEmail(emailId)
        }
    }

    const handleChangeOldPassword = (event) => {
        setFailed(false);
        setSamePassword(false);
        if (event.target.value === newPassword) {
            setSamePassword(true);
        }
        setOldPassword(event.target.value)
    }

    const handleChangeNewPassword = (event) => {
        setFailed(false);
        setSamePassword(false);
        if (oldPassword === event.target.value) {
            setSamePassword(true);
        }
        setNewPassword(event.target.value)
    };

    const handleChangeNewPasswordCheck = (event) => {
        setFailed(false);
        setNewPasswordCheck(event.target.value)
    };

    const disableSubmitButtonPasswordReset = () => {
        if (oldPassword && newPassword && newPasswordCheck) {
            if (oldPassword === newPassword) {
                return true;
            }
            if (newPassword === newPasswordCheck && validatePasswords()) {
                return false;
            }
        }
        return true;
    };

    const handleLogin = () => {
        setLoginInProgress(true);
        setLoginFailed(false);
        setUserRole('');
        setUserLocked(false);
        setInactiveUser(false);
        if (validateEmail(emailId)) {
            AuthService.login(emailId, password, true).then(
                (res) => {
                    if (res.data.errorCode) {
                        if (localStorage.getItem("_loginAttempts")) {
                            localStorage.setItem("_loginAttempts", res.data.password_attempt ? res.data.password_attempt : 1);
                        } else {
                            localStorage.setItem("_loginAttempts", 1)
                        }
                        if (res.data.errorCode === 40190) {
                            setUserLocked(true);
                            localStorage.setItem("_loginAttempts", 5);
                            
                        } else {
                            if (5 - res.data.password_attempt == 0)
                                setUserLocked(true);
                            else {
                                setLoginFailed(true);
                                setAttemptsLeft(5 - res.data.password_attempt);
                            }
                        }
                    } else {
                        localStorage.removeItem("_loginAttempts")
                        authUtil.setSession(res);
                        props.history.push('/');
                    }
                    setLoginInProgress(false);
                    if (captcha) {
                        captcha.reset();
                    }
                    setCaptchaValue(null)
                }).catch((error) => {
                    if (captcha) {
                        captcha.reset();
                    }
                    setCaptchaValue(null)
                    if (localStorage.getItem("_loginAttempts")) {
                        localStorage.setItem("_loginAttempts", localStorage.getItem("_loginAttempts") + 1)
                    } else {
                        localStorage.setItem("_loginAttempts", 1)
                    }
                    setLoginInProgress(false);
                    //TODO: throw error message in the login form itself, don't show snackbar
                    if (error && error.response && error.response.status) {
                        if (error.response.status === 409) {
                            setUserRole(error.response.data.user_role);
                            setResetPassword(true);

                        }
                        else if (error.response.status === 406) {
                            setUpdatePassword(true);
                            setResetPassword(true);
                        }
                        else if (error.response.status === 403) {
                            setInactiveUser(true);
                        }
                        else {
                            setLoginFailed(true);
                        }

                    }
                    if (error.message == "Network Error") {
                        notify({
                            type: "error",
                            message: "Internal Server Error"
                        });
                    }
                }
                );
        } else {
            setLoginInProgress(false);
            setLoginFailed(true);
        }
    }

    const setCaptchaRef = (ref) => {
        if (ref) {
            return captcha = ref;
        }
    };

    const handleOpenPopupOpen = () => {
        setPopupOpen(true);
    }

    const handleClosePopup = () => {
        setPopupOpen(false);
    }

    const sideList = side => (
        <div className={classes.list}>
            {/* <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <Typography className={classes.failedMsg} style={{ display: resetPassword ? 'block' : 'none' }} >
                            Your password was reset by the ADMIN
                        </Typography>
                    </div>
                </Toolbar>
            </div> */}
            <div className={classes.paswordValidity} style={{
                    display: updatePassword ? 'block' : 'none',
                    fontSize: '0.75rem'
                }}>
                < h3 > Your current password is expired. Please reset your password.</h3>
                </div>
            <div className={classes.root}>

                <Typography className={classes.resetText}>RESET PASSWORD</Typography>
                <br></br>

                <div className={classes.warningStyle} style={{
                    display: samePasswordError ? 'block' : 'none',
                    fontSize: '0.75rem'
                }}>
                    < h3 > New password should not match previous {PREVIOUS_PASSWORDS_TO_CHECK_NUM} passwords</h3>
                </div>
                <div className={classes.warningStyle} style={{
                    display: maxChangedPassword ? 'block' : 'none', fontSize: '0.85rem'
                }}>
                    < h3 > Password is already changed for maximum number of times in a day.Please try again later</h3>
                </div>
                <div className={classes.warningStyle} style={{
                    display: passwordCharLimitError ? 'block' : 'none', fontSize: '0.85rem'
                }}>
                    < h3 > New Password should have at least 14 characters for admin and super admin user</h3>
                </div>
                <div className={classes.warningStyle} style={{ display: failed ? 'block' : 'none', fontSize: '0.75rem' }}>
                    <h3  >
                        Incorrect current password
                    </h3>
                </div>
                <div className={classes.warningStyle} style={{ display: samePassword ? 'block' : 'none', fontSize: '0.75rem' }}>
                    <h3>
                        New password should not match current password
                    </h3>
                </div>
                <div className={classes.form}>
                    <div className={classes.emailId}>
                        {/* <FormControl fullWidth> */}
                        <InputLabel>User Email *</InputLabel>
                        <Input
                            autoFocus={setInputFocus()}
                            //ref={focusUsernameInputField}
                            value={emailId}
                            type={'text'}
                            label={'Your EmailId *'}
                            placeholder={emailId}
                            disabled
                            fullWidth
                        />
                        <FormHelperText style={{ color: '#f44336', paddingBottom: '10px' }}>{values.valid ? '' : ''}</FormHelperText>
                        {/* </FormControl> */}
                    </div>
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        type="password"
                        className={classes.textField}
                        label={"Enter current password"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={oldPassword}
                        onChange={handleChangeOldPassword}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        type="password"
                        className={classes.textField}
                        label={"Enter new password"}
                        margin="normal"
                        rows="2"
                        disabled={oldPassword.length === 0}
                        required
                        fullWidth
                        value={newPassword}
                        onChange={handleChangeNewPassword}
                    />
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        type="password"
                        className={classes.textField}
                        label={newPasswordCheck.length > 0 ? newPassword === newPasswordCheck ? "Enter new password again" : "Enter new password again (passwords do not match)" : "Enter new password again"}
                        margin="normal"
                        rows="2"
                        required
                        error={newPasswordCheck.length > 0 && newPassword !== newPasswordCheck}
                        disabled={oldPassword.length === 0}
                        fullWidth
                        value={newPasswordCheck}
                        onChange={handleChangeNewPasswordCheck}
                    />
                </div>

                <Button
                    className={(newPassword !== '' && newPasswordCheck !== '' && validatePasswords()) ? classes.signin : classes.noSignin}
                    disabled={disableSubmitButtonPasswordReset() || backdrop}
                    onClick={handleSubmitUpdatePassword}
                >
                    Reset Password
                </Button>
                <Typography className={classes.failedMsg} style={{ display: !passwordsMatch ? 'block' : 'none' }} >
                    Your passwords do not match
                </Typography>
                {
                    validatePasswords() ?
                        ""
                        :
                        (userRole === 'SUPERADMIN' || userRole === 'ADMIN') ? <div className={classes.warningStyleNoBg}>
                            <h3>* Password should be at least 14 characters with a combination of numbers, lower case, upper case letters and special characters (# $ & % @ ! ˆ * . _ - _ )</h3>
                        </div> :
                        <div className={classes.warningStyleNoBg}>
                            <h3>* Password should be at least 8 characters with a combination of numbers, lower case, upper case letters and special characters (# $ & % @ ! ˆ * . _ - _ )</h3>
                        </div>
                }
            </div>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 100 }} >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div >
    );

    const disableLoginButton = () => {
        if (emailId === '' && password === '') {
            return true
        }
        if (validateEmail(emailId)) {
                    if (captchaValue) {
                        return false
                    } else if (localStorage.getItem("_loginAttempts") >= 3) {
                        return true
                    }

        }
        return false;
    }

    const isEmailCorrect = () => {
        if (validateEmail(emailId) && password.length > 0) {
            return true
        }
        return false
    }

    return (
        <MuiThemeProvider theme={theme} >
            <div className={classes.drawerHeader}>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.toggleDrawer(false)}
                    edge="start"
                >
                    <ChevronRightIcon />
                </IconButton>
            </div>
            <div className={classes.root}>
                <Typography className={classes.loginText}>LOGIN TO YOUR ACCOUNT</Typography>
                <div className={classes.form}>
                    <div className={classes.emailId}>
                        {/* <FormControl fullWidth> */}
                        <InputLabel>User Email *</InputLabel>
                        <Input
                            autoFocus={setInputFocus()}
                            //ref={focusUsernameInputField}
                            value={emailId}
                            type={'text'}
                            label={'Your EmailId *'}
                            onChange={handleEmailChange}
                            error={flagEmail()}
                            fullWidth
                        />
                        <FormHelperText style={{ color: '#f44336', paddingBottom: '10px' }}>{values.valid ? '' : ''}</FormHelperText>
                        {/* </FormControl> */}
                    </div>
                </div>

                <div className={classes.secret}>
                    <InputLabel>Password *</InputLabel>
                    <TextField
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        value={password}
                        onChange={handlePasswordChange}
                        onKeyPress={handleKeyPress}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        tabIndex={- 1}
                                        className={classes.root1}
                                    >
                                        {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            className: classes.input
                        }}
                    />
                </div>
                {
                    localStorage.getItem("_loginAttempts") ?
                        localStorage.getItem("_loginAttempts") >= 3 ?
                            <ReCAPTCHA
                                ref={(r) => setCaptchaRef(r)}
                                sitekey={SITE_KEY} //have to generate blockchain site API key for react-google-captcha
                                onChange={onChangeCaptchaValue}
                            />
                            :
                            ""
                        :
                        ""
                }
                {/* <Typography className={classes.failedMsg} style={{ display: failed ? 'block' : 'none' }} >
                    Incorrect user email or password
                </Typography> */}
                <Button
                    className={isEmailCorrect() ? classes.signin : classes.noSignin}
                    disabled={disableLoginButton() || loginInProgress}
                    onClick={handleLogin}
                >
                    Login
                </Button>

                <Button
                    size="small"
                    color="secondary"
                    className={classes.forgot_password_align}
                    onClick={handleOpenPopupOpen}
                >
                    forgot password?
                </Button>
                <br></br>
                <Typography className={classes.failedMsg} style={{ display: userLocked ? 'inline-block' : 'none' }} >
                    This account is locked due to 5 wrong password attempts.<br></br>Please try again later.
                </Typography>
                <Typography className={classes.failedMsg} style={{ display: loginFailed ? 'inline-block' : 'none' }} >
                    Incorrect user email or password.<br></br>{attemptsLeft} attempt(s) are left before account gets locked.
                </Typography>
                <Typography className={classes.failedMsg} style={{ display: resetPassword ? 'inline-block' : 'none' }} >
                    Your password has been reset by the ADMIN, please create a new password.
                </Typography>
                <Typography className={classes.failedMsg} style={{ display: inactiveUser ? 'inline-block' : 'none' }} >
                    Your account is inactive, please contact your admin.
                </Typography>
                <Drawer
                    anchor="right"
                    open={resetPassword}
                    onOpen={closeDrawer('right', true)}
                    onClose={closeDrawer('right', true)}
                >
                    {sideList('right')}
                </Drawer>
                <Dialog
                    open={popupOpen}
                    onClose={handleClosePopup}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle>
                        <Typography className={classes.dialogTitle} gutterBottom>
                            {`Forgot Password`}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className={classes.dialogContentText}>
                            {"Please contact your admin for resetting your password."}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus className={classes.dialogButton} color="primary" onClick={handleClosePopup} >
                            {"CLOSE"}
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        </MuiThemeProvider>
    );

}