import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import Tab from '@material-ui/core/Tab';
import TableBody from "@material-ui/core/TableBody";
import { Input, FormControl } from '@material-ui/core';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from "@material-ui/icons/Close";
import Drawer from '@material-ui/core/Drawer';

import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import shipmentService from "../../services/ShipmentService";
import channelService from "../../services/ChannelService"
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import useStyles from './styles/ShipmentsStyles';
import Util from "../../utils/Util";
import notify from "../../utils/notifier";
import Moment from 'react-moment';
import Tooltip from '@material-ui/core/Tooltip';
import AutoCompleteAsync from "../AutoCompleteAsync";
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { AiOutlineFileExclamation } from "react-icons/ai";
import { AiOutlineFileText } from "react-icons/ai";
import { withStyles } from "@material-ui/core/styles";
import Highlight from 'react-highlighter';
// import ShipmentRevision from "./ShipmentRevisions";
// import EditShipment from "./EditShipment";
import { VALID_USER_INPUT_REGEX, ONLY_NUMBERS_REGEX, LENGTH_BREADTH_HEIGHT, SHIPMENT_NUMBER_REGEX, SHIPMENT_REF_NUM_VALID_USER_INPUT_REGEX } from "../../utils/constants"
import {
  _columns,
  userPrefs,
  MenuProps,
  disableSubmitButtonShipmentsByRef as disableSubmitButtonByShipmentsRef,
  disableSubmitButton,
  createMovementType,
  createChannels,
  checkIfNotNumber,
  disableAddProductButton,
  disableAddContainerButton,
  buildRequestObj,
  buildRequestObjEditShipment
} from './ShipmentsUtils'

const placeHolders = {
  podName: "Origin Port",
  poaName: "Destination Port",
  channelName: "Channel",
  carrier: "Carrier",
  status: "Status"
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const highlightableColumns = ["podName", "poaName", "channelName", "carrier", "shipmentReference", "shipmentNumber"]

export default function Shipment(props) {

  const classes = useStyles();
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [columns, setColumns] = React.useState(_columns);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [searchKey, setSearchKey] = useState({ value: "" });
  const [shipmentInfoData, setshipmentInfoData] = useState({});
  const [channels, setChannels] = React.useState([]);
  const [organizationID, setOrganisationID] = React.useState(localStorage.getItem('_orgID'));
  const [channelIDMap, setChannelIDMap] = React.useState({});
  const [waitChannelFetch, setWaitChannelFetch] = React.useState(true);
  const [backdrop, setBackdrop] = React.useState(false);
  const [selectChannel, setSelectChannel] = React.useState(null);
  const [sideDrawerNumber, setSideDrawerNumber] = React.useState(1)
  const [editRow, setEditRow] = React.useState("")

  const [invalidUserInput, setInvalidUserInput] = React.useState([]);
  const [queryCount, setQueryCount] = React.useState(0);
  const [originalqueryCount, setOriginalQueryCount] = React.useState(0);
  const [radioButtonValue, setRadioButtonValue] = React.useState('ByShipmentRef');

  const [containerArray, setContainerArray] = React.useState([]);
  const [numberOfProducts, setNumberOfProducts] = React.useState([0])
  const [numberOfContainers, setNumberOfContainers] = React.useState(0);
  const [openPanels, setOpenPanels] = React.useState({});
  const [hideOrigin, setHideOrigin] = React.useState(true);
  const [hideDestination, setHideDestination] = React.useState(true);
  const [filter, setFilter] = React.useState(true);
  const [selectedArrays, setSelectedArrays] = React.useState({
    podName: [],
    poaName: [],
    channelName: [],
    carrier: [],
    status: [],
  });

  const [dictChannels, setDictChannels] = React.useState({})
  const [inputArrays, setInputArrays] = React.useState({
    podName: [],
    poaName: [],
    channelName: [],
    carrier: [],
    status: [],
  });
  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const tableHeadWidth = (100 / _columns.length) + "%";

  const [values, setValues] = React.useState({
    Importer: "",
    Exporter: "",
    Origin: "",
    Destination: "",
    Channel: "",
    Vessel: "",
    Voyage: "",
    ContainerNumber: "",
    PortOfArrival: "",
    PortOfDeparture: "",
    shipmentNumber: "",
    shipmentReference: "",
    movementType: "CY-CY",
    Carrier: "",
    Atd: "",
    Eta: "",
    ShipmentRefNum: ""
  });

  const [render, setRender] = React.useState(false)
  const setInitialState = () => {
    values.Importer = ""
    values.Exporter = ""
    values.Origin = ""
    values.Destination = ""
    values.Channel = ""
    values.Vessel = ""
    values.Voyage = ""
    values.ContainerNumber = ""
    values.PortOfArrival = ""
    values.PortOfDeparture = ""
    values.shipmentNumber = ""
    values.shipmentReference = ""
    values.movementType = "CY-CY"
    values.Carrier = ""
    values.Eta = ""
    values.Atd = ""
    values.ShipmentRefNum = ""
    setWaitChannelFetch(true)
    setEditRow("")
    setNumberOfProducts([0])
    setNumberOfContainers(0)
    setInvalidUserInput([]);
    setContainerArray([]);
  }

  const movementType = [
    "CY-CY", "CY - Door", "Door - CY", "Door - Door"
  ];

  const portsMap = {
    "EGALY": "Alexandria",
    "JOAQJ": "Aqaba",
    "LBBEY": "Beirut",
    "MACAS": "Casablanca",
    "AEJEA": "Jebel Ali ",
    "CAMTR": "Montreal ",
    "EGPSE": "Port Said East",
    "CNQDG": "Qingdao",
    "KWSAA": "Shuaiba",
    "IQUQR": "Umm Qasr Port"
  }

  function removeInvalidUserInputKey(key) {
    let index = invalidUserInput.indexOf(key);
    if (index > -1) {
      invalidUserInput.splice(index, 1)
      setInvalidUserInput(invalidUserInput);
    }
  }


  function handleChangePortOfOrigin(value) {
    setValues({ ...values, ["PortOfDeparture"]: value.port });
  }

  function handleChangePortOfDestination(value) {
    setValues({ ...values, ["PortOfArrival"]: value.port });
  }

  const handleChangeMovementTypes = prop => (event) => {
    switch (event.target.value) {
      case "CY-CY":
        setHideDestination(true);
        setHideOrigin(true);
        values.Origin = "";
        values.Destination = "";
        break;

      case "CY - Door":
        setHideDestination(false);
        setHideOrigin(true);
        values.Origin = "";
        values.Destination = "";
        break;

      case "Door - CY":
        setHideDestination(true);
        setHideOrigin(false);
        values.Origin = "";
        values.Destination = "";
        break;

      case "Door - Door":
        setHideDestination(false);
        setHideOrigin(false);
        values.Origin = "";
        values.Destination = "";
        break;

      default:
        setHideDestination(true);
        setHideOrigin(true);
        values.Origin = "";
        values.Destination = ""

    }
    setValues({ ...values, [prop]: event.target.value });
  }

  const handleChange = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      removeInvalidUserInputKey(prop);
    }
    setValues({ ...values, [prop]: event.target.value });

    // if (prop == "Channel") {
    //   setBackdrop(true);
    //   var channelID = channelIDMap[event.target.value]
    //   channelService.getChannelByChannelId(channelID, true, true).then(
    //     response => {
    //       const _channelDataByChannelID = response.data;
    //       var _channelDataByChannelIDArr = _channelDataByChannelID["organizations"]
    //       let arr = []
    //       var dict = {}
    //       for (let i = 0; i < _channelDataByChannelIDArr.length; i++) {
    //         arr.push(_channelDataByChannelIDArr[i].organizationName)
    //         dict[_channelDataByChannelIDArr[i].organizationName] = _channelDataByChannelIDArr[i].id
    //       }
    //       setOrganisationData(_channelDataByChannelIDArr);
    //       setWaitChannelFetch(false);
    //       setBackdrop(false)
    //     },
    //     error => {
    //       notify({
    //         type: "error",
    //         message: "Error while fetching channels data by channel ID"
    //       });
    //     }
    //   );
    // }
  };

  const handleChangeShipmentNumber = prop => (event) => {

    if (!SHIPMENT_REF_NUM_VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }

    let selectedShipmentType;
    let clearedShipmentType;

    if (SHIPMENT_NUMBER_REGEX.test(event.target.value.trim())) {
      selectedShipmentType = "shipmentNumber";
      clearedShipmentType = "shipmentReference";
    } else {
      selectedShipmentType = "shipmentReference";
      clearedShipmentType = "shipmentNumber";
    }

    setValues({ ...values, [prop]: event.target.value, [selectedShipmentType]: event.target.value, [clearedShipmentType]: "" });
  };

  function fetchChannelsByOrgId() {
    setBackdrop(true);
    channelService.getChannelsByOrgId(organizationID).then(
      response => {
        //setBackdrop(false);
        const _channelData = response.data;

        var dict = {}
        var arrChannel = []
        for (let i = 0; i < _channelData.length; i++) {
          arrChannel.push(_channelData[i].channelName)
          dict[_channelData[i].channelName] = _channelData[i].id
        }

        setChannelIDMap(dict)
        setChannels(arrChannel)
        setBackdrop(false);
        setDrawerState({ ...drawerState, ["right"]: true })

      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching channels data by org ID"
        });
      }
    );
  }


  function fetchShipmentsSharedByOrgID(query) {
    setBackdrop(true);
    shipmentService.getShipmentsSharedByOrgID(organizationID, query).then(
      response => {

        let _shipmentData = response.data[0];

        for (let i = 0; i < _shipmentData.length; i++) {
          _shipmentData[i]['ShipmentNo'] = i + 1 + (query['_page'] - 1) * (query['_limit']);
        }
        setRows(_shipmentData);
        if (response.headers.hasOwnProperty('x-total-count')) {
          setQueryCount(parseInt(response.headers['x-total-count']));
        }
        if (!query['q']) {
          setOriginalRows(_shipmentData);
          setOriginalQueryCount(parseInt(response.headers['x-total-count']));
        }
        setshipmentInfoData(_shipmentData);
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        if (!query['q']) {
          setRows([]);
        }
        else {
          notify({
            type: "error",
            message: "Error while fetching shipments data"
          });
        }
      }
    );

    shipmentService.getUniqueColumnValuesShipmentsSharedWithOrgId(organizationID).then(
      response => {
        let _shipmentData = response.data;

        let podArray = []
        for (let j = 0; j < _shipmentData[0].length; j++) {
          if (_shipmentData[0][j]["port_of_departure"] && _shipmentData[0][j]["port_of_departure"].length) {
            podArray.push(_shipmentData[0][j]["port_of_departure"])
          }
        }

        let poaArray = []
        for (let j = 0; j < _shipmentData[1].length; j++) {
          if (_shipmentData[1][j]["port_of_arrival"] && _shipmentData[1][j]["port_of_arrival"].length) {
            poaArray.push(_shipmentData[1][j]["port_of_arrival"])
          }
        }
        let channelArray = []
        let tempDict = {}
        for (let j = 0; j < _shipmentData[2].length; j++) {
          if (_shipmentData[2][j]["channel_name"] && _shipmentData[2][j]["channel_name"].length) {
            channelArray.push(_shipmentData[2][j]["channel_name"])
          }
          tempDict[_shipmentData[2][j]["channel_name"]] = _shipmentData[2][j]["channel_id"]
        }

        let carrierArray = []
        for (let j = 0; j < _shipmentData[3].length; j++) {
          if (_shipmentData[3][j]["carrier"] && _shipmentData[3][j]["carrier"].length) {
            carrierArray.push(_shipmentData[3][j]["carrier"])
          }
        }

        let statusArray = []
        for (let j = 0; j < _shipmentData[4].length; j++) {
          if (_shipmentData[4][j]["status"] && _shipmentData[4][j]["status"].length) {
            statusArray.push(_shipmentData[4][j]["status"])
          }
        }
        setDictChannels(tempDict);
        setInputArrays({
          "status": statusArray,
          "channelName": channelArray,
          "poaName": poaArray,
          "podName": podArray,
          "carrier": carrierArray
        });
      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching filter values"
        });
      }
    )

  }

  const fetchData = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = null, prop = null, filterArray = null) => {
    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder,
    }
    if (searchText) {
      query['q'] = searchText;
    }

    if (prop == "podName") {
      if (filterArray.length > 0) {
        query["_podName"] = filterArray;
      }
    } else {
      if (selectedArrays["podName"].length > 0) {
        query["_podName"] = selectedArrays["podName"];
      }
    }

    if (prop == "poaName") {
      if (filterArray.length > 0) {
        query["_poaName"] = filterArray;
      }
    } else {
      if (selectedArrays["poaName"].length > 0) {
        query["_poaName"] = selectedArrays["poaName"];
      }
    }

    let channelQuery = []
    if (prop == "channelName") {
      if (filterArray.length > 0) {
        for (const eachChannelName of filterArray) {
          channelQuery.push(dictChannels[eachChannelName]);
        }
        query["_channelId"] = channelQuery
      }
    } else {
      if (selectedArrays["channelName"].length > 0) {
        for (const eachChannelName of selectedArrays["channelName"]) {
          channelQuery.push(dictChannels[eachChannelName]);
        }
        query["_channelId"] = channelQuery
      }
    }

    if (prop == "carrier") {
      if (filterArray.length > 0) {
        query["_carrier"] = filterArray;
      }
    } else {
      if (selectedArrays["carrier"].length > 0) {
        query["_carrier"] = selectedArrays["carrier"];
      }
    }

    if (prop == "status") {
      if (filterArray.length > 0) {
        query["_status"] = filterArray;
      }
    } else {
      if (selectedArrays["status"].length > 0) {
        query["_status"] = selectedArrays["status"];
      }
    }

    fetchShipmentsSharedByOrgID(query);
  };

  const buttonClickFilter = () => event => {
    if (filter) {
      setFilter(false)
    } else {
      setFilter(true)
    }
  }

  const handleChangeMulitpleSelect = prop => (event) => {
    let newArray = Array.from(event.target.value);
    setSelectedArrays(prevState => {
      return { ...prevState, [prop]: newArray };
    });
    fetchData(rowsPerPage, 0, orderBy, "asc", null, prop, event.target.value);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = () => {
    setBackdrop(true);
    let createShipmentReqObj;
    if (radioButtonValue === "ByShipmentRef") {
      createShipmentReqObj = {
        "channelId": channelIDMap[values.Channel],
        "shipmentNumber": values.shipmentNumber,
        "shipmentReference": values.shipmentReference
      }

      shipmentService.createShipmentByShipmentReference(organizationID, createShipmentReqObj).then(
        response => {
          refreshTable();
          setBackdrop(false);
        },
        error => {
          setBackdrop(false);
          if (error.response && error.response.status === 409) {
            notify({
              type: "error",
              message: "This shipment is already imported."
            })
          }
          else if (error.response && error.response.status === 404) {
            notify({
              type: "error",
              message: "Shipment is not found in Cargo Flow."
            });
          } else {
            notify({
              type: "error",
              message: "Error while creating shipment"
            });

          }
        }
      );
    }
    else {
      createShipmentReqObj = buildRequestObj(portsMap, values, channelIDMap, containerArray);

      if (!createShipmentReqObj) return;

      shipmentService.createShipment(organizationID, createShipmentReqObj).then(
        response => {
          refreshTable();
          setBackdrop(false);
        },
        error => {
          setBackdrop(false);
          if (error.response.status === 409) {
            notify({
              type: "error",
              message: "This shipment reference is already used. Provide a new one"
            })
          }
          else if (error.response.status === 404) {
            notify({
              type: "error",
              message: "Shipment is not found in Cargo Flow."
            });
          } else {
            notify({
              type: "error",
              message: "Error while creating shipment"
            });

          }
        }
      );
    }
  };

  function disableCreateShipmentSubmitButton() {
    if (radioButtonValue === "ByShipmentRef") {
      return disableSubmitButtonByShipmentsRef(values.Channel, values.shipmentNumber, values.shipmentReference);
    } else {
      return disableSubmitButton(values.Channel, values.PortOfArrival, values.PortOfDeparture, containerArray, values.shipmentReference);
    }
  }

  const handleSubmitEditShipment = () => {
    let editShipmentApiReqObj = buildRequestObjEditShipment(organizationID, editRow, values);
    setBackdrop(true);
    if (!editShipmentApiReqObj) return;
    shipmentService.updateShipment(editRow["id"], editShipmentApiReqObj).then(
      response => {
        refreshTable();
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while updating shipment"
        });
      }
    );
  };

  const buttonClickCreateANewShipment = (side, open, row) => event => {
    fetchChannelsByOrgId();
    setDrawerState({ ...drawerState, ["right"]: true });
    setSideDrawerNumber(1)
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
  }

  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
    setInitialState();
    setInvalidUserInput([]);
  };

  const getCurrentShipment = (row) => {
    var currentShipment = shipmentInfoData.filter(function (shipment) {
      return shipment.id == row.id;
    });
    return currentShipment;
  };

  function refreshTable() {
    setDrawerState({ ...drawerState, ["right"]: false });
    fetchData();
    setInitialState();
  }

  const handleRequestSort = property => event => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
    fetchData(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    fetchData(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    fetchData(+event.target.value, 0);
  };

  const clearSearchText = () => {
    setSearchKey({ value: "" });
    fetchData();
  };

  let timeout = null;
  const handleSearch = event => {
    const _val = event.target.value;
    setSearchKey(prevState => {
      return { ...prevState, value: _val };
    });
    if (_val.length <= 2) {
      setRows(originalRows);
      setQueryCount(originalqueryCount);
      return;
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fetchData(rowsPerPage, 0, orderBy, order, _val);
    }, 200);
  };

  function renderAgain() {
    if (render) {
      setRender(false)
    } else {
      setRender(true)
    }
  }

  function validateProductInputs(prop, value, regex) {
    if (!regex.test(value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      removeInvalidUserInputKey(prop);
    }
  }

  const handleChangeProducts = (containerIndex, productIndex, property) => (event) => {
    let tempProduct = containerArray[containerIndex]["products"][productIndex]
    tempProduct[property] = event.target.value
    containerArray[containerIndex]["products"][productIndex] = tempProduct;

    let containerCheckId = containerArray[containerIndex]['validationCheckId'];
    let productCheckId = containerArray[containerIndex]["products"][productIndex]['validationCheckId'];

    let prop = containerCheckId + "_" + productCheckId + "_" + property;
    validateProductInputs(prop, event.target.value, VALID_USER_INPUT_REGEX);
    renderAgain()
  };

  const handleChangeContainers = (containerIndex, property) => (event) => {
    let tempContainer = containerArray[containerIndex]
    tempContainer[property] = event.target.value;
    containerArray[containerIndex] = tempContainer;

    let containerCheckId = containerArray[containerIndex]['validationCheckId'];
    let prop = containerCheckId + "_" + property;
    validateProductInputs(prop, event.target.value, VALID_USER_INPUT_REGEX);
    renderAgain()
  };

  const handleRemoveProduct = (containerIndex, productIndex) => {
    let tempContainerData = containerArray;

    let containerCheckId = containerArray[containerIndex]['validationCheckId'];
    let productCheckId = containerArray[containerIndex]["products"][productIndex]['validationCheckId'];

    for (let key in tempContainerData[containerIndex]["products"][productIndex]) {
      if (key !== "validationCheckId") {
        let prop = containerCheckId + "_" + productCheckId + "_" + key;
        removeInvalidUserInputKey(prop);
      }
    }

    tempContainerData[containerIndex]["products"].splice(productIndex, 1);
    let tempNumberOfProducts = numberOfProducts;
    tempNumberOfProducts[containerIndex] -= 1;
    setNumberOfProducts(tempNumberOfProducts);
    setContainerArray(tempContainerData);

    let tempOpenPanels = openPanels;
    delete tempOpenPanels[containerCheckId + "_" + productCheckId];
    setOpenPanels(tempOpenPanels);

    renderAgain()
  }

  const handleRemoveContainer = (containerIndex) => {
    let tempContainerData = containerArray;
    let containerCheckId = containerArray[containerIndex]['validationCheckId'];

    for (let index in tempContainerData[containerIndex]["products"]) {
      handleRemoveProduct(containerIndex, index);
    }

    tempContainerData.splice(containerIndex, 1);
    let tempNumberOfProducts = numberOfProducts;
    tempNumberOfProducts.splice(containerIndex, 1);
    setNumberOfProducts(tempNumberOfProducts);
    setContainerArray(tempContainerData);
    setNumberOfContainers(tempContainerData.length);

    let prop = containerCheckId + "_" + "containerNumber";

    let tempOpenPanels = openPanels;
    delete tempOpenPanels[containerCheckId];
    setOpenPanels(tempOpenPanels);

    removeInvalidUserInputKey(prop);
    renderAgain();
  }

  const handleAddProduct = (containerNumber) => {
    let tempContainerData = containerArray;
    let containerCheckId = containerArray[containerNumber]['validationCheckId'];
    let productCheckId = Util.makeId(5);
    tempContainerData[containerNumber]["products"].push({
      "validationCheckId": productCheckId,
      "productNumber": null,
      "productDescription": null,
      "productHsCode": null,
      "purchaseOrderNumber": null,
      "unitPrice": null,
      "quantity": null
    })
    let tempNumberOfProducts = numberOfProducts;
    tempNumberOfProducts[containerNumber] += 1;
    setNumberOfProducts(tempNumberOfProducts);
    setContainerArray(tempContainerData);

    let tempOpenPanels = openPanels;
    tempOpenPanels[containerCheckId + "_" + productCheckId] = true;
    setOpenPanels(tempOpenPanels);

    renderAgain();
  }

  const handleAddContainer = () => {
    let tempContainerData = containerArray;
    let containerCheckId = Util.makeId(5);
    tempContainerData.push({
      "validationCheckId": containerCheckId,
      "containerNumber": null,
      "products": []
    });
    setContainerArray(tempContainerData);
    setNumberOfContainers(numberOfContainers + 1);

    let tempNumberOfProducts = numberOfProducts;
    tempNumberOfProducts.push(0);
    setNumberOfProducts(tempNumberOfProducts);

    let tempOpenPanels = openPanels;
    tempOpenPanels[containerCheckId] = true;
    setOpenPanels(tempOpenPanels);

    renderAgain();
  }

  const handleClick = container => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenPanels({ ...openPanels, [container]: !openPanels[container] });
  };

  function createProducts(containerNumber) {
    let arr = []
    let newProductNumber = 0;
    for (let productNumber = 0; productNumber < numberOfProducts[containerNumber]; productNumber++) {

      let productValidationId = containerArray[containerNumber]["validationCheckId"] + "_" + containerArray[containerNumber]["products"][productNumber]["validationCheckId"];
      arr.push(
        <div>
          <ListItem style={{ background: "#f8f8f8", border: "0.8px solid #00000021" }} button onClick={handleClick(productValidationId)}>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText style={{ fontWeight: "bold" }} primary={"Product #" + (productNumber + 1)} />
            {openPanels[productValidationId] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPanels[productValidationId]} timeout="auto" unmountOnExit>
            <Paper className={classes.rootProduct} elevation={0}>
              <form className={classes.shipmentForm}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  key={containerNumber + "productName" + (productNumber + 1)}
                  required
                  className={classes.textField}
                  label={"Product Number"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + containerArray[containerNumber]["products"][productNumber]['validationCheckId'] + "_" + "productNumber")}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["products"][productNumber]["productNumber"]}
                  onChange={handleChangeProducts(containerNumber, productNumber, "productNumber")}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  key={containerNumber + "productDescription" + (productNumber + 1)}
                  className={classes.textField}
                  label={"Description"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + containerArray[containerNumber]["products"][productNumber]['validationCheckId'] + "_" + "productDescription")}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["products"][productNumber]["productDescription"]}
                  onChange={handleChangeProducts(containerNumber, productNumber, "productDescription")}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  key={containerNumber + "productHsCode" + (productNumber + 1)}
                  className={classes.textField}
                  label={"HS Code"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + containerArray[containerNumber]["products"][productNumber]['validationCheckId'] + "_" + "productHsCode")}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["products"][productNumber]["productHsCode"]}
                  onChange={handleChangeProducts(containerNumber, productNumber, "productHsCode")}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  key={containerNumber + "purchaseOrderNumber" + (productNumber + 1)}
                  className={classes.textField}
                  label={"Purchase Order Number"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + containerArray[containerNumber]["products"][productNumber]['validationCheckId'] + "_" + "purchaseOrderNumber")}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["products"][productNumber]["purchaseOrderNumber"]}
                  onChange={handleChangeProducts(containerNumber, productNumber, "purchaseOrderNumber")}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  key={containerNumber + "unitPrice" + (productNumber + 1)}
                  className={classes.textField}
                  label={"Unit Price"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + containerArray[containerNumber]["products"][productNumber]['validationCheckId'] + "_" + "unitPrice") || checkIfNotNumber(containerArray[containerNumber]["products"][productNumber]["unitPrice"])}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["products"][productNumber]["unitPrice"]}
                  onChange={handleChangeProducts(containerNumber, productNumber, "unitPrice")}
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  key={containerNumber + "quantity" + (productNumber + 1)}
                  className={classes.textField}
                  label={"Quantity"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + containerArray[containerNumber]["products"][productNumber]['validationCheckId'] + "_" + "quantity") || checkIfNotNumber(containerArray[containerNumber]["products"][productNumber]["quantity"])}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["products"][productNumber]["quantity"]}
                  onChange={handleChangeProducts(containerNumber, productNumber, "quantity")}
                />
              </form>
              <div>
                {newProductNumber === 0 ?
                  ""
                  :
                  <Button autoFocus startIcon={<RemoveCircleIcon />} className={classes.removeButton} color="primary"
                    onClick={() => handleRemoveProduct(containerNumber, productNumber)} disabled={disableAddProductButton()}>
                    Remove Product #{productNumber + 1}
                  </Button>
                }
              </div>
            </Paper>

          </Collapse>
        </div>
      )
      newProductNumber = newProductNumber + 1

    }

    return arr
  }

  const buttonClickClearAllFilters = () => {
    let doRefresh = false
    if (selectedArrays.poaName.length > 0 || selectedArrays.podName.length > 0 || selectedArrays.channelName.length > 0 || selectedArrays.carrier.length > 0 || selectedArrays.status.length > 0) {
      doRefresh = true
    }
    selectedArrays.poaName = []
    selectedArrays.podName = []
    selectedArrays.channelName = []
    selectedArrays.carrier = []
    selectedArrays.status = []
    if (doRefresh) {
      refreshTable();
    }
  }

  function createContainer() {
    let arr = []
    let newContainerNumber = 0;
    for (let containerNumber = 0; containerNumber < numberOfContainers; containerNumber++) {

      let containerValidationId = containerArray[containerNumber]['validationCheckId'];
      arr.push(
        <div>
          <ListItem style={{ background: "#f8f8f8", border: "0.8px solid #00000021" }} button onClick={handleClick(containerValidationId)}>
            <ListItemIcon>
            </ListItemIcon>
            <ListItemText style={{ fontWeight: "bold" }} primary={"Container #" + (containerNumber + 1)} />
            {openPanels[containerValidationId] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openPanels[containerValidationId]} timeout="auto" unmountOnExit>
            <Paper className={classes.rootProduct}>
              <div className={classes.flexItemProduct}>
                <TextField
                  id="standard-basic"
                  key={"Container #" + (containerNumber + 1)}
                  required
                  className={classes.textField}
                  label={"Container #" + (newContainerNumber + 1) + " Number"}
                  error={invalidUserInput.includes(containerArray[containerNumber]['validationCheckId'] + "_" + "containerNumber")}
                  margin="normal"
                  rows="2"
                  fullWidth
                  value={containerArray[containerNumber]["containerNumber"]}
                  onChange={handleChangeContainers(containerNumber, "containerNumber")}
                />
              </div>
              <div>
                {createProducts(containerNumber)}
                <div className={classes.formContainer}>
                  <Button autoFocus startIcon={<AddCircleIcon />} className={classes.addButton} color="primary"
                    onClick={() => handleAddProduct(containerNumber)} disabled={disableAddProductButton()}>
                    Add Product
                    </Button>
                </div>
              </div>
              <div>
                {newContainerNumber === 0 ?
                  ""
                  :
                  <Button autoFocus startIcon={<RemoveCircleIcon />} className={classes.removeButton} color="primary"
                    onClick={() => handleRemoveContainer(containerNumber)} disabled={disableAddProductButton()}>
                    Remove Container #{containerNumber + 1}
                  </Button>
                }
              </div>
            </Paper>
          </Collapse>

        </div>
      )
      newContainerNumber = newContainerNumber + 1

    }

    return arr
  }

  const handleClickonRow = (event, row) => {
    var newState = { "shipmentValue": getCurrentShipment(row), "id": row.id, "ShipmentNo": row.ShipmentNo }
    props.history.push({ pathname: '/shipment/' + row.id, state: newState });
  };

  const handleClickEditShipment = (side, open, row) => event => {
    event.stopPropagation();
    event.preventDefault();
    let tempEditRow = row;
    setEditRow(tempEditRow);
    setSideDrawerNumber(2)
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open })
  }

  const handleChangeRadioButton = event => {
    if (event.target.value === "ByShipmentRef") {
      values.movementType = "CY-CY";
    }
    setRadioButtonValue(event.target.value);
  };

  const createShipmentTable = () => {
    let table = []
    for (let index = 0; index < rows.length; index++) {
      let row = rows[index]
      table.push(
        <TableRow hover style={{ cursor: "pointer", height: "60px" }} role="data-row" tabIndex={-1} key={index} onClick={event => handleClickonRow(event, row)} >
          {columns.map(column => {
            var value = "-";
            if (row[column.id]) {
              value = row[column.id];
            }
            if (column.id === "edit") {
              return (
                <TableCell width={tableHeadWidth} key={column.id} align="center">
                  <Button className={classes.buttonDownload} onClick={handleClickEditShipment("right", true, row)}>
                    <EditIcon />
                  </Button>
                </TableCell>
              );
            } else if (column.id === "isDocumentUploaded") {
              return (
                <TableCell width={tableHeadWidth} key={column.id} align="center">
                  {value && value !== "-" ?
                    <HtmlTooltip placement="top"
                      title={
                        <React.Fragment>
                          {"At least one document is uploaded in this shipment"}
                        </React.Fragment>
                      }
                    >
                      <div><AiOutlineFileText style={{ height: "1.5em", width: "1.5em" }}></AiOutlineFileText></div>
                    </HtmlTooltip>
                    // <Tooltip title="At least one document is uploaded in this shipment" placement="top">
                    //   <div><AiFillFileText style={{ height: "1.5em", width: "1.5em" }}></AiFillFileText></div>
                    // </Tooltip>

                    :

                    <HtmlTooltip placement="top"
                      title={
                        <React.Fragment>
                          {"No documents are uploaded to this shipment"}
                        </React.Fragment>
                      }
                    >
                      <div><AiOutlineFileExclamation style={{ height: "1.5em", width: "1.5em", color: "red" }}></AiOutlineFileExclamation></div>
                    </HtmlTooltip>
                    // <Tooltip title="No documents are uploaded to this shipment" placement="top">
                    //   <div><AiFillFileExclamation style={{ height: "1.5em", width: "1.5em" }}></AiFillFileExclamation></div>
                    // </Tooltip>
                  }
                </TableCell>
              )
            } else if (column.id === "ata" || column.id === "eta") {
              return (
                <TableCell width={tableHeadWidth} key={column.id} align="center">
                  {value && value !== "-" ? <Moment format="DD/MM/YYYY">{value}</Moment> : "-"}
                </TableCell>
              )
            } else if (column.id === "vessel") {
              if (!row["vessel"] || !row["voyage"]) {
                value = "-";
              } else {
                value = row["vessel"] + " " + row["voyage"];
              }

              return (
                <TableCell width={tableHeadWidth} key={column.id} align="center">
                  <Highlight search={searchKey.value}>
                    {value}
                  </Highlight>
                </TableCell>
              )
            }
            else {
              if (highlightableColumns.includes(column.id)) {
                return (
                  <TableCell key={column.id} align="center">
                    <Highlight search={searchKey.value}>
                      {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                    </Highlight>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={column.id} align="center">
                    {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                  </TableCell>
                );
              }
            }
          })}
        </TableRow>
      );
    }
    return table;
  }

  const createNoTable = () => {
    let arr = []
    arr.push(
      <TableRow style={{ height: "60px" }}>
        <TableCell colSpan="11" align="center" className={classes.noRecordsRow}>
          {backdrop ? "Fetching the shipments, please wait" : "No shipments found, Please click on create a shipment"}
        </TableCell>
      </TableRow>
    )
    return arr;
  }

  const createShipmentByShipmentReference = side => (
    <div className={classes.flexItem}>
      <TextField
        id="outlined-basic"
        variant="outlined"
        className={classes.textField}
        error={invalidUserInput.includes("ShipmentRefNum")}
        label={"Shipment Reference Number"}
        margin="normal"
        rows="2"
        required
        fullWidth
        value={values.ShipmentRefNum}
        autoComplete='off'
        helperText="Ex SA-5HAUN9 , Coffe-4553517892"
        onChange={handleChangeShipmentNumber("ShipmentRefNum")}
      />
    </div>
  );

  const createShipmentByUserInput = side => (
    <div className={classes.flexItem}>
      <form className={classes.shipmentForm}>
        <TextField
          id="standard-basic"
          className={classes.textField}
          error={invalidUserInput.includes("Importer")}
          label={"Importer"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Importer}
          autoComplete='off'
          onChange={handleChange("Importer")}
        />
        <TextField
          id="standard-basic"
          className={classes.textField}
          error={invalidUserInput.includes("Exporter")}
          label={"Exporter"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Exporter}
          autoComplete='off'
          onChange={handleChange("Exporter")}
        />
        {/* <TextField
          id="standard-basic"
          select
          className={classes.textField}
          error={invalidUserInput.includes("PortOfArrival")}
          label={"Dest Port"}
          margin="normal"
          rows="2"
          required
          fullWidth
          value={values.poaCode}
          autoComplete='off'
          onChange={handleChange("poaCode")}
        >
          {createPorts(portsMap, values.podCode)}
        </TextField>

        <TextField
          id="standard-basic"
          select
          className={classes.textField}
          error={invalidUserInput.includes("PortOfDeparture")}
          label={"Origin Port"}
          margin="normal"
          rows="2"
          required
          fullWidth
          value={values.podCode}
          autoComplete='off'
          onChange={handleChange("podCode")}
        >
          {createPorts(portsMap, values.poaCode)}
        </TextField> */}
        {/* <span>
          <span >
            <AutoComplete handleChange={handleChangePortOfOrigin} label={"Origin Port"} />
          </span>
          <span >
            <AutoComplete handleChange={handleChangePortOfDestination} label={"Dest Port"} />
          </span>
        </span> */}
        <div className={classes.formContainer}>
          <div className={classes.flexItemPorts}>
            <AutoCompleteAsync handleChange={handleChangePortOfOrigin} label={"Origin Port"}></AutoCompleteAsync>
          </div>
          <div className={classes.flexItemPorts}>
            <AutoCompleteAsync handleChange={handleChangePortOfDestination} label={"Dest Port"}></AutoCompleteAsync>
          </div>
        </div>

        <TextField
          id="standard-basic"
          className={hideOrigin ? classes.hideDisplay : classes.textField}
          error={invalidUserInput.includes("Origin")}
          label={"Origin"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Origin}
          autoComplete='off'
          onChange={handleChange("Origin")}
        />
        <TextField
          id="standard-basic"
          className={hideDestination ? classes.hideDisplay : classes.textField}
          error={invalidUserInput.includes("Destination")}
          label={"Destination"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Destination}
          autoComplete='off'
          onChange={handleChange("Destination")}
        />
        <TextField
          id="standard-basic"
          className={classes.textField}
          error={invalidUserInput.includes("Vessel")}
          label={"Vessel"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Vessel}
          autoComplete='off'
          onChange={handleChange("Vessel")}
        />
        <TextField
          id="standard-basic"
          className={classes.textField}
          error={invalidUserInput.includes("Voyage")}
          label={"Voyage"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Voyage}
          autoComplete='off'
          onChange={handleChange("Voyage")}
        />
        <TextField
          id="standard-basic"
          className={classes.textField}
          error={invalidUserInput.includes("Carrier")}
          label={"Carrier"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.Carrier}
          autoComplete='off'
          onChange={handleChange("Carrier")}
        />
        <TextField
          id="standard-basic"
          className={classes.textField}
          error={invalidUserInput.includes("shipmentReference")}
          label={"Shipment Reference"}
          margin="normal"
          rows="2"
          fullWidth
          value={values.shipmentReference}
          autoComplete='off'
          onChange={handleChange("shipmentReference")}
        />
      </form>

      <div>
        {createContainer()}
        <div className={classes.formContainer}>
          <Button autoFocus startIcon={<AddCircleIcon />} className={classes.addButton} color="primary"
            onClick={() => handleAddContainer()} disabled={disableAddContainerButton()}>
            Add Container
          </Button>
        </div>
      </div>
    </div>
  );

  const sideList = side => (
    <div className={classes.list}>
      <div>
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Create a new shipment"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <br></br>

      <div style={{ overflow: "auto", maxHeight: "74vh" }}>
        {
          backdrop ? "" : (
            channels.length === 0 ?
              <div className={classes.flexItem}>
                <Paper className={classes.root}>
                  <div className={classes.root_noShipments}>
                    <h3>{"You are not part of any channel, Join a channel"}</h3>
                  </div>
                </Paper>
              </div>
              :
              ""
          )
        }

        <div className={classes.flexItem}>
          <FormControl component="fieldset">
            <RadioGroup aria-label="position" name="position" value={radioButtonValue} onChange={handleChangeRadioButton} row>
              <FormControlLabel
                value="ByShipmentRef"
                control={<Radio color="primary" />}
                label="By Shipment Reference"
                labelPlacement="end"
              />
              <FormControlLabel
                value="NewShipment"
                control={<Radio color="primary" />}
                label="Create new Shipment"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.flexItem}>
          <TextField
            id="standard-basic"
            select
            className={classes.textField}
            label={"Channel"}
            margin="normal"
            rows="2"
            required
            fullWidth
            value={values.Channel}
            autoComplete='off'
            disabled={channels.length === 0}
            onChange={handleChange("Channel")}
          >
            {createChannels(channels)}
          </TextField>
          <TextField
            id="standard-basic"
            select
            className={classes.textField}
            label={"Movement Type"}
            margin="normal"
            rows="2"
            required
            disabled={radioButtonValue === "ByShipmentRef"}
            fullWidth
            value={values.movementType}
            autoComplete='off'

            onChange={handleChangeMovementTypes("movementType")}
          >
            {createMovementType(movementType)}
          </TextField>
        </div>

        {
          radioButtonValue === "ByShipmentRef" ? (
            <div className={classes.flexItem}>
              {createShipmentByShipmentReference()}
            </div>
          ) : (
              <div className={classes.flexItem}>
                {createShipmentByUserInput()}
              </div>
            )
        }

      </div>

      <div class={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmit} variant="contained" color="primary" disabled={backdrop || invalidUserInput.length || disableCreateShipmentSubmitButton()}>
          {"SUBMIT"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>

      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div >
  );

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={event => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  TabPanelSideDrawer.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function TabPanelSideDrawer(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  const sideListEditShipment = side => (
    <div className={classes.list}>
      <Toolbar>
        <div className={classes.drawerHeader}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={closeDrawer("right", false)}
            edge="start"
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
        <Typography variant="h6" noWrap>{"Edit shipment"}</Typography>
      </Toolbar>
      <Divider></Divider>
      <br></br>
      {/* {
        update ? <EditShipment editRow={editRow} refreshTable={refreshTable} closeDrawer={closeDrawer} ></EditShipment>
          : ""
      } */}

      {/* DO NOT REMOVE THIS
        <Tabs
          variant="fullWidth"
          indicatorColor="primary"
          value={valueModifyShipmentSideList}
          onChange={handleChangeModifyShipmentSideList}
          aria-label="nav tabs example"
        >
          <LinkTab label="UPDATE" href="/drafts" {...a11yProps(0)} />
          <LinkTab label="REVISIONS" href="/trash" {...a11yProps(1)} />
        </Tabs>
      </Toolbar>
      <Divider></Divider>
      <br></br>
      {
        update ? <EditShipment editRow={editRow} refreshTable={refreshTable} closeDrawer={closeDrawer} ></EditShipment>
          : ""
      }
      <TabPanelSideDrawer value={valueModifyShipmentSideList} index={1} >
        <Grid container spacing={1}>
          <Grid container item xs={12} spacing={3}>
            <ShipmentRevision xsValue={12} blockNo={""} blockPage={false} shipmentID={editRow.id}></ShipmentRevision>
          </Grid>
        </Grid>
      </TabPanelSideDrawer> */}
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  return (
    <div className={classes.borderMain}>
      <Backdrop open={backdrop} style={{ color: "primary", zIndex: 100 }} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.root}>
        <Grid item xs={12} style={{ minHeight: "87vh" }}>
          <div className={classes.tableTitle}>
            <h2>{"Shipments"}</h2>
          </div>
          <div className={classes.tableHeadRight}>
            <div className={classes.shipmentOptionDiv}>
              <div className={classes.searchBox}>
                <InputBase
                  onChange={handleSearch}
                  value={searchKey.value}
                  className={classes.input}
                  placeholder={"Search Shipments"}
                />
                {searchKey.value.length ? (
                  <IconButton
                    size="medium"
                    aria-label="search"
                    onClick={clearSearchText}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                    ""
                  )}
                <IconButton
                  size="medium"
                  aria-label="search"
                  disabled
                >
                  <SearchIcon />
                </IconButton>
              </div>
              <Button
                color="primary"
                className={classes.button}
                onClick={buttonClickCreateANewShipment("right", true)}
                startIcon={<AddCircleIcon />}
                disabled={localStorage.getItem("_orgCode") !== "dpw-cargoes"}
              >
                {"Create a Shipment"}
              </Button>
            </div>
            <div className={classes.tableHeadRightFilters}>
              {
                filter ?
                  <div className={classes.filterDiv}>
                    <div className={classes.filterTextFieldDiv}>
                      <Grid item xs={12} style={{ minHeight: "10vh" }}>
                        {
                          columns.map(column => (
                            column.isFilterable ?
                              <FormControl variant="outlined" className={classes.filterTextField}>
                                <InputLabel id="demo-mutiple-checkbox-label">{placeHolders[column.id]}</InputLabel>
                                <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={selectedArrays[column.id]}
                                  onChange={handleChangeMulitpleSelect(column.id)}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  variant="outlined"
                                >
                                  {inputArrays[column.id].map((name) => (
                                    <MenuItem key={name} value={name}>
                                      <Checkbox checked={selectedArrays[column.id].indexOf(name) > -1} />
                                      <ListItemText primary={name} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              :
                              ""

                          ))
                        }
                      </Grid>
                    </div>
                    <div className={classes.clearFilterButton}>
                      <Button
                        color="primary"
                        onClick={buttonClickClearAllFilters}
                      >
                        {"Clear All"}
                      </Button>
                    </div>
                  </div>
                  :
                  ""
              }
            </div>
          </div>
          {/* <div>
            <Button
              color="primary"
              className={classes.button}
              onClick={buttonClickFilter()}
              startIcon={<SearchIcon />}
            >
              {"Filter"}
            </Button>
          </div> */}

          <div>
          </div>
          <div className={classes.tableWrapper}>
            <Paper className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                <TableHead>
                  <TableRow >
                    {columns.map(column => (
                      <TableCell style={{ fontWeight: "bold" }}
                        width={tableHeadWidth}
                        key={column.id}
                        align="center"
                        sortDirection={orderBy === column.id ? order : false}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={handleRequestSort(column.id)}
                            className={classes.tableColumnLabel}
                          >
                            {column.label}
                            {orderBy === column.id ? (
                              <span className={classes.visuallyHidden}>
                                {order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                            column.label
                          )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.length === 0 ? createNoTable() : createShipmentTable()}
                </TableBody>
                <Drawer
                  anchor="right"
                  open={drawerState.right}
                >
                  {sideDrawerNumber === 1 ? sideList('right') : sideDrawerNumber === 2 ? sideListEditShipment('right') : ""}
                </Drawer>
              </Table>
            </Paper>
          </div>
          {rows.length === 0 ? "" :
            <TablePagination
              rowsPerPageOptions={userPrefs.rowsPerPageOptions}
              component="div"
              count={queryCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                "aria-label": "previous page"
              }}
              nextIconButtonProps={{
                "aria-label": "next page"
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={"Rows per page"}
            />
          }
        </Grid>
      </Paper>
    </div>
  );
}
