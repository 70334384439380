import { toast } from "react-toastify";

const SUCCESS = "success";
const ERROR = "error";
const INFO = "info";
const WARN = "warn";

export default function Notify(obj) {
  let styleOptions = {
    hideProgressBar: true
  }
  if (Object.keys(obj).includes('autoClose')) {
    styleOptions['autoClose'] = obj.autoClose
  }
  if (Object.keys(obj).includes('limit')) {
    styleOptions['limit'] = obj.limit
  }
  switch (obj.type) {
    case SUCCESS:
      toast.success(obj.message, styleOptions);
      break;
    case ERROR:
      toast.error(obj.message, styleOptions);
      break;
    case INFO:
      toast.info(obj.message, styleOptions);
      break;
    case WARN:
      toast.warn(obj.message, styleOptions);
      break;
    default:
      toast.info(obj.message, styleOptions);
  }
}