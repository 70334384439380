import { blue, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

const useStyles = makeStyles({
  list: {
    width: "60vw"
  },

  hideDisplay: {
    display: "none"
  },

  root: {
    width: "100%",
    background: "white"
  },
  rootProduct: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: "98%",
    background: "white"
  },
  root_noShipments: {
    width: "100%",
    background: "#F5C6CB",
    color: "#721C24",
    textAlign: "center",
    padding: '1px 3px 4px'
  },
  formControlSelect: {
    marginRight: theme.spacing(3),
    minWidth: 200,
  },
  formControlSelectLength: {
    minWidth: 200,
  },
  borderMain: {
    marginLeft: "-10px",
    marginRight: "-10px",
    marginTop: "-1px",
  },
  root_drawer: {
    flexGrow: 1,
    padding: '12px 30px 48px'
  },
  tableWrapper: {
    width: "100%",
    maxHeight: "70vh",
    overflow: "auto",
    fontFamily: "courier"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableColumnLabel: {
    marginLeft: theme.spacing(.5),
  },
  closeButton: {
    fontFamily: "timesnewroman",
    margin: theme.spacing(1),
    textAlign: "center",
    marginTop: "auto",
    display: "flex",
  },
  buttonDownload: {
    background: "none",
    padding: 0,
    fontSize: "11px"
  },
  tableHeadRight: {
    float: "right",
    display: "block"
  },
  tableTitle: {
    float: "left",
    paddingLeft: "20px"
  },
  paper: {
    width: "45%",
    color: theme.secondaryColor,
    background: theme.primaryColor
  },
  displayFlex: {
    display: "flex"
  },
  searchBox: {
    lineHeight: "0px",
    borderBottom: "0.8px solid #00000021",
    paddingBottom:'1px',
    marginBottom:'20px',
    marginRight:theme.spacing(2)
  },
  addButton: {
    fontFamily: "timesnewroman",
    margin: theme.spacing(3),
    textAlign: "center",
    display: "flex"
  },
  removeButton: {
    fontFamily: "timesnewroman",
    margin: theme.spacing(3),
    textAlign: "center"
  },
  shipmentInfopara: {
    background: "#f8f8f8",
    padding: "40px"
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  sideBar: {
    width: "40vw",
    height: "4.5vw",
    alignItems: "center"
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  flexItem: {
    flex: 1,
    margin: theme.spacing(1),
    padding: '12px 30px 12px'
  },

  flexItemPorts: {
    flex: 1,
    margin: theme.spacing(1),
    padding: '12px 0px 12px'
  },
  flexItemProduct: {
    flex: 1,
    margin: theme.spacing(1),
    padding: '12px 30px 12px'
  },
  textField: {
    paddingRight: theme.spacing(4)
  },
  button: {
    paddingRight: theme.spacing(2.5)
  },
  drawerButton: {
    margin: theme.spacing(2),
    float: "right",
  },
  disableHoverIcon: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      background: 'none'
    }
  },
  sidedrawerFooter: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "#ffffff",
  },
  tableBorder: {
    border: "0.8px solid #00000021"
  },
  tableContainer: {
    padding: "20px",
    paddingTop: "0px"
  },
  noRecordsRow: {
    background: "#f8f8f8"
  },
  shipmentForm: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '47%',
    },
  },
  tableHeadRightFilters:{
    float: "right",
  },
  shipmentOptionDiv: {
    justifyContent: "flex-end",
    display: "flex",
    marginLeft:theme.spacing(10),
    paddingLeft:theme.spacing(20)
  },
  filterTextField: {
    float: "left",
    width: "15vw",
    paddingRight: theme.spacing(2)
  },
  filterDiv: {
    display: "flex"
  },
  filterTextFieldDiv: {
    
  },
  clearFilterButton: {
    // paddingRight: theme.spacing(2.5),
    marginRight: "1rem",
    marginTop: "1rem"
  },
});

export default useStyles;