import axios from 'axios'
import authUtil from '../utils/authUtil'
import Util from '../utils/Util'

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`

function getDocumentsByOrgId (organizationId, query) {
    let queryParams = Util.buildQueryParams(query);
    return axios.get( BASE_API_URL + `/documents/organizations/` + organizationId + queryParams, {
        headers: {
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    })
}

function getShipmentViewDocumentInfo(organizationId, query) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/documents/shipmentView/` + organizationId + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getBookingViewDocumentInfo(organizationId, query) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/documents/bookingView/` + organizationId + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function uploadBulkDocuments (requestObj) {
    return axios.post( BASE_API_URL + `/documents/bulkupload`, requestObj, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    })
}

function uploadBulkDocumentsV2 (requestObj) {
  return axios.post( BASE_API_URL + `/documents/bulkuploadv2`, requestObj, {
      headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authUtil.getToken()
      }
  })
}

function updateDocument(documentID, requestObj) {
    return axios.post(
      BASE_API_URL + `/documents/` + documentID,
      requestObj,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

function updateDocumentV2(documentID, requestObj) {
  return axios.post(
    BASE_API_URL + `/documents/` + documentID + `/update`,
    requestObj,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getDocumentRevisions(documentID) {
    return axios.get(
      BASE_API_URL +
      `/documents/` +
      documentID +
      `/revisions`,
      {
        headers: {
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

function getOrganizationsListForPrivateDoc (documentId) {
    return axios.get( BASE_API_URL + `/documents/` + documentId + '/organizations', {
        headers: {
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    })
}

function sharePrivateDocument (documentId, requestObj) {
    return axios.post( BASE_API_URL + `/documents/` + documentId + '/share', requestObj, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    })
}


function downloadDocument(documentID, version = null, stream = false) {
    let query = (version) ? `?stream=${stream}&version=${version}` : `?stream=${stream}`;
    return axios.get(
      BASE_API_URL +
      `/documents/` +
      documentID +
      `/download` + query,
      {
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

  function downloadDocumentMetadata(documentID, version = null) {
    return axios.get(
      BASE_API_URL +
      `/documents/` +
      documentID +
      `/downloadmetadata` + '?version=' + version,
      {
        headers: {
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

  function getDocumentsByShipmentID(shipmentID, query) {
    let queryParams = Util.buildQueryParams(query)
    return axios.get(
      BASE_API_URL + `/documents/shipments/${shipmentID}` + queryParams,
      {
        headers: {
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

  function getDocumentsByBookingNumber(bookingNumber, query) {
    let queryParams = Util.buildQueryParams(query)
    return axios.get(
      BASE_API_URL + `/documents/bookings/${bookingNumber}` + queryParams,
      {
        headers: {
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

  function attachShipmentsToDocument(documentId, requestObj) {
    return axios.post( BASE_API_URL + `/documents/` + documentId + '/shipments/attach', requestObj, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    }) 
  }

  function attachBookingsToDocument(documentId, requestObj) {
    return axios.post( BASE_API_URL + `/documents/` + documentId + '/bookings/attach', requestObj, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    }) 
  }

  function getChannelFilterValuesByOrgId(organizationId) {
    return axios.get(
      BASE_API_URL + `/documents/channelFilterValues/${organizationId}`,
      {
        headers: {
          Authorization: 'Bearer ' + authUtil.getToken()
        }
      }
    )
  }

export default {
    getDocumentsByOrgId,
    uploadBulkDocuments,
    uploadBulkDocumentsV2,
    getOrganizationsListForPrivateDoc,
    sharePrivateDocument,
    updateDocument,
    updateDocumentV2,
    downloadDocument,
    downloadDocumentMetadata,
    getDocumentRevisions,
    getDocumentsByShipmentID,
    getShipmentViewDocumentInfo,
    getBookingViewDocumentInfo,
    attachShipmentsToDocument,
    attachBookingsToDocument,
    getChannelFilterValuesByOrgId,
    getDocumentsByBookingNumber
}