import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles({
    list: {
        width: "30vw",
    },
    root: {
        //width: "100%",
        background: "white",
        direction: "row",
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    paperCard: {
        width: "100%",
        fontFamily: "courier",
        top: 56,
        right: -24,
        maxHeight: 400,
        padding: theme.spacing(0.4),
        backgroundColor: theme.secondaryColor,
        overflow: "scroll",
        borderTop: "1px solid white",
        display: "flex"

    },
    button: {
        margin: theme.spacing(1)
    },
    card: {
        height: "100%",
        display: "flex",
        margin: theme.spacing(2),
        boxShadow: "2px 2px 10px -5px",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1
    },
    borderMain: {
        marginLeft: "-10px",
        marginRight: "-10px",
        marginTop: "-1px",
    },
    tableHeadRight: {
        float: "right",
        display: "flex"
    },
    tableTitle: {
        width: "50%",
        float: "left",
        paddingLeft: "20px"
    },
    paper: {
        width: "45%",
        color: theme.secondaryColor,
        background: theme.primaryColor
    },
    displayFlex: {
        display: "flex"
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1
    },
    searchBox: {
        borderBottom: "0.8px solid #00000021",
        display: 'flex',
        height: theme.spacing(5),
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2)
    },
    sideBar: {
        width: "40vw",
        height: "4.5vw",
        alignItems: "center"
    },
    formContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    flexItem: {
        flex: 1,
        margin: theme.spacing(1),
        paddingLeft: theme.spacing(3)
    },
    textField: {
        paddingRight: theme.spacing(4)
    },
    drawerButton: {
        margin: theme.spacing(2),
        float: "right",

    },
    exploreButton: {
        margin: theme.spacing(1)
    },
    cardAction: {
        marginLeft: theme.spacing(3)
    },

    sidedrawerFooter: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        backgroundColor: "#ffffff",
    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        padding: '1px 3px 4px',
        marginLeft: "-10px",
    }
});
