import React from "react";
import { Route, Redirect } from "react-router-dom";
import authUtil from './authUtil';
import notify from './notifier';

export const ProtectedRoute = ({
    component: Component, Roles,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (authUtil.isAuthenticated()) {
                    //TODO: update the logic little bit
                    //TODO: check user context before returning
                    if (Roles == 'Admin') {
                        if (authUtil.isAdminOrSuperAdmin()) {
                            return <Component {...props}  {...rest} />;
                        } else {
                            setTimeout(() => {
                                window.location.href = '/'
                            }, 1);
                            return (
                                <Redirect
                                    to={{
                                        pathname: '/',
                                        state: {
                                            from: props.location
                                        }
                                    }}
                                />
                            );
                        }
                    } else {
                        return <Component {...props}  {...rest} />;
                    }


                } else {
                    notify({
                        type: "warn",
                        message: "Session timed out, please login again.",
                        autoClose: 7000,
                        limit: 1
                    });
                    setTimeout(() => {
                        window.location.href = '/login'
                    }, 1000);
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
};
