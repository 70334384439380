import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useStyles from './styles/ShipmentInformationStyles';
import {
    _documentColumns,
    _productColumns
} from './ShipmentInformationUtils';


export default function ShipmentContainers(props) {

    const classes = useStyles();
    const [openPanels, setOpenPanels] = React.useState({});
    const [productTableOrderBy, setProductTableOrderBy] = React.useState("id");

    const handleClick = container => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpenPanels({ ...openPanels, [container]: !openPanels[container] });
    };

    const createNoTable = () => {
        let arr = []
        arr.push(
            <TableRow style={{height: "60px"}}>
                <TableCell colSpan="9" align="center" className={classes.noRecordsRow}>
                    {"No products available"}
                </TableCell>
            </TableRow>
        )
        return arr;
    }

    const createProductTableInner = (row) => {
        let table = []
        for (let i = 0; i < _productColumns.length; i++) {
            var column = _productColumns[i]
            const value = row[column.id];
            table.push(
                <TableCell key={column.id} align={column.align}>
                    {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                </TableCell>
            )
        }
        return table
    }

    const createProductTable = (containerProduct) => {

        let table = []
        table.push(
            <TableRow style={{height: "60px"}} hover role="data-row" tabIndex={-1} key={containerProduct.productCode}  >
                {createProductTableInner(containerProduct)}
            </TableRow>
        )
        return table
    }

    // const createNoProductTable = () => {
    //     let arr = []
    //     arr.push(
    //         <TableRow>
    //             <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
    //                 {backdrop ? " Fetching the Products, please wait" : "No Products are found"}
    //             </TableCell>
    //         </TableRow>
    //     )
    //     return arr;
    // }

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <div></div>
            }
            className={classes.root}
        >

            {props.containers != null && props.containers.length !== 0 ? (
                props.containers.map((container) => {

                    return (
                        <div className={classes.treeViewDiv}>
                            <ListItem className={classes.treeViewCollapsableTabs} button onClick={handleClick(container.containerNumber)}>
                                <ListItemIcon>
                                </ListItemIcon>
                                <ListItemText className={classes.treeViewListItemText} primary={container.containerNumber} />
                                {openPanels[container.containerNumber] ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openPanels[container.containerNumber]} timeout="auto" unmountOnExit>
                                <Paper className={classes.tableContainer}>
                                    <Table stickyHeader aria-label="sticky table" className={classes.tableBorderProducts}>
                                        <TableHead>
                                            <TableRow>
                                                {_productColumns.map(column => (
                                                    <TableCell
                                                        key={column.id}
                                                        className={classes.tableHeadRow}
                                                        align="inherit"
                                                    >
                                                        {column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        {container.products != null && container.products.length !== 0 ? (
                                            container.products.map((product) => {


                                                return (
                                                    <TableBody>
                                                        {product.productNumber === null ? createNoTable() : createProductTable(product)}
                                                    </TableBody>
                                                );
                                            })
                                        ) : (
                                                ""
                                            )}

                                    </Table>
                                </Paper>

                            </Collapse>

                        </div>
                    );
                })
            ) : (
                    <Typography style={{ textAlign: "center" }}>No containers available</Typography>
                )}

        </List>

    );

}
