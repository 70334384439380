import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';

export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
};

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const _columns = [
  { id: 'documentNo', label: 'ID', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false },
  { id: 'originalFileName', label: 'File Name', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'name', label: 'Document Reference', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'subAssetTypeName', label: 'Document Type', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'version', label: 'Version', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false },
  { id: 'channelName', label: 'Channel', align: 'center', minWidth: 170, isFilterable: true, fitlerTypeSearch: true },
  { id: 'updatedAt', label: 'Last updated at', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'Link', label: 'Action', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false }
];

export const _columnsTreeView = [
  { id: 'documentNo', label: 'ID', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false },
  { id: 'originalFileName', label: 'File Name', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'name', label: 'Document Reference', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'subAssetTypeName', label: 'Document Type', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'version', label: 'Version', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false },
  { id: 'channelName', label: 'Channel', align: 'center', minWidth: 170, isFilterable: true, fitlerTypeSearch: true },
  { id: 'updatedAt', label: 'Last updated at', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'Link', label: 'Action', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false }
];


export const fileUploadCriteria = {
  validFileTypes: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'application/zip',
    'image/jpeg',
    'image/png'
    //'text/html'
  ],
  maxFileSizeLimit: 10000000 // 10 MB
}

export const availableTemplateTypes = ['BLLA', 'CINV', 'PORD'];

export const documentChaincodeName = "documentcc";

export const createDocumentTypes = (documentTypeData = [], radioButtonValue, documentRows = [], events = []) => {
  let table = [];

  let requiredDocs = documentTypeData.filter(i => i.isRequiredDoc);
  let notRequiredDocs = documentTypeData.filter(i => !i.isRequiredDoc);

  documentTypeData = requiredDocs.concat(notRequiredDocs);

  let documentTypeCount = {};
  for (let document of documentRows) {
    if (!documentTypeCount[document.code]) {
      documentTypeCount[document.code] = 0;
    }
    documentTypeCount[document.code] += 1;
  }

  for (let docType of documentTypeData) {

    var flag = true;
    if (events.includes(documentTypeName)) {
      flag = false;
    }

    var documentTypeName = docType.name;
    let menuText = documentTypeName;
    if (docType.isRequiredDoc) {
      menuText += String.fromCharCode(42);
    }
    if (documentTypeCount[docType.code]) {
      menuText += `(${documentTypeCount[docType.code]})`;
    }

    if (flag) {
      table.push(
        <MenuItem
          key={documentTypeName}
          value={documentTypeName}
          disabled={radioButtonValue === 'MakeADocument' && !availableTemplateTypes.includes(docType.code)}
        >
          {menuText}
        </MenuItem>
      )
    }
  }
  return table
}


export const buildRequestObjUpdateDocument = (
  file,
  documentJSON,
  documentCode,
  documentName,
  documentDescription,
  fileType,
  organizationsIds,
  shipmentIds,
  uploadVersion
) => {
  var formData = new FormData()
  formData.append('file', file)
  if (documentName) {
    formData.append('name', documentName)
  }
  if (documentDescription) {
    formData.append('description', documentDescription)
  }
  
  formData.append('data', JSON.stringify(documentJSON))
  formData.append('fileType', fileType)
  formData.append('uploadVersion', uploadVersion)
  if (organizationsIds.length) {
    formData.append('organizationIds', JSON.stringify(organizationsIds))
    formData.append('isPrivate', true)
  }
  if (shipmentIds && shipmentIds.length) {
    formData.append('shipmentIds', JSON.stringify(shipmentIds));
  }
  if (documentCode && documentCode.length) {
    formData.append('subAssetTypeCode', documentCode);
  }

  return formData
}

export const disableSubmitButtonUpdate = (
  fileType,
  file,
  documentJSON,
  updateVersion
) => {

  if (fileType === "File" && updateVersion) {
    if (file.name) {
      return false;
    }
  } else {
    if (documentJSON) {
      return false
    }
  }

  return true
}


export const buildRequestObjUploadMultiDocument = (documentsData, shipmentIds = [], documentTypeIDMap, fileType, selectedPrivateMembers = [], channelId) => {

  let docData = [];
  var formData = new FormData();
  for(let doc in documentsData) {
    let docDataObj = {
      'name' : documentsData[doc]['documentName'],
      'description': documentsData[doc]['documentDescription'],
      'subAssetTypeId': documentTypeIDMap[documentsData[doc]['documentType']],
      'data': documentsData[doc]['documentJSON'],
      'channelId' : channelId,
      'isPrivate' :  selectedPrivateMembers.length ? true : false
    };

    if (shipmentIds && shipmentIds.length) {
      docDataObj['shipmentIds'] = shipmentIds;
    }
    docDataObj['organizationIds'] = selectedPrivateMembers;

    docData.push(docDataObj)
    formData.append('file', documentsData[doc]['file']);
  }

  formData.append('documentsData', JSON.stringify(docData));
  formData.append('fileType', fileType);

  return formData;
}


export const disableSubmitButtonMultiUpload = (documentsData, radioButtonBool, selectedChannelId) => {
  
  if (documentsData.length === 0) {
    return true;
  }

  for (let doc of documentsData) {
    if (radioButtonBool === true) {
      if (selectedChannelId.length !== 0 && doc.documentType && Object.keys(doc.documentJSON).length) {
      } else {
        return true;
      }
    } else {
      if (selectedChannelId.length !== 0 && doc.file) {
      } else {
        return true;
      }
    }
  }

  return false;

}


export function createJSON(shipmentValue) {
  let tempJSON = {}
  if (shipmentValue.importerName) {
    tempJSON['importerName'] = shipmentValue.importerName
  }
  if (shipmentValue.exporterName) {
    tempJSON['exporterName'] = shipmentValue.exporterName
  }
  if (shipmentValue.origin) {
    tempJSON['origin'] = shipmentValue.origin
  }
  if (shipmentValue.destination) {
    tempJSON['destination'] = shipmentValue.destination
  }
  if (shipmentValue.vessel !== '-') {
    tempJSON['vessel'] = shipmentValue.vessel
  }
  return tempJSON
}
