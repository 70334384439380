import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { EMAIL_REGEX, VALID_USER_INPUT_REGEX } from "../utils/constants";

import theme from "../themes/lightTheme";
import {
    MenuItem, TextField, ListItemText, Backdrop, CircularProgress
} from '@material-ui/core';
import UserManagementService from "../../src/services/UserManagementService";
import notify from "../utils/notifier";
const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1
    },
    backdrop: {
        zIndex: lightTheme.zIndex.drawer + 1,
    },
    flexItem: {
        flex: 1,

        paddingLeft: '20px',
        paddingRight: '20px',
    },
    bootstrapFormLabel: {
        fontSize: 21,
        fontWeight: "bold",
        color: "black",
        "&$labelFocused": {
            color: "black",
            fontWeight: "bold",
            fontSize: 16,
        }
    },
    margin: {
        margin: lightTheme.spacing.unit,
    },
    bootstrapRoot: {
        padding: 0,
        'label + &': {
            marginTop: lightTheme.spacing.unit * 3,
        }
    },
    dialogTitle: {
        fontWeight: "700",
        fontSize: "1.25rem",
        paddingLeft: "10px"
    },
    dialogButton: {
        fontWeight: "700",
        fontFamily: "sans-serif"
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: lightTheme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 12px',
        width: 'calc(100% - 24px)',
        transition: lightTheme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    labelRoot: {
        fontSize: 16,
        fontWeight: "bold",
        color: "black",
        "&$labelFocused": {
            color: "black",
            fontWeight: "bold",
            fontSize: 16,
        }
    },
    footer: {
        display: "flex",
        justifyContent: "space-between"
    },
    warningDiv: {
        paddingLeft: "24px"
    },
    warningText: {
        color: "black",
        fontSize: 14,
        fontWeight: "bold"
    }
}));


export default function ContactUsForm(props) {

    const classes = useStyles();
    const [topics, setTopics] = useState('');
    const [selectedTopic, setSelectedTopic] = useState('');
    const [description, setDescription] = useState('');
    const [email, setEmailId] = useState("");
    const [backdrop, setBackdrop] = React.useState(false);
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const handleTopic = event => {
        setSelectedTopic(event.target.value);
    }
    const handleChange = prop => (event) => {
        let ValidRegEx = (prop === 'email') ? EMAIL_REGEX : VALID_USER_INPUT_REGEX;
        if (!ValidRegEx.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {

                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        if (prop === 'email')
            setEmailId(event.target.value);
        else setDescription(event.target.value);
    };

    const handleSubmit = () => {
        setBackdrop(true);
        UserManagementService.addUserContactUsRequest(selectedTopic, description, email).then(
            response => {
                notify({
                    type: 'success',
                    message: 'Support request successfully submitted. We will get back to you shortly!'
                });
                handleClose();
                setBackdrop(false);
            },
            error => {
                notify({
                    type: "error",
                    message: `Failed to submit your request. Please try again`
                });
                setBackdrop(false);
            }
        );
    }
    function checkDisableSubmitButton() {
        return description === '' || selectedTopic === '' || email === '' || invalidUserInput.length;
    }

    const handleClose = () => {
        props.handleClose();
    }

    const fetchData = () => {
        setBackdrop(true);
        UserManagementService.getContactUsTopics().then(
            response => {
                setTopics(response.data);
                setBackdrop(false);
            },
            error => {
                notify({
                    type: "error",
                    message: `Failed to fetch list of questions`
                });
                setBackdrop(false);
            }
        )

    };

    React.useEffect(() => {
        fetchData();
    }, []);


    return (

        topics === '' ? "" : <div>
            <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle} gutterBottom>
                        {`Contact Us`}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogContentText}>

                        <Grid container spacing={5}>
                            <div style={{ maxHeight: '56vh', overflow: 'auto', width: "100%", marginLeft: "12px", marginRight: "12px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className={classes.flexItem}>
                                        <TextField
                                            id="standard-basic"
                                            required
                                            select
                                            className={classes.textField}
                                            label={"Please select a topic"}
                                            margin="normal"
                                            required
                                            fullWidth
                                            value={selectedTopic}
                                            onChange={handleTopic}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.labelRoot,
                                                }
                                            }}

                                        >
                                            {topics.map(topic => (
                                                <MenuItem key={topic.code} value={topic.code}>
                                                    <ListItemText primary={topic.name} />
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
                                        <TextField
                                            label="Description"
                                            id="bootstrap-input"
                                            error={invalidUserInput.includes("description")}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classes.bootstrapRoot,
                                                    input: classes.bootstrapInput,
                                                },
                                            }}
                                            multiline='true'
                                            rows='3'
                                            rowsMax='6'
                                            required
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.bootstrapFormLabel,
                                            }}
                                            onChange={handleChange('description')}
                                        />
                                        <br>
                                        </br>
                                        <TextField
                                            label="Your Email Id"
                                            id="bootstrap-input"
                                            error={invalidUserInput.includes("email")}
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classes.bootstrapRoot,
                                                    input: classes.bootstrapInput,
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                className: classes.bootstrapFormLabel,
                                            }}
                                            required
                                            onChange={handleChange('email')}
                                            helperText={invalidUserInput.includes("email") ? "Enter valid email Id" : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                            <br></br>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.footer}>
                    <div className={classes.warningDiv}>
                        <span className={classes.warningText}>
                            * For further enquiries please email us at <a href="mailto:support@datachain.cargoes.com">support@datachain.cargoes.com</a>
                        </span> 
                    </div>
                    <div style={{ marginRight: "12px" }}>
                        <Button className={classes.dialogButton} onClick={handleClose} color="primary" autofocus >
                            {`CLOSE`}
                        </Button>
                        <Button className={classes.dialogButton} onClick={handleSubmit} color="primary" autofocus disabled={checkDisableSubmitButton()} >
                            {`SUBMIT`}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
}

