// @flow

import React, { createRef, Fragment } from 'react'
import { Map, Polyline, TileLayer, Marker, Popup } from 'react-leaflet'
import './mapStyles.css'
import { originIcon, portOfDepartureIcon, portOfArrivalIcon, destinationIcon, shipmentRouteNotPresentIcon } from './customizableIcons'

const MyMarkersList = (markers) => {
  let markersArr = []
  if (markers.routeDataFetched) {
    for (let i = 0; i < markers.pastPortsArray.length; i++) {
      if (markers.pastPortsArray[i][0] !== null && markers.pastPortsArray[i][1] !== null)
        markersArr.push(<Marker position={markers.pastPortsArray[i]} icon={destinationIcon}></Marker>)
    }

    for (let i = 0; i < markers.futurePortsArray.length; i++) {
      markersArr.push(<Marker position={markers.futurePortsArray[i]} icon={portOfArrivalIcon}></Marker>)
    }

    if (markers.shipmentLocation["latitude"] && markers.shipmentLocation["longitude"]) {
      markersArr.push(<Marker position={[markers.shipmentLocation["latitude"], markers.shipmentLocation["longitude"]]} icon={originIcon}></Marker>)
    }
  } else {
    if (!markers.fetchingRouteData) {
      markersArr.push(<Marker position={[0, 0]} icon={shipmentRouteNotPresentIcon}>
        <Popup>
          <div>
            <b>
              Shipment route data currently not available.
          </b>
          </div>
        </Popup>
      </Marker>)
    }
  }

  return <Fragment>{markersArr}</Fragment>
}

function getCoordinates(routeData) {
  let coOrdinatesArray = [];
  for (let i = 0; i < routeData.length; i++) {
    coOrdinatesArray.push([routeData[i]["lat"], routeData[i]["lon"]]);
  }
  return coOrdinatesArray;
}

function getCoordinatesOfPorts(portsData) {
  let pastPortsArray = [];
  let futurePortsArray = [];
  for (let i = 0; i < portsData.length; i++) {
    if (!portsData[i]["isFuture"]) {
      pastPortsArray.push([portsData[i]["latitude"], portsData[i]["longitude"]]);
    } else {
      futurePortsArray.push([portsData[i]["latitude"], portsData[i]["longitude"]]);
    }
  }
  return [pastPortsArray, futurePortsArray];
}

export default function Map_Component(props) {

  const mapRef = createRef()
  const currentRouteCoOrdinatesGroupRef = createRef()
  const futureRouteCoOrdinatesGroupRef = createRef()
  let fetchingRouteData = props.fetchingRouteData

  function Boundaries(mapRef, currentRouteCoOrdinatesGroupRef, futureRouteCoOrdinatesGroupRef) {
    if (mapRef.current && currentRouteCoOrdinatesGroupRef.current && futureRouteCoOrdinatesGroupRef.current) {
      const map = mapRef.current.leafletElement;  //get native Map instance
      const currentRouteCoOrdinatesGroup = currentRouteCoOrdinatesGroupRef.current.leafletElement; //get native featureGroup instance
      const futureRouteCoOrdinatesGroup = futureRouteCoOrdinatesGroupRef.current.leafletElement;
      let currentRouteCoOrdinatesBounds = currentRouteCoOrdinatesGroup.getBounds();
      let futureRouteCoOrdinatesBounds = futureRouteCoOrdinatesGroup.getBounds();
      if (Object.keys(currentRouteCoOrdinatesBounds).length != 0 && currentRouteCoOrdinatesBounds["_northEast"]["lat"] < futureRouteCoOrdinatesBounds["_northEast"]["lat"]) {
        currentRouteCoOrdinatesBounds["_northEast"]["lat"] = futureRouteCoOrdinatesBounds["_northEast"]["lat"]
      }
      if (Object.keys(currentRouteCoOrdinatesBounds).length != 0 && currentRouteCoOrdinatesBounds["_northEast"]["lng"] < futureRouteCoOrdinatesBounds["_northEast"]["lng"]) {
        currentRouteCoOrdinatesBounds["_northEast"]["lng"] = futureRouteCoOrdinatesBounds["_northEast"]["lng"]
      }
      if (Object.keys(currentRouteCoOrdinatesBounds).length != 0 && currentRouteCoOrdinatesBounds["_southWest"]["lat"] > futureRouteCoOrdinatesBounds["_southWest"]["lat"]) {
        currentRouteCoOrdinatesBounds["_southWest"]["lat"] = futureRouteCoOrdinatesBounds["_southWest"]["lat"]
      }
      if (Object.keys(currentRouteCoOrdinatesBounds).length != 0 && currentRouteCoOrdinatesBounds["_southWest"]["lng"] > futureRouteCoOrdinatesBounds["_southWest"]["lng"]) {
        currentRouteCoOrdinatesBounds["_southWest"]["lng"] = futureRouteCoOrdinatesBounds["_southWest"]["lng"]
      }
      if (Object.keys(currentRouteCoOrdinatesBounds).length != 0)
        map.fitBounds(currentRouteCoOrdinatesGroup.getBounds());
      else map.fitBounds(futureRouteCoOrdinatesGroup.getBounds());
    }
  }

  if (props.routeDataFetched) {
    setTimeout(function () { Boundaries(mapRef, currentRouteCoOrdinatesGroupRef, futureRouteCoOrdinatesGroupRef); }, 1500);
  }

  let currentRouteCoOrdinatesArray = getCoordinates(props.shipmentCurrentRoute);
  let futureRouteCoOrdinatesArray = getCoordinates(props.shipmentFutureRoute);
  let portsArray = getCoordinatesOfPorts(props.ports)
  let latCentre = 0;
  let lonCentre = 0;
  if (props.shipmentCurrentRoute.length > 2) {
    latCentre = (props.shipmentCurrentRoute[0]["lat"] + props.shipmentCurrentRoute[props.shipmentCurrentRoute.length - 1]["lat"]) / 2
    lonCentre = (props.shipmentCurrentRoute[0]["lon"] + props.shipmentCurrentRoute[props.shipmentCurrentRoute.length - 1]["lon"]) / 2
  }

  return (
    <div className='MapCss'>
      <Map
        style={{ height: "630px", width: "100%" }}
        zoom={3}
        center={[latCentre, lonCentre]}
        ref={mapRef}>
        <TileLayer url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png" />
        <MyMarkersList currentRouteCoOrdinatesMarkers={currentRouteCoOrdinatesArray} futureRouteCoOrdinatesMarkers={futureRouteCoOrdinatesArray} pastPortsArray={portsArray[0]} futurePortsArray={portsArray[1]} shipmentLocation={props.shipmentLocation} routeDataFetched={props.routeDataFetched} fetchingRouteData={fetchingRouteData} />
        <Polyline color="black" positions={currentRouteCoOrdinatesArray} ref={currentRouteCoOrdinatesGroupRef} />
        <Polyline color="grey" positions={futureRouteCoOrdinatesArray} ref={futureRouteCoOrdinatesGroupRef} />
      </Map>
    </div>
  )
}
