import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

export const useStyles = makeStyles(theme => ({
    list: {
        width: "90vw",
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    iconAlign: {
        verticalAlign: "middle"

    },
    root: {
        width: "100%",
        background: "white"
    },
    cardWith: {
        //width: "60vw",
    },
    cardHeader: {
        background: theme.palette.primary.main,
        color: "white",
        height: "5vh"
    },
    card: {
        boxShadow: "-1px 2px 5px 8px rgba(161,174,255,0.91)"
    },
    blockPaper: {
        maxHeight: "80vh"
    },
    title: {
        fontSize: "15px",
    },
    timelineIconStyle: {
        background: theme.palette.primary.main,
        color: '#fff'
    }


}));