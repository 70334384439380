import axios from 'axios'
import authUtil from '../utils/authUtil'
import Util from '../utils/Util'

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`

axios.defaults.withCredentials = true
function getOrganizations() {
  return axios.get(BASE_API_URL + `/admin/organizations?peers=false`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getShipmentsCreatedByOrgID(organizationID) {
  return axios.get(
    BASE_API_URL +
    `/shipments/organizations/` +
    organizationID +
    `?type=created`,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}



function getEventsByShipmentID(shipmentID, query = {}) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/shipments/` + shipmentID + `/events/` + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getProductsByShipmentID(shipmentID, query) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/shipments/` + shipmentID + `/products/` + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getContainersByShipmentID(shipmentID, query) {
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/shipments/` + shipmentID + `/containers/` + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getShipmentsSharedByOrgID(organizationID, query = {}) {
  query['type'] = 'shared'
  let queryParams = Util.buildQueryParams(query)
  return axios.get(
    BASE_API_URL + `/shipments/organizations/` + organizationID + queryParams,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function getShipmentsByShipmentID(shipmentID) {
  return axios.get(BASE_API_URL + `/shipments/` + shipmentID, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getShipmentsByDocumentId(documentId) {
  return axios.get(BASE_API_URL + `/shipments/documents/` + documentId, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function createShipment(orgainzationID, requestObj) {
  return axios.post(
    BASE_API_URL + `/shipments/organizations/` + orgainzationID,
    requestObj,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function createShipmentByShipmentReference(orgainzationID, requestObj) {
  return axios.post(BASE_API_URL + `/shipments/organizations/external/` + orgainzationID, requestObj, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function generateEvent(shipmentID, requestObj) {
  return axios.post(
    BASE_API_URL + `/shipments/` + shipmentID + `/events/`,
    requestObj,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function createNewEventType(requestObj) {
  return axios.post(BASE_API_URL + `/events`, requestObj, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getEvents() {
  return axios.get(BASE_API_URL + `/assets/types?assetTypeName=Events`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getShipmentPorts(searchString) {
  return axios.get(BASE_API_URL + `/staticdata/shipmentPorts?searchString=` + searchString, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getEventsByContainerId(shipmentID, containerId) {
  return axios.get(
    BASE_API_URL +
    `/shipments/` +
    shipmentID +
    `/events/containers/` +
    containerId,
    {
      headers: {
        Authorization: 'Bearer ' + authUtil.getToken()
      }
    }
  )
}

function updateShipment(shipmentID, requestObj) {
  return axios.post(BASE_API_URL + `/shipments/` + shipmentID, requestObj, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getShipmentsChannelId(channelId) {
  return axios.get(BASE_API_URL + `/shipments/channels/` + channelId, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getDocumentTypes() {
  return axios.get(BASE_API_URL + `/assets/types?assetTypeName=Documents`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getDocumentTypesByDestination(shipmentReferenceNumber = null) {
  return axios.get(BASE_API_URL + `/staticdata/documentTypes/destination?shipmentReference=${shipmentReferenceNumber}`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getDocumentTypesByDestinationForShipments(shipmentNumberList) {
  return axios.get(BASE_API_URL + `/staticdata/documentTypes/destination?shipmentNumberList=${shipmentNumberList}`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getShipmentRevisionsByShipmentId(shipmentID) {
  return axios.get(BASE_API_URL + `/shipments/` + shipmentID + `/revisions`, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getShipmentsByShipmentReferenceNumber (requestObj) {
  let queryParams = Util.buildQueryParams(requestObj);
  return axios.get(BASE_API_URL + `/shipments/shipmentReferenceNumber` + queryParams, {
    headers: {
      Authorization: 'Bearer ' + authUtil.getToken()
    }
  })
}

function getLiveShipmentRouteDataCargoFlow(cargoFlowShipmentId) {
  return axios.get(BASE_API_URL + `/shipment/shipments/` + cargoFlowShipmentId + `/map`, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getUniqueColumnValuesShipmentsSharedWithOrgId(orgainzationId) {
  return axios.get(BASE_API_URL + `/shipments/UniqueColumnValues/` + orgainzationId, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

export default {
  getOrganizations,
  getShipmentsCreatedByOrgID,
  getShipmentsSharedByOrgID,
  getShipmentsByShipmentID,
  getProductsByShipmentID,
  createShipment,
  createShipmentByShipmentReference,
  generateEvent,
  createNewEventType,
  getShipmentsChannelId,
  getEventsByContainerId,
  getDocumentTypes,
  updateShipment,
  getEvents,
  getEventsByShipmentID,
  getShipmentRevisionsByShipmentId,
  getContainersByShipmentID,
  getShipmentPorts,
  getShipmentsByShipmentReferenceNumber,
  getLiveShipmentRouteDataCargoFlow,
  getDocumentTypesByDestination,
  getDocumentTypesByDestinationForShipments,
  getShipmentsByDocumentId,
  getUniqueColumnValuesShipmentsSharedWithOrgId
}
