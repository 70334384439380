import React, { useState } from "react";
import useStyles from './styles/AssetInfoStyles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import assetService from '../../services/AssetService';
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';
import { FormControl, FormControlLabel, InputLabel, Checkbox } from '@material-ui/core';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import RelativeTime from 'react-relative-time';
import "../../css/style.css";
import Util from "../../utils/Util";
import notify from "../../utils/notifier";
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AdjustIcon from '@material-ui/icons/Adjust';
import { Link } from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import UploadFile from '../UploadAssetFile';
import AssetTransactioninfo from './AssetTransactioninfo';
import {
    _columns,
} from './AssetUtils';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {
    fileUploadCriteria,
    buildRequestObjUpdateAsset,
} from './AssetUtils';




export default function AssetInfo(props) {
    const classes = useStyles();
    const [revisions, setRevisions] = useState([]);
    const [owner, setOwner] = useState('');
    const [possibleOwners, setPossibleOwners] = useState([]);
    const [nextOwner, setNextOwner] = useState({});
    const [assetNumber, setAssetNumber] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [uploadVersion, setUploadVersion] = React.useState(false);
    const [uploadDocumentDialog, setUploadDocumentDialog] = useState(false);
    const [file, setFile] = useState("");
    const [fileSubmit, setFileSubmit] = useState(false);
    const [backdrop, setBackdrop] = React.useState(false);
    const [updateMsg, setUpdateMsg] = useState(false);
    const [showTransactionsInfoBlock, setShowTransactionsInfoBlock] = useState(false);
    const [selectedTxId, setSelectedTxId] = useState("");
    const [validUpdateAssetStateCodes, setValidUpdateAssetStateCodes] = useState(false);


    const [currentOwner, setCurrentOwner] = useState(props.location.state.currentOwnerOrganizationName);
    const [currentOwnerRole, setCurrentOwnerRole] = useState(props.location.state.currentOwnerOrganizationRoleName);
    const [lastUpdated, setLastUpdated] = useState(props.location.state.updatedAt)
    const [uploadingDoc, setUploadingDoc] = useState(false);
    const [changingOwner, setChangingOwner] = useState(false);
    
    const fetchAssetDetails = () => {
        setAssetNumber(props.location.state.assetNumber);

        assetService.getAssetRevisionByAssetNumber(props.location.state.assetNumber).then(
            response => {
                let _data = response.data;
                setRevisions(_data);
                setCurrentOwner(_data[0].currentOwnerOrganizationName);
                setCurrentOwnerRole(_data[0].currentOwnerOrganizationRoleCode);
                setLastUpdated(_data[0].createdAt);
                checkValidStateForUpdatingAsset(_data[0].currentStateCode);
                if (localStorage.getItem('_orgCode') == _data[0].currentOwnerOrganizationCode) {
                    assetService.getNextPossibleOwnerRole(props.location.state.assetNumber, _data[0].currentStateCode).then(
                        response => {
                            let data1 = response.data;
                            setPossibleOwners(data1);
                            setInitialState();
                        },
                        error => {
                            setInitialState();
                            notify({
                                type: "error",
                                message: 'error',
                            });
                        }

                    )
                }

            },
            error => {
                notify({
                    type: "error",
                    message: 'error',
                });
            }
        )
    }

    const checkValidStateForUpdatingAsset = (stateCode) => {

        assetService.checkValidStateForUpdatingAsset(stateCode).then(
            response => {
                let _data = response.data;
                setValidUpdateAssetStateCodes(_data);
            },
            error => {
                notify({
                    type: 'error',
                    message: 'Error while checking of current state is valid for updating asset',
                });
            }
        )
    }

    const setInitialState = () => {
        setFile("");
        setFileSubmit(false);
        setNextOwner('');
        setOwner('');
        setUploadVersion(false);
        setUpdateMsg(false);
    }
    const handleChangeOwner = (event) => {
        setOwner(event.target.value);
        setUploadVersion(false);
        let obj = possibleOwners.filter(x => { return (x.organizationName == event.target.value.split(" (")[0]) });
        if ((revisions[0].currentStateCode == "BOL_RECEIVED_BY_EXPORTER" || revisions[0].currentStateDescription == "") &&
            localStorage.getItem('_orgCode') == obj[0].organizationCode)
            setUpdateMsg(true);
        else setUpdateMsg(false);
        setNextOwner({
            orgName: obj[0].organizationName,
            orgCode: obj[0].organizationCode,
            roleName: obj[0].commercialRoleName,
            roleCode: obj[0].commercialRoleCode
        })
    }

    const handleChangeOwnership = () => {
        setOpenDialog(false);
        setChangingOwner(true);
        let reqobj = {
            "currentOwnerOrgCode": revisions[0].currentOwnerOrganizationCode,
            "currentOwnerOrgRole": revisions[0].currentOwnerOrganizationRoleCode,//This is roleCode not roleName
            "newOwnerOrgCode": nextOwner.orgCode,
            "newOwnerOrgRole": nextOwner.roleCode
        };
        assetService.transferOwnership(assetNumber, reqobj).then(
            response => {
                setChangingOwner(false);
                notify({
                    type: "success",
                    message: 'Ownership is successfully transferred ',
                });
                fetchAssetDetails();
            },
            error => {
                setChangingOwner(false);
                setInitialState();
                notify({
                    type: "error",
                    message: 'Error while transfering ownership',
                });
            }
        );
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setUploadDocumentDialog(false);
    }
    const handleClickDownloadDocument = (event, version) => {
        setBackdrop(true);
        assetService.downloadAsset(props.location.state.assetNumber, version).then(
            response => {
                setBackdrop(false)
                Util.downloadTemplate(response);
            },
            error => {
                setBackdrop(false)
                if (error && error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }
            }
        );
    };

    const handleClickPreviewDocument = (event, versionToDownload) => {
        event.stopPropagation();
        event.preventDefault();
        setBackdrop(true);

        assetService.downloadAsset(props.location.state.assetNumber, versionToDownload).then(
            response => {
                setBackdrop(false)

                var type = response.headers["content-type"];
                if (type === 'application/octet-stream') {
                    type = 'application/pdf';
                }

                const blob = new Blob([response.data], {
                    type: type,
                    encoding: "UTF-8"
                });

                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                window.open(link.href)
            },
            error => {
                setBackdrop(false)
                if (error.response.status === 404) {
                    notify({
                        type: "error",
                        message: "File integrity has been compromised. Please contact the administrator."
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while downloading document."
                    });
                }


            }
        );
    };

    const handleUploadVersion = () => {
        setOwner('')
        setUploadVersion(!uploadVersion);
        setUpdateMsg(false);
        setFile("");
        setFileSubmit(false);
    }

    const handleUpdateAsset = () => {
        setUploadDocumentDialog(false);
        setUploadingDoc(true);
        let formDataUpdateAssetList = buildRequestObjUpdateAsset(file, revisions[0].currentOwnerOrganizationCode, revisions[0].currentOwnerOrganizationRoleCode, props.location.state.assetNumber);
        if (!formDataUpdateAssetList) {
            return;
        }

        assetService.updateAsset(props.location.state.assetNumber, formDataUpdateAssetList).then(
            response => {
                fetchAssetDetails();
                setUploadingDoc(false);
                let _message = "Asset uploaded successfully";
                notify({
                    type: "success",
                    message: _message
                });
            },
            error => {
                setUploadingDoc(false);
                setInitialState();
                notify({
                    type: "error",
                    message: "Error while uploading a document"
                });
            }
        );

    }

    const handleFileUpload = (file) => {
        if (file) {
            if (fileUploadCriteria.validFileTypes.includes(file.type) && file.size > 0 && file.size <= fileUploadCriteria.maxFileSizeLimit) {
                setFile(file);
                setFileSubmit(true);
            } else {
                let errorMsg;
                if (!fileUploadCriteria.validFileTypes.includes(file.type)) {
                    errorMsg = "Invalid file type uploaded"
                } else {
                    errorMsg = "File size criteria is not matching"
                }
                notify({
                    type: "error",
                    message: errorMsg
                });
                setFile("");
                setFileSubmit(false);

            }
        } else {
            setFile("")
        }
    }

    const handleClickDeleteFile = () => {
        setFile("");
        setFileSubmit(false);
    }


    function checkFileExtentionValidForPreview(row) {
        let fileExtensionPreviewAllowed = ['pdf', 'png', 'jpg', 'html', 'jpeg'];
        let originalFileName = row.originalAssetName;
        let fileExt = originalFileName ? originalFileName.split('.').slice(-1).pop() : "";
        return fileExtensionPreviewAllowed.includes(fileExt);
    }
    React.useEffect(() => {
        fetchAssetDetails();
    }, [props.location.state]);



    const showTransactionsBlock = (txId) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setSelectedTxId(txId);
        setShowTransactionsInfoBlock(true);
    };



    const closeTransactionsPaneBlock = () => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setShowTransactionsInfoBlock(false);
        setSelectedTxId("");
    };

    return (
        <div className={classes.borderMain}>
            {
                props.location.state ? (
                    <div>
                        <Paper style={{ minHeight: "87vh" }}>
                            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 1000000 }} >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                            <section className={classes.shipmentInfo}>
                                <h2>
                                    {<Link style={{ textDecoration: 'none' }} to={{ pathname: "/assets" }}>
                                        <a color="primary" className={classes.a}>
                                            Assets
                                </a>
                                    </Link>}
                                        / Asset Details

                                    <Chip label={"Asset Number : " + props.location.state.assetNumber} style={{ float: "right", marginLeft: '20px' }} />

                                </h2>

                                <div className={classes.shipmentInfopara}>
                                    <div className={classes.paper_root}>
                                        <Paper variant="outlined" square style={{ padding: 'auto' }}>
                                            <p align="center" className={classes.smallText}>
                                                Asset Reference
                                                </p>
                                            <h3 align="center" style={{ alignContent: "center", padding: "auto" }} >
                                                {props.location.state.assetReference ? props.location.state.assetReference : "-"}
                                            </h3>
                                        </Paper>
                                        <Paper variant="outlined" square>
                                            <p align="center" className={classes.smallText}>
                                                Issuer
                                                </p>
                                            <h3 align="center" >
                                                {props.location.state.issuerOrganizationName ? props.location.state.issuerOrganizationName : "-"}
                                            </h3>
                                        </Paper>
                                        <Paper variant="outlined" square style={{ paddingLeft: '2px' }}>
                                            <p align="center" className={classes.smallText}>
                                                Issuer Role
                                                </p>
                                            <h3 align="center" style={{ wordBreak: 'break-word' }} >
                                                {props.location.state.issuerOrganizationRole ? props.location.state.issuerOrganizationRole : "-"}
                                            </h3>
                                        </Paper>
                                        <Paper variant="outlined" square >
                                            <p align="center" className={classes.smallText}>
                                                Asset Type
                                                </p>
                                            <h3 align="center" >
                                                {props.location.state.subAssetTypeName ? props.location.state.subAssetTypeName : "-"}
                                            </h3>
                                        </Paper>
                                        <Paper variant="outlined" square >
                                            <p align="center" className={classes.smallText}>
                                                Channel
                                                </p>
                                            <h3 align="center" >
                                                {props.location.state.channelName ? props.location.state.channelName : "-"}
                                            </h3>
                                        </Paper>

                                        <Paper variant="outlined" square>
                                            <p align="center" className={classes.smallText}>
                                                Current Owner
                                                </p>
                                            <h3 align="center"  >
                                                {currentOwner ? currentOwner : "-"}
                                            </h3>
                                        </Paper>

                                        <Paper variant="outlined" square style={{ paddingLeft: '2px' }}>
                                            <p align="center" className={classes.smallText}>
                                                Current Owner Role
                                                </p>
                                            <h4 align="center" style={{ wordBreak: 'break-word' }} >
                                                {currentOwnerRole ? currentOwnerRole : "-"}
                                            </h4>
                                        </Paper>
                                        <Paper variant="outlined" square style={{ paddingLeft: '2px', paddingRight: '1px' }}>
                                            <p align="center" className={classes.smallText}>
                                                Last Updated at
                                                </p>
                                            <h4 align="center" style={{ wordBreak: 'break-word' }} >
                                                {`${lastUpdated.split('T')[0]} ${lastUpdated.split('T')[1].split('.')[0]}`}
                                            </h4>
                                        </Paper>

                                    </div>
                                </div>

                            </section>
                            {revisions.length != 0 ? (
                                <div className={classes.navigation}>
                                    <div className={classes.timelineDiv} style={{ paddingLeft: '32px' }} >
                                        <VerticalTimeline className={classes.verticalTimeLine} layout="1-column">
                                            {revisions.map((member, index) => {
                                                return (
                                                    <VerticalTimelineElement
                                                        className="vertical-timeline-element--work"
                                                        contentStyle={{ background: '#fff', color: '#fff', boxShadow: 'none', cursor: 'pointer', marginLeft: '40px' }}
                                                        iconStyle={{ background: '#173a64', color: '#fff', width: '30px', height: '30px', left: '5px' }}
                                                        icon={<AdjustIcon />}

                                                    >
                                                        <div className={selectedTxId == member.txId ? (classes.cardHeaderBlock) : (classes.cardContent)} onClick={showTransactionsBlock(member.txId)}>
                                                            <div className={classes.cardContentInfoDiv}>
                                                                <div className={classes.versionInfoDiv}>
                                                                    <span className={classes.boldText} >
                                                                        {member.currentStateDescription}
                                                                    &nbsp;&nbsp; <span className={classes.smallText}>(<RelativeTime value={member.createdAt} titleformat="iso8601" />)</span>
                                                                    </span>
                                                                </div>
                                                                <div className={classes.noMargin}>
                                                                    <span className={classes.smallText}>
                                                                        State owned by&nbsp;
                                                                    <span className={classes.smallText}>
                                                                            <span style={{ fontWeight: 'bold' }}>{member.currentOwnerOrganizationName} ({member.currentOwnerOrganizationRoleName})</span>, &nbsp;transfer initiated by <span style={{ fontWeight: 'bold' }}>{member.firstName} {member.lastName ? member.lastName : ''} ({member.createdByUserOrganizationName})</span>
                                                                        </span> &nbsp;&nbsp;
                                                                    </span>
                                                                </div>

                                                                <div className={classes.versionInfoDiv}>
                                                                    <span className={classes.smallText}>
                                                                        Version: {member.version}
                                                                        <span className={classes.smallText}>
                                                                            &nbsp;&nbsp;<QueryBuilderIcon className={classes.smallTextIcon} />&nbsp;{member.createdAt}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div className={classes.docTransactionIdDiv}>
                                                                    <VerifiedUserIcon className={classes.smallTextIcon} style={{ "color": "green" }} />
                                                                    <span className={classes.smallTextTransactionId}>
                                                                        {member.txId}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className={classes.cardContentActionDiv}>
                                                                {
                                                                    <div>
                                                                        {
                                                                            checkFileExtentionValidForPreview(member) ?
                                                                                <Tooltip title="Preview">
                                                                                    <Button className={classes.buttonDownload}
                                                                                        onClick={event => handleClickPreviewDocument(event, member.version)}
                                                                                    >
                                                                                        <DescriptionIcon />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                                :
                                                                                <Tooltip title="Preview Not avaialble">
                                                                                    <Button className={classes.buttonDownload}

                                                                                    >
                                                                                        <DescriptionIcon color="disabled" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                        }
                                                                        <Tooltip title="Download">
                                                                            <Button className={classes.buttonDownload}
                                                                                onClick={event => handleClickDownloadDocument(event, member.version)}
                                                                            >
                                                                                <CloudDownloadIcon />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </div>
                                                                }
                                                                &nbsp; &nbsp;
                                                            </div>
                                                        </div>

                                                    </VerticalTimelineElement>
                                                );
                                            })

                                            }
                                        </VerticalTimeline>
                                    </div>
                                    <div className={classes.transactionInfoDiv}>
                                        {(revisions != null && possibleOwners.length && localStorage.getItem('_orgCode') == revisions[0].currentOwnerOrganizationCode) ?
                                            <div >
                                                <FormControl style={{ minWidth: '100%', marginTop: '5px' }}>
                                                    <InputLabel id="demo-mutiple-name-label"> Select an organization to transfer ownership</InputLabel>

                                                    <Select
                                                        labelId="demo-mutiple-name-label"
                                                        id="demo-mutiple-name"
                                                        onChange={handleChangeOwner}
                                                        value={owner}
                                                        className={classes.ownerDropdown}
                                                    >
                                                        {possibleOwners.map((row) => { return (<MenuItem key={`${row.organizationName} (${row.commercialRoleName})`} value={`${row.organizationName} (${row.commercialRoleName})`}>{row.organizationName} ({row.commercialRoleName})</MenuItem>); })}

                                                    </Select>
                                                </FormControl>

                                                {updateMsg ?
                                                    <div className={classes.warningDiv}>
                                                        <div className={classes.warningIconDiv}>
                                                            <WarningIcon className={classes.warningIcon} />
                                                        </div>
                                                        <span className={classes.warningText}>
                                                            Please upload a new version to continue with selected owner
                                                    </span>
                                                    </div> : ""}

                                                <div className={classes.flexItem}>
                                                    {
                                                        (currentOwnerRole === 'EXPORTER' && validUpdateAssetStateCodes) ?
                                                        <div className={classes.uploadVersionCheckboxDiv}>
                                                            <FormControlLabel
                                                                control={<Checkbox
                                                                    checked={uploadVersion}
                                                                    onChange={handleUploadVersion}
                                                                />}
                                                                label="Upload new version"
                                                            />
                                                        </div>
                                                        : ""
                                                    }
                                                    {
                                                        uploadVersion ? <div>
                                                            <UploadFile onFileUpload={handleFileUpload} handleClickDeleteFile={handleClickDeleteFile} documentsDetails={[{ "file": file }]} documentIndex={0}></UploadFile>
                                                        </div> : ""
                                                    }
                                                    <div style={{ display: 'flex', float: "right", marginLeft: '20px' }}>
                                                        {uploadingDoc ? <div className={classes.uploadDiv} style={{ float: "left" }}>Uploading a document &nbsp;&nbsp;  <CircularProgress size={18} /></div> : changingOwner
                                                            ? <div className={classes.uploadDiv} style={{ float: "left" }}>Transfering ownership &nbsp;&nbsp;  <CircularProgress size={18} /></div> : ""
                                                        }
                                                        {(fileSubmit || owner) ? <Button className={classes.drawerButton} color="primary" variant="contained"
                                                            style={{ float: "right", marginLeft: '20px' }}
                                                            onClick={() => setInitialState()}
                                                            disabled={uploadingDoc === true || changingOwner === true}>
                                                            Cancel
                                                                </Button>
                                                            : ""
                                                        }

                                                        {fileSubmit ? <Button className={classes.drawerButton} color="primary" variant="contained"
                                                            style={{ float: "right", marginLeft: '20px' }}
                                                            onClick={() => { setUploadDocumentDialog(true) }}
                                                            disabled={uploadingDoc === true}>
                                                            Upload
                                                                </Button> :
                                                            (owner != '' && updateMsg == false) ?
                                                                <Button className={classes.drawerButton} color="primary" variant="contained"
                                                                    style={{ float: "right", marginLeft: '20px' }}
                                                                    onClick={() => { setOpenDialog(true) }}
                                                                    disabled={changingOwner === true}>
                                                                    Submit
                                                                </Button>
                                                                : <Button className={classes.drawerButton} color="primary" variant="contained"
                                                                    style={{ float: "right", marginLeft: '20px' }}
                                                                    disabled>
                                                                    Submit
                                                                </Button>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            : ""}

                                        {
                                            showTransactionsInfoBlock ?
                                                <AssetTransactioninfo
                                                    channelId={props.location.state.channelId}
                                                    txId={selectedTxId}
                                                    closeTransactionsPane={closeTransactionsPaneBlock}
                                                /> : ""
                                        }
                                    </div>
                                </div>

                            ) : ""}
                        </Paper>
                        <Dialog
                            open={uploadDocumentDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>
                                <Typography className={classes.dialogTitle} gutterBottom>
                                    {`Update new document`}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className={classes.dialogContentText}>
                                    {`Are you sure you want to continue, this action would create another document version on the ledger?`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className={classes.dialogButton} onClick={handleUpdateAsset} color="primary">
                                    CONFIRM
                                </Button>
                                <Button className={classes.dialogButton} onClick={handleCloseDialog} color="primary" autoFocus>
                                    CLOSE
                            </Button>
                            </DialogActions>
                        </Dialog>
                        <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>
                                <Typography className={classes.dialogTitle} gutterBottom>
                                    {`Review and Approve`}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className={classes.dialogContentText}>
                                    {`Change ownership to ${nextOwner.orgName}(${nextOwner.roleName})`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className={classes.dialogButton} onClick={handleChangeOwnership} color="primary">
                                    CONFIRM
                                </Button>
                                <Button className={classes.dialogButton} onClick={handleCloseDialog} color="primary" autoFocus>
                                    CLOSE
                            </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                ) : ""
            }

        </div >

    )
};



