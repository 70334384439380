import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from '@material-ui/icons/Edit';
import { Link } from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import { useStyles } from "./styles/OrganizationStyles";
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Input, InputLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { ListItemText, ListItemIcon, List, ListItem } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import chaincodeService from '../../services/ChaincodeService';
import Chip from '@material-ui/core/Chip';

import { VALID_USER_INPUT_REGEX } from "../../utils/constants";
import NetworkService from "../../services/NetworkService";
import organizationService from "../../services/OrganizationService";
import EventsMessagesModal from "./EventsMessagesModal";
import Drawer from '@material-ui/core/Drawer';

import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import notify from '../../utils/notifier';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PropTypes, { func } from 'prop-types';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DoneIcon from '@material-ui/icons/Done';
import { channelUseStyles } from "../channel/styles/ChannelStyles";
import networkService from "../../services/NetworkService";
import channelService from "../../services/ChannelService";
import CreateChannelSideDrawer from "./CreateChannelSideDrawer";
import InviteOrgsToNetworkSideDrawer from "./InviteOrgsToNetworkSideDrawer";
import SearchOrg from "./SearchOrg";
import SearchChannel from "./SearchChannel";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import {
  _columns,
  _columnsWithRoles,
  userPrefs,
  MenuProps,
  disableSubmitButtonOrgWithoutNetwork,
  buildRequestObjForAddingOrgWithNetwork,
  disableAddPeerButton,
  buildRequestObjForAddingOrgNoNetwork,
} from './OrganizationsUtils'

export const _channelColumns = [
  { id: "channelName", label: "Name", align: "center", minWidth: 170, sortable: true },
  { id: "channelDescription", label: "Description", align: "center", minWidth: 170, sortable: true },
  { id: "memberCount", label: "No. of Members", align: "center", minWidth: 170 },
];

export default function Organizations(props) {

  let _tabValue = 0;
  if (props.location.state && props.location.state.hasOwnProperty('tabValue')) {
    _tabValue = props.location.state.tabValue;
  }
  const classes = useStyles();
  const [orgRows, setOrgRows] = React.useState([]);
  const [originalOrgRows, setOriginalOrgRows] = React.useState([]);
  const [orgColumns, setOrgColumns] = React.useState(_columns);
  const [orgPage, setOrgPage] = React.useState(0);
  const [orgRowsPerPage, setOrgRowsPerPage] = React.useState(10);
  const [tab, setTab] = React.useState(_tabValue);
  const [orgData, setOrgData] = React.useState();
  const [outsidePeerNames, setOutsidePeerNames] = React.useState([null])
  const [outsidePeerPorts, setOutsidePeerPorts] = React.useState([null])
  const [render, setRender] = React.useState(true)
  const [orgOrder, setOrgOrder] = React.useState("asc");
  const [orgOrderBy, setOrgOrderBy] = React.useState("organizationName");
  const [selectedOrgs, setSelectedOrgs] = React.useState([]);
  const [addOrgState, setAddOrgState] = React.useState(null);
  const [enrollAdminState, setEnrollAdminState] = React.useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [editNetwork, setEditNetwork] = React.useState(false)
  const [editOrganization, setEditOrganization] = React.useState(false)
  const [networkName, setNetworkName] = React.useState("");
  const [networkNameCopy, setNetworkNameCopy] = React.useState("");
  const [networkDescription, setNetworkDescription] = React.useState("");
  const [networkDescriptionCopy, setNetworkDescriptionCopy] = React.useState("");
  const [breadCrumbNetworkNameFocus, setBreadCrumbNetworkNameFocus] = React.useState(false);
  const [networkDescriptionFocus, setNetworkDescriptionFocus] = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(false);

  const [chaincodes, setChaincodes] = React.useState(null);
  const [selectedNetworks, setSelectedNetworks] = React.useState([]);
  const [networksArray, setNetworksArray] = React.useState([]);
  const [participatedNetworks, setParticipatedNetworks] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const channelClasses = channelUseStyles();
  const [channelRows, setChannelRows] = React.useState([]);
  const [originalChannelRows, setOriginalChannelRows] = React.useState([]);
  const [channelColumns, setChannelColumns] = React.useState(_channelColumns);
  const [channelPage, setChannelPage] = React.useState(0);
  const [channelRowsPerPage, setChannelRowsPerPage] = React.useState(10);
  const [channelOrder, setChannelOrder] = React.useState("asc");
  const [channelOrderBy, setChannelOrderBy] = React.useState("channelName");

  const [channelSearchKey, setChannelSearchKey] = useState({ value: "" });
  const [channelInfoData, setChannelInfoData] = useState({});
  const [channelDrawerState, setChannelDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  }); //drawer

  const [editPeerRow, setEditPeerRow] = React.useState({});
  const [editPeerRowId, setEditPeerRowId] = React.useState(null);
  const [editPeerName, setEditPeerName] = React.useState("");
  const [editPeerNameCopy, setEditPeerNameCopy] = React.useState("");
  const [editPeerDomainName, setEditPeerDomainName] = React.useState("");
  const [editPeerDomainNameCopy, setEditPeerDomainNameCopy] = React.useState("");
  const [newEditPeerRow, setNewEditPeerRow] = React.useState("")
  const [focusedPeerColumn, setFocusedPeerColumn] = React.useState("")

  const [invalidUserInput, setInvalidUserInput] = React.useState([]);
  const [channelQueryCount, setChannelQueryCount] = React.useState(0);
  const [channelOriginalqueryCount, setChannelOriginalQueryCount] = React.useState(0);
  const [textBreadCrumbsPeerName, setTextBreadCrumbPeerName] = React.useState(classes.textBreadCrumbPeerName);
  const [textBreadCrumbsPeerDomainName, setTextBreadCrumbPeerDomainName] = React.useState(classes.textBreadCrumbPeerDomainName);
  const [breadCrumbOrganizationDescriptionFocus, setBreadCrumbOrganizationDescriptionFocus] = React.useState(false);

  const [orgListGenerateToken, setOrgListGenerateToken] = React.useState([]);

  const [searchKey, setSearchKey] = useState({ value: "" });
  const [successChangeEditNetworkValues, setSuccessChangeEditNetworkValues] = React.useState(false);

  const [editNetworkValues, setEditNetworkValues] = React.useState({
    networkName: "",
    networkDescription: "",
    networkId: ""
  });

  const [editOrganizationValues, setEditOrganizationValues] = React.useState({
    organizationName: "",
    organizationDescription: "",
    organizationDomainName: "",
    organizationId: "",
    userOganizationId: ""
  });

  const [queryCount, setQueryCount] = React.useState(0);
  const [originalqueryCount, setOriginalQueryCount] = React.useState(0);
  const [chains, setChains] = React.useState([]);
  const [mapChainCodes, setMapChainCodes] = React.useState({});
  const [installPeer, setInstallPeer] = React.useState({});

  const [drawerState, setDrawerState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStateInviteOrg, setDrawerStateInviteOrg] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStateChainCode, setDrawerStateChainCode] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStateNetworks, setDrawerStateNetworks] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStatePeers, setDrawerStatePeers] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStateClientToken, setDrawerStateClientToken] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [drawerStateAssignCommercialRole, setDrawerStateAssignCommercialRole] = React.useState({
    right: false,
  });

  const [installChainCode, setInstallChainCode] = React.useState({ id: null, version: null })
  const tableHeadWidth = "42%";
  const tableHeadWidthPeerCount = "16%"
  const [numberOfPeers, setNumberOfPeers] = React.useState(1)

  const [values, setValues] = React.useState({
    orgName: "",
    hostCaPort: "",
    orgCaPort: "",
    orgDomainName: "",
    organizationDescription: "",
    peers: [],
    peerNames: [null],
    peerPorts: [null],
    users: null,
    couchDBUsername: "",
    couchDBPassword: "",
    orgCaUsername: "",
    orgCaPassword: "",
    members: 0,
    inviteOrgs: [],
    roles: []
  });

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [popupOpenPeer, setPopupOpenPeer] = React.useState(false);
  const [textBreadCrumbsOrganizationName, setTextBreadCrumbOrganizationName] = React.useState(classes.textBreadCrumbOrganizationName);
  const [textBreadCrumbsOrganizationDomainName, setTextBreadCrumbOrganizationDomainName] = React.useState(classes.textBreadCrumbOrganizationDomainName);
  const [textBreadCrumbsOrganizationDescription, setTextBreadCrumbOrganizationDescription] = React.useState(classes.textBreadCrumbOrganizationDescription);
  const [editOrganizationRowId, setEditOrganizationRowId] = React.useState(null);
  const [editOrganizationName, setEditOrganizationName] = React.useState("");
  const [editOrganizationNameCopy, setEditOrganizationNameCopy] = React.useState("");
  const [editOrganizationDescription, setEditOrganizationDescription] = React.useState("");
  const [editOrganizationDescriptionCopy, setEditOrganizationDescriptionCopy] = React.useState("");
  const [editOrganizationDomainName, setEditOrganizationDomainName] = React.useState("");
  const [editOrganizationDomainNameCopy, setEditOrganizationDomainNameCopy] = React.useState("");
  const [focusedOrganizationColumn, setFocusedOrganizationColumn] = React.useState("")
  const [newEditOrganizationRow, setNewEditOrganizationRow] = React.useState("")
  const [newProp, setNewProp] = React.useState("")
  const [newEventTargetValue, setNewEventTargetValue] = React.useState("")
  const [orgIdGenerateToken, setOrgIdGenerateToken] = React.useState();
  const [generatedClientId, setGeneratedClientId] = React.useState("");
  const [generatedClientSecret, setGeneratedClientSecret] = React.useState("");

  const [openDialog, setOpenDialog] = React.useState(false);
  const [dialogTextContent, setDialogTextContent] = React.useState("");
  const [selectedOrgToRemove, setSelectedOrgToRemove] = React.useState({
    id: null,
    organizationName: null
  });
  const [commercialRoleList, setCommercialRoleList] = React.useState([]);
  const [selectableCommercialRoleList, setSelectableCommercialRoleList] = React.useState([]);
  const [selectedAssignRoleOrgCode, setSelectedAssignRoleOrgCode] = React.useState("");
  const [allOrg, setAllOrg] = React.useState([]);
  const [assignedCommercialRoleOfOrg, setAssignedCommercialRoleOfOrg] = React.useState([]);
  const [selectedCommercialRolesByOrg, setSelectedCommercialRolesByOrg] = React.useState([]);
  const [addingOrg, setAddingOrg] = React.useState(false);
  const [assigningCommercialRole, setAssigningCommercialRole] = React.useState(false);

  const [installCCIndex, setInstallCCIndex] = React.useState('');
  const handleMouseEnterOrganizationRow = (event) => {
    setTextBreadCrumbOrganizationName(classes.textBreadCrumbsOnHoverOrganizationName);
    setTextBreadCrumbOrganizationDomainName(classes.textBreadCrumbsOnHoverOrganizationDomainName);
    setTextBreadCrumbOrganizationDescription(classes.textBreadCrumbsOnHoverOrganizationDescription)
  }

  const handleMouseEnterOrganizationRowOnClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const handleMouseLeaveOrganizationRow = () => {
    if ((editOrganizationName === editOrganizationNameCopy) && (editOrganizationDomainName === editOrganizationDomainNameCopy) && (editOrganizationDescription === editOrganizationDescriptionCopy)) {
      setTextBreadCrumbOrganizationName(classes.textBreadCrumbs);
      setTextBreadCrumbOrganizationDomainName(classes.textBreadCrumbs);
      setTextBreadCrumbOrganizationDescription(classes.textBreadCrumbs)
    }
  }

  const handleChangeOrganizationRow = (row, prop) => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      if (editOrganizationRowId !== null && editOrganizationRowId !== row["id"]) {
        setNewEditOrganizationRow(row);
        setNewProp(prop);
        setNewEventTargetValue(event.target.value);
        setPopupOpen(true);
      } else {
        setEditOrganizationRowId(row["id"])
        setFocusedOrganizationColumn(prop)
        if (editOrganizationRowId === null || editOrganizationRowId !== row["id"]) {
          setEditOrganizationName(row["organizationName"])
          setEditOrganizationNameCopy(row["organizationName"])
          setEditOrganizationDomainName(row["organizationDomainName"])
          setEditOrganizationDomainNameCopy(row["organizationDomainName"]);
          setEditOrganizationDescription(row["organizationDescription"]);
          setEditOrganizationDescriptionCopy(row["organizationDescription"]);
        }
        if (prop === "organizationName") {
          setEditOrganizationName(event.target.value)
        } else if (prop === "organizationDomainName") {
          setEditOrganizationDomainName(event.target.value)
        } else if (prop === "organizationDescription") {
          setEditOrganizationDescription(event.target.value)
        }
        // setBreadCrumbOrganizationDescriptionFocus(true);
        setTextBreadCrumbOrganizationName(classes.textBreadCrumbsOnHoverOrganizationName);
        setTextBreadCrumbOrganizationDomainName(classes.textBreadCrumbsOnHoverOrganizationDomainName);
      }
    }
  }

  const handleChangeMulitpleSelect = (event) => {
    setSelectedNetworks(event.target.value);
    // fetchOrganizationData(rowsPerPage, 25, orgOrderBy, "asc", null, event.target.value)
    fetchOrganizationData(false, orgRowsPerPage, 0, orgOrderBy, orgOrder, searchKey.value, event.target.value);
  };

  const buttonClickClearAllFilters = () => {
    let doRefresh = false
    if (selectedNetworks.length > 0) {
      doRefresh = true
    }
    setSelectedNetworks([]);
    if (doRefresh) {
      fetchOrganizationData();
    }
  }

  const handleSubmitOrganizationDetails = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setBackdrop(true);
    let requestObj = buildRequestObjEditOrganization();
    let statusCode = null;
    if (!requestObj) return;
    organizationService.updateOrganization(editOrganizationRowId, requestObj).then(
      response => {
        statusCode = response.status;
        // setSuccessChangeEditNetworkValues(true)
        notify({
          type: "success",
          message: "Successfully updated organization"
        });
        if (editOrganizationRowId == localStorage.getItem("_orgID")) {
          localStorage.setItem("_orgName", editOrganizationName)
          localStorage.setItem("_orgDomain", editOrganizationDomainName)
          window.location.reload()
        }
        setDrawerState({ ...drawerState, ["right"]: false });
        setInitialState();
        let _newOrgRows = orgRows
        for (let _newOrgRow of _newOrgRows) {
          if (_newOrgRow["id"] === editOrganizationRowId) {
            _newOrgRow["organizationName"] = editOrganizationName
            _newOrgRow["organizationDescription"] = editOrganizationDescription
            _newOrgRow["organizationDomainName"] = editOrganizationDomainName
            _newOrgRow["open"] = false
          }
        }
        setOrgRows(_newOrgRows)
        setOrgData(_newOrgRows);
        setOriginalOrgRows(_newOrgRows);
        setEditOrganizationRowId(null)
        setEditOrganizationName("")
        setEditOrganizationDomainName("")
        // fetchOrganizationData();
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while Updating organization"
        });
      }
    );
  }

  const handleClearOrganizationDetails = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setEditOrganizationRowId(null)
    setEditOrganizationName(editOrganizationNameCopy)
    setEditOrganizationDomainName(editOrganizationDomainNameCopy)
  }

  const handleClosePopupDiscard = () => {
    setPopupOpen(false);
    let row = newEditOrganizationRow;
    let prop = newProp;
    let eventTargetValue = newEventTargetValue
    setEditOrganizationRowId(row["id"])
    setFocusedOrganizationColumn(prop)
    setEditOrganizationName(row["organizationName"])
    setEditOrganizationNameCopy(row["organizationName"])
    setEditOrganizationDomainName(row["organizationDomainName"])
    setEditOrganizationDomainNameCopy(row["organizationDomainName"]);
    setEditOrganizationDescription(row["organizationDescription"])
    if (prop === "organizationName") {
      setEditOrganizationName(eventTargetValue)
    } else if (prop === "organizationDomainName") {
      setEditOrganizationDomainName(eventTargetValue)
    } else if (prop === "organizationDescription") {
      setEditOrganizationDescription(eventTargetValue)
    }
    // setBreadCrumbOrganizationDescriptionFocus(true);
    setTextBreadCrumbOrganizationName(classes.textBreadCrumbsOnHoverOrganizationName);
    setTextBreadCrumbOrganizationDomainName(classes.textBreadCrumbsOnHoverOrganizationDomainName);
  }

  const handleClosePopupDoNotDiscard = () => {
    setPopupOpen(false);
  }

  const setInitialState = () => {
    values.organizationDescription = "";
    values.orgName = ""
    values.orgDomainName = ""
    values.peers = []
    values.peerNames = [null]
    values.users = null;

    values.couchDBUsername = "";
    values.couchDBPassword = "";
    values.orgCaUsername = "";
    values.orgCaPassword = "";
    values.members = 0;
    values.inviteOrgs = [];
    values.roles = [];
    setSelectedOrgs([]);
    setNumberOfPeers(1)
    setOutsidePeerNames([null])
    editNetworkValues.networkName = ""
    editNetworkValues.networkDescription = ""
    editNetworkValues.networkId = ""
    setEditNetwork(false)
    setSelectedOrgToRemove({
      id: null,
      organizationName: null
    });
    setSelectedAssignRoleOrgCode("");
    setAssignedCommercialRoleOfOrg([]);
    setSelectableCommercialRoleList([]);
    setSelectedCommercialRolesByOrg([]);
  }

  const fetchNetworkData = () => {
    let query = {
      'peers': false,
      'organizations': false
    }
    setBackdrop(true);
    networkService.getNetworkById(props.location.state.networkId, query).then(
      response => {
        let _data = response.data;
        setNetworkName(_data.networkName);
        setNetworkNameCopy(_data.networkName);
        setNetworkDescription(_data.networkDescription);
        setNetworkDescriptionCopy(_data.networkDescription);
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while fetching network data"
        });
      }
    );
  }

  React.useEffect(() => {
    if (tab === 0) {
      fetchOrganizationData();

      organizationService.getOrgsWithoutPeers().then(
        response => {
          let data = response.data;
          setAllOrg(data);
          let nonOrdererOrgs = data.filter(org => org.isOrderer === false);
          setOrgListGenerateToken(nonOrdererOrgs);
        },
        error => {
          notify({
            type: "error",
            message: "Error while fetching organizations data"
          });
        }
      )

    } else {
      fetchChannelsData();
    }
    if (props.location.state) {
      setNetworkName((successChangeEditNetworkValues ? editNetworkValues.networkName : props.location.state.networkName));
      setNetworkNameCopy((successChangeEditNetworkValues ? editNetworkValues.networkName : props.location.state.networkName));
      setNetworkDescription((successChangeEditNetworkValues ? editNetworkValues.networkDescription : props.location.state.networkDescription));
      setNetworkDescriptionCopy((successChangeEditNetworkValues ? editNetworkValues.networkDescription : props.location.state.networkDescription));
      fetchNetworkData();
    }
    return function cleanup() {
      setOrgData([]);
      setOrgRows([]);
      setOriginalOrgRows([]);
    };
  }, [props.location.state]);

  const handleChange = prop => (event) => {

    // reset selection when changing number of members
    if (prop === "members") {
      values.inviteOrgs = [];
      values.roles = [];
      if (render) {
        setRender(false)
      } else {
        setRender(true)
      }
    }

    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeEditOrganization = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }
    setEditOrganizationValues({ ...editOrganizationValues, [prop]: event.target.value });
  };

  function handleCloseModal() {
    setAddOrgState(null);
    setEnrollAdminState(null);
    setShowModal(false);
  }

  function isOrganizationDetailsChanged() {
    if ((editOrganizationName === editOrganizationNameCopy) && (editOrganizationDomainName === editOrganizationDomainNameCopy) && (editOrganizationDescription === editOrganizationDescriptionCopy)) {
      setEditOrganizationRowId(null)
      return false
    }
    return true
  }

  function isPeerDetailsChanged() {
    if ((editPeerName === editPeerNameCopy) && (editPeerDomainName === editPeerDomainNameCopy)) {
      setEditPeerRowId(null)
      return false
    }
    return true
  }

  const handleChangeRoles = i => (event) => {
    if (values.roles[i]) {
      values.roles[i] = event.target.value;
    }
    else {
      values.roles.push(event.target.value);
    }

    if (render) {
      setRender(false)
    } else {
      setRender(true)
    }

  };

  const handleChangePeerNames = index => (event) => {
    let prop = '_peerName' + index;
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }

    var tempArrPeerNames = outsidePeerNames
    tempArrPeerNames[index] = event.target.value;

    if (render) {
      setRender(false)
    } else {
      setRender(true)
    }
  };


  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    if (newValue === 0 && !orgRows.length) {
      fetchOrganizationData();
    }
    if (newValue === 1 && !channelRows.length) {
      fetchChannelsData();
    }
    setTab(newValue);
  };


  const handleAdd = () => {
    outsidePeerNames.push(null)
    outsidePeerPorts.push(null)
    values.peerNames.push(null)
    values.peerPorts.push(null)
    setNumberOfPeers(numberOfPeers + 1)

  };

  const handleAddNew = () => {
    outsidePeerNames.push(null)
    outsidePeerPorts.push(null)
    values.peerNames.push(null)
    values.peerPorts.push(null)
    setNumberOfPeers(numberOfPeers + 1)
  };

  const handleClickonRow = (event, row, cameFrom, path, networkId) => {
    var newState = { row: row, cameFrom: cameFrom, networkName: networkNameCopy, networkId: networkId, networkDescription: networkDescriptionCopy }
    props.history.push({ pathname: path, state: newState });
  };

  const handleClickChannelonRow = (event, row, currentChannel) => {
    var newState = {
      channelValue: currentChannel, channelName: row.channelName, channelCode: row.channelCode, channelId: row.id,
      channelDescription: row.channelDescription, networkId: row.networkId, networkName: row.networkName, networkDescription: networkDescriptionCopy
    };
    props.history.push({ pathname: '/channel/' + row.channelName, state: newState });
  };

  const handleClickEditOrganiation = (side, open, row) => event => {
    setEditOrganization(true);
    event.stopPropagation();
    event.preventDefault();
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setEditOrganizationValues({
      "organizationName": row.organizationName,
      "organizationDescription": row.organizationDescription,
      "organizationDomainName": row.organizationDomainName,
      "organizationId": row.id
    });
    setDrawerState({ ...drawerState, [side]: open })
  }

  const handleRemove = (i) => {
    let newOutsidePeerNames = []
    for (let lol = 0; lol < values.peerNames.length; lol++) {
      if (lol !== i) {
        newOutsidePeerNames.push(outsidePeerNames[lol])
      }
    }
    let dummyArray = [...newOutsidePeerNames]
    values.peerNames = dummyArray
    outsidePeerNames.splice(i, 1)
    setNumberOfPeers(numberOfPeers - 1)
  };

  const handleSetDrawerState = (side, open, drawer) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateInviteOrg({ ...drawerStateInviteOrg, [side]: open });
    if (drawer === "close") {
      setInitialState();
    }
  }

  const openDrawer = (side, open) => event => {
    setEditOrganization(false)
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
  };

  const openDrawerAssignCommercialRole = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateAssignCommercialRole({ ...drawerState, [side]: open });

    organizationService.getAllCommercialRoles().then(
      response => {
        let data = response.data;
        setCommercialRoleList(data);
      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching commercial roles"
        });
      }
    )

  };

  const openInviteOrgDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateInviteOrg({ ...drawerStateInviteOrg, [side]: open });
  };

  const openDrawerClientToken = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateClientToken({ ...drawerStateClientToken, [side]: open });
  };

  const closeDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerState({ ...drawerState, [side]: open });
    setInitialState();
  };

  const closeDrawerInviteOrg = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateInviteOrg({ ...drawerStateInviteOrg, [side]: open });
    setInitialState();
  };

  const closeChannelDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setChannelDrawerState({ ...channelDrawerState, [side]: open });
  };

  const closeDrawerAssignCommercialRole = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateAssignCommercialRole({ ...drawerState, [side]: open });
    setInitialState();
  };

  const disableSubmitButtonEditOrganization = () => {
    if (editOrganizationValues.organizationName && editOrganizationValues.organizationDescription && editOrganizationValues.organizationDomainName) {
      return false;
    }
    return true;
  };

  const validateFields = (...fields) => {
    let errorFields = [];
    fields.forEach(field => {
      if (!field) {
        errorFields.push(field);
      }
    });
    return errorFields;
  };

  const handleSubmitEditOrganization = () => {
    setBackdrop(true);
    closeDrawer('right', false);
    let requestObj = buildRequestObjEditOrganization();
    let statusCode = null;
    if (!requestObj) return;
    organizationService.updateOrganization(editOrganizationValues.organizationId, requestObj).then(
      response => {
        statusCode = response.status;
        notify({
          type: "success",
          message: "Successfully updated organization"
        });
        // setSuccessChangeEditNetworkValues(true)
        setDrawerState({ ...drawerState, ["right"]: false });
        setInitialState();
        fetchOrganizationData();
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while Updating organization"
        });
      }
    );
  };


  const sideListEditOrganization = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Update Organization Details"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>

      <div style={{ overflow: "auto", maxHeight: "74vh" }}>
        <div className={classes.flexItem}>
          <TextField
            id="standard-basic"
            className={classes.textField}
            error={invalidUserInput.includes("organizationName")}
            label={"Name"}
            margin="normal"
            rows="2"
            fullWidth
            defaultValue={editOrganizationValues.organizationName}
            autoComplete='off'
            onChange={handleChangeEditOrganization("organizationName")}
          />
        </div>
        <div className={classes.flexItem}>
          <TextField
            id="standard-basic"
            className={classes.textField}
            error={invalidUserInput.includes("organizationDescription")}
            label={"Description"}
            margin="normal"
            rows="2"
            fullWidth
            defaultValue={editOrganizationValues.organizationDescription}
            autoComplete='off'
            onChange={handleChangeEditOrganization("organizationDescription")}
          />
        </div>
        <div className={classes.flexItem}>
          <TextField
            id="standard-basic"
            className={classes.textField}
            error={invalidUserInput.includes("organizationDomainName")}
            label={"Domain"}
            margin="normal"
            rows="2"
            fullWidth
            defaultValue={editOrganizationValues.organizationDomainName}
            autoComplete='off'
            onChange={handleChangeEditOrganization("organizationDomainName")}
          />
        </div>

      </div>

      <div className={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmitEditOrganization} color="primary" variant="contained" disabled={backdrop || disableSubmitButtonEditOrganization() || invalidUserInput.length}>
          {"SUBMIT"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  const disableSubmitButtonAssignCommercialRole = () => {
    if (selectedAssignRoleOrgCode.length && selectedCommercialRolesByOrg.length) {
      return false;
    }
    return true;
  };

  const handleSubmitAssignCommercialRole = () => {
    setAssigningCommercialRole(true);
    setDrawerStateAssignCommercialRole({ ...drawerState, right: false });
    let _assignedRoleCode = assignedCommercialRoleOfOrg.map(x => x.code);
    let _selectedRoles = selectedCommercialRolesByOrg.map(x => x.code);
    let requestObj = {
      orgRoles: [
        {
          organizationCode: selectedAssignRoleOrgCode,
          roles: [..._assignedRoleCode, ..._selectedRoles]
        }
      ]
    }
    organizationService.assignCommercialRolesToOrganizations(requestObj).then(
      response => {
        notify({
          type: "success",
          message: "Successfully assigned commercial role"
        });
        setDrawerStateAssignCommercialRole({ ...drawerStateAssignCommercialRole, ["right"]: false });
        setInitialState();
        setAssigningCommercialRole(false);
      },
      error => {
        setAssigningCommercialRole(false);
        notify({
          type: "error",
          message: "Error while assigning commercial role"
        });
      }
    );
  };

  const handleChangeAssignRoleOrg = (event) => {
    setAssignedCommercialRoleOfOrg([]);
    setSelectedCommercialRolesByOrg([]);
    setSelectedAssignRoleOrgCode(event.target.value);
    let _orgCode = event.target.value;

    organizationService.getOrgCommercialRolesByOrgCode(_orgCode).then(
      response => {
        let data = response.data;
        setAssignedCommercialRoleOfOrg(data);
        let _assignedRoleCode = data.map(x => x.code);
        let _selectableCommercialRoleList = commercialRoleList.filter(x => !_assignedRoleCode.includes(x.code));
        setSelectableCommercialRoleList(_selectableCommercialRoleList);
      },
      error => {
        notify({
          type: "error",
          message: "Error while fetching commercial roles for selected organization"
        });
      }
    )

  };

  const handleChangeMultiSelectCommercialOrgRole = prop => (event, newValue) => {
    setSelectedCommercialRolesByOrg(newValue);
  }


  const sideListAssignCommercialRole = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawerAssignCommercialRole("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Assign commercial roles to organization"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <div className={classes.docFormDiv}>
        <div className={classes.shipmentRefAndChannelDiv}>
          {
            assignedCommercialRoleOfOrg.length != 0 ?
              <div className={classes.assignedCommercialRoleOfOrg}>
                <div className={classes.sharedShipmentsUpdateDoc}>
                  <Typography variant="h6" className={classes.memberListTitle}>
                    Commercial role(s) assigned to selected organization:
                      </Typography>
                  <List dense={true}>
                    {assignedCommercialRoleOfOrg.map((role) => (
                      <ListItem className={classes.memberListItem}>
                        <ListItemText
                          primary={role.name}
                          className={classes.memberListItemText}
                        />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </div> : ""
          }
          <FormControl className={classes.channelFormControl}>
            <InputLabel id="channel-checkbox-label">Select organization</InputLabel>
            <Select
              labelId="channel-checkbox-label"
              id="channel-checkbox"
              value={selectedAssignRoleOrgCode}
              onChange={handleChangeAssignRoleOrg}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {
                allOrg.map((org) => (
                  <MenuItem
                    key={org.organizationCode}
                    value={org.organizationCode}
                  >
                    {org.organizationName}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <div className={classes.shipmentRefNumAutoCompleteTextField}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={selectableCommercialRoleList}
              size="small"
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select Commercial Roles"
                  helperText={"Assign more commercial role(s) to selected organization"}
                />
              )}
              renderOption={(option, { inputValue }) => {
                const matches = match(option.name, inputValue);
                const parts = parse(option.name, matches);

                return (
                  <div>
                    {parts.map((part, index) => (
                      <span key={index} style={{ fontSize: "0.875rem" }}>
                        {part.text}
                      </span>
                    ))}
                  </div>
                );
              }}
              onChange={handleChangeMultiSelectCommercialOrgRole()}
            />
          </div>

        </div>
      </div>


      <div className={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmitAssignCommercialRole} color="primary" variant="contained" disabled={backdrop || disableSubmitButtonAssignCommercialRole()}>
          {"SUBMIT"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawerAssignCommercialRole('right', false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  const buildRequestObjEditOrganization = () => {
    let invalidFields = [];
    invalidFields = validateFields(editOrganizationName);

    if (invalidFields.length) {
      setBackdrop(false);
      notify({
        type: "error",
        message:
          "Invalid Fields(" +
          invalidFields.length +
          "): Please provide valid input."
      });
      return;
    }
    var formData = {};
    formData["organizationName"] = editOrganizationName;
    formData["organizationDescription"] = editOrganizationDescription;
    formData["organizationDomainName"] = editOrganizationDomainName;
    // formData["organizationId"] = editOrganizationValues.organizationId;
    formData["orgRole"] = localStorage.getItem("_orgRole")
    formData["userRole"] = localStorage.getItem("_userRole")
    return formData;
  };

  const handleSubmitInstallChainCode = () => {
    setBackdrop(true);
    installChainCode.peers = [installPeer]
    chaincodeService.installChainCode(installChainCode).then(response => {
      notify({
        type: 'success',
        message: 'Chaincode installed successfully!'
      });
      closeDrawerChainCode("right", false);
      setDrawerStateChainCode({ ...drawerStateChainCode, ['right']: false });
      setBackdrop(false);
      chaincodeService.getInstalledChaincodesByPeerId(installChainCode.peers[0].id).then(
        response => {
          for (let peer of orgRows[installCCIndex].peers) {
            if (peer.id == installChainCode.peers[0].id) {
              peer.installedChaincodes = response.data
            }
          }
          setOrgRows(orgRows);
          if (render) {
            setRender(false)
          } else {
            setRender(true)
          }
        },
        error => {

        }
      );
    }, error => {
      if (error.response && error.response.status == 409) {
        notify({
          type: "error",
          message: "This chaincode is already installed"
        });
      } else {
        notify({
          type: "error",
          message: "Error occured while installing chaincode"
        });
      }
      setBackdrop(false);
    });
  };

  const handleSubmitGenerateClientToken = () => {
    setBackdrop(true);
    organizationService.generateClientCredentials(orgIdGenerateToken).then(response => {

      let data = response.data;
      setGeneratedClientId(data.clientId);
      setGeneratedClientSecret(data.clientSecret);
      notify({
        type: 'success',
        message: 'Client token generated successfully!'
      });
      setBackdrop(false);
    }, error => {
      notify({
        type: "error",
        message: "Failed to generate client token"
      });
      setBackdrop(false);
    });
  };

  const handleSubmit = () => {
    values.peerNames = [...outsidePeerNames]
    let requestObjWithNetwork = buildRequestObjForAddingOrgWithNetwork(values);
    let requestObjNoNetwork = buildRequestObjForAddingOrgNoNetwork(values);

    let statusCode = null;
    setDrawerState({ ...drawerState, right: false });
    setAddingOrg(true);
    if (props.location.state) {
      NetworkService.inviteOrgsToNetwork(requestObjWithNetwork, props.location.state.networkId).then(
        response => {
          setAddingOrg(false);
          let message = "";
          let _data = response.data;
          let partialFailure = _data.some(i => !i.success)

          if (partialFailure) {
            let failedOrgs = _data.filter(i => !i.success).map(i => i.data.orgName);
            message = "Successfully invited some of the orgs to the network. Failed to invite the following orgs : " + failedOrgs.toString();
          } else {
            message = "Successfully invited all orgs to network";
          }

          notify({
            type: "success",
            message: message
          });
          let reload = true;
          fetchOrganizationData(reload, orgRowsPerPage, orgPage, orgOrderBy, orgOrder);
          setDrawerState({ ...drawerState, ["right"]: false });
          setInitialState();

        },
        error => {

          setAddingOrg(false);
          notify({
            type: "error",
            message: "Failed to invite the orgs to the network"
          });
        }
      );

    } else {
      organizationService.addOrg(requestObjNoNetwork).then(
        response => {
          setAddingOrg(false);
          statusCode = response.status;
          notify({
            type: "success",
            message: "Successfully created organization "
          });

          let reload = true;
          fetchOrganizationData(reload, orgRowsPerPage, 0, orgOrderBy, orgOrder);
          setDrawerState({ ...drawerState, ["right"]: false });
          setInitialState();
        },
        error => {
          setAddingOrg(false);
          notify({
            type: "error",
            message: "Error while creating organization "
          });
        }
      );
    }
  };

  const handleRequestSort = property => event => {
    const isDesc = orgOrderBy === property && orgOrder === "desc";
    setOrgOrder(isDesc ? "asc" : "desc");
    let reload = false;
    setOrgPage(0);
    setOrgOrderBy(property);
    fetchOrganizationData(reload, orgRowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value, selectedNetworks);
  };

  const fetchOrganizationData = (reload = true, pageSize = orgRowsPerPage, currentPage = 0, sortBy = orgOrderBy, sortOrder = orgOrder, searchText = "", filterArray = null) => {

    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder
    }

    if (searchText) {
      query['q'] = searchText;
    }

    if (drawerStateInviteOrg['right'] === true) {
      setDrawerStateInviteOrg({ ...drawerStateInviteOrg, ['right']: false });
    }

    setSearchKey({ value: searchText });

    if (props.location.state) {
      setBackdrop(true);
      organizationService.getOrgsByNetwork(props.location.state.networkId, query).then(
        response => {
          let _data = response.data;
          _data.map(org => {
            org["peerCount"] = org["peers"].length;
            org["open"] = false
          })
          setOrgRows(_data);
          if (response.headers.hasOwnProperty('x-total-count')) {
            setQueryCount(parseInt(response.headers['x-total-count']));
          }
          if (!searchText) {
            setOrgData(_data);
            setOriginalOrgRows(_data);
            setOriginalQueryCount(parseInt(response.headers['x-total-count']));
          }
          setBackdrop(false);
          closeDrawer("right", false);
        },
        error => {
          setBackdrop(false);
          if (searchText) {
            setOrgRows([]);
          }
          else {
            notify({
              type: "error",
              message: "Error while fetching organizations data by network"
            });
          }
        }
      );
    } else {
      setBackdrop(true);
      if (filterArray && filterArray.length > 0) {
        query["_networkName"] = filterArray
      }
      organizationService.getOrgs(query).then(
        response => {
          let _data = response.data;
          let networksArrayTemp = []
          {
            _data.map(org => {
              org["peerCount"] = org["peers"].length;
              org["networks"].map(networkTemp => {
                if (!networksArrayTemp.includes(networkTemp["networkName"])) {
                  networksArrayTemp.push(networkTemp["networkName"])
                }
              })
              org["open"] = false
            })
          }
          if (networksArray.length === 0) {
            setNetworksArray(networksArrayTemp);
          }

          setOrgRows(_data);
          if (response.headers.hasOwnProperty('x-total-count')) {
            setQueryCount(parseInt(response.headers['x-total-count']));
          }
          if (!searchText) {
            setOrgData(_data);
            setOriginalOrgRows(_data);
            setOriginalQueryCount(parseInt(response.headers['x-total-count']));
          }


          setBackdrop(false);
        },
        error => {
          setBackdrop(false);
          notify({
            type: "error",
            message: "Error while fetching organizations data"
          });
        }
      );
    }

  };

  const handleChangeOrgPage = (event, newPage) => {
    setOrgPage(newPage);
    setOrgRows([]);
    setOriginalOrgRows([]);
    let reload = false;
    fetchOrganizationData(reload, orgRowsPerPage, newPage);
  };

  const handleChangeOrgRowsPerPage = event => {
    setOrgRowsPerPage(+event.target.value);
    setOrgPage(0);
    setOrgRows([]);
    setOriginalOrgRows([]);
    let reload = false;
    fetchOrganizationData(reload, +event.target.value, 0);
  };

  const handleSetInitialOrgRow = () => {
    setOrgRows(originalOrgRows);
    setQueryCount(originalqueryCount);
  }

  function createParticipatedNetworks(networks) {
    let networkString = "";
    if (networks && networks.length > 1) {
      networkString = networks[0]["networkName"] + "; ..... "
    } else if (networks && networks.length === 1) {
      networkString = networks[0]["networkName"]
    }

    return networkString
  }

  function createInstalledChainCodes(chaincodes) {

    let chaincodesString = "";
    if (chaincodes) {
      if (chaincodes.length > 1) {
        chaincodesString = chaincodes[0]["name"] + " (v" + chaincodes[0]["version"] + ") ; ..... "
      } else if (chaincodes.length === 1) {
        chaincodesString = chaincodes[0]["name"] + " (v" + chaincodes[0]["version"] + ")"
      } else {
        chaincodesString = "No chaincodes installed"
      }
    }
    return chaincodesString
  }

  function createPeers() {
    let arr = []
    let extraArray = []
    for (let kol = 0; kol < values.peerNames.length; kol++) {
      extraArray.push(null)
    }
    values.peerNames = extraArray
    for (let peerNumber = 0; peerNumber < numberOfPeers; peerNumber++) {
      arr.push(
        <div key={peerNumber} className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              key={(peerNumber + 1) + 'ok'}
              required
              error={invalidUserInput.includes("_peerName" + peerNumber)}
              className={classes.textField}
              label={"Peer " + (peerNumber + 1) + " Name"}
              margin="normal"
              rows="2"
              fullWidth
              value={values.peerNames[peerNumber]}
              onChange={handleChangePeerNames(peerNumber)}
            />
          </div>
          {peerNumber === 0 ? "" :
            <IconButton className={classes.closeButton} onClick={() => handleRemove(peerNumber)}>
              <DeleteForeverIcon />
            </IconButton>}
        </div>
      )
    }
    return arr
  }

  const sideListAddNewOrg = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawer("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Add a new Organization"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <br></br>
      <div style={{ overflow: "auto", maxHeight: "74vh" }}>
        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("orgName")}
              label={"Organization Name"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.orgName}
              onChange={handleChange("orgName")}
            />
          </div>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              required
              className={classes.textField}
              error={invalidUserInput.includes("orgDomainName")}
              label={"Organizaton Domain"}
              margin="normal"
              rows="2"
              fullWidth
              value={values.orgDomainName}
              onChange={handleChange("orgDomainName")}
            />
          </div>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              error={invalidUserInput.includes("organizationDescription")}
              label={"Organization Description"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.organizationDescription}
              onChange={handleChange("organizationDescription")}
            />
          </div>
        </div>
        <div>
          {createPeers()}
          <div className={classes.formContainer}>

            <Button autoFocus startIcon={<AddCircleIcon />} className={classes.addButton} color="primary"
              onClick={() => handleAdd()} disabled={disableAddPeerButton(values)}>
              Add Peer
            </Button>
          </div>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              label={"Ca Username"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.orgCaUsername}
              onChange={handleChange("orgCaUsername")}
            />
          </div>
          <div className={classes.flexItem}>
            <TextField
              type="password"
              id="standard-basic"
              className={classes.textField}
              label={"Ca Password"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.orgCaPassword}
              onChange={handleChange("orgCaPassword")}
            />
          </div>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.flexItem}>
            <TextField
              id="standard-basic"
              className={classes.textField}
              label={"Couch DB Username"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.couchDBUsername}
              onChange={handleChange("couchDBUsername")}
            />
          </div>
          <div className={classes.flexItem}>
            <TextField
              type="password"
              id="standard-basic"
              className={classes.textField}
              label={"Couch DB Password"}
              margin="normal"
              rows="2"
              required
              fullWidth
              value={values.couchDBPassword}
              onChange={handleChange("couchDBPassword")}
            />
          </div>
        </div>

      </div>
      <div class={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmit} color="primary" variant="contained" disabled={addingOrg || disableSubmitButtonOrgWithoutNetwork(values, outsidePeerNames, outsidePeerPorts) || invalidUserInput.length}>
          {"SUBMIT"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );

  const buildRequestObjEditNetwork = (_networkName, _networkDescription) => {
    var formData = {};
    formData["networkName"] = _networkName;
    formData["networkDescription"] = _networkDescription;
    formData["userOrganizationId"] = localStorage.getItem("_orgID")
    formData["orgRole"] = localStorage.getItem("_orgRole")
    formData["userRole"] = localStorage.getItem("_userRole")
    return formData;
  };

  function handleSubmitEditNetwork(_networkName, _networkDescription) {
    setBackdrop(true);
    closeDrawer('right', false);
    let requestObj = buildRequestObjEditNetwork(_networkName, _networkDescription);
    let statusCode = null;
    if (!requestObj) return;
    NetworkService.updateNetwork(props.location.state.networkId, requestObj).then(
      response => {
        statusCode = response.status;
        notify({
          type: "success",
          message: "Successfully updated network"
        });
        setSuccessChangeEditNetworkValues(true)
        setDrawerState({ ...drawerState, ["right"]: false });
        setInitialState();
        setBackdrop(false);
        setNetworkName(_networkName);
        setNetworkNameCopy(_networkName);
        props.location.state.networkName = _networkName;
        setNetworkDescription(_networkDescription);
        setNetworkDescriptionCopy(_networkDescription);
        props.location.state.networkDescription = _networkDescription;
        setBreadCrumbNetworkNameFocus(false);
        setNetworkDescriptionFocus(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while updating the network"
        });
      }
    );
  };



  /*********************************************************************************************************************************/
  /* --------------------------------------------------   Channels Tab   --------------------------------------------------------- */
  /*********************************************************************************************************************************/



  const fetchChannelsData = (pageSize = channelRowsPerPage, currentPage = 0, sortBy = channelOrderBy, sortOrder = channelOrder, searchText = "") => {

    let userOrgId = localStorage.getItem('_orgID');
    let query = {
      '_page': currentPage + 1,
      '_limit': pageSize,
      '_sort': sortBy,
      '_order': sortOrder
    }
    if (searchText) {
      query['q'] = searchText;
    }

    setChannelSearchKey({ value: searchText });

    setChannelDrawerState({ ...channelDrawerState, 'right': false });
    setBackdrop(true);

    channelService.getChannelsByNetworkId(props.location.state.networkId, userOrgId, query).then(
      response => {
        const _data = response.data;
        setChannelInfoData(_data);
        setChannelRows(_data);
        if (response.headers.hasOwnProperty('x-total-count')) {
          setChannelQueryCount(parseInt(response.headers['x-total-count']));
        }

        if (!searchText) {
          setOriginalChannelRows(_data);
          setChannelOriginalQueryCount(parseInt(response.headers['x-total-count']));
        }
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        if (searchText) {
          setChannelRows([]);
        }
        else {
          notify({
            type: "error",
            message: "Error while fetching channels data"
          });
        }
      }
    );

  };

  const handleSetInitialChannelRow = () => {
    setChannelRows(originalChannelRows);
    setChannelQueryCount(channelOriginalqueryCount);
  }

  const openChannelDrawer = (side, open, row) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setChannelDrawerState({ ...channelDrawerState, [side]: open });
  };

  const handleChannelRequestSort = property => event => {
    const isDesc = channelOrderBy === property && channelOrder === "desc";
    setChannelOrder(isDesc ? "asc" : "desc");
    setChannelOrderBy(property);
    fetchChannelsData(channelRowsPerPage, 0, property, isDesc ? "asc" : "desc", channelSearchKey.value);
  };

  const handleChangeChannelPage = (event, newPage) => {
    setChannelPage(newPage);
    fetchChannelsData(channelRowsPerPage, newPage);
  };

  const handleChangeChannelRowsPerPage = event => {
    setChannelRowsPerPage(+event.target.value);
    setChannelPage(0);
    fetchChannelsData(+event.target.value, 0);
  };

  const getCurrentChannel = (row) => {
    //TODO: process columns....
    var currentChannel = channelInfoData.filter(function (channel) {
      return channel.channelName == row.channelName;
    });
    return currentChannel;
  };


  const handleChangeNetworkName = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }

    setNetworkName(event.target.value);
    setBreadCrumbNetworkNameFocus(true);
  }

  const [textBreadCrumbs, setTextBreadCrumbs] = React.useState(classes.textBreadCrumbs);

  const handleNetworkNameSave = (event) => {
    if (!invalidUserInput.includes("networkName")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditNetwork(networkName, networkDescriptionCopy);
    }
  }

  const handleBreadCrumbUndo = () => {
    setNetworkName(networkNameCopy);
    setBreadCrumbNetworkNameFocus(false);
    setTextBreadCrumbs(classes.textBreadCrumbs);
  }

  const handleMouseEnterNetworkName = () => {
    setTextBreadCrumbs(classes.textBreadCrumbsOnHover);
  }

  const handleMouseLeaveNetworkName = () => {
    if (networkName === networkNameCopy) {
      setTextBreadCrumbs(classes.textBreadCrumbs);
    }
  }

  const openDrawerShowNetworks = (side, open, networks) => event => {
    event.stopPropagation();
    event.preventDefault();
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setParticipatedNetworks(networks)
    setDrawerStateNetworks({ ...drawerStateNetworks, [side]: open });
  };

  const openDrawerShowPeers = (side, open, chaincodes) => event => {
    event.stopPropagation();
    event.preventDefault();
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setChaincodes(chaincodes)
    setDrawerStatePeers({ ...drawerStatePeers, [side]: open });
  };

  const handleClickRow = (row) => event => {
    if (!row["open"]) {
      if (!row["isOrderer"] && !(row["peers"] && row["peers"][0] && row["peers"][0]["installedChaincodes"])) {
        let query = {
          'chaincodeFlag': true
        }
        setBackdrop(true);
        organizationService.getPeersByOrgId(row.id, query).then(
          response => {
            let _data = response.data;
            for (let rowPeer of row.peers) {
              for (let peer of _data) {
                if (peer["id"] === rowPeer["id"]) {
                  rowPeer["installedChaincodes"] = peer["installedChaincodes"];
                  break;
                }
              }
            }
            for (let orgRow of orgRows) {
              if (orgRow["id"] === row["id"]) {
                orgRow = row
                break;
              }
            }

            setOrgRows(orgRows);
            setOriginalOrgRows(orgRows);
            setBackdrop(false);
          },
          error => {
            setBackdrop(false);
            notify({
              type: "error",
              message: "Error "
            });
          }
        );
      }
    }
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (row["open"]) {
      row["open"] = false
    } else {
      row["open"] = true
    }
    if (render) {
      setRender(false)
    } else {
      setRender(true)
    }
  }

  const openDrawerChainCode = (side, open, peer, index) => event => {
    setInstallCCIndex(index);
    setChaincodes(peer.installedChaincodes)
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setInstallPeer(peer);
    setBackdrop(true);

    chaincodeService.getAllChaincodes().then(
      response => {
        let _data = response.data;
        let chainCodesArray = []
        let _uninstalledChainCodes = []
        for (let chaincode of _data) {
          let isChaincodeInstalled = false
          for (let peerTemp of peer.installedChaincodes) {
            if ((chaincode.name == peerTemp.name) && (chaincode.version == peerTemp.version)) {
              isChaincodeInstalled = true
              break
            }
          }
          if (!isChaincodeInstalled) {
            _uninstalledChainCodes.push(chaincode)
          }
          chainCodesArray[chaincode.name + chaincode.version] = { id: chaincode.id, version: chaincode.version }
        }
        setMapChainCodes(chainCodesArray)
        setChains(_uninstalledChainCodes);
        setBackdrop(false);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error occured while fetcing the data"
        });
      }

    );

    setDrawerStateChainCode({ ...drawerStateChainCode, [side]: open });
  };

  const closeDrawerChainCode = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateChainCode({ ...drawerStateChainCode, [side]: open });
  };

  const closeDrawerNetworks = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStateNetworks({ ...drawerStateNetworks, [side]: open });
  };

  const closeDrawerPeers = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerStatePeers({ ...drawerStatePeers, [side]: open });
  };

  const closeDrawerClientToken = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setGeneratedClientId("");
    setGeneratedClientSecret("");
    setDrawerStateClientToken({ ...drawerStateClientToken, [side]: open });
  };

  const handleChangeNetworkDescription = prop => (event) => {
    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
    }

    setNetworkDescription(event.target.value);
    setNetworkDescriptionFocus(true);
  }

  const [textNetworkDescription, setTextNetworkDescription] = React.useState(classes.textNetworkDescription);

  const handleNetworkDescriptionSave = (event) => {
    if (!invalidUserInput.includes("networkDescription")) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmitEditNetwork(networkNameCopy, networkDescription);
    }
  }

  const handleNetworkDescriptionUndo = () => {
    setNetworkDescription(networkDescriptionCopy);
    setNetworkDescriptionFocus(false);
    setTextNetworkDescription(classes.textNetworkDescription);
  }

  const handleMouseEnterNetworkDescription = () => {
    setTextNetworkDescription(classes.textNetworkDescriptionHover);
  }

  const handleMouseLeaveNetworkDescription = () => {
    if (networkDescription === networkDescriptionCopy) {
      setTextNetworkDescription(classes.textNetworkDescription);
    }
  }

  const handleChangeChainCode = prop => (event) => {
    setInstallChainCode(mapChainCodes[event.target.value])
  };

  const handleChangeOrgListGenerateToken = prop => (event) => {
    setOrgIdGenerateToken(event.target.value)
  };

  const handleClosePopupDiscardPeer = () => {
    setPopupOpenPeer(false);
    let row = newEditPeerRow;
    let prop = newProp;
    let eventTargetValue = newEventTargetValue
    setEditPeerRow(row)
    setEditPeerRowId(row["id"])
    setFocusedPeerColumn(prop)

    setEditPeerName(row["peerName"])
    setEditPeerNameCopy(row["peerName"])
    setEditPeerDomainName(row["peerDomainName"])
    setEditPeerDomainNameCopy(row["peerDomainName"])
    if (prop === "peerName") {
      setEditPeerName(eventTargetValue)
    } else if (prop === "peerDomainName") {
      setEditPeerDomainName(eventTargetValue)
    }
    // setBreadCrumbOrganizationDescriptionFocus(true);
    setTextBreadCrumbPeerName(classes.textBreadCrumbsOnHoverPeerName);
    setTextBreadCrumbPeerDomainName(classes.textBreadCrumbsOnHoverPeerDomainName);
  }

  const handleClosePopupDoNotDiscardPeer = () => {
    setPopupOpenPeer(false);
  }

  const handleMouseEnterPeerRow = () => {
    setTextBreadCrumbPeerName(classes.textBreadCrumbsOnHoverPeerName);
    setTextBreadCrumbPeerDomainName(classes.textBreadCrumbsOnHoverPeerDomainName);
  }

  const handleMouseLeavePeerRow = () => {
    if (editPeerName === editPeerNameCopy) {
      setTextBreadCrumbPeerName(classes.textBreadCrumbs);
    }
  }

  const handleChangePeerRow = (row, prop) => (event) => {

    if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
      if (!invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput, prop]);
      }
    } else {
      if (invalidUserInput.includes(prop)) {
        setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
      }
      if (editPeerRowId !== null && editPeerRowId !== row["id"]) {
        setNewEditPeerRow(row);
        setNewProp(prop);
        setNewEventTargetValue(event.target.value);
        setPopupOpenPeer(true);
      } else {
        setEditPeerRow(row)
        setEditPeerRowId(row["id"])
        setFocusedPeerColumn(prop)
        if (editPeerRowId === null || editPeerRowId !== row["id"]) {
          setEditPeerName(row["peerName"])
          setEditPeerNameCopy(row["peerName"])
          setEditPeerDomainName(row["peerDomainName"])
          setEditPeerDomainNameCopy(row["peerDomainName"])
        }
        if (prop === "peerName") {
          setEditPeerName(event.target.value)
        } else if (prop === "peerDomainName") {
          setEditPeerDomainName(event.target.value)
        }
        setBreadCrumbOrganizationDescriptionFocus(true);
        setTextBreadCrumbPeerName(classes.textBreadCrumbsOnHoverPeerName);
        setTextBreadCrumbPeerDomainName(classes.textBreadCrumbsOnHoverPeerDomainName);
      }
    }
  }

  const buildRequestObjEditPeer = (_peerName, _peerDomainName) => {
    var formData = {};
    formData["peerName"] = _peerName;
    formData["peerDomainName"] = _peerDomainName;
    return formData;
  };

  const handleSubmitPeerDetails = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setBackdrop(true);
    let requestObj = buildRequestObjEditPeer(editPeerName, editPeerDomainName);
    if (!requestObj) return;
    organizationService.updatePeer(localStorage.getItem("_orgID"), editPeerRowId, requestObj).then(
      response => {
        notify({
          type: "success",
          message: "Successfully updated Peer"
        });
        setEditPeerRowId(null)
        setBackdrop(false);
        fetchOrganizationData();
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: "Error while Updating Peer"
        });
      }
    );
  }

  const handleClearPeerDetails = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setEditPeerRowId(null)
    setEditPeerName(editPeerNameCopy)
    setEditPeerDomainName(editPeerDomainNameCopy)
  }

  const sideListGenerateClientToken = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawerClientToken("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Generate client credentials"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <div className={classes.flexItem}>
        <TextField
          id="standard-basic"
          select
          required
          className={classes.textField}
          label={"Organizations"}
          margin="normal"
          rows="2"
          fullWidth
          disabled={orgListGenerateToken.length === 0}
          onChange={handleChangeOrgListGenerateToken("")}
        >
          {orgListGenerateToken.map((org) => (
            <MenuItem key={org.id} value={org.id}>
              {org.organizationName}
            </MenuItem>
          ))}
        </TextField>
      </div>

      {
        (generatedClientId.length && generatedClientSecret.length) ?
          <div className={classes.flexItem}>
            <Typography className={classes.clientToken}>
              {`Client Id: ${generatedClientId}`}
            </Typography>
            <Typography className={classes.clientToken}>
              {`Client Secret: ${generatedClientSecret}`}
            </Typography>
            <label className={classes.clientTokenLabel}>
              {`* Please copy the client id and client secret, they cannot be retrieved again.`}
            </label>
          </div> : ""
      }

      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmitGenerateClientToken} color="primary" variant="contained" disabled={backdrop || !orgIdGenerateToken}>
          {"GENERATE CREDENTIALS"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawerClientToken("right", false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>
    </div>
  );

  const sideListChainCode = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawerChainCode("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Install Chaincodes"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <div className={classes.flexItem}>
        <TextField
          id="standard-basic"
          select
          required
          className={classes.textField}
          label={"Chain Code"}
          margin="normal"
          rows="2"
          fullWidth
          disabled={chains.length === 0}
          onChange={handleChangeChainCode("")}
        >
          {chains.map((chain) => (
            <MenuItem key={chain.name + chain.version} value={chain.name + chain.version}>
              {chain.name + " (v" + chain.version + ")"}
            </MenuItem>
          ))}
        </TextField>
        {
          !backdrop && chains && chains != null && Object.keys(chains).length === 0 ?
            <Typography style={{ textAlign: "center", marginLeft: "-18%" }}>All available chaincodes are already installed</Typography>
            :
            ""
        }
      </div>
      <div style={{ marginTop: "100px", marginBottom: "30px", textAlign: "center" }}>

        <Typography variant="h6" noWrap>{"Installed chaincodes on the peer"}</Typography>
      </div>
      <Divider></Divider>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {chaincodes != null && Object.keys(chaincodes).length !== 0 && typeof chaincodes === 'object' ? (
            chaincodes.map((chaincode) => {
              return (
                <TableRow style={{ height: "60px" }} key="id">
                  <TableCell style={{ textAlign: "center" }} component="th" scope="row">
                    <b>{chaincode.name}</b>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Version : {chaincode.version} </TableCell>
                </TableRow>
              );
            })
          ) : (
              <Typography variant="h6" style={{ textAlign: "center", marginTop: "2vw" }}>No Chaincodes are installed on the Peer Yet</Typography>
            )}
        </TableBody>
      </Table>


      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.sidedrawerFooter}>
        <Divider />
        <Button className={classes.drawerButton} onClick={handleSubmitInstallChainCode} color="primary" variant="contained" disabled={backdrop || (installChainCode.id === null)}>
          {"SUBMIT"}
        </Button>
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawerChainCode("right", false)} variant="contained" disabled={backdrop}>
          {"CANCEL"}
        </Button>
      </div>
    </div>

  );

  const sideListNetworks = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawerNetworks("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Participated Networks"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {participatedNetworks != null && Object.keys(participatedNetworks).length !== 0 && typeof participatedNetworks === 'object' ? (
            participatedNetworks.map((participatedNetwork) => {
              return (
                <TableRow style={{ height: "60px" }} key="id">
                  <TableCell component="th" scope="row">
                    <b>{participatedNetwork.networkName}</b>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
              ""
            )}
        </TableBody>
      </Table>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.sidedrawerFooter}>
        <Divider />
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawerNetworks("right", false)} variant="contained" disabled={backdrop}>
          {"CLOSE"}
        </Button>
      </div>
    </div>

  );

  const sideListPeers = side => (
    <div className={classes.list}>
      <div >
        <Toolbar>
          <div className={classes.drawerHeader}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={closeDrawerPeers("right", false)}
              edge="start"
            >
              <ChevronRightIcon color="primary" />
            </IconButton>
          </div>
          <Typography variant="h6" noWrap>{"Installed Chaincodes"}</Typography>
        </Toolbar>
        <Divider></Divider>
      </div>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {chaincodes != null && Object.keys(chaincodes).length !== 0 && typeof chaincodes === 'object' ? (
            chaincodes.map((chaincode) => {
              return (
                <TableRow style={{ height: "60px" }} key="id">
                  <TableCell style={{ textAlign: "center" }} component="th" scope="row">
                    <b>{chaincode.name}</b>
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Version : {chaincode.version} </TableCell>
                </TableRow>
              );
            })
          ) : (
              ""
            )}
        </TableBody>
      </Table>
      <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.sidedrawerFooter}>
        <Divider />
        <Button autoFocus className={classes.drawerButton} onClick={closeDrawerPeers("right", false)} variant="contained" disabled={backdrop}>
          {"CLOSE"}
        </Button>
      </div>
    </div>

  );

  const handleRemoveOrgFromNetwork = () => {
    setBackdrop(true);
    handleCloseDialog();
    NetworkService.removeOrgFromNetwork(props.location.state.networkId, selectedOrgToRemove.id).then(
      response => {
        setBackdrop(false);
        let message = response.message;
        notify({
          type: "success",
          message: message
        });
        setInitialState();
        fetchOrganizationData();
      },
      error => {
        setBackdrop(false);
        setInitialState();
        notify({
          type: "error",
          message: `Failed to remove ${selectedOrgToRemove.organizationName} from network`,
          autoClose: false
        });
      }
    );
  };

  const handleOpenRemoveOrgDialog = (popUpState, orgInfo) => event => {
    popUpState.close();
    setBackdrop(true);

    channelService.getChannelsByNetworkId(props.location.state.networkId, orgInfo.id).then(
      response => {
        let _data = response.data;
        let _dialogMsg = '';
        setSelectedOrgToRemove(orgInfo);
        if (_data.length) {
          let _channelNames = _data.map(ch => ch.channelName);
          _dialogMsg = `Cannot remove ${orgInfo.organizationName} from network. ${orgInfo.organizationName} is a member of ${_channelNames}`
        } else {
          _dialogMsg = `Do you really want to remove ${orgInfo.organizationName} from network?`;
        }
        setDialogTextContent(_dialogMsg);
        setBackdrop(false);
        setOpenDialog(true);
      },
      error => {
        setBackdrop(false);
        notify({
          type: "error",
          message: `Error while fetching channel list for ${orgInfo.organizationName}`
        });
      }
    );


  };

  const handleCloseDialog = () => {
    setDialogTextContent("");
    setOpenDialog(false);
  };


  return (
    <div className={classes.borderMain}>
      {
        props.location.state ? (
          <Paper style={{ minHeight: "87vh" }}>
            <div className={classes.navigation}>
              <Breadcrumbs aria-label="breadcrumb" className={classes.networkBreadCrumb}>
                <Link style={{ textDecoration: 'none' }} to={{ pathname: "/networks" }}>
                  <a color="primary" className={classes.a}>
                    Networks
                      </a>
                </Link>
                <div className={classes.textBreadCrumbDiv}>
                  {
                    ((localStorage.getItem('_userRole') === "SUPERADMIN") || (localStorage.getItem('_userRole') == "ADMIN" && localStorage.getItem('_orgRole') == "operator")) ?
                      <input
                        id="standard-basic"
                        className={textBreadCrumbs}
                        error={invalidUserInput.includes("networkName")}
                        margin="normal"
                        rows="2"
                        autoComplete='off'
                        value={networkName}
                        onMouseEnter={handleMouseEnterNetworkName}
                        onMouseLeave={handleMouseLeaveNetworkName}
                        onChange={handleChangeNetworkName("networkName")} />
                      :
                      <div>{networkName}</div>
                  }
                </div>
              </Breadcrumbs>
              <div className={(breadCrumbNetworkNameFocus && (networkName != networkNameCopy)) ? classes.textBreadCrumbButtonsDiv : classes.textBreadCrumbButtonsDivNone}>
                <IconButton className={classes.breadCrumbButton} onClick={handleNetworkNameSave} disabled={invalidUserInput.includes("networkName")}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton className={classes.breadCrumbButton} onClick={handleBreadCrumbUndo}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>

            <div className={classes.networkInfo}>
              {
                ((localStorage.getItem('_userRole') === "SUPERADMIN") || (localStorage.getItem('_userRole') == "ADMIN" && localStorage.getItem('_orgRole') == "operator")) ?
                  <input
                    id="standard-basic"
                    className={textNetworkDescription}
                    error={invalidUserInput.includes("networkDescription")}
                    margin="normal"
                    rows="2"
                    autoComplete='off'
                    value={networkDescription}
                    onMouseEnter={handleMouseEnterNetworkDescription}
                    onMouseLeave={handleMouseLeaveNetworkDescription}
                    onChange={handleChangeNetworkDescription("networkDescription")} />
                  :
                  <div>{networkDescription}</div>
              }

              <div className={(networkDescriptionFocus && (networkDescription != networkDescriptionCopy)) ? classes.descriptionButtonsDiv : classes.descriptionButtonsDivNone}>
                <IconButton className={classes.descriptionButton} onClick={handleNetworkDescriptionSave} disabled={invalidUserInput.includes("networkDescription")}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton className={classes.descriptionButton} onClick={handleNetworkDescriptionUndo}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
            {
              tab === 0 ?
                <div className={classes.tableHeadRight}>
                  <SearchOrg searchKey={searchKey} fetchOrganizationData={fetchOrganizationData} orgRowsPerPage={orgRowsPerPage} orgPage={orgPage} orgOrderBy={orgOrderBy} orgOrder={orgOrder} handleSetInitialOrgRow={handleSetInitialOrgRow} />
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={openInviteOrgDrawer("right", true)}
                    startIcon={<AddCircleIcon />}
                    disabled={(localStorage.getItem("_orgRole") !== "operator" && localStorage.getItem("_userRole") !== "SUPERADMIN") || (localStorage.getItem('_userRole') === "USER")}
                  >
                    {"Invite organizations to network"}
                  </Button>
                </div>
                :
                tab === 1 ?
                  <div className={channelClasses.tableHeadRight}>
                    <SearchChannel channelSearchKey={channelSearchKey} fetchChannelsData={fetchChannelsData} handleSetInitialChannelRow={handleSetInitialChannelRow} channelRowsPerPage={channelRowsPerPage} channelPage={channelPage} channelOrderBy={channelOrderBy} channelOrder={channelOrder} />
                    <Button
                      color="primary"
                      className={channelClasses.button}
                      onClick={openChannelDrawer("right", true)}
                      disabled={!(localStorage.getItem("_userRole") === "ADMIN" || localStorage.getItem("_userRole") === "SUPERADMIN")}
                      startIcon={<AddCircleIcon />}
                    >
                      {"Create a private communication channel"}
                    </Button>
                  </div>
                  : ""
            }
            <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary" className={classes.tabsStyling}>
              <Tab label="Participating Organizations" {...a11yProps(0)} className={classes.tabStyling} />
              <Tab label="Private Communication Channels" {...a11yProps(1)} className={classes.tabStyling} />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Grid item xs={12}>
                <div className={classes.tableWrapper}>
                  <Paper className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>

                      <TableHead>
                        <TableRow>
                          {
                            _columnsWithRoles.map(column => {
                              if (column.id === "moreOption" && localStorage.getItem("_userRole") === "SUPERADMIN") {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={"center"}
                                    className={classes.tableHeadRow}
                                  >
                                    {(column.label)}
                                  </TableCell>
                                )
                              } else {
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={"center"}
                                    className={classes.tableHeadRow}
                                    sortDirection={orgOrderBy === column.id ? orgOrder : false}
                                  >
                                    {column.sortable ? (
                                      <TableSortLabel
                                        active={orgOrderBy === column.id}
                                        direction={orgOrder}
                                        onClick={handleRequestSort(column.id)}
                                        className={column.id === "organizationRole" ? classes.tableHeadAlignLeft : ""}
                                      >
                                        {column.label}
                                        {orgOrderBy === column.id ? (
                                          <span className={classes.visuallyHidden}>
                                            {orgOrder === "desc"
                                              ? "sorted descending"
                                              : "sorted ascending"}
                                          </span>
                                        ) : null}
                                      </TableSortLabel>
                                    ) : (
                                        column.label
                                      )}
                                  </TableCell>
                                )
                              }
                            })
                          }
                        </TableRow>
                      </TableHead>
                      {orgRows.length === 0 ?
                        <TableCell colSpan="6" align="center" className={classes.noRecordsRow}>
                          {backdrop ? " Fetching the organizations, please wait" : (!searchKey.value ? "No organizations are present in the network, add an organization" : "No Organizations found")}
                        </TableCell>
                        :
                        <TableBody>
                          {orgRows.map((row, index) => {
                            var path = "/organizations/" + row.id;
                            return (
                              <TableRow hover style={{ cursor: "pointer", height: "60px" }} role="data-row" tabIndex={-1} key={index}>
                                {_columnsWithRoles.map(column => {
                                  if (column.id === "moreOption" && localStorage.getItem("_userRole") === "SUPERADMIN") {
                                    return (
                                      <TableCell key={column.id} align={"center"}>
                                        <div className={classes.moreOrgOptions}>
                                          <PopupState variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                              <React.Fragment>
                                                <IconButton
                                                  variant="contained"
                                                  color="primary"
                                                  {...bindTrigger(popupState)}
                                                >
                                                  <MoreVertIcon color="primary" />
                                                </IconButton>
                                                <Menu {...bindMenu(popupState)}>
                                                  <MenuItem onClick={handleOpenRemoveOrgDialog(popupState, row)}>Remove from network</MenuItem>
                                                </Menu>
                                              </React.Fragment>
                                            )}
                                          </PopupState>
                                        </div>
                                      </TableCell>
                                    )
                                  } else {
                                    const value = row[column.id];
                                    return (
                                      <TableCell key={column.id} align={"center"} onClick={event => handleClickonRow(event, row, "Networks", path, props.location.state.networkId)}>
                                        {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                      </TableCell>
                                    );
                                  }
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      }
                      <Drawer
                        anchor="right"
                        open={drawerStateInviteOrg.right}
                        onOpen={closeDrawerInviteOrg('right', true)}
                        onClose={closeDrawerInviteOrg('right', false)}
                      >
                        <InviteOrgsToNetworkSideDrawer handleSetDrawerState={handleSetDrawerState} networkId={props.location.state.networkId} networkName={props.location.state.networkName}
                          orgData={orgData} fetchOrganizationData={fetchOrganizationData} orgRowsPerPage={orgRowsPerPage} orgPage={orgPage} orgOrderBy={orgOrderBy} orgOrder={orgOrder} />
                      </Drawer>
                    </Table>
                  </Paper>
                </div>
                {orgRows.length === 0 ?
                  "" :
                  <TablePagination
                    rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                    component="div"
                    count={queryCount}
                    rowsPerPage={orgRowsPerPage}
                    page={orgPage}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={handleChangeOrgPage}
                    onChangeRowsPerPage={handleChangeOrgRowsPerPage}
                    labelRowsPerPage={"Rows per page"}
                  />
                }
              </Grid>
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Grid item xs={12}>
                <div className={channelClasses.tableWrapper}>
                  <Paper className={channelClasses.tableContainer}>
                    <Table stickyHeader aria-label="sticky table" className={channelClasses.tableBorder}>
                      <TableHead>
                        <TableRow>
                          {channelColumns.map(column => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className={channelClasses.tableHeadRow}
                              sortDirection={channelOrderBy === column.id ? channelOrder : false}
                            >
                              {column.sortable ? (
                                <TableSortLabel
                                  active={channelOrderBy === column.id}
                                  direction={channelOrder}
                                  className={classes.tableColumnLabel}
                                  onClick={handleChannelRequestSort(column.id)}
                                >
                                  {column.label}
                                  {channelOrderBy === column.id ? (
                                    <span className={channelClasses.visuallyHidden}>
                                      {channelOrder === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                    </span>
                                  ) : null}
                                </TableSortLabel>
                              ) : (
                                  column.label
                                )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {channelRows.length === 0 ?
                        <TableCell colSpan="3" align="center" className={channelClasses.noRecordsRow}>
                          {backdrop ? " Fetching the channels, please wait" : (!channelSearchKey.value ? "No channels found" : "No Channels are present, please create a channel")}
                        </TableCell>
                        :
                        <TableBody>
                          {channelRows.map((row, index) => {
                            var currentChannel = getCurrentChannel(row);
                            return (
                              <TableRow hover style={{ cursor: "pointer", height: "60px" }} role="data-row" tabIndex={-1} key={index} onClick={event => handleClickChannelonRow(event, row, currentChannel)} >
                                {channelColumns.map(column => {
                                  const value = row[column.id];
                                  return (
                                    <TableCell key={column.id} align={column.align}>
                                      {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      }
                    </Table>
                    <Drawer
                      anchor="right"
                      open={channelDrawerState.right}
                      onOpen={closeChannelDrawer('right', true)}
                      onClose={closeChannelDrawer('right', true)}
                    >
                      <CreateChannelSideDrawer closeChannelDrawer={closeChannelDrawer} fetchChannelsData={fetchChannelsData} networkName={props.location.state.networkName} networkId={props.location.state.networkId} />
                    </Drawer>
                  </Paper>
                </div>
                {channelRows.length === 0 ?
                  ""
                  :
                  <TablePagination
                    rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                    component="div"
                    count={channelQueryCount}
                    rowsPerPage={channelRowsPerPage}
                    page={channelPage}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={handleChangeChannelPage}
                    onChangeRowsPerPage={handleChangeChannelRowsPerPage}
                    labelRowsPerPage={"Rows per page"}
                  />
                }
              </Grid>
            </TabPanel>

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Paper>
        ) : (
            <Paper className={classes.root} style={{ minHeight: "87vh" }}>
              <Grid item xs={12}>
                <div className={classes.tableTitle} style={{ display: 'flex' }}>
                  <h2>{"Organizations"}</h2>
                </div>
                <div className={classes.tableHeadRight}>
                  {addingOrg ? <div className={classes.uploadDiv} style={{ alignContent: 'center', justifyContent: 'center' }}  >Creating organization &nbsp;&nbsp;  <CircularProgress size={18} /></div> : ""}
                  {assigningCommercialRole ? <div className={classes.uploadDiv} style={{ alignContent: 'center', justifyContent: 'center' }}  >Assigning Commercial Role &nbsp;&nbsp;  <CircularProgress size={18} /></div> : ""}
                  <div className={classes.filterDiv}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-checkbox-label">Networks</InputLabel>
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={selectedNetworks}
                        onChange={handleChangeMulitpleSelect}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                      >
                        {networksArray.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedNetworks.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {
                      selectedNetworks.length > 0 ?
                        <div className={classes.clearFilterButtonDiv}>
                          <Button
                            color="primary"
                            className={classes.clearFilterButton}
                            onClick={buttonClickClearAllFilters}
                          >
                            {"Clear All"}
                          </Button>
                        </div>
                        :
                        ""
                    }
                  </div>
                  <SearchOrg searchKey={searchKey} fetchOrganizationData={fetchOrganizationData} orgRowsPerPage={orgRowsPerPage} orgPage={orgPage} orgOrderBy={orgOrderBy} orgOrder={orgOrder} handleSetInitialOrgRow={handleSetInitialOrgRow} />&nbsp;
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={openDrawerAssignCommercialRole("right", true)}
                    startIcon={<AddCircleIcon />}
                    disabled={localStorage.getItem("_userRole") === "SUPERADMIN" ? false : (localStorage.getItem("_orgRole") === "operator" && localStorage.getItem("_userRole") === "ADMIN") ? false : true}
                  >
                    {"Assign commercial role"}
                  </Button>&nbsp;
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={openDrawer("right", true)}
                    startIcon={<AddCircleIcon />}
                    disabled={localStorage.getItem("_userRole") === "SUPERADMIN" ? false : (localStorage.getItem("_orgRole") === "operator" && localStorage.getItem("_userRole") === "ADMIN") ? false : true}
                  >
                    {"Create Org"}
                  </Button>
                  {
                    localStorage.getItem("_userRole") === "SUPERADMIN" ?
                      <Button
                        color="primary"
                        className={classes.button}
                        onClick={openDrawerClientToken("right", true)}
                      >
                        {"Generate client Credentials"}
                      </Button> : ""
                  }
                </div>
                <div className={classes.tableWrapper}>
                  <Paper className={classes.tableContainer}>
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      className={classes.root}
                    >
                      {
                        backdrop && orgRows.length === 0 ?
                          (
                            <Typography style={{ textAlign: "center" }}>Fetching Organizations: Please wait</Typography>
                          )
                          :
                          <div>
                            <div className={classes.treeViewDiv}>
                              <ListItem className={classes.treeViewCollapsableTabsa}>
                                <ListItemText
                                  className={classes.listItemText}
                                  primary={
                                    <div className={classes.listItemTextPrimaryDiv}>
                                      <div className={classes.listItemTextPrimaryDivTypo}>
                                        <TableCell align="center" className={classes.treeViewTableCellNameValue}>
                                          <div className={classes.treeViewTableCellInfoDiv}>
                                            <Typography className={classes.treeViewTableCellInfoValue}>
                                              <div className={classes.textBreadCrumbsOrganizationNameDefault}>
                                                {`Organization Name`}
                                              </div>
                                            </Typography>
                                          </div>

                                        </TableCell>
                                        <TableCell align="center" className={classes.treeViewTableCellDomainValue}>
                                          <div className={classes.treeViewTableCellInfoDiv}>
                                            <Typography className={classes.treeViewTableCellInfoValue}>
                                              <div className={classes.textBreadCrumbsOrganizationDomainNameDefault}>
                                                {`Organization Domain`}
                                              </div>
                                            </Typography>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.treeViewTableCellDescriptionValue}>
                                          <div className={classes.treeViewTableCellInfoDiv}>
                                            <Typography className={classes.treeViewTableCellInfoValue}>
                                              <div className={classes.textBreadCrumbsOrganizationDescriptionDefault}>
                                                {`Organization Description`}
                                              </div>
                                            </Typography>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.treeViewTableCellPeerCountValue}>
                                          <div className={classes.treeViewTableCellInfoDiv}>
                                            <Typography className={classes.treeViewTableCellInfoValue}>

                                              {`Number of Peers`}

                                            </Typography>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.treeViewTableCellUserCountValue}>
                                          <div className={classes.treeViewTableCellInfoDiv}>
                                            <Typography className={classes.treeViewTableCellInfoValue}>
                                              {`Number of Users`}
                                            </Typography>
                                          </div>
                                        </TableCell>
                                        <TableCell align="center" className={classes.treeViewTableCellNetworkValue}>
                                          <div className={classes.treeViewTableCellInfoDiv}>
                                            <Typography className={classes.treeViewTableCellInfoValue}>
                                              {`Participated Networks`}

                                            </Typography>
                                          </div>
                                        </TableCell>

                                      </div>
                                    </div>
                                  }
                                />
                                <div className={classes.expandIcon}>
                                  {/* <ExpandLess /> */}
                                </div>
                              </ListItem>
                            </div>
                            {orgRows.length !== 0 ? (
                              orgRows.map((row, index) => {
                                return (
                                  <div className={classes.treeViewDiv}>
                                    <ListItem className={classes.treeViewCollapsableTabs} button onClick={handleClickRow(row)}>
                                      <ListItemText
                                        className={classes.listItemText}
                                        primary={
                                          <div className={classes.listItemTextPrimaryDiv}>
                                            {/* <div className={classes.listItemTextPrimaryDivInfoIconDiv}>

                                            </div> */}
                                            <div className={classes.listItemTextPrimaryDivTypo}>
                                              <TableCell align="center" className={classes.treeViewTableCellNameValue}>
                                                <div className={classes.treeViewTableCellInfoDiv}>
                                                  <Typography className={classes.treeViewTableCellInfoValue}>
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                      <div>
                                                        <input
                                                          id="standard-basic"
                                                          className={editOrganizationRowId === row["id"] ? textBreadCrumbsOrganizationName : classes.textBreadCrumbsOrganizationNameDefault}
                                                          margin="normal"
                                                          rows="2"
                                                          autoFocus={editOrganizationRowId === row["id"] && focusedOrganizationColumn === "organizationName"}
                                                          disabled={!((localStorage.getItem("_userRole") === "ADMIN" && localStorage.getItem("_orgRole") === "operator") || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                          autoComplete='off'
                                                          value={editOrganizationRowId === row["id"] ? editOrganizationName : row["organizationName"]}
                                                          onMouseEnter={handleMouseEnterOrganizationRow}
                                                          onMouseLeave={handleMouseLeaveOrganizationRow}
                                                          onChange={handleChangeOrganizationRow(row, "organizationName")}
                                                          onClick={handleMouseEnterOrganizationRowOnClick} />
                                                      </div>
                                                    </Breadcrumbs>
                                                  </Typography>
                                                </div>
                                              </TableCell>
                                              <TableCell align="center" className={classes.treeViewTableCellDomainValue}>
                                                <div className={classes.treeViewTableCellInfoDiv}>
                                                  <Typography className={classes.treeViewTableCellInfoValue}>
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                      <div>
                                                        <input
                                                          id="standard-basic"
                                                          className={editOrganizationRowId === row["id"] ? textBreadCrumbsOrganizationDomainName : classes.textBreadCrumbsOrganizationDomainNameDefault}
                                                          margin="normal"
                                                          rows="2"
                                                          autoFocus={editOrganizationRowId === row["id"] && focusedOrganizationColumn === "organizationDomainName"}
                                                          disabled={!((localStorage.getItem("_userRole") === "ADMIN" && localStorage.getItem("_orgRole") === "operator") || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                          autoComplete='off'
                                                          value={editOrganizationRowId === row["id"] ? editOrganizationDomainName : (row["organizationDomainName"])}
                                                          onMouseEnter={handleMouseEnterOrganizationRow}
                                                          onMouseLeave={handleMouseLeaveOrganizationRow}
                                                          onChange={handleChangeOrganizationRow(row, "organizationDomainName")}
                                                          onClick={handleMouseEnterOrganizationRowOnClick} />
                                                      </div>
                                                    </Breadcrumbs>
                                                  </Typography>
                                                </div>
                                              </TableCell>
                                              <TableCell align="center" className={classes.treeViewTableCellDescriptionValue}>
                                                <div className={classes.treeViewTableCellInfoDiv}>
                                                  <Typography className={classes.treeViewTableCellInfoValue}>
                                                    <Breadcrumbs aria-label="breadcrumb">
                                                      <div>
                                                        <input
                                                          id="standard-basic"
                                                          className={editOrganizationRowId === row["id"] ? textBreadCrumbsOrganizationDescription : classes.textBreadCrumbsOrganizationDescriptionDefault}
                                                          error={invalidUserInput.includes("organizationDescription")}
                                                          margin="normal"
                                                          autoFocus={editOrganizationRowId === row["id"] && focusedOrganizationColumn === "organizationDescription"}
                                                          rows="2"
                                                          disabled={!((localStorage.getItem("_userRole") === "ADMIN" && localStorage.getItem("_orgRole") === "operator") || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                          autoComplete='off'
                                                          value={editOrganizationRowId === row["id"] ? editOrganizationDescription : (row["organizationDescription"])}
                                                          onMouseEnter={handleMouseEnterOrganizationRow}
                                                          onMouseLeave={handleMouseLeaveOrganizationRow}
                                                          onChange={handleChangeOrganizationRow(row, "organizationDescription")}
                                                          onClick={handleMouseEnterOrganizationRowOnClick} />
                                                      </div>
                                                    </Breadcrumbs>
                                                  </Typography>
                                                </div>
                                              </TableCell>
                                              <TableCell align="center" className={classes.treeViewTableCellPeerCountValue}>
                                                <div className={classes.treeViewTableCellInfoDiv}>
                                                  <Typography className={classes.treeViewTableCellInfoValue}>
                                                    <div>
                                                      {row["peerCount"]}
                                                    </div>
                                                  </Typography>
                                                </div>
                                              </TableCell>
                                              <TableCell align="center" className={classes.treeViewTableCellUserCountValue}>
                                                <div className={classes.treeViewTableCellInfoDiv}>
                                                  <Typography className={classes.treeViewTableCellInfoValue}>
                                                    <div>
                                                      {row["usersCount"]}
                                                    </div>
                                                  </Typography>
                                                </div>
                                              </TableCell>
                                              <TableCell align="center" className={classes.treeViewTableCellNetworkValue}>
                                                <div className={classes.treeViewTableCellInfoDiv}>
                                                  <Typography className={classes.treeViewTableCellInfoValue}>
                                                    {
                                                      editOrganizationRowId === row["id"] && isOrganizationDetailsChanged() ?
                                                        <div style={{ display: "flex" }}>
                                                          <Tooltip title="Click to see the list of all participated networks">
                                                            <Button className={classes.buttonText}
                                                              onClick={openDrawerShowNetworks("right", true, row["networks"])}>
                                                              {createParticipatedNetworks(row["networks"])}
                                                            </Button>
                                                          </Tooltip>
                                                          <Button className={classes.buttonDownloadSmall} onClick={handleSubmitOrganizationDetails}>
                                                            <DoneIcon fontSize="small" />
                                                          </Button>
                                                          <Button className={classes.buttonDownloadSmall} onClick={handleClearOrganizationDetails}>
                                                            <CloseIcon fontSize="small" />
                                                          </Button>
                                                        </div>
                                                        :
                                                        <Tooltip title="Click to see the list of all participated networks">
                                                          <Button className={classes.buttonText}
                                                            onClick={openDrawerShowNetworks("right", true, row["networks"])}>
                                                            {createParticipatedNetworks(row["networks"])}
                                                          </Button>
                                                        </Tooltip>
                                                    }
                                                  </Typography>
                                                </div>
                                              </TableCell>
                                            </div>
                                          </div>
                                        }
                                      />
                                      {row["open"] ?
                                        <div className={classes.expandIcon}>
                                          <ExpandLess />
                                        </div>
                                        :
                                        <div className={classes.expandIcon}>
                                          <ExpandMore />
                                        </div>
                                      }
                                    </ListItem>

                                    <Collapse in={row["open"]} style={{ marginLeft: "3%", marginRight: "3%" }}>
                                      <Box margin={1}>
                                        <Table size="small" aria-label="purchases">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell style={{ fontWeight: 700 }}>Peer Name</TableCell>
                                              <TableCell style={{ fontWeight: 700 }}>Peer Domain</TableCell>
                                              <TableCell style={{ fontWeight: 700 }}>Peer Port</TableCell>
                                              <TableCell style={{ fontWeight: 700 }}>Installed Chaincodes</TableCell>
                                              <TableCell style={{ fontWeight: 700 }}>Action</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {row.peers.map((peerTemp) => (
                                              <TableRow key={peerTemp.id}>
                                                {/* <TableCell component="th" scope="row">
                                                {peerTemp.peerName}
                                              </TableCell>
                                              <TableCell>{peerTemp.peerDomainName}</TableCell> */}
                                                <TableCell component="th" scope="row">
                                                  <Breadcrumbs aria-label="breadcrumb">
                                                    <div>
                                                      <input
                                                        id="standard-basic"
                                                        className={editPeerRowId === peerTemp["id"] ? textBreadCrumbsPeerName : classes.textBreadCrumbsPeerNameDefault}
                                                        margin="normal"
                                                        rows="2"
                                                        autoFocus={editPeerRowId === peerTemp["id"] && focusedPeerColumn === "peerName"}
                                                        disabled={!((localStorage.getItem("_userRole") === "ADMIN" && localStorage.getItem("_orgRole") === "operator") || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                        autoComplete='off'
                                                        value={editPeerRowId === peerTemp["id"] ? editPeerName : (peerTemp.peerName)}
                                                        onMouseEnter={handleMouseEnterPeerRow}
                                                        onMouseLeave={handleMouseLeavePeerRow}
                                                        onChange={handleChangePeerRow(peerTemp, "peerName")}
                                                        onClick={handleMouseEnterOrganizationRowOnClick} />
                                                    </div>
                                                  </Breadcrumbs>
                                                </TableCell>
                                                <TableCell>
                                                  <Breadcrumbs aria-label="breadcrumb">
                                                    <div>
                                                      <input
                                                        id="standard-basic"
                                                        className={editPeerRowId === peerTemp["id"] ? textBreadCrumbsPeerDomainName : classes.textBreadCrumbsPeerDomainNameDefault}
                                                        margin="normal"
                                                        rows="2"
                                                        autoFocus={editPeerRowId === peerTemp["id"] && focusedPeerColumn === "peerDomainName"}
                                                        disabled={!((localStorage.getItem("_userRole") === "ADMIN" && localStorage.getItem("_orgRole") === "operator") || localStorage.getItem("_userRole") === "SUPERADMIN")}
                                                        autoComplete='off'
                                                        value={editPeerRowId === peerTemp["id"] ? editPeerDomainName : (peerTemp.peerDomainName)}
                                                        onMouseEnter={handleMouseEnterPeerRow}
                                                        onMouseLeave={handleMouseLeavePeerRow}
                                                        onChange={handleChangePeerRow(peerTemp, "peerDomainName")}
                                                        onClick={handleMouseEnterOrganizationRowOnClick} />
                                                    </div>
                                                  </Breadcrumbs>
                                                </TableCell>
                                                <TableCell>
                                                  {peerTemp.peerPort}
                                                </TableCell>
                                                <TableCell>
                                                  {
                                                    row["isOrderer"] ?
                                                      <Tooltip title="Click to see the list of all installed chaincodes">
                                                        <Button className={classes.buttonText}
                                                          disabled>
                                                          N/A
                                                        </Button>
                                                      </Tooltip>
                                                      :
                                                      <Tooltip title="Click to see the list of all installed chaincodes">
                                                        <Button className={classes.buttonText}
                                                          disabled={peerTemp.installedChaincodes ? peerTemp.installedChaincodes.length === 0 : false}
                                                          onClick={openDrawerShowPeers("right", true, peerTemp.installedChaincodes)}>
                                                          {createInstalledChainCodes(peerTemp.installedChaincodes)}
                                                        </Button>
                                                      </Tooltip>
                                                  }
                                                </TableCell>
                                                <TableCell>
                                                  {
                                                    editPeerRowId === peerTemp["id"] && isPeerDetailsChanged() ?
                                                      <div>
                                                        <Button className={classes.buttonDownload} disabled={row["isOrderer"]} variant="outlined" onClick={openDrawerChainCode("right", true, peerTemp, index)}>
                                                          Install ChainCode
                                                        </Button>
                                                        <Button className={classes.buttonDownloadSmall} onClick={handleSubmitPeerDetails}>
                                                          <DoneIcon fontSize="small" />
                                                        </Button>
                                                        <Button className={classes.buttonDownloadSmall} onClick={handleClearPeerDetails}>
                                                          <CloseIcon fontSize="small" />
                                                        </Button>
                                                      </div>
                                                      :
                                                      <div>
                                                        <Button className={classes.buttonDownload} disabled={row["isOrderer"] || !row["adminEnrolled"]} variant="outlined" onClick={openDrawerChainCode("right", true, peerTemp, index)}>
                                                          Install ChainCode
                                                      </Button>
                                                      </div>
                                                  }
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </div>
                                );
                              })
                            ) : (
                                <Typography style={{ textAlign: "center" }}>No Organizations in the network</Typography>
                              )
                            }
                          </div>
                      }
                    </List>
                  </Paper>
                </div>
                {orgRows.length === 0 ?
                  "" :
                  <TablePagination
                    rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                    component="div"
                    count={queryCount}
                    rowsPerPage={orgRowsPerPage}
                    page={orgPage}
                    backIconButtonProps={{
                      "aria-label": "previous page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "next page"
                    }}
                    onChangePage={handleChangeOrgPage}
                    onChangeRowsPerPage={handleChangeOrgRowsPerPage}
                    labelRowsPerPage={"Rows per page"}
                  />
                }
              </Grid>
              <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </Paper>
          )
      }
      <EventsMessagesModal open={showModal} addOrgState={addOrgState} enrollAdminState={enrollAdminState} handleCloseModal={handleCloseModal}></EventsMessagesModal>
      <Drawer
        anchor="right"
        open={drawerStateClientToken.right}
        onOpen={closeDrawerClientToken('right', true)}
        onClose={closeDrawerClientToken('right', false)}
      >
        {sideListGenerateClientToken('right')}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerStateChainCode.right}
        onOpen={closeDrawerChainCode('right', true)}
        onClose={closeDrawerChainCode('right', false)}
      >
        {sideListChainCode('right')}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerStateNetworks.right}
        onOpen={closeDrawerNetworks('right', true)}
        onClose={closeDrawerNetworks('right', false)}
      >
        {sideListNetworks('right')}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerStatePeers.right}
        onOpen={closeDrawerPeers('right', true)}
        onClose={closeDrawerPeers('right', false)}
      >
        {sideListPeers('right')}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerState.right}
        onOpen={closeDrawer('right', true)}
        onClose={closeDrawer('right', true)}
      >
        {editOrganization ? sideListEditOrganization('right') : sideListAddNewOrg('right')}
      </Drawer>
      <Drawer
        anchor="right"
        open={drawerStateAssignCommercialRole.right}
        onOpen={closeDrawerAssignCommercialRole('right', true)}
        onClose={closeDrawerAssignCommercialRole('right', true)}
      >
        {sideListAssignCommercialRole('right')}
      </Drawer>
      <Dialog
        open={popupOpen}
        onClose={handleClosePopupDoNotDiscard}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle} gutterBottom>
            {`Discard details`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
            You are about to discard other modified organization details
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopupDiscard} className={classes.dialogButton} color="primary" >
            PROCEED
          </Button>
          <Button onClick={handleClosePopupDoNotDiscard} className={classes.dialogButton} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={popupOpenPeer}
        onClose={handleClosePopupDoNotDiscardPeer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle} gutterBottom>
            {`Discard details`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogContentText}>
            You are about to discard other modified Peer details
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopupDiscardPeer} className={classes.dialogButton} color="primary">
            PROCEED
          </Button>
          <Button onClick={handleClosePopupDoNotDiscardPeer} className={classes.dialogButton} color="primary">
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography className={classes.dialogTitle} gutterBottom>
            {`Removing ${selectedOrgToRemove.organizationName} from network`}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.dialogContentText}>
            {`${dialogTextContent}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
            dialogTextContent.includes('Cannot remove') ? "" :
              <Button className={classes.dialogBotton} onClick={handleRemoveOrgFromNetwork} color="primary">
                CONFIRM
            </Button>
          }
          <Button className={classes.dialogBotton} onClick={handleCloseDialog} color="primary" autoFocus>
            {dialogTextContent.includes('Cannot remove') ? "CLOSE" : "CANCEL"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
