import axios from "axios";
import authUtil from '../utils/authUtil';
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;


// Add token in all requests
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + authUtil.getToken();
axios.defaults.withCredentials = true;
function createChannel(requestObj, networkId, organizationId) {
  if (authUtil.isAuthenticated()) {
    return axios.post(BASE_API_URL + `/networks/${networkId}/organizations/${organizationId}/channels/`, requestObj, {
      headers: {
        "Content-Type": "application/json",
      }
    });
  }
}

function inviteOrgsToChannel(requestObj, channelId) {
  return axios.post(BASE_API_URL + `/channels/${channelId}/invite`, requestObj, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getChannelsByOrgId(orgId, query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/channels/organizations/` + orgId + queryParams, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}


function getChannelByChannelId(channelId, peers = true, organizations = true) {

  return axios.get(BASE_API_URL + `/channels/` + channelId + '?peers=' + peers + '&organizations=' + organizations, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function getChannelInfo(channelId, peer) {
  return axios.get(BASE_API_URL + `/channels/` + channelId + `/info`, {
    params: {
      peer: peer
    },
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function getOrgNameMspIdByChannelId(channelId) {
  return axios.get(BASE_API_URL + `/channels/` + channelId + `/endorsingOrgs`, {
    headers: {
      "Content-Type": "application/json"
    }
  })
}

function getEndorsementPolicy(channelId, chaincodeId, version) {
  return axios.get(BASE_API_URL + `/channels/` + channelId + `/chaincodes/` + chaincodeId + `/endorsementPolicy`, {
    params: {
      version: version
    },
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function updateChannel(channelId, requestObj) {
  return axios.post(BASE_API_URL + `/channels/` + channelId, requestObj, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getChannelsByNetworkId(networkId, organizationId, query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/channels/network/` + networkId + `/organization/` + organizationId + queryParams, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function getChannelsByInstantiatedChaincodeName(chaincodeName) {
  return axios.get(BASE_API_URL + `/channels/instantiatedChaincode/` + chaincodeName, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function getChannelsByCommittedChaincodeName(chaincodeName) {
  return axios.get(BASE_API_URL + `/channels/committedChaincode/` + chaincodeName, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function removeOrgFromChannel(channelId, organizationId) {
  return axios.post(BASE_API_URL + `/channels/${channelId}/removeOrg/` + organizationId, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getEndorsementPoliciesOfOrgByChannelId(channelId, organizationId) {
  return axios.get(BASE_API_URL + `/channels/${channelId}/organization/${organizationId}/endorsementPolicies`, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function getOrganizationsInChannelsByNetworkId(networkId) {
  return axios.get(BASE_API_URL + `/channels/organization/network/` + networkId, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function getOrganizationsInChannelByInstalledChaincodeId(channelId, chaincodeVersionId) {
  return axios.get(BASE_API_URL + `/channels/${channelId}/chaincode/${chaincodeVersionId}/orgs`, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}

function getChannelsInNetworkByOrganizationId(networkId, organizationId) {
  return axios.get(BASE_API_URL + `/channels/organization/${organizationId}/network/${networkId}`, {
    headers: {
      //"Authorization": 'Bearer ' + authUtil.getToken()
    }
  });
}
function getBlockByNum(channelId, blockNumber, filteredResponse) {
  let query = {};
  query['filteredResponse'] = filteredResponse;
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/channels/${channelId}/blocks/${blockNumber}` + queryParams, {
    headers: {
      // "Content-Type": "application/json"
    }
  });
}

function getBlocks(channelId, blockNumber, numberofBlocks) {
  let query = {};
  query.blockNum = blockNumber;
  query.numBlocks = numberofBlocks;
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/channels/${channelId}/blocks` + queryParams, {
    headers: {
      // "Content-Type": "application/json"
    }
  });
}



export default {
  createChannel,
  inviteOrgsToChannel,
  getChannelsByOrgId,
  getChannelInfo,
  getChannelByChannelId,
  getOrgNameMspIdByChannelId,
  getEndorsementPolicy,
  updateChannel,
  getChannelsByNetworkId,
  getChannelsByInstantiatedChaincodeName,
  getChannelsByCommittedChaincodeName,
  removeOrgFromChannel,
  getEndorsementPoliciesOfOrgByChannelId,
  getOrganizationsInChannelsByNetworkId,
  getOrganizationsInChannelByInstalledChaincodeId,
  getChannelsInNetworkByOrganizationId,
  getBlockByNum,
  getBlocks
};
