import axios from "axios";
import authUtil from '../utils/authUtil';
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;


// Add token in all headers
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + authUtil.getToken();
axios.defaults.withCredentials = true;
function getOrgsByNetwork(networkId, query) {
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/organizations/network/` + networkId + queryParams, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function getOrgs(query) {
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/organizations` + queryParams, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function getOrgsWithoutPeers(query = {}) {
    query['peers'] = 'false';
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/organizations` + queryParams, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function getOrgsByOrgId(orgId) {
    return axios.get(BASE_API_URL + `/organizations/` + orgId, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function getPeersByOrgId(orgId, query) {
    let queryParams = Util.buildQueryParams(query);
    return axios.get(BASE_API_URL + `/admin/organizations/` + orgId + `/peers` + queryParams, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function addOrg(requestObj) {
    return axios.post(BASE_API_URL + `/admin/organizations`, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function updateOrganization(orgId, requestObj) {
    return axios.post(BASE_API_URL + `/admin/organizations/` + orgId, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function updatePeer(orgId, editPeerRowId, requestObj) {
    return axios.post(BASE_API_URL + `/admin/organizations/` + orgId + `/peers/` + editPeerRowId, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function generateClientCredentials(orgId, requestObj) {
    return axios.post(BASE_API_URL + `/admin/organizations/generateClientCredentials/` + orgId, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function getOrgCommercialRolesByOrgCode(orgCode) {
    return axios.get(BASE_API_URL + `/organizations/` + orgCode + `/roles`, {
        // headers: {
        //     "Authorization": 'Bearer ' + authUtil.getToken()
        // }
    });
}

function getAllCommercialRoles() {
    return axios.get(
        BASE_API_URL + `/staticdata?type=commercial_roles`,
        {
            // headers: {
            //     Authorization: 'Bearer ' + authUtil.getToken()
            // }
        }
    )
}

function assignCommercialRolesToOrganizations(requestObj) {
    return axios.post(BASE_API_URL + `/admin/organizations/roles/assign`, requestObj, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export default {
    getOrgsByNetwork,
    getOrgs,
    addOrg,
    getOrgsByOrgId,
    getOrgsWithoutPeers,
    getPeersByOrgId,
    updateOrganization,
    updatePeer,
    generateClientCredentials,
    getOrgCommercialRolesByOrgCode,
    getAllCommercialRoles,
    assignCommercialRolesToOrganizations
};