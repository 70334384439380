import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../../themes/lightTheme';
import {
    Typography, Button, MenuItem, Divider, TextField, ListItemText, List, ListItem, IconButton, Backdrop, CircularProgress
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChaincodeService from '../../services/ChaincodeService';
import notify from '../../utils/notifier';
import ChannelService from '../../services/ChannelService';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Tooltip from '@material-ui/core/Tooltip';



const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1
    },
    drawerHeader: {
        //padding: '5%'
        height: '64px',
        width: '100%',
        display: 'inline-flex'
    },
    title: {
        fontSize: '20px',
        fontFamily: 'Helvetica, sans-serif',
        paddingTop: '16px'
    },
    formBody: {
        marginTop: '8%',
        overflow: 'auto',
        maxHeight: '74vh'
    },
    fields: {
        paddingBottom: '5%',
        paddingLeft: lightTheme.spacing(2)
    },
    formControl: {
        marginTop: '10px',
        width: '100%'
    },
    peers: {
        marginLeft: '5%',
        width: '25%'
    },
    formActions: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        background: 'white'
    },
    cancelBtn: {

        float: "right",
        margin: lightTheme.spacing(2),

    },
    submitBtn: {

        float: "right",
        margin: lightTheme.spacing(2),
    },
    backdrop: {
        zIndex: lightTheme.zIndex.drawer + 1,
    },
    hidden: {
        display: "none",
    },
    flexItem: {
        flex: 1,
        paddingLeft: lightTheme.spacing(2),
        //marginTop: "-30px",

        width: "95%"
    },
    targetPeers: {

    },
    warningStyle: {
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
        marginLeft: "-10px",
    },
    memberListTitle: {
        paddingTop: lightTheme.spacing(1),
        fontSize: "0.875rem",
        fontWeight: "700",
        textAlign: 'left'
    },
    memberListItem: {

    },
    memberListItemIcon: {
        color: "#000000",
        display: 'inline-flex',
        minWidth: lightTheme.spacing(4)
    },
    memberListItemText: {
        fontSize: "0.875rem"
    },
    iconStyle: {
        color: "green"
    },
    warningDiv: {
        display: "flex",
        marginBottom: lightTheme.spacing(1),
        background: "#F5C6CB",
        color: "#721C24",
        textAlign: "center",
        paddingTop: "1px",
        paddingBottom: "1px",
    },
    warningIconDiv: {
        paddingRight: lightTheme.spacing(0.5),
        marginTop: lightTheme.spacing(1),
        marginLeft: lightTheme.spacing(2),
    },
    warningIcon: {
        fontSize: '0.9rem',
    },
    warningText: {
        fontWeight: '600',
        fontSize: '0.99rem',
        marginTop: lightTheme.spacing(.5)
    },

}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function CommitChaincodeForm(props) {

    const classes = useStyles();

    const [newChannels, setNewChannels] = React.useState('');
    const [channels, setChannels] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [endorser, setEndorser] = React.useState([]);
    const [approvedOrgs, setApprovedOrgs] = React.useState([]);

    //Fetch chaincode information
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {

        const orgId = localStorage.getItem('_orgID');
        ChannelService.getChannelsByOrgId(orgId).then(
            response => {
                let _data = response.data;
                _data = _data.map((row) => ({ name: `${row.channelName} (${row.networkName})`, ...row }));
                setChannels(_data);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error occured while fetching the data"
                });
            }
        );

    };

    const handleChange = event => {
        setNewChannels(event.target.value);

        let channelId = event.target.value;

        ChaincodeService.getCurrentChaincodeSequence(props.data.id, channelId).then(
            response => {
                let lastCommittedSequence = response.data;
                let sequence;
                if (lastCommittedSequence[0].max != null) {
                    lastCommittedSequence = parseInt(lastCommittedSequence[0].max);
                    sequence = lastCommittedSequence + 1;
                } else {
                    sequence = null
                }
            
                ChaincodeService.getEndorsementPolicyByChaincodeVersionId(props.data.chaincodeVersionId, channelId, false, sequence).then(
                    response => {
                        let _data = response.data;
                        if (_data.length) {
                            let _endorsers = _data[0].endorsementPolicy
                            _endorsers = _endorsers.split('(')[1];
                            _endorsers = _endorsers.split(')')[0];
                            _endorsers = _endorsers.split(',');
                            _endorsers = _endorsers.map(i => i.toUpperCase().slice(1, i.length - 1));
                            _endorsers = _endorsers.map(i => i.split('.')[0].split('-').join(' '));
                            _endorsers = _endorsers.map(i => i.slice(0, i.length - 3));
                            setEndorser(_endorsers);
                        } else {
                            setEndorser([]);
                        }
                    },
                    error => {
                        notify({
                            type: "error",
                            message: "Error occured while fetching the endorsement policy"
                        });
                    }
                );

                ChaincodeService.getApprovedOrganizationsByChaincodeVersionId(props.data.chaincodeVersionId, channelId, sequence) .then(
                    response => {
                        let _data = response.data;
                        if (_data.length) {
                            let _orgsCodes = _data.map(org => org.organizationCode.toUpperCase());
                            _orgsCodes = _orgsCodes.map(org => org.split('-').join(' '));
                            setApprovedOrgs(_orgsCodes);
                        } else {
                            setApprovedOrgs([]);
                        }
                    },
                    error => {
                        notify({
                            type: "error",
                            message: "Error occured while fetching organizations that approved the chaincode"
                        });
                    }
                );
            },
            error => {
                notify({
                    type: "error",
                    message: "Error occured while fetching the chaincode details"
                });
            }
        )
    };


    const handleSubmit = () => {

        // TODO: once backed is working, DONT construct peer
        const orgName = localStorage.getItem('_orgName');

        const reqObj = {channelId: newChannels};
        setOpen(true);
        ChaincodeService.commitChaincode(props.data.chaincodeVersionId, reqObj).then(
            response => {
                setOpen(false);
                notify({
                    type: 'success',
                    message: 'Chaincode definition committed successfully!'
                });
                props.handleClose();
            },
            error => {
                setOpen(false);
                notify({
                    type: 'error',
                    message: 'Error occured while committing chaincode'
                });
            }
        );
    };

    const checkIfAllOrgApproved = () => {
        for (let i of endorser) {
            if (!approvedOrgs.includes(i)) {
                return false;
            }
        }
        return true;
    }

    return (
        <MuiThemeProvider theme={lightTheme}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={props.handleClose} style={{ background: 'none' }}>
                    <ChevronRightIcon color="primary" />
                </IconButton>
                <Typography className={classes.title}>Commit chaincode</Typography>
            </div>
            <Divider />
            {
                newChannels != '' && !endorser.length ?
                <div className={classes.warningDiv}>
                    <div className={classes.warningIconDiv}>
                        <WarningIcon className={classes.warningIcon} />
                    </div>
                    <span className={classes.warningText}>
                        There are no changes to commit on the selected channel
                    </span>
                </div> : ""
            }
            <div className={classes.formBody}>
                <div className={classes.fields} style={{ display: 'block' }}>
                    <Typography><b>Chaincode Name : </b>{props.data.name}</Typography>
                </div>
                <div className={classes.fields} style={{ display: 'block' }}>
                    <Typography><b>Version : </b>{props.data.version}</Typography>
                </div>
                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        required
                        select
                        className={classes.textField}
                        label={"Channels"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={newChannels}
                        onChange={handleChange}
                    >
                        {channels.map(channel => (
                            <MenuItem key={channel.id} value={channel.id}>
                                <ListItemText primary={channel.name} />
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
            </div>
            {
                endorser.length ? 
                <div className={classes.flexItem}>
                    <Typography variant="h6" className={classes.memberListTitle}>
                        Endorsement Policy
                    </Typography>
                    <Typography variant="h6" className={classes.memberListTitle}>
                        Following organization(s) must approve before committing the chaincode version:
                    </Typography>
                    <List dense={true}>
                        {endorser.map((member) => (
                            <ListItem className={classes.memberListItem}>
                                <ListItemText
                                    primary={member}
                                    className={classes.memberListItemText}
                                />
                                {
                                    (approvedOrgs.includes(member)) ? <CheckCircleIcon fontSize="medium" className={classes.iconStyle} /> : <Tooltip title={<span style={{ fontSize: '12px' }}>Pending approval</span>} placement="right-end"><MoreHorizIcon fontSize="medium" /></Tooltip>
                                }
                            </ListItem>
                        ))}
                    </List>
                </div> : ""
            }
            <div></div>
            <div className={classes.formActions}>
                <Divider />
                <Button
                    variant="contained"
                    className={classes.submitBtn}
                    color="primary"
                    onClick={handleSubmit}
                    disabled={newChannels == '' || !endorser.length || !checkIfAllOrgApproved()}
                >
                    Submit
                </Button>
                <Button
                    variant="contained"
                    className={classes.cancelBtn} onClick={props.handleClose}>

                    Cancel
                </Button>

            </div>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </MuiThemeProvider>

    );
}