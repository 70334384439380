import React from "react";
import { channelUseStyles } from "../channel/styles/ChannelStyles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

export default function SearchChannel(props) {

    const channelClasses = channelUseStyles();
    const [channelSearchKey, setChannelSearchKey] = React.useState(props.channelSearchKey);


    const clearChannelSearchText = () => {
        setChannelSearchKey({ value: "" });
        props.fetchChannelsData(props.channelRowsPerPage, props.channelPage);
    };
    
    let timeout = null;
    const handleChannelSearch = event => {
      const _val = event.target.value;
      setChannelSearchKey({ value : _val });
  
      if (_val.length <= 2) {
        props.handleSetInitialChannelRow();
        return;
      }
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        props.fetchChannelsData(props.channelRowsPerPage, 0, props.channelOrderBy, props.channelOrder, _val);
      }, 200);
    };

    return (
        <div className={channelClasses.searchBox}>
            <InputBase
                onChange={handleChannelSearch}
                value={channelSearchKey.value}
                className={channelClasses.input}
                placeholder={"Search Channels"}
            />
                {
                    channelSearchKey.value.length ? (
                        <IconButton
                            size="medium"
                            aria-label="search"
                            onClick={clearChannelSearchText}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : (
                        ""
                    )
                }
            <IconButton
                size="medium"
                aria-label="search"
                disabled
            >
                <SearchIcon />
            </IconButton>
        </div>
    )
}