"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PdfMenuStyles = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    .buttons-container {\n        padding: 0 30px;\n        margin: 10px 0;\n        display: flex;\n        justify-content: center;\n        position: sticky;\n        top: 10px;\n    }\n\n    .button {\n        margin: 0 5px;\n        padding: 8px;\n        cursor: pointer;\n        border-radius: 5px;\n        border: none;\n        outline: none;\n        font-size: 1.1em;\n    }\n\n    .button:hover {\n        background-color: rgba(221, 221, 221, .5);\n    }\n\n    .input {\n        width: 25px;\n        border-radius: 5px;\n        border: 0;\n        background: #dddddd;\n        outline: none;\n        padding: 8px;\n        margin: 0 5px;\n        font-weight: bold;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var PdfMenuStyles = _styledComponents.default.span(_templateObject());

exports.PdfMenuStyles = PdfMenuStyles;