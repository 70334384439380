import axios from "axios";
import authUtil from '../utils/authUtil';
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

// Add token to all headers
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + authUtil.getToken();
axios.defaults.withCredentials = true;
function createNetwork(requestObj) {
  return axios.post(BASE_API_URL + `/admin/networks/`, requestObj, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function getNetworks(query) {
  let queryParams = Util.buildQueryParams(query);
  const output = axios.get(BASE_API_URL + `/networks` + queryParams);
  return output;
}

function getNetworkById(networkId, query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/admin/networks/` + networkId + queryParams);
}


function inviteOrgsToNetwork(requestObj, networkId) {
  return axios.post(BASE_API_URL + `/admin/networks/` + networkId + `/organizations`, requestObj, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function getNetworksByOrgID(orgID, query) {
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/networks/organization/` + orgID + queryParams, {
    // headers: {
    //     "Authorization": 'Bearer ' + authUtil.getToken()
    // }
  });
}

function updateNetwork(networkId, requestObj) {
  return axios.post(BASE_API_URL + `/admin/networks/` + networkId, requestObj, {
    headers: {
      "Content-Type": "application/json",
    }
  });
}

function removeOrgFromNetwork(networkId, organizationId) {
  return axios.post(BASE_API_URL + `/admin/networks/` + networkId + `/removeOrg/` + organizationId, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  createNetwork,
  inviteOrgsToNetwork,
  getNetworks,
  getNetworkById,
  getNetworksByOrgID,
  updateNetwork,
  removeOrgFromNetwork
};
