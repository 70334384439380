import React from 'react';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import lightTheme from '../../themes/lightTheme';
import {
    InputLabel, Checkbox, ListItemText, FormControl, Input,
    Typography, Button, Select, MenuItem, Divider,
    IconButton, Backdrop, CircularProgress
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChaincodeService from '../../services/ChaincodeService';
import notify from '../../utils/notifier';
import organizationService from '../../services/OrganizationService'
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles(lightTheme => ({
    root: {
        flexGrow: 1
    },
    drawerHeader: {
        //padding: '5%'
        height: '64px',
        width: '100%',
        display: 'inline-flex'
    },
    title: {
        fontSize: '20px',
        fontFamily: 'Helvetica, sans-serif',
        paddingTop: '16px'
    },
    formBody: {
        marginTop: '8%',
    },
    fields: {
        paddingBottom: '5%',
        paddingLeft: lightTheme.spacing(2)
    },
    peers: {
        marginLeft: '5%',
        width: '25%'
    },
    formControl: {
        marginTop: '10px',
        width: '100%'
    },
    formActions: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    cancelBtn: {

        float: "right",
        margin: lightTheme.spacing(2),

    },
    submitBtn: {

        float: "right",
        margin: lightTheme.spacing(2),
    },
    backdrop: {
        zIndex: lightTheme.zIndex.drawer + 1,
    },
    flexItem: {
        flex: 1,
        paddingLeft: lightTheme.spacing(2),
        marginTop: "0px",

        width: "95%"
    },
    textField: {
        //paddingRight: lightTheme.spacing(4)
    },


}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function InstallForm(props) {

    const classes = useStyles();
    const [peers, setPeers] = React.useState([]);
    const [installPeers, setInstallPeers] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    //Fetch chaincode information
    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        let orgId = localStorage.getItem("_orgID");
        organizationService.getOrgsByOrgId(orgId).then(
            response => {
                const _data = response.data;
                setPeers(_data.peers);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching peers data"
                });
            }
        );
    };

    const handleChange = event => {
        setInstallPeers(event.target.value);
    };

    const handleSubmit = () => {
        const orgName = localStorage.getItem('_orgName');
        const reqObj = { peers: installPeers, id: props.data.id, version: props.data.version };
        setOpen(true);
        ChaincodeService.installChainCode(reqObj, orgName.toLowerCase()).then(response => {
            notify({
                type: 'success',
                message: 'Chaincode installed successfully!'
            });
            props.handleInstall({ data: { data: props.data, installPeers: installPeers } });
            setOpen(false);
        }, error => {
                if (error.response && error.response.status == 409) {
                notify({
                    type: "error",
                    message: "This chaincode is already installed"
                });
            } else {
                notify({
                    type: "error",
                    message: "Error occured while installing chaincode"
                });
            }
            setOpen(false);
        });
    };

    return (
        <MuiThemeProvider theme={lightTheme}>
            <div className={classes.drawerHeader}>
                <IconButton onClick={props.handleClose} style={{ background: 'none' }}>
                    <ChevronRightIcon color="primary" />
                </IconButton>
                <Typography className={classes.title}>Install</Typography>
            </div>
            <Divider />
            <div className={classes.formBody}>
                <div className={classes.fields}>
                    <Typography><b>Chaincode Name :</b> {props.data.name}</Typography>
                </div>
                <div className={classes.fields}>
                    <Typography><b>Version :</b> {props.data.version}</Typography>
                </div>

                <div className={classes.flexItem}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Peers *</InputLabel>
                        <Select
                            multiple
                            className={classes.targetPeers}
                            value={installPeers}
                            onChange={handleChange}
                            input={<Input />}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip key={value.id} label={value.peerName} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {peers.map(target => (
                                <MenuItem key={target.peerName} value={target}>
                                    <Checkbox checked={installPeers.some(_peer => _peer['id'] === target.id)} />
                                    <ListItemText primary={target.peerName} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className={classes.formActions} >
                <Divider />
                <Button className={classes.submitBtn} color="primary" variant="contained" onClick={handleSubmit} disabled={installPeers.length === 0 ? true : false}>
                    Submit
                </Button>
                <Button className={classes.cancelBtn} variant="contained" onClick={props.handleClose}>
                    Cancel
                </Button>

            </div>
            <Backdrop className={classes.backdrop} open={open} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </MuiThemeProvider >
    );
}