import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'

export const _documentColumns = [ 
  { id: 'documentNo', label: 'ID', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false },
  { id: 'originalFileName', label: 'File Name', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'name', label: 'Document Name', align: 'center', minWidth: 170, sortable: true, isFilterable: false, fitlerTypeSearch: false },
  { id: 'subAssetTypeName', label: 'Document Type', align: 'center', minWidth: 170, sortable: true, isFilterable: true, fitlerTypeSearch: true },
  { id: 'version', label: 'Version', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false },
  { id: 'Link', label: 'Action', align: 'center', minWidth: 170, isFilterable: false, fitlerTypeSearch: false }
]

export const _productColumns = [
  //{ id: "productNo", label: "No", minWidth: 170 },
  {
    id: 'productNumber',
    label: 'Product Number',
    minWidth: 170,
    sortable: true
  },
  {
    id: 'productDescription',
    label: 'Description',
    minWidth: 170,
    sortable: true
  },
  {
    id: 'purchaseOrderNumber',
    label: 'Purchase Order Number',
    minWidth: 170,
    sortable: true
  },
  { id: 'productHsCode', label: 'HS Code', minWidth: 170, sortable: true },
  { id: 'unitPrice', label: 'Unit Price ($)', minWidth: 170, sortable: true },
  { id: 'quantity', label: 'Quantity', minWidth: 170, sortable: true }
]

export const userPrefs = {
  rowsPerPageOptions: [10, 25, 100]
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      },
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export const fileUploadCriteria = {
  validFileTypes: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/pdf',
    'image/jpeg',
    'image/png'
    //'text/html'
  ],
  maxFileSizeLimit: 1000000 // 10MB
}

export const disableSubmitButtonGenerateAnEvent = (
  uploadValuesEventType,
  uploadValuesContainerNumber
) => {
  if (uploadValuesEventType && uploadValuesContainerNumber) {
    return false
  }
  return true
}

export const generateEvents = events => {
  let table = []

  for (let i = 0; i < events.length; i++) {
    var event = events[i]
    table.push(
      <MenuItem key={event} value={event}>
        {event}
      </MenuItem>
    )
  }
  return table
}


export const availableTemplateTypes = ['BLLA', 'CINV', 'PORD'];

export const createDocumentTypes = (documentTypeData, events, radioButtonValue, documentRows) => {
  let table = []


  let requiredDocs = documentTypeData.filter(i => i.isRequiredDoc);
  let unrequiredDocs = documentTypeData.filter(i => !i.isRequiredDoc);

  documentTypeData = requiredDocs.concat(unrequiredDocs);

  let documentTypeCount = {};
  if(documentRows){
    for(let document of documentRows) {
      if(!documentTypeCount[document.code]) {
        documentTypeCount[document.code] = 0;
      }
      documentTypeCount[document.code] += 1;
    }
  }

  for (let docType of documentTypeData) {
    var documentTypeName = docType.name;
    var b = true
    for (let j = 0; j < events.length; j++) {
      if (events[j] === documentTypeName) {
        b = false
      }
    }

    let menuText = documentTypeName;
    if (docType.isRequiredDoc) {
      menuText += String.fromCharCode(42);
    }

    if (documentTypeCount[docType.code]) {
      menuText += `(${documentTypeCount[docType.code]})`;
    }

    if (b) {
      table.push(
        <MenuItem
          key={documentTypeName}
          value={documentTypeName}
          disabled={radioButtonValue === 'MakeADocument' &&  !availableTemplateTypes.includes(docType.code)}
        >
          { menuText }
        </MenuItem>
      )
    }
  }
  return table
}

export const buildRequestObjUpdateDocument = (
  file,
  documentName,
  documentDescription,
  documentJSON,
  subAssetTypeId,
  fileType
) => {
  let orgId = localStorage.getItem('_orgID')
  var formData = new FormData()
  formData.append('file', file)
  formData.append('name', documentName)
  formData.append('description', documentDescription)
  formData.append('subAssetTypeId', subAssetTypeId)
  formData.append('data', JSON.stringify(documentJSON))
  formData.append('createdOrgId', orgId)
  formData.append('lastUpdatedOrgId', orgId)
  formData.append('fileType', fileType)
  return formData
}

export const buildRequestObjGenerateEvent = (
  organizationID,
  eventMap,
  uploadValues
) => {
  var formData = {}
  formData['createdOrgId'] = organizationID
  formData['eventCode'] = eventMap[uploadValues.eventType][1]
  formData['description'] = uploadValues.eventType
  formData['descriptionFromSource'] = uploadValues.descriptionFromSource //optional, text label is description
  formData['source'] =
    uploadValues.source === ''
      ? localStorage.getItem('_orgName')
      : uploadValues.source // optional text label info source
  formData['status'] = 'actual'
  formData['location'] = uploadValues.location // optional text label location
  formData['details'] = { info: 'User generated' }
  formData['actualEventTime'] = new Date().toISOString();
  formData['projectedEventTime'] = 'null';
  formData["containerNumber"] = uploadValues.containerNumber.split("-")[0];
  formData["containerId"] = uploadValues.containerNumber.split("-")[1];

  return formData
}

export const buildRequestObjCreateEventType = (
  organizationID,
  newEventType
) => {
  var formData = {}
  formData['createdOrgId'] = organizationID
  formData['newEventType'] = newEventType
  return formData
}

export function createJSON(props) {
  let tempJSON = {}
  if (props.location.state.shipmentValue[0].importerName) {
    tempJSON['importerName'] = props.location.state.shipmentValue[0].importerName
  }
  if (props.location.state.shipmentValue[0].exporterName) {
    tempJSON['exporterName'] = props.location.state.shipmentValue[0].exporterName
  }
  if (props.location.state.shipmentValue[0].origin) {
    tempJSON['origin'] = props.location.state.shipmentValue[0].origin
  }
  if (props.location.state.shipmentValue[0].destination) {
    tempJSON['destination'] = props.location.state.shipmentValue[0].destination
  }
  if (props.location.state.shipmentValue[0].vessel) {
    tempJSON['vessel'] = props.location.state.shipmentValue[0].vessel
  }
  if (props.location.state.shipmentValue[0].voyage) {
    tempJSON['voyage'] = props.location.state.shipmentValue[0].voyage
  }
  return tempJSON
}
