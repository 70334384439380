import React, { useRef } from "react";
import useStyles from '../components/documents/styles/DocumentStyles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import documentService from "../services/DocumentService";
import notify from "../utils/notifier";
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Tooltip, Button } from '@material-ui/core';
import { Multiselect } from 'multiselect-react-dropdown';


export default function SharePrivateDocument(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [privateMemberList, setPrivateMemberList] = React.useState(props.privateMemberList ? props.privateMemberList : []);
    const [selectedMembers, setSelectedMembers] = React.useState([]);
    const [showComponent, setShowComponent] = React.useState(false);
    const [render, setRender] = React.useState(false);
    const multiselectRef = useRef();
    function resetValues() {
        multiselectRef.current.resetSelectedValues();
    }
    function renderAgain() {
        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }
    }
    const handleAddMembers = (documentId) => {
        setBackdrop(true);
        
        let privateMemberOrgIdsBeforeUpdate = privateMemberList.map(member => { return parseInt(member.id) });

        let newPrivateMembers = selectedMembers.filter(member => !privateMemberOrgIdsBeforeUpdate.includes(parseInt(member.orgId)));
        
        let newPrivateMembersOrgIds = newPrivateMembers.map(member => { return parseInt(member.orgId) })

        if (newPrivateMembersOrgIds.length != 0) {
            let requestObj = {
                "organizationIds": newPrivateMembersOrgIds
            }
            documentService.sharePrivateDocument(documentId, requestObj).then(
                response => {
                    setBackdrop(false);
                    // props.handleSharePrivateDocumentSuccessfully();
                    setSelectedMembers([]);
                    setPrivateMemberList([...privateMemberList, ...newPrivateMembers]);
                    let _orgMember = orgMembers.filter(member => !newPrivateMembersOrgIds.includes(member['orgId']));
                    setOrgMembers(_orgMember);
                    let _message = "Document shared successfully with new members";
                    if (_orgMember.length > 0)
                        resetValues();
                    renderAgain();

                    notify({
                        type: "success",
                        message: _message
                    });
                },
                error => {
                    setBackdrop(false);
                    let _message = "Error while sharing the document to newly added members";
                    notify({
                        type: "error",
                        message: _message
                    });
                }
            );
        } else {
            setBackdrop(false);
            notify({
                type: "error",
                message: "No new members selected, please select new members!"
            });
        }

    }

    React.useEffect(() => {
        let _privateMemberCode = props.privateMemberList.map(member => member['organizationCode']);
        let _orgMember = props.channelMembers.filter(member => !_privateMemberCode.includes(member['organizationCode']));
        setOrgMembers(_orgMember);
        setShowComponent(true);
    }, []);

    const handleChangeMultiSelectMember = prop => (newValue) => {
        setSelectedMembers(newValue);
    }

    const handleMembers = prop => (event, newValue) => {
        setSelectedMembers(newValue);
    }

    return (

        showComponent ?

        <div className={classes.privateMemberList}>
            <Typography variant="h6" className={classes.memberListTitle}>
                Document is shared privately with following member(s):
            </Typography>
            <List dense={true}>
                {privateMemberList.map((member) => (
                    <ListItem className={classes.memberListItem}>
                        <ListItemIcon className={classes.memberListItemIcon}>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={member.organizationName}
                            className={classes.memberListItemText}
                        />
                    </ListItem>
                ))}
            </List>
            {
                props.channelMembers.length === privateMemberList.length ?
                    <span className={classes.smallText}>
                        *Document is shared with all the members of the channel.
                    </span>
                    :
                    <div className={classes.orgMemberDocPageDiv}>
                            <Multiselect
                                options={orgMembers} // Options to display in the dropdown
                                onSelect={handleChangeMultiSelectMember()}
                                onRemove={handleChangeMultiSelectMember()}
                                displayValue="organizationName"   //"name" // Property name to display in the dropdown options
                                placeholder="Share with members"
                                closeIcon="circle2"
                                hidePlaceholder="true"
                                ref={multiselectRef}
                                style={{ searchBox: { border: "none", "border-bottom": "0.8px solid #00000021", "border-radius": "0px" }, chips: { background: "#e0e0e0", color: "#343a40", fontfamily: 'LatoLight' } }}
                            />

                        {
                            selectedMembers.length ?
                                <div className={classes.addMemberButtonDiv}>
                                    <Tooltip className={classes.addDocButtonTooltip} title="Share with selected member(s)">
                                        <Button autoFocus className={classes.shareButton} color="primary"
                                            onClick={() => handleAddMembers(props.documentId)}>
                                            {"SHARE"}
                                        </Button>
                                    </Tooltip>
                                </div> : ""
                        }
                    </div>
            }
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div> : <div></div>
    );
}