import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Input, FormControl } from '@material-ui/core';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from '@material-ui/core/Drawer';

import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import bookingService from "../../services/BookingService";
import channelService from "../../services/ChannelService"
import ListItemText from '@material-ui/core/ListItemText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useStyles from './styles/BookingsStyles';
import notify from "../../utils/notifier";
import Moment from 'react-moment';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { AiOutlineFileExclamation } from "react-icons/ai";
import { AiOutlineFileText } from "react-icons/ai";
import { withStyles } from "@material-ui/core/styles";
import Highlight from 'react-highlighter';

import { VALID_USER_INPUT_REGEX, SHIPMENT_NUMBER_REGEX, SHIPMENT_REF_NUM_VALID_USER_INPUT_REGEX } from "../../utils/constants"
import {
    _columns,
    userPrefs,
    MenuProps,
    disableSubmitButtonBookingRefNumber,
    createChannels,
    createOrgDropdown,
} from './BookingsUtils'

const placeHolders = {
    podName: "Origin Port",
    poaName: "Destination Port",
    channelName: "Channel",
    carrier: "Carrier",
    status: "Status"
}

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const highlightableColumns = ["podName", "poaName", "channelName", "carrier", "referenceNumber", "bookingNumber"]

export default function Bookings(props) {

    const classes = useStyles();
    const [rows, setRows] = React.useState([]);
    const [originalRows, setOriginalRows] = React.useState([]);
    const [columns, setColumns] = React.useState(_columns);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState("desc");
    const [orderBy, setOrderBy] = React.useState("id");
    const [searchKey, setSearchKey] = useState({ value: "" });
    const [bookingInfoData, setBookingInfoData] = useState({});
    const [channels, setChannels] = React.useState([]);
    const [organizationID, setOrganisationID] = React.useState(localStorage.getItem('_orgID'));
    const [channelIDMap, setChannelIDMap] = React.useState({});
    const [waitChannelFetch, setWaitChannelFetch] = React.useState(true);
    const [backdrop, setBackdrop] = React.useState(false);
    const [sideDrawerNumber, setSideDrawerNumber] = React.useState(1)

    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [queryCount, setQueryCount] = React.useState(0);
    const [originalqueryCount, setOriginalQueryCount] = React.useState(0);
    const [orgMembers, setOrgMembers] = React.useState([]);
    const [seletedOrgId, setSeletedOrgId] = React.useState(null);

    const [filter, setFilter] = React.useState(true);
    const [selectedArrays, setSelectedArrays] = React.useState({
        podName: [],
        poaName: [],
        channelName: [],
        carrier: [],
        status: [],
    });

    const [dictChannels, setDictChannels] = React.useState({})
    const [inputArrays, setInputArrays] = React.useState({
        podName: [],
        poaName: [],
        channelName: [],
        carrier: [],
        status: [],
    });
    const [drawerState, setDrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const tableHeadWidth = (100 / _columns.length) + "%";

    const [values, setValues] = React.useState({
        Importer: "",
        Exporter: "",
        Origin: "",
        Destination: "",
        Channel: "",
        Vessel: "",
        Voyage: "",
        ContainerNumber: "",
        PortOfArrival: "",
        PortOfDeparture: "",
        bookingNumber: null,
        referenceNumber: null,
        movementType: "CY-CY",
        Carrier: "",
        Atd: "",
        Eta: "",
        BookingRefNum: ""
    });
    const setInitialState = () => {
        values.Importer = ""
        values.Exporter = ""
        values.Origin = ""
        values.Destination = ""
        values.Channel = ""
        values.Vessel = ""
        values.Voyage = ""
        values.ContainerNumber = ""
        values.PortOfArrival = ""
        values.PortOfDeparture = ""
        values.bookingNumber = null
        values.referenceNumber = null
        values.movementType = "CY-CY"
        values.Carrier = ""
        values.Eta = ""
        values.Atd = ""
        values.BookingRefNum = ""
        setWaitChannelFetch(true)
        setInvalidUserInput([]);
    }

    const movementType = [
        "CY-CY", "CY - Door", "Door - CY", "Door - Door"
    ];

    const portsMap = {
        "EGALY": "Alexandria",
        "JOAQJ": "Aqaba",
        "LBBEY": "Beirut",
        "MACAS": "Casablanca",
        "AEJEA": "Jebel Ali ",
        "CAMTR": "Montreal ",
        "EGPSE": "Port Said East",
        "CNQDG": "Qingdao",
        "KWSAA": "Shuaiba",
        "IQUQR": "Umm Qasr Port"
    }

    function removeInvalidUserInputKey(key) {
        let index = invalidUserInput.indexOf(key);
        if (index > -1) {
            invalidUserInput.splice(index, 1)
            setInvalidUserInput(invalidUserInput);
        }
    }


    const handleChange = prop => (event) => {
        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            removeInvalidUserInputKey(prop);
        }
        setValues({ ...values, [prop]: event.target.value });

        if (prop == "Channel") {
            fetchOrganizationByChannelId(channelIDMap[event.target.value]);
        }
    };

    const handleChangeOrg = (event) => {
        setSeletedOrgId(event.target.value);
    };

    const handleChangeBookingRefNumber = prop => (event) => {

        if (!SHIPMENT_REF_NUM_VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }

        let selectedBookingType;
        let clearedBookingType;

        if (SHIPMENT_NUMBER_REGEX.test(event.target.value.trim())) {
            selectedBookingType = "bookingNumber";
            clearedBookingType = "referenceNumber";
        } else {
            selectedBookingType = "referenceNumber";
            clearedBookingType = "bookingNumber";
        }

        setValues({ ...values, [prop]: event.target.value, [selectedBookingType]: event.target.value, [clearedBookingType]: "" });
    };

    function fetchChannelsByOrgId() {
        setBackdrop(true);
        channelService.getChannelsByOrgId(organizationID).then(
            response => {
                //setBackdrop(false);
                const _channelData = response.data;

                var dict = {}
                var arrChannel = []
                for (let i = 0; i < _channelData.length; i++) {
                    arrChannel.push(_channelData[i].channelName)
                    dict[_channelData[i].channelName] = _channelData[i].id
                }

                setChannelIDMap(dict)
                setChannels(arrChannel)
                setBackdrop(false);
                setDrawerState({ ...drawerState, ["right"]: true })

            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching channels data by org ID"
                });
            }
        );
    }

    function fetchOrganizationByChannelId(channelId) {
        setBackdrop(true);

        channelService.getOrgNameMspIdByChannelId(channelId).then(
            response => {
                const _data = response.data;
                setOrgMembers(_data);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching channel members list"
                });
            }
        );
    }


    function fetchBookingsSharedByOrgID(query) {
        setBackdrop(true);
        bookingService.getBookingsSharedByOrgID(organizationID, query).then(
            response => {

                let _bookingData = response.data[0];

                for (let i = 0; i < _bookingData.length; i++) {
                    _bookingData[i]['BookingNo'] = i + 1 + (query['_page'] - 1) * (query['_limit']);
                }
                setRows(_bookingData);
                if (response.headers.hasOwnProperty('x-total-count')) {
                    setQueryCount(parseInt(response.headers['x-total-count']));
                }
                if (!query['q']) {
                    setOriginalRows(_bookingData);
                    setOriginalQueryCount(parseInt(response.headers['x-total-count']));
                }
                setBookingInfoData(_bookingData);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                if (!query['q']) {
                    setRows([]);
                }
                else {
                    notify({
                        type: "error",
                        message: "Error while fetching bookings data"
                    });
                }
            }
        );

        bookingService.getUniqueColumnValuesBookingsSharedWithOrgId(organizationID).then(
            response => {
                let _bookingData = response.data;

                let podArray = []
                for (let j = 0; j < _bookingData[0].length; j++) {
                    if (_bookingData[0][j]["port_of_departure"] && _bookingData[0][j]["port_of_departure"].length) {
                        podArray.push(_bookingData[0][j]["port_of_departure"])
                    }
                }

                let poaArray = []
                for (let j = 0; j < _bookingData[1].length; j++) {
                    if (_bookingData[1][j]["port_of_arrival"] && _bookingData[1][j]["port_of_arrival"].length) {
                        poaArray.push(_bookingData[1][j]["port_of_arrival"])
                    }
                }
                let channelArray = []
                let tempDict = {}
                for (let j = 0; j < _bookingData[2].length; j++) {
                    if (_bookingData[2][j]["channel_name"] && _bookingData[2][j]["channel_name"].length) {
                        channelArray.push(_bookingData[2][j]["channel_name"])
                    }
                    tempDict[_bookingData[2][j]["channel_name"]] = _bookingData[2][j]["channel_id"]
                }

                let carrierArray = []
                for (let j = 0; j < _bookingData[3].length; j++) {
                    if (_bookingData[3][j]["carrier"] && _bookingData[3][j]["carrier"].length) {
                        carrierArray.push(_bookingData[3][j]["carrier"])
                    }
                }

                let statusArray = []
                for (let j = 0; j < _bookingData[4].length; j++) {
                    if (_bookingData[4][j]["status"] && _bookingData[4][j]["status"].length) {
                        statusArray.push(_bookingData[4][j]["status"])
                    }
                }
                setDictChannels(tempDict);
                setInputArrays({
                    "status": statusArray,
                    "channelName": channelArray,
                    "poaName": poaArray,
                    "podName": podArray,
                    "carrier": carrierArray
                });
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching filter values"
                });
            }
        )

    }

    const fetchData = (pageSize = rowsPerPage, currentPage = 0, sortBy = orderBy, sortOrder = order, searchText = null, prop = null, filterArray = null) => {
        let query = {
            '_page': currentPage + 1,
            '_limit': pageSize,
            '_sort': sortBy,
            '_order': sortOrder,
        }
        if (searchText) {
            query['q'] = searchText;
        }

        if (prop == "podName") {
            if (filterArray.length > 0) {
                query["_podName"] = filterArray;
            }
        } else {
            if (selectedArrays["podName"].length > 0) {
                query["_podName"] = selectedArrays["podName"];
            }
        }

        if (prop == "poaName") {
            if (filterArray.length > 0) {
                query["_poaName"] = filterArray;
            }
        } else {
            if (selectedArrays["poaName"].length > 0) {
                query["_poaName"] = selectedArrays["poaName"];
            }
        }

        let channelQuery = []
        if (prop == "channelName") {
            if (filterArray.length > 0) {
                for (const eachChannelName of filterArray) {
                    channelQuery.push(dictChannels[eachChannelName]);
                }
                query["_channelId"] = channelQuery
            }
        } else {
            if (selectedArrays["channelName"].length > 0) {
                for (const eachChannelName of selectedArrays["channelName"]) {
                    channelQuery.push(dictChannels[eachChannelName]);
                }
                query["_channelId"] = channelQuery
            }
        }

        if (prop == "carrier") {
            if (filterArray.length > 0) {
                query["_carrier"] = filterArray;
            }
        } else {
            if (selectedArrays["carrier"].length > 0) {
                query["_carrier"] = selectedArrays["carrier"];
            }
        }

        if (prop == "status") {
            if (filterArray.length > 0) {
                query["_status"] = filterArray;
            }
        } else {
            if (selectedArrays["status"].length > 0) {
                query["_status"] = selectedArrays["status"];
            }
        }

        fetchBookingsSharedByOrgID(query);
    };


    const handleChangeMulitpleSelect = prop => (event) => {
        let newArray = Array.from(event.target.value);
        setSelectedArrays(prevState => {
            return { ...prevState, [prop]: newArray };
        });
        fetchData(rowsPerPage, 0, orderBy, "asc", null, prop, event.target.value);
    };

    React.useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = () => {
        setBackdrop(true);
        let createBookingReqObj;
        createBookingReqObj = {
            "channelId": channelIDMap[values.Channel],
            "bookingNumber": values.bookingNumber,
            "referenceNumber": values.referenceNumber
        }

        bookingService.createBookingByBookingReference(seletedOrgId, createBookingReqObj).then(
            response => {
                refreshTable();
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                if (error.response && error.response.status === 409) {
                    notify({
                        type: "error",
                        message: "This booking is already imported."
                    })
                }
                else if (error.response && error.response.status === 404) {
                    notify({
                        type: "error",
                        message: `Booking is not found in Cargo Flow for ${localStorage.getItem('_orgName')}`
                    });
                } else {
                    notify({
                        type: "error",
                        message: "Error while creating booking"
                    });

                }
            }
        );
    };

    function disableCreateBookingSubmitButton() {
        return disableSubmitButtonBookingRefNumber(values.Channel, values.bookingNumber, values.referenceNumber);
    }

    const buttonClickCreateANewBooking = (side, open, row) => event => {
        fetchChannelsByOrgId();
        setDrawerState({ ...drawerState, ["right"]: true });
        setSideDrawerNumber(1)
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
    }

    const closeDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, [side]: open });
        setInitialState();
        setInvalidUserInput([]);
    };

    const getCurrentBooking = (row) => {
        var currentBooking = bookingInfoData.filter(function (booking) {
            return booking.id == row.id;
        });
        return currentBooking;
    };

    function refreshTable() {
        setDrawerState({ ...drawerState, ["right"]: false });
        fetchData();
        setInitialState();
    }

    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
        fetchData(rowsPerPage, 0, property, isDesc ? "asc" : "desc", searchKey.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        fetchData(rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        fetchData(+event.target.value, 0);
    };

    const clearSearchText = () => {
        setSearchKey({ value: "" });
        fetchData();
    };

    let timeout = null;
    const handleSearch = event => {
        const _val = event.target.value;
        setSearchKey(prevState => {
            return { ...prevState, value: _val };
        });
        if (_val.length <= 2) {
            setRows(originalRows);
            setQueryCount(originalqueryCount);
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchData(rowsPerPage, 0, orderBy, order, _val);
        }, 200);
    };


    const buttonClickClearAllFilters = () => {
        let doRefresh = false
        if (selectedArrays.poaName.length > 0 || selectedArrays.podName.length > 0 || selectedArrays.channelName.length > 0 || selectedArrays.carrier.length > 0 || selectedArrays.status.length > 0) {
            doRefresh = true
        }
        selectedArrays.poaName = []
        selectedArrays.podName = []
        selectedArrays.channelName = []
        selectedArrays.carrier = []
        selectedArrays.status = []
        if (doRefresh) {
            refreshTable();
        }
    }

    const handleClickonRow = (event, row) => {
        var newState = { "bookingValue": getCurrentBooking(row), "id": row.id, "BookingNo": row.BookingNo }
        props.history.push({ pathname: '/booking/' + row.bookingNumber, state: newState });
    };


    const createBookingTable = () => {
        let table = []
        for (let index = 0; index < rows.length; index++) {
            let row = rows[index]
            table.push(
                <TableRow hover style={{ cursor: "pointer", height: "60px" }} role="data-row" tabIndex={-1} key={index} onClick={event => handleClickonRow(event, row)} >
                    {columns.map(column => {
                        var value = "-";
                        if (row[column.id]) {
                            value = row[column.id];
                        }
                        if (column.id === "isDocumentUploaded") {
                            return (
                                <TableCell width={column.width} key={column.id} align="center">
                                    {value && value !== "-" ?
                                        <HtmlTooltip placement="top"
                                            title={
                                                <React.Fragment>
                                                    {"At least one document is uploaded in this booking"}
                                                </React.Fragment>
                                            }
                                        >
                                            <div><AiOutlineFileText style={{ height: "1.5em", width: "1.5em" }}></AiOutlineFileText></div>
                                        </HtmlTooltip>
                                        // <Tooltip title="At least one document is uploaded in this booking" placement="top">
                                        //   <div><AiFillFileText style={{ height: "1.5em", width: "1.5em" }}></AiFillFileText></div>
                                        // </Tooltip>

                                        :

                                        <HtmlTooltip placement="top"
                                            title={
                                                <React.Fragment>
                                                    {"No documents are uploaded for this booking"}
                                                </React.Fragment>
                                            }
                                        >
                                            <div><AiOutlineFileExclamation style={{ height: "1.5em", width: "1.5em", color: "red" }}></AiOutlineFileExclamation></div>
                                        </HtmlTooltip>
                                        // <Tooltip title="No documents are uploaded to this booking" placement="top">
                                        //   <div><AiFillFileExclamation style={{ height: "1.5em", width: "1.5em" }}></AiFillFileExclamation></div>
                                        // </Tooltip>
                                    }
                                </TableCell>
                            )
                        } else if (column.id === "ata" || column.id === "eta") {
                            return (
                                <TableCell width={column.width} key={column.id} align="center">
                                    {value && value !== "-" ? <Moment format="DD/MM/YYYY">{value}</Moment> : "-"}
                                </TableCell>
                            )
                        } else if (column.id === "vessel") {
                            if (!row["vessel"] || !row["voyage"]) {
                                value = "-";
                            } else {
                                value = row["vessel"] + " " + row["voyage"];
                            }

                            return (
                                <TableCell width={column.width} key={column.id} align="center">
                                    <Highlight search={searchKey.value}>
                                        {value}
                                    </Highlight>
                                </TableCell>
                            )
                        }
                        else {
                            if (highlightableColumns.includes(column.id)) {
                                return (
                                    <TableCell key={column.id} align="center">
                                        <Highlight search={searchKey.value}>
                                            {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                        </Highlight>
                                    </TableCell>
                                );
                            } else {
                                return (
                                    <TableCell key={column.id} align="center">
                                        {column.format && typeof value === "number" ? (column.format(value)) : (value)}
                                    </TableCell>
                                );
                            }
                        }
                    })}
                </TableRow>
            );
        }
        return table;
    }

    const createNoTable = () => {
        let arr = []
        arr.push(
            <TableRow style={{ height: "60px" }}>
                <TableCell colSpan="11" align="center" className={classes.noRecordsRow}>
                    {backdrop ? "Fetching bookings, please wait" : "No Bookings, Please click on create a booking"}
                </TableCell>
            </TableRow>
        )
        return arr;
    }


    const sideList = side => (
        <div className={classes.list}>
            <div>
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Create a new booking"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div style={{ overflow: "auto", maxHeight: "74vh" }}>
                {
                    backdrop ? "" : (
                        channels.length === 0 ?
                            <div className={classes.flexItem}>
                                <Paper className={classes.root}>
                                    <div className={classes.root_noShipments}>
                                        <h3>{"You are not part of any channel, Join a channel"}</h3>
                                    </div>
                                </Paper>
                            </div>
                            :
                            ""
                    )
                }

                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        select
                        className={classes.textField}
                        label={"Channel"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={values.Channel}
                        autoComplete='off'
                        disabled={channels.length === 0}
                        onChange={handleChange("Channel")}
                    >
                        {createChannels(channels)}
                    </TextField>
                    <TextField
                        id="standard-basic"
                        select
                        className={classes.textField}
                        label={"Select organization"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={seletedOrgId}
                        autoComplete='off'
                        onChange={handleChangeOrg}
                    >
                        {createOrgDropdown(orgMembers)}
                    </TextField>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        className={classes.textField}
                        error={invalidUserInput.includes("BookingRefNum")}
                        label={"Booking Reference Number"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={values.BookingRefNum}
                        autoComplete='off'
                        helperText="Ex SA-5HAUN9 , Coffe-4553517892"
                        onChange={handleChangeBookingRefNumber("BookingRefNum")}
                    />
                </div>

            </div>

            <div class={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmit} variant="contained" color="primary" disabled={backdrop || invalidUserInput.length || disableCreateBookingSubmitButton()}>
                    {"SUBMIT"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );


    return (
        <div className={classes.borderMain}>
            <Backdrop open={backdrop} style={{ color: "primary", zIndex: 100 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Paper className={classes.root}>
                <Grid item xs={12} style={{ minHeight: "87vh" }}>
                    <div className={classes.tableTitle}>
                        <h2>{"Bookings"}</h2>
                    </div>
                    <div className={classes.tableHeadRight}>
                        <div className={classes.shipmentOptionDiv}>
                            <div className={classes.searchBox}>
                                <InputBase
                                    onChange={handleSearch}
                                    value={searchKey.value}
                                    className={classes.input}
                                    placeholder={"Search Bookings"}
                                />
                                {searchKey.value.length ? (
                                    <IconButton
                                        size="medium"
                                        aria-label="search"
                                        onClick={clearSearchText}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ) : (
                                        ""
                                    )}
                                <IconButton
                                    size="medium"
                                    aria-label="search"
                                    disabled
                                >
                                    <SearchIcon />
                                </IconButton>
                            </div>
                            <div className={classes.button}>
                                <Button
                                    color="primary"
                                    className={classes.UploadButton}
                                    onClick={buttonClickCreateANewBooking("right", true)}
                                    startIcon={<AddCircleIcon />}
                                    disabled={localStorage.getItem("_orgCode") !== "dpw-cargoes"}
                                >
                                    {"Create a Booking"}
                                </Button>
                            </div>
                        </div>

                        <div className={classes.tableHeadRightFilters}>
                            {
                                filter ?
                                    <div className={classes.filterDiv}>
                                        <div className={classes.filterTextFieldDiv}>
                                            <Grid item xs={12} style={{ minHeight: "8vh" }}>
                                                {
                                                    columns.map(column => (
                                                        column.isFilterable ?
                                                            <FormControl variant="outlined" className={classes.filterTextField}>
                                                                <InputLabel id="demo-mutiple-checkbox-label">{placeHolders[column.id]}</InputLabel>
                                                                <Select
                                                                    labelId="demo-mutiple-checkbox-label"
                                                                    id="demo-mutiple-checkbox"
                                                                    multiple
                                                                    value={selectedArrays[column.id]}
                                                                    onChange={handleChangeMulitpleSelect(column.id)}
                                                                    input={<Input />}
                                                                    renderValue={(selected) => selected.join(', ')}
                                                                    MenuProps={MenuProps}
                                                                    variant="outlined"
                                                                >
                                                                    {inputArrays[column.id].map((name) => (
                                                                        <MenuItem key={name} value={name}>
                                                                            <Checkbox checked={selectedArrays[column.id].indexOf(name) > -1} />
                                                                            <ListItemText primary={name} />
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            :
                                                            ""

                                                    ))
                                                }
                                            </Grid>
                                        </div>
                                        <div className={classes.clearFilterButton}>
                                            <Button
                                                color="primary"
                                                onClick={buttonClickClearAllFilters}
                                            >
                                                {"Clear All"}
                                            </Button>
                                        </div>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    {/* <div>
            <Button
              color="primary"
              className={classes.button}
              onClick={buttonClickFilter()}
              startIcon={<SearchIcon />}
            >
              {"Filter"}
            </Button>
          </div> */}

                    <div>
                    </div>
                    <div className={classes.tableWrapper}>
                        <Paper className={classes.tableContainer}>
                            <Table stickyHeader aria-label="sticky table" className={classes.tableBorder}>
                                <TableHead>
                                    <TableRow >
                                        {columns.map(column => (
                                            <TableCell style={{ fontWeight: "bold" }}
                                                width={column.width}
                                                key={column.id}
                                                align="center"
                                                sortDirection={orderBy === column.id ? order : false}
                                            >
                                                {column.sortable ? (
                                                    <TableSortLabel
                                                        active={orderBy === column.id}
                                                        direction={order}
                                                        onClick={handleRequestSort(column.id)}
                                                        className={classes.tableColumnLabel}
                                                    >
                                                        {column.label}
                                                        {orderBy === column.id ? (
                                                            <span className={classes.visuallyHidden}>
                                                                {order === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                ) : (
                                                        column.label
                                                    )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.length === 0 ? createNoTable() : createBookingTable()}
                                </TableBody>
                                <Drawer
                                    anchor="right"
                                    open={drawerState.right}
                                >
                                    {sideDrawerNumber === 1 ? sideList('right') : ""}
                                </Drawer>
                            </Table>
                        </Paper>
                    </div>
                    {rows.length === 0 ? "" :
                        <TablePagination
                            rowsPerPageOptions={userPrefs.rowsPerPageOptions}
                            component="div"
                            count={queryCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            backIconButtonProps={{
                                "aria-label": "previous page"
                            }}
                            nextIconButtonProps={{
                                "aria-label": "next page"
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={"Rows per page"}
                        />
                    }
                </Grid>
            </Paper>
        </div>
    );
}
