import axios from "axios";
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

axios.defaults.withCredentials = true;
function getBlockTransactions(channelId, blockNumber) {
  return axios.get(BASE_API_URL + `/channels/` + channelId + `/blocks/` + blockNumber, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function getBlocksInChannel(channelId, blockHeight) {
  let query = {};
  if (blockHeight) {
    query['blockNum'] = blockHeight;
  }
  query['numBlocks'] = 5;
  let queryParams = Util.buildQueryParams(query);
  return axios.get(BASE_API_URL + `/channels/` + channelId + `/blocks` + queryParams, {
    headers: {
      "Content-Type": "application/json"
    }
  });

}

export default {
  getBlocksInChannel,
  getBlockTransactions
};
