import axios from 'axios';
import authUtil from '../utils/authUtil';
import Util from '../utils/Util';

const BASE_API_URL = `${process.env.REACT_APP_API_ENDPOINT}`;

axios.defaults.withCredentials = true;

function getAssetsByOrgId(organizationId, query) {
    let queryParams = Util.buildQueryParams(query)
    return axios.get(
        BASE_API_URL + `/assets/organizations/${organizationId}` + queryParams,
        {
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )
}

function downloadAsset(assetId, version = null, stream = false) {
    let query = (version) ? `?stream=${stream}&version=${version}` : `?stream=${stream}`;
    return axios.get(
        BASE_API_URL +
        `/assets/` +
        assetId +
        `/download` + query,
        {
            responseType: 'blob',
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )
}

function createBulkAssets(requestObj) {
    return axios.post(BASE_API_URL + `/assets/bulkcreate`, requestObj, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    })
}

function getAssetRevisionByAssetNumber(assetNumber) {
    return axios.get(
        BASE_API_URL +
        '/assets/' +
        assetNumber +
        '/revisions',
        {
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )
}

function getHistoryByAssetId(assetId) {
    return axios.get(
        BASE_API_URL +
        '/assets/' +
        assetId +
        '/history',
        {
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )
}

function getNextPossibleOwnerRole(assetNumber, currenState) {
    return axios.get(
        BASE_API_URL +
        '/assets/' +
        assetNumber +
        '/state/' +
        currenState +
        '/nextOwners',
        {
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )

}

function transferOwnership(assetNumber, requestObj) {
    return axios.post(
        BASE_API_URL + '/assets/' + assetNumber +
        '/transferOwnership',
        requestObj,
        {
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )
}

function updateAsset(assetId, requestObj) {
    return axios.post(
        BASE_API_URL + '/assets/' +
        assetId,
        requestObj,
        {
            headers: {
                Authorization: 'Bearer ' + authUtil.getToken()
            }
        }
    )
}

function getUniqueColumnValuesAssetsSharedWithOrgId(orgainzationId) {
    return axios.get(BASE_API_URL + `/assets/UniqueColumnValues/` + orgainzationId, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}

function checkValidStateForUpdatingAsset(stateCode) {
    return axios.get(BASE_API_URL + `/assets/checkValidStateForUpdatingAsset/state/${stateCode}`, {
        headers: {
            Authorization: 'Bearer ' + authUtil.getToken()
        }
    });
}


    export default {
        getAssetsByOrgId,
        downloadAsset,
        createBulkAssets,
        getAssetRevisionByAssetNumber,
        getNextPossibleOwnerRole,
        transferOwnership,
        updateAsset,
        getHistoryByAssetId,
        getUniqueColumnValuesAssetsSharedWithOrgId,
        checkValidStateForUpdatingAsset
};
