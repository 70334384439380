import React from "react";
import Button from '@material-ui/core/Button';
import notify from "../../utils/notifier";
import Backdrop from '@material-ui/core/Backdrop';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from "@material-ui/core/Divider";
import { FormControl, Input, InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import shipmentService from "../../services/ShipmentService";


import {
    buildRequestObjCreateEventType,
} from './ShipmentInformationUtils';


import useStyles from './styles/ShipmentInformationStyles';


export default function CreateEvent(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [newEventType, setNewEventType] = React.useState('');
    const [organizationID, setOrganisationID] = React.useState(localStorage.getItem('_orgID'));

    const setInitialState = () => {
        setNewEventType("");
    }


    const handleSubmitCreateEventType = () => {

        let formDataCreateEventType = buildRequestObjCreateEventType(organizationID, newEventType);
        if (!formDataCreateEventType) {
            return;
        }
        setBackdrop(true);
        shipmentService.createNewEventType(formDataCreateEventType).then(
            response => {
                props.refreshEvents();
                setInitialState();
                setBackdrop(false)
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while creating an event"
                });
            }
        );
    }

    const handleEventTypeChange = prop => (event) => {
        setNewEventType(event.target.value)
    };


    return (
        <div className={classes.eventSideList}>
            <div >
                <Toolbar>
                    <div className={classes.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.closeDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Create an Event Type"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div style={{ overflow: "auto", maxHeight: "85vh" }}>

                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '94%' }}>
                        <TextField
                            id="standard-basic"
                            className={classes.textField}
                            label={"Event Type"}
                            margin="normal"
                            rows="2"
                            required
                            fullWidth
                            value={newEventType}
                            onChange={handleEventTypeChange("")}
                        />
                    </FormControl>
                </div>
            </div>

            <div class={classes.sidedrawerFooter}>
                <Divider />
                <Button className={classes.drawerButton} onClick={handleSubmitCreateEventType} color="primary" variant="contained" disabled={backdrop || !newEventType}>
                    {"CREATE"}
                </Button>
                <Button autoFocus className={classes.drawerButton} onClick={props.closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>

            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
}