import React, { useState } from "react";
import useStyles from '../components/documents/styles/DocumentStyles';
import Backdrop from '@material-ui/core/Backdrop';
import { APPLICATION_ID, VALID_USER_INPUT_REGEX } from "../utils/constants";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, FormControlLabel, Input, InputLabel, Checkbox, ListItemText, ListItemIcon, List, ListItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UploadFile from './UploadFile';
import FormRenderer from 'form-render-module'
import notify from "../utils/notifier";
import bookingService from "../services/BookingService";
import documentService from "../services/DocumentService";
import channelService from "../services/ChannelService";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DescriptionIcon from '@material-ui/icons/Description';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Multiselect } from 'multiselect-react-dropdown';

import {
    fileUploadCriteria,
    MenuProps,
    createDocumentTypes,
    buildRequestObjUpdateDocument,
    disableSubmitButtonUpdate,
} from '../components/documents/DocumentsUtilsV2'

export default function UpdateDocument(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [documentName, setDocumentName] = React.useState(props.documentName);
    const [documentType, setDocumentType] = React.useState(props.documentType);
    const [documentCode, setDocumentCode] = React.useState(props.documentCode);
    const [documentDescription, setDocumentDescription] = React.useState(props.documentDescription);
    const [documentJSON, setDocumentJSON] = React.useState({});
    const [fileName, setFileName] = React.useState(props.fileName);
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [file, setFile] = useState("");
    const [channelMembers, setChannelMembers] = React.useState([]);
    const [nonSharedBookings, setNonSharedBookings] = React.useState([]);
    const [nonSharedMembers, setNonSharedMembers] = React.useState([]);
    const [selectedMembers, setSelectedMembers] = React.useState([]);
    const [privateMemberList, setPrivateMemberList] = React.useState([]);
    const [sharedBookingList, setSharedBookingList] = React.useState([]);
    const [selectedBookings, setSelectedBookings] = React.useState([]);
    const [documentTypeIDMap, setDocumentTypeIDMap] = React.useState({});
    const [documentCodeIDMapRev, setdocumentCodeIDMapRev] = React.useState({});
    const [documentTypesInfo, setDocumentTypesInfo] = React.useState([]);
    const [uploadVersion, setUploadVersion] = React.useState(false);


    const handleChangeDocumentName = (prop) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setDocumentName(event.target.value);
    };

    const handleChangeDocumentDescription = (prop) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setDocumentDescription(event.target.value)
    };

    const obj = (arg) => {
        var json = Object.assign({}, arg)
        var temp = Object.assign(documentJSON, json);
        if (Object.keys(json).length !== 0) {
            setDocumentJSON(temp);
        }
    }

    const handleFileUpload = (file) => {
        if (file) {
            if (fileUploadCriteria.validFileTypes.includes(file.type) && file.size > 0 && file.size <= fileUploadCriteria.maxFileSizeLimit) {
                setFile(file);
            } else {
                let errorMsg;
                if (!fileUploadCriteria.validFileTypes.includes(file.type)) {
                    errorMsg = "Invalid file type uploaded"
                } else {
                    errorMsg = "File size criteria is not matching"
                }
                notify({
                    type: "error",
                    message: errorMsg
                });
            }
        } else {
            setFile("")
        }
    }

    const handleClickDeleteFile = () => {
        setFile("");
    }

    const handleUploadVersion = () => {
        setUploadVersion(!uploadVersion);
    }

    const handleSubmitUpdateDocument = () => {

        let privateMemberOrgIdsBeforeUpdate = privateMemberList.map(member => { return parseInt(member.organizationId) });
        let newPrivateMembersOrgIds = selectedMembers.filter(member => !privateMemberOrgIdsBeforeUpdate.includes(parseInt(member.orgId)));
        let _newOrganizationMemberIds = [];
        if (newPrivateMembersOrgIds.length > 0) {
            _newOrganizationMemberIds = newPrivateMembersOrgIds.map(member => { return member.id ? parseInt(member.id) : parseInt(member.orgId) })
        }
        let formDataUpdateDocumentList = buildRequestObjUpdateDocument(file, documentJSON, documentCode, documentName, documentDescription, props.rowFileType, _newOrganizationMemberIds, selectedBookings, uploadVersion);
        if (!formDataUpdateDocumentList) {
            return;
        }
        setBackdrop(true);
        documentService.updateDocumentV2(props.documentID, formDataUpdateDocumentList).then(
            response => {
                props.refreshDocumentTable();
                setBackdrop(false);
                let _message = "Document updated successfully";
                notify({
                    type: "success",
                    message: _message
                });
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while updating the document"
                });
            }
        );

    };

    function handleSearch(inputvalue){
        if(inputvalue.length>2){
        bookingService.getBookingsByDocumentId(props.documentID).then(
            response => {
                let data = response.data;
                setSharedBookingList(data);
                let _sharedBookingNumbers = data.map(booking => booking.bookingNumber);
                let orgId = parseInt(localStorage.getItem('_orgID'));
                let query = {
                    'q':inputvalue,
                    '_limit':50
                }

                bookingService.getBookingsSharedByOrgID(orgId, query).then(
                    response => {
                        let data = response.data[0];
                        let _nonSharedBookings = data.filter(booking => !_sharedBookingNumbers.includes(booking.bookingNumber));
                        if(_nonSharedBookings.length){
                        _nonSharedBookings= _nonSharedBookings.map((row)=>({name:getBookingDropdownText(row),...row}));
                        setNonSharedBookings(_nonSharedBookings);
                        setBackdrop(false);
                        }
                    },
                    error => {
                        notify({
                            type: "error",
                            message: `Error while getting list of all bookings`
                        });
                    }
                )
            },
            error => {
                notify({
                    type: "error",
                    message: `Error while getting list of bookings where document : ${props.documentName} is uploaded`
                });
            }
        )
        }
    } 

    const handleMembers = prop => (event, newValue) => {
        setSelectedMembers(newValue);
    }

    const handleChangeMultiSelectBookingNumber = prop => ( newValue) => {
        
        let _newSelectedBookingNumbers = newValue.map(option => option.bookingNumber);
        setSelectedBookings(_newSelectedBookingNumbers);
    }

    const handleChangeDocumentType = () => (event) => {
        setDocumentType(event.target.value);
        setDocumentCode(documentCodeIDMapRev[documentTypeIDMap[event.target.value]]);
        // renderAgain();
    }

    function fetchDocumentTypes() {

        setBackdrop(true);

        bookingService.getDocumentTypesByDestination().then(
            response => {
                const _data = response.data;
                let arr = []
                let dict = {}
                let dictRev = {}
                let dictMap = {}
                for (let i = 0; i < _data.length; i++) {
                    var singleData = _data[i];
                    arr.push(singleData["name"])
                    dict[singleData["name"]] = singleData["id"]
                    dictRev[singleData["id"]] = singleData["name"]
                    dictMap[singleData["id"]] = singleData["code"]
                }
                setDocumentTypeIDMap(dict);
                setdocumentCodeIDMapRev(dictMap);
                setDocumentTypesInfo(_data);
                setBackdrop(false);
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while fetching Document types"
                });
            }
        );
    }


    React.useEffect(() => {

        if (props.rowFileType === 'JSON') {
            setBackdrop(true)

            documentService.downloadDocumentMetadata(props.documentID, props.version).then(
                response => {
                    setBackdrop(false)
                    const _data = response.data;
                    let jsonData = typeof(_data.data.metadata) === "string" ? JSON.parse(_data.data.metadata) : _data.data.metadata;
                    setDocumentJSON(jsonData);
                },
                error => {
                    setBackdrop(false)
                    notify({
                        type: "error",
                        message: "Error while fetching download Document"
                    });
                }
            );
        } else {
            fetchDocumentTypes();
        }

        if (props.isPrivate === true) {
            setBackdrop(true);

            channelService.getOrgNameMspIdByChannelId(props.channelId).then(
                response => {
                    const _channelMembers = response.data;
                    setChannelMembers(_channelMembers);
                    documentService.getOrganizationsListForPrivateDoc(props.documentID).then(
                        response => {
                            let data = response.data;
                            data = data.map(member => {
                                member['id'] = member['organizationId'];
                                return member;
                            })
                            setPrivateMemberList(data);
                            let _privateMemberCode = data.map(member => member['organizationCode']);
                            let _nonSharedMembers = _channelMembers.filter(member => !_privateMemberCode.includes(member['organizationCode']));
                            setNonSharedMembers(_nonSharedMembers);
                            setBackdrop(false);
                        },
                        error => {
                            setBackdrop(false);
                            notify({
                                type: "error",
                                message: `Error while getting private member list for document : ${props.documentName}`
                            });
                        }
                    );
                    setBackdrop(false);
                },
                error => {
                    setBackdrop(false);
                    notify({
                        type: "error",
                        message: "Error while fetching channel members list"
                    });
                }
            );
        }

        setBackdrop(true);

        bookingService.getBookingsByDocumentId(props.documentID).then(
            response => {
                let data = response.data;
                setSharedBookingList(data);
                let _sharedBookingNumbers = data.map(booking => booking.bookingNumber);

                let orgId = parseInt(localStorage.getItem('_orgID'));

                bookingService.getBookingsSharedByOrgID(orgId).then(
                    response => {
                        let data = response.data[0];
                        let _nonSharedBookings = data.filter(booking => !_sharedBookingNumbers.includes(booking.bookingNumber));
                        _nonSharedBookings= _nonSharedBookings.map((row)=>({name:getBookingDropdownText(row),...row}));
                        setNonSharedBookings(_nonSharedBookings);
                        setBackdrop(false);
                    },
                    error => {
                        setBackdrop(false);
                        notify({
                            type: "error",
                            message: `Error while getting list of all bookings`
                        });
                    }
                )
            },
            error => {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: `Error while getting list of bookings where document : ${props.documentName} is uploaded`
                });
            }
        )

    }, []);


    function getBookingDropdownText (bookingInfo) {
        let _text = "";
        if (bookingInfo.bookingNumber) {
            _text += bookingInfo.bookingNumber;
        }
        if (bookingInfo.referenceNumber) {
            _text = _text + " (" + bookingInfo.referenceNumber + ")";
        }

        return _text;
    }


    return (
        <div className={classes.list}>
            <Toolbar>
                <div className={classes.drawerHeader}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.closeDrawer("right", false)}
                        edge="start"
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </div>
                <Typography variant="h6" noWrap>{"Update document"}</Typography>
            </Toolbar>
            <Divider></Divider>
            <br></br>
            <div className={classes.docOptionDiv}>

                {
                     props.rowFileType === "File" ? 
                     <div className={classes.flexItem}>
                         <div className={classes.uploadedFileNameDiv}>
                             <div className={classes.fileIconDiv}>
                                <Tooltip title="Uploaded file name">
                                    <DescriptionIcon />
                                </Tooltip>
                             </div>    
                             <div className={classes.fileNameTextDiv}>
                                <Typography fontSize="1rem"  noWrap>{fileName}</Typography>
                             </div>   
                        </div>
                    </div> 
                    : ""
                }

                <div className={classes.flexItem}>
                    <FormControl style={{ minWidth: '100%' }}>
                        <InputLabel id="demo-mutiple-name-label">Document Type</InputLabel>
                        {
                            props.rowFileType === "JSON" ?
                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                fullWidth
                                value={props.documentType}
                                input={<Input />}
                                MenuProps={MenuProps}
                                disabled
                            >
                                {[<MenuItem
                                    key={props.documentType}
                                    value={props.documentType}
                                >
                                    {props.documentType}
                                </MenuItem>]}
                            </Select> : 
                            <Select
                                labelId="demo-mutiple-name-label"
                                id="demo-mutiple-name"
                                fullWidth
                                value={documentType}
                                onChange={handleChangeDocumentType()}
                                input={<Input />}
                                MenuProps={MenuProps}
                            >
                                {createDocumentTypes(documentTypesInfo, "UploadADocument")}
                            </Select>
                        }
                    </FormControl>
                </div>

                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        error={invalidUserInput.includes("updateDocumentName")}
                        label={"Document Reference"}
                        margin="normal"
                        rows="2"
                        fullWidth
                        value={documentName}
                        onChange={handleChangeDocumentName("updateDocumentName")}
                    />
                </div>

                <div className={classes.flexItem}>
                    <TextField
                        id="standard-basic"
                        className={classes.textField}
                        error={invalidUserInput.includes("updateDocumentDescription")}
                        label={"Document Description"}
                        margin="normal"
                        rows="2"
                        fullWidth
                        value={documentDescription}
                        onChange={handleChangeDocumentDescription("updateDocumentDescription")}
                    />
                </div>
                {
                    privateMemberList.length ?
                        <div className={classes.flexItem}>
                            <Typography variant="h6" className={classes.memberListTitle}>
                                Document is shared privately with following member(s):
                            </Typography>
                            <List dense={true}>
                                {privateMemberList.map((member) => (
                                    <ListItem className={classes.memberListItem}>
                                        <ListItemIcon className={classes.memberListItemIcon}>
                                            <AccountCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={member.organizationName}
                                            className={classes.memberListItemText}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                        : ""
                }
                {
                    props.isPrivate === true ?
                        <div className={classes.orgMemberUpdateDocDiv}>
                            {
                                channelMembers.length  === privateMemberList.length ?
                                <span className={classes.smallText}>
                                    *Document is shared with all the members of the channel.
                                </span>
                                :
                                <div className={classes.shipmentRefNumAutoCompleteTextFieldUpdateDoc}> 
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={nonSharedMembers}
                                        size="small"
                                        getOptionLabel={(option) => option.organizationName ? option.organizationName : ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                InputLabelProps={{style: {fontSize: "0.875rem"}}}
                                                variant="standard"
                                                label="Share with more members"
                                            />
                                        )}
                                        renderOption={(option, { inputValue }) => {
                                            const matches = match(option.organizationName, inputValue);
                                            const parts = parse(option.organizationName, matches);
                            
                                            return (
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span key={index} style={{ fontSize: "0.875rem" }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            );
                                        }}
                                        onChange={handleMembers()}
                                    />
                                </div>
                            }
                        </div> 
                        : ""
                }
                {
                    sharedBookingList.length != 0 ? 
                    <div className={classes.flexItem}>
                        <div className={classes.sharedShipmentsUpdateDoc}>
                            <Typography variant="h6" className={classes.memberListTitle}>
                                Document is attached to following booking(s):
                            </Typography>
                            <List dense={true}>
                                {sharedBookingList.map((bookingInfo) => (
                                    <ListItem className={classes.memberListItem}>
                                        <ListItemIcon className={classes.memberListItemIcon}>
                                            <LocalShippingIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={getBookingDropdownText(bookingInfo)}
                                            className={classes.memberListItemText}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </div> : ""
                }
                <div className={classes.flexItem}>
                    {
                        nonSharedBookings.length === 0 ?
                        <div className={classes.smallTextAddShipmentsDiv}>
                            <span className={classes.smallTextAddShipments}>
                                {
                                    backdrop ? "Fetching bookings ..." : "*Document is shared with all the bookings."
                                }
                            </span>
                        </div>
                        :
                        <div className={classes.shipmentRefNumAutoCompleteTextFieldUpdateDoc}> 
                            
                            <div>
                            <Multiselect
                            options={nonSharedBookings} // Options to display in the dropdown
                            onSelect={handleChangeMultiSelectBookingNumber()}
                            onRemove={handleChangeMultiSelectBookingNumber()} 
                            displayValue="name" // Property name to display in the dropdown options
                            placeholder="Add more bookings"
                            closeIcon="circle2"
                            hidePlaceholder="true"
                            onSearch={(inputvalue)=>handleSearch(inputvalue)}
                            style={  {searchBox:{ border: "none", "border-bottom": "0.8px solid #00000021", "border-radius": "0px" },chips: { background: "#e0e0e0",color:"#343a40",fontfamily:'LatoLight'}} }
                            />
                            <p style={{float:'left',marginTop:'5px', fontSize: "0.755rem",color:"rgba(0, 0, 0, 0.54)"}}>Attach documents to selected bookings</p>
                            </div>
                        </div>
                    }
                </div>
                {
                    props.rowFileType === "JSON" ?
                        <div className={classes.flexItem}>
                            <div className={classes.uploadVersionCheckboxDiv}>
                                <p>Document</p>
                            </div>
                            <div style={{
                                maxWidth: "1400px", maxHeight: "100%", border: "1px solid rgba(236, 236, 236, 0.46)", marginRight: "30px", borderRadius: "5px", zoom: '1.1', MozTransform: 'scale(1,1.1)', MozTransformOrigin: 'top'
                            }}>
                                <FormRenderer docName={props.documentCode} appId={APPLICATION_ID} parentCallback={obj} metadata={{}} filledData={documentJSON} disable={false} />

                            </div>
                        </div>
                        : props.rowFileType === "File" ? 
                            <div className={classes.flexItem}>
                                <div className={classes.uploadVersionCheckboxDiv}>
                                    <FormControlLabel
                                        control={<Checkbox checked={uploadVersion} onChange={handleUploadVersion}/>}
                                        label="Upload new version"
                                    />
                                </div>
                                {
                                    uploadVersion ? <UploadFile onFileUpload={handleFileUpload} handleClickDeleteFile={handleClickDeleteFile} documentsDetails={[{ "file": file }]} documentIndex={0}></UploadFile> : ""
                                }
                            </div>
                            :
                            ""
                }
                <div className={props.rowFileType === "File" ? classes.sidedrawerFooter : classes.sidedrawerFooterJSON}>
                    <Divider />
                    <Button className={classes.drawerButton} onClick={handleSubmitUpdateDocument} variant="contained" color="primary" disabled={backdrop || disableSubmitButtonUpdate(props.rowFileType, file, documentJSON, uploadVersion) || invalidUserInput.length}>
                        {"UPDATE"}
                    </Button>
                    <Button autoFocus className={classes.drawerButton} onClick={props.closeDrawer('right', false)} variant="contained" disabled={backdrop}>
                        {"CANCEL"}
                    </Button>
                </div>
            </div>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
}