import React from "react";
import { useStyles } from "./styles/OrganizationStyles";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

export default function SearchOrg(props) {

    const classes = useStyles();
    const [searchKey, setSearchKey] = React.useState(props.searchKey.value === null ? {value : ""} : props.searchKey);

    const clearOrgSearchText = () => {
        setSearchKey({ value: "" });
        let reload = false;
        props.fetchOrganizationData(reload, props.orgRowsPerPage, props.orgPage);
    };

    let timeout = null;
    const handleOrgSearch = event => {
        const _val = event.target.value;
        setSearchKey({ value: _val });

        if (_val.length <= 2) {
            props.handleSetInitialOrgRow()
            return;
        }
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            let reload = false;
            props.fetchOrganizationData(reload, props.orgRowsPerPage, 0, props.orgOrderBy, props.orgOrder, _val);
        }, 200);
    };

    return (
        <div className={classes.searchBox}>
            <InputBase
                onChange={handleOrgSearch}
                value={searchKey.value}
                className={classes.input}
                placeholder={"Search Organizations"}
            />
            {
                searchKey.value.length ? (
                    <IconButton
                        size="medium"
                        aria-label="search"
                        onClick={clearOrgSearchText}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : (
                        ""
                    )
            }
            <IconButton
                size="medium"
                aria-label="search"
                disabled
            >
                <SearchIcon />
            </IconButton>
        </div>
    )
}