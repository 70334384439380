import React, { useState } from "react";
import networkService from "../../services/NetworkService"
import { channelUseStyles } from "../channel/styles/ChannelStyles";
import organizationService from "../../services/OrganizationService";
import channelService from "../../services/ChannelService";
import notify from '../../utils/notifier';
import { VALID_USER_INPUT_REGEX, ALPHA_NUM_SMALLCAP_NO_SPACE } from "../../utils/constants";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Input, FormControl, MenuItem } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel, Chip, ListItemText } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CreateChannelSideDrawer(props) {
    const channelClasses = channelUseStyles();
    const [network, setNetwork] = React.useState(props.networkName)
    const [backdrop, setBackdrop] = React.useState(false);
    const [channelOrgData, setChannelOrgData] = React.useState([]);
    const [noOfMembersChannel, setNoOfMembersChannel] = React.useState([]);
    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [selectedMembers, setSelectedMembers] = React.useState([]);
    const [render, setRender] = React.useState(true);
    const [channelValues, setChannelValues] = React.useState({
        channelId: "",
        channelName: "",
        channelDescription: "",
        channelPolicy: "",
        numOfEndorsingSignatures: "",
        members: "",
        memberNames: []
    });
    const [state, setState] = React.useState({
        checkedMembers: false,
    });

    const setChannelInitialState = () => {
        channelValues.channelId = ""
        channelValues.channelName = ""
        channelValues.members = ""
        channelValues.channelDescription = ""
        channelValues.channelPolicy = ""
        channelValues.numOfEndorsingSignatures = ""
        channelValues.memberNames = []
        setSelectedMembers([])
        setNetwork([])
        setState({
            checkedMembers: false
        })
    }


    const fetchData = () => {
        setBackdrop(true);
        let userOrgId = localStorage.getItem('_orgID');

        let networkId = props.networkId;
        // fetch organizations by networkId without peers and orderer org.
        let peersFlag = false;
        let includeOrderer = false;
        let query = {
            'peers': peersFlag,
            'orderer': includeOrderer
        }
        organizationService.getOrgsByNetwork(networkId, query).then(
            response => {
                if (typeof response.data != "string") {
                    const _data = response.data.filter(org => (org.id != userOrgId));
                    setChannelOrgData(_data);
                    let noOfMembersArr = [...Array(_data.length + 1).keys()].slice(1);
                    setNoOfMembersChannel(noOfMembersArr);
                    setBackdrop(false)
                }
            },
            error => {
                setBackdrop(false)
                notify({
                    type: "error",
                    message: "Error while fetching organizations data"
                });
            }
        );
    }

    const handleChangeMemberNames = (event) => {
        setChannelValues({ ...channelValues, ['memberNames']: event.target.value.map(member => member.id) });
        setSelectedMembers(event.target.value);
        if (render) {
            setRender(false);
        } else {
            setRender(true);
        }
    };


    React.useEffect(() => {
        fetchData();
    }, []);

    const handleChangeCheckbox = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    const handleChangeChannelValue = prop => (event) => {
        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setChannelValues({ ...channelValues, [prop]: event.target.value });
    };

    const handleChangeChannelName = prop => (event) => {
        if (!ALPHA_NUM_SMALLCAP_NO_SPACE.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setChannelValues({ ...channelValues, [prop]: event.target.value });
    };


    const buildCreateChannelRequestObj = () => {

        var formData = {};
        formData["channelName"] = channelValues.channelName;
        formData["members"] = channelValues.members;
        formData["channelDescription"] = channelValues.channelDescription;
        return formData;
    };

    const buildCreateChannelAPIreqObj = () => {
        var apiReqObj = {};
        let userOrgId = localStorage.getItem('_orgID');
        apiReqObj["channelName"] = channelValues.channelName;
        apiReqObj["channelDescription"] = channelValues.channelDescription;
        apiReqObj["channelEndorsingPolicy"] = channelValues.channelPolicy;
        apiReqObj["numOfEndorsingSignatures"] = channelValues.numOfEndorsingSignatures
        return apiReqObj;
    };

    const disableChannelCreateSubmitButton = () => {

        if (!network || !channelValues.channelName || !channelValues.channelDescription) {
            return true;
        }

        let disableSubmitButton = false;

        if (state.checkedMembers) {

            if (channelValues.members) {
                return channelValues.memberNames.length !== channelValues.members
            } else {
                disableSubmitButton = true
            }

        }

        return disableSubmitButton;
    };

    const handleSubmitCreateChannel = () => {
        let userOrgId = localStorage.getItem('_orgID');
        let formData = buildCreateChannelRequestObj();
        let createChannelApiReqObj = buildCreateChannelAPIreqObj();
        let networkId = props.networkId;
        let organizationId = userOrgId;
        let statusCode = null;
        let message = "Error creating the channel. "
        if (!formData) return;

        // setBackdrop(true);
        props.handleChannelName(channelValues.channelName);
        props.handleChannelDrawer(false, 1, false);


        //Create channel call
        channelService.createChannel(createChannelApiReqObj, networkId, organizationId)
            .then(response => {
                statusCode = response.status;

                let _data = response.data;
                message = "Created the channel successfully. "
                var apiReqObj = {};
                let channelId = _data.id;
                apiReqObj["organizationId"] = userOrgId;
                apiReqObj["invitedOrgs"] = [];
                for (let i = 0; i < channelValues.memberNames.length; i++) {
                    apiReqObj["invitedOrgs"].push(channelValues.memberNames[i]);
                }
                props.handleChannelDrawer(false, 2, false);
                if (apiReqObj["invitedOrgs"].length > 0) {
                    //Invite orgs call
                    props.handleChannelDrawer(false, 2, true);
                    channelService.inviteOrgsToChannel(apiReqObj, channelId)
                        .then(response => {

                            let _data = response.data;
                            let partialFailure = _data.some(i => !i.success);

                            if (partialFailure) {
                                let failedOrgs = _data.filter(i => !i.success).map(i => i.data.orgName);
                                message += "Successfully invited some of the orgs to the channel. Failed to invite the following orgs : " + failedOrgs.toString();
                            } else {
                                message += "Successfully invited all orgs to channel.";
                            }
                            props.fetchChannelsData();
                            notify({
                                type: "success",
                                message: message
                            });
                            props.handleChannelDrawer(false, 0, false);
                            setChannelInitialState();

                        }).catch(error => {
                            message += "Failed to invite the orgs to the channel."
                            // setBackdrop(false);
                            props.handleChannelDrawer(false, 0, false);
                            notify({
                                type: "error",
                                message: message
                            });
                        }
                        );

                } else {
                    props.fetchChannelsData();
                    props.handleChannelDrawer(false, 0, false);

                    notify({
                        type: "success",
                        message: message
                    });
                    setChannelInitialState();
                }

            }).catch(error => {
                // setBackdrop(false);
                props.handleChannelDrawer(false, 0, false);
                notify({
                    type: "error",
                    message: message
                });
            }
            );

    };


    return (
        <div className={channelClasses.list}>
            <div >
                <Toolbar>
                    <div className={channelClasses.drawerHeader}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={props.closeChannelDrawer("right", false)}
                            edge="start"
                        >
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                    </div>
                    <Typography variant="h6" noWrap>{"Create a private communication channel"}</Typography>
                </Toolbar>
                <Divider></Divider>
            </div>
            <br></br>

            <div style={{ overflow: "auto", maxHeight: "74vh" }}>

                <div className={channelClasses.flexItem}>
                    <div className={channelClasses.networkInfo}>
                        <Typography className={channelClasses.networkLabel} >
                            {'Network Name ' + String.fromCharCode(42)}
                        </Typography>
                        <Typography className={channelClasses.networkText} >
                            {network}
                        </Typography>
                    </div>
                </div>

                <div className={channelClasses.flexItem}>
                    <TextField
                        id="standard-basic"
                        key={"channelName"}
                        className={channelClasses.textField}
                        error={invalidUserInput.includes("channelName")}
                        label={"Channel Name"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={channelValues.channelName}
                        onChange={handleChangeChannelName("channelName")}
                    />
                    {/* <FormHelperText style={{ color: '#f44336', paddingBottom: '10px' }}>{validName ? '' : 'No spaces allowed in name'}</FormHelperText> */}
                </div>

                <div className={channelClasses.flexItem}>
                    <TextField
                        id="standard-basic"
                        key={"channelDescription"}
                        className={channelClasses.textField}
                        error={invalidUserInput.includes("channelDescription")}
                        label={"Channel Description"}
                        margin="normal"
                        rows="2"
                        required
                        fullWidth
                        value={channelValues.channelDescription}
                        onChange={handleChangeChannelValue("channelDescription")}
                    />
                </div>

                <div className={channelClasses.flexItem}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.checkedMembers}
                                onChange={handleChangeCheckbox('checkedMembers')}
                                value="checkedMembers"
                                color="primary"
                            />
                        }
                        label="Invite members to this channel"
                    />

                </div>

                {state.checkedMembers ?
                    <div>
                        <div className={channelClasses.flexItem}>
                            <TextField
                                id="standard-multiline-static"
                                key="noOfMembers"
                                select
                                required
                                label="Select no of members"
                                className={channelClasses.textField}
                                margin="normal"
                                fullWidth
                                value={channelValues.members}
                                onChange={handleChangeChannelValue("members")}
                            >
                                {noOfMembersChannel.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>

                        {
                            ((selectedMembers.length != channelValues.members) && (channelValues.members != '')) ? (
                                <div className={channelClasses.warningStyle}>
                                    <h3>Warning : select {channelValues.members} members only</h3>
                                </div>
                            ) : (
                                    ""
                                )
                        }

                        <div className={channelClasses.flexItem}>
                            <FormControl className={channelClasses.formControl}>
                                <InputLabel>Members *</InputLabel>
                                <Select
                                    multiple
                                    className={channelClasses.multiselect}
                                    value={selectedMembers}
                                    onChange={handleChangeMemberNames}
                                    input={<Input />}
                                    renderValue={selected => (
                                        <div className={channelClasses.chips}>
                                            {selected.map(value => (
                                                <Chip key={value.id} label={value.organizationName} className={channelClasses.chip} />
                                            ))}
                                        </div>
                                    )}
                                >
                                    {channelOrgData.map(option => (
                                        <MenuItem key={option.id} value={option}>
                                            <Checkbox checked={selectedMembers.some(_option => _option['id'] === option.id)} />
                                            <ListItemText primary={option.organizationName} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    :
                    ""
                }

                <br></br>
            </div>
            <div className={channelClasses.sidedrawerFooter}>
                <Divider></Divider>
                <Button className={channelClasses.drawerButton} onClick={handleSubmitCreateChannel} color="primary" variant="contained" disabled={backdrop || disableChannelCreateSubmitButton() || invalidUserInput.length}>
                    {"SUBMIT"}
                </Button>
                <Button autoFocus className={channelClasses.drawerButton} onClick={props.closeChannelDrawer('right', false)} variant="contained" disabled={backdrop}>
                    {"CANCEL"}
                </Button>
            </div>


            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}
