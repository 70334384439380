import React from "react";
import { useStyles } from "./styles/ChannelInformationStyles";
import { VALID_USER_INPUT_REGEX } from "../../utils/constants";
import IconButton from "@material-ui/core/IconButton";
import channelService from "../../services/ChannelService";
import Paper from '@material-ui/core/Paper';
import notify from "../../utils/notifier";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';



export default function ChannelInformationNavigation(props) {

    const classes = useStyles();

    const [invalidUserInput, setInvalidUserInput] = React.useState([]);
    const [channelName, setChannelName] = React.useState(props.channelName);
    const [channelNameCopy, setChannelNameCopy] = React.useState(props.channelName);
    const [channelDescription, setChannelDescription] = React.useState(props.channelDescription);
    const [channelDescriptionCopy, setChannelDescriptionCopy] = React.useState(props.channelDescription);
    const [channelNameFocus, setChannelNameFocus] = React.useState(false);
    const [channelDescriptionFocus, setChannelDescriptionFocus] = React.useState(false);
    const [backdrop, setBackdrop] = React.useState(false);


    const setInitialState = () => {
        setInvalidUserInput([]);
    }

    React.useEffect(() => {

    }, []);

    const validateFields = (...fields) =>
    {
        let errorFields = [];
        fields.forEach(field =>
        {
            if (!field) {
                errorFields.push(field);
            }
        });
        return errorFields;
    };

    const buildRequestObjEditChannel = (_channelName, _channelDescription) =>
    {
        let invalidFields = [];
        invalidFields = validateFields(_channelName);

        if (invalidFields.length) {
            setBackdrop(false);
            notify({
                type: "error",
                message:
                    "Invalid Fields(" +
                    invalidFields.length +
                    "): Please provide valid input."
            });
            return;
        }
        var formData = {};
        formData["channelName"] = _channelName;
        formData["channelDescription"] = _channelDescription;
        formData["organizationId"] = localStorage.getItem("_orgID")
        formData["orgRole"] = localStorage.getItem("_orgRole")
        formData["userRole"] = localStorage.getItem("_userRole")
        return formData;
    };

    const handleSubmitEditChannel = (_channelName, _channelDescription) =>
    {
        setBackdrop(true);
        let requestObj = buildRequestObjEditChannel(_channelName, _channelDescription);
        let statusCode = null;
        if (!requestObj) return;
        channelService.updateChannel(props.channelId, requestObj).then(
            response =>
            {
                statusCode = response.status;
                notify({
                    type: "success",
                    message: "Successfully updated channel"
                });
                props.fetchData();
                setInitialState();
                setBackdrop(false);
                setChannelName(_channelName);
                setChannelNameCopy(_channelName);
                setChannelDescription(_channelDescription);
                setChannelDescriptionCopy(_channelDescription);
                var newState = {
                    channelValue: props.channelValue, channelName: _channelName, channelCode: props.channelCode, channelId: props.channelId,
                    channelDescription: _channelDescription, networkId: props.networkId, networkName: props.networkName
                }
                props.history.push({ pathname: '/channel/' + _channelName, state: newState });
            },
            error =>
            {
                setBackdrop(false);
                notify({
                    type: "error",
                    message: "Error while updating channel information"
                });
            }
        );
    };


    const handleChangeChannelName = prop => (event) =>
    {
        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setChannelName(event.target.value);
        setChannelNameFocus(true);
    }

    const [textBreadCrumbsInput, setTextBreadCrumbsInput] = React.useState(classes.textBreadCrumbsInput);

    const handleChannelNameSave = () =>
    {
        if (!invalidUserInput.includes("channelName")) {
            handleSubmitEditChannel(channelName, channelDescriptionCopy);
        }
        setChannelNameFocus(false);
    }

    const handleChannelNameUndo = () =>
    {
        setChannelName(channelNameCopy);
        setChannelNameFocus(false);
        setTextBreadCrumbsInput(classes.textBreadCrumbsInput);
    }

    const handleMouseEnterChannelName = () =>
    {
        setTextBreadCrumbsInput(classes.textBreadCrumbsInputOnHover);
    }

    const handleMouseLeaveChannelName = () =>
    {
        if (channelName === channelNameCopy) {
            setTextBreadCrumbsInput(classes.textBreadCrumbsInput);
        }
    }

    const handleChangeChannelDescription = prop => (event) =>
    {
        if (!VALID_USER_INPUT_REGEX.test(event.target.value)) {
            if (!invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput, prop]);
            }
        } else {
            if (invalidUserInput.includes(prop)) {
                setInvalidUserInput([...invalidUserInput.slice(0, invalidUserInput.indexOf(prop)), ...invalidUserInput.slice(invalidUserInput.indexOf(prop) + 1)]);
            }
        }
        setChannelDescription(event.target.value);
        setChannelDescriptionFocus(true);
    }

    const [channelDescriptionInput, setChannelDescriptionInput] = React.useState(classes.channelDescriptionInput);

    const handleChannelDescriptionSave = () =>
    {
        if (!invalidUserInput.includes("channelName")) {
            handleSubmitEditChannel(channelNameCopy, channelDescription);
        }
        setChannelDescriptionFocus(false);
    }

    const handleChannelDescriptionUndo = () => {
        setChannelDescription(channelDescriptionCopy);
        setChannelDescriptionFocus(false);
        setChannelDescriptionInput(classes.channelDescriptionInput);
    }

    const handleMouseEnterChannelDescription = () =>
    {
        setChannelDescriptionInput(classes.channelDescriptionInputOnHover);
    }

    const handleMouseLeaveChannelDescription = () => {
        if (channelName === channelNameCopy) {
            setChannelDescriptionInput(classes.channelDescriptionInput);
        }
    }


    return (
        <div className={classes.channelInformationNavigationDiv}>
            <div className={classes.navigation}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.channelInfo}>
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: "/networks" }}>
                        <a color="primary" className={classes.a}>
                            Networks
                        </a>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: `/networks/${props.networkId}`, state: { "networkId": props.networkId, "networkName": props.networkName, "networkDescription": props.networkDescription } }}>
                        <a color="primary" className={classes.a}>
                            {props.networkName}
                        </a>
                    </Link>
                    <Link style={{ textDecoration: 'none' }} to={{ pathname: `/networks/${props.networkId}`, state: { "networkId": props.networkId, "networkName": props.networkName, "networkDescription": props.networkDescription, "tabValue": 1 } }}>
                        <a color="primary" className={classes.a}>
                            Channels
                        </a>
                    </Link>
                    <div className={classes.textBreadCrumbDiv}>
                        <input
                            id="standard-basic"
                            className={textBreadCrumbsInput}
                            margin="normal"
                            rows="2"
                            fullWidth
                            autoComplete='off'
                            value={channelName}
                            onMouseEnter={handleMouseEnterChannelName}
                            onMouseLeave={handleMouseLeaveChannelName}
                            onChange={handleChangeChannelName("channelName")} />
                    </div>
                </Breadcrumbs>
                {
                    invalidUserInput.includes("channelName") ?
                        <p className={classes.invalidChanneNameValueText}>
                            invalid character found
                        </p>
                    :
                    <div className={(channelNameFocus && (channelName != channelNameCopy)) ? classes.textBreadCrumbEditSaveButtonDiv : classes.textBreadCrumbEditSaveButtonDivNone}>
                        <IconButton className={classes.breadCrumbButton} onClick={handleChannelNameSave}>
                            <DoneIcon fontSize="small" />
                        </IconButton>
                        <IconButton className={classes.breadCrumbButton} onClick={handleChannelNameUndo}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                }
            </div>
            <section className={classes.channelInfo}>
                <Grid container spacing={0} >
                    <Grid item xs={10} sm={11}>
                        <Paper className={classes.channelDescriptionGrid}>
                            <div className={classes.paperChannelDescription}>
                                <input
                                    id="standard-basic"
                                    className={channelDescriptionInput}
                                    error={invalidUserInput.includes("channelDescription")}
                                    margin="normal"
                                    rows="2"
                                    fullWidth
                                    autoComplete='off'
                                    value={channelDescription}
                                    onMouseEnter={handleMouseEnterChannelDescription}
                                    onMouseLeave={handleMouseLeaveChannelDescription}
                                    onChange={handleChangeChannelDescription("channelDescription")} />
                            </div>
                            {
                                invalidUserInput.includes("channelDescription") ?
                                    <p className={classes.invalidChanneDescriptionValueText}>
                                        invalid character found
                                    </p>
                                :
                                <div className={(channelDescriptionFocus && (channelDescription != channelDescriptionCopy)) ? classes.channelDescriptionEditButtonDiv : classes.channelDescriptionEditButtonDivNone}>
                                    <IconButton className={classes.breadCrumbButton} onClick={handleChannelDescriptionSave}>
                                        <DoneIcon fontSize="small" />
                                    </IconButton>
                                    <IconButton className={classes.breadCrumbButton} onClick={handleChannelDescriptionUndo}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </div>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={2} sm={1}>
                        <Paper className={classes.paperChannelBlockInfo}><h1 style={{ fontSize: "40px", lineHeight: "15px" }}>{props.numBlocks} </h1>Blocks</Paper>
                    </Grid>
                </Grid>
            </section>
            <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 100 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}