import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../themes/lightTheme";

const useStyles = makeStyles({
  list: {
    width: "60vw",
    display: "block"
  },
  docOptionDiv: {
    display: "block",
    maxHeight: "85vh"
  },
  docFormDiv: {
    display: "block",
    overflow: "auto",
    maxHeight: "80vh"
  },
  root: {
    width: "100%",
    background: "white"
  },
  borderMain: {
    marginLeft: "-10px",
    marginRight: "-10px",
    marginTop: "-1px",
  },
  tableWrapper: {
    width: "100%",
    maxHeight: "70vh",
    overflow: "auto",
    fontFamily: "courier"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableColumnLabel: {
    marginLeft: theme.spacing(0.2),
  },
  actionDiv: {
    display: "flex",
    justifyContent: "center"
  },
  docRevisionRow: {
    display: "flex",
    justifyContent: "space-around"
  },
  privateMemberList: {
    padding: theme.spacing(1)
  },
  sharedShipments: {
    padding: theme.spacing(1)
  },
  sharedShipmentsUpdateDoc: {

  },
  missingDocTypeDiv: {
    display: "flex",
    justifyContent: "center"
  },
  buttonDownload: {
    background: "none",
    padding: 0,
    fontSize: "11px"
  },
  viewOptionFilterDiv: {
    display: "flex",
    paddingLeft: "20px"
  },
  viewOption: {
    width: '40%',
    display: "flex",
    flexDirection: 'row',
    float: 'left',

  },
  viewOptionFilterRightDiv: {
    paddingRight: theme.spacing(2),
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  viewText: {
    fontSize: '15px'
  },
  tableTitleDiv: {
    display: "flex",
    paddingLeft: "20px"
  },
  tableHeadRight: {
    float: "right",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  tableTitle: {

  },
  paper: {
    width: "45%",
    color: theme.secondaryColor,
    background: theme.primaryColor
  },
  input: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    flex: 1
  },
  searchIcon: {
    marginTop: theme.spacing(1),
  },
  searchBox: {
    borderBottom: "0.8px solid #00000021",
    display: 'flex',
    height: theme.spacing(5),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  },
  flexItem: {
    flex: 1,
    padding: '0px 30px 0px',
  },
  docTypeNameDiv: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  docTypeDiv: {
    flex: 1,
    display: "flex",
    paddingLeft: "30px",
    paddingRight: "40px"
  },
  docNameDiv: {
    flex: 1
  },
  docTypeToolTipDiv: {
    paddingTop: theme.spacing(2.5)
  },
  docNameTextField: {
    paddingRight: theme.spacing(4),
    marginTop: -theme.spacing(0.25)
  },
  documentDescriptionTextField: {
    marginTop: -theme.spacing(1)
  },
  textField: {
    // paddingRight: theme.spacing(4)
  },
  shipmentRefNumTextField: {
    marginTop: theme.spacing(0),
    width: "60%"
  },
  shipmentRefNumAutoCompleteTextField: {
    marginTop: theme.spacing(0),
    width: "60%"
  },
  shipmentRefNumAutoCompleteTextFieldUpdateDoc: {
    marginTop: theme.spacing(0),
    width: "100%",
    padding: "8px 8px 16px"
  },
  shipmentShareInfo: {
    paddingBottom: theme.spacing(2)
  },
  sharingOptionForm: {
    width: "100%",
    marginBottom: theme.spacing(1)
  },
  sharingOptionDiv: {
    display: "block",
    padding: '8px 30px 8px',
  },
  orgMemberDiv: {
    width: "100%",
    marginBottom: theme.spacing(1),
    marginTop: -theme.spacing(1.5)
  },
  orgMemberDocPageDiv: {
    width: "100%",
    marginTop: theme.spacing(0),
    padding: '8px 8px 16px',
  },
  orgMemberFormControl: {
    width: "100%",
  },
  orgMemberUpdateDocDiv: {
    padding: '4px 30px 16px',
  },
  memberListTitle: {
    fontSize: "0.875rem",
    fontWeight: "700",
    textAlign: 'left'
  },
  memberListItem: {

  },
  memberListItemIcon: {
    color: "#000000",
    display: 'inline-flex',
    minWidth: theme.spacing(4)
  },
  memberListItemText: {
    fontSize: "0.875rem"
  },
  documentNameDiv: {
    display: "flex",
    justifyContent: "center"
  },
  privateDocTooltipDiv: {
    paddingTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.5)
  },
  button: {
    paddingRight: theme.spacing(2.5)
  },
  UploadButtonDiv: {
    height: "3rem",
    paddingTop: "1rem",
    paddingRight: "1rem",
    // width: "100%"
  },
  UploadButton: {
    float: "right"
  },
  drawerButton: {
    margin: theme.spacing(2),
    float: "right",
  },
  sidedrawerFooter: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    backgroundColor: "#ffffff",
  },
  sidedrawerFooterJSON: {
    width: "100%",
    backgroundColor: "#ffffff",
  },
  tableBorder: {
    border: "0.8px solid #00000021",
  },
  tableHeadRow: {
    fontWeight: "700"
  },
  tableContainer: {
    padding: "20px",
    paddingTop: "30px"
  },
  tableFooterDiv: {
    display: "flex"
  },
  tableFooterNoteDiv: {
    width: "100%",
    paddingLeft: "1.5rem",
    marginTop: "0.25rem"
  },
  tablePaginationDiv: {
    marginTop: "-0.75rem",
    width: "100%",
    justifyContent: "flex-end",
    marginRight: theme.spacing(1),
  },
  noRecordsRow: {
    background: "#ffffff"
  },
  dividerStyling: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  hfivestyle: {
    fontSize: "0.8em",
    marginBlockStart: "0",
    marginBlockEnd: "0",
    fontWeight: "200"
  },
  hsixstyle: {
    display: "initial",
    fontSize: "0.9em",
    marginBlockStart: "0",
    marginBlockEnd: "0"
  },
  printIcon: {
    verticalAlign: 'bottom'
  },
  smallTextIcon: {
    fontSize: 'small',
    color: 'gray',
    marginBottom: "-2px"
  },
  listItemText: {
    fontWeight: "bold"
  },
  treeViewDiv: {
    paddingBottom: theme.spacing(1.5)
  },
  treeViewCollapsableTabs: {
    background: "#ffffff",
    border: "0.5px solid #00000021",
    fontWeight: "bold",
    borderRadius: "2px",
    boxShadow: "2px 2px 10px -5px",
    height: theme.spacing(10)
  },
  treeViewTableCell: {
    border: "none",
    width: "8%"
  },
  treeViewTableCellInfoDiv: {
    display: "block"
  },
  treeViewTableCellInfoLabel: {
    fontSize: "0.8rem"
  },
  treeViewTableCellInfoValue: {
    fontWeight: "700"
  },
  listItemTextPrimaryDiv: {
    display: "flex"
  },
  listItemTextPrimaryDivInfoIconDiv: {
    marginRight: "1vw",
    paddingTop: theme.spacing(3.5)
  },
  listItemTextPrimaryDivInfoIcon: {
  },
  listItemTextPrimaryDivTypo: {
    width: "100%"
  },
  reqDocumentsDiv: {
    marginTop: -theme.spacing(2)
  },
  reqDocumentsButton: {
    marginBottom: theme.spacing(0.5)
  },
  reqDocumentsDialog: {
    '& .MuiDialog-paperWidthSm': {
      width: theme.spacing(45)
    }
  },
  reqDocumentsDialogListItemText: {
    display: "flex"
  },
  reqDocumentsDialogListItemTextCheckIcon: {
    marginLeft: theme.spacing(3)
  },
  formControl: {
    paddingRight: "0.5rem",
    minWidth: 180,
    maxWidth: 180,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  filterButton: {
    marginTop: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    float: "right"
  },
  inputTab: {

  },
  searchTab: {
    lineHeight: "0px",
    borderBottom: "0.8px solid #00000021"
  },
  floatRight: {
    float: "right"
  },
  filterDiv: {
    display: "flex"
  },
  clearFilterButtonDiv: {
    minHeight: "5vh",
    paddingTop: "1rem",
    // minWidth: '6vw'
  },
  clearFilterButton: {

  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    paddingRight: theme.spacing(4)
  },
  addMemberButtonDiv: {
    justifyContent: "flex-end",
    paddingTop: theme.spacing(1)
  },
  docInfoDiv: {
    paddingBottom: theme.spacing(1)
  },
  docInfoPaper: {
    border: "0.5px solid #00000021",
    borderRadius: "2px",
    boxShadow: "2px 2px 10px -5px",
  },
  removeDocButtonDiv: {
    padding: "0px 32px 0px",
    minHeight: theme.spacing(5)
  },
  addButton: {
    fontFamily: "timesnewroman",
    textAlign: "center",
    display: "flex",
  },
  shareButton: {
    textAlign: "center",
    display: "flex",
  },
  removeButton: {
    fontFamily: "timesnewroman",
    textAlign: "center",
    display: "flex",
  },
  buttonTooltip: {
    float: "right"
  },
  addDocButtonTooltip: {
    float: "right"
  },
  shipmentNumberAutoCompleteTooltip: {
    float: "right",
    width: "100%"
  },
  hiddenField: {
    visibility: "hidden",
    padding: "20px",
    maxHeight: theme.spacing(3)
  },
  fileUploadWrapper: {
    background: "#f8f8f8",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
  },
  fileCriteriaLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  shipmentRefNumLabel: {
    background: "#f8f8f8",
    padding: "8px 30px 8px",
    margin: "8px 0px 24px"
  },
  orgMembersSelect: {

  },
  smallText: {
    display: "flex",
    fontSize: 'small',
    color: 'gray',
    float: "left"
  },
  smallTextAddShipments: {
    display: "flex",
    fontSize: 'small',
    color: 'gray',
    float: "left",
    paddingBottom: theme.spacing(1)
  },
  smallTextAddShipmentsDiv: {
    marginTop: theme.spacing(0),
    width: "100%",
  },
  removeDocButtonTooltip: {
    float: "right",
    marginTop: -theme.spacing(3.5)
  },
  warningDiv: {
    display: "flex"
  },
  warningIconDiv: {
    paddingRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.25)
  },
  warningIcon: {
    fontSize: '0.875rem',
    color: 'orange'
  },
  warningText: {
    fontWeight: '600',
    fontSize: '0.875rem',
    color: 'orange'
  },
  uploadVersionCheckboxDiv: {
    width: "max-content"
  },
  shipmentRefAndChannelDiv: {
    justifyContent: "space-between",
    display: "flex",
    padding: '0px 30px 8px',
  },
  channelFormControl: {
    width: "35%"
  },
  previewButton: {
    display: 'flex',
    marginLeft: 'auto'
  },
  buttonPreview: {
    background: "#D3D3D3",
    padding: 0,
    fontSize: "11px",
    float: "right",
    marginLeft: "10px",
    marginTop: "0.75rem"
  },
  uploadedFileNameDiv: {
    display: "flex",
    paddingBottom: theme.spacing(1)
  },
  fileIconDiv: {
    paddingRight: theme.spacing(1.5)
  },
  fileNameTextDiv: {

  }
});
export default useStyles;