import MenuItem from '@material-ui/core/MenuItem';
import React from "react";
import notify from '../../utils/notifier';

export const _columns = [
    { id: "organizationName", label: "Name", align: "left", minWidth: 100, sortable: true },
    { id: "organizationDomainName", label: "Domain", align: "left", minWidth: 100, sortable: true },
    { id: "organizationDescription", label: "Description", align: "center", minWidth: 100 },
    { id: "peerCount", label: "No. of Peers", align: "center", minWidth: 50 },
    { id: "usersCount", label: "No. of Users", align: "center", minWidth: 50 },
    { id: "networks", label: "Participated Networks", align: "center", minWidth: 50 },
    // { id: "action", label: "Action", align: "center", minWidth: 50 },
    // { id: "edit", label: "Action", align: "center", minWidth:50}
];

export const _columnsWithRoles = [
    { id: "organizationName", label: "Name", minWidth: 100, sortable: true },
    { id: "organizationDomainName", label: "Domain", minWidth: 170, sortable: true },
    { id: "organizationRole", label: "Role Type", minWidth: 170, sortable: true },
    { id: "peerCount", label: "No. of Peers", minWidth: 50 },
    { id: "moreOption", label: "", maxWidth: 1, sortable: false}
];

export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    PaperProps: {
        style: {
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            },
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const disableSubmitButton = (values) => {
    if (values.members) {
        for (let i = 0; i < values.members; i++) {
            if (values.inviteOrgs[i] === undefined || values.roles[i] === undefined) {
                return true;
            }
        }
        return false;
    }
    return true;
};

export const disableSubmitButtonOrgWithoutNetwork = (values, outsidePeerNames) => {
    let disableSubmitButtonValue = false
    if (values.orgName && values.orgDomainName && values.orgCaPassword && values.orgCaUsername && values.couchDBPassword && values.couchDBUsername) {
        for (let i = 0; i < 2; i++) {
            if (outsidePeerNames[i]) {
                if (!outsidePeerNames[i].length) {
                    disableSubmitButtonValue = true
                }
            } else {
                disableSubmitButtonValue = true
            }
        }
        return disableSubmitButtonValue;
    }
    return true;
};

export function getExistingOrganizations(dropdownOrganizations, selectedOrgs, index) {
    let table = [];
    for (let i = 0; i < dropdownOrganizations.length; i++) {

        let dropdownOrganization = dropdownOrganizations[i];
        if (dropdownOrganization.id !== selectedOrgs[index] && selectedOrgs.includes(dropdownOrganization.id)) {

        }
        else {
            table.push(
                <MenuItem key={dropdownOrganization.id} value={dropdownOrganization.id} >
                    {dropdownOrganization.organizationName}
                </MenuItem>
            )
        }
    }
    return table
}

const validateFields = (...fields) => {
    let errorFields = [];
    fields.forEach(field => {
        if (!field) {
            errorFields.push(field);
        }
    });
    return errorFields;
};

export const buildRequestObjForAddingOrgNoNetwork = (values) => {

    var formData = {};
    formData["organizationName"] = values.orgName;
    formData["organizationDomainName"] = values.orgDomainName;
    formData["organizationDescription"] = values.organizationDescription;
    formData["orgPeers"] = [];
    for (let i = 0; i < values.peerNames.length; i++) {
        let peerObj = {};
        peerObj["peerName"] = values.peerNames[i];
        peerObj["peerDomainName"] = values.peerNames[i] + "." + values.orgDomainName;
        formData["orgPeers"].push(peerObj);
    }
    formData["orgCaUsername"] = values.orgCaUsername;
    formData["orgCaPassword"] = values.orgCaPassword;
    formData["couchDBUsername"] = values.couchDBUsername;
    formData["couchDBPassword"] = values.couchDBPassword;
    return formData;
};

export const buildRequestObjForAddingOrgWithNetwork = (values) => {

    var formData = {};
    formData["organizationsArray"] = [];
    for (let i = 0; i < values.members; i++) {
        let peerObj = {};
        peerObj["organizationId"] = values.inviteOrgs[i];
        peerObj["organizationRole"] = values.roles[i];
        formData["organizationsArray"].push(peerObj);
    }
    return formData;
};

export const disableAddPeerButton = (values) => {
    if (values.peerNames.length === 2) {
        return true
    }
    if (values.peerNames && values.peerPorts) {
        return false;
    }
    return true;
};
