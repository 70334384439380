import React, { useRef } from "react";
import useStyles from '../components/documents/styles/DocumentStyles';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ListItemText, ListItemIcon, List, ListItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import notify from "../utils/notifier";
import shipmentService from "../services/ShipmentService";
import documentService from "../services/DocumentService";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Tooltip from '@material-ui/core/Tooltip';
import { Multiselect } from 'multiselect-react-dropdown';
export default function AttachDocumentToShipments(props) {

    const classes = useStyles();
    const [backdrop, setBackdrop] = React.useState(false);
    const [sharedShipmentList, setSharedShipmentList] = React.useState([]);
    const [nonSharedShipments, setNonSharedShipments] = React.useState([]);
    const [selectedShipments, setSelectedShipments] = React.useState([]);
    const [render, setRender] = React.useState(false);
    const [showComponent, setShowComponent] = React.useState(false);

    function renderAgain() {
        if (render) {
            setRender(false)
        } else {
            setRender(true)
        }
    }

    React.useEffect(() => {
        shipmentService.getShipmentsByDocumentId(props.documentId).then(
            response => {
                let data = response.data;
                setSharedShipmentList(data);
                let _sharedShipmentIds = data.map(shipment => shipment.id);

                let orgId = parseInt(localStorage.getItem('_orgID'));

                shipmentService.getShipmentsSharedByOrgID(orgId).then(
                    response => {
                        let data = response.data[0];
                        let _nonSharedShipment = data.filter(shipment => !_sharedShipmentIds.includes(shipment.id));
                        _nonSharedShipment = _nonSharedShipment.map((row) => ({ name: getShipmentDropdownText(row), ...row }));
                        setNonSharedShipments(_nonSharedShipment);
                        setShowComponent(true);
                    },
                    error => {
                        notify({
                            type: "error",
                            message: `Error while getting list of all shipments`
                        });
                    }
                )
            },
            error => {
                notify({
                    type: "error",
                    message: `Error while getting list of shipments where document : ${props.documentName} is uploaded`
                });
            }
        )
    }, []);


    const multiselectRef = useRef();
    function resetValues() {
        multiselectRef.current.resetSelectedValues();
    }

    const handleAttachShipment = (documentId) => {
        setBackdrop(true);

        let requestObj = {
            "shipmentIds": selectedShipments
        }

        documentService.attachShipmentsToDocument(documentId, requestObj).then(
            response => {
                setBackdrop(false);
                let _message = "Document attached successfully with new shipments";
                let _newAttachedShipments = nonSharedShipments.filter(shipment => selectedShipments.includes(shipment.id));
                let _newNonSharedShipments = nonSharedShipments.filter(shipment => !selectedShipments.includes(shipment.id));
                setSelectedShipments([]);
                setSharedShipmentList([...sharedShipmentList, ..._newAttachedShipments]);
                setNonSharedShipments(_newNonSharedShipments);
                if (_newNonSharedShipments.length > 0)
                    resetValues();
                renderAgain();
                notify({
                    type: "success",
                    message: _message
                });

            },
            error => {
                setBackdrop(false);
                let _message = "Error while attaching the document to newly added shipments";
                notify({
                    type: "error",
                    message: _message
                });
            }
        );

    }


    const handleChangeMultiSelectShipmentNumber = prop => (newValue) => {
        let _newSelectedShipmentIds = newValue.map(option => option.id);
        setSelectedShipments(_newSelectedShipmentIds);
    }

    function getShipmentDropdownText(shipmentInfo) {
        let _text = "";
        if (shipmentInfo.shipmentNumber) {
            _text += shipmentInfo.shipmentNumber;
        }
        if (shipmentInfo.shipmentReference) {
            _text = _text + " (" + shipmentInfo.shipmentReference + ")";
        }

        return _text;
    }

    function handleSearch(inputvalue) {

        if (inputvalue.length > 2) {
            shipmentService.getShipmentsByDocumentId(props.documentId).then(response => {
                let data = response.data;
                setSharedShipmentList(data);
                let _sharedShipmentIds = data.map(shipment => shipment.id);


                let orgId = parseInt(localStorage.getItem('_orgID'));

                shipmentService.getShipmentsSharedByOrgID(orgId).then(
                    response => {
                        let data = response.data[0];
                        let _nonSharedShipment = data.filter(shipment => !_sharedShipmentIds.includes(shipment.id));
                        if (_nonSharedShipment.length) {
                            _nonSharedShipment = _nonSharedShipment.map((row) => ({ name: getShipmentDropdownText(row), ...row }));
                            setNonSharedShipments(_nonSharedShipment);
                        }

                        setShowComponent(true);
                    },
                    error => {
                        notify({
                            type: "error",
                            message: `Error while getting list of all shipments`
                        });
                    }
                )
            },
                error => {
                    notify({
                        type: "error",
                        message: `Error while getting list of shipments where document : ${props.documentName} is uploaded`
                    });
                }
            )
        }
    }



    return (

        showComponent ?

            <div className={classes.sharedShipments}>
                {
                    sharedShipmentList && sharedShipmentList.length === 0 ?
                        <Typography variant="h6" className={classes.memberListTitle}>
                            Document is not attached to any shipment
                    </Typography>
                        :
                        <div>
                            <Typography variant="h6" className={classes.memberListTitle}>
                                Document is attached to following shipment(s):
                    </Typography>
                            <List dense={true}>
                                {sharedShipmentList.map((shipmentInfo) => (
                                    <ListItem className={classes.memberListItem}>
                                        <ListItemIcon className={classes.memberListItemIcon}>
                                            <LocalShippingIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={getShipmentDropdownText(shipmentInfo)}
                                            className={classes.memberListItemText}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                }
                {
                    nonSharedShipments.length === 0 ?
                        <span className={classes.smallTextAddShipments}>
                            {
                                backdrop ? "Fetching shipments ..." : "*Document is shared with all the shipments."
                            }
                        </span>
                        :
                        <div className={classes.shipmentRefNumAutoCompleteTextFieldUpdateDoc}>
                            {
                                <Multiselect
                                    options={nonSharedShipments}
                                    onSelect={handleChangeMultiSelectShipmentNumber()}
                                    onRemove={handleChangeMultiSelectShipmentNumber()}
                                    displayValue="name"
                                    placeholder="Add more shipments"
                                    closeIcon="circle2"
                                    hidePlaceholder="true"
                                    onSearch={(inputvalue) => handleSearch(inputvalue)}
                                    ref={multiselectRef}
                                    style={{ searchBox: { border: "none", "border-bottom": "0.8px solid #00000021", "border-radius": "0px" }, chips: { background: "#e0e0e0", color: "#343a40", fontfamily: 'LatoLight' } }}
                                />
                            }

                            {
                                selectedShipments.length ?
                                    <div className={classes.addMemberButtonDiv}>
                                        <Tooltip className={classes.addDocButtonTooltip} title="Attach to selected shipment(s)">
                                            <Button autoFocus className={classes.shareButton} color="primary"
                                                onClick={() => handleAttachShipment(props.documentId)}>
                                                {"Attach"}
                                            </Button>
                                        </Tooltip>
                                    </div> : ""
                            }
                        </div>
                }
                <Backdrop open={backdrop} color="primary" style={{ color: "primary", zIndex: 10000 }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div> : <div></div>
    )
}