import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FontAwesome from 'react-fontawesome';
import Util from "../../utils/Util";
import theme from "../../themes/lightTheme";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import '../../css/style.css';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import { Divider } from "@material-ui/core";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import notify from "../../utils/notifier";
import chaincodeService from "../../services/ChaincodeService"
import Skeleton from 'react-loading-skeleton';
import JSONTree from 'react-json-tree';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';

export const userPrefs = {
    rowsPerPageOptions: [10, 25, 100]
};

const writeTheme = {
    base00: '#ffffff',
    base01: '#2e2f30',
    base02: '#515253',
    base03: '#737475',
    base04: '#959697',
    base05: '#b7b8b9',
    base06: '#dadbdc',
    base07: '#fcfdfe',
    base08: '#e31a1c',
    base09: '#e6550d',
    base0A: '#dca060',
    base0B: '#31a354',
    base0C: '#80b1d3',
    base0D: '#3182bd',
    base0E: '#756bb1',
    base0F: '#b15928'
};

const useStyles = makeStyles({
    list: {
        width: "90vw",
    },
    sideBar: {
        width: "30vw",
    },
    paperText: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    iconAlign: {
        verticalAlign: "middle"

    },
    root: {
        width: "100%",
        background: "white"
    },
    cardWith: {
        //width: "60vw",
    },
    card: {
        cursor: "pointer"
    },
    typography: {
        padding: theme.spacing(2),
    },
    evenHeader: {
        background: "#F8F8F8"
    },
    h3padding: {
        paddingLeft: theme.spacing(2),
        marginBottom: "5px",
        marginTop: "5px",
        minHeight: "64px"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerIcon: {
        paddingLeft: "4%",
        "&:hover": {
            //you want this to be the same as the backgroundColor above
            background: 'none'
        }

    },

    JSONtree: {
        '& ul': {
            backgroundColor: 'transparent !important',
            color: '#fff'
        }
    },

    smallText: {
        fontSize: 'small',
        color: 'gray'
    },
    smallTextIcon: {
        fontSize: 'small',
        color: 'gray',
        marginBottom: "-2px"
    },


});

const reads = {
    color: '#2AA233'
};
const writes = {
    color: '#DD8016'
};

export default function EventTransactionInfo(props) {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [transDetails, setTransDetails] = React.useState(null);
    const [eventData, setEventData] = React.useState(null);
    const [xsValue, setXs] = useState(12);
    const [tampered, setTampered] = useState(null);
    const [showTransactionsInfo, setShowTransactionsInfo] = useState(false);
    const open = Boolean(anchorEl);
    const fetchData = () => {
        let eventId = props.eventId;
        let shipmentId = props.shipmentId;
        chaincodeService.getEventById(shipmentId, eventId).then(
            response => {
                const _data = response.data;
                setEventData(_data["details"])
                setTransDetails(_data["tx_details"]);
                setTampered(_data["tampered"]);
            },
            error => {
                notify({
                    type: "error",
                    message: "Error while fetching event"
                });
            }
        );
    };

    React.useEffect(() => {
        fetchData();
    }, [props.txId]);

    return (
        <Grid container item xs={props.xsValue} spacing={3}>

            <Paper style={{ maxHeight: '56vh', overflow: 'auto', width: "100%", marginLeft: "12px" }} elevation={2}>
                <Toolbar style={{ background: "#f8f8f8" }}>
                    <Typography variant="h9" >
                        <ChevronRightIcon onClick={props.closeTransactionsPane()} style={{ verticalAlign: "middle", cursor: "pointer" }} />
                        Transaction Info
                    </Typography>
                </Toolbar>
                <div style={{ background: "#f8f8f8", paddingLeft: "2em" }}>{tampered != null ? (!tampered ? (
                    <div className={classes.smallTextIcon}>
                        <VerifiedUserIcon style={{ "color": "green" }} className={classes.smallTextIcon} />Data’s authenticity is verified against blockchain ledger
                    </div>) : (<div className={classes.smallTextIcon}>
                        <CancelIcon style={{ "color": "red" }} className={classes.smallTextIcon} />Data looks like tampered. Hashes don't match from blockchain
                    </div>))
                    : ("")}
                    <div></div>
                </div>

                <Divider></Divider>
                <div style={{
                    background: "#f8f8f8", height: "100%", overflow: "auto"
                }}>
                    {transDetails != null && Object.keys(transDetails).length !== 0 ? (
                        <div>
                            {
                                <Table className={classes.table} aria-label="customized table">
                                    <TableBody>
                                        <TableRow style={{ height: "60px" }} key="id">
                                            <TableCell component="th" scope="row">
                                                <b>Transaction ID</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.transactionId} </TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="creator">
                                            <TableCell component="th" scope="row">
                                                <b>Creator Organization</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.creator ? transDetails.creator.toUpperCase().slice(0, transDetails.creator.length - 3) : ""}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="endorser">
                                            <TableCell component="th" scope="row">
                                                <b>Endorsers</b>
                                            </TableCell>
                                            <TableCell align="left">
                                                {transDetails != null && transDetails.endorsers.length > 0 ? (
                                                    transDetails.endorsers.map((member, index) => {
                                                        return (
                                                            <div>{(index + 1) + ")"} {member.toUpperCase().slice(0, member.length - 3)}</div>
                                                        );
                                                    })
                                                ) : (
                                                        ""
                                                    )}
                                                {/* <JSONTree
                                                data={transDetails.endorsers.length > 0 ? transDetails.endorsers.map(i => i.toUpperCase().slice(0, i.length - 3)) : ""}
                                                theme={writeTheme}
                                                invertTheme={false}
                                            /> */}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="channel">
                                            <TableCell component="th" scope="row">
                                                <b>Channel</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.channelId}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="trxntype">
                                            <TableCell component="th" scope="row">
                                                <b>Transaction Type</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.transactionType}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="timestamp">
                                            <TableCell component="th" scope="row">
                                                <b>Timestamp</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.channelInfo.timestamp}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="chaincodeName">
                                            <TableCell component="th" scope="row">
                                                <b>Chaincode Name</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.chaincode.name}</TableCell>
                                        </TableRow>
                                        <TableRow style={{ height: "60px" }} key="chaincodeVersion">
                                            <TableCell component="th" scope="row">
                                                <b>Chaincode Version</b>
                                            </TableCell>
                                            <TableCell align="left">{transDetails.chaincode.version}</TableCell>
                                        </TableRow>

                                        <TableRow style={{ height: "60px" }} key="endorser">
                                            <TableCell component="th" scope="row">
                                                <b>Event data</b>
                                            </TableCell>
                                            <TableCell align="left"><JSONTree
                                                data={eventData != null ? eventData : ""}
                                                theme={writeTheme}
                                                invertTheme={false}
                                            /></TableCell>
                                        </TableRow>

                                    </TableBody>
                                </Table>}
                        </div>
                    ) : (
                            <Grid item xs={12}>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                                <br></br>
                                <Skeleton height={20} />
                            </Grid>)}
                </div >
            </Paper >
        </Grid >
    );
}